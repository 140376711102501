import { get } from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

export const storeEnv = createRoutine(actionTypes.STORE_ENV);

const env = (state = {}, action) => {
  switch (action.type) {
    case storeEnv.TRIGGER: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const getEnv = (state) => get(state, 'env', {});
export default env;
