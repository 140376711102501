import { getRiskAlertStatusTextByStatus } from '../utils/methods';
import { formatTimeLocal } from '../utils/time';

export default [
  {
    id: 'date',
    accessor: ({ lastUpdatedTime }) => formatTimeLocal(lastUpdatedTime),
    width: 130,
  },
  {
    id: 'status',
    accessor: (row) => getRiskAlertStatusTextByStatus(row.status),
    width: 108,
  },
  {
    id: 'user_name',
    accessor: 'userName',
    width: 195,
  },
];
