import React, { Fragment } from 'react';
import { Button, Label, Icon, Dropdown } from 'semantic-ui-react';

export const transactionTypeOptions = [
  {
    key: 'deposit',
    value: 'deposit',
    text: 'Deposit',
  },

  {
    key: 'withdrawal',
    value: 'withdrawal',
    text: 'Withdrawal',
  },
];
export const internalTransferTypeOptions = [
  {
    key: 'internal',
    value: 'internal',
    text: 'Internal Transfer (Legacy Behavior)',
  },
];

const ReconciliationFilters = ({
  filterColumn,
  refresh,
  reconciliationLoading,
  filtered,
  removeFilter,
}) => (
  <Fragment>
    {/* Disable the filter for type. */}
    {/* <Dropdown
      placeholder="Transaction Type:"
      className="pull-right"
      search
      selection
      options={transactionTypeOptions}
      onChange={(event, { value }) => filterColumn('type', value)}
      value={filtered.reduce((acc, val) => (val.id === 'type' ? val.value : ''), {})}
    /> */}
    <Button
      loading={reconciliationLoading}
      icon={reconciliationLoading ? 'spinner' : 'refresh'}
      onClick={refresh}
      floated="right"
    />
    {filtered.length
      ? filtered.map((filter) => (
          <Label
            as="a"
            className="pull-right"
            onClick={() => removeFilter(filter)}
          >
            {filter.id === 'txId' ? 'Linked Entries' : filter.value}
            <Icon name="delete" />
          </Label>
        ))
      : ''}
  </Fragment>
);

export default ReconciliationFilters;
