import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  futuresProducts,
  createFuturesProduct,
  updateFuturesProduct,
} from '../reducers/futuresProductReducer';
import {
  spotProducts,
  createSpotProduct,
  updateSpotProduct,
} from '../reducers/spotProductsReducer';
import { FUTURES_PRODUCTS, SPOT_PRODUCTS } from '../constants/actionTypes';

export default function* watchProducts() {
  yield takeLatest(
    futuresProducts.TRIGGER,
    createSaga(futuresProducts, FUTURES_PRODUCTS),
  );
  yield takeLatest(
    createFuturesProduct.TRIGGER,
    createSaga(
      createFuturesProduct,
      'CREATE_FUTURES_PRODUCT',
      'Creating futures product...',
      'Futures product created.',
    ),
  );
  yield takeLatest(
    updateFuturesProduct.TRIGGER,
    createSaga(
      updateFuturesProduct,
      'UPDATE_PRODUCT',
      'Updating futures product...',
      'Futures product updated.',
    ),
  );
  yield takeLatest(
    spotProducts.TRIGGER,
    createSaga(spotProducts, SPOT_PRODUCTS),
  );
  yield takeLatest(
    createSpotProduct.TRIGGER,
    createSaga(
      createSpotProduct,
      'CREATE_SPOT_PRODUCT',
      'Creating spot product...',
      'Spot product created.',
    ),
  );
  yield takeLatest(
    updateSpotProduct.TRIGGER,
    createSaga(
      updateSpotProduct,
      'UPDATE_PRODUCT',
      'Updating spot product...',
      'Spot product updated.',
    ),
  );
}
