import { omit } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getFormInitialValues } from 'redux-form';
import { XTable, createLoadingSelector } from 'erisxkit/client';
import PropTypes from 'prop-types';
import { title } from 'change-case';
import { Header, Button, Icon } from 'semantic-ui-react';
import { getAssetTypesAsOptions } from '../../selectors';
import { assetTypes } from '../../actions/utilActions';
import {
  spotProducts,
  createSpotProduct,
  getSpotProducts,
  updateSpotProduct,
} from '../../reducers/spotProductsReducer';
import spotProductsMetadata from '../../metadata/spotProductsMetadata';
import { showModal, hideModal } from 'erisxkit/client';
import {
  CREATE_SPOT_PRODUCT,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../constants/modalTypes';
import { shallowObjectDiff } from '../../utils/methods';

const mapDispatchToProps = {
  assetTypes,
  spotProducts,
  createSpotProduct,
  showModal,
  hideModal,
  updateSpotProduct,
};

const mapStateToProps = (state) => ({
  assetTypesOptions: getAssetTypesAsOptions(state),
  spotProductsList: getSpotProducts(state),
  spotProductsLoading: createLoadingSelector(['SPOT_PRODUCTS'])(state),
  spotProductInitialValues: getFormInitialValues('CreateSpotProduct')(state),
});

class SpotProductsContainer extends Component {
  componentDidMount = () => {
    this.props.assetTypes();
    this.props.spotProducts();
  };

  confirmAction = (payload = {}) => {
    let update;
    const actionType = payload.type || 'create';
    const formPayload = omit(payload, 'type');
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: `Confirm ${title(actionType)} Spot Product`,
      msg: `${title(actionType)} spot product with the following details?`,
      hideModal: this.props.hideModal,
      closeOnEscape: true,
      onConfirm: () => {
        if (actionType === 'create') {
          this.props.createSpotProduct(payload);
        } else {
          update = shallowObjectDiff(
            formPayload,
            this.props.spotProductInitialValues,
          );
          this.props.updateSpotProduct({
            productSymbol: payload.symbol,
            update,
          });
        }
        // call hidemodal twice to hide both confirmation and form modals
        this.props.hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
        this.props.hideModal();
      },
      payload: this.props.spotProductInitialValues
        ? shallowObjectDiff(formPayload, this.props.spotProductInitialValues)
        : formPayload,
    });
  };

  render = () => (
    <Fragment>
      <div className="flex-row space-between">
        <Header as="h1">Spot Products</Header>
        <Button
          onClick={() =>
            this.props.showModal(CREATE_SPOT_PRODUCT, {
              assetTypesOptions: this.props.assetTypesOptions,
              confirmAction: this.confirmAction,
              hideModal: this.props.hideModal,
              onApply: this.props.createSpotProduct,
              type: 'create',
            })
          }
          className="add-button"
          size="small"
          floated="right"
        >
          <Icon name="add" /> Create Spot Product
        </Button>
      </div>
      <XTable
        title="spotProducts"
        showPagination
        showPageSizeOptions={false}
        pageSize={10}
        className="-striped"
        columns={spotProductsMetadata(
          this.props.showModal,
          CREATE_SPOT_PRODUCT,
          {
            assetTypesOptions: this.props.assetTypesOptions,
            confirmAction: this.confirmAction,
            hideModal: this.props.hideModal,
            onApply: this.props.updateSpotProduct,
            type: 'update',
          },
        )}
        data={this.props.spotProductsList}
        loading={this.props.spotProductsLoading}
      />
    </Fragment>
  );
}

SpotProductsContainer.propTypes = {
  assetTypes: PropTypes.func.isRequired,
  assetTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  spotProductsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  spotProducts: PropTypes.func.isRequired,
  createSpotProduct: PropTypes.func.isRequired,
  spotProductsLoading: PropTypes.bool,
};

SpotProductsContainer.defaultProps = {
  assetTypesOptions: [],
  spotProductsList: [],
  spotProductsLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpotProductsContainer);
