import * as actionTypes from '../constants/actionTypes';

export default function assetTypesReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ASSET_TYPES_REQUEST:
      return {};
    case actionTypes.ASSET_TYPES_SUCCESS:
      return {
        ...state,
        assetTypes: action.assetTypes,
      };
    default:
      return state;
  }
}
