import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'semantic-ui-react';

/**
 * @description Generic button that's linked to a customizable timer
 * @prop {function} onTimerStart - callback invoked when button is clicked and timer starts
 * @prop {function} onTimerEnd - callback invoked when button is clicked and ends
 * @prop {number} timer - Time in seconds (integer) where the button will be enabled
 */

const TimerButton = ({ text, timer, onTimerStart, onTimerEnd, className }) => {
  const [timeRemaining, setTimeRemaining] = useState(timer);
  const [timerRunning, setTimerRunning] = useState(false);

  const onClick = () => {
    setTimerRunning(true);
    onTimerStart();
  };

  const handleTimerEnd = () => {
    onTimerEnd();
    setTimerRunning(false);
  };

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        setTimeRemaining((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(interval);
            handleTimerEnd();
            return timer;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerRunning, timer]);

  return (
    <>
      <Button
        className={className}
        as="div"
        labelPosition="right"
        onClick={onClick}
        disabled={timerRunning}
      >
        <Button color="red">{text}</Button>
        <Label as="a" basic color="red" pointing="left">
          {timeRemaining}
        </Label>
      </Button>
    </>
  );
};

TimerButton.defaultProps = {
  text: 'Reveal',
  timer: 30,
  onTimerStart: () => {},
  onTimerEnd: () => {},
  className: '',
};

TimerButton.propTypes = {
  text: PropTypes.string,
  timer: PropTypes.number,
  onTimerStart: PropTypes.func,
  onTimerEnd: PropTypes.func,
  className: PropTypes.string,
};

export default TimerButton;
