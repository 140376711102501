import React, { Fragment, useState } from 'react';
import { Tab, Header, Table, Form } from 'semantic-ui-react';
import { titleCase } from 'change-case';
import {
  inputOnChange,
  datetimeOnchange,
  showModal,
  hideModal,
} from 'erisxkit/client';
import Datetime from 'react-datetime';
import TradesLogContainer from './TradeLogContainer';
import TradeCaptureReportsContainer from './TradeCaptureReportsContainer';
import XTab from '../common/tabs/XTab';
import {
  fetchBlockTradeRequests,
  cancelBlockTradeRequest,
  commitBlockTradeRequest,
} from '../reducers/blockTradesReducer';
import blockTradeRequestsMetadata from '../metadata/blockTradeRequestsMetadata';
import GenericFilteredTable from '../common/containers/GenericFilteredTable';
import ProductContractSelection from '../common/containers/ProductContractSelection';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import { BLOCK_TRADE_REQUEST } from '../constants/actionTypes';
import { useDispatch } from 'react-redux';

const filters = [
  {
    placeholder: 'Request Id',
    component: Form.Input,
    name: 'request_id',
    id: 'request_id',
    label: 'Request Id',
    onChange: inputOnChange,
  },
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'Trade Date',
    name: 'trade_date',
    id: 'trade_date',
    dateFormat: 'YYYY-MM-DD',
    inputProps: { placeholder: 'Filter Trade Date' },
    closeOnSelect: true,
    onChange: datetimeOnchange('trade_date'),
  },
  {
    component: ProductContractSelection,
    contractName: 'contract_symbol',
    name: 'contract_symbol',
    onChange:
      (onChange) =>
      (e, { name, value }) =>
        onChange(name, value, 'eq'),
    showDetails: true,
    upward: false,
  },
  {
    placeholder: 'Submitter',
    component: Form.Input,
    name: 'submitter',
    id: 'submitter',
    label: 'Submitter',
    onChange: inputOnChange,
  },
  {
    placeholder: 'State',
    component: Form.Input,
    name: 'state',
    id: 'state',
    label: 'State',
    onChange: inputOnChange,
  },
  {
    component: AccountSelectionContainer,
    name: 'buy_side.account_id',
    id: 'buy_side',
    label: 'Buy Side',
    onChange: inputOnChange,
    withoutField: true,
  },
  {
    component: AccountSelectionContainer,
    name: 'sell_side.account_id',
    id: 'sell_side',
    label: 'Sell Side',
    onChange: inputOnChange,
    withoutField: true,
  },
];
const row = [
  'accountLabel',
  'senderSubId',
  'senderLocation',
  'customerAccountRef',
  'clOrdId',
  'cti',
  'origin',
];

const createData = ({ buySide = {}, sellSide = {} }) =>
  row.map((r) => ({
    key: r,
    buyValue: buySide[r],
    sellValue: sellSide[r],
  }));

const renderBodyRow = ({ key, buyValue, sellValue }, i) => ({
  key: key || `row-${i}`,
  cells: [
    <b>{titleCase(key)}</b>,
    buyValue ? { key: 'buyValue', content: buyValue } : { content: '—' },
    sellValue ? { key: 'sellValue', content: sellValue } : { content: '—' },
  ],
});
export const BlockTradesSubcomponent = ({ original }) => (
  <Fragment>
    <Table
      celled
      collapsing
      className="compact"
      headerRow={[
        { width: 4 },
        { content: 'Buy Side', width: 6 },
        { content: 'Sell Side', width: 6 },
      ]}
      striped
      tableData={createData(original)}
      renderBodyRow={renderBodyRow}
    />
  </Fragment>
);

const panes = (actionBlockTrade) => [
  {
    url: 'journals',
    className: 'journals',
    menuItem: 'Trades',
    render: () => (
      <Tab.Pane attached={false}>
        <TradesLogContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'tcrs',
    className: 'tcrs',
    menuItem: 'TCRs',
    render: () => (
      <Tab.Pane attached={false}>
        <TradeCaptureReportsContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'block',
    className: 'block',
    menuItem: 'Block Requests',
    render: ({ filterArgs }) => (
      <Tab.Pane attached={false}>
        <GenericFilteredTable
          slice={['blockTradeRequests']}
          routine={fetchBlockTradeRequests}
          key="block_trade_requests"
          title="block_trade_requests"
          metadata={blockTradeRequestsMetadata(actionBlockTrade)}
          expandable
          sortable
          multipleExpandable
          filters={filters}
          SubComponent={BlockTradesSubcomponent}
          filterArgs={filterArgs}
        />
      </Tab.Pane>
    ),
  },
];

const TradesContainer = () => {
  const dispatch = useDispatch();
  const [filterArgs, setFilterArgs] = useState(undefined);
  const defaultArgs = {
    filter: [],
    limit: 100,
    offset: 0,
    page: 0,
    sort: [],
  };
  const confirmBlockTrade = (data) => {
    const args = {
      tradeRequestId: data.requestId,
    };
    dispatch(commitBlockTradeRequest(args));
    dispatch(hideModal(BLOCK_TRADE_REQUEST));
    setFilterArgs([]);
    dispatch(fetchBlockTradeRequests(defaultArgs));
  };

  const rejectBlockTrade = (data) => {
    const args = {
      tradeRequestId: data.requestId,
    };
    dispatch(cancelBlockTradeRequest(args));
    dispatch(hideModal(BLOCK_TRADE_REQUEST));
    setFilterArgs([]);
    dispatch(fetchBlockTradeRequests(defaultArgs));
  };

  const actionBlockTrade = (data, type) => {
    dispatch(
      showModal(BLOCK_TRADE_REQUEST, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(BLOCK_TRADE_REQUEST)),
        action: (data, type) =>
          type === 'confirm' ? confirmBlockTrade(data) : rejectBlockTrade(data),
        type,
        data,
      }),
    );
  };

  return (
    <Fragment>
      <Header as="h1">Trades</Header>
      <XTab panes={panes(actionBlockTrade)} filterArgs={filterArgs} />
    </Fragment>
  );
};

export default TradesContainer;
