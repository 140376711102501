import { takeLatest } from 'redux-saga/effects';
import {
  fetchCgmList,
  createCGM,
  updateCGM,
  deleteCgm,
  linkCgmToParticipant,
  unlinkCgmToParticipant,
} from '../reducers/cgmManagementReducer';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

export default function* watch() {
  yield takeLatest(
    fetchCgmList,
    generateSaga(fetchCgmList, { method: HTTPMethod.GET }),
  );
  yield takeLatest(
    deleteCgm,
    generateSaga(deleteCgm, {
      method: HTTPMethod.DELETE,
      loading: 'Deleting CGM...',
      success: 'CGM Deleted successfully',
    }),
  );
  yield takeLatest(
    createCGM,
    generateSaga(createCGM, {
      loading: 'Submitting CGM Creation request',
      success: 'CGM has been added successfully',
    }),
  );
  yield takeLatest(
    updateCGM,
    generateSaga(updateCGM, {
      loading: 'Submitting CGM Update request',
      success: 'CGM has been updated successfully',
      method: HTTPMethod.PUT,
    }),
  );
  yield takeLatest(
    linkCgmToParticipant,
    generateSaga(linkCgmToParticipant, {
      loading: 'Linking CGM to Participant',
      success: 'CGM has been linked successfully',
    }),
  );
  yield takeLatest(
    unlinkCgmToParticipant,
    generateSaga(unlinkCgmToParticipant, {
      loading: 'Unlinking CGM to Participant',
      success: 'CGM has been unlinked successfully',
    }),
  );
}
