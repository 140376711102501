import { get, debounce } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector, getAllAccounts } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';
import { fetchMembers } from '../../reducers/membersReducer';

const mapStateToProps = (state) => ({
  membersList: Object.values(getAllAccounts('members')(state)),
  membersLoading: createLoadingSelector(['MEMBERS'])(state),
});

const mapDispatchToProps = {
  fetchMembers,
};

const membersOpts = (members) =>
  members.map((member) => ({
    key: member.memberId,
    value: member.memberId,
    text: member.name,
    description: member.firmCode,
  }));

class MemberSelectionContainer extends Component {
  state = { searchQuery: '' };

  constructor(props) {
    super(props);
    // Debounce
    this.handleSearchChange = debounce(this.handleSearchChange, 500);
  }

  handleSearchChange = (e, { searchQuery }) => {
    this.props.fetchMembers({
      filter: [{ attr: 'name', op: 'contain', value: searchQuery }],
      limit: 10,
    });
    this.setState({ searchQuery });
  };

  renderFormField = () => (
    <Form.Field
      control={Select}
      search
      selection
      options={membersOpts(this.props.membersList)}
      onChange={(e, { value }) =>
        get(this.props, ['input', 'onChange'], () => {})(value)
      }
      onSearchChange={this.handleSearchChange}
      loading={this.props.membersLoading}
      noResultsMessage={this.state.searchQuery ? '' : 'Search for an member.'}
      selectOnBlur={false}
      clearable
      defaultValue={get(this.props, ['input', 'value'])}
      {...this.props}
    />
  );

  render = () => {
    if (this.props.defaultValue) {
      if (this.props.membersList.length) {
        return this.renderFormField();
      }
      return null;
    }
    return this.renderFormField();
  };
}

MemberSelectionContainer.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  membersList: PropTypes.arrayOf(
    PropTypes.shape({
      memberId: PropTypes.string,
      name: PropTypes.string,
      members: PropTypes.array,
      balances: PropTypes.object,
      type: PropTypes.string,
    }),
  ).isRequired,
};

// Specifies the default values for props:
MemberSelectionContainer.defaultProps = {
  name: 'memberId',
  placeholder: 'Search by member name',
  label: 'Start typing the member name',
  multiple: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberSelectionContainer);
