import _ from 'lodash';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { WithdrawalConfirm } from 'erisxkit/client';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { hideModal } from 'erisxkit/client';
import {
  createFiatWithdrawalRequest,
  createCryptoWithdrawalRequest,
} from '../../actions/manualEntryActions';
import { getFormValues } from 'redux-form';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import { getCurrentUser } from '../../reducers/usersReducer';
import { getAmountMinUnit } from '../../reducers/utilReducer';

class ConfirmExternalTransferContainer extends Component {
  onConfirm = (payload) => {
    switch (this.props.type) {
      case 'fiat':
        this.props.createFiatWithdrawalRequest(payload);
        break;
      case 'crypto':
        this.props.createCryptoWithdrawalRequest(payload);
        break;
    }
    this.props.hideModal();
  };

  render() {
    return (
      <WithdrawalConfirm
        {...this.props}
        onCancel={this.props.hideModal}
        onConfirm={this.onConfirm}
      />
    );
  }
}

ConfirmExternalTransferContainer.propTypes = {
  formName: PropTypes.string.isRequired,
  // All other props are passed through to WithdrawalConfirm component.
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideModal,
      createFiatWithdrawalRequest,
      createCryptoWithdrawalRequest,
    },
    dispatch,
  );

const mapStateToProps = (state, ownProps) => ({
  values: getFormValues(ownProps.formName)(state),
  amountMinUnit: getAmountMinUnit(state),
  user: getCurrentUser(state),
  selectedMemberId: getSelectedMemberId(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmExternalTransferContainer);
