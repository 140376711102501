import React, { useCallback, useMemo } from 'react';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import XTable7 from '../../../common/table/XTable7';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import Member from '../../../ts/models/Member.model';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { RootState } from '../../../ts/types/store';
import { blockTradePermissionsMetadata } from './blockTradePermissionsMetadata';
import { AuthorizedBlockTraderRow } from '../../../ts/models/BlockTrades/PermissionManagement.model';
import {
  GET_AUTHORIZED_BLOCK_TRADERS,
  REMOVE_AUTHORIZED_BLOCK_TRADER,
  fetchAuthorizedBlockTraders,
  getAuthorizedBlockTradersForGrid,
  removeAuthorizedBlockTraderPromise,
} from '../../../reducers/blockTradePermissionsReducer';
import {
  ADD_AUTHORIZED_TRADER,
  GENERIC_MODAL,
} from '../../../constants/modalTypes';
import { GenericModalProps } from '../../../common/GenericModal';
import styled from 'styled-components';
import { Header, Button } from 'semantic-ui-react';

interface Props {
  selectedMember: Member;
}

const AddAuthorizedTrader = styled(Button)`
  width: 220px;
  height: 40px;
  margin-left: auto;
  margin-bottom: 20px;
`;

const BlockTradePermissions = ({ selectedMember }: Props) => {
  const dispatch = useAppDispatch();
  const authorizedTraders: AuthorizedBlockTraderRow[] = useAppSelector(
    getAuthorizedBlockTradersForGrid,
  );
  const loading = useAppSelector((state: RootState) =>
    createLoadingSelector([GET_AUTHORIZED_BLOCK_TRADERS])(state),
  );
  const firmCode = useMemo(() => selectedMember?.firmCode, [selectedMember]);

  const fetchData = useCallback(() => {
    if (!firmCode) return;
    dispatch(fetchAuthorizedBlockTraders({ urlParams: { firmCode } }));
  }, [dispatch, firmCode]);

  const onAddAuthorizedTrader = () => {
    dispatch(
      showModal(ADD_AUTHORIZED_TRADER, {
        size: 'large',
        onSuccess: fetchData,
      }),
    );
  };

  const onDeleteRequest = useCallback(
    async (authorizedTraderEmail: string) => {
      try {
        await removeAuthorizedBlockTraderPromise(
          {
            urlParams: { firmCode, removedTraderEmail: authorizedTraderEmail },
          },
          dispatch,
        );
      } catch (e) {
        console.error('Error: could not send delete request');
      }
      dispatch(fetchAuthorizedBlockTraders({ urlParams: { firmCode } }));
      dispatch(hideModal());
    },
    [dispatch, firmCode],
  );

  const onDelete = useCallback(
    (authorizedTraderEmail: string) => {
      dispatch(
        showModal(GENERIC_MODAL, {
          header: 'Confirm Delete',
          content: `Are you sure you want to remove authorized block trader ${authorizedTraderEmail}?`,
          cancelProps: {
            text: 'Cancel',
            onClick: () => dispatch(hideModal()),
          },
          confirmProps: {
            text: 'Delete',
            onClick: () => {
              onDeleteRequest(authorizedTraderEmail);
            },
            danger: true,
          },
          loadingSelector: (state: any) =>
            createLoadingSelector([REMOVE_AUTHORIZED_BLOCK_TRADER])(state),
        } as GenericModalProps),
      );
    },
    [dispatch, onDeleteRequest],
  );

  const metadata = useMemo(
    () => blockTradePermissionsMetadata(onDelete),
    [onDelete],
  );

  return (
    <>
      <div className="flex-row space-between">
        <Header as="h1" dividing>
          Block Trade Permissions
        </Header>
        <AddAuthorizedTrader
          className="add-button"
          floated="right"
          onClick={onAddAuthorizedTrader}
          content="Add Authorized Trader"
          icon="plus"
        />
      </div>
      <XTable7
        title="blockTradePermissions"
        metadata={metadata}
        data={authorizedTraders}
        fetchData={fetchData}
        loading={loading}
        count={authorizedTraders?.length || 0}
        showHeader
        showPagination={false}
      />
    </>
  );
};

export default BlockTradePermissions;
