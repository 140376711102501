import React from 'react';
import SystemAlertsContainer from './SystemAlertsContainer';
import ChangeLogsContainer from './ChangeLogsContainer';
import ExceptionsContainer from './ExceptionsContainer';
import TasksContainer from './TasksContainer';
import RunTasksContainer from './RunTasksContainer';
import XTab from '../../common/tabs/XTab';
import complianceAlertComponents from '../../constants/complianceAlertComponents';
import complianceAlertCategories from '../../constants/complianceAlertCategories';

const panes = [
  {
    url: 'system_alerts',
    menuItem: 'Alerts',
    render: () => (
      <SystemAlertsContainer
        requiredFilters={[
          { attr: 'component', op: 'ne', value: complianceAlertComponents },
          { attr: 'category', op: 'ne', value: complianceAlertCategories },
        ]}
      />
    ),
  },
  {
    url: 'change_logs',
    menuItem: 'Change Logs',
    render: () => <ChangeLogsContainer />,
  },
  {
    url: 'exceptions',
    menuItem: 'Exceptions',
    render: () => <ExceptionsContainer />,
  },
  { url: 'task_logs', menuItem: 'Task Log', render: () => <TasksContainer /> },
  {
    url: 'trigger_tasks',
    menuItem: 'Run Tasks',
    render: () => <RunTasksContainer />,
  },
];

const SystemMessages = () => <XTab panes={panes} authorize />;

export default SystemMessages;
