import _ from 'lodash';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import { arrayToObject } from 'erisxkit/client';
import * as actionTypes from '../constants/actionTypes';

export const changeLogs = createRoutine(actionTypes.CHANGE_LOGS);

const initialState = {
  count: 0,
  byId: {},
};

// reducer creator for system alerts and exception messages
export function messages(name) {
  return (state = initialState, action) => {
    switch (action.type) {
      case actionTypes[`${name}_SUCCESS`]: {
        let data;
        let key;
        if (name === 'EXCEPTIONS') {
          data = action.payload.exceptions;
        }
        if (name === 'SYSTEM_ALERTS') {
          data = action.payload.alerts;
        }

        return {
          ...state,
          count: action.payload.count,
          byId: arrayToObject(data, key),
        };
      }
      default:
        return state;
    }
  };
}

// reducer creator for system alerts and exception info
// (the data displayed in the filter drop downs)
function info(name) {
  return (state = {}, action) => {
    switch (action.type) {
      case actionTypes[`${name}_SUCCESS`]:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };
}

const systemAlerts = combineReducers({
  messages: messages('SYSTEM_ALERTS'),
  systemAlertInfo: info('SYSTEM_ALERT_INFO'),
});

const exceptions = combineReducers({
  messages: messages('EXCEPTIONS'),
  exceptionInfo: info('EXCEPTION_INFO'),
});

const changeLogsReducer = handleAction(
  changeLogs.SUCCESS,
  (state, action) => action.payload,
  {},
);

export default {
  systemAlerts,
  changeLogs: changeLogsReducer,
  exceptions,
};

/* Selectors */
const getSystemAlerts = (state) =>
  _.get(state, ['systemAlerts', 'messages', 'byId'], {});
const getExceptions = (state) =>
  _.get(state, ['exceptions', 'messages', 'byId'], {});

const getSelected = (state) => _.get(state, ['systemAlerts', 'selected'], '');

export const getSystemAlertsList = (state) =>
  Object.values(getSystemAlerts(state));
export const getExceptionsList = (state) => Object.values(getExceptions(state));
export const getChangeLogsList = (state) =>
  _.get(state, ['changeLogs', 'logs'], []);

export const getSystemAlertInfo = (state) =>
  _.get(state, ['systemAlerts', 'systemAlertInfo'], {});
export const getExceptionInfo = (state) =>
  _.get(state, ['exceptions', 'exceptionInfo'], {});

export const getSystemAlertsCount = (state) =>
  _.get(state, ['systemAlerts', 'messages', 'count'], 0);
export const getExceptionssCount = (state) =>
  _.get(state, ['exceptions', 'messages', 'count'], 0);
export const getChangeLogsCount = (state) =>
  _.get(state, ['changeLogs', 'count'], 0);

export const getSelectedSystemAlert = createSelector(
  [getSystemAlerts, getSelected],
  (list, id) => _.get(list, id, {}),
);

export const getChangeLogsEdits = (state) =>
  _.chain(state)
    .get(['changeLogs', 'logs'], [])
    .map((log) => log.edits)
    .value();
