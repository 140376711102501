import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { Message, Button, Icon } from 'semantic-ui-react';
import ScrollArea from 'react-scrollbar';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const renderPages = (numPages, scale) =>
  Array.from({ length: numPages }, (_, i) => (
    <Page className="page-pdf" scale={scale} pageIndex={i} key={i} />
  ));

const PdfViewer = ({ image }) => {
  const [numOfPages, setOfNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  return (
    <ScrollArea
      className="terms-container"
      style={{ maxHeight: '50vh' }}
      horizontal={false}
      verticalScrollbarStyle={{
        backgroundColor: '#ffffff',
        borderRadius: '1em',
        width: '0.8rem',
      }}
      verticalContainerStyle={{
        backgroundColor: 'rgba(0, 44, 70, 0.8)',
        opacity: 1,
        width: '0.95rem',
      }}
    >
      <Button.Group>
        <Button icon onClick={() => setScale(scale + 0.3)}>
          <Icon name="zoom-in" />
        </Button>
        <Button icon onClick={() => setScale(scale - 0.3)}>
          <Icon name="zoom-out" />
        </Button>
      </Button.Group>

      <Document
        file={image}
        onLoadError={() => (
          <Message>Oops! We couldn&#39;t load this PDF.</Message>
        )}
        onSourceError={() => (
          <Message>
            Oops! We encountered an error while loading this PDF.
          </Message>
        )}
        onLoadSuccess={({ numPages }) => setOfNumPages(numPages)}
      >
        {renderPages(numOfPages, scale)}
      </Document>
    </ScrollArea>
  );
};

PdfViewer.propTypes = {
  image: PropTypes.string.isRequired,
};

export default PdfViewer;
