import {
  DIRECT_MEMBER,
  EXECUTION_BROKER_MEMBER,
  FIRM_MEMBER,
  INVESTMENT_ADVISOR_MEMBER,
  VENDOR_MEMBER,
  INTERMEDIARY,
  FCM,
} from './memberTypes';

export default {
  [DIRECT_MEMBER.type]: {
    PERSONAL: 0,
    SSI: 1,
    LEDGER_ACCOUNTS: 2,
    MEMBER_USERS: 3,
  },
  [EXECUTION_BROKER_MEMBER.type]: {
    PERSONAL: 0,
    SSI: 1,
    LEDGER_ACCOUNTS: 2,
    MEMBER_USERS: 3,
  },
  [FIRM_MEMBER.type]: {
    PERSONAL: 0,
    UBO: 1,
    SSI: 2,
    LEDGER_ACCOUNTS: 3,
    MEMBER_USERS: 4,
  },
  [INVESTMENT_ADVISOR_MEMBER.type]: {
    PERSONAL: 0,
    SSI: 1,
    LEDGER_ACCOUNTS: 2,
    MEMBER_USERS: 3,
  },
  [INTERMEDIARY.type]: {
    PERSONAL: 0,
    UBO: 1,
    SSI: 2,
    LEDGER_ACCOUNTS: 3,
    MEMBER_USERS: 4,
  },
  [FCM.type]: {
    PERSONAL: 0,
    UBO: 1,
    SSI: 2,
    LEDGER_ACCOUNTS: 3,
    MEMBER_USERS: 4,
  },
  [VENDOR_MEMBER.type]: {
    PERSONAL: 0,
    SSI: 1,
    LEDGER_ACCOUNTS: 2,
    MEMBER_USERS: 3,
  },
};
