import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Header, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import omit from 'lodash/omit';
import { MARGIN_RATES } from '../../constants/modalTypes';
import XTable7 from '../../common/table/XTable7';
import initialMarginCalculationsMetadata from '../../metadata/initialMarginCalculationsMetadata';
import useEditableTableFields from '../../hooks/useEditableTableFields';
import { getCurrentUser } from '../../reducers/usersReducer';
import { formatTimeLocal } from '../../utils/time';
import {
  fetchFcmMarginCalculations,
  getFcmMarginCalculations,
  getInitialMarginTradeDate,
  setMarginRequirementsPromiseCreator,
} from '../../reducers/initialMarginReducer';
import { SET_MARGIN_REQUIREMENTS } from '../../constants/actionTypes';
import usePaginatedFetch from '../../hooks/usePaginatedFetch';
import styled from 'styled-components';
import MARGIN_CALL_CALCULATION_TYPES from '../../constants/marginCallCalculationTypes';

const StyledSelect = styled.select`
  max-height: 20px;
  margin-right: 10px;
  &:has(option[value='']:checked) {
    background: rgba(255, 252, 127, 0.8);
  }
  & option {
    background: white;
  }
`;

const StyledTopHeader = styled.div`
  align-items: center;
  justify-content: space-between;
`;

const InitialMarginCalculations = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const tradeDate = useSelector(getInitialMarginTradeDate);
  const data = useSelector(getFcmMarginCalculations);
  const loading = useSelector(
    createLoadingSelector(['FCM_MARGIN_CALL_CALCULATIONS']),
  );
  const isInitialMount = useRef(true);
  const filtersRef = useRef();
  const [typeFilterValue, setTypeFilterValue] = useState('');

  const {
    onReset,
    onEditClicked,
    onBlur,
    onSetValue,
    isFieldBeingEdited,
    editableData,
    hasEdits,
  } = useEditableTableFields(data);

  const handleApply = async (typeFilterValue) => {
    try {
      // Date and Type are common to all margin calculations - use the first we get
      const date = get(editableData, [0, 'tradeDate'], '');
      // Make a list of margin requirement objects by setting override values
      const marginRequirements = editableData.map((calculation) => ({
        // Remove unnecessary attributes from calculation
        ...omit(calculation, [
          'comment',
          'trade_date',
          'time',
          'type',
          'originalFlatMargin',
          'cover2AddOnOverride',
          'cover2_add_on_override',
        ]),
        // Manually snake cased because of issues with camelCase->snake_case conversion
        // when numbers are involved
        cover_2_add_on_override: calculation.cover2AddOnOverride,
        overrideMarginRequirement: calculation.flatMarginRequirement,
        overrideComment: calculation.comment,
      }));

      await setMarginRequirementsPromiseCreator(
        {
          tradeDate: date,
          type: typeFilterValue,
          marginRequirements,
        },
        dispatch,
      );
      dispatch(hideModal(MARGIN_RATES));
      dispatch(filteredFetch({ filters: filtersRef.current }));
    } catch (error) {
      dispatch(hideModal(MARGIN_RATES));
    }
  };

  const filteredFetch = usePaginatedFetch(fetchFcmMarginCalculations, {
    tradeDate,
  });

  const fetch = useCallback(
    (params) => {
      if (!typeFilterValue) return;
      filtersRef.current = params.filters;
      // When the table filters, update filtersRef with current filters
      // so they can be used on useEffect without triggering a rerender
      filteredFetch({ filters: filtersRef.current });
    },
    [tradeDate, filteredFetch, typeFilterValue],
  );

  const onApply = useCallback(() => {
    dispatch(
      showModal(MARGIN_RATES, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(MARGIN_RATES)),
        updateStatus: () => handleApply(typeFilterValue),
        action: 'Confirm',
        date:
          editableData.length > 0
            ? formatTimeLocal(editableData[0].tradeDate)
            : '',
        initiator: user,
        title: 'Override Calculated Margin',
        message:
          'You will submit an approval request to override a calculated margin',
        loadingSelector: SET_MARGIN_REQUIREMENTS,
      }),
    );
  }, [typeFilterValue, editableData]);

  useEffect(() => {
    // Since we fetch from useEffect on tradeDate and with table's fetchData
    // We prevent useEffect from fetching as the table already fetches on mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    filteredFetch({ filters: filtersRef.current });
  }, [tradeDate, typeFilterValue]);

  return (
    <div className="vert-flex">
      <StyledTopHeader className="topHeader">
        <div>
          <StyledSelect
            id="settlementCycleDropdown"
            value={typeFilterValue}
            onChange={(e) => {
              const newValue = e.target.value || '';
              setTypeFilterValue(newValue);
              const filters = filtersRef.current;
              const newFilters = filters?.filter((f) => f.id !== 'type') ?? [];
              if (newValue) {
                newFilters.push({ id: 'type', value: e.target.value });
              }
              filtersRef.current = newFilters;
            }}
          >
            <option value="">Select Settlement Cycle...</option>
            {MARGIN_CALL_CALCULATION_TYPES.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </StyledSelect>
        </div>
        <div className="flex-right">
          <Button
            className="add-button resetBtn"
            id="resetRates"
            onClick={onReset}
            disabled={!hasEdits}
          >
            Reset
          </Button>
          <Button
            className="add-button"
            id="applyRates"
            onClick={onApply}
            disabled={typeFilterValue === ''}
          >
            Apply
          </Button>
        </div>
      </StyledTopHeader>
      <XTable7
        metadata={initialMarginCalculationsMetadata(
          onEditClicked,
          onSetValue,
          isFieldBeingEdited,
          onBlur,
        )}
        title="calculated-margin-call-table"
        data={typeFilterValue !== '' ? editableData : []}
        fetchData={fetch}
        loading={loading}
        count={editableData?.length || 0}
        flex
        showPagination={false}
      />
    </div>
  );
};

export default InitialMarginCalculations;
