import React from 'react';
import { Provider } from 'react-redux';
import Routes from '../routes/index';
import configureStore from '../store/configureStore';

const AppContainer = () => (
  <Provider store={configureStore}>
    <Routes />
  </Provider>
);

export default AppContainer;
