import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { get } from 'lodash';
import * as actionTypes from '../constants/actionTypes';

export const fetchAuthorizedIps = createRoutine(actionTypes.AUTHORIZED_IPS);
export const fetchIpAuthorizationHistory = createRoutine(
  actionTypes.IP_AUTHORIZATION_HISTORY,
);
export const addAuthorizedIp = createRoutine(actionTypes.ADD_AUTHORIZED_IP);
export const removeAuthorizedIp = createRoutine(
  actionTypes.REMOVE_AUTHORIZED_IP,
);
export const addAuthorizedIpPromiseCreator = promisifyRoutine(addAuthorizedIp);
export const removeAuthorizedIpPromiseCreator =
  promisifyRoutine(removeAuthorizedIp);

// Reducer
export default handleActions(
  {
    [fetchAuthorizedIps.SUCCESS]: (state, { payload }) => ({
      ...state,
      authorizedIps: payload,
    }),
    [fetchIpAuthorizationHistory.SUCCESS]: (state, { payload }) => ({
      ...state,
      ipAuthorizationHistory: payload,
    }),
  },
  {},
);

export const getAuthorizedIps = (state) =>
  get(state, ['authorizationIp', 'authorizedIps'], []);
export const getIpAuthorizationHistory = (state) =>
  get(state, ['authorizationIp', 'ipAuthorizationHistory'], []);
