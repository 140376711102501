import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal } from 'semantic-ui-react';
import { hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import ModalDetails from './ModalDetails';
import colors from '../../constants/colors';
import {
  approveFcmClearingPromiseCreator,
  rejectFcmClearingPromiseCreator,
} from '../../reducers/fcmClearingReducer';
import {
  ClearingRequestAction,
  FCMClearingRequest,
} from '../../ts/models/FCMClearing.model';

const Header = styled(Modal.Header)`
  text-align: center;
  color: ${colors.secondary2};
`;
const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: center;
`;

const ClearingRequestModal: React.FC<{
  request: FCMClearingRequest;
  action: ClearingRequestAction;
  fetchData: () => void;
}> = ({ action, request, fetchData }) => {
  const dispatch = useDispatch();
  const isApproval = useMemo(() => action === 'approve', [action]);
  const header = `${isApproval ? 'Approve' : 'Reject'} Request`;
  const subHeader = `You are about to ${action} this request`;
  const buttonText = isApproval ? 'Approve' : 'Reject';
  const firmCode = request?.fcmFirmCode;
  const onCancel = useCallback(() => dispatch(hideModal()), [dispatch]);

  const onClick = useCallback(async () => {
    try {
      if (isApproval) {
        await approveFcmClearingPromiseCreator(
          {
            urlParams: {
              firmCode,
              requestId: request.requestId,
            },
          },
          dispatch,
        );
      } else {
        await rejectFcmClearingPromiseCreator(
          {
            urlParams: {
              firmCode,
              requestId: request.requestId,
            },
          },
          dispatch,
        );
      }
      fetchData();
      onCancel();
    } catch (e) {
      onCancel();
    }
  }, [isApproval, request, firmCode, dispatch, onCancel, fetchData]);

  const fields = useMemo(() => {
    const participantName = get(request, 'requestingMemberName');
    const comment = get(request, 'comment');
    return [
      {
        header: 'Participant',
        text: participantName || '-',
      },
      {
        header: 'Comment',
        text: comment || '-',
      },
    ];
  }, [request]);

  return (
    <>
      <Header>{header}</Header>
      <Modal.Content>
        <ModalDetails subHeader={subHeader} fields={fields} />
      </Modal.Content>
      <Actions>
        <Button secondary onClick={onCancel}>
          Cancel
        </Button>
        <Button primary onClick={onClick}>
          {buttonText}
        </Button>
      </Actions>
    </>
  );
};

export default ClearingRequestModal;
