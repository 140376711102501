export default [
  {
    Header: 'Profile Name',
    id: 'margin_profile',
    accessor: 'marginProfile',
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
  },
  {
    Header: 'Initial Multiplier',
    id: 'initial_margin_rate_multiplier',
    accessor: 'initialMarginRateMultiplier',
  },
  {
    Header: 'Maintenance Multiplier',
    id: 'maintenance_margin_rate_multiplier',
    accessor: 'maintenanceMarginRateMultiplier',
  },
  {
    Header: 'Max Initial Rate',
    id: 'max_initial_margin_rate',
    accessor: 'maxInitialMarginRate',
  },
  {
    Header: 'Min Initial Rate',
    id: 'min_initial_margin_rate',
    accessor: 'minInitialMarginRate',
  },
  {
    Header: 'Max Maintenance Rate',
    id: 'max_maintenance_margin_rate',
    accessor: 'maxMaintenanceMarginRate',
  },
  {
    Header: 'Min Maintenance Rate',
    id: 'min_maintenance_margin_rate',
    accessor: 'minMaintenanceMarginRate',
  },
  {
    Header: 'Spreads Max Initial Rate',
    id: 'spreads_max_initial_margin_rate',
    accessor: 'spreadsMaxInitialMarginRate',
  },
  {
    Header: 'Spreads Max Maintenance Rate',
    id: 'spreads_max_maintenance_margin_rate',
    accessor: 'spreadsMaxMaintenanceMarginRate',
  },
  {
    Header: 'Spreads Min Initial Rate',
    id: 'spreads_min_initial_margin_rate',
    accessor: 'spreadsMinInitialMarginRate',
  },
  {
    Header: 'Spreads Min Maintenance Rate',
    id: 'spreads_min_maintenance_margin_rate',
    accessor: 'spreadsMinMaintenanceMarginRate',
  },
  {
    Header: 'Public Name',
    id: 'public_name',
    accessor: 'publicName',
  },
  {
    Header: 'Publish to Website',
    id: 'publish_to_website',
    accessor: 'publishToWebsite',
  },
];
