import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import { createLedger, ledgers } from '../reducers/ledgersReducer';
import { CREATE_LEDGER, LEDGERS } from '../constants/actionTypes';

export default function* watch() {
  yield takeLatest(
    createLedger.TRIGGER,
    createSaga(
      createLedger,
      CREATE_LEDGER,
      'Creating new ledger...',
      'Ledger created.',
    ),
  );
  yield takeLatest(ledgers.TRIGGER, createSaga(ledgers, LEDGERS));
}
