import { useMemo } from "react";

interface ToggleData {
  [key: string]: boolean
};

const agGridMigrationStoreKey = 'ag-grid-migration-toggle-tcs-admin-ui';

const getGridToggleData = (gridName: string): boolean => {
  const data = localStorage.getItem(agGridMigrationStoreKey);
  try {
    const gridToggleData = JSON.parse(data ?? '{}');
    const currentGridSetting = gridToggleData?.[gridName];
    if (currentGridSetting !== undefined) {
      return currentGridSetting;
    }
    return true;
  } catch (e) {
    return true;
  }
};

export const setGridToggleData = (gridName: string, toggle: boolean) => {
  const data = localStorage.getItem(agGridMigrationStoreKey);

  const setStorageData = (data: ToggleData) => {
    data[gridName] = toggle;
    const json = JSON.stringify(data);
    localStorage.setItem(agGridMigrationStoreKey, json);
  };

  try {
    const gridToggleData = JSON.parse(data ?? '{}');
    setStorageData(gridToggleData);
  } catch (e) {
    setStorageData({});
  }
}

export const useGridToggle = (gridName: string) => {
  const gridToggleData = useMemo(() => { 
    return getGridToggleData(gridName);
  }, [gridName]);
  return gridToggleData;
};
