import { TABLE_INPUT_TYPES } from '../../common/table/constants';

const spanMetadata = (futuresProducts) => [
  {
    Header: 'Spread Priority',
    id: 'spreadPriority',
    accessor: 'spreadPriority',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
    editInputType: TABLE_INPUT_TYPES.LIST,
    options: futuresProducts,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Front Tenor',
    id: 'frontTenor',
    accessor: 'frontTenor',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Back Tenor',
    id: 'backTenor',
    accessor: 'backTenor',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];

export default spanMetadata;
