import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { exportCoreFile, coreFile } from '../reducers/coreFilesReducer';
import api, * as urls from '../api';
import { delay } from '../utils/methods';
import { CORE_FILE_POLLING_INTERVAL } from '../constants/timeouts';
import { getActiveLedgerId } from '../reducers/ledgersReducer';

function* exportCoreFileRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Exporting core file...', {
    hideAfter: 0,
  });
  try {
    const ledgerId = yield select(getActiveLedgerId);
    const newTask = yield call(api.post, urls.EXPORT_CORE_FILE_API_ENDPOINT, {
      ...payload,
      ...(ledgerId && { ledgerId }),
    });
    loadingCallback();
    yield all([
      put(exportCoreFile.success(newTask)),
      put(coreFile.trigger({ ...payload, ...newTask })),
    ]);
  } catch (e) {
    loadingCallback();
    yield put(exportCoreFile.failure(e.response));
  }
}

function* coreFileRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    `Checking status of task ${payload.report} for ${payload.date}...`,
    { hideAfter: 0 },
  );
  try {
    const selectedTask = yield call(
      api.post,
      urls.CORE_FILE_API_ENDPOINT,
      payload,
    );
    if (selectedTask.state !== 'pending') {
      loadingCallback();
      cogoToast.info(
        `${payload.report} core file for ${payload.date} status updated.`,
      );
      yield put(coreFile.success(selectedTask));
    } else {
      yield delay(CORE_FILE_POLLING_INTERVAL);
      loadingCallback();
      yield put(coreFile.trigger(payload));
    }
  } catch (e) {
    loadingCallback();
    yield put(coreFile.failure(e.response));
  }
}

export default function* watchCoreFileRequests() {
  yield takeEvery(exportCoreFile.TRIGGER, exportCoreFileRequest);
  yield takeEvery(coreFile.TRIGGER, coreFileRequest);
}
