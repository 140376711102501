import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

const ExpandCollapseButton = ({ isExpanded, onClick }) => (
  <Button icon labelPosition="left" onClick={onClick}>
    <Icon name={`arrow ${isExpanded ? 'up' : 'down'}`} />
    {`${isExpanded ? 'Collapse' : 'Expand'} All`}
  </Button>
);

ExpandCollapseButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExpandCollapseButton;
