import { TABLE_INPUT_TYPES } from '../../common/table/constants';

const concentrationAddOnMetadata = (futuresProducts) => [
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
    editInputType: TABLE_INPUT_TYPES.LIST,
    options: futuresProducts,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Expiry Index',
    id: 'expiryIndex',
    accessor: 'expiryIndex',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Initial Contracts Threshold',
    id: 'initialContractsThreshold',
    accessor: 'initialContractsThreshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 230,
  },
  {
    Header: 'Initial Add On',
    id: 'initialAddOn',
    accessor: 'initialAddOn',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 180,
  },
  {
    Header: 'Extra Contracts Threshold',
    id: 'extraContractsThreshold',
    accessor: 'extraContractsThreshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 230,
  },
  {
    Header: 'Extra Add On',
    id: 'extraAddOn',
    accessor: 'extraAddOn',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 180,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];

export default concentrationAddOnMetadata;
