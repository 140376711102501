import { get } from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import { getSelectedAccountId } from './accountHistoryReducer';

export const WITHDRAWAL_LIMIT = 'WITHDRAWAL_LIMIT';
export const DEPOSIT_LIMIT = 'DEPOSIT_LIMIT';

export const ACCOUNT_OVERRIDES = 'ACCOUNT_OVERRIDES';
export const UPDATE_ACCOUNT_OVERRIDES = 'UPDATE_ACCOUNT_OVERRIDES';

export const fetchWithdrawalLimit = createRoutine(WITHDRAWAL_LIMIT);
export const fetchDepositLimit = createRoutine(DEPOSIT_LIMIT);
export const fetchAccountOverrides = createRoutine(ACCOUNT_OVERRIDES);

export const updateAccountOverrides = createRoutine(UPDATE_ACCOUNT_OVERRIDES);

export default function achReducer(state = [], action) {
  switch (action.type) {
    case fetchWithdrawalLimit.SUCCESS:
      return {
        ...state,
        [action.payload.accountId]: {
          ...state[action.payload.accountId],
          WITHDRAWAL_LIMIT: action.payload,
        },
      };
    case fetchDepositLimit.SUCCESS:
      return {
        ...state,
        [action.payload.accountId]: {
          ...state[action.payload.accountId],
          DEPOSIT_LIMIT: action.payload,
        },
      };
    case fetchAccountOverrides.SUCCESS:
      return {
        ...state,
        [action.payload.accountId]: {
          ...state[action.payload.accountId],
          ACCOUNT_OVERRIDES: action.payload,
        },
      };
    default:
      return state;
  }
}

export const getAllLimits = (state) => state.ach;
export const getLimits = createSelector(
  [getAllLimits, getSelectedAccountId],
  (limits, id) => limits[id],
);

export const getAccountOverrides = createSelector(
  [getAllLimits, getSelectedAccountId],
  (limits, id) => get(limits, [id, ACCOUNT_OVERRIDES], {}),
);
