import React from 'react';
import { Icon } from 'semantic-ui-react';
import Datetime from 'react-datetime';

class IconDatePicker extends React.Component {
  render() {
    return (
      <div className="iconDatePicker">
        <Datetime
          closeOnSelect={true}
          renderInput={this.renderInput}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
  renderInput(props) {
    function clear() {
      props.onChange({ target: { value: '' } });
    }
    return (
      <div>
        <Icon {...props} name="calendar alternate"></Icon>
      </div>
    );
  }
}

export default IconDatePicker;
