import React, { PureComponent } from 'react';
import {
  renderField,
  renderTextArea,
  renderDropdown,
  rules,
  normalization,
  fundsDesignation,
} from 'erisxkit/client';
import { Field, reduxForm } from 'redux-form';
import { Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

class DesignationFundsTransfer extends PureComponent {
  render = () => {
    const {
      valid,
      handleSubmit,
      assetTypesOptions,
      assetTypesLoading,
      confirmTransfer,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(confirmTransfer)}>
        <Field
          component={AccountSelectionContainer}
          id="accountId"
          label="Account"
          name="accountId"
          required
          validate={[rules.required]}
        />
        <Field
          name="assetType"
          id="assetType"
          placeholder="Select Asset Type"
          component={renderDropdown}
          label="Asset type"
          options={assetTypesOptions}
          loading={assetTypesLoading}
          required
          validate={[rules.required]}
        />
        <Form.Group>
          <Field
            component={renderDropdown}
            label="From Designation"
            name="fromDesignation"
            placeholder="Select the designation you would like to move."
            required
            options={fundsDesignation}
            validate={[rules.required]}
          />
          <Icon name="arrow circle right" color="grey" size="huge" />
          <Field
            component={renderDropdown}
            label="To Designation"
            name="toDesignation"
            placeholder="Select the designation you would like these funds to be deposited."
            required
            options={fundsDesignation}
            validate={[rules.required]}
          />
        </Form.Group>
        <Field
          component={renderField}
          id="amount"
          label="Amount"
          name="amount"
          parse={normalization.floatsOnly}
          placeholder="0.000"
          required
          validate={[rules.required]}
        />
        <Field
          component={renderTextArea}
          required
          label="Comment"
          name="comment"
          id="comment"
          placeholder="Comment on the transfer"
        />
        <Form.Button disabled={!valid} primary>
          Submit
        </Form.Button>
      </Form>
    );
  };
}

DesignationFundsTransfer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  assetTypesOptions: PropTypes.array.isRequired,
  assetTypesLoading: PropTypes.bool,
  confirmTransfer: PropTypes.func.isRequired,
};

DesignationFundsTransfer.defaultProps = {
  valid: false,
  assetTypesLoading: true,
};

export default reduxForm({
  form: 'DesignationFundsTransfer',
})(DesignationFundsTransfer);
