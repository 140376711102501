import { get } from 'lodash';
import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import {
  closingPrices,
  generalJournalCategories,
} from '../actions/manualEntryActions';
import { emarketBalanceAdjustments } from '../actions/accountsActions';
import {
  CLEAR_TRADE_ENTRY_CGMS_BUY_SIDE,
  CLEAR_TRADE_ENTRY_CGMS_SELL_SIDE,
} from '../constants/actionTypes';

export const republishBatch = createRoutine('REPUBLISH_BATCH');

export const fetchTradeEntryCgmsBuySide = createRoutine(
  'CGM_LIST_BY_ACCOUNT_BUY',
);
export const fetchTradeEntryCgmsSellSide = createRoutine(
  'CGM_LIST_BY_ACCOUNT_SELL',
);

export const resetCgmListBuySide = () => ({
  type: CLEAR_TRADE_ENTRY_CGMS_BUY_SIDE,
});
export const resetCgmListSellSide = () => ({
  type: CLEAR_TRADE_ENTRY_CGMS_SELL_SIDE,
});

const closingPricesReducer = handleAction(
  closingPrices.SUCCESS,
  (state, { payload }) => payload,
  {},
);

const emarketBalanceAdjustmentsReducer = handleAction(
  emarketBalanceAdjustments.SUCCESS,
  (state, { payload }) => payload,
  {},
);

const generalJournalCategoriesReducer = handleAction(
  generalJournalCategories.SUCCESS,
  (state, { payload }) => payload,
  [],
);

const tradeEntryCgmsReducer = handleActions(
  {
    [fetchTradeEntryCgmsBuySide.SUCCESS]: (state, { payload }) => ({
      ...state,
      cgmListBuySide: get(payload, 'cgmList', []).map((cgm) => ({
        key: cgm['cgmNumber'],
        value: cgm['cgmNumber'],
        text: cgm['cgmNumber'],
        description: cgm['description'],
      })),
    }),
    [fetchTradeEntryCgmsSellSide.SUCCESS]: (state, { payload }) => ({
      ...state,
      cgmListSellSide: get(payload, 'cgmList', []).map((cgm) => ({
        key: cgm['cgmNumber'],
        value: cgm['cgmNumber'],
        text: cgm['cgmNumber'],
        description: cgm['description'],
      })),
    }),
    [CLEAR_TRADE_ENTRY_CGMS_BUY_SIDE]: (state, { payload }) => ({
      ...state,
      cgmListBuySide: [],
    }),
    [CLEAR_TRADE_ENTRY_CGMS_SELL_SIDE]: (state, { payload }) => ({
      ...state,
      cgmListSellSide: [],
    }),
  },
  {},
);

export const getClosingPrices = (state) =>
  get(state, ['manualEntriesReducer', 'closingPrices', 'closingPrices'], []);
export const getClosingPricesCount = (state) =>
  get(state, ['manualEntriesReducer', 'closingPrices', 'count'], 0);

export const getEmarketBalanceAdjustments = (state) =>
  get(
    state,
    ['manualEntriesReducer', 'emarketBalanceAdjustments', 'adjustments'],
    [],
  );
export const getEmarketBalanceAdjustmentsCount = (state) =>
  get(state, ['manualEntriesReducer', 'emarketBalanceAdjustments', 'count'], 0);

export const getGeneralJournalCategories = (state) =>
  get(state, ['manualEntriesReducer', 'generalJournalCategories'], []);

export const getGeneralJournalCategoriesAsOptions = (state) =>
  getGeneralJournalCategories(state).map((category) => ({
    key: category,
    value: category,
    text: category
      .split('_')
      .map((word) =>
        word !== 'and'
          ? `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`
          : word,
      )
      .join(' '),
  }));

export const getTradeEntryCgmsBuySide = (state) =>
  get(state, ['manualEntriesReducer', 'tradeEntryCgms', 'cgmListBuySide'], []);

export const getTradeEntryCgmsSellSide = (state) =>
  get(state, ['manualEntriesReducer', 'tradeEntryCgms', 'cgmListSellSide'], []);

export default combineReducers({
  closingPrices: closingPricesReducer,
  emarketBalanceAdjustments: emarketBalanceAdjustmentsReducer,
  generalJournalCategories: generalJournalCategoriesReducer,
  tradeEntryCgms: tradeEntryCgmsReducer,
});
