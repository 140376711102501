import _ from 'lodash';
import React, { useState } from 'react';
import { Comment, Header, Input, Form } from 'semantic-ui-react';

const comment = (key, commentDetails) => (
  <Comment key={key}>
    <Comment.Avatar as="i" className="avatar icon user" />
    <Comment.Content>
      <Comment.Author as="a">{commentDetails.userName}</Comment.Author>
      <Comment.Metadata>
        <div>{commentDetails.lastUpdatedTime}</div>
      </Comment.Metadata>
      <Comment.Text>{commentDetails.comment}</Comment.Text>
    </Comment.Content>
  </Comment>
);

const RiskAlertsComments = ({ addComment, data, fetchData }) => {
  const [value, setValue] = useState('');

  const handleClick = (e) => {
    addComment(e, value);
    setValue('');
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const comments = data?.map((commentDetails, key) =>
    comment(key, commentDetails),
  );
  return (
    <Comment.Group style={{ width: '100%', maxWidth: '100%' }}>
      <Header as="h3">Comments</Header>
      {comments}
      <Form>
        <Form.Group inline>
          <Form.Field width="13">
            <Input
              id="comment"
              name="comment"
              onChange={(e) => setValue(e.target.value)}
              placeholder="Write a comment..."
              value={value}
            />
          </Form.Field>
          <Form.Button
            width="3"
            size="large"
            color={'teal'}
            id="send-comment"
            onClick={handleClick}
            disabled={!value}
            content="Send"
          >
            Send
          </Form.Button>
        </Form.Group>
      </Form>
    </Comment.Group>
  );
};

export default RiskAlertsComments;
