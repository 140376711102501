import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import ChangeLogs from '../../common/table/ExternalExpandableTable';
import changeLogsMetadata from '../../metadata/changeLogsMetadata';
import {
  changeLogs,
  getChangeLogsList,
  getChangeLogsCount,
  getChangeLogsEdits,
} from '../../reducers/systemAlertsReducer';
import { fetchUsersPromiseCreator } from '../../reducers/usersReducer';

const mapStateToProps = (state) => ({
  changeLogsList: getChangeLogsList(state),
  changeLogsEdits: getChangeLogsEdits(state),
  count: getChangeLogsCount(state),
  loading: createLoadingSelector(['CHANGE_LOGS'])(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchUsersPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      changeLogs,
    },
    dispatch,
  ),
});

// Subcomponent spits out raw (but prettified!) JSON
const SubComponent = (row) => (
  <Segment>
    <pre className="mono">{JSON.stringify(row.original, null, 2)}</pre>
  </Segment>
);

class SystemAlertsContainer extends Component {
  componentDidMount = () => {
    this.props.fetchUsersPromiseCreator({
      filter: [{ attr: 'disabled', op: 'eq', value: 'false' }],
      includeName: true,
    });
  };

  fetchData = (state) => {
    let filters = {};
    if (state.filtered.length) {
      filters = state.filtered.reduce((obj, item) => {
        const id = item.id === 'timestamp' ? 'date' : item.id;
        obj[id] = item.value;
        return obj;
      }, {});
    }
    this.props.changeLogs({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      ...filters,
    });
  };

  render = () => (
    <ChangeLogs
      title="changeLogs"
      className="-striped"
      data={this.props.changeLogsList}
      metadata={changeLogsMetadata}
      onFetchData={this.fetchData}
      count={this.props.count}
      loading={this.props.loading}
      viewRowDetails={this.viewRowDetails}
      SubComponent={SubComponent}
      noDataText="No Change Logs found."
      multipleExpandable
      pageSize={100}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemAlertsContainer);
