import useRoleValidation from '../../hooks/useRoleValidation';
import {
  TRUST_THRESHOLDS_READ_ONLY_ROLES,
  TRUST_THRESHOLDS_READ_WRITE_ROLES,
} from './TrustThresholds';

const useTrustTresholdsEnabled = () => {
  const read = useRoleValidation(TRUST_THRESHOLDS_READ_ONLY_ROLES);
  const write = useRoleValidation(TRUST_THRESHOLDS_READ_WRITE_ROLES);
  return read || write;
};

export default useTrustTresholdsEnabled;
