import { TABLE_INPUT_TYPES } from '../../common/table/constants';

const spanInterSpreadMetadata = (futuresProducts) => [
  {
    Header: 'Spread Priority',
    id: 'spreadPriority',
    accessor: 'spreadPriority',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Front Product',
    id: 'frontProduct',
    accessor: 'frontProduct',
    editInputType: TABLE_INPUT_TYPES.LIST,
    options: futuresProducts,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Front Tenor',
    id: 'frontTenor',
    accessor: 'frontTenor',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Front Quantity',
    id: 'frontQty',
    accessor: 'frontQty',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Back Product',
    id: 'backProduct',
    accessor: 'backProduct',
    editInputType: TABLE_INPUT_TYPES.LIST,
    options: futuresProducts,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Back Tenor',
    id: 'backTenor',
    accessor: 'backTenor',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Back Quantity',
    id: 'backQty',
    accessor: 'backQty',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Spread Credit',
    id: 'spreadCredit',
    accessor: 'spreadCredit',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];

export default spanInterSpreadMetadata;
