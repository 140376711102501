import React, { Fragment } from 'react';
import { renderDropdown, renderCheckbox, rules } from 'erisxkit/client';
import { Field } from 'redux-form';
import { Button, Form, Modal } from 'semantic-ui-react';
import FormProvider from '../../common/FormProvider';

export default ({
  onConfirm,
  handleSubmit,
  hideModal,
  emarketAccountProfileOptions,
  emarketFeeProfileOptions,
  emarketInstrumentProfileOptions,
  emarketFeeProfileId,
}) => (
  <FormProvider
    form="general-journal"
    onSubmit={handleSubmit}
    initialValues={{ emarketFeeProfileId: emarketFeeProfileId }}
  >
    {({ handleSubmit, valid }) => (
      <Fragment>
        <Modal.Header>
          Create E-Market Account
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={hideModal}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit(onConfirm)}>
            <Field
              name="emarketFeeProfileId"
              id="emarketFeeProfileId"
              component={renderDropdown}
              label="Fee Profile"
              placeholder="Fee Profile for account"
              options={emarketFeeProfileOptions}
              // validate={[rules.required]}
            />
            <Field
              name="active"
              component={renderCheckbox}
              label="Activate E-Market account upon creation"
            />
            <Modal.Actions className="modal-form-actions">
              <Form.Button onClick={() => hideModal()} content="Close" />
              {/* TODO: add  disabled={!valid} back in after TME change is permanent */}
              <Form.Button primary id="confirm" content="Create" />
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Fragment>
    )}
  </FormProvider>
);
