import React from 'react';
import { isEmpty } from 'lodash';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const Options = ({ groupProps, loading, refreshProps, toggleProps }) => (
  <Button.Group {...groupProps}>
    {!isEmpty(toggleProps) ? (
      <Button
        icon
        id="toggle"
        labelPosition="left"
        onClick={() => toggleProps.toggle()}
        {...toggleProps}
      >
        <Icon
          name={toggleProps.toggled ? 'check square outline' : 'square outline'}
        />
        {toggleProps.text || 'Toggle'}
      </Button>
    ) : null}
    {!isEmpty(refreshProps) ? (
      <Button
        icon
        id="refresh"
        labelPosition={refreshProps.text ? 'left' : ''}
        disabled={loading}
        onClick={() => refreshProps.refresh()}
        {...refreshProps}
      >
        <Icon name="refresh" loading={loading} {...refreshProps.icon} />
        {refreshProps.text}
      </Button>
    ) : null}
  </Button.Group>
);

Options.propTypes = {
  groupProps: PropTypes.objectOf(PropTypes.string),
  loading: PropTypes.bool,
  refreshProps: PropTypes.objectOf(PropTypes.any),
  toggleProps: PropTypes.objectOf(PropTypes.any),
};

Options.defaultProps = {
  groupProps: {},
  loading: false,
  refreshProps: {},
  toggleProps: {},
};

export default Options;
