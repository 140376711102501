import React, { Fragment } from 'react';
import {
  renderField,
  renderDropdown,
  rules,
  normalization,
} from 'erisxkit/client';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { titleCase } from 'change-case';

const CreateCollateralProduct = ({
  assetTypesOptions,
  assetTypesLoading,
  baseAssetType,
  confirmAction,
  contractSize,
  description,
  haircut,
  handleSubmit,
  hideModal,
  initialize,
  pristine,
  symbol,
  type,
  valid,
}) => {
  if (pristine && type === 'update') {
    // on update, only initialize with given form fields, not redux-form scaffolding
    initialize(
      {
        baseAssetType,
        contractSize,
        description,
        haircut,
        symbol,
        type,
      },
      { keepDirty: true },
    ); // flip pristine to false after initialize
  }

  return (
    <Fragment>
      <Modal.Header>
        {titleCase(type)} Collateral Product{' '}
        <Button
          className="pull-right"
          circular
          size="mini"
          icon="times"
          onClick={() => hideModal()}
        />
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit(confirmAction)}>
          <Field
            name="symbol"
            id="symbol"
            component={renderField}
            label="Product code"
            placeholder="e.g. T-Bill"
            validate={[rules.required]}
            required
          />
          <Field
            name="description"
            id="description"
            component={renderField}
            label="Full name of product"
            placeholder="e.g. Treasury Bill"
            validate={[rules.required]}
            required
          />
          <Field
            name="contractSize"
            id="contractSize"
            component={renderField}
            label="Contract Size"
            placeholder="1.0"
            validate={[rules.required]}
            required
          />
          <Field
            name="baseAssetType"
            id="baseAssetType"
            component={renderDropdown}
            label="Base asset type"
            loading={assetTypesLoading}
            options={assetTypesOptions}
            required
            validate={[rules.required]}
          />
          <Field
            name="haircut"
            id="haircut"
            component={renderField}
            label="Haircut"
            placeholder="Cannot be larger than 100%"
            parse={normalization.floatsOnly}
            validate={[rules.required]}
            required
          />
          <Modal.Actions className="modal-form-actions">
            <Form.Button onClick={() => hideModal()}>Close</Form.Button>
            <Form.Button primary disabled={!valid || pristine} id={type}>
              {titleCase(type)} collateral product
            </Form.Button>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Fragment>
  );
};

CreateCollateralProduct.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  type: PropTypes.string,
  valid: PropTypes.bool,
};

CreateCollateralProduct.defaultProps = {
  pristine: false,
  type: 'create',
  valid: false,
};

export default reduxForm({
  form: 'CreateCollateralProduct',
})(CreateCollateralProduct);
