export default [
  'logPath',
  'logLevel',
  'agingRate',
  'pidFile',
  'dbName',
  'dbHost',
  'persistInSafeMode',
  'rmqHost',
  'rmqPort',
  'mongoReplicaSet',
];
