import React from 'react';
import {
  ParticipantManagementTableRow,
  ParticipantRow,
  isCAR,
  isCGM,
  isParticipant,
} from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import ParticipantActions from './ParticipantActions';
import CgmActions from './CgmActions';
import CarActions from './CarActions';

const Actions: React.FC<{
  participant: ParticipantRow;
  rowData: ParticipantManagementTableRow;
  memberId: string;
  fetchData: (params: Record<string, string>) => void;
}> = ({ rowData, participant, memberId, fetchData }) => {
  if (isParticipant(rowData))
    return (
      <ParticipantActions
        fetchData={fetchData}
        memberId={memberId}
        participant={rowData}
      />
    );

  if (isCGM(rowData))
    return (
      <CgmActions
        fetchData={fetchData}
        memberId={memberId}
        cgm={rowData}
        participant={participant}
      />
    );

  if (isCAR(rowData))
    return (
      <CarActions
        fetchData={fetchData}
        memberId={memberId}
        car={rowData}
        participant={participant}
      />
    );

  return null;
};

export default Actions;
