import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'erisxkit/client';
import TextButtons from '../../../common/TextButtons';
import { CLEARING_REQUEST_ACTION } from '../../../constants/modalTypes';
import colors from '../../../constants/colors';
import {
  ClearingRequestAction,
  FCMClearingRequest,
} from '../../../ts/models/FCMClearing.model';

const ClearingRequestActions: React.FC<{
  request: FCMClearingRequest;
  fetchData: () => void;
}> = ({ request, fetchData }) => {
  const dispatch = useDispatch();
  const isPending = request?.status === 'pending';

  const onActionClick = useCallback(
    (request: FCMClearingRequest, action: ClearingRequestAction) => {
      dispatch(
        showModal(CLEARING_REQUEST_ACTION, {
          request,
          action,
          fetchData
        }),
      );
    },
    [dispatch],
  );

  const buttons = useMemo(() => {
    return [
      {
        text: 'Approve',
        onClick: () => onActionClick(request, 'approve'),
        fontSize: '14',
      },
      {
        text: 'Reject',
        onClick: () => onActionClick(request, 'reject'),
        fontSize: '14',
        color: colors.error2,
      },
    ];
  }, [request, onActionClick]);

  return isPending ? <TextButtons buttons={buttons} /> : null;
};

export default ClearingRequestActions;
