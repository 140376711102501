import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import XTab from '../../common/tabs/XTab';
import OverviewContainer from './OverviewContainer';

const panes = [
  {
    url: 'overview',
    menuItem: 'Overview',
    render: () => <OverviewContainer />,
  },
];

const ConfigurationContainer = () => (
  <Fragment>
    <Header as="h1" dividing>
      Risk Engine
    </Header>
    <XTab panes={panes} />
  </Fragment>
);

export default ConfigurationContainer;
