import React from 'react';
import { Form, Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const RepublishBatch = ({ handleChange, incrementalId, republishBatch }) => (
  <Form>
    <Form.Field>
      <label> Enter the Incremental Id to republish</label>
      <Input
        name="incrementalId"
        onChange={handleChange}
        value={incrementalId}
        action={
          <Button
            content="Republish batch "
            onClick={republishBatch}
            disabled={!incrementalId}
            id="republishBatch"
          />
        }
      />
    </Form.Field>
  </Form>
);

RepublishBatch.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default RepublishBatch;
