import { format } from 'erisxkit/client';

const BID = 'BID';
const ASK = 'ASK';
export default [
  {
    Header: 'CGM',
    id: 'cgm',
    accessor: 'cgm',
  },
  {
    Header: 'Symbol',
    id: 'symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Exp Order',
    id: 'expirationOrder',
    accessor: 'expirationOrder',
  },
  {
    Header: 'Long Qty',
    id: 'long_qty',
    accessor: ({ longQty = '' }) => (longQty ? format(longQty) : ''),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Short Qty',
    id: 'short_qty',
    accessor: ({ shortQty = '' }) => (shortQty ? `-${format(shortQty)}` : ''),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Net Qty',
    id: 'net_qty',
    accessor: ({ netQty = '' }) => (netQty ? format(netQty) : ''),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Span Margin',
    id: 'total_span_requirement',
    accessor: 'totalSpanRequirement',
  },
  {
    Header: 'Span Spread',
    id: 'total_spread_requirement',
    accessor: 'totalSpreadRequirement',
  },
  {
    Header: 'Variation Margin',
    id: 'variation_margin',
    accessor: 'variationMargin',
  },
  {
    Header: 'Fund. Amt',
    id: 'cdfFundingAmount',
    accessor: 'cdfFundingAmount',
  },
  {
    Header: 'Reference Price',
    id: 'reference_price',
    accessor: ({ referencePrice }) => format(referencePrice),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Last Settlement Price',
    id: 'last_settlement_price',
    accessor: 'lastSettlementPrice',
  },
  {
    Header: 'Avg Long Trade Price',
    id: 'long_avg_price',
    accessor: 'longAvgPrice',
  },
  {
    Header: 'Avg Short Trade Price',
    id: 'short_avg_price',
    accessor: 'shortAvgPrice',
  },
  {
    Header: 'Settlement Type',
    id: 'settlement_type',
    accessor: 'settlementType',
  },
  {
    Header: 'Member',
    id: 'member',
    accessor: 'member',
  },
  {
    Header: 'Snapshot ID',
    id: 'snapshot_id',
    accessor: 'snapshotId',
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
];
