import { startCase, get } from 'lodash';
import { connect } from 'react-redux';
import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { Segment, Loader } from 'semantic-ui-react';
import EnvPromise from '../../config/env';
import history from '../../constants/history';
import {
  fetchMembers,
  getSelectedMemberId,
  getSelectedMember,
} from '../../reducers/membersReducer';
import ClearingMemberAccountsContainer from '../../containers/ClearingMemberAccounts/ClearingMemberAccountsContainer';
import MemberUserIndexContainer from '../../containers/MemberUsers/MemberUserIndexContainer';
import DetailsHeader from '../../common/components/DetailsHeader';
import XTab from '../../common/tabs/XTab';
import LinkedMemberAssetAccountsContainer from './LinkedMemberAssetAccountsContainer';
import AuthorizationsIPsContainer from './AuthorizationsIPsContainer';
import BackButton from '../../common/components/BackButton';
import CopyInput from '../../common/components/CopyInput';
import { VENDOR_MEMBER, FIRM_MEMBER, FCM } from '../../constants/memberTypes';
import { formatDateLocal, formatTimeLocal } from '../../utils/time';
import UBOContainer from './CreateMember/UBOContainer';
import memberSteps from '../../constants/createMemberSteps';
import {
  INVESTMENT_ADVISOR_MEMBER,
  INTERMEDIARY,
} from '../../constants/memberTypes';
import { getNameFromMemberTypeCode, getStatusName } from '../../utils/methods';
import {
  authorizedIpRoles,
  readAccessFCMRoles,
  writeAccessFCMRoles,
} from '../../reducers/employeesReducer';
import { getCurrentUserRoles } from '../../reducers/usersReducer';
import * as statuses from '../../constants/statuses';
import FCMMemberContainer from './FCMMemberContainer';
import FCMClearingRequests from './FCMClearingRequests/FCMClearingRequests';
import ParticipantManagement from './ParticipantManagement/ParticipantManagement';
import BlockTradePermissions from './BlockTradePermissions/BlockTradePermissions';
import BlockTradeRequests from './BlockTradeRequests/BlockTradeRequests';

const panes = (
  updateMember,
  member,
  userRoles,
  blockTradesEnabled,
  exchangeMemberEnabled,
) => {
  const clearingAccounts = {
    url: 'clearing_accounts',
    menuItem: 'Clearing Accounts',
    render: ({ selectedMember }) => (
      <ClearingMemberAccountsContainer
        detailsView
        memberId={selectedMember.memberId}
        isInvestmentAdvisor={
          selectedMember.type === INVESTMENT_ADVISOR_MEMBER.type
        }
        memberUsers={selectedMember.memberUsers}
        userAccounts={selectedMember.ledgerAccounts}
        addAccount={() =>
          updateMember({ step: memberSteps[member.type].LEDGER_ACCOUNTS })
        }
      />
    ),
  };
  const panesList = [
    ...(FIRM_MEMBER.type === member.type
      ? [
          {
            url: 'ubo',
            menuItem: 'UBO',
            render: ({ selectedMember }) => (
              <UBOContainer
                ubos={selectedMember.ultimateBeneficialOwners}
                memberId={selectedMember.memberId}
                addUbo={() =>
                  updateMember({ step: memberSteps[member.type].UBO })
                }
                memberDetailsView
              />
            ),
          },
        ]
      : []),
    {
      url: 'member_users',
      menuItem: 'Member Users',
      render: ({ selectedMember }) => (
        <MemberUserIndexContainer
          userIds={selectedMember.memberUsers}
          addMember={() =>
            updateMember({ step: memberSteps[member.type].MEMBER_USERS })
          }
        />
      ),
    },
    {
      url: 'ssi',
      menuItem: 'Linked Asset Accounts (SSI)',
      render: ({ selectedMember }) => (
        <LinkedMemberAssetAccountsContainer
          memberId={selectedMember.memberId}
          memberDetailsView
          updateMember={() =>
            updateMember({ step: memberSteps[member.type].SSI })
          }
        />
      ),
    },
    member.clearingMemberType === INTERMEDIARY.code &&
      userRoles.some((item) => authorizedIpRoles.includes(item)) && {
        url: 'authorized_ips',
        menuItem: 'Authorized IPs',
        render: ({ selectedMember }) => (
          <AuthorizationsIPsContainer selectedMember={selectedMember} />
        ),
      },
    member.clearingMemberType !== FCM.code &&
      exchangeMemberEnabled && {
        url: 'fcm_clearing',
        menuItem: 'FCM Clearing',
        render: ({ selectedMember }) => (
          <FCMMemberContainer selectedMember={selectedMember} />
        ),
      },
    ...(member.clearingMemberType === FCM.code &&
    exchangeMemberEnabled &&
    userRoles.some((item) =>
      [...writeAccessFCMRoles, ...readAccessFCMRoles].includes(item),
    )
      ? [
          {
            url: 'fcm_clearing_requests',
            menuItem: 'FCM Clearing Requests',
            render: ({ selectedMember }) => (
              <FCMClearingRequests selectedMember={selectedMember} />
            ),
          },
          {
            url: 'participant_management',
            menuItem: 'Participants',
            render: ({ selectedMember }) => (
              <ParticipantManagement selectedMember={selectedMember} />
            ),
          },
        ]
      : []),
    ...(blockTradesEnabled
      ? [
          {
            url: 'block_trade_permissions',
            menuItem: 'Block Trade Permissions',
            render: ({ selectedMember }) => (
              <BlockTradePermissions selectedMember={selectedMember} />
            ),
          },
          {
            url: 'block_trade_requests',
            menuItem: 'Block Trade Requests',
            render: ({ selectedMember }) => (
              <BlockTradeRequests selectedMember={selectedMember} />
            ),
          },
        ]
      : []),
  ];
  return (VENDOR_MEMBER.type === member.type ? [] : [clearingAccounts]).concat(
    panesList,
  );
};

const mapStateToProps = (state) => ({
  selectedMemberId: getSelectedMemberId(state),
  selectedMember: getSelectedMember(state),
  userRoles: getCurrentUserRoles(state),
});

const mapDispatchToProps = {
  fetchMembers,
};

class MemberDetailsContainer extends Component {
  state = {
    enableBlockTrades: '',
    enableExchangeMember: '',
  };
  componentDidMount = () => {
    this.props.fetchMembers({
      filter: [{ attr: 'id', value: this.props.selectedMemberId, op: 'eq' }],
    });
    EnvPromise.then(({ enableBlockTrades, enableExchangeMember }) =>
      this.setState({
        enableBlockTrades: enableBlockTrades === 'true',
        enableExchangeMember: enableExchangeMember === 'true',
      }),
    );
  };

  updateMember = (args = {}) => {
    history.push('update_member', { step: args.step });
  };

  formatAddress = (selectedMember = {}) => {
    const { address1, address2, city, locationState, postalCode, country } =
      selectedMember;
    if (
      address1 ||
      address2 ||
      city ||
      locationState ||
      postalCode ||
      country
    ) {
      return `${address1 || ''} ${address2 || ''} ${city || ''}, ${
        locationState || ''
      } ${postalCode || ''} ${country || ''}`;
    }
    return <i>No address provided.</i>;
  };

  render = () => {
    const { selectedMember = {} } = this.props;

    const {
      status,
      clearingMemberType,
      name,
      firmCode,
      lei,
      phoneNumber,
      emarketOrganizationName,
      createdAt,
      memberId,
      onboardingStartedSpot,
      documentsCompleteSpot,
      onboardingStartedFutures,
      documentsCompleteFutures,
      allowCollateral,
      allowFutures,
      allowSpot,
      taxId,
      ecp,
      ellipticLink,
      statusDate,
    } = selectedMember;
    const itemDetails = [
      {
        status: getStatusName(status),
        clearingMemberType: getNameFromMemberTypeCode(clearingMemberType),
        contactName: (
          <CopyInput
            id="contactName"
            className="compact"
            value={selectedMember.name}
          />
        ),
        firmCode,
        lei,
        phoneNumber: phoneNumber || <i>No phone number provided.</i>,
        emarketOrganizationName: emarketOrganizationName || (
          <i>No E-Market organization.</i>
        ),
        address: this.formatAddress(selectedMember),
        createdAt: formatTimeLocal(createdAt),
        memberId: (
          <CopyInput id="memberId" className="compact" value={memberId} />
        ),
        taxId,
        ECP: ecp === undefined ? ecp : ecp ? 'Yes' : 'No',
        ...(selectedMember.ellipticLink && {
          ellipticLink: (
            <a href={selectedMember.ellipticLink} target="_blank">
              Customer Transactions
            </a>
          ),
        }),
      },
      {
        ...{
          statusAsOfDate: formatDateLocal(statusDate),
        },
        ...(allowSpot && {
          onboardingStartedSpot: formatDateLocal(onboardingStartedSpot),
          documentsCompleteSpot: formatDateLocal(documentsCompleteSpot),
        }),
        ...(allowFutures && {
          onboardingStartedFutures: formatDateLocal(onboardingStartedFutures),
          documentsCompleteFutures: formatDateLocal(documentsCompleteFutures),
        }),
      },
    ];

    const labels = {
      status: selectedMember.status === statuses.AC.code,
      futures: selectedMember.allowFutures,
      spot: selectedMember.allowSpot,
      collateral: selectedMember.allowCollateral,
      futuresBlock: selectedMember.allowFuturesBlock,
      spotBlock: selectedMember.allowSpotBlock,
      settlementService: selectedMember.allowSettlementService,
    };

    return (
      <Fragment>
        {selectedMember.memberId ? (
          <Fragment>
            <BackButton
              header={selectedMember.name}
              headerLabels={[
                selectedMember.type && {
                  content: startCase(selectedMember.type),
                  size: 'small',
                  tag: true,
                },
              ]}
            />
            <DetailsHeader
              loading={!selectedMember.memberId}
              item={itemDetails}
              labels={labels}
              editable
              onEdit={() => this.updateMember()}
            />
            <XTab
              panes={panes(
                this.updateMember,
                selectedMember,
                this.props.userRoles,
                this.state.enableBlockTrades,
                this.state.enableExchangeMember,
              )}
              loading={!selectedMember.memberId}
              selectedMember={this.props.selectedMember}
            />
          </Fragment>
        ) : (
          <Segment padded="very" basic className="center-vertical">
            <Loader active content="Fetching selected member user..." />
          </Segment>
        )}
      </Fragment>
    );
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberDetailsContainer),
);
