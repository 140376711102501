import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';

// This component can either render the value (string) passed to it as prop
// or it can render a react node (renderValue). However, only what's passed as value
// is what's going to be copied to the clipboard
const CopyField = ({ value, renderValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimeoutId] = useState('');

  const copyToClipboard = (text) => {
    navigator?.clipboard?.writeText(text);
  };

  const handleOpen = () => {
    setIsOpen(true);
    setTimeoutId(
      setTimeout(() => {
        setIsOpen(false);
      }, 1500),
    );
  };

  const handleClose = () => {
    setIsOpen(false);
    clearTimeout(timeoutId);
  };

  return (
    <div style={{ display: 'inline' }}>
      {renderValue || value}
      <Popup
        // we can't copy text from fields without an ID and value
        trigger={
          value && (
            <Icon
              link
              name="copy outline"
              onClick={() => copyToClipboard(value)}
            />
          )
        }
        content="Copied!"
        on="click"
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        position="bottom right"
        pointing
        inverted
      />
    </div>
  );
};

CopyField.defaultProps = {
  renderValue: null,
};

CopyField.propTypes = {
  value: PropTypes.string.isRequired,
  renderValue: PropTypes.node,
};

export default CopyField;
