import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import { Button, Label, Icon, Input } from 'semantic-ui-react';
import EmployeeSelectionContainer, {
  UNASSIGNED_EMPLOYEE_USER_ID,
  PENDING_CLIENT_ACTION_EMPLOYEE_USER_ID,
} from '../../common/containers/EmployeeSelectionContainer';
import { employee } from '../../constants/userTypes';
import history from '../../constants/history';
import { useSelector } from 'react-redux';
import { employeeToOptions } from '../../utils/methods';
import { getEmployeesCollection } from '../../reducers/employeesReducer';
import { getUserCollection } from '../../reducers/usersReducer';
import UserSelectionContainer from '../../common/containers/UserSelectionContainer';
import { useEffect } from 'react/cjs/react.production.min';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows = [], setFilter, id },
  defaultValues,
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);
  const [tempFilter, setTempFilter] = useState([]);

  const getBeginTime = (index) => {
    const beginTimeValue =
      tempFilter[index] ||
      filterValue[index] ||
      _.get(defaultValues, index.toString(), undefined);
    return beginTimeValue ? beginTimeValue * 1000 : beginTimeValue;
  };
  return (
    <div>
      <Datetime
        inputProps={{ placeholder: 'Begin time' }}
        closeOnSelect
        value={getBeginTime(0)}
        onChange={(e) => {
          let val = e;
          if (e._d) {
            val = e.unix();
          }
          setTempFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <Datetime
        inputProps={{ placeholder: 'End Time' }}
        closeOnSelect
        value={getBeginTime(1)}
        onChange={(e) => {
          let val = e;
          if (e._d) {
            val = e.unix();
          }
          setTempFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
      <Button
        content="Apply"
        compact
        onClick={() => {
          setFilter(tempFilter);
        }}
      />
    </div>
  );
}

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5px;
`;

const StyledLabel = styled(Label)`
  width: fit-content;
`;

const StyledSelect = styled.select`
  max-height: 20px;
  margin-right: 10px;
`;

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, options = [] },
}) {
  const [tempFilter, setTempFilter] = useState('');

  const usesObjectOptions = useMemo(
    () => options.some((opt) => _.isObject(opt)),
    [options],
  );

  const getOptionText = useCallback(
    (option) => {
      if (usesObjectOptions) return _.get(option, 'text', '-');
      return option;
    },
    [usesObjectOptions],
  );

  const getOptionValue = useCallback(
    (option) => {
      if (usesObjectOptions) return _.get(option, 'value', '');
      return option;
    },
    [usesObjectOptions],
  );

  const getFilterValueLabel = useCallback(
    (value) => {
      if (usesObjectOptions) {
        return _.get(
          options.find((option) => _.get(option, 'value', '') === value),
          'text',
          '-',
        );
      }
      return value;
    },
    [usesObjectOptions, options],
  );

  // Render a multi-select box
  return (
    <FilterWrapper>
      {filterValue ? (
        <StyledLabel>
          {getFilterValueLabel(filterValue)}
          <Icon name="delete" onClick={() => setFilter('')} />
        </StyledLabel>
      ) : null}
      <Row>
        <StyledSelect
          value={tempFilter || filterValue}
          onChange={(e) => {
            setTempFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={getOptionValue(option)}>
              {getOptionText(option)}
            </option>
          ))}
        </StyledSelect>
        <Button
          content="Apply"
          compact
          onClick={() => {
            setFilter(tempFilter);
          }}
        />
      </Row>
    </FilterWrapper>
  );
}

const getLabel = (filterValue, tempFilter, employees) => {
  switch (filterValue) {
    case UNASSIGNED_EMPLOYEE_USER_ID:
      return <span>Unassigned</span>;
    case PENDING_CLIENT_ACTION_EMPLOYEE_USER_ID:
      return <span>Pending Client Action</span>;
    default:
      return employees[tempFilter || filterValue]?.email;
  }
};

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilterWithUrl({
  column: { filterValue, setFilter, preFilteredRows, id, options = [] },
}) {
  filterValue = filterValue === 'false' ? 'active' : filterValue;
  filterValue = filterValue === 'true' ? 'disabled' : filterValue;
  const [tempFilter, setTempFilter] = useState('');
  // Render a multi-select box
  let activeFilter;

  /* Update the filter status state based on the url parameter set */
  if (history.location.search.includes('disabled')) {
    // Set in the search box the current status for the first time
    if (history.location.search.includes('disabled=true')) {
      activeFilter = 'disabled';
    }
    if (history.location.search.includes('disabled=false')) {
      activeFilter = 'active';
    }
  }

  const _filter = (value) => {
    if (id === 'status' || id === 'disabled') {
      if (value === 'all') {
        // if it is all, remove the disabled parameter
        history.replace({
          ...history.location,
          search: history.location.search
            .replace('?disabled=true', '')
            .replace('?disabled=false', ''),
        });
      } else {
        // if it is disabled or enabled, put the right parameter value
        history.replace({
          ...history.location,
          search: value === 'disabled' ? `disabled=true` : `disabled=false`,
        });
      }
      setFilter(value === 'all' ? undefined : value);
    }
  };
  return (
    <div>
      {filterValue || activeFilter ? (
        <Label>
          {filterValue || activeFilter}
          <Icon name="delete" onClick={() => _filter('all')} />
        </Label>
      ) : null}
      <select
        value={tempFilter || filterValue || activeFilter}
        onChange={(e) => {
          setTempFilter(e.target.value || undefined);
        }}
      >
        <option value="all">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      <Button
        content="Apply"
        compact
        onClick={() => {
          _filter(tempFilter);
        }}
      />
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function EmployeeColumnFilter({ column: { filterValue, setFilter } }) {
  const [tempFilter, setTempFilter] = useState('');
  const employees = useSelector(getEmployeesCollection);
  const arrEmployees = Object.entries(employees || {}).map(
    ([key, value]) => value,
  );
  const options = employeeToOptions(arrEmployees);
  // Render a multi-select box
  return (
    <div>
      {!_.isEmpty(filterValue) ? (
        <Label>
          {getLabel(filterValue, tempFilter, employees)}
          <Icon name="delete" onClick={() => setFilter('')} />
        </Label>
      ) : null}
      <EmployeeSelectionContainer
        additionalFilters={[
          { attr: 'type', op: 'eq', value: employee },
          { attr: 'disabled', op: 'eq', value: 'false' },
        ]}
        name="assignedEmployeeUserId"
        placeholder="Select assigned"
        className="ddlOnboardingEmployees-width"
        label=""
        input={{
          onChange: (e) => {
            setTempFilter(e);
          },
        }}
        options={options || []}
      />
      <Button
        content="Apply"
        compact
        onClick={() => {
          setFilter(tempFilter);
        }}
      />
    </div>
  );
}

const checkValueNamesFilter = (filterValue, value) =>
  filterValue[value] && filterValue[value].length > 0 ? filterValue[value] : '';
// This is a custom filter UI for selecting
// a unique option from a list
export function UsersColumnFilter({ onChange }) {
  const [tempFilter, setTempFilter] = useState('');
  const users = useSelector(getUserCollection);
  const arrUsers = Object.entries(users || {}).map(([key, value]) => value);
  const options = employeeToOptions(arrUsers);

  React.useEffect(() => {
    if (tempFilter !== '') {
      onChange(tempFilter);
    }
  }, [tempFilter]);

  return (
    <div>
      <UserSelectionContainer
        additionalFilters={[{ attr: 'disabled', op: 'eq', value: 'false' }]}
        name="assignedUserUserId"
        placeholder="Select assigned"
        label=""
        className="ddlOnboardingUsers-width"
        input={{
          onChange: (e) => {
            setTempFilter(e);
          },
        }}
        showAllOption
        options={options || []}
      />
    </div>
  );
}

export function NamesFilter({
  column: { filterValue = {}, preFilteredRows, setFilter, id },
}) {
  const [tempFilter, setTempFilter] = useState({
    firstName: checkValueNamesFilter(filterValue, 'firstName'),
    middleName: checkValueNamesFilter(filterValue, 'middleName'),
    lastName: checkValueNamesFilter(filterValue, 'lastName'),
  });

  return (
    <div>
      {filterValue.firstName && filterValue.firstName !== '' ? (
        <Label>
          {filterValue.firstName}
          <Icon
            name="delete"
            onClick={() =>
              setFilter({
                ...filterValue,
                firstName: '',
              })
            }
          />
        </Label>
      ) : null}
      <Input
        name="name"
        type="text"
        placeholder="Name"
        onChange={(e) => {
          setTempFilter({
            ...tempFilter,
            firstName: e.target.value,
          });
        }}
      />
      {filterValue.middleName && filterValue.middleName !== '' ? (
        <Label>
          {filterValue.middleName}
          <Icon
            name="delete"
            onClick={() =>
              setFilter({
                ...filterValue,
                middleName: '',
              })
            }
          />
        </Label>
      ) : null}
      <Input
        name="middleName"
        type="text"
        placeholder="Middle Name"
        onChange={(e) => {
          setTempFilter({
            ...tempFilter,
            middleName: e.target.value,
          });
        }}
      />
      {filterValue.lastName && filterValue.lastName !== '' ? (
        <Label>
          {filterValue.lastName}
          <Icon
            name="delete"
            onClick={() =>
              setFilter({
                ...filterValue,
                lastName: '',
              })
            }
          />
        </Label>
      ) : null}
      <Input
        name="lastName"
        type="text"
        placeholder="Last Name"
        onChange={(e) => {
          setTempFilter({
            ...tempFilter,
            lastName: e.target.value,
          });
        }}
      />

      <Button
        content="Apply"
        compact
        onClick={() => {
          setFilter(tempFilter);
        }}
      />
    </div>
  );
}

export function DefaultColumnNoDelayFilter({
  column: { filterValue, preFilteredRows = [], setFilter },
}) {
  const [tempFilter, setTempFilter] = useState('');

  const handleApply = (filter) => {
    setFilter(filter || tempFilter);
  };

  return (
    <div>
      {filterValue ? (
        <Label>
          {filterValue}
          <Icon name="delete" onClick={() => setFilter('')} />
        </Label>
      ) : null}
      <input
        autoFocus
        value={tempFilter || ''}
        onChange={({ target = {} }) => {
          setTempFilter(target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder="Search records..."
        onKeyDown={(e) => e.key === 'Enter' && handleApply()}
      />
      <Button content="Apply" compact onClick={() => handleApply()} />
    </div>
  );
}

export function DayColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const [tempFilter, setTempFilter] = useState('');

  return (
    <div>
      <Datetime
        dateFormat="YYYY-MM-DD"
        timeFormat={false}
        inputProps={{ placeholder: 'Select Date' }}
        closeOnSelect
        value={tempFilter || filterValue}
        onChange={(e) => {
          const val = e.format('YYYY-MM-DD');
          setTempFilter(val);
        }}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      <Button
        content="Apply"
        compact
        onClick={() => {
          setFilter(tempFilter || filterValue);
        }}
      />
    </div>
  );
}

export function AccountColumnFilter({ column: { filterValue, setFilter } }) {
  const [tempFilter, setTempFilter] = useState('');

  // Render a multi-select box
  return (
    <div>
      {!_.isEmpty(filterValue) ? (
        <Label>
          {/*getLabel(filterValue, tempFilter, employees)*/}
          {filterValue.label}
          <Icon name="delete" onClick={() => setFilter('')} />
        </Label>
      ) : null}

      <AccountSelectionContainer
        label=""
        placeholder="Type an Account"
        onChange={(event, { options, value }) => {
          const label = options.find((x) => x.value === value)?.text;
          setFilter({ value, label });
        }}
      />
    </div>
  );
}
