import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { string } from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import cogoToast from 'cogo-toast';

import XTable7 from '../../common/table/XTable7';
import {
  fetchRiskConfigurations,
  createRiskConfigPromise,
  updateRiskConfigPromise,
  deleteRiskConfigPromise,
  getRiskConfigSectionCount,
  getRiskConfigSectionData,
  RISK_CONFIGURATIONS,
} from '../../reducers/riskConfigurationsReducer';
import objectDiff from '../../utils/objectDiff';
import usePaginatedFetch from '../../hooks/usePaginatedFetch';

const RiskConfigTable = ({
  configSection,
  metadata,
  editable,
  hideActions,
  isAddNewDisabled,
  template,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(createLoadingSelector([RISK_CONFIGURATIONS]));
  const data = useSelector((state) =>
    getRiskConfigSectionData(state, configSection),
  );
  const count = useSelector((state) =>
    getRiskConfigSectionCount(state, configSection),
  );

  const fetchReports = usePaginatedFetch(fetchRiskConfigurations, {
    configSection,
  });

  const onApply = useCallback(async (changes) => {
    try {
      const { create, remove, update } = changes;
      if (!isEmpty(create)) {
        await createRiskConfigPromise(
          { configSection, items: create },
          dispatch,
        );
      }
      if (!isEmpty(update)) {
        // For updates, we only send the keys to update with their new value, not the whole object
        const items = update.map((change) =>
          objectDiff(change?.original, change?.updated, ['uuid']),
        );
        await updateRiskConfigPromise({ configSection, items }, dispatch);
      }
      if (!isEmpty(remove)) {
        // For removals we only send the uuids
        const items = remove.map((r) => ({ uuid: get(r, 'uuid', '') }));
        await deleteRiskConfigPromise({ configSection, items }, dispatch);
      }
    } catch (e) {
      cogoToast.error('Could not apply changes');
    }
  }, []);

  return (
    <XTable7
      title={configSection}
      metadata={metadata}
      data={data}
      count={count}
      loading={loading}
      fetchData={fetchReports}
      editable={editable}
      onSaveChanges={onApply}
      editActionsToHide={hideActions}
      isAddNewButtonDisabled={isAddNewDisabled}
      newRowTemplate={template}
    />
  );
};

RiskConfigTable.defaultProps = {
  configSection: '',
  metadata: [],
  editable: false,
  hideActions: [],
  isAddNewDisabled: undefined,
  template: {},
};

RiskConfigTable.propTypes = {
  configSection: PropTypes.string,
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      header: PropTypes.string.isRequired,
    }),
  ),
  editable: PropTypes.bool,
  hideActions: PropTypes.arrayOf(PropTypes.string),
  isAddNewDisabled: PropTypes.func,
  template: PropTypes.objectOf(PropTypes.any),
};

export default RiskConfigTable;
