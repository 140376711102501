import { call, put, takeLatest } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { createSaga } from '../utils/createSaga';
import * as actions from '../reducers/manualOnboardingReducer';
import api, * as urls from '../api';

//* Emarket onboarding sagas */
function* createAndLinkClearingAccountRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Creating and linking clearing account...',
    { hideAfter: 0 },
  );
  try {
    let linkedClearingAccount;
    const newAccount = yield call(
      api.post,
      urls.CREATE_CLEARING_ACCOUNT_API_ENDPOINT,
      { userId: payload },
    );

    loadingCallback();
    if (newAccount.accountId) {
      cogoToast.success('Clearing account created! Linking to user...');

      linkedClearingAccount = yield call(
        api.post,
        urls.LINK_CLEARING_ACCOUNT_API_ENDPOINT,
        {
          userId: payload,
          accountId: newAccount.accountId,
        },
      );

      if (linkedClearingAccount) {
        cogoToast.success('Clearing account linked!');
      }
    }

    yield put(
      actions.createAndLinkClearingAccount.success(linkedClearingAccount),
    );
  } catch (e) {
    loadingCallback();
    yield put(actions.createAndLinkClearingAccount.failure(e.response));
  }
}

function* createEmarketAccountRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Creating E-Market account...', {
    hideAfter: 0,
  });
  try {
    const newEmarketAccount = yield call(
      api.post,
      urls.CREATE_EMARKET_ACCOUNT_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    cogoToast.success('E-Market account created!');
    yield put(
      actions.createEmarketAccount.success({
        ...newEmarketAccount,
        selectedAccountId: payload,
      }),
    );
  } catch (e) {
    loadingCallback();
    yield put(actions.createEmarketAccount.failure(e.response));
  }
}

function* createCxBalancesRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Initializing Emarket balances...',
    { hideAfter: 0 },
  );
  try {
    const newCxBalances = yield call(
      api.post,
      urls.CREATE_CX_BALANCES_API_ENDPOINT,
      { cxAccountId: payload },
    );
    loadingCallback();
    if (newCxBalances) {
      cogoToast.success('Emarket balances created!');
    }
    yield put(actions.createCxBalances.success(newCxBalances));
  } catch (e) {
    loadingCallback();
    yield put(actions.createCxBalances.failure(e.response));
  }
}

function* createTradeCxUserRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Creating a Emarket user for given Emarket account...',
    { hideAfter: 0 },
  );
  try {
    const newTradeCxUser = yield call(
      api.post,
      urls.CREATE_TRADE_CX_USER_API_ENDPOINT,
      { ...payload },
    );
    loadingCallback();
    if (newTradeCxUser.cxUserId) {
      cogoToast.success(`Emarket user created! ID: ${newTradeCxUser.cxUserId}`);
    }
    yield put(actions.createTradeCxUser.success(newTradeCxUser));
  } catch (e) {
    loadingCallback();
    yield put(actions.createTradeCxUser.failure(e.response));
  }
}

function* routeCxUserThroughCQGRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Setting CQG routing relationship...',
    { hideAfter: 0 },
  );
  try {
    const cxUserRoutedThroughCQG = yield call(
      api.post,
      urls.ROUTE_CX_USER_THROUGH_CQG_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    if (cxUserRoutedThroughCQG) {
      cogoToast.success('Routing relationship set!');
    }
    yield put(actions.routeCxUserThroughCQG.success(cxUserRoutedThroughCQG));
  } catch (e) {
    loadingCallback();
    yield put(actions.routeCxUserThroughCQG.failure(e.response));
  }
}

/*
 *
 * CQG manual onboarding sagas
 *
 */
function* createCqgCustomerRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Creating a CQG customer...', {
    hideAfter: 0,
  });
  try {
    const newCqgCustomer = yield call(
      api.post,
      urls.CREATE_CQG_CUSTOMER_ENDPOINT,
      payload,
    );
    yield put(actions.createCqgCustomer.success(newCqgCustomer));
    loadingCallback();
    if (newCqgCustomer.result.id) {
      cogoToast.success(
        `CQG customer created! ID: ${newCqgCustomer.result.id}`,
      );
    }
  } catch (e) {
    loadingCallback();
    yield put(actions.createCqgCustomer.failure(e.response));
  }
}

function* createCqgTraderRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Creating a CQG trader...', {
    hideAfter: 0,
  });
  try {
    const newCqgTrader = yield call(api.post, urls.CREATE_CQG_TRADER_ENDPOINT, {
      email: payload.email,
      cqgCustomerId: payload.cqgCustomerId,
      userId: payload.userId,
    });
    loadingCallback();
    if (newCqgTrader.result.traderId) {
      cogoToast.success(
        `CQG trader created! Trader ID: ${newCqgTrader.result.traderId}`,
      );
    }
    yield put(actions.createCqgTrader.success(newCqgTrader));
  } catch (e) {
    loadingCallback();
    yield put(actions.createCqgTrader.failure(e.response));
  }
}

function* createCqgAccountRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Creating a CQG account...', {
    hideAfter: 0,
  });
  try {
    const newCqgAccount = yield call(
      api.post,
      urls.CREATE_CQG_ACCOUNT_ENDPOINT,
      {
        cxAccountId: payload.cxAccountId,
        cqgTraderId: payload.cqgTraderId,
        userId: payload.userId,
        accountId: payload.accountId,
      },
    );
    loadingCallback();
    if (newCqgAccount.result.newAccountId) {
      cogoToast.success(
        `CQG account created! Account ID: ${newCqgAccount.result.newAccountId}`,
      );
    }
    yield put(actions.createCqgAccount.success(newCqgAccount));
  } catch (e) {
    loadingCallback();
    yield put(actions.createCqgAccount.failure(e.response));
  }
}

function* addRequiredCqgServicesToCqgTraderRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Adding required CQG services...', {
    hideAfter: 0,
  });
  try {
    const requiredCqgServices = yield call(
      api.post,
      urls.ADD_REQUIRED_CQG_SERVICES_TO_CQG_TRADER_ENDPOINT,
      {
        cqgTraderId: payload,
      },
    );
    loadingCallback();
    if (requiredCqgServices.success) {
      cogoToast.success(`Services added to account: ${payload}`);
    }
    yield put(
      actions.addRequiredCqgServicesToCqgTrader.success(requiredCqgServices),
    );
  } catch (e) {
    loadingCallback();
    yield put(actions.addRequiredCqgServicesToCqgTrader.failure(e.response));
  }
}

function* linkCqgTraderToCqgCustomerRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Linking CQG trader to customer...',
    { hideAfter: 0 },
  );
  try {
    const linkedCqgTrader = yield call(
      api.post,
      urls.LINK_CQG_TRADER_TO_CQG_CUSTOMER_ENDPOINT,
      {
        cqgTraderId: payload.cqgTraderId,
        cqgCustomerId: payload.cqgCustomerId,
      },
    );
    loadingCallback();
    if (linkedCqgTrader.success) {
      cogoToast.success('CQG trader linked to customer!');
    }
    yield put(actions.linkCqgTraderToCqgCustomer.success(linkedCqgTrader));
  } catch (e) {
    loadingCallback();
    yield put(actions.linkCqgTraderToCqgCustomer.failure(e.response));
  }
}

function* addCqgTraderToCqgAccountRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Adding CQG trader to account...', {
    hideAfter: 0,
  });
  try {
    const addCqgTraderResult = yield call(
      api.post,
      urls.ADD_CQG_TRADER_TO_CQG_ACCOUNT_ENDPOINT,
      {
        cqgAccountId: payload.cqgAccountId,
        cqgTraderId: payload.cqgTraderId,
      },
    );
    loadingCallback();
    yield put(actions.addCqgTraderToCqgAccount.success(addCqgTraderResult));
  } catch (e) {
    loadingCallback();
    yield put(actions.addCqgTraderToCqgAccount.failure(e.response));
  }
}

function* addCxTraderIdToCqgTraderRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Adding Emarket trading ID to CQG trader...',
    { hideAfter: 0 },
  );
  try {
    const addCxTraderIdResult = yield call(
      api.post,
      urls.ADD_CX_TRADER_ID_TO_CQG_TRADER_ENDPOINT,
      payload,
    );
    loadingCallback();
    if (addCxTraderIdResult.success) {
      cogoToast.success('Emarket trading ID added to CQG trader!');
    }
    yield put(actions.addCxTraderIdToCqgTrader.success(addCxTraderIdResult));
  } catch (e) {
    loadingCallback();
    yield put(actions.addCxTraderIdToCqgTrader.failure(e.response));
  }
}

function* sendCqgWelcomeEmailRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Sending CQG welcome email...', {
    hideAfter: 0,
  });
  try {
    const cqgWelcomeEmail = yield call(
      api.post,
      urls.SEND_CQG_WELCOME_EMAIL_ENDPOINT,
      {
        cqgTraderId: payload.cqgTraderId,
        cqgCustomerId: payload.cqgCustomerId,
      },
    );
    loadingCallback();
    if (cqgWelcomeEmail.success) {
      cogoToast.success('CQG welcome email sent!');
    }
    yield put(actions.sendCqgWelcomeEmail.success(cqgWelcomeEmail));
  } catch (e) {
    loadingCallback();
    yield put(actions.sendCqgWelcomeEmail.failure(e.response));
  }
}

function* sendEmailRequest({ payload }) {
  yield put(actions.sendEmail.request());

  const loadingCallback = cogoToast.loading('Sending email...', {
    hideAfter: 0,
  });
  try {
    const email = yield call(api.post, urls.SEND_EMAIL_ENDPOINT, payload);
    loadingCallback();
    if (email.success) {
      cogoToast.success('Email sent!');
    }
    yield put(actions.sendEmail.success(email));
  } catch (e) {
    loadingCallback();
    yield put(actions.sendEmail.failure(e.response));
  }
}

export default function* watchManualOnboarding() {
  yield takeLatest(
    actions.createAndLinkClearingAccount.TRIGGER,
    createAndLinkClearingAccountRequest,
  );
  yield takeLatest(
    actions.fetchEmarketAccountProfiles.TRIGGER,
    createSaga(
      actions.fetchEmarketAccountProfiles,
      actions.EMARKET_ACCOUNT_PROFILES,
    ),
  );
  yield takeLatest(
    actions.fetchEmarketFeeProfiles.TRIGGER,
    createSaga(actions.fetchEmarketFeeProfiles, actions.EMARKET_FEE_PROFILES),
  );
  yield takeLatest(
    actions.fetchEmarketInstrumentPermissionProfiles.TRIGGER,
    createSaga(
      actions.fetchEmarketInstrumentPermissionProfiles,
      actions.EMARKET_INSTRUMENT_PERMISSION_PROFILES,
    ),
  );
  yield takeLatest(
    actions.createEmarketOrganization.TRIGGER,
    createSaga(
      actions.createEmarketOrganization,
      actions.CREATE_EMARKET_ORGANIZATION,
      'Creating E-Market Organization for Member',
      'E-Market Organization Created',
    ),
  );
  yield takeLatest(
    actions.createEmarketAccount.TRIGGER,
    createEmarketAccountRequest,
  );
  yield takeLatest(actions.createCxBalances.TRIGGER, createCxBalancesRequest);
  yield takeLatest(actions.createTradeCxUser.TRIGGER, createTradeCxUserRequest);
  yield takeLatest(
    actions.routeCxUserThroughCQG.TRIGGER,
    routeCxUserThroughCQGRequest,
  );

  yield takeLatest(actions.createCqgCustomer.TRIGGER, createCqgCustomerRequest);
  yield takeLatest(actions.createCqgTrader.TRIGGER, createCqgTraderRequest);
  yield takeLatest(actions.createCqgAccount.TRIGGER, createCqgAccountRequest);
  yield takeLatest(
    actions.addRequiredCqgServicesToCqgTrader.TRIGGER,
    addRequiredCqgServicesToCqgTraderRequest,
  );
  yield takeLatest(
    actions.linkCqgTraderToCqgCustomer.TRIGGER,
    linkCqgTraderToCqgCustomerRequest,
  );
  yield takeLatest(
    actions.addCqgTraderToCqgAccount.TRIGGER,
    addCqgTraderToCqgAccountRequest,
  );
  yield takeLatest(
    actions.addCxTraderIdToCqgTrader.TRIGGER,
    addCxTraderIdToCqgTraderRequest,
  );
  yield takeLatest(
    actions.sendCqgWelcomeEmail.TRIGGER,
    sendCqgWelcomeEmailRequest,
  );
  yield takeLatest(actions.sendEmail.TRIGGER, sendEmailRequest);

  yield takeLatest(
    actions.fetchMarginProfiles.TRIGGER,
    createSaga(actions.fetchMarginProfiles, actions.MARGIN_PROFILES),
  );
  yield takeLatest(
    actions.fetchRiskProfiles.TRIGGER,
    createSaga(actions.fetchRiskProfiles, actions.RISK_PROFILES),
  );
  yield takeLatest(
    actions.fetchFeeProfiles.TRIGGER,
    createSaga(actions.fetchFeeProfiles, actions.FEE_PROFILES),
  );
}
