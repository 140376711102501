import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { format } from 'erisxkit/client';
import { formatDateLocal, formatTimeLocalWithDoW } from '../utils/time';
import { accountColumn } from '../common/table/commonMetadata';

export const mainComponentMetadata = (
  createReversal,
  showAccountCol = true,
) => [
  {
    Header: 'Id',
    id: 'closeoutId',
    accessor: 'uuid',
  },
  {
    Header: 'Date/Time',
    id: 'time',
    accessor: (row) => formatTimeLocalWithDoW(row.time, true),
  },
  {
    Header: 'Product',
    id: 'product_symbol',
    accessor: 'productSymbol',
  },
  {
    Header: 'Contract',
    id: 'contract_code',
    accessor: 'contractCode',
  },
  {
    ...accountColumn(),
    show: showAccountCol,
  },
  {
    Header: 'QTY',
    id: 'qty',
    accessor: 'qty',
  },
  {
    Header: 'Realized',
    id: 'realized',
    accessor: 'realized',
  },
  {
    Header: 'Source',
    id: 'source',
    accessor: 'source',
  },
  {
    Header: '',
    id: 'more',
    accessor: (d) => (
      <Dropdown icon="ellipsis vertical" pointing="top right">
        <Dropdown.Menu>
          <Dropdown.Item
            key="more"
            text="Reverse closeout"
            onClick={(e) => createReversal(d, e)}
            icon="redo"
          />
        </Dropdown.Menu>
      </Dropdown>
    ),
    filterable: false,
    width: 35,
  },
];

export const subComponentMetadata = [
  {
    Header: 'Position ID',
    accessor: 'positionId',
    id: 'positionId',
  },
  {
    Header: 'Trade Date',
    id: 'time',
    accessor: (row) => formatDateLocal(row.tradeDate),
  },
  {
    Header: 'Side',
    accessor: 'side',
    id: 'side',
  },
  {
    Header: 'QTY',
    accessor: 'qty',
    id: 'qty',
  },
  {
    Header: 'PX',
    accessor: 'px',
    id: 'px',
  },
  {
    Header: 'Notional',
    accessor: 'notional',
    id: 'notional',
  },
  {
    Header: 'Trade Type',
    accessor: 'trdType',
    id: 'trdType',
  },
  {
    Header: 'Trade ID',
    accessor: 'tradeId',
    id: 'tradeId',
  },
  {
    Header: 'Trade Report ID',
    accessor: 'tradeReportId',
    id: 'tradeReportId',
  },
  {
    Header: 'ClOrdId',
    accessor: 'clOrdId',
    id: 'clOrdId',
  },
  {
    Header: 'CustAcctRef',
    accessor: 'customerAccountRef',
    id: 'customerAccountRef',
  },
];
