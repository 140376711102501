import get from 'lodash/get';

export const TRAVEL_RULE_CATEGORIES = {
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
};
export const NATURAL_PERSON_KEY = 'originator.natural';
export const LEGAL_PERSON_KEY = 'originator.legal';
export const ORIGINATING_VASP_KEY = 'originatingVasp';
export const RECIPIENT_VASP_KEY = 'recipientVasp';

export const isNaturalPersonSelected = (activeFormGroups = []) =>
  activeFormGroups.includes(NATURAL_PERSON_KEY);

/** Table row utility methods */
export const isDepositTravelRule = (row) =>
  get(row, 'category') === TRAVEL_RULE_CATEGORIES.DEPOSIT;
export const isWithdrawalTravelRule = (row) =>
  get(row, 'category') === TRAVEL_RULE_CATEGORIES.WITHDRAWAL;
// null represets pending trust travel rule
export const isTrustTravelRule = (row) => get(row, 'fromTrust') === true;
export const isPendingTravelRule = (row) => get(row, 'fromTrust') === null;
// Only LegalPersons have name attr
export const isOriginatorLegalPerson = (row) =>
  !!get(row, 'originator.legalPersonName');
