import { get } from 'lodash';
import React, { Fragment } from 'react';
import { Form, Button, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import UserSelectionContainer from '../../common/containers/UserSelectionContainer';
import { memberUser } from '../../constants/userTypes';

const AddRemoveAccounts = ({
  addUsers,
  hideModal,
  saveAccountDetails,
  saveDisabled,
  selectedAccount,
  userValues,
}) => (
  <Fragment>
    <Modal.Content>
      <Form>
        <Modal.Header>
          {get(selectedAccount, 'name', '')}{' '}
          <span className="italic">
            ({get(selectedAccount, 'accountId', '')})
          </span>
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={() => hideModal()}
          />
        </Modal.Header>
        <UserSelectionContainer
          additionalFilters={[{ attr: 'type', op: 'eq', value: memberUser }]}
          multiple
          name="accountId"
          placeholder="Select users"
          label="Select Users to Link to Account:"
          input={{ onChange: addUsers, value: userValues }}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={hideModal}>
        Cancel
      </Button>
      <Button positive onClick={saveAccountDetails} disabled={saveDisabled}>
        Save
      </Button>
    </Modal.Actions>
  </Fragment>
);

AddRemoveAccounts.propTypes = {
  addUsers: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  saveAccountDetails: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool,
  selectedAccount: PropTypes.objectOf(PropTypes.string),
  userValues: PropTypes.arrayOf(PropTypes.string),
};

AddRemoveAccounts.defaultProps = {
  saveDisabled: false,
  selectedAccount: {},
  userValues: [],
};

export default AddRemoveAccounts;
