import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchFees } from '../reducers/feesReducer';
import { api_server_api, FEES_ENDPOINT } from '../api';

function* feeProfileSaga(arg) {
  try {
    yield put(fetchFees.request());
    const data = yield call(api_server_api.post, FEES_ENDPOINT, arg.payload);
    yield put(fetchFees.success({ ...data }));
  } catch (e) {
    yield put(fetchFees.failure(e.response));
  }
}

export default function* watchConfiguration() {
  yield takeLatest(fetchFees.TRIGGER, feeProfileSaga);
}
