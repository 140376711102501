import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';
import { get } from 'lodash';

export const fetchClientTransfer = createRoutine(
  actionTypes.MEMBER_TRANSFER_FINAL_APPROVALS,
);
export const fetchClientTransferPromiseCreator =
  promisifyRoutine(fetchClientTransfer);
export const approveAllClientTransfer = createRoutine(actionTypes.APPROVE_ALL);
export const approveAllClientTransferPromiseCreator = promisifyRoutine(
  approveAllClientTransfer,
);
export const rejectAllClientTransfer = createRoutine(actionTypes.REJECT_ALL);
export const rejectAllClientTransferPromiseCreator = promisifyRoutine(
  rejectAllClientTransfer,
);

export default function clientTransferReducer(state = {}, action) {
  switch (action.type) {
    case fetchClientTransfer.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getClientTransfers = (state) => get(state, ['clientTransfer'], {});
