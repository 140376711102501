import { formatTimeLocal } from '../utils/time';
import { memberColumn } from '../common/table/commonMetadata';

const crypto_address_analyses = [
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: (row) => formatTimeLocal(row.updatedAt),
  },
  {
    Header: 'Asset Type',
    id: 'assetType',
    accessor: 'assetType',
  },
  {
    Header: 'Address',
    id: 'address',
    accessor: 'address',
    sortable: false,
  },
  {
    Header: 'Risk Score',
    id: 'riskScore',
    accessor: 'riskScore',
  },
  memberColumn(),
];

export default crypto_address_analyses;
