import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  renderDropdown,
  renderField,
  rules,
  normalization,
  renderCheckbox,
} from 'erisxkit/client';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { titleCase } from 'change-case';
import AssetTypeSelectionContainer from '../../common/containers/AssetTypeSelectionContainer';
import SubExchangeSelectionContainer from '../../common/containers/SubExchangeSelectionContainer';
import deliveryTypes from '../../constants/deliveryTypes';
import { minValue, maxValue } from '../../common/forms/rules';

const MARGIN_FINANCIAL = 'margin_financial';
const MARGIN_PHYSICAL = 'margin_physical';
const MARGIN_CONTINUOUS = 'margin_continuous';
export const MARGIN_PRODUCTS = [
  MARGIN_FINANCIAL,
  MARGIN_PHYSICAL,
  MARGIN_CONTINUOUS,
];
const isMarginProduct = (product) => MARGIN_PRODUCTS.includes(product);

const MIN_VALUE_MARGIN_ROUNDING = 1;
const MAX_VALUE_MARGIN_ROUNDING = 100;
const SPAN_ID_MIN_VALUE = 10000;

const minValueMarginRounding = minValue(MIN_VALUE_MARGIN_ROUNDING);
const maxValueMarginRounding = maxValue(MAX_VALUE_MARGIN_ROUNDING);
const spanIdMinValue = minValue(SPAN_ID_MIN_VALUE);

const FuturesProduct = ({
  change,
  deliveryFormValue,
  onChangeSubExchange,
  symbolFormValue,
  allowBlockTradesFormValue,
}) => (
  <Fragment>
    <Field
      id="symbol"
      name="symbol"
      component={renderField}
      label="Product Code"
      onChange={(e, value) =>
        change(
          'description',
          `${value || '[product code]'} FUTURES ${titleCase(deliveryFormValue) || '[delivery]'}`,
        )
      }
      validate={[rules.required]}
      required
    />
    <Form.Group widths="4">
      <Field
        name="subExchangeId"
        id="subExchangeId"
        component={SubExchangeSelectionContainer}
        label="Sub Exchanges"
        onChange={onChangeSubExchange}
      />
      <Field
        name="deliveryFee"
        id="deliveryFee"
        component={renderField}
        label="Delivery Fee"
      />
      <Field
        name="deliveryFeeType"
        id="deliveryFeeType"
        component={AssetTypeSelectionContainer}
        label="Delivery Fee Currency"
      />
      <Field
        name="spanId"
        id="spanId"
        component={renderField}
        label="Span Id"
        validate={[spanIdMinValue]}
        parse={normalization.numbersOnly}
      />
    </Form.Group>
    <Form.Group widths="4">
      <Field
        name="baseAssetType"
        id="baseAssetType"
        placeholder="base"
        component={AssetTypeSelectionContainer}
        label="Base Asset Type"
        required
        validate={[rules.required]}
      />
      <Field
        name="quotedAssetType"
        id="quotedAssetType"
        placeholder="quote"
        component={AssetTypeSelectionContainer}
        label="Quoted Asset Type"
        required
        validate={[rules.required]}
      />
      <Field
        name="allowBlockTrades"
        component={renderCheckbox}
        label="Allow Block Trades"
      />
      <Field
        name="minimumBlockSize"
        id="minimumBlockSize"
        component={renderField}
        label="Minimum Block Size"
        disabled={!allowBlockTradesFormValue}
        parse={normalization.floatsOnly}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        name="contractSize"
        id="contractSize"
        component={renderField}
        label="Contract Size"
        parse={normalization.floatsOnly}
      />
      <Field
        name="tickSize"
        id="tickSize"
        component={renderField}
        label="Tick Size"
        parse={normalization.floatsOnly}
      />
      <Field
        name="delivery"
        component={renderDropdown}
        label="Delivery"
        onChange={(e, value) => {
          change(
            'description',
            `${symbolFormValue || '[product code]'} FUTURES ${titleCase(value) || '[delivery]'}`,
          );
          change('delivery', value);
          if (!isMarginProduct(value)) {
            change('marginRoundingOutright', null);
            change('marginRoundingSpreads', null);
          }
        }}
        options={deliveryTypes}
        validate={[rules.required]}
        required
      />
      <Field
        name="settlementPeriod"
        component={renderField}
        label="Settlement Period"
        placeholder="(in hours)"
        parse={normalization.floatsOnly}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        name="cftcReportingLimit"
        component={renderField}
        label="CFTC Reporting Limit"
        parse={normalization.floatsOnly}
      />
      <Field
        name="exchangeReportableLimit"
        component={renderField}
        label="Exchange Reportable Limit"
        parse={normalization.floatsOnly}
      />
      <Field
        name="maximumReportableLimit"
        component={renderField}
        label="Maximum Reportable Limit"
        parse={normalization.floatsOnly}
      />
      <Field
        name="cftc_102bLimit"
        component={renderField}
        label="CFTC Volume Reporting Limit"
        parse={normalization.floatsOnly}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field name="description" component={renderField} label="Description" />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        name="marginRoundingOutright"
        component={renderField}
        label="Margin Rounding Outright"
        disabled={!isMarginProduct(deliveryFormValue)}
        parse={normalization.numbersOnly}
        validate={[minValueMarginRounding, maxValueMarginRounding]}
      />
      <Field
        name="marginRoundingSpreads"
        component={renderField}
        label="Margin Rounding Spreads"
        disabled={!isMarginProduct(deliveryFormValue)}
        parse={normalization.numbersOnly}
        validate={[minValueMarginRounding, maxValueMarginRounding]}
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        name="delistedAt"
        component={renderCheckbox}
        label="Delist Product"
      />
    </Form.Group>
  </Fragment>
);

FuturesProduct.propTypes = {
  change: PropTypes.func,
  deliveryFormValue: PropTypes.string,
  onChangeSubExchange: PropTypes.func.isRequired,
  symbolFormValue: PropTypes.string,
  allowBlockTradesFormValue: PropTypes.string,
};

FuturesProduct.defaultProps = {
  change: () => {},
  deliveryFormValue: '',
  symbolFormValue: PropTypes.string,
  allowBlockTradesFormValue: PropTypes.string,
};

export default FuturesProduct;
