import React from 'react';

const referralCodesMetadata = [
  {
    Header: () => <p className="referral-table-header">Users invited</p>,
    id: 'email',
    accessor: 'referreeEmail',
    filterable: false,
  },
  {
    Header: () => <p className="referral-table-header">Status</p>,
    id: 'status',
    accessor: 'status',
    filterable: false,
    Cell: ({ original }) => (
      <p className="referral-table-status">{original.status}</p>
    ),
  },

  {
    Header: () => <p className="referral-table-header">Earned</p>,
    id: 'earned',
    accessor: 'referrerPayoutAmount',
    filterable: false,
    Cell: ({ original }) => (
      <p className="referral-table-earned">
        {`$ ${original.referrerPayoutAmount}`}
      </p>
    ),
  },
];
export default referralCodesMetadata;
