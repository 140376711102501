import React from 'react';
import { NavLink } from 'react-router-dom';
import { formatDateField, NANO_TO_MILLI_FACTOR } from './riskOverviewMetadata';

export default [
  {
    Header: 'Alert ID',
    id: 'alertId',
    accessor: 'alertId',
    Cell: ({ row }) => (
      <NavLink to={`/risk_alert/${row.original.alertId}/detail`}>
        {' '}
        {row.original.alertId}{' '}
      </NavLink>
    ),
    width: 100,
  },
  {
    Header: 'Transaction Time',
    id: 'transaction_time',
    accessor: ({ transactionTime }) => {
      const time = transactionTime.toString();
      // times in backend are in nano seconds, we need to convert those to milliseconds
      const mils = parseInt(
        time.substring(0, time.length - NANO_TO_MILLI_FACTOR),
      );

      return formatDateField(mils);
    },
    width: 220,
  },
  {
    Header: 'Account',
    id: 'accountNumber',
    accessor: 'accountNumber',
    width: 115,
  },
  {
    Header: 'Alert Type',
    id: 'type',
    accessor: 'type',
    width: 158,
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: 'description',
    width: 158,
  },
];
