import { connect } from 'react-redux';
import React, { Component } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import { Segment, List } from 'semantic-ui-react';
import {
  fetchExceptions,
  fetchExceptionsInfo,
  selectException,
} from '../../actions/systemAlertsActions';
import SystemAlerts from '../../common/table/ExternalExpandableTable';
import exceptionsMetadata from '../../metadata/exceptionsMetadata';
import {
  getExceptionsList,
  getExceptionssCount,
  getExceptionInfo,
} from '../../reducers/systemAlertsReducer';

const mapStateToProps = (state) => ({
  exceptions: getExceptionsList(state),
  count: getExceptionssCount(state),
  exceptionInfo: getExceptionInfo(state),
  loading: createLoadingSelector(['EXCEPTIONS'])(state),
});

const mapDispatchToProps = {
  fetchExceptions,
  fetchExceptionsInfo,
  selectException,
};

const SubComponent = (row) => (
  <Segment inverted color="red" tertiary>
    <h3> Message: {row.original.message} </h3>
    <List items={row.original.backtrace} />
  </Segment>
);

class ExceptionsContainer extends Component {
  componentDidMount() {
    this.props.fetchExceptionsInfo();
  }
  fetchData(state) {
    let filters = {};
    if (state.filtered.length !== 0) {
      filters = state.filtered.reduce((obj, item) => {
        obj[item.id] = item.value;
        return obj;
      }, {});
    }
    this.props.fetchExceptions({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      ...filters,
    });
  }

  render = () => {
    const { exceptions, count, exceptionInfo, loading } = this.props;
    const metadata = exceptionsMetadata(exceptionInfo);

    return (
      <SystemAlerts
        title="systemAlerts"
        className="-striped"
        data={exceptions}
        metadata={metadata}
        onFetchData={this.fetchData.bind(this)}
        count={count}
        loading={loading}
        viewRowDetails={this.viewRowDetails}
        SubComponent={SubComponent}
        noDataText="No exceptions were found."
        multipleExpandable
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExceptionsContainer);
