import React from 'react';
import styled from 'styled-components';
import colors from '../constants/colors';
import ClearingRequestActions from '../containers/Members/FCMClearingRequests/ClearingRequestActions';
import {
  ClearingRequestStatus,
  FCMClearingRequest,
} from '../ts/models/FCMClearing.model';
import { CellProps } from 'react-table';
import { formatTimeLocal } from '../utils/time';

const StyledStatus = styled.p<{ status: ClearingRequestStatus }>`
  color: ${({ status }) => {
    if (status === 'approved') return colors.accent4;
    if (status === 'rejected') return colors.error2;
  }};
  font-weight: ${({ status }) => (status === 'pending' ? 400 : 'bold')};
  text-transform: capitalize;
`;

const Status: React.FC<{ status: ClearingRequestStatus }> = ({ status }) => {
  return <StyledStatus status={status}>{status}</StyledStatus>;
};

const fcmClearingRequestsMetadata = (fetchData: () => void) => [
  {
    id: 'createdAt',
    accessor: (row: FCMClearingRequest) => formatTimeLocal(row?.createdAt),
    Header: 'Time',
    minSize: 100,
    enableColumnFilter: false,
    hideFilter: true,
  },
  {
    id: 'requestingMemberName',
    accessor: 'requestingMemberName',
    Header: 'Participant',
    minSize: 100,
    enableColumnFilter: false,
    hideFilter: true,
  },
  {
    id: 'comment',
    accessor: 'comment',
    Header: 'Comment',
    minSize: 100,
    enableColumnFilter: false,
    hideFilter: true,
  },
  {
    id: 'status',
    accessor: 'status',
    Header: 'Status',
    minSize: 100,
    enableColumnFilter: false,
    hideFilter: true,
    Cell: ({ row }: CellProps<FCMClearingRequest>) => {
      const status = row?.original.status;
      return <Status status={status} />;
    },
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Actions',
    Cell: ({ row }: CellProps<FCMClearingRequest>) => {
      const request = row?.original;
      return <ClearingRequestActions fetchData={fetchData} request={request} />;
    },
    hideFilter: true,
  },
];

export default fcmClearingRequestsMetadata;
