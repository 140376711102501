import React, { Fragment } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Icon, Label, Popup, List, Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { formatTimeLocal } from '../utils/time';

const showIcon = (row, createJournal) => {
  switch (row.original.state) {
    case 'internal':
      return (
        <Icon.Group
          link
          title="Create Reversal Journal"
          onClick={() => createJournal(row.index)}
        >
          <Icon link name="redo" color="red" />
          <Icon link corner name="circle add" color="green" />
        </Icon.Group>
      );
    case 'external':
      return (
        <Icon.Group
          link
          title="Create Journal"
          onClick={() => createJournal(row.index)}
        >
          <Icon link name="arrow right" color="red" />
          <Icon link corner name="circle add" color="green" />
        </Icon.Group>
      );
    default:
      return '';
  }
};

const filterArray = (filter, row) => {
  if (Array.isArray(filter.value)) {
    return filter.value.includes(row._original.key);
  }
  return row[filter.id] === filter.value;
};

const reconciliationTable =
  (createJournal, transactionlink, type, state) =>
  (handleRowExpanded, filterLinked, unLinkEntries) => [
    {
      expander: true,
      className: 'text-center',
      Expander: (row) => {
        const expander = (
          <Icon.Group size="large">
            <Icon
              name={`triangle ${row.isExpanded ? 'down' : 'right'}`}
              onClick={() => handleRowExpanded(row)}
            />
            <Icon
              color="green"
              corner
              name="comment alternate outline"
              onClick={() => handleRowExpanded(row)}
            />
          </Icon.Group>
        );
        if (row.original.txComments || row.original.journalComments) {
          return expander;
        }

        return (
          <div
            className={classnames('rt-expander', row.isExpanded && '-open')}
            onClick={() => handleRowExpanded(row)}
          >
            &bull;
          </div>
        );
      },
    },
    {
      Header: '',
      accessor: (row) =>
        row.links ? (
          <Fragment>
            <Popup
              trigger={
                <Label as="a">
                  <Icon name="linkify" /> {_.get(row.links, 'length', null)}{' '}
                  link(s)
                </Label>
              }
              content={
                <Fragment>
                  <List items={row.links} />
                  <div className="flex-row">
                    <Button
                      tiny
                      color="green"
                      icon="filter"
                      onClick={() => filterLinked(row)}
                      content="Show linked entries"
                    />
                    <Button
                      tiny
                      color="red"
                      icon="unlinkify"
                      onClick={() => unLinkEntries(row)}
                      content="Unlink all entries"
                    />
                  </div>
                </Fragment>
              }
              on="click"
            />
          </Fragment>
        ) : (
          ''
        ),
      id: 'links',
    },
    {
      Header: '',
      columns: [
        {
          Header: 'Reference Code',
          id: 'refCode',
          accessor: 'refCode',
          className: 'mono',
        },
      ],
    },
    {
      Header: 'External Transactions',
      headerClassName: 'text-right',
      columns: [
        {
          Header: 'Transaction Id',
          id: 'refCode',
          accessor: 'txId',
          Cell: (e) =>
            transactionlink ? ( // replace period and following chars with '' --v
              <a
                className="mono"
                target="_blank"
                href={`${transactionlink}${e.original.txId.replace(/\..+/g, '')}`}
              >
                {' '}
                {e.original.txId}{' '}
              </a>
            ) : (
              <span>{e.original.txId}</span>
            ),
          filterMethod: filterArray,
        },
        {
          Header: 'Confirmations',
          id: 'confirmations',
          accessor: 'confirmations',
          show: type === 'wallet' && state === 0,
        },
        {
          Header: 'Time',
          id: 'txTime',
          width: 225,
          accessor: (d) => d.txTime && formatTimeLocal(d.txTime),
        },
        {
          Header: 'Fee',
          id: 'fee',
          accessor: 'fee',
          width: 100,
          className: 'text-right mono',
        },
        {
          Header: 'Amount',
          id: 'externalAmount',
          accessor: (d) =>
            !_.isUndefined(d.amount) && d.state !== 'internal'
              ? d.amount.toString()
              : '',
          width: 100,
          className: 'text-right mono',
          headerClassName: 'text-right',
        },
      ],
    },
    {
      columns: [
        {
          Header: '',
          id: 'action',
          width: 32,
          resizable: false,
          Cell: (row) => (
            <span>
              {!row.original.reconciled ? (
                showIcon(row, createJournal)
              ) : (
                <Icon title="Matched" color="green" name="circle check" />
              )}
            </span>
          ),
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            }
            if (filter.value === 'reconciled') {
              return row._original.reconciled;
            }
            if (filter.value === 'unreconciled') {
              return !row._original.reconciled;
            }
            if (filter.value === 'break') {
              return row._original.txBreak || row.journalBreak;
            }
            return true;
          },
        },
      ],
    },
    {
      Header: 'Clearing System Transactions',
      headerClassName: 'text-left',
      columns: [
        {
          Header: 'Amount',
          id: 'internalAmount',
          width: 100,
          accessor: (d) =>
            !_.isUndefined(d.amount) && d.state !== 'external'
              ? -d.amount.toString()
              : '',
          className: 'mono',
          headerClassName: 'text-left',
        },
        {
          Header: 'Account',
          accessor: 'accountId',
          Cell: (e) => (
            <NavLink
              className="mono"
              to={`/clearing_accounts/${e.original.accountId}`}
            >
              {' '}
              {e.original.accountId}{' '}
            </NavLink>
          ),
        },
        {
          Header: 'Time',
          id: 'journalTime',
          width: 225,
          accessor: (d) => d.journalTime && formatTimeLocal(d.journalTime),
        },
        {
          Header: 'Created By',
          id: 'createdBy',
          accessor: 'createdBy',
        },
        {
          Header: 'Description',
          id: 'description',
          accessor: 'description',
          resizable: false,
        },
      ],
    },
  ];

export default reconciliationTable;
