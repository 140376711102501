import Big from 'bignumber.js';
import { Icon, Label } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import _ from 'lodash';
import { formatTimeLocal, formatDateLocal } from '../utils/time';

const iconColorForState = (state, type) =>
  ({
    posted: 'green',
    pending: 'yellow',
    rejected: 'red',
    admin_rejected: 'red',
  })[state] || type;

const iconForType = (type) => {
  switch (type) {
    case 'deposit':
      return 'down arrow';
    case 'withdrawal':
      return 'up arrow';
    case 'buy':
    case 'sell':
      return 'exchange';
    default:
      return '';
  }
};

const accountHistory = [
  {
    Header: '',
    columns: [
      {
        Header: 'Time',
        id: 'time',
        headerClassName: 'pull-left',
        accessor: (row) => formatTimeLocal(row.time),
      },
      {
        Header: 'Report Date',
        id: 'reportDate',
        headerClassName: 'pull-left',
        accessor: (row) =>
          formatDateLocal(_.get(row, ['postingSummary', '0', 'reportDate'])),
      },
      {
        Header: 'Product',
        id: 'product',
        headerClassName: 'pull-left',
        accessor: 'product',
      },
      {
        Header: 'Type',
        id: 'type',
        headerClassName: 'pull-left',
        accessor: ({ txType, state }) => (
          <Fragment>
            <Icon
              name={iconForType(txType)}
              color={iconColorForState(state, txType)}
            />
            {txType && txType.charAt(0).toUpperCase() + txType.slice(1)}
          </Fragment>
        ),
      },
      {
        Header: 'Quantity',
        id: 'quantity',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'quantity',
      },
      {
        Header: 'Amount',
        id: 'amount',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'amount',
      },
      {
        Header: 'Price',
        id: 'price',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'price',
      },
      {
        Header: 'Notional',
        id: 'notional',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'notional',
      },
      {
        Header: 'Clearing Fee',
        id: 'clearingFee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({ clearingFee = 0, feeType = '' }) =>
          `${clearingFee} ${feeType}`,
      },
      {
        Header: 'Exchange Fee',
        id: 'exchangeFee',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({ exchangeFee = 0, feeType = '' }) =>
          `${exchangeFee} ${feeType}`,
      },
      {
        Header: 'Fee',
        id: 'otherFees',
        accessor: ({ otherFees = 0, feeType = '' }) =>
          `${Big(otherFees).toFixed()} ${feeType}`,
        headerClassName: 'pull-left',
      },
      {
        Header: 'Total ',
        id: 'total',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({
          amount = 0,
          notional = 0,
          exchangeFee = 0,
          clearingFee = 0,
          otherFees = 0,
        }) =>
          Big(amount)
            .plus(Big(notional))
            .plus(Big(exchangeFee).plus(Big(clearingFee).plus(Big(otherFees))))
            .toFixed(),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: (d) => (
          <Label
            color={iconColorForState(d.state, 'green')}
            content={d.state}
          />
        ),
        className: 'pull-right',
        headerClassName: 'pull-right',
      },
    ],
  },
];

export default accountHistory;
