import React, { useCallback, useEffect, useMemo } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  renderDropdown,
  hideModal,
  createLoadingSelector,
} from 'erisxkit/client';
import get from 'lodash/get';
import unset from 'lodash/unset';
import { ApprovedParticipantAction } from './Action/ParticipantActions';
import GenericModal from '../../../common/GenericModal';
import {
  GET_TRADING_PARTICIPANTS,
  fetchTradingParticipants,
  getParticipantCARLinkModalLoading,
  getParticipantListSelectOptions,
} from '../../../reducers/participantManagementReducer';
import {
  GET_CUSTOMER_ACCOUNT_REFERENCE_LIST,
  fetchCarList,
  getCarListSelectOptions,
  linkCarToParticipantPromise,
  unlinkCarToParticipantPromise,
} from '../../../reducers/carManagementReducer';
import {
  CARRow,
  TradingParticipant,
} from '../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import ModalDetails, { InfoField } from './ModalDetails';
import { RootState } from '../../../ts/types/store';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getCustomerAccountFromMember } from '../../../reducers/accountsReducer';
import Account from '../../../ts/models/Account.model';

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;

type IForm = {
  isLink: boolean;
  participant?: TradingParticipant;
  accountLabel?: string;
};
type IProps = {
  isLink: boolean;
  participant?: TradingParticipant;
  accountLabel?: string;
};

const LINK_FORM_NAME = 'participantLinkCAR';
const ParticipantCARLinkForm = reduxForm<IForm, IProps>({
  form: LINK_FORM_NAME,
})(({ isLink, change, participant, accountLabel }) => {
  const dispatch = useAppDispatch();

  const carOptions = useAppSelector(getCarListSelectOptions);
  const participantOptions = useAppSelector(getParticipantListSelectOptions);
  const loading = useAppSelector(getParticipantCARLinkModalLoading);

  const isFetchingCARs = useAppSelector((state: RootState) =>
    createLoadingSelector([GET_CUSTOMER_ACCOUNT_REFERENCE_LIST])(state),
  );
  const isFetchingParticipants = useAppSelector((state: RootState) =>
    createLoadingSelector([GET_TRADING_PARTICIPANTS])(state),
  );
  useEffect(() => {
    dispatch(
      fetchTradingParticipants({
        urlParams: { accountLabel },
      }),
    );
  }, [accountLabel, dispatch]);

  useEffect(() => {
    // If action is link and a participant was passed,
    // initialize participant dropdown
    if (isLink) {
      // Delete subrows key because that throws off redux-form equality check
      unset(participant, 'subRows');
      change('participant', participant);
    }
  }, [change, isLink, participant]);

  return (
    <StyledForm>
      <Field
        component={renderDropdown}
        placeholder="Enter name"
        name="carName"
        id="carName"
        type="text"
        required
        label="CAR"
        fluid
        options={carOptions}
        disabled={loading}
        search
        loading={isFetchingCARs}
      />
      <Field
        component={renderDropdown}
        label="Trading Participant"
        fluid
        required
        placeholder="Select"
        name="participant"
        id="participant"
        options={participantOptions}
        disabled={loading}
        search
        loading={isFetchingParticipants}
      />
    </StyledForm>
  );
});

const ParticipantCARLinkModal: React.FC<{
  action: ApprovedParticipantAction;
  participant: TradingParticipant;
  car: CARRow;
  memberId: string;
  fetchData: () => void;
}> = ({ action, participant, car, memberId, fetchData }) => {
  const dispatch = useAppDispatch();
  const formValues = useAppSelector((state: RootState) =>
    getFormValues(LINK_FORM_NAME)(state),
  );
  const account: Account = useAppSelector((state: RootState) =>
    getCustomerAccountFromMember(state, memberId),
  );
  const accountLabel = account?.label;
  const loading = useAppSelector(getParticipantCARLinkModalLoading);
  const isLink = action === ApprovedParticipantAction.LINK;
  const header = `${isLink ? 'Link' : 'Un-Link'} CAR to Participant`;

  const carToLink = get(formValues, 'carName', '');
  const participantToLink = get(formValues, 'participant', '');

  useEffect(() => {
    if (isLink)
      dispatch(
        fetchCarList({
          urlParams: { accountLabel },
        }),
      );
  }, [accountLabel, dispatch, isLink]);

  const unlinkFields = useMemo(() => {
    return [
      {
        header: 'Trading Participant',
        text: participant?.participantName || '',
      },
      { header: 'CAR', text: car?.carName || '' },
    ] as InfoField[];
  }, [car, participant]);

  const content = useMemo(() => {
    return isLink ? (
      <ParticipantCARLinkForm
        accountLabel={accountLabel}
        participant={participant}
        isLink={isLink}
      />
    ) : (
      <ModalDetails
        fields={unlinkFields}
        subHeader="You are about to Unlink a CAR"
      />
    );
  }, [participant, isLink, unlinkFields]);

  const onLink = useCallback(async () => {
    try {
      await linkCarToParticipantPromise(
        {
          tradingFirmMemberId: participantToLink.participantId,
          accountLabel,
          customerAccountReference: carToLink,
          urlParams: {
            accountLabel,
            customerAccountReference: carToLink,
          },
        },
        dispatch,
      );
      dispatch(hideModal());
      fetchData();
    } catch (error) {
      dispatch(hideModal());
    }
  }, [
    accountLabel,
    carToLink,
    dispatch,
    participantToLink.participantId,
    fetchData,
  ]);

  const onUnlink = useCallback(async () => {
    try {
      await unlinkCarToParticipantPromise(
        {
          tradingFirmMemberId: participant?.participantId,
          accountLabel,
          customerAccountReference: car?.carName,
          urlParams: {
            accountLabel,
            customerAccountReference: car?.carName,
          },
        },
        dispatch,
      );
      dispatch(hideModal());
      fetchData();
    } catch (error) {
      dispatch(hideModal());
    }
  }, [
    accountLabel,
    car?.carName,
    dispatch,
    participant?.participantId,
    fetchData,
  ]);

  const linkButtonDisabled = useMemo(
    () => loading || !participantToLink || !carToLink,
    [carToLink, loading, participantToLink],
  );

  const confirmProps = useMemo(
    () => ({
      text: isLink ? 'Link' : 'Unlink',
      onClick: isLink ? onLink : onUnlink,
      danger: !isLink,
      disabled: isLink ? linkButtonDisabled : false,
    }),
    [isLink, linkButtonDisabled, onLink, onUnlink],
  );

  const cancelProps = useMemo(
    () => ({
      text: 'Cancel',
      onClick: () => dispatch(hideModal()),
    }),
    [dispatch],
  );
  
  return (
    <GenericModal
      header={header}
      content={content}
      cancelProps={cancelProps}
      confirmProps={confirmProps}
      loadingSelector={getParticipantCARLinkModalLoading}
    />
  );
};

export default ParticipantCARLinkModal;
