import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  createLinkedMemberBankAccountAdmin,
  updateLinkedMemberAssetAccountAdmin,
  createLinkedMemberCryptoAddress,
  createLinkedMemberCollateralAccount,
  deleteLinkedMemberAssetAccount,
  fetchLinkedMemberAssetAccounts,
  LINKED_MEMBER_ASSET_ACCOUNTS,
  CREATE_LINKED_MEMBER_BANK_ACCOUNT_ADMIN,
  UPDATE_LINKED_MEMBER_ASSET_ACCOUNT_ADMIN,
  CREATE_LINKED_MEMBER_CRYPTO_ADDRESS,
  CREATE_LINKED_MEMBER_COLLATERAL_ACCOUNT,
  DELETE_LINKED_MEMBER_ASSET_ACCOUNT,
} from '../reducers/linkedMemberAssetAccountsReducer';

export default function* watchLinkedMemberAssetAccounts() {
  yield takeLatest(
    fetchLinkedMemberAssetAccounts.TRIGGER,
    createSaga(fetchLinkedMemberAssetAccounts, LINKED_MEMBER_ASSET_ACCOUNTS),
  );
  yield takeLatest(
    createLinkedMemberBankAccountAdmin.TRIGGER,
    createSaga(
      createLinkedMemberBankAccountAdmin,
      CREATE_LINKED_MEMBER_BANK_ACCOUNT_ADMIN,
      'Attempting to create linked bank account for member.',
      'Success! Approval Req created for linked Bank account.',
    ),
  );
  yield takeLatest(
    updateLinkedMemberAssetAccountAdmin.TRIGGER,
    createSaga(
      updateLinkedMemberAssetAccountAdmin,
      UPDATE_LINKED_MEMBER_ASSET_ACCOUNT_ADMIN,
      'Attempting to update linked bank account for member.',
      'Success! Approval Req updated for linked Bank account.',
    ),
  );
  yield takeLatest(
    createLinkedMemberCryptoAddress.TRIGGER,
    createSaga(
      createLinkedMemberCryptoAddress,
      CREATE_LINKED_MEMBER_CRYPTO_ADDRESS,
      'Attempting to create linked crypto address for member.',
      'Success! Approval Req created for linked crypto address.',
    ),
  );
  yield takeLatest(
    deleteLinkedMemberAssetAccount.TRIGGER,
    createSaga(
      deleteLinkedMemberAssetAccount,
      DELETE_LINKED_MEMBER_ASSET_ACCOUNT,
      'Attempting to delete linked bank account for member.',
      'Success! Linked Bank account deleted.',
    ),
  );
  yield takeLatest(
    createLinkedMemberCollateralAccount.TRIGGER,
    createSaga(
      createLinkedMemberCollateralAccount,
      CREATE_LINKED_MEMBER_COLLATERAL_ACCOUNT,
      'Attempting to create linked collateral account for member.',
      'Success! Approval Req created for linked collateral account.',
    ),
  );
}
