import React, { useEffect } from 'react';
import { Tab, Header, Icon } from 'semantic-ui-react';
import XTab from '../../common/tabs/XTab';
import { useDispatch } from 'react-redux';
import DefaultFinancialResourceParameters from './DefaultFinancialResourceParameters';
import alertsMetadata from '../../metadata/riskConfigurationsMetadata/riskAlertsThresholdsMetadata';
import stressEngineMetadata from '../../metadata/riskConfigurationsMetadata/stressEngineParamsMetadata';
import pricingMetadata from '../../metadata/riskConfigurationsMetadata/pricingEngineParamsMetadata';

import RiskConfigTable from './RiskConfigTable';
import sections from './riskConfigSections';
import InitialMarginParams from './InitialMarginParams';
import {
  PRICING_ENGINE_PARAMS_TEMPLATE,
  RISK_ALERT_THRESHOLD_TEMPLATE,
  STRESS_ENGINE_TEMPLATE,
} from './newRowTemplates';
import { TABLE_EDIT_ACTIONS } from '../../common/table/constants';
import hasOneRow from './utils';
import { futuresProducts } from '../../reducers/futuresProductReducer';
import FinancialTabs from './FinancialTabs';
import LargeTraderTabs from './LargeTraderTabs';

const panes = [
  {
    url: 'post_trade_risk_alerts',
    menuItem: 'Post-Trade Risk Alerts',
    render: () => (
      <RiskConfigTable
        configSection={sections.RISK_ALERTS_THRESHOLDS}
        metadata={alertsMetadata}
        editable
        template={RISK_ALERT_THRESHOLD_TEMPLATE}
        key={sections.RISK_ALERTS_THRESHOLDS}
      />
    ),
  },
  {
    url: 'stress-engine-parameters',
    menuItem: 'Stress Engine Parameters',
    render: () => (
      <RiskConfigTable
        configSection={sections.STRESS_ENGINE_PARAMS}
        metadata={stressEngineMetadata}
        editable
        template={STRESS_ENGINE_TEMPLATE}
        key={sections.STRESS_ENGINE_PARAMS}
      />
    ),
  },
  {
    url: 'default_financial_resource_parameters',
    menuItem: 'Default Financial Resource Parameters',
    render: () => (
      <Tab.Pane attached={false}>
        <DefaultFinancialResourceParameters />
      </Tab.Pane>
    ),
  },
  {
    url: 'initial_margin_parameters',
    menuItem: 'Initial Margin Parameters',
    render: () => (
      <Tab.Pane attached={false}>
        <InitialMarginParams />
      </Tab.Pane>
    ),
  },
  {
    url: 'pricing_engine_parameters',
    menuItem: 'Pricing Engine Parameters',
    render: () => (
      <RiskConfigTable
        configSection={sections.PRICING_ENGINE_PARAMS}
        metadata={pricingMetadata}
        editable
        template={PRICING_ENGINE_PARAMS_TEMPLATE}
        hideActions={[TABLE_EDIT_ACTIONS.DELETE]}
        isAddNewDisabled={hasOneRow}
        key={sections.PRICING_ENGINE_PARAMS}
      />
    ),
  },
  {
    url: 'large-trader',
    menuItem: 'Large Trader',
    render: () => (
      <Tab.Pane attached={false}>
        <LargeTraderTabs />
      </Tab.Pane>
    ),
  },
  {
    url: 'financial',
    menuItem: 'Financial',
    render: () => (
      <Tab.Pane attached={false}>
        <FinancialTabs />
      </Tab.Pane>
    ),
  },
];

const RiskConfigurations = () => {
  const dispatch = useDispatch();
  useEffect(() => dispatch(futuresProducts()), []);

  return (
    <>
      <Header as="h1" dividing>
        Risk Parameters Configuration
      </Header>
      <XTab panes={panes} />
    </>
  );
};

export default RiskConfigurations;
