import React from 'react';
import { NavLink } from 'react-router-dom';
import { formatDateField, NANO_TO_MILLI_FACTOR } from './riskOverviewMetadata';
import { DateRangeColumnFilter } from '../common/table/Filters';

export default [
  {
    Header: 'Alert ID',
    id: 'alert_id',
    accessor: 'alertId',
    Cell: ({ row }) => (
      <NavLink to={`/risk_alert/${row.original.alertId}/detail`}>
        {' '}
        {row.original.alertId}{' '}
      </NavLink>
    ),
  },
  {
    Header: 'Transaction Time',
    id: 'transaction_time',
    accessor: ({ transactionTime }) => {
      const time = transactionTime.toString();
      // times in backend are in nano seconds, we need to convert those to milliseconds
      const mils = parseInt(
        time.substring(0, time.length - NANO_TO_MILLI_FACTOR),
      );

      return formatDateField(mils);
    },
    Filter: DateRangeColumnFilter,
    width: 200,
  },
  {
    Header: 'Account',
    id: 'account_number',
    accessor: 'accountNumber',
  },
  {
    Header: 'Alert Type',
    id: 'type',
    accessor: 'type',
  },
  {
    Header: 'Alert Status',
    id: 'status',
    accessor: 'status',
  },
  {
    Header: 'Alert Severity',
    id: 'severity',
    accessor: 'severity',
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: 'description',
    width: 400,
  },
  {
    Header: 'Comment',
    id: 'comment',
    accessor: 'comment',
    width: 400,
  },
];
