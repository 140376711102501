import React from 'react';
import { format } from 'erisxkit/client';
import { get } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import { overflowMenu } from '../common/table/commonMetadata';

export default ({ showAddContractModal, showProductModal }) => [
  {
    Header: '',
    columns: [
      {
        Header: 'Product Code',
        accessor: 'symbol',
        id: 'symbol',
      },
      {
        id: 'subExchangeId',
        Header: 'Sub Exchange',
        accessor: 'subExchangeLabel',
      },
      {
        Header: 'Delivery Fee',
        accessor: 'deliveryFee',
        id: 'deliveryFee',
      },
      {
        Header: 'Delivery Fee Currency',
        accessor: 'deliveryFeeType',
        id: 'deliveryFeeType',
      },
      {
        Header: 'Base Asset Type',
        accessor: 'baseAssetType',
        id: 'baseAssetType',
      },
      {
        Header: 'Quoted Asset Type',
        accessor: 'quotedAssetType',
        id: 'quotedAssetType',
      },
      {
        Header: 'Contract Size',
        accessor: (row) => format(get(row, 'contractSize', '')),
        id: 'contractSize',
      },
      {
        Header: 'Delivery',
        accessor: 'delivery',
        id: 'delivery',
      },
      {
        Header: 'Description',
        accessor: 'description',
        id: 'description',
      },
      {
        Header: 'CFTC Reporting Limit',
        accessor: (row) => get(row, 'cftcReportingLimit', '—'),
        id: 'cftcReportingLimit',
      },
      {
        Header: 'Exchange Reportable Limit',
        accessor: (row) => get(row, 'exchangeReportableLimit', '—'),
        id: 'exchangeReportableLimit',
      },
      {
        Header: 'Maximum Reportable Limit',
        accessor: (row) => get(row, 'maximumReportableLimit', '—'),
        id: 'maximumReportableLimit',
      },
      {
        Header: 'CFTC Volume Reporting Limit',
        accessor: (row) => get(row, 'cftc_102bLimit', '—'),
        id: 'cftc_102bLimit',
      },
      {
        Header: 'Settlement Period',
        accessor: (row) => get(row, 'settlementPeriod', '—'),
        id: 'settlementPeriod',
      },
      {
        Header: 'Span Id',
        accessor: (row) => get(row, 'spanId', '—'),
        id: 'spanId',
      },
      overflowMenu({
        items: ({ original }) => [
          <Dropdown.Item
            id="add-futures-contract"
            icon="add"
            onClick={() => showAddContractModal(original)}
            text="Add Contract"
          />,
          <Dropdown.Item
            id="edit-product"
            icon="pencil"
            onClick={() => showProductModal({ type: 'update', ...original })}
            text="Edit Product"
          />,
        ],
      }),
    ],
  },
];
