import _ from 'lodash';
import get from 'lodash/get';
import omit from 'lodash/omit';
import React, { Fragment, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  renderField,
  renderDropdown,
  apiKeyPermissions,
  hideModal,
  rules,
} from 'erisxkit/client';
import apiKeyPermissionStructure from '../../constants/apiKeyPermissions';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import {
  Field,
  reduxForm,
  getFormValues,
  change as reduxChange,
} from 'redux-form';
import {
  Form,
  Modal,
  Button,
  Icon,
  Header,
  FormGroup,
} from 'semantic-ui-react';
import { memberUserPermissionsToStructure } from './MemberUserSettings';
import * as userTypes from '../../constants/userTypes';
import SearcheableMemberSelect from '../Members/SearcheableMemberSelect';
import SearcheableAccountSelect from '../../common/components/SearcheableAccountSelect';
import {
  addClearingAccountToMemberPromiseCreator,
  createMemberUserPromiseCreator,
  fetchMemberUserPermissions,
  fetchMemberUserTypes,
  getMemberUserTypes,
  getUserPermissions,
  getUserStates,
  memberStates,
} from '../../reducers/usersReducer';
import { addMemberToUsersPromiseCreator } from '../../reducers/membersReducer';
import {
  apiCredentialsPermissions as fetchApiCredentialsPermissions,
  getApiCredentialPermissions,
} from '../../reducers/apiCredentialsReducer';
import { FIRM_SECURITY } from '../../constants/userStates';
import Permissions from './Permissions';

const Label = styled.p`
  font-weight: bold;
`;

const API_KEY_FIELD = 'apiKeyPermissions';
const MEMBER_TYPE_FIELD = 'memberUserType';
const PERMISSIONS_FIELD = 'permissions';
const MEMBER_LINKS_FIELD = 'memberLinks';
const ACC_LINKS_FIELD = 'accountLinks';
const STATE_FIELD = 'state';

const CLONEABLE_MEMBER_FIELDS = [
  API_KEY_FIELD,
  MEMBER_TYPE_FIELD,
  PERMISSIONS_FIELD,
  MEMBER_LINKS_FIELD,
  ACC_LINKS_FIELD,
  STATE_FIELD,
];

const CREATE_USER_FORM = 'create_user';

const CreateMember = ({
  onApply,
  type = userTypes.memberUser,
  cloneMember,
  change,
}) => {
  const dispatch = useDispatch();
  const values = useSelector((state) => getFormValues(CREATE_USER_FORM)(state));
  const userPermissions = useSelector(getUserPermissions);
  const memberUserTypes = useSelector(getMemberUserTypes);
  const userStates = useSelector(getUserStates);
  const apiCredentialsPermissions = useSelector(getApiCredentialPermissions);

  const isCloning = useMemo(() => !isEmpty(cloneMember), [cloneMember]);
  const userStatesOptions = useMemo(
    () =>
      userStates.map((state) => ({
        key: state,
        value: state,
        text: state,
      })),
    [userStates],
  );
  const closeModal = () => dispatch(hideModal());

  const onClone = async () => {
    try {
      const newUser = await createMemberUserPromiseCreator(
        {
          ...omit(values, ['accountLinks', 'memberLinks']),
        },
        dispatch,
      );

      const userId = get(newUser, 'userId', '');
      const members = get(cloneMember, 'memberLinks', []);
      const accountIds = get(cloneMember, 'accountLinks', []).map(
        (link) => link?.accountId,
      );

      for (const member of members) {
        await addMemberToUsersPromiseCreator(
          {
            memberId: member?.memberId,
            userIds: [userId],
          },
          dispatch,
        );
      }
      if (accountIds?.length > 0) {
        addClearingAccountToMemberPromiseCreator(
          {
            accountIds,
            userId,
          },
          dispatch,
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createCallback = isCloning ? onClone : onApply;

  const onConfirm = () => {
    createCallback();
    closeModal();
  };
  // Populate form with values from member to clone if necessary
  useEffect(() => {
    if (isCloning) {
      CLONEABLE_MEMBER_FIELDS.forEach((field) => {
        const initialValue = get(cloneMember, field, null);
        if (field === MEMBER_LINKS_FIELD && initialValue) {
          const userIds = initialValue.map((link) => link?.memberId);
          dispatch(change(field, userIds));
          return;
        }

        if (field === ACC_LINKS_FIELD && initialValue) {
          const links = initialValue.map((link) => link?.accountId);
          dispatch(change(field, links));
          return;
        }

        if (field === STATE_FIELD) {
          dispatch(change(field, FIRM_SECURITY));
          return;
        }

        if (initialValue) dispatch(change(field, initialValue));
      });
    }
  }, [cloneMember, isCloning]);

  useEffect(() => {
    dispatch(fetchApiCredentialsPermissions());
    dispatch(fetchMemberUserPermissions());
    dispatch(fetchMemberUserTypes());
    dispatch(memberStates());
  }, []);

  const changePermissions = (e, data, permissionType) => {
    dispatch(reduxChange(CREATE_USER_FORM, permissionType, data.value));
  };

  const setPermissions = () => {
    let permissions;

    if (values?.[PERMISSIONS_FIELD]) {
      permissions = values[PERMISSIONS_FIELD];
    } else if (cloneMember?.permissions) {
      permissions = cloneMember.permissions;
    } else {
      permissions = userPermissions;
    }

    return permissions;
  };

  const isValidEmail = useMemo(() => {
    const email = get(values, 'email');
    return !!(email && !rules.email(email));
  }, [values, rules]);

  return (
    <Fragment>
      <Header icon="add" content={`${isCloning ? 'Clone' : 'Create'} User`} />
      <Modal.Content>
        <Form>
          <Field
            name="email"
            component={renderField}
            label="Please enter email of the user to be created:"
            required
            validate={[rules.required, rules.email]}
          />
          {type === userTypes.memberUser && (
            <Fragment>
              <FormGroup>
                <Field
                  name="firstName"
                  component={renderField}
                  label="First Name"
                />
                <Field
                  name="lastName"
                  component={renderField}
                  label="Last Name"
                />
              </FormGroup>
              <Label>User Permissions ()</Label>
              <hr></hr>
              <Permissions
                permissionsWithHeaderStructure={memberUserPermissionsToStructure(
                  cloneMember?.permissions,
                )}
                permissions={setPermissions()}
                name={PERMISSIONS_FIELD}
                onChange={(e, data) => {
                  changePermissions(e, data, PERMISSIONS_FIELD);
                }}
                dataWithHeader
              />
              <Label>API Key Permissions</Label>
              <hr></hr>
              <Permissions
                permissionsWithHeaderStructure={memberUserPermissionsToStructure(
                  cloneMember?.apiKeyPermissions,
                  apiKeyPermissionStructure,
                )}
                permissions={get(
                  values,
                  API_KEY_FIELD,
                  cloneMember?.apiKeyPermissions,
                )}
                name={API_KEY_FIELD}
                onChange={(e, data) => {
                  changePermissions(e, data, API_KEY_FIELD);
                }}
                dataWithHeader
              />
              <Field
                name={MEMBER_TYPE_FIELD}
                component={renderDropdown}
                label="Member User Type"
                selection
                clearable
                selectOnBlur={false}
                options={memberUserTypes}
                defaultValue={null}
              />
              {isCloning && (
                <>
                  <Field
                    multiple
                    component={SearcheableMemberSelect}
                    id="memberLinks"
                    label="Members"
                    name="memberLinks"
                    width={8}
                    initialFilter={[
                      {
                        attr: 'id',
                        op: 'eq',
                        value: get(cloneMember, 'memberLinks', []).map(
                          (link) => link?.memberId,
                        ),
                      },
                    ]}
                  />
                  <Field
                    multiple
                    component={SearcheableAccountSelect}
                    id="accountLinks"
                    label="Account Links"
                    name="accountLinks"
                    width={8}
                    initialFilter={[
                      {
                        attr: 'account_id',
                        op: 'eq',
                        value: get(cloneMember, 'accountLinks', []).map(
                          (link) => link?.accountId,
                        ),
                      },
                    ]}
                  />
                  <Field
                    name={STATE_FIELD}
                    component={renderDropdown}
                    label="Member State"
                    search
                    selection
                    clearable
                    selectOnBlur={false}
                    options={userStatesOptions}
                  />
                </>
              )}
            </Fragment>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={closeModal}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button color="green" onClick={onConfirm} disabled={!isValidEmail}>
          <Icon name="add" /> Create
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default reduxForm({
  form: CREATE_USER_FORM,
  initialValues: {
    memberUserType: null,
  },
})(CreateMember);
