import { get } from 'lodash';
import React, { Fragment } from 'react';
import { Form, Dropdown, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { withFilters, inputOnChange } from 'erisxkit/client';
import MemberSelectionContainer from '../common/containers/MemberSelectionContainer';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import addressMetadata from '../metadata/addressSurveillanceMetadata';
import transactionMetadata from '../metadata/transactionSurveillanceMetadata';
import Table from '../common/table/ExternalTable';

const ExternalTableWithFilter = withFilters(Table);

const filters = (type, assetTypes = []) => [
  {
    placeholder: 'Asset Type',
    component: Dropdown,
    name: 'asset_type',
    key: 'asset_type',
    id: 'asset_type',
    label: 'Asset Type',
    onChange: inputOnChange,
    options: assetTypes,
    selection: true,
    search: true,
  },
  {
    placeholder: 'Address',
    component: Form.Input,
    name: 'address',
    id: 'address',
    label: 'Address',
    onChange: inputOnChange,
  },
  {
    placeholder: 'Transaction',
    component: Form.Input,
    name: 'transaction_hash',
    id: 'transaction_hash',
    label: 'Transaction',
    onChange: inputOnChange,
    show: type === 'Transaction',
  },
  {
    component: MemberSelectionContainer,
    name: 'member_id',
    id: 'member_id',
    onChange: inputOnChange,
  },
  {
    component: AccountSelectionContainer,
    name: 'account_id',
    id: 'account_id',
    onChange: inputOnChange,
    show: type === 'Transaction',
  },
];

const Surveillance = ({
  data,
  loading,
  count,
  onFetchData,
  type,
  assetTypes,
}) => (
  <Fragment>
    <Header as="h1" dividing>{`Crypto ${type} Analyses`}</Header>
    <ExternalTableWithFilter
      title={`crypto${type}Analyses`}
      noDataText={`No crypto ${type} analyses were found.`}
      data={data}
      count={count}
      showPageSizeOptions={false}
      showPagination
      columns={type === 'Transaction' ? transactionMetadata : addressMetadata}
      onFetchData={onFetchData}
      loading={loading}
      sortable
      getTrProps={(state, rowInfo) => ({
        className: `${get(rowInfo, ['original', 'flagged'], false) ? 'negative' : ''}`,
      })}
      className="-highlight"
      filters={filters(type, assetTypes)}
    />
  </Fragment>
);

Surveillance.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      assetType: PropTypes.string,
      address: PropTypes.string,
      riskScore: PropTypes.string,
      rawData: PropTypes.string,
      updatedAt: PropTypes.string,
      userIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  count: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onFetchData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

Surveillance.defaultProps = {
  data: [],
  count: 0,
  loading: false,
  type: 'Address',
};

export default Surveillance;
