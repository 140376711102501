import { TABLE_INPUT_TYPES } from '../../common/table/constants';

const marginProfilesMetadata = (futuresProducts) => [
  {
    Header: 'Effective Trade Date',
    id: 'effectiveTradeDate',
    accessor: 'effectiveTradeDate',
    editInputType: TABLE_INPUT_TYPES.DATE,
    required: true,
    minWidth: 170,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
    editInputType: TABLE_INPUT_TYPES.LIST,
    options: futuresProducts,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Margin Profile',
    id: 'marginProfile',
    accessor: 'marginProfile',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Initial Margin Rate Multiplier',
    id: 'initialMarginRateMultiplier',
    accessor: 'initialMarginRateMultiplier',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 170,
  },
  {
    Header: 'Maintenance Margin Rate Multiplier',
    id: 'maintenanceMarginRateMultiplier',
    accessor: 'maintenanceMarginRateMultiplier',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Discretionary Add On',
    id: 'discretionaryAddOn',
    accessor: 'discretionaryAddOn',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 180,
  },
  {
    Header: 'Max Initial Margin Rate',
    id: 'maxInitialMarginRate',
    accessor: 'maxInitialMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 180,
  },
  {
    Header: 'Max Maintenance Margin Rate',
    id: 'maxMaintenanceMarginRate',
    accessor: 'maxMaintenanceMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 170,
  },
  {
    Header: 'Min Initial Margin Rate',
    id: 'minInitialMarginRate',
    accessor: 'minInitialMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 170,
  },
  {
    Header: 'Min Maintenance Margin Rate',
    id: 'minMaintenanceMarginRate',
    accessor: 'minMaintenanceMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 170,
  },
  {
    Header: 'Spreads Max Initial Margin Rate',
    id: 'spreadsMaxInitialMarginRate',
    accessor: 'spreadsMaxInitialMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 170,
  },
  {
    Header: 'Spreads Max Maintenance Margin Rate',
    id: 'spreadsMaxMaintenanceMarginRate',
    accessor: 'spreadsMaxMaintenanceMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 210,
  },
  {
    Header: 'Spreads Min Initial Margin Rate',
    id: 'spreadsMinInitialMarginRate',
    accessor: 'spreadsMinInitialMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 170,
  },
  {
    Header: 'Spreads Min Maintenance Margin Rate',
    id: 'spreadsMinMaintenanceMarginRate',
    accessor: 'spreadsMinMaintenanceMarginRate',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 210,
  },
  {
    Header: 'Public Name',
    id: 'publicName',
    accessor: 'publicName',
    minWidth: 150,
  },
  {
    Header: 'Publish To Website',
    id: 'publishToWebsite',
    accessor: 'publishToWebsite',
    editInputType: TABLE_INPUT_TYPES.BOOLEAN,
    minWidth: 180,
    required: true,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];

export default marginProfilesMetadata;
