import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions, createAction } from 'redux-actions';
import { get } from 'lodash';
import Moment from 'moment-timezone';

import {
  FCM_MARGIN_CALL_CALCULATIONS,
  FCM_MARGIN_CALL_REQUIREMENTS,
  SET_MARGIN_REQUIREMENTS,
  UPDATE_INITIAL_MARGIN_TRADE_DATE,
} from '../constants/actionTypes';

export const fetchFcmMarginCalculations = createRoutine(
  FCM_MARGIN_CALL_CALCULATIONS,
);
export const fetchFcmMarginRequirements = createRoutine(
  FCM_MARGIN_CALL_REQUIREMENTS,
);
export const setMarginRequirements = createRoutine(SET_MARGIN_REQUIREMENTS);
export const setMarginRequirementsPromiseCreator = promisifyRoutine(
  setMarginRequirements,
);

export const updateInitialMarginTradeDate = createAction(
  UPDATE_INITIAL_MARGIN_TRADE_DATE,
);

// Reducer
export default handleActions(
  {
    [fetchFcmMarginCalculations.SUCCESS]: (state, { payload }) => ({
      ...state,
      fcmMarginCalculations: payload.map((calc) => ({
        ...calc,
        spanMarginRequirementOverride: '',
        concentrationAddOnOverride: '',
        stressAddOnOverride: '',
        cover_2AddOnOverride: '',
        discretionaryAddOnOverride: '',
      })),
    }),
    [fetchFcmMarginRequirements.SUCCESS]: (state, { payload }) => ({
      ...state,
      fcmMarginRequirement: payload,
      requirementList: payload?.marginRequirements?.map((req) => ({
        ...req,
        type: payload?.type,
        time: payload?.time,
        tradeDate: payload?.tradeDate,
        state: payload?.pending,
      })),
    }),
    [updateInitialMarginTradeDate]: (state, { payload }) => ({
      ...state,
      date: payload,
    }),
  },
  {
    date: Moment().format('YYYY-MM-DD'),
  },
);

export const getFcmMarginCalculations = (state) =>
  get(state, ['initialMargins', 'fcmMarginCalculations'], []);
export const getFcmMarginRequirements = (state) =>
  get(state, ['initialMargins', 'fcmMarginRequirement'], {});
export const getFcmMarginRequirementList = (state) =>
  get(state, ['initialMargins', 'requirementList'], []);
export const getInitialMarginTradeDate = (state) =>
  get(state, ['initialMargins', 'date'], '');
