import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import * as actionTypes from '../constants/actionTypes';
import {
  rejectedJournals,
  closeoutJournals,
} from '../reducers/journalsReducer';

export default function* watchJournals() {
  yield takeLatest(
    rejectedJournals.TRIGGER,
    createSaga(
      rejectedJournals,
      actionTypes.REJECTED_JOURNALS,
      null,
      null,
      {},
      {},
      { withPage: true },
    ),
  );

  yield takeLatest(
    closeoutJournals.TRIGGER,
    createSaga(
      closeoutJournals,
      actionTypes.CLOSEOUT_JOURNALS,
      null,
      null,
      {},
      {},
      { withPage: true },
    ),
  );
}
