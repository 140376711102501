import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { get } from 'lodash';

export const FEES = 'FEES';

export const fetchFees = createRoutine(FEES);

export default handleActions(
  {
    [fetchFees.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

function formatFeeProfilesForDropdown(feeProfiles) {
  var toReturn = [];
  for (var key in feeProfiles) {
    toReturn.push({
      text: key,
      key: feeProfiles[key]['feeProfileId'],
      value: feeProfiles[key]['feeProfileId'],
      description: feeProfiles[key]['feeProfileId'],
    });
  }
  return toReturn;
}

export const getFeeProfilesForDropdown = function (state) {
  return formatFeeProfilesForDropdown(get(state, ['fees', 'fees'], {}));
};
