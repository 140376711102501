import React, { PureComponent } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import EnvPromise from '../config/env';
import {
  fundsSegregationBalances as fetchFundsSegregationBalances,
  getFundsSegregationBalances,
} from '../reducers/balancesReducer';
import FundsSegregationBalances from '../components/FundsSegregationBalances';
import { FUNDS_SEGREGATION_BALANCES } from '../constants/actionTypes';

const mapStateToProps = (state) => ({
  fundsSegregationBalances: getFundsSegregationBalances(state),
  fundsSegregationBalancesLoading: createLoadingSelector([
    FUNDS_SEGREGATION_BALANCES,
  ])(state),
});

const mapDispatchToProps = {
  fetchFundsSegregationBalances,
};

export const COMBINED = 'combined';

export const getBalancesByAssetType = (balances = []) => {
  const balancesByAssetType = {};
  balances.forEach(({ fd, amounts }) => {
    Object.assign(balancesByAssetType, {
      [COMBINED]: {
        assetType: COMBINED,
        ...balancesByAssetType[COMBINED],
        [fd]: get(amounts, COMBINED, {}),
      },
    });
    get(amounts, 'byAssetType', []).forEach(({ assetType, ...rest }) =>
      Object.assign(balancesByAssetType, {
        [assetType]: {
          assetType,
          ...balancesByAssetType[assetType],
          [fd]: rest,
        },
      }),
    );
  });
  return balancesByAssetType;
};

class FundsSegregationBalancesContainer extends PureComponent {
  state = {
    segregatedBufferCriticalThreshold: '',
    segregatedBufferWarningThreshold: '',
  };

  componentDidMount = () => {
    this.props.fetchFundsSegregationBalances();
    EnvPromise.then(
      ({
        segregatedBufferWarningThreshold,
        segregatedBufferCriticalThreshold,
      }) =>
        this.setState({
          segregatedBufferWarningThreshold,
          segregatedBufferCriticalThreshold,
        }),
    );
  };

  render = () => {
    const balances = get(this.props.fundsSegregationBalances, 'balances', []);
    const balancesByAssetType = getBalancesByAssetType(balances);
    return (
      <FundsSegregationBalances
        assetTypes={Object.keys(balancesByAssetType)}
        balances={balances}
        balancesByAssetType={balancesByAssetType}
        fetchFundsSegregationBalances={this.props.fetchFundsSegregationBalances}
        homeAssetType={get(
          this.props.fundsSegregationBalances,
          'homeAssetType',
          '',
        )}
        oi={get(this.props.fundsSegregationBalances, 'oi', '')}
        loading={this.props.fundsSegregationBalancesLoading}
        segregatedBufferCriticalThreshold={
          this.state.segregatedBufferCriticalThreshold
        }
        segregatedBufferWarningThreshold={
          this.state.segregatedBufferWarningThreshold
        }
      />
    );
  };
}

FundsSegregationBalancesContainer.propTypes = {
  fetchFundsSegregationBalances: PropTypes.func.isRequired,
  fundsSegregationBalances: PropTypes.objectOf(PropTypes.any),
  fundsSegregationBalancesLoading: PropTypes.bool,
};

FundsSegregationBalancesContainer.defaultProps = {
  fundsSegregationBalances: {},
  fundsSegregationBalancesLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundsSegregationBalancesContainer);
