import React, { Fragment } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderDropdown } from 'erisxkit/client';
import { removeAllWhiteSpaces } from '../../utils/methods';

const AddCryptoAddress = ({ hideModal, confirm, assetTypes }) => (
  <Fragment>
    <Modal.Header>Add Crypto Address</Modal.Header>
    <Modal.Content>
      <Form>
        <Field
          component={renderField}
          placeholder="Label/Nickname"
          type="text"
          name="label"
        />
        <Field
          component={renderDropdown}
          placeholder="Currency Type"
          fluid
          selection
          options={assetTypes}
          name="assetType"
        />
        <Field
          component={renderField}
          placeholder="Address"
          type="text"
          name="address"
          normalize={(value) => removeAllWhiteSpaces(value)}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button id="cancel" onClick={hideModal}>
        Cancel
      </Button>
      <Button id="confirm" onClick={() => confirm('crypto')}>
        Confirm
      </Button>
    </Modal.Actions>
  </Fragment>
);

export default reduxForm({
  form: 'add_linked_account',
})(AddCryptoAddress);
