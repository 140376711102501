import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select, Dropdown } from 'semantic-ui-react';
import { getAssetTypesAsOptions } from '../../selectors';
import { getSelectedMemberId } from '../../reducers/membersReducer';
import {
  getFilteredLinkedMemberAssetAccounts,
  getLinkedAccountLabel,
  fetchLinkedMemberAssetAccounts,
} from '../../reducers/linkedMemberAssetAccountsReducer';

const mapStateToProps = (state, ownProps) => ({
  selectedMemberId: getSelectedMemberId(state),
  linkedMemberAssetAccounts: getFilteredLinkedMemberAssetAccounts(
    state,
    ownProps,
  ),
  linkedMemberAssetAccountsLoading: createLoadingSelector([
    fetchLinkedMemberAssetAccounts._PREFIX,
  ])(state),
});

class LinkedMemberAssetAccountSelectionContainer extends Component {
  render = () => {
    return <Form.Field {...this.props} control={this.select} />;
  };

  // Providing the Select component separately like this allows us
  // to translate this.props.input into props for the Select component
  // for compatibility with redux-form.
  select = () => {
    const {
      placeholder,
      linkedMemberAssetAccounts,
      linkedMemberAssetAccountsLoading,
    } = this.props;

    const reduxFormProps = this.props.input
      ? {
          // These allow redux-form to set value and respond to change.
          ...this.props.input,
          onChange: (e, { value }) => this.props.input.onChange(value),
        }
      : {};

    return (
      <Select
        {...reduxFormProps}
        loading={linkedMemberAssetAccountsLoading}
        search
        placeholder={placeholder}
        options={linkedMemberAssetAccounts.map((a) => ({
          key: a.id,
          text: getLinkedAccountLabel(a),
          value: a.id,
        }))}
        clearable
      />
    );
  };
}

LinkedMemberAssetAccountSelectionContainer.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.array,
  filter: PropTypes.shape({
    assetType: PropTypes.string,
    memberId: PropTypes.string,
  }),
  linkedMemberAssetAccounts: PropTypes.array.isRequired,
  linkedMemberAssetAccountsLoading: PropTypes.bool.isRequired,
};

LinkedMemberAssetAccountSelectionContainer.defaultProps = {
  name: 'linkedMemberAssetAccount',
  placeholder: 'Select SSI...',
  label: 'SSI',
};

export default connect(mapStateToProps)(
  LinkedMemberAssetAccountSelectionContainer,
);
