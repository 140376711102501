import { connect } from 'react-redux';
import React, { Component } from 'react';
import get from 'lodash/get';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import {
  createLoadingSelector,
  filteredArrayToAttrValue,
  showModal,
  sortedArrayToAttrValue,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import ClearingAccounts from '../../components/ClearingAccounts';
import clearingAccountsMetadata from '../../metadata/clearingAccountsMetadata';
import {
  selectAccountHistory,
  updateAccount,
} from '../../actions/accountsActions';
import {
  getAccountCount,
  getAccountListWithBalances,
  fetchAccountsV2,
} from '../../reducers/accountsReducer';
import { assetTypes } from '../../actions/utilActions';
import { getAssetTypes } from '../../selectors';
import { CREATE_ACCOUNT } from '../../constants/modalTypes';
import history from '../../constants/history';
import { getTotalUSDValues } from '../../reducers/balancesReducer';
import { CLEARING } from '../../constants/accountTypes';
import * as userTypes from '../../constants/userTypes';
import {
  fetchMemberUserTypes,
  fetchUsersPromiseCreator,
  getMemberUserTypes,
} from '../../reducers/usersReducer';
import { ACCOUNTS_V2 } from '../../constants/actionTypes';

const mapStateToProps = (state) => ({
  accountsLoading: createLoadingSelector([ACCOUNTS_V2])(state),
  accountCount: getAccountCount(state),
  assetTypesList: getAssetTypes(state),
  accountListWithBalances: getAccountListWithBalances(state),
  totalBalances: getTotalUSDValues(state),
  memberUserTypes: getMemberUserTypes(state),
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchAccountsV2,
      selectAccountHistory,
      assetTypes,
      showModal,
      updateAccount,
      fetchMemberUserTypes,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      fetchUsersPromiseCreator,
    },
    dispatch,
  ),
});

const getFilterOp = (columns, id) => {
  const column = columns.find((c) => c.id === id);
  return column.filterOp || DEFAULT_FILTER_OP;
};

class ClearingMemberAccountsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { rowPopupState: {} };
  }

  componentDidMount = () => {
    this.props.fetchMemberUserTypes();
  };

  openPopup = (rowClicked, type) => {
    this.setState((prevState) => ({
      ...prevState,
      rowPopupState: {
        ...prevState.rowPopupState,
        [`popup_${type}_${rowClicked}`]: true,
      },
    }));
  };

  closePopup = (rowClicked, type) => {
    this.setState((prevState) => ({
      ...prevState,
      comment: '',
      rowPopupState: {
        ...prevState.rowPopupState,
        [`popup_${type}_${rowClicked}`]: undefined,
      },
    }));
  };
  onFetchData = (state) => {
    const {
      balances,
      userAccounts,
      fetchUsersPromiseCreator,
      isInvestmentAdvisor,
      memberUsers,
    } = this.props;

    let filter = [];
    let completeUserAccountList = userAccounts ? [...userAccounts] : [];

    if (!userAccounts && !balances) {
      filter = filter.concat([
        { attr: 'account_type', op: 'eq', value: CLEARING },
      ]);
    }

    let sort;
    const { sortBy, filters } = state;
    if (sortBy && sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }

    if (filters && filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrValue(
          filters.map((each) => {
            return {
              ...each,
              op: getFilterOp(state.columns, each.id),
            };
          }),
        ),
      );
    }

    // For an Investment Advisor user we show their accounts AND
    // The accounts of their member users
    if (isInvestmentAdvisor) {
      // Since we can't get accounts by memberUser, we fetch the member users
      // and map the account Ids linked to them
      const userFilters = [
        {
          attr: 'type',
          op: 'eq',
          value: this.props.memberUserTypes.map((el) => el['value']),
        },
      ];

      userFilters.push({
        attr: 'user_id',
        op: 'eq',
        value: memberUsers || [],
      });

      let authorizedUsersAccountList = [];

      fetchUsersPromiseCreator({
        limit: state.pageSize,
        offset: state.pageIndex * state.pageSize,
        page: state.pageIndex,
        filter: userFilters,
        sort,
      })
        .then((result) => {
          const users = get(result, 'users', []);
          // Get list of account IDs owned by the member users
          authorizedUsersAccountList = users.reduce((ids, acc) => {
            const memberUserAccounts = get(acc, 'accountLinks', []);
            return [
              ...ids,
              ...memberUserAccounts.map((account) => account.accountId),
            ];
          }, []);
          // The complete account list has the accounts of the
          // and the accounts of the member users assigned to it
          completeUserAccountList = [
            ...completeUserAccountList,
            ...authorizedUsersAccountList,
          ];
        })
        .then(() => {
          // We now fetch accounts filtering by the ID of the accounts of the Member
          // and all the accountIds of the member users' accounts
          if (userAccounts || completeUserAccountList.length > 0) {
            filter = filter.concat([
              { attr: 'account_id', value: completeUserAccountList, op: 'eq' },
            ]);
          }
          this.props.fetchAccountsV2({
            limit: state.pageSize,
            offset: state.pageIndex * state.pageSize,
            page: state.pageIndex,
            balances,
            showUsers: !balances,
            filter,
            sort,
          });
        });
    } else {
      // Its not an Investment Advisor so the accounts to show are only the ones
      // assigned to the Member
      if (userAccounts) {
        filter = filter.concat([
          { attr: 'account_id', value: userAccounts, op: 'eq' },
        ]);
      }
      this.props.fetchAccountsV2({
        limit: state.pageSize,
        offset: state.pageIndex * state.pageSize,
        page: state.pageIndex,
        // flag for getting total balance of each account
        balances,
        // flag for showing users of each account by email
        showUsers: !balances,
        filter,
        sort,
      });
    }
  };

  selectAccount = (id) => {
    this.props.selectAccountHistory(id);
    history.push(
      `/${this.props.balances ? 'balances' : 'clearing_accounts'}/${id}`,
    );
  };

  updateAccount = (account, key) => {
    const { accountId } = account;
    const stateKey = `${key}State`;
    const commentKey = `${key}Comment`;
    let initialState = '';
    if (key === 'aml') {
      initialState = 'unflagged';
    }
    if (key === 'locked') {
      initialState = 'unlocked';
    }
    let update = {};
    if (!account[stateKey] || account[stateKey] === initialState) {
      update = {
        [stateKey]: key === 'aml' ? 'flagged' : 'locked',
        [commentKey]: this.state.comment,
      };
    } else {
      update = {
        [stateKey]: initialState,
        [commentKey]: this.state.comment,
      };
    }

    this.setState({
      comment: '',
      rowPopupState: {},
    });
    this.props.updateAccount({
      accountId,
      update,
    });
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  createAccount = () => {
    const {
      firmCode = '',
      name = '',
      memberId = '',
    } = this.props.personalValues || {};
    const memberValues = {
      firmCode,
      accountDesignation: 'C',
      accountName: name,
      memberId,
    };
    this.props.showModal(CREATE_ACCOUNT, {
      type: 'clearing',
      selectedAccount: memberValues,
    });
  };

  onClose = () => {
    this.setState({
      comment: '',
    });
  };

  render() {
    const {
      selectAccountHistory,
      assetTypesList,
      userId,
      addAccount,
      accountCount = 0,
      accountListWithBalances = [],
      balances,
      detailsView,
      wizardView,
      removeAccount,
      addLedgerAccounts,
      removeLedgerAccounts,
      isInvestmentAdvisor,
      memberId,
    } = this.props;
    const { comment } = this.state;
    const popup = {
      rowPopupState: this.state.rowPopupState,
      open: this.openPopup,
      close: this.closePopup,
    };
    const metadata = clearingAccountsMetadata(
      this.updateAccount,
      this.handleChange,
      this.state.comment,
      this.onClose,
      balances,
      assetTypesList,
      wizardView,
      removeLedgerAccounts,
      { isRIA: isInvestmentAdvisor, memberId },
      popup,
    );

    return (
      <ClearingAccounts
        data={accountListWithBalances}
        metadata={metadata}
        createAccount={this.createAccount}
        addAccount={addAccount}
        addLedgerAccount={addLedgerAccounts}
        userId={userId}
        selectAccount={this.selectAccount}
        onFetchData={this.onFetchData}
        count={accountCount}
        loading={this.props.accountsLoading}
        userAccounts={this.props.userAccounts}
        balances={balances}
        detailsView={detailsView}
        wizardView={wizardView}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClearingMemberAccountsContainer);
