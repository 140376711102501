import React, { Component, Fragment } from 'react';
import { Modal, Button, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { hideModal } from 'erisxkit/client';

const mapDispatchToProps = {
  hideModal,
};

class AdminKey extends Component {
  state = {
    password: '',
  };

  handleChange = (e, obj) => {
    const change = { [obj.name]: obj.value };
    this.setState(change);
  };

  render = () => {
    const { hideModal } = this.props;
    return (
      <Fragment>
        <Modal.Header>Please confirm your Admin Key:</Modal.Header>
        <Modal.Content>
          <Input name="password" type="password" onChange={this.handleChange} />
        </Modal.Content>
        <Modal.Actions>
          <Button secondary id="cancel" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            primary
            id="confirm"
            onClick={() => this.props.submit(this.state.password)}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Fragment>
    );
  };
}

export default connect(null, mapDispatchToProps)(AdminKey);
