import React from 'react';
import { connect } from 'react-redux';
import { Header, Button, Segment, Popup } from 'semantic-ui-react';
import cogoToast from 'cogo-toast';
import { getCurrentUser } from '../reducers/usersReducer';
import { getLoggedInUser } from '../selectors';
import { showModal } from 'erisxkit/client';
import { CREATE_ADMIN_KEY } from '../constants/modalTypes';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  authUser: getLoggedInUser(state),
});

const mapDispatchToProps = {
  showModal,
};

const ProfileContainer = ({ user = {}, authUser = {}, showModal, ...rest }) => (
  <section>
    <Header
      as="h2"
      content={`${authUser.name || ''} - ${authUser.email || ''}`}
      subheader={user.userId}
    />
    <Segment.Group horizontal>
      <Segment padded>
        <h3>Admin Key</h3>
        <Button
          content="Create Admin Key"
          onClick={() => showModal(CREATE_ADMIN_KEY)}
        />
      </Segment>
      <Segment padded>
        <h3>UI Settings</h3>
        <Popup
          trigger={
            <Button
              className="reset-columns"
              negative
              onClick={() => {
                const preferences =
                  JSON.parse(localStorage.getItem('XTable')) || {};
                delete preferences[user.userId];
                localStorage.setItem('XTable', JSON.stringify(preferences));
                cogoToast.info('Column widths reset.');
              }}
            >
              Reset Column Widths
            </Button>
          }
          header="Reset all column widths?"
          content="Click this button to reset all table columns to their default widths."
          on="hover"
        />
      </Segment>
    </Segment.Group>
  </section>
);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
