import auth0 from 'auth0-js';
import moment from 'moment';
import history from '../constants/history';
import EnvPromise from '../config/env';

// refresh buffer for access_tokens, 30 seconds.
const REFRESH_BUFFER = 60 * 1000;
const getRedirectUri = ({redirectUri}) => {
  if (redirectUri.includes('localhost')) {
    return `http://${window.location.host}/login`;
  } else {
    return redirectUri;
  }
}
export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getSession = this.getSession.bind(this);
    this.env = '';
  }

  auth0() {
    return EnvPromise.then((env) => {
      this.env = env;
      return new auth0.WebAuth({
        // Env variables are converted to camelCase by axios convertors automatically
        // TODO/FIXME: straighten this out, would prefer not to have this translation.
        domain: env.domain,
        clientID: env.clientId,
        redirectUri: getRedirectUri(env),
        audience: env.auth0ApiAud,
        responseType: 'token',
        scope: 'openid profile email read:clearing_api',
      });
    });
  }

  handleAuthentication() {
    this.auth0({}).then((auth) => {
      auth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken) {
          this.startInterval();
          this.setSession(authResult);
          history.push('/');
        } else if (err) {
          // Pass the error message back to Auth0
          auth.authorize({
            prompt: 'login',
            errorDescription: err.errorDescription,
          });
        }
      });
    });
  }

  startInterval = () => {
    const { expiresIn } = this.getSession();
    if (expiresIn) {
      this.interval = setInterval(
        () => this.checkSession(this.env.clientID),
        this.getInterval(),
      );
    }
  };

  getInterval = () => {
    const { expiresIn } = this.getSession();
    if (expiresIn < REFRESH_BUFFER) {
      return (expiresIn * 1000) / 2;
    }
    return expiresIn * 1000 - REFRESH_BUFFER;
  };

  setSession = ({ expiresIn, accessToken }) => {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('expires_in', expiresIn);
  };

  getSession = () => {
    const accessToken = localStorage.getItem('access_token');
    const expiresAt = localStorage.getItem('expires_at');
    const expiresIn = localStorage.getItem('expires_in');

    const session = {
      accessToken,
      expiresAt,
      expiresIn,
    };

    return session;
  };

  logout(params) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('ledger_id');

    EnvPromise.then((env) => {
      this.auth0({}).then((auth) =>
        auth.logout({
          clientID: env.clientId,
          returnTo: getRedirectUri(env),
          ...params,
        }),
      );
    });
  }

  checkSession = (clientID) => {
    this.auth0({ ...(clientID && { clientID }) }).then((auth) => {
      auth.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken) {
          this.setSession(authResult);
          return auth;
        }
        if (err) {
          // If there was an error, e.g. consent_required,
          // then send the user to the login page properly.
          auth.authorize({ prompt: 'login' });
        }
      });
    });
  };

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    const { expiresAt } = this.getSession();
    return new Date().getTime() < expiresAt;
  }

  login() {
    this.auth0().then((auth) => auth.authorize({ prompt: 'login' }));
  }
}
