import { TABLE_INPUT_TYPES } from '../../common/table/constants';

const riskAdjustmentsMetadata = (futuresProducts) => [
  {
    Header: 'Effective Trade Date',
    id: 'effectiveTradeDate',
    accessor: 'effectiveTradeDate',
    editInputType: TABLE_INPUT_TYPES.DATE,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
    editInputType: TABLE_INPUT_TYPES.LIST,
    options: futuresProducts,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Expiration Front',
    id: 'expirationFront',
    accessor: 'expirationFront',
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Expiration Back',
    id: 'expirationBack',
    accessor: 'expirationBack',
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Cash Basis Adjustment',
    id: 'cashBasisAdjustment',
    accessor: 'cashBasisAdjustment',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 210,
  },
  {
    Header: 'Calendar Adjustment',
    id: 'calendarAdjustment',
    accessor: 'calendarAdjustment',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 210,
  },
  {
    Header: 'Funding Adjustment',
    id: 'fundingAdjustment',
    accessor: 'fundingAdjustment',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 210,
  },
  {
    Header: 'Spread Charge',
    id: 'spreadCharge',
    accessor: 'spreadCharge',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 180,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];

export default riskAdjustmentsMetadata;
