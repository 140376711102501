import React, { useEffect, useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import { createLoadingSelector, hideModal, showModal } from 'erisxkit/client';
import Member from '../../../ts/models/Member.model';
import XTable7 from '../../../common/table/XTable7';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import BlockTradeRequest, {
  BlockTradeRequestAction,
} from '../../../ts/models/BlockTrades/BlockTradeRequest.model';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  GET_BLOCK_TRADE_REQUESTS_V2,
  approveBlockTradePromise,
  fetchBlockTradeRequestsV2,
  getBlockTradeRequests,
  rejectBlockTradePromise,
} from '../../../reducers/blockTradeRequestsReducer';
import {
  fetchTradingParticipants,
  getParticipantList,
} from '../../../reducers/participantManagementReducer';
import {
  ApproveBlockTradeRequestForm,
  RejectBlockTradeRequestForm,
} from './BlockTradeRequestForm';
import { APPROVE_REJECT_BLOCK_TRADE_REQUEST } from '../../../constants/modalTypes';
import { Row } from 'react-table';
import { RootState } from '../../../ts/types/store';
import blockTradeRequestsConfirmMetadata from '../../../metadata/blockTradeRequestConfirmMetadata';
import blockTradeRequestsMetadata from '../../../metadata/blockTradeRequestsMetadata';

interface Props {
  selectedMember: Member;
}
const BlockTradeRequests = ({ selectedMember }: Props) => {
  const dispatch = useAppDispatch();
  const blockTradeRequests: BlockTradeRequest[] = useAppSelector(
    getBlockTradeRequests,
  );
  const firmCode = useMemo(() => selectedMember?.firmCode, [selectedMember]);

  const participantList = useAppSelector(getParticipantList);

  const loading = useAppSelector((state: RootState) =>
    createLoadingSelector([GET_BLOCK_TRADE_REQUESTS_V2])(state),
  );

  useEffect(() => {
    if (firmCode) {
      dispatch(fetchBlockTradeRequestsV2({ participantId: firmCode }));
      dispatch(
        fetchTradingParticipants({ tradingParticipantFirmCode: firmCode }),
      );
    }
  }, [firmCode, dispatch]);

  const onSave = (
    action: string,
    row: BlockTradeRequest,
    values: ApproveBlockTradeRequestForm | RejectBlockTradeRequestForm,
  ) => {
    const promiseFunction =
      action === BlockTradeRequestAction.APPROVE
        ? approveBlockTradePromise
        : rejectBlockTradePromise;
    dispatch(hideModal());
    promiseFunction(
      { urlParams: { requestId: row.requestId }, ...values },
      dispatch,
    ).then(() => {
      dispatch(fetchBlockTradeRequestsV2({ participantId: firmCode }));
    });
  };

  const memoizedConfirmColumns = useMemo(
    () => blockTradeRequestsConfirmMetadata(),
    [],
  );

  const onActionClick = (row: Row<BlockTradeRequest>, action: string) => {
    dispatch(
      showModal(APPROVE_REJECT_BLOCK_TRADE_REQUEST, {
        size: 'large',
        row: row?.original,
        memoizedConfirmColumns,
        action,
        onSave: (
          action: BlockTradeRequestAction,
          row: BlockTradeRequest,
          values: ApproveBlockTradeRequestForm | RejectBlockTradeRequestForm,
        ) => {
          onSave(action, row, values);
        },
      }),
    );
  };
  const metadata = useMemo(() => {
    return blockTradeRequestsMetadata(participantList, onActionClick);
  }, [participantList]);

  return (
    <>
      <Header as="h1" dividing>
        Block Trade Requests
      </Header>
      <XTable7
        title="blockTradeRequests"
        metadata={metadata}
        data={blockTradeRequests}
        fetchData={() => {}}
        loading={loading}
        count={blockTradeRequests?.length || 0}
        showHeader
        showPagination={false}
      />
    </>
  );
};

export default BlockTradeRequests;
