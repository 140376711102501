import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

/**
 * @param {string} filters: Filters the type of request we want. Usually PENDING.
 *                        (Optional.)
 */
export const fetchApprovalReqs = (filters) => ({
  type: actionTypes.APPROVAL_REQS_REQUEST,
  payload: filters,
});

export const fetchApprovalLogs = createRoutine(actionTypes.APPROVAL_LOGS);
export const approve = createRoutine(actionTypes.APPROVE);
export const approvePromiseCreator = promisifyRoutine(approve);

export const fetchApprovalReqPayload = (uuid) => ({
  type: actionTypes.APPROVAL_REQ_PAYLOAD_REQUEST,
  payload: uuid,
});

/**
 * @param {string} uuid: select a uuid for approval
 */
export const selectApproval = (uuid) => ({
  type: actionTypes.SELECT_APPROVAL,
  uuid,
});
