import * as actionTypes from '../constants/actionTypes';

export const fetchSystemAlerts = (payload) => ({
  type: actionTypes.SYSTEM_ALERTS_REQUEST,
  payload,
});

export const fetchSystemAlertInfo = () => ({
  type: actionTypes.SYSTEM_ALERT_INFO_REQUEST,
});

export const fetchExceptions = (payload) => ({
  type: actionTypes.EXCEPTIONS_REQUEST,
  payload,
});

export const fetchExceptionsInfo = () => ({
  type: actionTypes.EXCEPTION_INFO_REQUEST,
});

export const selectException = (exceptionId) => ({
  type: actionTypes.SELECT_EXCEPTION,
  exceptionId,
});
