import React from 'react';
import {
  DetailHeader,
  Details,
  Info,
  SubHeader,
} from './ParticipantManagement.styles';

export type InfoField = {
  header: string;
  text: string;
};

const ModalDetails: React.FC<{
  fields: InfoField[];
  subHeader: string;
}> = ({ fields, subHeader }) => {
  return (
    <>
      <SubHeader>{subHeader}</SubHeader>
      <Details>
        {fields.map((field) => (
          <DetailHeader key={field.header}>{field.header}</DetailHeader>
        ))}
        {fields.map((field) => (
          <Info key={field.text}>{field.text}</Info>
        ))}
      </Details>
    </>
  );
};

export default ModalDetails;
