import Big from 'bignumber.js';
import React, { Fragment } from 'react';
import { get, filter, some, find, sumBy, compact } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Input, Button } from 'semantic-ui-react';
import { bigSumFixed } from 'erisxkit/client';
import { formatTimeLocalWithDoW } from '../utils/time';
import { DELIVERY } from '../constants/modalTypes';

const getTotalLongQty = (selections, data) =>
  sumBy(
    filter(selections, (selection) => {
      const position = find(data, { positionId: selection.positionId });
      if (position) {
        return position.qty > 0;
      }
      return false;
    }),
    (row) => row.qty,
  );

const getTotalShortQty = (selections, data) =>
  sumBy(
    filter(selections, (selection) => {
      const position = find(data, { positionId: selection.positionId });
      if (position) {
        return position.qty < 0;
      }
      return false;
    }),
    (row) => row.qty,
  );

// use toFixed for both to lop off trailing zeroes
const formatLong = (qty) => (Big(qty).isPositive() ? Big(qty).toFixed() : '');
const formatShort = (qty) =>
  Big(qty).isNegative() ? Big(qty).abs().toFixed() : '';

export const formatAggregated = (row, accessor) => {
  if (get(row, 'row._pivotID') === 'productCode') {
    return bigSumFixed(row.subRows, (s) => get(s, accessor));
  } else if (get(row, 'row._pivotID') !== 'rollupAccountIds') {
    return row.value;
  }
  return '';
};

// TODO: Refactor this function so it takes an object instead of a long list of arguments
export const futuresPositionsMetadata = (
  futuresProducts,
  futuresContracts,
  contractCloseQuantity,
  selection,
  isDcoAccount,
  showModal,
  hideModal,
  isDelivery,
  hideTotalOTE = false,
) =>
  compact([
    {
      Header: 'Exc/SubExc Code',
      id: 'exchangeSymbol',
      accessor: (row) =>
        row.subExchangeLabel ? `ErisX/${row.subExchangeLabel}` : 'ErisX',
      Aggregated: ({ row }) =>
        row.subExchangeLabel ? `ErisX/${row.subExchangeLabel}` : 'ErisX',
      Cell: () => null,
    },
    { pivot: true },
    {
      Header: 'Product Code',
      accessor: 'productSymbol',
      id: 'productCode',
      aggregate: () => null,
      PivotValue: ({ value }) => value,
    },
    {
      Header: 'Maturity Date',
      id: 'expirationTime',
      accessor: (row) => formatTimeLocalWithDoW(row.expirationTime, true),
      Cell: () => null,
      aggregate: (values) => values[0],
      Aggregated: (row) => (
        <Fragment>
          {get(row, 'row._pivotID') === 'contractCode' ? row.value : ''}
        </Fragment>
      ),
    },
    {
      Header: 'Contract Code',
      accessor: 'contractCode',
      id: 'contractCode',
      aggregate: () => null,
      PivotValue: ({ value }) => value,
    },
    {
      Header: 'Account',
      id: 'contraAccountLabel',
      aggregate: () => null,
      accessor: ({ accountLabel, contraAccountLabel }) =>
        accountLabel || contraAccountLabel,
      ...((isDcoAccount && {
        expander: true,
        Expander: (arg) => (
          <Fragment>
            <span
              className={`rt-expander vertical-align-super${arg.isExpanded ? ' -open' : ''}`}
            />
            <NavLink
              target="_blank"
              className="mono"
              to={`/balances/${get(arg, ['original', 'contraAccountId'])}#futures`}
            >
              {get(arg, ['original', 'contraAccountLabel'])}
            </NavLink>
          </Fragment>
        ),
      }) || {
        PivotValue: ({ value }) => value,
      }),
    },
    !isDelivery
      ? null
      : {
          Header: 'Delivery Type',
          accessor: (row) => {
            const product = futuresProducts.find(
              (each) => row.productSymbol === each.symbol,
            );
            return product && product.delivery;
          },
          aggregate: (values) => values[0],
          Aggregated: ({ row }) => row.delivery || '-',
          Cell: () => null,
          id: 'delivery',
        },
    isDcoAccount
      ? null
      : {
          Header: 'Rollup Accounts',
          id: 'rollupAccountIds',
          accessor: ({ rollupAccountId }) => rollupAccountId,
          expander: true,
          Expander: (arg) => (
            <Fragment>
              <span
                className={`rt-expander vertical-align-super${arg.isExpanded ? ' -open' : ''}`}
              />
              <NavLink
                target="_blank"
                className="mono"
                to={`/balances/${get(arg, ['original', 'rollupAccountId'])}#futures`}
              >
                {get(arg, ['original', 'rollupAccountLabel'], '—')}
              </NavLink>
            </Fragment>
          ),
        },
    // Total long, short, and OTE are flipped for DCO positions screen.
    {
      Header: 'Total Long',
      accessor: ({ totalLong, positions }) =>
        isDcoAccount
          ? // TODO: stop using this calculation once we use aggregated method for DCO positions screen
            bigSumFixed(positions, ({ qty }) => formatShort(qty))
          : Big(totalLong).abs().toFixed(), // show total long as a positive
      id: 'totalLong',
      ...(isDcoAccount && { aggregate: (values) => bigSumFixed(values) }),
      ...(!isDcoAccount && {
        aggregate: (values) => values[0],
        Aggregated: (row) => formatAggregated(row, 'totalLong'),
        Cell: () => null,
      }),
      className: 'text-right mono total-long',
      headerClassName: 'text-right',
      width: 95,
    },
    {
      Header: 'Total Short',
      accessor: ({ totalShort, positions }) =>
        isDcoAccount
          ? // TODO: same with total long above.                // v-- same as above, also to trim trailing zeroes.
            bigSumFixed(positions, ({ qty }) => formatLong(qty))
          : Big(totalShort).abs().toFixed(),
      id: 'totalShort',
      ...(isDcoAccount && { aggregate: (values) => bigSumFixed(values) }),
      ...(!isDcoAccount && {
        aggregate: (values) => values[0],
        Aggregated: (row) => formatAggregated(row, 'totalShort'),
        Cell: () => null,
      }),
      className: 'text-right mono total-short',
      headerClassName: 'text-right',
      width: 95,
    },
    hideTotalOTE
      ? null
      : {
          Header: 'Total OTE',
          accessor: (row) => {
            const totalOte = bigSumFixed(
              row.positions,
              (value) => value.reserveOte,
            );
            if (isDcoAccount) {
              return Big(totalOte).times(-1).toFixed();
            }
            return row.totalOte;
          },
          id: 'totalOTE',
          ...(isDcoAccount && { aggregate: (values) => bigSumFixed(values) }),
          ...(!isDcoAccount && {
            aggregate: (values) => values[0],
            Aggregated: (row) => formatAggregated(row, 'totalOTE') || 0,
            Cell: () => null,
          }),
          className: 'text-right mono',
          headerClassName: 'text-right',
          width: 95,
        },
    isDcoAccount
      ? null
      : {
          Header: 'Close Qty',
          id: 'close-contract',
          accessor: (row) => (
            <Fragment>
              <Input
                className="contract-close-qty"
                size="mini"
                fluid
                type="number"
                onChange={(e, { value }) =>
                  contractCloseQuantity(
                    row.contractSymbol,
                    value,
                    row.rollupAccountId,
                  )
                }
                error={
                  getTotalLongQty(selection, row.positions) >
                    Math.min(
                      row.totalLong,
                      Big(row.totalShort).abs().toFixed(),
                    ) ||
                  getTotalShortQty(selection, row.positions) >
                    Math.min(row.totalLong, Big(row.totalShort).abs().toFixed())
                }
              />
              <small className="helper">
                Max Closeout{' '}
                {Math.min(
                  Big(row.totalLong).abs().toFixed(),
                  Big(row.totalShort).abs().toFixed(),
                )}{' '}
              </small>
            </Fragment>
          ),
          resizable: false,
          // FIXME: aggregation breaks this, but conditional rendering needs a different prop to work...
          aggregate: () => null,
        },
    !isDcoAccount || !isDelivery
      ? null
      : {
          accessor: null,
          Header: '',
          maxWidth: 100,
          resizable: false,
          aggregate: (row, rows) => {
            if (get(rows[0], '_nestingLevel') === 0) {
              return (
                <Button
                  size="mini"
                  content="Delivery"
                  onClick={() =>
                    showModal(DELIVERY, {
                      contractCode: rows[0].contractCode,
                      onConfirm: () => hideModal(),
                      productCode: rows[0].productCode,
                    })
                  }
                />
              );
            }
            return null;
          },
        },
  ]);

export const subComponentMetadata = (
  selections,
  closeQuantity,
  closePositions,
  contractSymbol,
  isDcoAccount,
  accountId,
) => [
  {
    Header: 'Execution Time',
    accessor: (row) => formatTimeLocalWithDoW(row.et, true),
    id: 'et',
    ...(!isDcoAccount && {
      Footer: <span className="bold">Total Close Out Quantity Selected:</span>,
    }),
  },
  {
    Header: 'TradeID',
    accessor: 'positionId',
    id: 'positionId',
  },
  {
    Header: 'ClientOrderId',
    accessor: 'clOrdId',
    id: 'clientOrderId',
  },
  {
    Header: 'CustAcctRef',
    accessor: (row) => get(row, 'customerAccountRef', '—'),
    id: 'customerAccountRef',
  },
  {
    Header: 'Price',
    accessor: (subRow) => <span className="mono">{get(subRow, 'px', '')}</span>,
    id: 'px',
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  // Longs and shorts should be flipped for DCO positions screen, by request.
  // They will be shown from the direction of the clearing member position.
  {
    Header: 'Long',
    accessor: ({ qty }) => (isDcoAccount ? formatShort(qty) : formatLong(qty)),
    id: 'long',
    ...(!isDcoAccount && {
      Footer: ({ data }) => (
        <span className="bold">
          {getTotalLongQty(
            selections,
            data.map((pos) => pos._original),
          )}
        </span>
      ),
    }),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Short',
    accessor: ({ qty }) => (isDcoAccount ? formatLong(qty) : formatShort(qty)),
    id: 'short',
    ...(!isDcoAccount && {
      Footer: ({ data }) => (
        <span className="bold">
          {getTotalShortQty(
            selections,
            data.map((pos) => pos._original),
          )}
        </span>
      ),
    }),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Close Qty',
    id: 'close-positions',
    show: !isDcoAccount,
    accessor: (subRow) =>
      some(selections, { positionId: subRow.positionId }) ? (
        <Input
          className="close-qty"
          fluid
          size="mini"
          type="number"
          onChange={(e, { value }) => closeQuantity(subRow.positionId, value)}
          value={find(selections, { positionId: subRow.positionId }).qty}
        />
      ) : (
        ''
      ),
    resizable: false,
    ...(!isDcoAccount && {
      Footer: ({ data }) => (
        <Button
          negative
          width={148}
          content="Close Positions"
          onClick={() => closePositions(contractSymbol, accountId)}
          id="close-positions"
          disabled={
            getTotalLongQty(
              selections,
              data.map((pos) => pos._original),
            ) !==
            getTotalShortQty(
              selections,
              data.map((pos) => pos._original),
            )
          }
        />
      ),
    }),
  },
];
