import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

const STORE_TX_AUTH_KEY = ' STORE_TX_AUTH_KEY';

export const GENERATE_HASH_ID = 'GENERATE_HASH_ID';
export const GENERATE_COLLATERAL_HASH_ID = 'GENERATE_COLLATERAL_HASH_ID';
const REMOVE_TX_AUTH_HOLD_DELAY = 'REMOVE_TX_AUTH_HOLD_DELAY';
export const REMOVE_TX_AUTH_MEMBER_HOLD_DELAY =
  'REMOVE_TX_AUTH_MEMBER_HOLD_DELAY';

export const storeTxAuthKey = createRoutine(STORE_TX_AUTH_KEY);
export const generateHashId = createRoutine(GENERATE_HASH_ID);
export const generateCollateralHashId = createRoutine(
  GENERATE_COLLATERAL_HASH_ID,
);
export const generateHashIdPromiseCreator = promisifyRoutine(generateHashId);
export const generateCollateralHashIdPromiseCreator = promisifyRoutine(
  generateCollateralHashId,
);
export const removeTxAuthHoldDelay = createRoutine(REMOVE_TX_AUTH_HOLD_DELAY);
export const removeTxAuthMemberHoldDelay = createRoutine(
  REMOVE_TX_AUTH_MEMBER_HOLD_DELAY,
);

const initialState = {};
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
