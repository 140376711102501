import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  getSecurityQuestions,
  getSelectedUserId,
  updateUser,
  fetchMemberSecurityQuestions,
} from '../../reducers/usersReducer';
import SecurityQuestionsForm from '../../components/MemberUsers/SecurityQuestionsForm';

const mapStateToProps = (state) => ({
  questions: getSecurityQuestions(state),
  values: _.get(state.form, ['security', 'values', 'answers']),
  selectedUserId: getSelectedUserId(state),
});

const mapDispatchToProps = {
  updateUser,
  fetchMemberSecurityQuestions,
};

class SecurityQuestionsContainer extends Component {
  componentDidMount = () => {
    this.props.fetchMemberSecurityQuestions(this.props.userId);
  };

  save = () => {
    const payload = {
      userId: this.props.selectedUserId,
      update: {
        securityQuestions: this.props.values,
      },
    };
    this.props.updateUser(payload);
  };

  render = () => (
    <SecurityQuestionsForm questions={this.props.questions} save={this.save} />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityQuestionsContainer);
