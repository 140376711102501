export default [
  'clearing_accounts',
  'balances',
  'home',
  'finance_accounts',
  'general_journal',
  'trades',
  'about',
  'manual_entries',
  'core_files',
];
