import React, { useState, Fragment } from 'react';
import { Header, Button, Icon, Form, Checkbox } from 'semantic-ui-react';
import { withFilters, inputOnChange, checkboxOnChange } from 'erisxkit/client';
import get from 'lodash/get';
import ExternalTable from '../common/table/ExternalTable';
import { DetailsHeader, WizardHeader } from '../common/components/PageHeaders';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import XTable7 from '../common/table/XTable7';

const AccountsHeader = ({
  balances,
  addAccount,
  createAccount,
  addExistingAccount,
  detailsView,
  wizardView,
}) => {
  const [account, setAccount] = useState('');
  if (detailsView) {
    return <DetailsHeader headerText="Ledger Accounts" onEdit={addAccount} />;
  }
  if (wizardView) {
    return (
      <div>
        <WizardHeader
          hideButton
          headerText="Ledger Accounts"
          buttonOnClick={createAccount}
          buttonText="Create & Assign Account"
        />
        <Form>
          <Form.Group>
            <AccountSelectionContainer
              value={account}
              onChange={(e, { value }) => {
                setAccount(value);
              }}
              label="Assign Account"
              placeholder="Search For Account"
              showCreate
              createOnClick={createAccount}
            />
            <Button
              primary
              content="Assign"
              disabled={!account}
              onClick={() => {
                addExistingAccount(account);
                setAccount('');
              }}
              style={{ height: '36px', marginTop: '25px' }}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }

  return (
    <div className="flex-row space-between">
      <Header as="h1" dividing>
        {balances ? 'All Accounts:' : 'Clearing Member Accounts'}
      </Header>
      <Button
        onClick={detailsView ? addAccount : createAccount}
        className="add-button"
        size="small"
        floated="right"
      >
        <Icon name="add" /> {detailsView ? 'Edit' : 'Create'} Account
      </Button>
    </div>
  );
};
const ClearingAccounts = React.forwardRef(
  ({
    data,
    metadata,
    addAccount,
    createAccount,
    addLedgerAccount,
    selectAccount,
    onFetchData,
    count,
    loading,
    userAccounts,
    balances,
    detailsView,
    wizardView,
  }) => (
    <Fragment>
      <AccountsHeader
        balances={balances}
        detailsView={detailsView}
        wizardView={wizardView}
        addAccount={addAccount}
        addExistingAccount={addLedgerAccount}
        createAccount={createAccount}
      />
      <XTable7
        data={data}
        metadata={metadata}
        fetchData={onFetchData}
        loading={loading}
        count={count}
        pageTableSize={20}
        onCellClick={(cell) => {
          const id = get(cell, 'row.original.accountId', null);
          const column = get(cell, 'column.id', null);
          if (
            id &&
            column !== 'aml_state' &&
            column !== 'locked_state' &&
            column !== 'actions'
          ) {
            selectAccount(id);
          }
        }}
        showGeneralSearch
        generalSearchOptions={[
          { key: 'firm_code', text: 'Firm Code', value: 'firm_code' },
          { key: 'account_code', text: 'Account Code', value: 'account_code' },
          {
            key: 'sub_account_code',
            text: 'Sub Account Code',
            value: 'sub_account_code',
          },
          { key: 'name', text: 'Account Name', value: 'name' },
        ]}
      />
    </Fragment>
  ),
);

ClearingAccounts.displayName = 'ClearingAccounts';

export default ClearingAccounts;
