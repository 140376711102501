import React, { Component } from 'react';
import { XTable } from 'erisxkit/client';

class ExternalExpandableTable extends Component {
  state = {
    expanded: {},
  };

  handleRowExpanded = ({ index }) => {
    const expanded = this.props.multipleExpandable
      ? {
          expanded: {
            ...this.state.expanded,
            [index]: !this.state.expanded[index],
          },
        }
      : { expanded: { [index]: !this.state.expanded[index] } };

    this.setState(expanded);
  };

  render = () => {
    const {
      title,
      data,
      metadata,
      onFetchData,
      count,
      loading,
      SubComponent,
      noDataText,
      multipleExpandable,
      ...rest
    } = this.props;

    return (
      <XTable
        title={title}
        loading={loading}
        sortable={false}
        minRows={5}
        noDataText
        className="-striped -highlight"
        columns={metadata}
        data={data}
        onFetchData={onFetchData}
        manual
        pages={Math.ceil(count / 20)}
        showPageSizeOptions={false}
        defaultPageSize={20}
        getTrProps={(state, rowInfo) => ({
          onClick: () => {
            this.handleRowExpanded(rowInfo);
          },
          className: 'pointer',
        })}
        SubComponent={(row) => SubComponent(row)}
        expanded={this.state.expanded}
        {...rest}
      />
    );
  };
}

export default ExternalExpandableTable;
