import { connect } from 'react-redux';
import { get } from 'lodash';
import React, { Component } from 'react';
import {
  XTable,
  createLoadingSelector,
  filteredArrayToAttrValue,
  getItemsCount,
  getItemsPaged,
  inputOnChange,
  withFilters,
  showModal,
  hideModal,
} from 'erisxkit/client';
import PropTypes from 'prop-types';
import { createReversal } from '../../actions/reconciliationActions';
import ExternalTable from '../../common/table/ExternalTable';
import {
  mainComponentMetadata,
  subComponentMetadata,
} from '../../metadata/closeoutJournalsMetadata';
import { closeoutJournals } from '../../reducers/journalsReducer';
import { getAssetTypesAsOptions } from '../../selectors';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../../constants/modalTypes';
import { CLOSEOUT_JOURNALS } from '../../constants/actionTypes';
import ProductContractSelection from '../../common/containers/ProductContractSelection';
import XTable7 from '../../common/table/XTable7';

const FilteredTable = withFilters(ExternalTable);
const slice = ['journals', 'closeout'];

const mapStateToProps = (state) => ({
  count: getItemsCount(slice)(state),
  loading: createLoadingSelector([CLOSEOUT_JOURNALS])(state),
  closeoutJournalsList: getItemsPaged(slice)(state),
  assetTypeOptions: getAssetTypesAsOptions(state),
});

const mapDispatchToProps = {
  closeoutJournals,
  createReversal,
  hideModal,
  showModal,
};

const filters = (selectedAccount) => [
  {
    component: ProductContractSelection,
    contractName: 'contract_symbol',
    name: 'contract_symbol',
    onChange:
      (onChange) =>
      (e, { name, value }) =>
        onChange(name, value, 'eq'),
    showDetails: true,
    upward: false,
    hideSpot: true,
  },
  ...(!selectedAccount
    ? [
        {
          component: AccountSelectionContainer,
          name: 'account_id',
          id: 'account_id',
          onChange: inputOnChange,
        },
      ]
    : []),
];

class CloseoutJournalsContainer extends Component {
  fetchData = (state) => {
    // initialize the filter to have trigger for type, this is required.
    let filter = [];
    const sort = [];
    if (this.props.selectedAccount) {
      filter.push({
        attr: 'account_id',
        op: 'eq',
        value: this.props.selectedAccount.accountId,
      });
    }
    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }
    this.props.closeoutJournals({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
      sort,
    });
  };

  fetchInternalTableData = () => {};

  confirmCreateReversal = (uuid) => {
    const { createReversal, hideModal } = this.props;
    createReversal({ uuid, showToast: true });
    hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
  };

  confirmCreateReversalModal = (payload = {}) => {
    const { showModal } = this.props;
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: 'Reverse journal',
      msg: 'Create a reversal journal for this closeout?',
      hideModal,
      closeOnDimmerClick: true,
      closeOnEscape: true,
      onConfirm: () => this.confirmCreateReversal(payload.uuid),
      payload,
    });
  };

  render = () => (
    <FilteredTable
      title="closeout-journals"
      data={this.props.closeoutJournalsList}
      metadata={mainComponentMetadata(
        this.confirmCreateReversalModal,
        this.props.showAccountCol,
      )}
      onFetchData={this.fetchData}
      loading={this.props.loading}
      noDataText={
        <span className="italic">No closeout journals were found.</span>
      }
      filters={filters(this.props.selectedAccount)}
      pageSize={20}
      pages={Math.ceil(this.props.count / 20)}
      getTdProps={(state, rowInfo, column) => ({
        className: column.id === 'more' && 'td-with-dropdown',
      })}
      SubComponent={({ row }) => (
        /*<XTable
          className="subtable no-stripe"
          title="closeout-subcomponent"
          data={get(row, ['_original', 'closeoutItems'], [])}
          showPagination={false}
          pageSize={get(row, ['_original', 'closeoutItems'], []).length}
          columns={subComponentMetadata}
        />

        */
        <div style={{ margin: '15px' }}>
          <XTable7
            title="closeout-subcomponent"
            metadata={subComponentMetadata}
            data={get(row, ['_original', 'closeoutItems'], [])}
            fetchData={this.fetchInternalTableData}
            showHeader={true}
            showFilter={false}
            showPagination={false}
          />
        </div>
      )}
    />
  );
}

CloseoutJournalsContainer.propTypes = {
  loading: PropTypes.bool,
  closeoutJournals: PropTypes.func.isRequired,
  closeoutJournalsList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  createReversal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showAccountCol: PropTypes.bool,
};

CloseoutJournalsContainer.defaultProps = {
  loading: false,
  closeoutJournalsList: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloseoutJournalsContainer);
