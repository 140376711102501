import _ from 'lodash';
import { connect } from 'react-redux';
import { createLoadingSelector, filteredArrayToObject } from 'erisxkit/client';
import React, { Component, Fragment } from 'react';
import { Tab } from 'semantic-ui-react';
import AccountHistory from '../../components/AccountHistory';
import { fetchHistory } from '../../actions/accountsActions';
import {
  getAccountHistory,
  getAccountHistoryCount,
  getRequestHistory,
  getRequestHistoryCount,
  getSelectedAccount,
  getSelectedAccountId,
  getMetadataForSelectedAccount,
  fetchRequestHistory,
} from '../../reducers/accountHistoryReducer';
import { assetTypes } from '../../actions/utilActions';
import { getAssetTypes } from '../../selectors';

const mapStateToProps = (state) => ({
  accountHistory: getAccountHistory(state),
  accountHistoryCount: getAccountHistoryCount(state),
  requestHistory: getRequestHistory(state),
  requestHistoryCount: getRequestHistoryCount(state),
  requestHistoryLoading: createLoadingSelector(['REQUEST_HISTORY'])(state),
  historyLoading: createLoadingSelector(['ACCOUNT_HISTORY'])(state),
  selectedAccountId: getSelectedAccountId(state),
  metadata: getMetadataForSelectedAccount(state),
  selectedAccount: getSelectedAccount(state),
  assetTypesData: getAssetTypes(state),
});

const mapDispatchToProps = {
  fetchHistory,
  fetchRequestHistory,
  assetTypes,
};

class AccountsHistoryContainer extends Component {
  componentDidMount() {
    this.props.assetTypes();

    // call these methods so the count is giving to the UI to display in the tab names
    // limit is hard coded to 1, should be changed to 0 when the back end is updated to support it.
    this.props.fetchRequestHistory({
      limit: 1,
      offset: 0,
      accountIds: this.props.selectedAccountId,
      state: ['pending', 'rejected'],
    });
  }

  fetchDataRequests = (state) => {
    let filter = {};
    if (state.filtered.length !== 0) {
      filter = filteredArrayToObject(state.filtered);
    }

    this.props.fetchRequestHistory({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      accountIds: this.props.selectedAccountId,
      state: ['pending', 'rejected'],
      ...filter,
    });
  };

  render() {
    const {
      accountHistory,
      selectedAccountId,
      selectedAccount,
      metadata,
      requestHistory,
      requestHistoryLoading,
      assetTypesData,
    } = this.props;
    const name = _.get(selectedAccount, 'name', '');

    return (
      <Fragment>
        {selectedAccountId ? (
          <AccountHistory
            key="requests"
            data={{
              name,
              history: requestHistory,
              loading: this.props.requestHistoryLoading,
              accountId: selectedAccountId,
              metadata,
              selectedAccount,
              fetchData: this.fetchDataRequests,
              count: this.props.requestHistoryCount,
              type: 'requests',
              assetTypesData,
            }}
          />
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsHistoryContainer);
