import React, { Fragment, useState, useEffect } from 'react';
import { Tab, Header, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import XTab from '../../common/tabs/XTab';
import { formatDateLocal } from '../../utils/time';
import IconDatePicker from '../../components/IconDatePicker';
import moment from 'moment';
import Moment from 'moment-timezone';
import InitialMarginCalculations from './InitialMarginCalculations';
import InitialMarginRequirements from './InitialMarginRequirements';
import {
  getInitialMarginTradeDate,
  updateInitialMarginTradeDate,
} from '../../reducers/initialMarginReducer';

const MarginRatesContainer = () => {
  const dispatch = useDispatch();
  const tradeDate = useSelector(getInitialMarginTradeDate);
  const [date, setDate] = useState(tradeDate);

  useEffect(() => {
    dispatch(updateInitialMarginTradeDate(date));
  }, [date]);

  const panes = [
    {
      url: 'override_initial_margin',
      menuItem: 'Override Initial Margin',
      className: 'marginRates',
      render: () => (
        <Tab.Pane attached={false}>
          <InitialMarginCalculations />
        </Tab.Pane>
      ),
    },
    {
      url: 'override_margin_requirements',
      menuItem: 'Margin Requirements',
      className: 'marginRates',
      render: () => (
        <Tab.Pane attached={false}>
          <InitialMarginRequirements />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Fragment>
      <Header as="h1" dividing>
        Clearing Initial Margin Requirement
      </Header>
      <div
        style={{
          width: '99%',
          marginBottom: '15px',
          marginTop: '15px',
          textAlign: 'right',
        }}
      >
        <p>
          {formatDateLocal(tradeDate)}
          <IconDatePicker
            style={{ display: 'inline-block' }}
            onChange={(e) => setDate(moment(e._d).format('YYYY-MM-DD'))}
          />
        </p>
      </div>
      <XTab panes={panes} />
    </Fragment>
  );
};

export default MarginRatesContainer;
