import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { get, find, map } from 'lodash';
import { createSelector } from 'reselect';
import {
  CONTRACT_SYMBOLS,
  CREATE_FUTURES_CONTRACT,
  FUTURES_CONTRACTS,
  UPDATE_CONTRACT,
} from '../constants/actionTypes';
import { getFuturesProducts } from './futuresProductReducer';

// Routines
export const contractSymbols = createRoutine(CONTRACT_SYMBOLS);
export const createFuturesContract = createRoutine(CREATE_FUTURES_CONTRACT);
export const futuresContracts = createRoutine(FUTURES_CONTRACTS);
export const futuresContractsPromiseCreator =
  promisifyRoutine(futuresContracts);

export const updateContract = createRoutine(UPDATE_CONTRACT);

// Reducer
export default (state = {}, { type, payload }) => {
  switch (type) {
    case contractSymbols.TRIGGER:
      return { ...state, keepPrevious: payload?.keepPrevious };
    case contractSymbols.SUCCESS:
      return state.keepPrevious
        ? {
            ...state,
            keepPrevious: false,
            contracts: [...state.contracts, ...payload.contracts],
          }
        : {
            ...state,
            ...payload,
          };
    case futuresContracts.SUCCESS:
      return { ...state, ...payload };
    case createFuturesContract.SUCCESS:
      return {
        ...state,
        futuresContracts: [...state.futuresContracts, payload],
      };
    case updateContract.SUCCESS:
      //Update the right one contract with the update payload
      const _futuresContracts = map(state.futuresContracts, (contract) =>
        contract.symbol === payload.symbol ? payload : contract,
      );
      return {
        ...state,
        futuresContracts: _futuresContracts,
      };
    default:
      return state;
  }
};

// Selectors
export const getContractSymbols = (state) =>
  get(state, ['contracts', 'contractSymbols'], []);
export const getContracts = (state) =>
  get(state, ['contracts', 'contracts'], []);
export const getContractsWithClearingCode = (state) =>
  get(state, ['contracts', 'contracts'], []).filter(
    (each) => !!each.contractCode,
  );

export const getFuturesContracts = (state) =>
  get(state, ['contracts', 'futuresContracts'], []);
export const getFuturesContractsCount = (state) =>
  get(state, ['contracts', 'count'], []);
export const getFuturesContractsWithContractSize = createSelector(
  [getFuturesContracts, getFuturesProducts],
  (futuresContracts, futuresProducts) =>
    futuresContracts.map((c) => {
      const product = find(futuresProducts, { symbol: c.productSymbol });
      return { ...c, contractSize: get(product, 'contractSize', '') };
    }),
);
