import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Large Trader Mapping Id',
    id: 'largeTraderMappingId',
    accessor: 'largeTraderMappingId',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 220,
  },
  {
    Header: 'Large Trader Report Firm Id',
    id: 'largeTraderReportFirmId',
    accessor: 'largeTraderReportFirmId',
    required: true,
    minWidth: 270,
  },
  {
    Header: 'Tcs Member Id',
    id: 'tcsMemberId',
    accessor: 'tcsMemberId',
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
