import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import { roundNumber } from '../utils/methods';

const clientTransferMedatada = (checkBox, handleChange) => [
  {
    Header: 'From',
    id: 'from',
    accessor: 'from',
  },
  {
    Header: 'To',
    id: 'to',
    accessor: 'to',
  },
  {
    Header: 'Asset',
    id: 'asset',
    accessor: 'asset',
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: (row) => roundNumber(row.amount, 2),
    className: 'text-right mono',
  },
  {
    Header: 'TCS Balance',
    id: 'tcsBalance',
    accessor: 'tcsBalance',
    className: 'text-right mono',
  },
  {
    Header: 'TME Available Balance',
    id: 'tmeAvailableBalance',
    accessor: 'tmeAvailableBalance',
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: <span style={{ marginRight: '10px' }}>Approve</span>,
    id: 'approve',
    accessor: 'approve',
    sortable: false,
    Cell: ({ row }) => (
      <Checkbox
        label=""
        name={'radioGroup' + row?.original?.id}
        id={'approve_' + row?.original?.id}
        amount={row?.original?.amount}
        from={row?.original?.from}
        checked={
          checkBox.find((e) => e.name === 'radioGroup' + row?.original?.id)
            ?.checked ===
          'approve_' + row?.original?.id
        }
        onChange={(e) => handleChange(e.target, row?.original)}
      />
    ),
  },
  {
    Header: 'Reject',
    id: 'reject',
    accessor: 'reject',
    Cell: ({ row }) => (
      <Checkbox
        label=""
        name={'radioGroup' + row?.original?.id}
        id={'reject_' + row?.original?.id}
        amount={row?.original?.amount}
        from={row?.original?.from}
        checked={
          checkBox.find((e) => e.name === 'radioGroup' + row?.original?.id)
            ?.checked ===
          'reject_' + row?.original?.id
        }
        onChange={(e) => handleChange(e.target, row?.original)}
      />
    ),
  },
];

export default clientTransferMedatada;
