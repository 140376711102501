import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import 'semantic-ui-css/semantic.css';
// import 'react-table-6/react-table.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import AppContainer from './containers/AppContainer';

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(<AppContainer />, document.getElementById('root'));
