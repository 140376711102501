import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Icon, List } from 'semantic-ui-react';
import get from 'lodash/get';
import ExpandCell from './ExpandCell';

export const accountColumn = (
  header = 'Account',
  id = 'accountId',
  label = 'accountLabel',
  type = 'clearing',
) => ({
  Header: header,
  id: 'account',
  // overriding onClick props so that the page only calls the defined onClick.
  getProps: () => ({
    onClick: () => {},
  }),
  Cell: (e) => (
    <NavLink
      target="_blank"
      className="mono"
      to={`/${type}_accounts/${e.original[id]}`}
    >
      {' '}
      {e.original[label]}{' '}
    </NavLink>
  ),
  sortable: false,
});

export const NotSupportedColumn = () => (
  <Icon
    name="warning circle"
    color="yellow"
    title="This functionality is not yet supported."
  />
);

// override defaults with props
export const memberColumn = (props = {}) => ({
  Header: 'Member',
  id: 'memberLinks',
  // overriding onClick props so that the page only calls the defined onClick.
  getProps: () => ({
    onClick: () => {},
  }),
  Cell: (e) => {
    const accessor = e[props?.accessor] || e;
    const members = get(accessor.original, 'memberLinks', []);
    const memberId = get(accessor.original, 'memberId', false);
    if (memberId) {
      members.push({
        memberId,
        memberLabel: get(accessor.original, 'memberLabel', ''),
      });
    }
    return (
      <List
        items={members.map((m) => (
          <NavLink
            target="_blank"
            className="flex"
            key={m.memberId}
            to={`/members/${m.memberId}/details`}
          >
            {m.memberLabel}
          </NavLink>
        ))}
      />
    );
  },
  sortable: false,
  ...props,
});

// override defaults with props
export const memberColumnXTable7 = (props = {}) => ({
  Header: 'Member',
  id: 'memberLinks',
  // overriding onClick props so that the page only calls the defined onClick.
  getProps: () => ({
    onClick: () => {},
  }),
  Cell: (e) => {
    const accessor = e[props?.accessor] || e;
    const members = get(accessor.row.original, 'memberLinks', []);
    const memberId = get(accessor.row.original, 'memberId', false);
    if (memberId) {
      members.push({
        memberId,
        memberLabel: get(accessor.row.original, 'memberLabel', ''),
      });
    }
    return (
      <List
        items={members.map((m) => (
          <NavLink
            target="_blank"
            className="flex"
            key={m.memberId}
            to={`/members/${m.memberId}/details`}
          >
            {m.memberLabel}
          </NavLink>
        ))}
      />
    );
  },
  sortable: false,
  ...props,
});

export const userColumn = (args) => {
  const {
    header = 'User',
    id = 'userId',
    label = 'memberUserLabel',
    type = 'member_user',
    ...rest
  } = args || {};
  let updatedType = type;
  return {
    Header: header,
    id,
    // overriding onClick props so that the page only calls the defined onClick.
    getProps: () => ({
      onClick: () => {},
    }),
    Cell: (e) => {
      const userType = get(e, 'original.initiatorType', '');
      const userTypeURL =
        userType === 'member_user' ? 'member_users' : 'employees';
      return (
        <NavLink target="_blank" to={`/${userTypeURL}/${e.original[id]}`}>
          {' '}
          {e.original[label]}{' '}
        </NavLink>
      );
    },
    sortable: false,
    ...rest,
  };
};

export const overflowMenu = ({ items = () => [], ...rest }) => ({
  Cell: (e) => (
    <Dropdown icon="ellipsis vertical" pointing="top right">
      <Dropdown.Menu>{items(e)}</Dropdown.Menu>
    </Dropdown>
  ),
  className: 'td-with-dropdown',
  Header: '',
  id: 'overflow',
  maxWidth: 25,
  resizable: false,
  ...rest,
});

export const expandColumn = {
  id: 'expander',
  Header: ({ getToggleAllRowsExpandedProps }) => (
    <span {...getToggleAllRowsExpandedProps()}></span>
  ),
  hideFilter: true,
  Cell: ExpandCell,
};
