import React, { Component } from 'react';
import { find, concat } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createTrade } from '../../actions/manualEntryActions';
import TradeEntry from '../../components/ManualEntries/TradeEntry';
import {
  CONFIRM_NO_POST_TO_EXCHANGE,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../constants/modalTypes';
import { showModal, hideModal } from 'erisxkit/client';
import { getSpotProducts } from '../../reducers/spotProductsReducer';
import { getFuturesProducts } from '../../reducers/futuresProductReducer';
import { getContracts } from '../../reducers/contractsReducer';
import {
  fetchTradeEntryCgmsBuySide,
  getTradeEntryCgmsBuySide,
  fetchTradeEntryCgmsSellSide,
  getTradeEntryCgmsSellSide,
  resetCgmListBuySide,
  resetCgmListSellSide,
} from '../../reducers/manualEntriesReducer';
import { getAllAccountsArray } from '../../reducers/accountsReducer';

const mapStateToProps = (state) => ({
  futuresProductsList: getFuturesProducts(state),
  spotProductsList: getSpotProducts(state),
  contracts: getContracts(state),
  tradeEntryCgmsBuySide: getTradeEntryCgmsBuySide(state),
  tradeEntryCgmsSellSide: getTradeEntryCgmsSellSide(state),
  allAccountsList: getAllAccountsArray(state),
});

const mapDispatchToProps = {
  createTrade,
  hideModal,
  showModal,
  fetchTradeEntryCgmsBuySide,
  fetchTradeEntryCgmsSellSide,
  resetCgmListBuySide,
  resetCgmListSellSide,
};

const initialState = {
  tradeDate: moment().format('YYYY-MM-DD'),
  executionTime: moment().format(),
  trdType: '',
  productType: 'SP',
  postToExchange: true,
  buySide: {},
  sellSide: {},
  qty: '',
  px: '',
};

class TradeEntryContainer extends Component {
  state = initialState;

  componentDidMount = () => {
    // update execution time every time component is mounted
    this.setState({ executionTime: moment().format() });
  };

  isProductSelectedFutures = () => {
    const { futuresProductsList } = this.props;
    return !!futuresProductsList?.find(
      (x) => x?.symbol === this.state?.product,
    );
  };

  handleChange = (e, { name, value, product }) => {
    const {
      futuresProductsList,
      spotProductsList,
      contracts,
      allAccountsList,
    } = this.props;
    const accountLabel = allAccountsList?.find(
      (x) => x?.accountId === value,
    )?.label;

    let obj;
    if (name === 'contractSymbol') {
      const contract = find(contracts, { symbol: value });
      if (contract) {
        const product = find(concat(futuresProductsList, spotProductsList), {
          symbol: contract.productSymbol,
        });
        const feeType = product.quotedAssetType;

        this.setState({
          buySide: { ...this.state.buySide, feeType },
          sellSide: { ...this.state.sellSide, feeType },
        });
      }
    }

    // fee types are determined solely by contract's associated product's base_asset_type.
    // buy and sell sides are represented by "buy/fooBar" and "sell/fooBar".
    // they are reconfigured in the state to { buy: { fooBar: value } }
    if (name.search(/buy\//) !== -1) {
      obj = {
        buySide: {
          ...this.state.buySide,
          [name.match(/buy\/([A-z]+)/)[1]]: value,
        },
      };
    } else if (name.search(/sell\//) !== -1) {
      obj = {
        sellSide: {
          ...this.state.sellSide,
          [name.match(/sell\/([A-z]+)/)[1]]: value,
        },
      };
    } else {
      obj = { [name]: value, product };
    }

    if (name == 'buy/accountId') {
      if (!value) {
        this.props.resetCgmListBuySide();
      } else {
        if (this.isProductSelectedFutures()) {
          this.props.fetchTradeEntryCgmsBuySide({
            account_label: accountLabel,
          });
        }
      }
    }
    if (name == 'sell/accountId') {
      if (!value) {
        this.props.resetCgmListSellSide();
      } else {
        if (this.isProductSelectedFutures()) {
          this.props.fetchTradeEntryCgmsSellSide({
            account_label: accountLabel,
          });
        }
      }
    }
    this.setState(obj);
  };

  // Dangerous. Only accessible via confirmation modal.
  confirmPostingDisabled = () => {
    this.setState({ postToExchange: false });
    this.props.hideModal();
  };

  handlePostToExchangeChanged = () => {
    if (this.state.postToExchange) {
      this.props.showModal(CONFIRM_NO_POST_TO_EXCHANGE, {
        dimmer: 'blurring',
        confirmPostingDisabled: this.confirmPostingDisabled,
      });
    } else {
      this.setState({ postToExchange: true });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      closeOnDimmerClick: true,
      closeOnEscape: true,
      payload: this.state,
      header: 'Confirm Manual Trade',
      hideModal: this.props.hideModal,
      onConfirm: () => {
        this.props.createTrade(this.state);
        this.props.hideModal();
      },
      msg: <p>Create manual trade?</p>,
    });
  };

  render = () => (
    <TradeEntry
      data={this.state}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
      postToExchangeChecked={this.state.postToExchange}
      handlePostToExchangeChanged={this.handlePostToExchangeChanged}
      tradeEntryCgmsBuySide={this.props.tradeEntryCgmsBuySide}
      tradeEntryCgmsSellSide={this.props.tradeEntryCgmsSellSide}
    />
  );
}

TradeEntryContainer.propTypes = {
  contractsLoading: PropTypes.bool,
  createTrade: PropTypes.func.isRequired,
  futuresContractOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  showModal: PropTypes.func.isRequired,
  spotProductOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  spotProducts: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

TradeEntryContainer.defaultProps = {
  contractsLoading: false,
  futuresContractOptions: [],
  spotProductOptions: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TradeEntryContainer);
