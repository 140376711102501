import { combineReducers } from 'redux';
import Big from 'bignumber.js';
import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { createRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';
import qs from 'query-string';

export const CREATE_MANUAL_EXTERNAL_TRANSACTION =
  'CREATE_MANUAL_EXTERNAL_TRANSACTION';
export const createManualExternalTransaction = createRoutine(
  CREATE_MANUAL_EXTERNAL_TRANSACTION,
);

function selectedAccountIdReducer(state = '', action) {
  switch (action.type) {
    case actionTypes.SELECT_ACCOUNT_RECONCILIATION:
      return action.accountId;
    default:
      return state;
  }
}

function selectedReconciliationReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.SELECT_RECONCILIATION:
      return { state: action.state, uuid: action.uuid };
    case actionTypes.RECONCILIATION_SUCCESS:
      return {
        state: action.reconciliation.state,
        uuid: action.reconciliation.uuid,
      };
    default:
      return state;
  }
}

function selectedTransactionReducer(state = '', action) {
  switch (action.type) {
    case actionTypes.SELECT_TRANSACTION:
      return action.payload;
    default:
      return state;
  }
}

export function reconciliationReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.CREATE_RECONCILIATION_SUCCESS:
    case actionTypes.RECONCILIATION_SUCCESS: {
      let entries = [];
      const { reconciliation } = action;

      if (reconciliation.entries) {
        entries = reconciliation.entries.map((s) => {
          const key = s.state === 'internal' ? s.journalId : s.txId;
          if (s.amount) {
            return { ...s, amount: new Big(s.amount), key };
          }
          return { ...s, key };
        });
      }

      return {
        ...state,
        ...reconciliation,
        entries,
        endExternalBalance: new Big(reconciliation.endExternalBalance),
        endInternalBalance: new Big(reconciliation.endInternalBalance),
        reconciledBalance: new Big(reconciliation.reconciledBalance),
        endTime: reconciliation.endTime,
        daysPrevious: reconciliation.daysPrevious,
      };
    }
    default:
      return state;
  }
}

const allReconciliationsReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_RECONCILIATION_SUCCESS:
    case actionTypes.RECONCILIATION_SUCCESS:
      return {
        ...state,
        [action.reconciliation.uuid]: reconciliationReducer(
          state[action.reconciliation.uuid],
          action,
        ),
      };
    default:
      return state;
  }
};

export default combineReducers({
  selectedTransaction: selectedTransactionReducer,
  selectedAccountId: selectedAccountIdReducer,
  byId: allReconciliationsReducer,
  selectedReconciliation: selectedReconciliationReducer,
});

//* selectors */
const getAccountIdFromURL = () => {
  const match = /(?:reconciliation|finance_accounts)\/([-A-z0-9]*)/.exec(
    window.location.href,
  );
  if (!match) return '';
  return match[1];
};
const getReconciliations = (state) => _.get(state.reconciliation, 'byId', {});
const getAllAccountsById = (state) => _.get(state, ['accounts', 'byId'], {});

export const getSelectedAccountId = (state) => {
  const urlAccountId = getAccountIdFromURL();
  const stateAccountId = _.get(
    state,
    ['reconciliation', 'selectedAccountId'],
    '',
  );
  return urlAccountId || stateAccountId;
};

export const getEndTime = (state, props) => {
  const search = _.get(props, ['location', 'search'], '');
  let parsedQuery = qs.parse(search, 'endTime');
  return parsedQuery.endTime ? parsedQuery.endTime : moment.utc();
};

export const getDaysPrevious = (state, props) => {
  const search = _.get(props, ['location', 'search'], '');
  let parsedQuery = qs.parse(search, 'daysPrevious');
  return parsedQuery.daysPrevious ? parsedQuery.daysPrevious : 0;
};

const getSelectedReconciliationStateFromURL = () => {
  const match = /reconciliation\/[-A-Za-z0-9]*\/reconcile/.exec(
    window.location.href,
  );
  if (!match) return 20;
  return 0;
};

const getSelectedReconciliationUUIDFromURL = () => {
  const match = /reconciliation\/[-A-Za-z0-9]*\/([-A-Za-z0-9]*)/.exec(
    window.location.href,
  );
  if (!match || match[1] === 'reconcile') return null;
  return match[1];
};

const getSelectedReconciliationState = (state) => {
  const reconciliationState = _.get(state.reconciliation, [
    'selectedReconciliation',
    'state',
  ]);
  if (!reconciliationState) {
    return getSelectedReconciliationStateFromURL();
  }
  return reconciliationState;
};
const getSelectedReconciliationUUID = (state) => {
  const reconciliationUUID = _.get(state.reconciliation, [
    'selectedReconciliation',
    'uuid',
  ]);
  if (!reconciliationUUID) {
    return getSelectedReconciliationUUIDFromURL();
  }
  return reconciliationUUID;
};
export const getSelectedReconciliation = createSelector(
  [getSelectedReconciliationState, getSelectedReconciliationUUID],
  (reconciliationState, uuid) => ({ state: reconciliationState, uuid }),
);

export const getSelectedAccount = createSelector(
  [getSelectedAccountId, getAllAccountsById],
  (accountId, accounts) => _.get(accounts, accountId, {}),
);

export const getCurrentReconciliation = createSelector(
  [getSelectedReconciliation, getReconciliations],
  (selectedReconciliation, reconciliationsById) =>
    _.get(reconciliationsById, _.get(selectedReconciliation, 'uuid', ''), {}),
);

//* get balance information */
const endExternalBalance = (state, props) =>
  _.get(getCurrentReconciliation(state, props), 'endExternalBalance', Big(NaN));
const endInternalBalance = (state, props) =>
  _.get(getCurrentReconciliation(state, props), 'endInternalBalance', Big(NaN));

// function to get difference of two BigJS numbers after checking if it's a number
const getDifference = (v1, v2) => {
  if (_.some([v1, v2], (d) => d.isNaN())) return '';
  return v1.minus(v2);
};

const add = (v1, v2) => {
  if (_.some([v1, v2], (d) => d.isNaN())) return '';
  return v1.plus(v2);
};

const isMatched = (v1, v2) => {
  if (_.some([v1, v2], (d) => d.isNaN())) return false;
  return v1.minus(v2).eq(0);
};

export const isMatchedEnd = createSelector(
  [endExternalBalance, endInternalBalance],
  (external, internal) => isMatched(external, internal),
);

export const getDifferenceEnd = createSelector(
  [endExternalBalance, endInternalBalance],
  (external, internal) => add(external, internal),
);

//* get transaction information */
const getTransactionIndex = (state) =>
  _.get(state.reconciliation, 'selectedTransaction', {});
const getAllTransactions = (state, props) =>
  _.get(getCurrentReconciliation(state, props), 'entries', []);
const getUnreconciledTransactions = (state, props) =>
  _.get(getCurrentReconciliation(state, props), 'entries', []).filter(
    (entry) => !entry.reconciled,
  );

// compute the 'type' for an entry so it can be used to create the correct journal.
const determineTxType = (transaction) => {
  if (transaction.state === 'external') {
    if (transaction.accountId) {
      // the transactionId will only be there for internal journals.
      return 'internal';
    } else if (transaction.amount > 0) {
      return 'deposit';
    } else if (transaction.amount < 0) {
      return 'withdrawal';
    }
  }
  return '';
};

const extractQuery = (queryString, object) => {
  let parsedQuery = qs.parse(queryString);
  return queryString[object] ? queryString[object] : null;
};

// returns the transaction by index
export const getTransaction = createSelector(
  [getTransactionIndex, getUnreconciledTransactions],
  ({ index }, transactions) => ({
    ...transactions[index],
    type: determineTxType(transactions[index]),
  }),
);
