const about = [
  {
    Header: '',
    columns: [
      {
        Header: 'Application Name',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Version',
        id: 'version',
        className: 'pull-right mono',
        accessor: 'version',
        filterable: false,
      },
    ],
  },
];

export default about;
