const blockTradeRequestsConfirmMetadata = () => [
  {
    accessor: 'state',
    id: 'state',
    Header: 'Status',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'product',
    id: 'product',
    Header: 'Product',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'contractSymbol',
    id: 'contractSymbol',
    Header: 'Contract Symbol',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'qty',
    id: 'qty',
    Header: 'Quantity',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'px',
    id: 'px',
    Header: 'Price',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'tradeDate',
    id: 'tradeDate',
    Header: 'Trade Date',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'validator',
    id: 'validator',
    Header: 'Validator',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'buySide.accountLabel',
    id: 'buySide.accountLabel',
    Header: 'Buy Account Label',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'buySide.clOrdId',
    id: 'buySide.clOrdId',
    Header: 'Buy ClOrdId',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.senderSubId',
    id: 'buySide.senderSubId',
    Header: 'Buy Sender SubId',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'sellSide.accountLabel',
    id: 'sellSide.accountLabel',
    Header: 'Sell Account Label',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'sellSide.clOrdId',
    id: 'sellSide.clOrdId',
    Header: 'Sell ClOrdId',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.senderSubId',
    id: 'sellSide.senderSubId',
    Header: 'Sell Sender SubId',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'reportingSide',
    id: 'reportingSide',
    Header: 'Reporting Side',
    minSize: 80,
    hideFilter: true,
  },

  {
    accessor: 'blockTradeId',
    id: 'blockTradeId',
    Header: 'Block Trade ID',
    minSize: 80,
    hideFilter: true,
  },
];

export default blockTradeRequestsConfirmMetadata;
