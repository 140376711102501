import { get } from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { handleActions, createAction } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  SURVEILLANCE_ENTRIES,
  CLEAR_SURVEILLANCE_ENTRIES,
} from '../constants/actionTypes';
import {
  cryptoAddressAnalyses,
  cryptoTransactionAnalyses,
} from '../actions/surveillanceActions';

export const surveillanceEntries = createRoutine(SURVEILLANCE_ENTRIES);
export const clearSurveillanceEntries = createAction(
  CLEAR_SURVEILLANCE_ENTRIES,
);
const initialState = { cryptoAddresses: {}, cryptoTransactions: {} };

export const elliptic = (state = initialState, action) => {
  switch (action.type) {
    case cryptoAddressAnalyses.SUCCESS:
      return { ...state, cryptoAddresses: action.payload };
    case cryptoTransactionAnalyses.SUCCESS:
      return { ...state, cryptoTransactions: action.payload };
    default:
      return state;
  }
};

export const entries = handleActions(
  {
    [surveillanceEntries.SUCCESS]: (state, { payload }) => payload,
    [clearSurveillanceEntries]: () => ({}),
  },
  {},
);

export default combineReducers({
  elliptic,
  entries,
});

export const getCryptoAddressAnalyses = (state) =>
  get(state, ['surveillance', 'elliptic', 'cryptoAddresses', 'analyses']);
export const getCryptoAddressAnalysesCount = (state) =>
  get(state, ['surveillance', 'elliptic', 'cryptoAddresses', 'count']);

export const getCryptoTransactionAnalyses = (state) =>
  get(state, ['surveillance', 'elliptic', 'cryptoTransactions', 'analyses']);
export const getCryptoTransactionAnalysesCount = (state) =>
  get(state, ['surveillance', 'elliptic', 'cryptoTransactions', 'count']);

export const getSurveillanceEntries = (state) =>
  get(state, ['surveillance', 'entries', 'entries'], []);
export const getSurveillanceEntriesCount = (state) =>
  get(state, ['surveillance', 'entries', 'count'], 0);
export const getSurveillanceEntriesAggregations = (state) =>
  get(state, ['surveillance', 'entries', 'aggregations']);
