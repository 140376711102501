import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  contractSymbols,
  createFuturesContract,
  futuresContracts,
  updateContract,
} from '../reducers/contractsReducer';

export default function* watchContracts() {
  yield takeLatest(
    contractSymbols.TRIGGER,
    createSaga(contractSymbols, 'CONTRACT_SYMBOLS'),
  );
  yield takeLatest(
    createFuturesContract.TRIGGER,
    createSaga(
      createFuturesContract,
      'CREATE_FUTURES_CONTRACT',
      'Creating futures contract...',
      'New futures contract created.',
    ),
  );
  yield takeLatest(
    futuresContracts.TRIGGER,
    createSaga(futuresContracts, 'FUTURES_CONTRACTS'),
  );
  yield takeLatest(
    updateContract.TRIGGER,
    createSaga(
      updateContract,
      'UPDATE_CONTRACT',
      'Updating contract...',
      'Contract updated.',
    ),
  );
}
