import React from 'react';
import { get } from 'lodash';
import { formatTimeLocal } from '../utils/time';
import { accountColumn } from '../common/table/commonMetadata';

export default [
  {
    Header: 'Time',
    id: 'time',
    accessor: ({ time }) => formatTimeLocal(time),
    minWidth: 225,
  },
  accountColumn(),
  {
    Header: 'UUID',
    id: 'uuid',
    accessor: ({ uuid }) => <span className="mono">{uuid}</span>,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
    width: 100,
  },
  {
    Header: 'Quantity',
    id: 'quantity', // not applicable to closeout journals. show a grey emdash if missing
    accessor: (row) =>
      get(row, 'quantity', <span className="grey">&mdash;</span>),
    width: 90,
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: 'description',
    minWidth: 400,
  },
  {
    Header: 'Transaction Type',
    id: 'txType',
    accessor: 'txType',
    minWidth: 120,
  },
];
