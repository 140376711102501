import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { get } from 'lodash';

export const SUB_EXCHANGES = 'SUB_EXCHANGES';
export const CREATE_SUB_EXCHANGE = 'CREATE_SUB_EXCHANGE';
export const UPDATE_SUB_EXCHANGE = 'UPDATE_SUB_EXCHANGE';

// Routines
export const createSubExchange = createRoutine(CREATE_SUB_EXCHANGE);
export const updateSubExchange = createRoutine(UPDATE_SUB_EXCHANGE);
export const fetchSubExchanges = createRoutine(SUB_EXCHANGES);

// Reducer
export default handleActions(
  {
    [fetchSubExchanges.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [createSubExchange.SUCCESS]: (state, { payload }) => ({
      ...state,
      subExchanges: [...state.subExchanges, payload],
    }),
    [updateSubExchange.SUCCESS]: (state, { payload }) => {
      const updatedSubExchanges = [...state.subExchanges];
      updatedSubExchanges[
        updatedSubExchanges.findIndex(
          (each) => each.subExchangeId === payload.subExchangeId,
        )
      ] = payload;
      return {
        ...state,
        subExchanges: updatedSubExchanges,
      };
    },
  },
  {},
);

export const getSubExchanges = (state) =>
  get(state, ['subExchanges', 'subExchanges'], []);
export const getSubExchangesCount = (state) =>
  get(state, ['subExchanges', 'count'], 0);
