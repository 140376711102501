import React from 'react';
import Big from 'bignumber.js';
import { Icon, Message, Popup } from 'semantic-ui-react';
import surveillanceRules from '../utils/surveillanceRules';

export default [
  {
    Header: '',
    id: 'alert',
    accessor: 'alert',
    maxWidth: 35,
    Cell: ({ original }) => {
      const alerts = surveillanceRules(original);
      if (alerts.length) {
        return (
          <Popup
            // onClose={onClose}
            wide="very"
            trigger={
              <Icon
                name="warning sign"
                link
                color="red"
                title="Click to see alerts."
              />
            }
            content={
              <Message info>
                {alerts.map((a) => (
                  <p>{a}</p>
                ))}
              </Message>
            }
            on="click"
          />
        );
      }
      return '';
    },
  },
  {
    Header: 'Clearing Member',
    id: 'reportingFirm',
    accessor: 'reportingFirm',
  },
  {
    Header: 'Origin',
    id: 'origin',
    accessor: 'origin',
  },
  {
    Header: 'Reportable Positions',
    columns: [
      {
        Header: 'Long',
        id: 'long',
        accessor: 'long',
      },
      {
        Header: 'Chg Long',
        id: 'changeLong',
        accessor: ({ long = 0, priorLong = 0 }) =>
          Big(long).minus(Big(priorLong)).toFixed(),
      },
      {
        Header: 'Short',
        id: 'short',
        accessor: 'short',
      },
      {
        Header: 'Chg Short',
        id: 'changeShort',
        accessor: ({ short = 0, priorShort = 0 }) =>
          Big(short).minus(Big(priorShort)).toFixed(),
      },
    ],
  },
  {
    Header: 'Open Interest',
    columns: [
      {
        Header: 'Long',
        accessor: 'positionEodLong',
      },
      {
        Header: 'Chg Long',
        id: 'changeOILong',
        accessor: ({ positionEodLong = 0, priorPositionEodLong = 0 }) =>
          Big(positionEodLong).minus(Big(priorPositionEodLong)).toFixed(),
      },
      {
        Header: 'Short',
        accessor: 'positionEodShort',
      },
      {
        Header: 'Chg Short',
        id: 'changeOILong',
        accessor: ({ positionEodShort = 0, priorPositionEodShort = 0 }) =>
          Big(positionEodShort).minus(Big(priorPositionEodShort)).toFixed(),
      },
    ],
  },
  {
    Header: 'Trade Activity',
    columns: [
      {
        Header: 'Bought',
        accessor: 'transactionQtyLong',
        id: 'transactionQtyLong',
      },
      {
        Header: 'Sold',
        accessor: 'transactionQtyShort',
        id: 'transactionQtyShort',
      },
    ],
  },
  {
    Header: 'Block Trade',
    columns: [
      {
        Header: 'Bought',
        accessor: 'transactionBlockQtyLong',
        id: 'transactionBlockQtyLong',
      },
      {
        Header: 'Sold',
        accessor: 'transactionBlockQtyShort',
        id: 'transactionBlockQtyShort',
      },
    ],
  },
  {
    Header: 'EFRP',
    columns: [
      {
        Header: 'Bought',
        accessor: 'transactionEfpQtyLong',
        id: 'transactionEfpQtyLong',
      },
      {
        Header: 'Sold',
        accessor: 'transactionEfpQtyShort',
        id: 'transactionEfpQtyShort',
      },
    ],
  },
  {
    Header: 'Total Trades',
    columns: [
      {
        Header: 'Bought',
        id: 'totalBought',
        accessor: ({
          transactionQtyLong = 0,
          transactionBlockQtyLong = 0,
          transactionEfpQtyLong = 0,
        }) =>
          Big(transactionQtyLong)
            .plus(Big(transactionBlockQtyLong))
            .plus(Big(transactionEfpQtyLong))
            .toFixed(),
      },
      {
        Header: 'Sold',
        id: 'totalSold',
        resizable: false,
        accessor: ({
          transactionQtyShort = 0,
          transactionBlockQtyShort = 0,
          transactionEfpQtyShort = 0,
        }) =>
          Big(transactionQtyShort)
            .plus(Big(transactionBlockQtyShort))
            .plus(Big(transactionEfpQtyShort))
            .toFixed(),
      },
    ],
    resizable: false,
  },
];
