import React, { Component } from 'react';
import { get, difference } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import PropTypes from 'prop-types';
import { hideModal } from 'erisxkit/client';
import {
  addClearingAccountToMemberPromiseCreator,
  removeClearingAccountFromMemberPromiseCreator,
  fetchUsers,
} from '../../reducers/usersReducer';
import {
  getSelectedAccount,
  getSelectedAccountId,
} from '../../reducers/accountHistoryReducer';
import AddRemoveUsers from '../../components/ClearingAccounts/AddRemoveUsers';
import { fetchAccountsV2 } from '../../reducers/accountsReducer';

const mapStateToProps = (state, props) => ({
  selectedAccountId: getSelectedAccountId(state),
  selectedAccount: getSelectedAccount(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      addClearingAccountToMemberPromiseCreator,
      removeClearingAccountFromMemberPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      hideModal,
      fetchUsers,
      fetchAccountsV2,
    },
    dispatch,
  ),
});

class AddRemoveUsersContainer extends Component {
  state = {
    saveDisabled: true,
    userValues: [],
    initialUsers: get(this.props, ['selectedAccount', 'memberUsers'], []).map(
      (each) => each.userId,
    ),
  };

  saveAccountDetails = async () => {
    const { userValues, initialUsers } = this.state;
    // remove initial users to avoid trying to link/unlink an account more than once
    const addUsers = difference(userValues, initialUsers);
    const removeUsers = difference(initialUsers, userValues);
    const actions = [];
    addUsers.forEach((user) => {
      actions.push(
        this.props.addClearingAccountToMemberPromiseCreator({
          userId: user,
          accountIds: [this.props.selectedAccountId],
        }),
      );
    });
    removeUsers.forEach((user) => {
      actions.push(
        this.props.removeClearingAccountFromMemberPromiseCreator({
          userId: user,
          accountIds: [this.props.selectedAccountId],
        }),
      );
    });
    Promise.all(actions).then(() => {
      this.props.fetchAccountsV2({
        filter: [
          { attr: 'account_id', value: this.props.selectedAccountId, op: 'eq' },
        ],
        showUsers: true,
      });
      this.props.hideModal();
    });
  };

  addUsers = (value) => {
    this.setState({
      userValues: value,
      saveDisabled: false,
    });
  };

  render = () => {
    const { initialUsers, saveDisabled, userValues } = this.state;
    const { addUsers, saveAccountDetails } = this;
    const { selectedAccount, hideModal } = this.props;
    const props = {
      addUsers,
      hideModal,
      saveAccountDetails,
      saveDisabled,
      selectedAccount,
    };
    return (
      <AddRemoveUsers
        {...props}
        userValues={userValues.length ? userValues : initialUsers}
      />
    );
  };
}

AddRemoveUsersContainer.propTypes = {
  addClearingAccountToMemberPromiseCreator: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  fetchAccountsV2: PropTypes.func.isRequired,
  removeClearingAccountFromMemberPromiseCreator: PropTypes.func.isRequired,
  selectedAccount: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedAccountId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRemoveUsersContainer);
