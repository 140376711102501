import React from 'react';
import { Segment, Button, Icon } from 'semantic-ui-react';
import { isEmpty, startCase } from 'lodash';

export const section = (label = '', value = '') => (
  <div className="detail-column" key={label}>
    <div className="detail-item">
      <p className="detail-title">{startCase(label)}</p>
      <p className="detail-text">{value || '—'}</p>
    </div>
  </div>
);

export const label = (label = '', value = '') => (
  <div className="label-item">
    <Icon
      name={value ? 'check' : 'close'}
      color={value ? 'green' : 'red'}
      empty
      key={label}
    />
    <span className="label-title">{startCase(label)}</span>
  </div>
);

const mapItemToSection = (item) =>
  Object.keys(item).map((key) => section(key, item[key]));

export default ({
  compact = false,
  item = {},
  editable = false,
  onEdit,
  labels,
  Component = null,
  loading,
  className,
}) => (
  <Segment loading={loading} className={className} compact={compact}>
    <div className="details-header">
      <div className="label-segment">
        {labels && Object.keys(labels).map((key) => label(key, labels[key]))}
      </div>
      {editable && <Button onClick={onEdit} content="Edit" floated="right" />}
    </div>
    {/* Support either a single object or array of objects (for multiple sections) */}
    {Array.isArray(item) ? (
      item.map(
        (subItem) =>
          !isEmpty(subItem) && (
            <div className="detail-segment">{mapItemToSection(subItem)}</div>
          ),
      )
    ) : (
      <div className="detail-segment">{mapItemToSection(item)}</div>
    )}
    {Component}
  </Segment>
);
