import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Fragment, Component } from 'react';
import { createLoadingSelector, showModal } from 'erisxkit/client';
import styled from 'styled-components';
import { Loader, Segment, Popup, Icon } from 'semantic-ui-react';
import {
  getSelectedUser,
  getAccountsForSelectedUser,
  getUserStates,
  getSelectedUserId,
  fetchUsers,
  getCurrentUser,
  getUserPermissions,
  getMemberUserTypes,
} from '../../reducers/usersReducer';
import {
  getSelectedEmployee,
  getSelectedEmployeeId,
  fetchEmployees,
} from '../../reducers/employeesReducer';
import ClearingMemberAccountsContainer from '../../containers/ClearingMemberAccounts/ClearingMemberAccountsContainer';
import ComplianceQuestionsContainer from './ComplianceQuestionsContainer';
import LinkedAccountsContainer from './LinkedAccountsContainer';
import MemberUserSettings from './MemberUserSettings';
import { ADD_REMOVE_ACCOUNTS, CREATE_MEMBER } from '../../constants/modalTypes';
import SecurityQuestionsContainer from './SecurityQuestionsContainer';
import XTab from '../../common/tabs/XTab';
import SupportingDocumentContainer from './SupportingDocumentContainer';
import BackButton from '../../common/components/BackButton';
import { memberUser, employee } from '../../constants/userTypes';
import PiiContainer from './PiiContainer';
import ReferralCode from './ReferralCode';
import NotesContainer from './NotesContainer';
import KycChecks, { canUserSeeKycChecks } from './KycChecks';
import { getApiCredentialPermissions } from '../../reducers/apiCredentialsReducer';

const mapStateToProps = (state) => ({
  accountsForSelectedUser: getAccountsForSelectedUser(state),
  loading: createLoadingSelector([fetchUsers._PREFIX])(state),
  loadingEmployees: createLoadingSelector([fetchEmployees._PREFIX])(state),
  selectedUserId: getSelectedUserId(state),
  selectedUser: getSelectedUser(state),
  selectedEmployeeId: getSelectedEmployeeId(state),
  selectedEmployee: getSelectedEmployee(state),
  userStates: getUserStates(state),
  currentUser: getCurrentUser(state),
  apiCredentialsPermissions: getApiCredentialPermissions(state),
  userPermissions: getUserPermissions(state),
  memberUserTypes: getMemberUserTypes(state),
});

const mapDispatchToProps = {
  showModal,
  fetchUsers,
  fetchEmployees,
};

const isEmployee = (match) => match.path === '/employees/:userId';

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const ContextMenu = styled.div`
  cursor: pointer;
`;

class MemberUserDetailsContainer extends Component {
  componentDidMount = () => {
    const filter = [
      {
        attr: 'user_id',
        op: 'eq',
        value: !isEmployee(this.props.match)
          ? this.props.selectedUserId
          : this.props.selectedEmployeeId,
      },
    ];

    if (isEmployee(this.props.match)) {
      filter.push({ attr: 'type', op: 'eq', value: employee });
      this.props.fetchEmployees({
        filter,
      });
    } else {
      this.props.fetchUsers({
        filter,
      });
    }
  };

  addAccount = () => {
    this.props.showModal(ADD_REMOVE_ACCOUNTS);
  };

  panes = (user = {}, currentUser = {}) => {
    const panesList = [];
    if (_.get(user, 'type') === memberUser) {
      panesList.push({
        url: 'clearing_accounts',
        menuItem: 'Clearing Accounts',
        render: ({ selectedUser: { userId = '', accountLinks = [] } = {} }) => (
          <ClearingMemberAccountsContainer
            detailsView
            userId={userId}
            addAccount={this.addAccount}
            userAccounts={accountLinks.map(
              (accountLink) => accountLink.accountId,
            )}
          />
        ),
      });
      panesList.push({
        url: 'pii',
        menuItem: 'PII',
        render: ({ selectedUser = {} }) => (
          <PiiContainer selectedUser={selectedUser} />
        ),
      });
      panesList.push({
        url: 'compliance',
        menuItem: 'Compliance',
        render: ({ selectedUser: { userId = '' } = {} }) => (
          <ComplianceQuestionsContainer userId={userId} />
        ),
      });

      panesList.push({
        url: 'security_questions',
        menuItem: 'Security Questions',
        render: ({ selectedUser: { userId = '' } = {} }) => (
          <SecurityQuestionsContainer userId={userId} />
        ),
      });
      panesList.push({
        url: 'supporting_doc',
        menuItem: 'Supporting Document',
        render: ({ selectedUser: { userId = '' } = {} }) => (
          <SupportingDocumentContainer userId={userId} />
        ),
      });
      if (canUserSeeKycChecks(currentUser)) {
        panesList.push({
          url: 'kyc_checks',
          menuItem: 'KYC Checks',
          render: ({ selectedUser: { userId = '' } = {} }) => (
            <KycChecks userId={userId} />
          ),
        });
      }
      panesList.push({
        url: 'referral_code',
        menuItem: 'Referral Code',
        render: ({ selectedUser: { userId = '' } = {} }) => (
          <ReferralCode userId={userId} />
        ),
      });
    }

    panesList.push({
      url: 'settings',
      menuItem: 'Settings',
      render: ({ selectedUser, selectedEmployee }) => (
        <MemberUserSettings
          selectedUser={
            _.get(user, 'type') === memberUser ? selectedUser : selectedEmployee
          }
          type={_.get(user, 'type')}
        />
      ),
    });

    if (_.get(user, 'type') === memberUser) {
      panesList.push({
        url: 'notes',
        menuItem: 'Notes',
        render: ({ selectedUser }) => (
          <NotesContainer selectedUser={selectedUser} />
        ),
      });
    }
    return panesList;
  };

  openOptionsMenu = () => {
    this.setState({ isOpen: true });
  };

  closeOptionsMenu = () => {
    this.setState({ isOpen: false });
  };

  createMemberModal = () => {
    const {
      apiCredentialsPermissions,
      showModal,
      userPermissions,
      memberUserTypes,
      selectedUser,
    } = this.props;
    showModal(CREATE_MEMBER, {
      userPermissions,
      apiCredentialsPermissions,
      type: memberUser,
      memberUserTypes,
      cloneMember: selectedUser,
    });
    this.closeOptionsMenu();
  };

  render = () => {
    let selectedUser = this.props.selectedUser;
    let email = _.get(this.props, ['selectedUser', 'email']);
    let userId = _.get(this.props, ['selectedUser', 'userId']);
    let message = 'Fetching selected member user...';

    if (isEmployee(this.props.match)) {
      selectedUser = this.props.selectedEmployee;
      email = _.get(this.props, ['selectedEmployee', 'email']);
      userId = _.get(this.props, ['selectedEmployee', 'userId']);
      message = 'Fetching selected employee...';
    }

    return (
      <Fragment>
        <Wrapper>
          <BackButton header={email} subheader={userId} />
          <Popup
            trigger={<Icon name="ellipsis vertical large" link />}
            content={
              <ContextMenu onClick={this.createMemberModal}>
                Clone this Member User
              </ContextMenu>
            }
            on="click"
            position="bottom right"
            open={this.state?.isOpen}
            onClose={this.closeOptionsMenu}
            onOpen={this.openOptionsMenu}
          />
        </Wrapper>
        {this.props.loading ||
        this.props.loadingEmployees ||
        _.isEmpty(selectedUser) ? (
          <Segment padded="very" basic className="center-vertical">
            <Loader active content={message} />
          </Segment>
        ) : (
          <XTab
            panes={this.panes(selectedUser, this.props.currentUser)}
            selectedUser={selectedUser}
          />
        )}
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberUserDetailsContainer);
