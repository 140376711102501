import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Header, Input, Segment, Button } from 'semantic-ui-react';
import { hideModal } from 'erisxkit/client';
import { CONFIRM_NO_POST_TO_EXCHANGE_PASSPHRASE } from '../../constants/confirmationPassphrases';

const mapDispatchToProps = {
  hideModal,
};

class ConfirmNoPostToExchange extends PureComponent {
  state = { disabled: true };

  confirmChange = (e, { value }) => {
    if (value === CONFIRM_NO_POST_TO_EXCHANGE_PASSPHRASE) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  };

  render = () => (
    <Fragment>
      <Header as="h1" size="huge" color="red" attached>
        Disable posting to exchange?
      </Header>
      <Segment attached color="red" inverted>
        <p>
          This is a dangerous operation! No risk checking or TME balance
          deductions will occur. Type{' '}
          <span className="mono">{`"${CONFIRM_NO_POST_TO_EXCHANGE_PASSPHRASE}"`}</span>{' '}
          below to continue.
        </p>
        <Input name="bypass" onChange={this.confirmChange} />
      </Segment>
      <Segment attached="bottom">
        <Button onClick={this.props.hideModal}>Close</Button>
        <Button
          color="red"
          disabled={this.state.disabled}
          onClick={this.props.confirmPostingDisabled}
          name="disable"
        >
          Disable
        </Button>
      </Segment>
    </Fragment>
  );
}

export default connect(null, mapDispatchToProps)(ConfirmNoPostToExchange);
