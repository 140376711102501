import React from 'react';
import _ from 'lodash';
import { Dropdown, Label } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';
import { iconColorForState } from '../utils/methods';

const cryptoAddresses = (override, remove) => [
  {
    Header: 'Label',
    id: 'label',
    accessor: 'label',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Asset Type',
    id: 'assetType',
    accessor: 'assetType',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Address',
    id: 'address',
    accessor: 'address',
    headerClassName: 'pull-left',
  },
  {
    Header: 'Date Added',
    id: 'createdAt',
    accessor: (row) => formatTimeLocal(row.createdAt),
  },
  {
    Header: 'Usable At',
    id: 'usableAt',
    accessor: (row) => formatTimeLocal(row.usableAt),
  },
  {
    Header: 'Status',
    id: 'state',
    accessor: (d) => (
      <Label color={iconColorForState(d.state)} content={d.state} />
    ),
    className: 'pull-right',
    headerClassName: 'pull-right',
    resizable: false,
  },
  {
    Header: '',
    id: 'more',
    accessor: (d) => (
      <Dropdown icon="ellipsis vertical" pointing="top right">
        <Dropdown.Menu>
          <Dropdown.Item
            key="override"
            text="Override 72-hour Hold"
            onClick={() => override(d)}
            icon="time"
          />
          {!!remove && (
            <Dropdown.Item
              key="remove"
              text="Delete SSI"
              onClick={() => remove(d)}
              icon="remove"
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
    filterable: false,
    resizable: false,
    width: 35,
  },
];

export default cryptoAddresses;
