import { takeLatest } from 'redux-saga/effects';
import { apiCredentialsPermissions } from '../reducers/apiCredentialsReducer';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    apiCredentialsPermissions.TRIGGER,
    createSaga(apiCredentialsPermissions, apiCredentialsPermissions._PREFIX),
  );
}
