export const SELECT_ACCOUNT_RECONCILIATION = 'SELECT_ACCOUNT_RECONCILIATION';
export const SELECT_RECONCILIATION = 'SELECT_RECONCILIATION';

export const RECONCILIATION_REQUEST = 'RECONCILIATION_REQUEST';
export const RECONCILIATION_SUCCESS = 'RECONCILIATION_SUCCESS';
export const RECONCILIATION_FAILED = 'RECONCILIATION_FAILED';

export const RECONCILIATION_HISTORY_REQUEST = 'RECONCILIATION_HISTORY_REQUEST';
export const RECONCILIATION_HISTORY_SUCCESS = 'RECONCILIATION_HISTORY_SUCCESS';
export const RECONCILIATION_HISTORY_FAILED = 'RECONCILIATION_HISTORY_FAILED';

export const CREATE_RECONCILIATION_REQUEST = 'CREATE_RECONCILIATION_REQUEST';
export const CREATE_RECONCILIATION_SUCCESS = 'CREATE_RECONCILIATION_SUCCESS';
export const CREATE_RECONCILIATION_FAILED = 'CREATE_RECONCILIATION_FAILED';

export const UPDATE_RECONCILIATION_ENTRY_DETAILS_REQUEST =
  'UPDATE_RECONCILIATION_ENTRY_DETAILS_REQUEST';
export const UPDATE_RECONCILIATION_ENTRY_DETAILS_SUCCESS =
  'UPDATE_RECONCILIATION_ENTRY_DETAILS_SUCCESS';
export const UPDATE_RECONCILIATION_ENTRY_DETAILS_FAILED =
  'UPDATE_RECONCILIATION_ENTRY_DETAILS_FAILED';

//* Accounts action Types */
export const ACCOUNTS_REQUEST = 'ACCOUNTS_REQUEST';
export const ACCOUNTS_SUCCESS = 'ACCOUNTS_SUCCESS';
export const ACCOUNTS_FAILED = 'ACCOUNTS_FAILED';

export const ACCOUNTS_V2 = 'ACCOUNTS_V2';
export const FCM_FIRM_CODES = 'FCM_FIRM_CODES';

export const EMARKET_ACCOUNT_TYPES = 'EMARKET_ACCOUNT_TYPES';
export const IRA_CUSTODIANS = 'IRA_CUSTODIANS';
export const IRA_ACCOUNT_TYPES_AND_CODES = 'IRA_ACCOUNT_TYPES_AND_CODES';

export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

export const UNUSED_WALLETS_REQUEST = 'UNUSED_WALLETS_REQUEST';
export const UNUSED_WALLETS_SUCCESS = 'UNUSED_WALLETS_SUCCESS';
export const UNUSED_WALLETS_FAILED = 'UNUSED_WALLETS_FAILED';

export const DESIGNATED_BALANCE_DETAILS = 'DESIGNATED_BALANCE_DETAILS';
export const BALANCES = 'BALANCES';

export const MULTI_ACCOUNT_BALANCES = 'MULTI_ACCOUNT_BALANCES';

export const CURRENEX_BALANCES = 'EMARKET_BALANCES';
export const CLEAR_CURRENEX_BALANCES = 'CLEAR_CURRENEX_BALANCES';

export const CREATE_EMARKET_BALANCE_ADJUSTMENT =
  'CREATE_EMARKET_BALANCE_ADJUSTMENT';
export const EMARKET_BALANCE_ADJUSTMENTS = 'EMARKET_BALANCE_ADJUSTMENTS';

export const POSITIONS = 'POSITIONS';
export const POSITION_SUMMARY = 'POSITION_SUMMARY';
export const POSITIONS_AGGREGATED = 'POSITIONS_AGGREGATED';
export const CLEAR_POSITIONS = 'CLEAR_POSITIONS';
export const CLEAR_POSITION_TRANSFERS = 'CLEAR_POSITION_TRANSFERS';

export const FUTURES_DCO_ACCOUNT = 'FUTURES_DCO_ACCOUNT';
//* Create Withdrawal/Deposit action Types */
export const SELECT_TRANSACTION = 'SELECT_TRANSACTION';

export const CREATE_WITHDRAWAL_REQUEST = 'CREATE_WITHDRAWAL_REQUEST';
export const CREATE_WITHDRAWAL_SUCCESS = 'CREATE_WITHDRAWAL_SUCCESS';
export const CREATE_WITHDRAWAL_FAILED = 'CREATE_WITHDRAWAL_FAILED';

export const CREATE_DEPOSIT_REQUEST = 'CREATE_DEPOSIT_REQUEST';
export const CREATE_DEPOSIT_SUCCESS = 'CREATE_DEPOSIT_SUCCESS';
export const CREATE_DEPOSIT_FAILED = 'CREATE_DEPOSIT_FAILED';

export const CREATE_TRANSFER_REQUEST = 'CREATE_TRANSFER_REQUEST';
export const CREATE_TRANSFER_SUCCESS = 'CREATE_TRANSFER_SUCCESS';
export const CREATE_TRANSFER_FAILED = 'CREATE_TRANSFER_FAILED';

export const CREATE_REVERSAL_REQUEST = 'CREATE_REVERSAL_REQUEST';
export const CREATE_REVERSAL_SUCCESS = 'CREATE_REVERSAL_SUCCESS';
export const CREATE_REVERSAL_FAILED = 'CREATE_REVERSAL_FAILED';

//* Account Transaction History action types */
export const SELECT_ACCOUNT_HISTORY = 'SELECT_ACCOUNT_HISTORY';

export const ACCOUNT_HISTORY_REQUEST = 'ACCOUNT_HISTORY_REQUEST';
export const ACCOUNT_HISTORY_SUCCESS = 'ACCOUNT_HISTORY_SUCCESS';
export const ACCOUNT_HISTORY_FAILED = 'ACCOUNT_HISTORY_FAILED';

//* Reconciliation Approval action types */
export const APPROVAL_LOGS = 'APPROVAL_LOGS';

export const APPROVAL_REQS_REQUEST = 'APPROVAL_REQS_REQUEST';
export const APPROVAL_REQS_SUCCESS = 'APPROVAL_REQS_SUCCESS';
export const APPROVAL_REQS_FAILED = 'APPROVAL_REQS_FAILED';

export const APPROVAL_REQ_PAYLOAD_REQUEST = 'APPROVAL_REQ_PAYLOAD_REQUEST';
export const APPROVAL_REQ_PAYLOAD_SUCCESS = 'APPROVAL_REQ_PAYLOAD_SUCCESS';
export const APPROVAL_REQ_PAYLOAD_FAILED = 'APPROVAL_REQ_PAYLOAD_FAILED';

//* Approve/Reject open ApprovalReqs, get Approval */
export const APPROVE = 'APPROVE';

export const SELECT_APPROVAL = 'SELECT_APPROVAL';

//* Get Available Asset Types action Types */
export const ASSET_TYPES_REQUEST = 'ASSET_TYPES_REQUEST';
export const ASSET_TYPES_SUCCESS = 'ASSET_TYPES_SUCCESS';
export const ASSET_TYPES_FAILED = 'ASSET_TYPES_FAILED';

//* Create Asset Type */
export const CREATE_ASSET_TYPE = 'CREATE_ASSET_TYPE';

//* Modal Action Types */
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

//* Auth actions */
export const STORE_USER = 'STORE_USER';
export const STORE_ENV = 'STORE_ENV';

//* System alerts and exceptions action types */
export const SYSTEM_ALERTS_REQUEST = 'SYSTEM_ALERTS_REQUEST';
export const SYSTEM_ALERTS_SUCCESS = 'SYSTEM_ALERTS_SUCCESS';
export const SYSTEM_ALERTS_FAILED = 'SYSTEM_ALERTS_FAILED';

export const SYSTEM_ALERT_INFO_REQUEST = 'SYSTEM_ALERT_INFO_REQUEST';
export const SYSTEM_ALERT_INFO_SUCCESS = 'SYSTEM_ALERT_INFO_SUCCESS';
export const SYSTEM_ALERT_INFO_FAILED = 'SYSTEM_ALERT_INFO_FAILED';

export const EXCEPTIONS_REQUEST = 'EXCEPTIONS_REQUEST';
export const EXCEPTIONS_SUCCESS = 'EXCEPTIONS_SUCCESS';
export const EXCEPTIONS_FAILED = 'EXCEPTIONS_FAILED';

export const EXCEPTION_INFO_REQUEST = 'EXCEPTION_INFO_REQUEST';
export const EXCEPTION_INFO_SUCCESS = 'EXCEPTION_INFO_SUCCESS';
export const EXCEPTION_INFO_FAILED = 'EXCEPTION_INFO_FAILED';

export const SELECT_EXCEPTION = 'SELECT_EXCEPTION';

export const CHANGE_LOGS = 'CHANGE_LOGS';

export const REJECTED_JOURNALS = 'REJECTED_JOURNALS';

export const SYSTEM_ALERTS_ACTIVE_TAB = 'SYSTEM_ALERTS_ACTIVE_TAB';

//* TCRs actions */
export const REPROCESS_TRADE_CAPTURE_REPORT = 'REPROCESS_TRADE_CAPTURE_REPORT';
export const REQUEST_REPROCESS_TRADE_CAPTURE_REPORT =
  'REQUEST_REPROCESS_TRADE_CAPTURE_REPORT';
export const TRADE_CAPTURE_REPORTS = 'TRADE_CAPTURE_REPORTS';
export const CLEAR_TRADE_CAPTURE_REPORT = 'CLEAR_TRADE_CAPTURE_REPORT';
export const UPDATE_TRADE_CAPTURE_REPORT = 'UPDATE_TRADE_CAPTURE_REPORT';

//* Manual entry actions */
export const CREATE_FEE_REBATE_REQUEST = 'CREATE_FEE_REBATE_REQUEST';
export const CREATE_FEE_REBATE_SUCCESS = 'CREATE_FEE_REBATE_SUCCESS';
export const CREATE_FEE_REBATE_FAILED = 'CREATE_FEE_REBATE_FAILED';

export const CREATE_GENERAL_JOURNAL = 'CREATE_GENERAL_JOURNAL';
export const GENERAL_JOURNAL_CATEGORIES = 'GENERAL_JOURNAL_CATEGORIES';

export const CREATE_TRADE = 'CREATE_TRADE';

export const CLOSING_PRICES = 'CLOSING_PRICES';
export const UPDATE_CLOSING_PRICE = 'UPDATE_CLOSING_PRICE';
export const CREATE_DESIGNATION_TRANSFER_JOURNAL =
  'CREATE_DESIGNATION_TRANSFER_JOURNAL';

export const CREATE_CRYPTO_WITHDRAWAL_REQUEST =
  'CREATE_CRYPTO_WITHDRAWAL_REQUEST';
export const CREATE_FIAT_WITHDRAWAL_REQUEST = 'CREATE_FIAT_WITHDRAWAL_REQUEST';

//* Surveillance actions */
export const CRYPTO_ADDRESS_ANALYSES = 'CRYPTO_ADDRESS_ANALYSES';
export const CRYPTO_TRANSACTION_ANALYSES = 'CRYPTO_TRANSACTION_ANALYSES';

export const SURVEILLANCE_ENTRIES = 'SURVEILLANCE_ENTRIES';
export const CLEAR_SURVEILLANCE_ENTRIES = 'CLEAR_SURVEILLANCE_ENTRIES';

//* About actions */
export const ABOUT = 'ABOUT';

//* Utility actions */
export const SCALE_BASE_TO_MIN_UNIT = 'SCALE_BASE_TO_MIN_UNIT';

export const SPOT_PRODUCTS = 'SPOT_PRODUCTS';
export const CREATE_SPOT_PRODUCT = 'CREATE_SPOT_PRODUCT';
export const UPDATE_SPOT_PRODUCT = 'UPDATE_SPOT_PRODUCT';

export const CREATE_FUTURES_CONTRACT = 'CREATE_FUTURES_CONTRACT';
export const CREATE_FUTURES_PRODUCT = 'CREATE_FUTURES_PRODUCT';
export const UPDATE_FUTURES_PRODUCT = 'UPDATE_FUTURES_PRODUCT';
export const FUTURES_CONTRACTS = 'FUTURES_CONTRACTS';
export const FUTURES_PRODUCTS = 'FUTURES_PRODUCTS';

export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';

// . Task actions */
export const TASKS = 'TASKS';
export const TASK_LOG = 'TASK_LOG';
export const TASK_INFO = 'TASK_INFO';
export const TRIGGER_TASK = 'TRIGGER_TASK';

//* Member actions */
export const MEMBERS = 'MEMBERS';
export const CREATE_FIRM_MEMBER = 'CREATE_FIRM_MEMBER';
export const CREATE_VENDOR_MEMBER = 'CREATE_VENDOR_MEMBER';
export const CREATE_DIRECT_MEMBER = 'CREATE_DIRECT_MEMBER';
export const CREATE_EXECUTION_BROKER_MEMBER = 'CREATE_EXECUTION_BROKER_MEMBER';
export const CREATE_INVESTMENT_MANAGER_MEMBER =
  'CREATE_INVESTMENT_MANAGER_MEMBER';
export const CREATE_INTERMEDIARY_MEMBER = 'CREATE_INTERMEDIARY_MEMBER';
export const CREATE_FCM_MEMBER = 'CREATE_FCM_MEMBER';
export const SELECT_MEMBER = 'SELECT_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const ADD_MEMBER_TO_LEDGER_ACCOUNTS = 'ADD_MEMBER_TO_LEDGER_ACCOUNTS';
export const REMOVE_MEMBER_FROM_LEDGER_ACCOUNTS =
  'REMOVE_MEMBER_FROM_LEDGER_ACCOUNTS';
export const ADD_MEMBER_TO_USERS = 'ADD_MEMBER_TO_USERS';
export const REMOVE_MEMBER_FROM_USERS = 'REMOVE_MEMBER_FROM_USERS';

//* Collateral actions  */
export const COLLATERAL = 'COLLATERAL';
export const COLLATERAL_CONTRACTS = 'COLLATERAL_CONTRACTS';
export const COLLATERAL_PRODUCTS = 'COLLATERAL_PRODUCTS';
export const CREATE_COLLATERAL_DEPOSIT_JOURNAL =
  'CREATE_COLLATERAL_DEPOSIT_JOURNAL';
export const CREATE_COLLATERAL_CONTRACT = 'CREATE_COLLATERAL_CONTRACT';
export const CREATE_COLLATERAL_PRODUCT = 'CREATE_COLLATERAL_PRODUCT';
export const UPDATE_COLLATERAL_PRODUCT = 'UPDATE_COLLATERAL_PRODUCT';
export const CREATE_COLLATERAL_WITHDRAWAL_JOURNAL =
  'CREATE_COLLATERAL_WITHDRAWAL_JOURNAL';

//* Contracts actions */
export const CONTRACT_SYMBOLS = 'CONTRACT_SYMBOLS';

export const CREATE_CLOSEOUT_INSTRUCTION = 'CREATE_CLOSEOUT_INSTRUCTION';
export const CLOSEOUT_JOURNALS = 'CLOSEOUT_JOURNALS';

export const FUNDS_SEGREGATION_BALANCES = 'FUNDS_SEGREGATION_BALANCES';

//* Ledger Actions */
export const CREATE_LEDGER = 'CREATE_LEDGER';
export const LEDGERS = 'LEDGERS';
export const SWITCH_LEDGER = 'SWITCH_LEDGER';

/* UBO Actions */
export const ADD_ULTIMATE_BENEFICIAL_OWNER = 'ADD_ULTIMATE_BENEFICIAL_OWNER';
export const REMOVE_ULTIMATE_BENEFICIAL_OWNER =
  'REMOVE_ULTIMATE_BENEFICIAL_OWNER';
export const UPDATE_ULTIMATE_BENEFICIAL_OWNER =
  'UPDATE_ULTIMATE_BENEFICIAL_OWNER';

/* UPCOMING DEPOSITS */
export const UPCOMING_DEPOSITS = 'UPCOMING_DEPOSITS';

/* Fee profiles */
export const FEES_SUCCESS = 'FEES_SUCCESS';
export const FEES_REQUEST = 'FEES_REQUEST';

export const BLOCK_TRADE_REQUEST = 'BLOCK_TRADE_REQUEST';

export const MEMBER_TRANSFER_FINAL_APPROVALS =
  'MEMBER_TRANSFER_FINAL_APPROVALS';
export const APPROVE_ALL = 'APPROVE_ALL';
export const REJECT_ALL = 'REJECT_ALL';

export const SETTLEMENT_INSTRUCTIONS = 'SETTLEMENT_INSTRUCTIONS';
export const REJECT_SETTLEMENT = 'REJECT_SETTLEMENT';
export const CONFIRM_SETTLEMENT = 'CONFIRM_SETTLEMENT';

export const BASE_MARGIN_RATES = 'BASE_MARGIN_RATES';
export const MARGIN_RATES_MULTIPLIERS = 'MARGIN_RATES_MULTIPLIERS';
export const APPLIED_MARGIN_RATES = 'APPLIED_MARGIN_RATES';
export const REJECT_MARGIN_RATES = 'REJECT_MARGIN_RATES';
export const ACCEPT_MARGIN_RATES = 'ACCEPT_MARGIN_RATES';
export const CREATE_APPLIED_MARGIN_RATES = 'CREATE_APPLIED_MARGIN_RATES';

export const AUTHORIZED_IPS = 'AUTHORIZED_IPS';
export const IP_AUTHORIZATION_HISTORY = 'IP_AUTHORIZATION_HISTORY';

export const ADD_AUTHORIZED_IP = 'ADD_AUTHORIZED_IP';
export const REMOVE_AUTHORIZED_IP = 'REMOVE_AUTHORIZED_IP';

/* INITIAL MARGINS */
export const FCM_MARGIN_CALL_CALCULATIONS = 'FCM_MARGIN_CALL_CALCULATIONS';
export const FCM_MARGIN_CALL_REQUIREMENTS = 'FCM_MARGIN_CALL_REQUIREMENTS';
export const SET_MARGIN_REQUIREMENTS = 'SET_MARGIN_REQUIREMENTS';
export const UPDATE_INITIAL_MARGIN_TRADE_DATE =
  'UPDATE_INITIAL_MARGIN_TRADE_DATE';

export const DELETE_FIAT_DEPOSIT_SCHEDULE = 'DELETE_FIAT_DEPOSIT_SCHEDULE';

export const CLEAR_TRADE_ENTRY_CGMS_BUY_SIDE =
  'CLEAR_TRADE_ENTRY_CGMS_BUY_SIDE';
export const CLEAR_TRADE_ENTRY_CGMS_SELL_SIDE =
  'CLEAR_TRADE_ENTRY_CGMS_SELL_SIDE';
export const POSITION_TRANSFER_TYPES = 'POSITION_TRANSFER_TYPES';
export const POSITION_TRANSFERS = 'POSITION_TRANSFERS';
export const CREATE_POSITION_TRANSFERS_REQUEST =
  'CREATE_POSITION_TRANSFERS_REQUEST';
