import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import has from 'lodash/has';

export const RISK_ACCOUNT_SUMMARY = 'RISK_ACCOUNT_SUMMARY';
export const SELECT_RISK_ACCOUNT = 'SELECT_RISK_ACCOUNT';
export const MARGIN_CALL_ALERTS = 'MARGIN_CALL_ALERTS';
export const RISK_DETAIL = 'RISK_DETAIL';

export const RISK_ALERTS = 'RISK_ALERTS';
export const RISK_ALERT_COMMENTS = 'RISK_ALERT_COMMENTS';
export const RISK_ALERT_STATUSES = 'RISK_ALERT_STATUSES';
export const ADD_RISK_ALERT_COMMENT = 'ADD_RISK_ALERT_COMMENT';
export const UPDATE_RISK_ALERT_STATUS = 'UPDATE_RISK_ALERT_STATUS';
export const UPDATE_MARGIN_REQUIREMENT_STATUS =
  'UPDATE_MARGIN_REQUIREMENT_STATUS';
export const MARGIN_DELIVERY = 'MARGIN_DELIVERY';

export const fetchRisk = createRoutine(RISK_ACCOUNT_SUMMARY);
export const fetchRiskDetail = createRoutine(RISK_DETAIL);
export const fetchMarginCallAlerts = createRoutine(MARGIN_CALL_ALERTS);
export const fetchRiskAlerts = createRoutine(RISK_ALERTS);
export const fetchRiskAlertComments = createRoutine(RISK_ALERT_COMMENTS);
export const fetchRiskAlertStatuses = createRoutine(RISK_ALERT_STATUSES);
export const addRiskAlertComment = createRoutine(ADD_RISK_ALERT_COMMENT);
export const updateRiskAlertStatus = createRoutine(UPDATE_RISK_ALERT_STATUS);
export const updateMarginRequirementStatus = createRoutine(
  UPDATE_MARGIN_REQUIREMENT_STATUS,
);
export const fetchMarginDelivery = createRoutine(MARGIN_DELIVERY);

const RESOLVED_MARGIN_CALL_STATUS = 'RESOLVED';
// Reducer
export default handleActions(
  {
    [fetchRisk.SUCCESS]: (state, { payload }) => ({
      ...state,
      risk: payload,
    }),
    [fetchRiskDetail.SUCCESS]: (state, { payload }) => ({
      ...state,
      riskDetail: payload,
    }),
    [fetchMarginCallAlerts.SUCCESS]: (state, { payload }) => ({
      ...state,
      marginCallAlerts: payload,
    }),
    [fetchRiskAlerts.SUCCESS]: (state, { payload }) => ({
      ...state,
      riskAlert: payload,
    }),
    [fetchRiskAlertComments.SUCCESS]: (state, { payload }) => ({
      ...state,
      riskAlertComments: payload,
    }),
    [fetchRiskAlertStatuses.SUCCESS]: (state, { payload }) => ({
      ...state,
      riskAlertStatus: payload,
    }),
    [addRiskAlertComment.SUCCESS]: (state, { payload }) => ({
      ...state,
      riskAlertComments: {
        count: state.riskAlertComments.count + 1,
        riskAlertComments: [
          ...state.riskAlertComments.riskAlertComments,
          payload.alertComment,
        ],
      },
    }),
    [updateRiskAlertStatus.SUCCESS]: (state, { payload }) => ({
      ...state,
      riskAlertStatus: {
        count: state.riskAlertStatus.count + 1,
        riskAlertStatuses: [
          payload.alertStatus,
          ...state.riskAlertStatus.riskAlertStatuses,
        ],
      },
    }),
    [updateMarginRequirementStatus.SUCCESS]: (state, { payload }) => ({
      ...state,
      marginCallAlerts: {
        marginCallAlerts: state?.marginCallAlerts?.marginCallAlerts
          .map((obj) =>
            obj?.id ===
            get(payload, 'marginRequirementStatus.marginRequirementId', '')
              ? {
                  ...obj,
                  status: get(payload, 'marginRequirementStatus.status', ''),
                }
              : obj,
          )
          .filter((obj) => obj?.status !== RESOLVED_MARGIN_CALL_STATUS),
      },
    }),
    [fetchMarginDelivery.SUCCESS]: (state, { payload }) => ({
      ...state,
      marginDelivery: payload.contractView,
    }),
  },
  {},
);

export const getRiskSummary = (state) =>
  get(state, ['risk', 'risk', 'riskAccountSummaries'], []);
export const getMarginCallAlerts = (state) =>
  get(state, ['risk', 'marginCallAlerts', 'marginCallAlerts'], []);
export const getSegFundsOwed = (state) =>
  get(state, ['risk', 'marginCallAlerts', 'segFundsOwed'], []);
export const getMpFundsOwed = (state) =>
  get(state, ['risk', 'marginCallAlerts', 'mpFundsOwed'], []);
export const getTotalUsdOwed = (state) =>
  get(state, ['risk', 'marginCallAlerts', 'totalUsdOwed'], []);
export const getMarginCallAlertsCount = (state) =>
  get(state, ['risk', 'marginCallAlerts', 'count'], []);
export const getRiskDetail = (state) =>
  get(state, ['risk', 'riskDetail', 'riskDetails'], []);
export const getRiskAccount = () => {
  const match = /(?:risk)\/([0-9]*)\/([-A-z0-9]*)\/detail/.exec(
    window.location.href,
  );
  if (!match) return '';
  return match[2];
};

export const getRiskSnapshotId = () => {
  const match = /(?:risk)\/([0-9]*)\/([-A-z0-9]*)\/detail/.exec(
    window.location.href,
  );
  if (!match) return '';
  return match[1];
};

export const getRiskAlertComments = (state) =>
  get(state, ['risk', 'riskAlertComments', 'riskAlertComments'], []);
export const getRiskAlertStatuses = (state) =>
  get(state, ['risk', 'riskAlertStatus', 'riskAlertStatuses'], []);
export const getFirstRiskAlertStatus = (state) =>
  get(state, ['risk', 'riskAlertStatus', 'riskAlertStatuses', [0]], []);
export const getRiskAlerts = (state) =>
  get(state, ['risk', 'riskAlert', 'riskAlerts'], []);

export const getMarginDelivery = (state) => {
  const data = get(state, ['risk', 'marginDelivery'], []);
  return dataTransformMarginDelivery(data);
};

export const getFuturesPositionMarginDelivery = (state) => {
  const data = get(state, ['risk', 'marginDelivery'], []);
  return dataTransformFuturesPositionMarginDelivery(data);
};

export const createRows = (
  ls,
  productSymbol,
  contractSymbol,
  maturityDate,
  deliveryType,
  mainSummary,
  accountView,
) => ({
  productSymbol,
  contractSymbol,
  deliveryType,
  maturityDate,
  ls,
  qty: get(mainSummary[ls], ['qty']),
  price: get(mainSummary[ls], ['price']),
  deliveryAsset: get(mainSummary[ls], ['deliveryAsset']),
  deliveryRequirement: get(mainSummary[ls], ['deliveryRequirement']),
  deliveryAmount: get(mainSummary[ls], ['deliveryAmount']),
  deliveryBalance: get(mainSummary[ls], ['deliveryBalance']),
  satisfied: get(mainSummary[ls], ['satisfied']),
  subRows: accountView
    // Use only account views that have the desired long/short type
    ?.filter((ac) => has(ac, ['summary', ls]))
    ?.map((e) => {
      // The total of each account view
      const accountData = get(e, ['summary', ls]);
      return {
        productSymbol,
        contractSymbol,
        deliveryType,
        maturityDate,
        account: get(e, ['accountLabel']),
        ls,
        qty: get(accountData, ['qty']),
        price: get(accountData, ['price']),
        deliveryAsset: get(accountData, ['deliveryAsset']),
        deliveryRequirement: get(accountData, ['deliveryRequirement']),
        deliveryAmount: get(accountData, ['deliveryAmount']),
        deliveryBalance: get(accountData, ['deliveryBalance']),
        satisfied: get(accountData, ['satisfied']),
        subRows: get(e, ['cgmView'])
          .filter((x) => x?.summary?.[ls])
          .map((cgm) => {
            // The total of each cgm view
            const cgmData = get(cgm, ['summary', ls]);
            return {
              productSymbol,
              contractSymbol,
              deliveryType,
              maturityDate,
              account: get(e, ['accountLabel']),
              cgm: get(cgm, ['cgmNumber']),
              deliveryAcct: get(cgm, ['deliveryAcctId']),
              ls,
              qty: get(cgmData, ['qty']),
              price: get(cgmData, ['price']),
              deliveryAsset: get(cgmData, ['deliveryAsset']),
              deliveryRequirement: get(cgmData, ['deliveryRequirement']),
              deliveryAmount: get(cgmData, ['deliveryAmount']),
              deliveryBalance: get(cgmData, ['deliveryBalance']),
              satisfied: get(cgmData, ['satisfied']),
            };
          }),
      };
    }),
});

const mainSubRows = (
  productSymbol,
  contractSymbol,
  maturityDate,
  deliveryType,
  summary,
  accountView,
) => {
  const arrSubRow = [];
  summary?.long &&
    arrSubRow.push(
      createRows(
        'long',
        productSymbol,
        contractSymbol,
        maturityDate,
        deliveryType,
        summary,
        accountView,
      ),
    );
  summary?.short &&
    arrSubRow.push(
      createRows(
        'short',
        productSymbol,
        contractSymbol,
        maturityDate,
        deliveryType,
        summary,
        accountView,
      ),
    );

  return arrSubRow;
};

export const dataTransformMarginDelivery = (data) => {
  const arrFinal = [];

  data.forEach(
    ({
      productSymbol,
      contractSymbol,
      maturityDate,
      deliveryType,
      summary,
      accountView,
      contractCode,
    }) => {
      arrFinal.push({
        productSymbol,
        contractSymbol,
        deliveryType,
        maturityDate,
        contractCode,
        subRows: mainSubRows(
          productSymbol,
          contractSymbol,
          maturityDate,
          deliveryType,
          summary,
          accountView,
        ),
      });
    },
  );

  return arrFinal;
};

export const dataTransformFuturesPositionMarginDelivery = (data) => {
  const arrFinal = [];

  data.forEach(
    ({
      productSymbol,
      contractSymbol,
      maturityDate,
      deliveryType,
      summary,
      accountView,
      contractCode,
    }) => {
      arrFinal.push({
        productSymbol,
        contractSymbol,
        deliveryType,
        maturityDate,
        contractCode,
        subRows: mainSubRowsFuturesPosition(
          productSymbol,
          contractSymbol,
          maturityDate,
          deliveryType,
          summary,
          accountView,
        ),
      });
    },
  );

  return arrFinal;
};

const mainSubRowsFuturesPosition = (
  productSymbol,
  contractSymbol,
  maturityDate,
  deliveryType,
  summary,
  accountView,
) => {
  const arrSubRow = [];
  arrSubRow.push(
    createRowsFuturesPosition(
      productSymbol,
      contractSymbol,
      maturityDate,
      deliveryType,
      summary,
      accountView,
    ),
  );
  return arrSubRow;
};

export const createRowsFuturesPosition = (
  productSymbol,
  contractSymbol,
  maturityDate,
  deliveryType,
  mainSummary,
  accountView,
) => ({
  productSymbol,
  contractSymbol,
  deliveryType,
  maturityDate,
  qtyLong: get(mainSummary['long'], ['qty']),
  qtyShort: get(mainSummary['short'], ['qty']),
  subRows: accountView?.map((e) => {
    return {
      productSymbol,
      contractSymbol,
      deliveryType,
      maturityDate,
      account: get(e, ['accountLabel']),
      qtyLong: get(e, 'summary.long.qty'),
      qtyShort: get(e, 'summary.short.qty'),
      subRows: get(e, ['cgmView']).map((cgm) => {
        return {
          productSymbol,
          contractSymbol,
          deliveryType,
          maturityDate,
          account: get(e, ['accountLabel']),
          cgm: get(cgm, ['cgmNumber']),
          qtyLong: get(cgm, 'summary.long.qty'),
          qtyShort: get(cgm, 'summary.short.qty'),
        };
      }),
    };
  }),
});
