export default [
  {
    text: 'cleared',
    key: 'cleared',
    value: 'cleared',
  },
  {
    text: 'pending',
    key: 'pending',
    value: 'pending',
  },
  {
    text: 'pending_buy_side',
    key: 'pending_buy_side',
    value: 'pending_buy_side',
  },
  {
    text: 'pending_sell_side',
    key: 'pending_sell_side',
    value: 'pending_sell_side',
  },
  {
    text: 'pending_sell_side_cx_debit',
    key: 'pending_sell_side_cx_debit',
    value: 'pending_sell_side_cx_debit',
  },
  {
    text: 'pending_buy_side_cx_debit',
    key: 'pending_buy_side_cx_debit',
    value: 'pending_buy_side_cx_debit',
  },
  {
    text: 'pending_sell_side_cx_credit',
    key: 'pending_sell_side_cx_credit',
    value: 'pending_sell_side_cx_credit',
  },
  {
    text: 'pending_buy_side_cx_credit',
    key: 'pending_buy_side_cx_credit',
    value: 'pending_buy_side_cx_credit',
  },
  {
    text: 'ready_for_clearing',
    key: 'ready_for_clearing',
    value: 'ready_for_clearing',
  },
  {
    text: 'failed_manual',
    key: 'failed_manual',
    value: 'failed_manual',
  },
];
