import { connect } from 'react-redux';
import {
  createLoadingSelector,
  filteredArrayToAttrValue,
  sortedArrayToAttrValue,
} from 'erisxkit/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cryptoAddressAnalyses } from '../../actions/surveillanceActions';
import {
  getCryptoAddressAnalyses,
  getCryptoAddressAnalysesCount,
} from '../../reducers/surveillanceReducer';
import Surveillance from '../../components/Surveillance';
import { getAssetTypesAsOptions } from '../../selectors';

const mapStateToProps = (state) => ({
  cryptoAddressAnalysesList: getCryptoAddressAnalyses(state),
  cryptoAddressAnalysesCount: getCryptoAddressAnalysesCount(state),
  cryptoAddressAnalysesLoading: createLoadingSelector([
    'CRYPTO_ADDRESS_ANALYSES',
  ])(state),
  assetTypes: getAssetTypesAsOptions(state),
});

const mapDispatchToProps = {
  cryptoAddressAnalyses,
};

class AddressSurveillanceContainer extends Component {
  fetchData = (state) => {
    let filter = [];
    let sort = [];
    if (state.filtered.length !== 0) {
      filter = filteredArrayToAttrValue(state.filtered);
    }
    if (state.sorted.length !== 0) {
      sort = sortedArrayToAttrValue(state.sorted);
    }
    this.props.cryptoAddressAnalyses({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      filter,
      sort,
    });
  };

  render = () => (
    <Surveillance
      data={this.props.cryptoAddressAnalysesList}
      count={this.props.cryptoAddressAnalysesCount}
      loading={this.props.cryptoAddressAnalysesLoading}
      onFetchData={this.fetchData}
      assetTypes={this.props.assetTypes}
      type="Address"
    />
  );
}

AddressSurveillanceContainer.propTypes = {
  cryptoAddressAnalysesList: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      assetType: PropTypes.string,
      address: PropTypes.string,
      riskScore: PropTypes.string,
      rawData: PropTypes.string,
      updatedAt: PropTypes.string,
      userIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  cryptoAddressAnalysesCount: PropTypes.number.isRequired,
  cryptoAddressAnalysesLoading: PropTypes.bool.isRequired,
  cryptoAddressAnalyses: PropTypes.func.isRequired,
};

AddressSurveillanceContainer.defaultProps = {
  cryptoAddressAnalysesList: [],
  cryptoAddressAnalysesCount: 0,
  cryptoAddressAnalysesLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressSurveillanceContainer);
