import React from 'react';
import { CellProps, Row } from 'react-table';
import get from 'lodash/get';
import { expandColumn } from '../common/table/commonMetadata';
import Actions from '../containers/Members/ParticipantManagement/Action/Actions';
import {
  ParticipantManagementTableRow,
  ParticipantRow,
} from '../ts/models/TradingParticipant/ApprovedParticipant.model';
import { getRowOriginalParentId } from '../common/table/utils';
import BlockTradeCheckbox from '../containers/Members/ParticipantManagement/BlockTradeCheckbox';

const participantManagementMetadata = (
  memberId: string,
  fetch: (params: Record<string, string>) => void,
  firmCode: string,
) => [
  expandColumn,
  {
    id: 'participantName',
    accessor: 'participantName',
    Header: 'Participant',
    minSize: 160,
    enableColumnFilter: true,
  },
  {
    id: 'allowFuturesBlock',
    accessor: 'allowFuturesBlock',
    Header: 'Allow Block Trades',
    width: 190,
    Cell: ({ row }: CellProps<ParticipantManagementTableRow>) =>
      get(row, 'depth') === 0 ? (
        <BlockTradeCheckbox
          participant={row?.original as ParticipantRow}
          selectedMemberFirmCode={firmCode}
          selectedMemberId={memberId}
        />
      ) : null,
  },
  {
    id: 'cgmName',
    accessor: 'cgmName',
    Header: 'CGM',
    minSize: 100,
    enableColumnFilter: true,
  },
  {
    id: 'carName',
    accessor: 'carName',
    Header: 'CAR',
    minSize: 100,
    enableColumnFilter: true,
  },
  {
    id: 'action',
    accessor: 'action',
    Header: 'Action',
    Cell: ({ row, rowsById }: CellProps<ParticipantManagementTableRow>) => {
      // If row is a CGM or a CAR we need to know which
      // participant it belongs to so we get the root row id for <row>
      // and use the table instance to get the original parent row
      const parentId = getRowOriginalParentId(row?.id);
      const parentRow = rowsById[parentId] as Row<ParticipantRow>;
      return (
        <Actions
          fetchData={fetch}
          memberId={memberId}
          rowData={row?.original}
          participant={parentRow?.original}
        />
      );
    },
  },
];

export default participantManagementMetadata;
