import React, { Fragment } from 'react';
import { Modal, Button, Segment } from 'semantic-ui-react';
import XTable7 from '../common/table/XTable7';

const fetchData = () => {};

const ModalBlockTradeRequest = ({ data, hideModal, action, type }) => {
  const arrData = [];
  arrData.push({
    requestId: data.requestId,
    buySide: data.buySide.accountLabel,
    sellSide: data.sellSide.accountLabel,
  });
  return (
    <Fragment>
      <Modal.Header>Blocktrade Request</Modal.Header>
      <Modal.Content>
        <Segment basic className="center">
          <div className="margin-bottom-21">
            <span className="tradesModalHeader">
              {type === 'confirm'
                ? 'Do you wish to confirm this blocktrade? '
                : 'Do you wish to cancel this block trade?'}
            </span>
          </div>
          <XTable7
            metadata={[
              {
                Header: 'Request Id',
                id: 'requestId',
                accessor: 'requestId',
                width: '370',
              },
              {
                Header: 'Buy Side',
                id: 'buySide',
                accessor: 'buySide',
                width: '200',
              },
              {
                Header: 'Sell Side',
                id: 'sellSide',
                accessor: 'sellSide',
                width: '200',
              },
            ]}
            data={arrData || []}
            fetchData={fetchData}
            showHeader={true}
            showFilter={false}
            showPagination={false}
            height="60px"
          />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button id="Cancel" onClick={() => hideModal()}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          color={type === 'confirm' ? 'teal' : 'red'}
          id="confirm"
          onClick={() => action(data, type)}
        >
          {type === 'confirm' ? 'Confirm' : 'Reject'}
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default ModalBlockTradeRequest;
