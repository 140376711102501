import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';
import {
  renderField,
  renderDateTime,
  renderDropdown,
  rules,
} from 'erisxkit/client';
import { Field, reduxForm } from 'redux-form';
import { Form, Modal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { titleCase } from 'change-case';

class CreateCollateralContract extends PureComponent {
  state = { standard: 'cusip' };

  componentDidMount = () => {
    const { cusip, isin, cedol, expirationTime, productSymbol, symbol, type } =
      this.props;

    // initialize to use given product symbol, or all values if given
    this.props.initialize(
      {
        cedol,
        cusip,
        isin,
        expirationTime,
        productSymbol,
        symbol,
        type,
      },
      { keepDirty: true },
    );
  };

  render = () => {
    const {
      confirmAction,
      coupon,
      handleSubmit,
      hideModal,
      pristine,
      type,
      valid,
    } = this.props;

    return (
      <Fragment>
        <Modal.Header>
          {titleCase(type)} Collateral Contract{' '}
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={() => hideModal()}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={handleSubmit(confirmAction)}>
            <Form.Group>
              <Field
                name={this.state.standard}
                id={this.state.standard}
                component={renderField}
                label={
                  <Form.Dropdown
                    defaultValue="cusip"
                    options={[
                      { key: 'cusip', text: 'CUSIP', value: 'cusip' },
                      { key: 'isin', text: 'ISIN', value: 'isin' },
                      { key: 'cedol', text: 'CEDOL', value: 'cedol' },
                    ]}
                    onChange={(e, { value }) =>
                      this.setState({ standard: value })
                    }
                  />
                }
                placeholder="xxxxxxxxx"
              />
            </Form.Group>
            <Field
              component={renderField}
              id="symbol"
              label="Contract Code"
              name="symbol"
              required
              validate={[rules.required]}
            />
            <Field
              component={renderField}
              id="productSymbol"
              name="productSymbol"
              label="Collateral Product Code"
              readOnly
              required
              validate={[rules.required]}
            />
            <Form.Field required>
              <label htmlFor="expiration-time">Maturity Date</label>
              <Field
                name="expirationTime"
                id="expiration-time"
                component={renderDateTime}
                startDate={Date.now()}
                placeholder="MM/DD/YYYY"
                required
              />
            </Form.Field>
            <Field
              name="coupon"
              id="coupon"
              component={renderField}
              label="Coupon"
            />
            <Modal.Actions className="modal-form-actions">
              <Form.Button onClick={() => hideModal()}>Close</Form.Button>
              <Form.Button primary disabled={!valid || pristine}>
                {titleCase(type)} collateral contract
              </Form.Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Fragment>
    );
  };
}

CreateCollateralContract.propTypes = {
  confirmCreateContract: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

CreateCollateralContract.defaultProps = {
  type: 'create',
  valid: false,
};

export default reduxForm({
  form: 'CreateCollateralContract',
})(CreateCollateralContract);
