import _ from 'lodash';
import { createRoutine } from 'redux-saga-routines';

export const LINKED_BANK_ACCOUNTS = 'LINKED_BANK_ACCOUNTS';
export const CREATE_LINKED_BANK_ACCOUNT_ADMIN =
  'CREATE_LINKED_BANK_ACCOUNT_ADMIN';
export const LINKED_CRYPTO_ADDRESSES = 'LINKED_CRYPTO_ADDRESSES';
export const CREATE_LINKED_CRYPTO_ADDRESS = 'CREATE_LINKED_CRYPTO_ADDRESS';

/**
 * Action generator to fetch the linked bank accounts
 */
export const fetchLinkedBankAccounts = createRoutine(LINKED_BANK_ACCOUNTS);
export const createLinkedBankAccountAdmin = createRoutine(
  CREATE_LINKED_BANK_ACCOUNT_ADMIN,
);
export const fetchLinkedCryptoAddresses = createRoutine(
  LINKED_CRYPTO_ADDRESSES,
);
export const createLinkedCryptoAddress = createRoutine(
  CREATE_LINKED_CRYPTO_ADDRESS,
);

//* Reducer */
export default function linkedAccountsReducer(state = {}, action) {
  switch (action.type) {
    case fetchLinkedBankAccounts.SUCCESS:
      return {
        ...state,
        bankAccounts: action.payload,
      };
    case fetchLinkedCryptoAddresses.SUCCESS:
      return {
        ...state,
        cryptoAddresses: action.payload,
      };
    default:
      return state;
  }
}

/* Selectors */
export const getLinkedCryptoAddresses = (state) =>
  _.get(state, ['linkedAccounts', 'cryptoAddresses'], []);
export const getLinkedBankAccounts = (state) =>
  _.chain(state)
    .get(['linkedAccounts', 'bankAccounts'], [])
    .uniqBy((account) => account.hashId)
    .value();
