import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';

import { TABLE_EDIT_ACTIONS } from './constants';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const IconNoOpacity = styled(Icon)`
  opacity: 1 !important;
`;
/**
 * @param {metadata} XTable7 metadata list
 * @returns
 */
export const addEditActions = ({
  metadata,
  onEdit,
  onRemove,
  editActionsToHide = [],
}) => {
  const canUpdate = !editActionsToHide.includes(TABLE_EDIT_ACTIONS.UPDATE);
  const canDelete = !editActionsToHide.includes(TABLE_EDIT_ACTIONS.DELETE);
  metadata.push({
    Header: 'Actions',
    id: 'editActions',
    Cell: ({ row }) => (
      <Wrapper>
        {canUpdate && (
          <IconNoOpacity
            name="edit outline"
            link
            onClick={() => onEdit(row)}
            id={`edit-row-${row?.id}`}
          />
        )}
        {canDelete && (
          <IconNoOpacity
            name="trash alternate outline"
            link
            onClick={() => onRemove(row)}
            id={`delete-row-${row?.id}`}
          />
        )}
      </Wrapper>
    ),
  });

  return metadata;
};

/** Row Utility functions */
export const isEditedRow = (row) => {
  const original = get(row, 'original', {});
  const current = get(row, 'state.original', {});
  return !isEqual(original, current) && get(row, 'state.isEdited', false);
};

export const isCreatedRow = (row) => get(row, 'state.isNew', false);

// TODO: Custom validation callbacks could be passed and props and added here
export const isEditedCellValid = (cell) => {
  // Only newly created rows or rows that have been edited can be invalid
  const isNew = get(cell, 'row.state.isNew', false);
  const isEdited = get(cell, 'row.state.isEdited', false);
  if (isNew || isEdited) {
    const isRequired = get(cell, 'column.required', false);
    // Shortcircuit if cell is not required
    if (!isRequired) return true;
    const cellAttribute = get(cell, 'column.id');
    const value = get(cell, `row.original.${cellAttribute}`);
    // If value is boolean or number then <false> and <0> are valid values
    if (isBoolean(value) || isNumber(value)) return true;
    // Cast value to boolean to see if its valid
    return !!value;
  }

  return true;
};

export const getRowCells = (row) => get(row, 'cells', []);

export const dataHasInvalidRows = (rows) =>
  rows.some((row) => getRowCells(row).some((cell) => !isEditedCellValid(cell)));

export const getFirstInvalidRow = (rows) =>
  rows.find((row) => getRowCells(row).some((cell) => !isEditedCellValid(cell)));

export const getInvalidRows = (rows) =>
  rows.filter((row) =>
    getRowCells(row).some((cell) => !isEditedCellValid(cell)),
  );

export const getInvalidCells = (row) =>
  get(row, 'cells', []).reduce((acc, curr) => {
    if (!isEditedCellValid(curr)) {
      return [...acc, get(curr, 'column.id')];
    }
    return acc;
  }, []);

export const getNewEditedRowsList = (removedRowId, currEditedRows = []) => {
  // If it's editing only one row and that's the row they delete, no rows are being edited
  if (currEditedRows.length === 1 && currEditedRows[0] === removedRowId)
    return [];
  // If 1st row is being edited and was removed, we filter it out. If not, we keep it
  const edited =
    removedRowId === '0'
      ? currEditedRows.filter((id) => id !== '0')
      : currEditedRows;

  // All rows that have a higher ID than the removed one, are to be updated
  return edited.map((id) => {
    if (Number(id) > Number(removedRowId)) {
      return String(Number(id) - 1);
    }
    return id;
  });
};

export const styles = {
  tableSearch: {
    float: 'left',
  },
  tableSettings: {
    float: 'right',
    marginTop: '23px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  headerRow: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  filterCount: {
    color: 'red',
    fontWeight: 'bold',
  },
  resizer: {
    display: 'inline-block',
    background: 'transparent',
    width: '5px',
    height: '100%',
    position: 'absolute',
    right: '0',
    top: '0',
    transform: 'translateX(50%)',
    zIndex: '1',
    touchAction: 'none',
    cursor: 'col-resize',
  },
  generalSearchDropdown: {
    zIndex: 999999,
  },
};

export const isSubRow = (row) => row?.depth > 0;
export const getParentRowId = (rowId) =>
  rowId ? rowId.split('.')?.slice(0, -1)?.join('.') : '';
/**
 * @param {string} rowId - id of row whose parent's row id we want to get
 * @returns  Id of the row that's the highest one in the parent-child hierarchy
 */
export const getRowOriginalParentId = (rowId) => {
  if (!rowId) return '';
  const rowIdHierarchies = rowId?.split('.');
  return rowIdHierarchies?.length > 0 ? rowIdHierarchies[0] : '';
};
