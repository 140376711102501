export default [
  { key: 'NEW', value: 'NEW', text: 'New' },
  { key: 'IN_PROGRESS', value: 'IN_PROGRESS', text: 'In Progress' },
  { key: 'EMAIL_SENT', value: 'EMAIL_SENT', text: 'Email Sent' },
  { key: 'ACKNOWLEDGED', value: 'ACKNOWLEDGED', text: 'Acknowledged' },
  {
    key: 'BANK_TRANSFER_INITIATED',
    value: 'BANK_TRANSFER_INITIATED',
    text: 'Bank Transfer Initiated',
  },
  { key: 'RESOLVED', value: 'RESOLVED', text: 'Resolved' },
  { key: 'REJECTED', value: 'REJECTED', text: 'Rejected' },
];
