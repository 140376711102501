import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import React, { Component } from 'react';
import { Segment, Dropdown } from 'semantic-ui-react';
import {
  createLoadingSelector,
  withFilters,
  inputOnChange,
  filteredArrayToAttrValue,
} from 'erisxkit/client';
import {
  fetchSystemAlerts,
  fetchSystemAlertInfo,
} from '../../actions/systemAlertsActions';
import systemAlertMetadata from '../../metadata/systemAlertsMetadata';
import {
  getSystemAlertsList,
  getSystemAlertsCount,
  getSystemAlertInfo,
} from '../../reducers/systemAlertsReducer';
import ExternalExpandableTable from '../../common/table/ExternalExpandableTable';

const FilteredExpandableTable = withFilters(ExternalExpandableTable);

export const filterFilters = (i, requiredFilters, attr) => {
  const rFilter = find(requiredFilters, { attr });
  if (rFilter.op === 'match') {
    return rFilter.value.includes(i);
  }
  if (rFilter.op === 'ne') {
    return !rFilter.value.includes(i);
  }
};

const createFilters = ({ components, categories }) => [
  {
    placeholder: 'Component',
    component: Dropdown,
    name: 'component',
    id: 'component',
    label: 'Component',
    onChange: inputOnChange,
    options:
      components && components.map((item) => ({ text: item, value: item })),
    multiple: true,
    selection: true,
    search: true,
    selectOnBlur: false,
  },
  {
    placeholder: 'Category',
    label: 'Category',
    component: Dropdown,
    name: 'category',
    id: 'category',
    onChange: inputOnChange,
    options:
      categories && categories.map((item) => ({ text: item, value: item })),
    multiple: true,
    selection: true,
    search: true,
    selectOnBlur: false,
  },
];

const mapStateToProps = (state) => ({
  systemAlerts: getSystemAlertsList(state),
  count: getSystemAlertsCount(state),
  systemAlertInfo: getSystemAlertInfo(state),
  loading: createLoadingSelector(['SYSTEM_ALERTS'])(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSystemAlerts,
      fetchSystemAlertInfo,
    },
    dispatch,
  );

const SubComponent = (row) => (
  <Segment inverted color="red" tertiary>
    {row.original.message}
  </Segment>
);

class SystemAlertsContainer extends Component {
  componentDidMount() {
    this.props.fetchSystemAlertInfo();
  }

  fetchData = (state) => {
    let filter = this.props.requiredFilters || [];
    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }
    this.props.fetchSystemAlerts({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
    });
  };

  render = () => {
    const {
      systemAlerts,
      count,
      systemAlertInfo = {},
      loading,
      requiredFilters = [],
    } = this.props;
    // when we do have required filters, we need to limit the filtering capability to only include the required filters.
    if (requiredFilters.length) {
      systemAlertInfo.components = get(
        systemAlertInfo,
        'components',
        [],
      ).filter((i) => filterFilters(i, requiredFilters, 'component'));
      systemAlertInfo.categories = get(
        systemAlertInfo,
        'categories',
        [],
      ).filter((i) => filterFilters(i, requiredFilters, 'category'));
    }

    const filters = createFilters(systemAlertInfo);
    return (
      <FilteredExpandableTable
        filters={filters}
        title="systemAlerts"
        className="-striped"
        data={systemAlerts}
        metadata={systemAlertMetadata}
        onFetchData={this.fetchData}
        count={count}
        loading={loading}
        viewRowDetails={this.viewRowDetails}
        SubComponent={SubComponent}
        noDataText="No System Alerts were found."
        multipleExpandable
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemAlertsContainer);
