import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { XTable, createLoadingSelector } from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { positions } from '../../actions/accountsActions';
import { getPositions } from '../../reducers/balancesReducer';
import {
  getCollateralContractsAsSymbols,
  collateralContractsPromiseCreator,
  createCollateralWithdrawalJournal,
} from '../../reducers/collateralReducer';
import { COLLATERAL_CONTRACTS, POSITIONS } from '../../constants/actionTypes';
import { getSelectedAccountIdBySlice } from '../../selectors';
import { showModal, hideModal } from 'erisxkit/client';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../../constants/modalTypes';
import {
  mainComponentMetadata,
  subComponentMetadata,
} from '../../metadata/collateralPositionsMetadata';

const mapStateToProps = (state) => ({
  collateralContractsSymbols: getCollateralContractsAsSymbols(state),
  collateralContractsLoading: createLoadingSelector([COLLATERAL_CONTRACTS])(
    state,
  ),
  positionsList: getPositions(state),
  positionsLoading: createLoadingSelector([POSITIONS])(state),
  selectedAccountId: getSelectedAccountIdBySlice('accounts')(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      collateralContractsPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      createCollateralWithdrawalJournal,
      hideModal,
      positions,
      showModal,
    },
    dispatch,
  ),
});

class CollateralPositionsContainer extends Component {
  componentDidMount = () => {
    this.props.collateralContractsPromiseCreator().then(
      () => {
        this.props.positions({
          accountId: this.props.selectedAccountId,
          contractSymbols: this.props.collateralContractsSymbols,
        });
      },
      () => console.error('Failed to fetch contracts'),
    );
  };

  createCollateralWithdrawalJournal = ({ positionId, qty }) => {
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      action: 'Withdraw collateral',
      closeOnDimmerClick: true,
      closeOnEscape: true,
      header: 'Confirm Collateral Withdrawal',
      hideModal: this.props.hideModal,
      msg: (
        <span>
          Withdraw the following piece of collateral from account{' '}
          <span className="mono">{this.props.selectedAccountId}</span>?
        </span>
      ),
      onConfirm: () => {
        this.props.createCollateralWithdrawalJournal({
          accountPosition: positionId,
          qty,
        });
        this.props.hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
      },
      payload: { positionId, qty },
    });
  };

  render = () => (
    <Fragment>
      <Header as="h2">
        Collateral Positions
        <Header.Subheader content="* not affected by report date selection." />
      </Header>
      <XTable
        title="collateral-positions"
        loading={
          this.props.positionsLoading || this.props.collateralContractsLoading
        }
        data={this.props.positionsList}
        columns={mainComponentMetadata}
        pageSize="6"
        SubComponent={({ row }) => (
          <XTable
            className="subtable no-stripe"
            title="collateral-positions-subcomponent"
            data={get(row, ['_original', 'positions'], [])}
            showPagination={false}
            pageSize={get(row, ['_original', 'positions'], []).length}
            columns={subComponentMetadata(
              this.createCollateralWithdrawalJournal,
            )}
          />
        )}
      />
    </Fragment>
  );
}

CollateralPositionsContainer.propTypes = {
  collateralContractsPromiseCreator: PropTypes.func.isRequired,
  collateralContractsLoading: PropTypes.bool,
  collateralContractsSymbols: PropTypes.arrayOf(PropTypes.string).isRequired,
  createCollateralWithdrawalJournal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  positions: PropTypes.func.isRequired,
  positionsList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      positions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          qty: PropTypes.string,
          px: PropTypes.string,
        }),
      ),
    }),
  ),
  positionsLoading: PropTypes.bool,
  selectedAccount: PropTypes.objectOf(PropTypes.any),
  selectedAccountId: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
};

CollateralPositionsContainer.defaultProps = {
  collateralContractsLoading: false,
  positionsList: [],
  positionsLoading: false,
  selectedAccount: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollateralPositionsContainer);
