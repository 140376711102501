import _ from 'lodash';
import { createSelector } from 'reselect';
import { getAccountIdFromURL } from '../utils/methods';
import { getFuturesProducts } from '../reducers/futuresProductReducer';
import { getFuturesContracts } from '../reducers/contractsReducer';
import { getSubExchanges } from '../reducers/subExchangesReducer';

// returns the logged in user
export const getLoggedInUser = (state) => _.get(state, 'loggedInUser', {});

export const getActiveRoute = (state) =>
  _.get(state, ['router', 'location'], {});
// get all asset types from state
export const getAssetTypes = (state) =>
  _.get(state, ['assetTypesReducer', 'assetTypes'], []);

export const getCryptoAssetTypes = (state) =>
  Object.values(getAssetTypes(state)).filter((type) => !type.isFiat);
export const getFiatAssetTypes = (state) =>
  Object.values(getAssetTypes(state)).filter((type) => type.isFiat);

// get if an asset type is fiat.
export const getIsFiatAssetType = (assetType) => (state) => {
  const allAssetTypes = _.get(state, ['assetTypesReducer', 'assetTypes'], []);
  return _.get(
    _.find(
      allAssetTypes,
      (type) => type.symbol.toUpperCase() === assetType.toUpperCase(),
    ),
    'isFiat',
    false,
  );
};

// provide a function for getting asset type by symbol.
export const getAssetTypeSelector = createSelector(
  [getAssetTypes],
  (assetTypes) => (symbol) => assetTypes.find((t) => t.symbol === symbol),
);

// getSelectedAccount for any slice of the state
export const getSelectedAccountIdBySlice = (slice) => (state) => {
  const urlAccountId = getAccountIdFromURL();
  const stateAccountId = _.get(state, [slice, 'selectedAccountId'], '');
  return urlAccountId || stateAccountId;
};

// format asset types as an array of options
export const getAssetTypesAsOptions = (state) =>
  getAssetTypes(state).map((asset) => ({
    key: asset.symbol,
    value: asset.symbol,
    text: asset.symbol,
    description: asset.description,
    isfiat: asset.isFiat ? 'true' : undefined, // custom prop to store isFiat bool
  }));

export const getSubExchangesAsOptions = (state) =>
  getSubExchanges(state).map((subExchange) => ({
    key: subExchange.subExchangeId,
    value: subExchange.subExchangeId,
    text: subExchange.name,
  }));

// format only crypto types as an array of options
export const getCryptoAssetTypesAsOptions = (state) =>
  getCryptoAssetTypes(state).map((asset) => ({
    key: asset.symbol,
    value: asset.symbol,
    text: asset.description,
    description: asset.symbol,
  }));

// format only fiat as an array of options
export const getFiatAssetTypesAsOptions = (state) =>
  getFiatAssetTypes(state).map((asset) => ({
    key: asset.symbol,
    value: asset.symbol,
    text: asset.description,
    description: asset.symbol,
  }));

// get any selector mapped to arbitrary options object
export const getSelectorAsOptions = (selector, option) => (state) =>
  _.map(selector(state), (o) => {
    if (!_.isObjectLike(o)) {
      return { key: o, value: o, text: o };
    }

    // for each item from selector, map options. fall back to given string.
    // if we get a function, pass the given value and handle it there.
    return _.mapValues(option, (opt) => {
      if (typeof opt !== 'function') {
        return _.get(o, [opt], opt);
      }
      return opt(o);
    });
  });

// get futures contracts with product asset types included. specifically for manual trade entry
export const getFuturesContractsWithProductAssetTypes = createSelector(
  [getFuturesContracts, getFuturesProducts],
  (contracts = [], products = []) =>
    contracts.map((contract) => {
      const baseProduct = products.filter(
        (product) => contract.productSymbol === product.symbol,
      )[0];
      if (!_.isEmpty(baseProduct)) {
        const { baseAssetType, quotedAssetType } = baseProduct;
        return Object.assign(contract, { baseAssetType, quotedAssetType });
      }
      return contract;
    }),
);
