import Big from 'bignumber.js';

const REPORTABLE_THRESHOLD = 50;

export default (row = {}) => {
  const errors = [];
  const {
    long,
    priorLong = 0,
    short,
    priorShort = 0,
    positionEodLong,
    priorPositionEodLong = 0,
    positionEodShort,
    priorPositionEodShort = 0,
    transactionQtyLong,
    transactionBlockQtyLong,
    transactionBlockQtyShort,
    transactionEfpQtyLong,
    transactionEfpQtyShort,
    transactionQtyShort,
  } = row;
  const changeLong = Big(long).minus(Big(priorLong)).toFixed();
  const changeShort = Big(short).minus(Big(priorShort)).toFixed();
  const changePositionEodLong = Big(positionEodLong)
    .minus(Big(priorPositionEodLong))
    .toFixed();
  const changePositionEodShort = Big(positionEodShort)
    .minus(Big(priorPositionEodShort))
    .toFixed();

  const totalLongTrades = Big(transactionQtyLong)
    .plus(Big(transactionBlockQtyLong))
    .plus(Big(transactionEfpQtyLong))
    .toFixed();
  const totalShortTrades = Big(transactionQtyShort)
    .plus(Big(transactionBlockQtyShort))
    .plus(Big(transactionEfpQtyShort))
    .toFixed();

  if (
    long > positionEodLong ||
    short > positionEodShort ||
    changeLong > changePositionEodLong ||
    changeShort > changePositionEodShort
  ) {
    errors.push('Indication of potential misreported or overstated LTR');
  }

  if (short === long) {
    errors.push('Indication of potential misreported LTR');
  }

  if (changePositionEodLong > 0) {
    if (changePositionEodLong > totalLongTrades) {
      errors.push('Indication of potential misreported or overstated OI');
    }
  } else if (changePositionEodLong > totalShortTrades) {
    errors.push('Indication of potential misreported or overstated OI');
  }

  if (changePositionEodShort > 0) {
    if (changePositionEodShort > totalShortTrades) {
      errors.push('Indication of potential misreported or overstated OI');
    }
  } else if (changePositionEodShort > totalLongTrades) {
    errors.push('Indication of potential misreported or overstated OI');
  }

  if (positionEodLong === positionEodShort) {
    errors.push('Indication of potential misreported OI');
  }

  if (
    (positionEodLong > REPORTABLE_THRESHOLD && !long) ||
    (positionEodShort > REPORTABLE_THRESHOLD && !short)
  ) {
    errors.push('Indication of potential misreported or under reported LTR');
  }
  return errors;
};
