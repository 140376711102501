import React, { Fragment } from 'react';
import { Modal, Header, Button, Divider, Segment } from 'semantic-ui-react';
import XTable7 from '../../common/table/XTable7';

const fetchData = () => {};
const ModalRiskAlertStatus = ({
  hideModal,
  alertId,
  accountNumber,
  to,
  from,
  updateStatus,
}) => {
  const statusChangeData = [
    {
      alertId: alertId,
      accountNumber: accountNumber,
      from: from.text,
      to: to.text,
    },
  ];

  return (
    <Fragment>
      <Modal.Content>
        <Header as="h3">Alert Status Change</Header>
        <Divider clearing />
        <Segment basic textAlign="center">
          <Header.Subheader style={{ fontSize: '18px', marginBottom: '14px' }}>
            You are changing the status of the following alert
          </Header.Subheader>
          <div style={{ width: '43em', margin: 'auto' }}>
            <XTable7
              metadata={[
                {
                  Header: 'Alert Id',
                  id: 'alertId',
                  accessor: 'alertId',
                },
                {
                  Header: 'Account',
                  id: 'accountNumber',
                  accessor: 'accountNumber',
                },
                {
                  Header: 'From',
                  id: 'from',
                  accessor: 'from',
                },
                {
                  Header: 'To',
                  id: 'to',
                  accessor: 'to',
                },
              ]}
              data={statusChangeData || []}
              fetchData={fetchData}
              showHeader={true}
              showFilter={false}
              showPagination={false}
            />
          </div>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button id="Cancel" onClick={() => hideModal()}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          color="teal"
          id="confirm"
          onClick={() => updateStatus(alertId, to)}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default ModalRiskAlertStatus;
