import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'UUID',
    id: 'uuid',
    accessor: 'uuid',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'DFRR Group Name',
    id: 'dfrrGroupName',
    accessor: 'dfrrGroupName',
    editInputType: TABLE_INPUT_TYPES.STRING,
    required: true,
    minWidth: 340,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
