import React, { Fragment, useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import { isArray } from 'lodash';
import XTable7 from '../../common/table/XTable7';
import metadata from '../../metadata/settlementServiceMetadata';
import { CONFIRM_REJECT_SETTLEMENT } from '../../constants/modalTypes';
import { SETTLEMENT_INSTRUCTIONS } from '../../constants/actionTypes';
import {
  showModal,
  hideModal,
  createLoadingSelector,
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import { useSelector, connect, useDispatch } from 'react-redux';
import {
  getSettlementInstructions,
  fetchSettlementInstructions,
  confirmSettlement,
  rejectSettlement,
} from '../../reducers/settlementReducer';
import moment from 'moment';

const SettlementServiceContainer = () => {
  const dispatch = useDispatch();
  const settlementInstructions = useSelector(getSettlementInstructions);
  const loading = useSelector(createLoadingSelector([SETTLEMENT_INSTRUCTIONS]));

  const parseEpoch = (epochValue) => {
    if (!epochValue) return;
    return epochValue.toString().length > 10 ? epochValue / 1000 : epochValue;
  };

  const approveReject = (data, action, selectedAccount = '') => {
    if (action === 'approve') {
      dispatch(
        confirmSettlement({
          settlementRequestId: data.id,
          account: selectedAccount,
        }),
      );
    }
    if (action === 'reject') {
      dispatch(
        rejectSettlement({
          settlementRequestId: data.id,
        }),
      );
    }

    dispatch(hideModal());
  };

  const approveRejectModal = (data, action) => {
    dispatch(
      showModal(CONFIRM_REJECT_SETTLEMENT, {
        type: 'clearing',
        data: data,
        action: action,
        cancel: () => dispatch(hideModal()),
        confirmAction: (selectedAccount) =>
          approveReject(data, action, selectedAccount),
      }),
    );
  };

  const fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    let sort = [
      {
        attr: 'time',
        value: 'desc',
      },
    ];
    let filter = [];

    if (filters && filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrValue(
          filters.map((each) => {
            return {
              ...each,
              op: 'eq',
            };
          }),
        ),
      );
    }

    const filterWithMaxMin = filter.reduce((acc, curr) => {
      if (isArray(curr.value)) {
        return acc.concat([
          ...(curr.value[0]
            ? [
                {
                  ...curr,
                  op: 'gte',
                  value: `${moment.unix(parseEpoch(curr.value[0]))}`,
                },
              ]
            : []),
          ...(curr.value[1]
            ? [
                {
                  ...curr,
                  op: 'lte',
                  value: `${moment.unix(parseEpoch(curr.value[1]))}`,
                },
              ]
            : []),
        ]);
      }
      return acc.concat([curr]);
    }, []);

    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }

    dispatch(
      fetchSettlementInstructions({
        limit: pageSize,
        offset: pageIndex * pageSize,
        page: pageIndex,
        filter: filterWithMaxMin,
        sort,
      }),
    );
  };

  const noEmptyObj = Object.keys(settlementInstructions).length > 0;

  return (
    <Fragment>
      <Header as="h1">
        <Header.Content>Settlement Service</Header.Content>
      </Header>
      <XTable7
        title="settlement-service"
        data={noEmptyObj ? settlementInstructions : []}
        metadata={metadata(approveRejectModal)}
        fetchData={fetchData}
        loading={loading}
        count={noEmptyObj ? settlementInstructions.length : []}
        pageTableSize={20}
      />
    </Fragment>
  );
};

export default SettlementServiceContainer;
