import { GridState, StateUpdatedEvent } from "ag-grid-community";
import { GridName } from "../../../components/agGrid/SystemApplications/SystemApplicationsGrid";
import { setGridSettings, useAgLocalStorage } from "./useAgLocalStorage";
import { useCallback, useEffect, useState } from "react";

export const useAgGridCommon = (isJest?: boolean) => {
  const [gridVisible, setGridVisible] = useState(true);
  const initialGridSettings: GridState = useAgLocalStorage(GridName);

  // Used to force a re-render if initialGridSettings has changed, b/c AgGridReact
  // initialState prop is only read on initialization
  const reloadGrid = useCallback(() => {
    setGridVisible(false);
    setTimeout(() => {
      setGridVisible(true);
    });
  }, []);

  useEffect(() => {
    reloadGrid();
  }, [initialGridSettings, reloadGrid]);

  const onStateUpdated = (event: StateUpdatedEvent) => {
    setGridSettings(GridName, event.state);
  };

  return {
    gridVisible: gridVisible || isJest,
    initialGridSettings,
    onStateUpdated,
  };
};
