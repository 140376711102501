import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, Button, Header, Tab } from 'semantic-ui-react';
import { showModal, hideModal } from 'erisxkit/client';
import isEmpty from 'lodash/isEmpty';
import JSONTree from 'react-json-tree';

const getPanes = ({ create, update, remove }) => {
  let panes = [];
  if (!isEmpty(create))
    panes.push({
      menuItem: 'Create',
      render: () => (
        <Tab.Pane attached={false}>
          <JSONTree data={{ create }} hideRoot shouldExpandNode={() => true} />
        </Tab.Pane>
      ),
    });

  if (!isEmpty(update))
    panes.push({
      menuItem: 'Update',
      render: () => (
        <Tab.Pane attached={false}>
          <JSONTree data={{ update }} hideRoot shouldExpandNode={() => true} />
        </Tab.Pane>
      ),
    });

  if (!isEmpty(remove))
    panes.push({
      menuItem: 'Delete',
      render: () => (
        <Tab.Pane attached={false}>
          <JSONTree data={{ remove }} hideRoot shouldExpandNode={() => true} />
        </Tab.Pane>
      ),
    });

  return panes;
};

const ReviewEditsModal = ({ create, update, remove, onConfirm }) => {
  const dispatch = useDispatch();
  const panes = getPanes({ create, update, remove });
  const closeModal = () => dispatch(hideModal());

  return (
    <>
      <Header icon="check circle" content="Review Changes" />
      <Modal.Content scrolling>
        <Header>
          <Header.Content>Review changes</Header.Content>
          <Header.Subheader>
            Go through the changes made and validate them.
          </Header.Subheader>
        </Header>

        <Header>
          <Header.Content>Summary:</Header.Content>
          <Header.Subheader>{`- Creations: ${create?.length || 0}`}</Header.Subheader>
          <Header.Subheader>{`- Updates: ${update?.length || 0}`}</Header.Subheader>
          <Header.Subheader>{`- Deletions: ${remove?.length || 0}`}</Header.Subheader>
        </Header>
        <JSONTree data={{ create, update, remove }} hideRoot />

        {/* <Tab menu={{ pointing: true }} panes={panes} /> */}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            onConfirm();
            closeModal();
          }}
          icon="save outline"
          content="Apply Changes"
        />
        <Button onClick={closeModal} content="Close" />
      </Modal.Actions>
    </>
  );
};

ReviewEditsModal.defaultProps = {
  create: [],
  update: [],
  remove: [],
  onConfirm: null,
};

ReviewEditsModal.propTypes = {
  create: PropTypes.arrayOf(PropTypes.object),
  update: PropTypes.arrayOf(PropTypes.object),
  remove: PropTypes.arrayOf(PropTypes.object),
  onConfirm: PropTypes.func,
};

export default ReviewEditsModal;
