import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'UUID',
    id: 'uuid',
    accessor: 'uuid',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Parameter',
    id: 'parameter',
    accessor: 'parameter',
    editInputType: TABLE_INPUT_TYPES.STRING,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Value',
    id: 'value',
    accessor: 'value',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 150,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
