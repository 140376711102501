import { formatTimeLocal } from '../utils/time';
import { formatUSD } from '../utils/methods';

const initialMarginRequirementsMetadata = () => [
  {
    Header: 'Time',
    id: 'time',
    accessor: (row) => formatTimeLocal(row.time),
    filterable: false,
  },
  {
    Header: 'Trade Date',
    id: 'tradeDate',
    accessor: (row) => formatTimeLocal(row.tradeDate),
    filterable: false,
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: 'type',
  },
  {
    Header: 'Firm',
    id: 'firmCode',
    accessor: 'firmCode',
  },
  {
    Header: 'Account',
    id: 'accountCode',
    accessor: 'accountCode',
  },
  {
    Header: 'Account Type',
    id: 'accountType',
    accessor: 'accountType',
  },
  {
    Header: 'Origin',
    id: 'origin',
    accessor: 'origin',
  },
  {
    Header: 'Applied Requirement',
    id: 'overrideMarginRequirement',
    accessor: (row) => formatUSD(row.marginRequirement),
  },
  {
    Header: 'Comment',
    id: 'overrideComment',
    accessor: 'overrideComment',
  },
  {
    Header: 'Span Margin',
    id: 'spanMarginRequirement',
    accessor: (row) => formatUSD(row?.spanMarginRequirement),
  },
  {
    Header: 'Span Margin Override',
    id: 'spanMarginRequirementOverride',
    accessor: (row) => formatUSD(row?.spanMarginRequirementOverride),
  },
  {
    Header: 'Guaranty Fund',
    id: 'guarantyFund',
    accessor: (row) => formatUSD(row?.guarantyFund),
  },
  {
    Header: 'Guaranty Fund Override',
    id: 'guarantyFundOverride',
    accessor: (row) => formatUSD(row?.guarantyFundOverride),
  },
  {
    Header: 'Concentration Add On',
    id: 'concentrationAddOn',
    accessor: (row) => formatUSD(row?.concentrationAddOn),
  },
  {
    Header: 'Concentration Add On Override',
    id: 'concentrationAddOnOverride',
    accessor: (row) => formatUSD(row?.concentrationAddOnOverride),
  },
  {
    Header: 'Stress Add On',
    id: 'stressAddOn',
    accessor: (row) => formatUSD(row?.stressAddOn),
  },
  {
    Header: 'Stress Add On Override',
    id: 'stressAddOnOverride',
    accessor: (row) => formatUSD(row?.stressAddOnOverride),
  },
  {
    Header: 'Cover 2 Add On',
    id: 'cover2AddOn',
    accessor: (row) => formatUSD(row?.cover_2AddOn),
  },
  {
    Header: 'Cover 2 Add On Override',
    id: 'cover2AddOnOverride',
    accessor: (row) => formatUSD(row?.cover_2AddOnOverride),
  },
  {
    Header: 'Discretionary Add On',
    id: 'discretionaryAddOn',
    accessor: (row) => formatUSD(row?.discretionaryAddOn),
  },
  {
    Header: 'Discretionary Add On Override',
    id: 'discretionaryAddOnOverride',
    accessor: (row) => formatUSD(row?.discretionaryAddOnOverride),
  },
];
export default initialMarginRequirementsMetadata;
