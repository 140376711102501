export default [
  {
    key: 'physical',
    text: 'Physical',
    value: 'physical',
  },
  {
    key: 'bounded',
    text: 'Bounded',
    value: 'bounded',
  },
  {
    key: 'margin_physical',
    text: 'Margin Physical',
    value: 'margin_physical',
  },
  {
    key: 'margin_financial',
    text: 'Margin Financial',
    value: 'margin_financial',
  },
  {
    key: 'margin_continuous',
    text: 'Margin Continuous',
    value: 'margin_continuous'
  }
];
