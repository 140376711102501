import _ from 'lodash';
import React, { Fragment } from 'react';
import {
  Modal,
  Button,
  Icon,
  Header,
  Form,
  Popup,
  Segment,
  Table,
  Select,
  Statistic,
  Message,
  Card,
} from 'semantic-ui-react';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import {
  transactionTypeOptions,
  internalTransferTypeOptions,
} from './ReconciliationFilters';

const ReconciliationModal = ({
  transaction,
  onApply,
  onClose,
  onPopupClose,
  onPopupOpen,
  popupIsOpen,
  handleChange,
  applyToAccountId,
  loading,
  disabled,
  type,
  selectedAccount,
  applyToAccountIdLabel,
  isFiatAssetType,
}) => {
  const confirmation = (
    <div>
      <h4> Are you sure? </h4>
      <Button.Group size="mini">
        <Button color="red" onClick={onPopupClose}>
          No
        </Button>
        <Button color="green" onClick={onApply}>
          Yes
        </Button>
      </Button.Group>
    </div>
  );

  const trigger = (
    <Button color="green" disabled={!applyToAccountId}>
      <Icon name="checkmark" /> Post
    </Button>
  );

  const tableData = (row) => [
    { title: 'Time', value: row.txTime || row.journalTime },
    { title: 'Amount', value: `${row.amount.toString()}` },
    { title: 'Fee', value: `${_.get(row, 'fee', '').toString()}` },
    { title: 'Transaction Id', value: transaction.txId },
  ];

  const renderBodyRow = ({ title, value }, i) => ({
    key: title || `row-${i}`,
    cells: [title, value ? { key: 'value', content: value } : 'Unknown'],
  });

  const headerRow = [{ content: 'Transaction Details', colSpan: 2 }];

  const table = (
    <Table
      celled
      striped
      renderBodyRow={renderBodyRow}
      tableData={tableData(transaction)}
      headerRow={headerRow}
    />
  );

  //
  //* two types of transactions that allow changing transaction type:
  //* first, external unreconciled entries on BMO reconciliations
  //* or, external unreconciled "internal" entries (like we see between crypto wallets)
  // refs PR-617
  //
  const allowChangingTransactionType = () =>
    isInternalTransfer() || isFiatExternalEntry();
  const isInternalTransfer = () =>
    transaction.state === 'external' && transaction.type === 'internal';
  const isFiatExternalEntry = () =>
    transaction.state !== 'internal' &&
    transaction.type !== 'internal' &&
    isFiatAssetType;
  const showChangingTransactionTypeWarning = () =>
    !isInternalTransfer() && type !== transaction.type; // Do not show warning for internal transfer entries

  //
  // If transaction type is 'internal' (internal wallet transfer) we will allow
  // the user to change the account - e.g. to post the transfer as a deposit and
  // withdrawal against a transfer account, rather than as a InternalTransfer
  // directly between the two wallet accounts (which is what the applyToAccountId
  // wants to do).
  // refs PR-617
  //
  // Later, we might consider removing InternalTransfer altogether.
  //
  const allowAccountSelection = () => !disabled || isInternalTransfer(); // this can override the "disabled" prop

  const getInternalTransferTypeOptions = () =>
    // If amount is positive, this can only be a Deposit or an InternalTransfer.
    // If amount is negative, this can only be a Withdrawal or an InternalTransfer.
    // If amount is zero (weird edge case), this can be anything.
    transactionTypeOptions
      .filter(
        (o) =>
          (transaction.amount <= 0 && o.value === 'withdrawal') ||
          (transaction.amount >= 0 && o.value === 'deposit'),
      )
      .concat(internalTransferTypeOptions);

  const getTransactionTypeOptions = () =>
    isInternalTransfer()
      ? getInternalTransferTypeOptions()
      : transactionTypeOptions;

  const modalContent = () => (
    <Fragment>
      <div className="tableContainer">{table}</div>
      <Form>
        {allowChangingTransactionType() && (
          <Form.Field
            control={Select}
            label="Select the type of the transaction to be posted: "
            name="type"
            options={getTransactionTypeOptions()}
            fluid
            search
            selection
            onChange={handleChange}
            defaultValue={type}
          />
        )}
        <Message warning visible={showChangingTransactionTypeWarning()}>
          <Icon name="exclamation circle" />
          Changing the type of a transaction being posted may result in a
          reconciliation break.
        </Message>
        <AccountSelectionContainer
          loading={loading}
          required
          placeholder="Select An Account"
          label="Please select an account to post this transaction to:"
          name="applyToAccountId"
          fluid
          search
          selection
          onChange={handleChange}
          disabled={!allowAccountSelection()}
          defaultValue={applyToAccountId}
        />
      </Form>
      <Segment className="text-center">
        <Statistic className="flex" size="medium">
          <Statistic.Value>
            {Math.abs(transaction.amount.toFixed())}
          </Statistic.Value>
          <Statistic.Label className="text-left">{`${type} Amount`}</Statistic.Label>
        </Statistic>
        {transaction.state !== 'internal' && transaction.type !== 'internal' ? (
          <Card.Group centered>
            <Card>
              <Card.Content header={selectedAccount.label} />
              <Card.Content
                description={
                  <Statistic
                    className="flex"
                    size="small"
                    label={
                      type === 'deposit'
                        ? Math.abs(transaction.amount.toFixed()) * -1
                        : Math.abs(transaction.amount.toFixed())
                    }
                  />
                }
              />
            </Card>
            <Card>
              <Card.Content
                header={
                  applyToAccountIdLabel ||
                  applyToAccountId ||
                  'Please Select An Account'
                }
              />
              <Card.Content
                description={
                  <Statistic
                    className="flex"
                    size="small"
                    label={
                      type === 'withdrawal'
                        ? Math.abs(transaction.amount.toFixed()) * -1
                        : Math.abs(transaction.amount.toFixed())
                    }
                  />
                }
              />
            </Card>
          </Card.Group>
        ) : transaction.type === 'internal' ? (
          <div>
            {' '}
            Creating an Internal Transfer to account: {applyToAccountId}{' '}
          </div>
        ) : (
          <div> Creating a Reversal to account: {applyToAccountId} </div>
        )}
      </Segment>
    </Fragment>
  );

  return (
    <Fragment>
      <Header icon="exchange" content="Reconcile Transaction" />
      <Modal.Content>{modalContent()}</Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>
          <Icon name="remove" /> Cancel
        </Button>
        <Popup
          open={popupIsOpen}
          onOpen={onPopupOpen}
          position="bottom center"
          trigger={trigger}
          content={confirmation}
          on="click"
        />
      </Modal.Actions>
    </Fragment>
  );
};

export default ReconciliationModal;
