import React, { Fragment, useState } from 'react';
import { Tab, Header, Icon } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import XTab from '../../common/tabs/XTab';
import MarginProfileMultipliers from './MarginProfileMultipliers';
import BaseMarginRates from './BaseMarginRates';
import AppliedMargin from './AppliedMargin';
import {
  updateMarginRateInfoDate,
  getMarginRateInfoDate,
} from '../../reducers/marginRatesReducer';
import { formatDateLocal } from '../../utils/time';
import IconDatePicker from '../../components/IconDatePicker';
import moment from 'moment';
import Moment from 'moment-timezone';

const MarginRatesContainer = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const tradeDate = useSelector(getMarginRateInfoDate);

  React.useEffect(() => {
    dispatch(updateMarginRateInfoDate(date));
  }, [date]);

  React.useEffect(() => {
    const currentDate = Moment().format('YYYY-MM-DD');
    dispatch(updateMarginRateInfoDate(currentDate));
  }, []);

  const panes = [
    {
      url: 'marginRates',
      menuItem: 'Margin Rates',
      className: 'marginRates',
      render: () => (
        <Tab.Pane attached={false}>
          <BaseMarginRates />
        </Tab.Pane>
      ),
    },
    {
      url: 'marginProfileMultipliers',
      menuItem: 'Margin Profile Multipliers',
      className: 'marginProfileMultipliers',
      render: () => (
        <Tab.Pane attached={false}>
          <MarginProfileMultipliers />
        </Tab.Pane>
      ),
    },
    {
      url: 'margin',
      menuItem: 'Calculated Margin',
      className: 'margin',
      render: () => (
        <Tab.Pane attached={false}>
          <AppliedMargin />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Fragment>
      <Header as="h1" dividing>
        Margin Rates
      </Header>
      <div
        style={{
          width: '99%',
          marginBottom: '15px',
          marginTop: '15px',
          textAlign: 'right',
        }}
      >
        <p>
          {formatDateLocal(tradeDate)}
          <IconDatePicker
            style={{ display: 'inline-block' }}
            onChange={(e) => setDate(moment(e._d).format('YYYY-MM-DD'))}
          ></IconDatePicker>
        </p>
      </div>
      <XTab panes={panes} />
    </Fragment>
  );
};

export default MarginRatesContainer;
