import { get } from 'lodash';
import React, { Fragment } from 'react';
import { Form, Input, Button, Segment, Header } from 'semantic-ui-react';
import { tradingApplications } from 'erisxkit/client';
import history from '../../constants/history';
import { getUserType } from '../../containers/MemberUsers/MemberUserSettings';
import * as userTypes from '../../constants/userTypes';
import { KYC, RESUBMIT_KYC } from '../../constants/userStates';
import Permissions from '../../containers/MemberUsers/Permissions';

const supportedTradingAppNames = ['erisx', 'cqg'];
const supportedTradingApplications = tradingApplications.filter((app) =>
  supportedTradingAppNames.includes(app.value),
);

const MemberSettings = ({
  apiCredentialsPermissions = [],
  user,
  handleChange,
  saveUserDetails,
  saveDisabled,
  update = {},
  userStates,
  appStates,
  confirmPassword,
  employeeRoles,
  routeSubmittingOrdersToApi,
  userPermissionsStructure,
  memberUserTypes,
  resetMf,
  apiPermissionsStructure,
}) => {
  return (
    <Segment.Group>
      <Segment.Group horizontal>
        <Segment className="user-settings-segment">
          <Form>
            <Header as="h2" content="General" />
            {getUserType(user) === userTypes.memberUser && (
              <Fragment>
                <Header as="h3"> Manual Onboarding </Header>
                <Form.Button
                  onClick={() => {
                    history.push(
                      `/member_users/${user.userId}/settings/onboard`,
                    );
                  }}
                >
                  Onboard
                </Form.Button>
              </Fragment>
            )}
            <Fragment>
              {getUserType(user) === userTypes.employee && (
                <Segment>
                  <Header
                    content="Elevate This Admin"
                    subheader="Before elevating an admin, make sure that this admin has set an Admin Key using the profile page.  Elevating an admin allows this admin to perform functions on Members that require 'Funding
              Password' (eg. Resetting a Member funding password, adding linked bank accounts etc...)"
                    as="h3"
                  />
                  <Button positive name="elevate" onClick={confirmPassword}>
                    {' '}
                    Elevate This Admin{' '}
                  </Button>

                  <Header as="h3"> Admin Key</Header>
                  <Form.Button
                    color="red"
                    name="reset"
                    onClick={confirmPassword}
                  >
                    Reset Admin Key
                  </Form.Button>
                </Segment>
              )}
            </Fragment>
            <Form.Field>
              <label>Email</label>
              <Input
                name="email"
                defaultValue={user.email}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Auth Id</label>
              <Input
                name="authId"
                defaultValue={user.authId}
                onChange={handleChange}
              />
            </Form.Field>
            {getUserType(user) === userTypes.employee && (
              <Fragment>
                <Form.Dropdown
                  label="Role"
                  name="roles"
                  multiple
                  fluid
                  search
                  selection
                  defaultValue={get(user, 'roles', [])}
                  options={employeeRoles.map((role) => ({
                    key: role,
                    value: role,
                    text: role,
                  }))}
                  onChange={handleChange}
                />
              </Fragment>
            )}
            {getUserType(user) === userTypes.memberUser && (
              <Fragment>
                <Form.Field>
                  <label>Freshdesk Contact Id</label>
                  <Input
                    name="freshdeskContactId"
                    defaultValue={user.freshdeskContactId}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Dropdown
                  label="Member User Type"
                  name="memberUserType"
                  search
                  selection
                  defaultValue={user.memberUserType}
                  options={memberUserTypes}
                  onChange={handleChange}
                />
                <Form.Dropdown
                  name="state"
                  label="User state"
                  fluid
                  search
                  selection
                  defaultValue={user.state}
                  options={userStates}
                  onChange={handleChange}
                />
              </Fragment>
            )}
            {[KYC, RESUBMIT_KYC].includes(get(user, 'state', '')) && (
              <Form.Dropdown
                name="appState"
                label="IDM App state"
                fluid
                search
                selection
                defaultValue={user.appState}
                options={appStates}
                onChange={handleChange}
              />
            )}
            <Button
              positive
              disabled={saveDisabled}
              id="save"
              onClick={saveUserDetails}
            >
              {' '}
              Save{' '}
            </Button>
          </Form>
        </Segment>
        {getUserType(user) === userTypes.memberUser && (
          <Segment className="user-settings-segment">
            <Form className="segment vertical">
              <Header as="h2" content="Default Trading Application" />
              <Form.Dropdown
                defaultValue={user.defaultTradingApp}
                name="defaultTradingApp"
                onChange={handleChange}
                options={supportedTradingApplications.map(
                  ({ name, value, disabled }) => ({
                    key: name,
                    text: name,
                    value,
                    disabled,
                  }),
                )}
                searchm
                selection
              />
              <Form.Button
                disabled={!update.defaultTradingApp}
                color="green"
                content="Save"
                name="submitTradingApp"
                onClick={saveUserDetails}
              />
            </Form>
            <Form className="segment vertical">
              <Header as="h2" content="Security" />
              <Form.Button color="red" name="reset" onClick={confirmPassword}>
                Reset{' '}
                {getUserType(user) === userTypes.memberUser
                  ? 'Funding Password'
                  : 'Admin Key'}
              </Form.Button>
              <Form.Button color="red" name="reset" onClick={resetMf} disabled>
                Reset 2FA
              </Form.Button>
            </Form>
            <Form className="segment vertical">
              <Header as="h2" content="CQG" />
              <Form.Field>
                <label>CQG User Id</label>
                <Input
                  name="cqgUserId"
                  defaultValue={user.cqgUserId}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>CQG Username</label>
                <Input
                  name="cqgUsername"
                  defaultValue={user.cqgUsername}
                  readOnly
                />
              </Form.Field>
              <Form.Field>
                <label>CQG Customer Id</label>
                <Input
                  name="cqgCustomerId"
                  defaultValue={user.cqgCustomerId}
                  readOnly
                />
              </Form.Field>
            </Form>
            <Form className="segment vertical">
              <Header as="h2" content="API Settings" />
              <Form.Button
                name="reset"
                onClick={() =>
                  routeSubmittingOrdersToApi({ memberUserId: user.userId })
                }
              >
                Route Fix Id for API Order Entry
              </Form.Button>
            </Form>
          </Segment>
        )}
      </Segment.Group>
      {getUserType(user) === userTypes.memberUser && (
        <Segment.Group horizontal>
          <Segment className="user-settings-segment">
            <Permissions
              header="Member User Permissions"
              permissionsWithHeaderStructure={userPermissionsStructure}
              permissions={get(update, 'permissions', user?.permissions)}
              name="permissions"
              onChange={handleChange}
              dataWithHeader
            />
            <Button
              positive
              disabled={!update.permissions}
              id="savePermissions"
              onClick={saveUserDetails}
            >
              {' '}
              Save{' '}
            </Button>
          </Segment>
          <Segment className="user-settings-segment">
            <Permissions
              header="API Key Permissions"
              permissionsWithHeaderStructure={apiPermissionsStructure}
              permissions={get(
                update,
                'apiKeyPermissions',
                user?.apiKeyPermissions,
              )}
              name="apiKeyPermissions"
              onChange={handleChange}
              dataWithHeader
            />
            <Button
              positive
              disabled={!update.apiKeyPermissions}
              id="saveApiKeyPermissions"
              onClick={saveUserDetails}
            >
              {' '}
              Save{' '}
            </Button>
          </Segment>
        </Segment.Group>
      )}
    </Segment.Group>
  );
};

export default MemberSettings;
