import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Divider } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';
import PdfViewer from './components/PdfViewer';
import colors from '../constants/colors';

const NoDocs = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.primary2};
  height: 80px;
  align-items: center;
  font-size: 18px;
`;

const Doc = styled.div`
  margin-bottom: 10px;
`;

const DocNum = styled.p`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Documents = ({ documents, noDocsText }) =>
  isEmpty(documents) ? (
    <NoDocs>{noDocsText}</NoDocs>
  ) : (
    <>
      {documents.map((doc, i) => (
        <Doc key={`doc-${doc.createdAt}-${i}`}>
          <DocNum>{`Document ${i + 1}:`}</DocNum>
          {`Created at: ${doc?.createdAt ? formatTimeLocal(doc.createdAt) : '-'}`}
          <Divider />
          {doc?.image &&
            (doc.image.split('/')[0].includes('image') ? (
              <img src={`data:${doc.image}`} alt="supporting_doc" />
            ) : (
              <PdfViewer
                image={`data:${doc.image}`}
                title="Supporting document"
              />
            ))}
        </Doc>
      ))}
    </>
  );

Documents.defaultProps = {
  documents: [],
  noDocsText: 'No documents to display',
};
Documents.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      image: PropTypes.string,
    }),
  ),
  noDocsText: PropTypes.string,
};
export default Documents;
