import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  renderDropdown,
  renderDateTime,
  renderField,
  rules,
  normalization,
} from 'erisxkit/client';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Field, formValueSelector } from 'redux-form';

const deliveryTypesWithBounds = ['bounded', 'push'];
const FuturesContract = ({ cycleOptions, productDelivery, firstTradeDate }) => {
  const futureBoundsDisabled = moment().isSameOrAfter(moment(firstTradeDate));
  const futureBoundsRule = () => futureBoundsDisabled || undefined;
  return (
    <Fragment>
      <Form.Group widths="3">
        <Field
          component={renderDropdown}
          id="cycle"
          label="Cycle"
          name="cycle"
          options={cycleOptions.map((o) => ({ key: o, text: o, value: o }))}
          required
          validate={[rules.required]}
        />
        <Field
          component={renderField}
          id="spanId"
          name="spanId"
          label="Span Id"
          parse={normalization.numbersOnly}
        />
      </Form.Group>
      <Form.Group widths="3">
        <Field
          component={renderField}
          id="productSymbol"
          name="productSymbol"
          label="Futures Product Code"
          readOnly
          required
          validate={[rules.required]}
        />
        <Field
          component={renderField}
          id="contractCode"
          label="Clearing Code"
          name="contractCode"
          required
          validate={[rules.required]}
        />
        <Field
          component={renderField}
          id="contractSymbol"
          label="Exchange Code"
          name="contractSymbol"
          required
          validate={[rules.required]}
        />
      </Form.Group>
      <Form.Group widths="3">
        <Form.Field required>
          <label htmlFor="expiration-time">Maturity Date and Time</label>
          <Field
            name="expirationTime"
            id="expiration-time"
            className="show-input"
            component={renderDateTime}
            startDate={Date.now()}
            timeFormat
            placeholder="MM/DD/YYYY h:mm am"
            required
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="first-trade-date">First Trade Date</label>
          <Field
            name="firstTradeDate"
            id="first-trade-date"
            className="show-input"
            component={renderDateTime}
            startDate={Date.now()}
            placeholder="MM/DD/YYYY"
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="last-trade-date">Last Trade Date</label>
          <Field
            name="lastTradeDate"
            id="last-trade-date"
            className="show-input"
            component={renderDateTime}
            startDate={Date.now()}
            placeholder="MM/DD/YYYY"
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="3">
        {deliveryTypesWithBounds.includes(productDelivery) && (
          <Fragment>
            <Form.Field required disabled={futureBoundsDisabled}>
              <Field
                name="upper"
                label="Upper Boundary"
                id="upper-boundary"
                className="show-input"
                component={renderField}
                required
                validate={[rules.required, futureBoundsRule]}
              />
            </Form.Field>
            <Form.Field required disabled={futureBoundsDisabled}>
              <Field
                name="lower"
                label="Lower Boundary"
                id="lower-boundary"
                className="show-input"
                component={renderField}
                required
                validate={[rules.required, futureBoundsRule]}
              />
            </Form.Field>
          </Fragment>
        )}
      </Form.Group>
      <Form.Group widths="3">
        <Form.Field required>
          <label htmlFor="listing-date">Listing Date</label>
          <Field
            name="listingDate"
            id="listing-date"
            className="show-input"
            component={renderDateTime}
            startDate={Date.now()}
            placeholder="MM/DD/YYYY"
            required
          />
        </Form.Field>
        <Form.Field required>
          <label htmlFor="delisting-date">Delisting Date</label>
          <Field
            name="delistingDate"
            id="delisting-date"
            className="show-input"
            component={renderDateTime}
            startDate={Date.now()}
            placeholder="MM/DD/YYYY"
            required
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          name="description"
          id="description"
          component={renderField}
          label="Description"
        />
      </Form.Group>
    </Fragment>
  );
};

FuturesContract.propTypes = {
  cycleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  productDelivery: PropTypes.string,
  firstTradeDate: PropTypes.string,
};

FuturesContract.defaultProps = {
  cycleOptions: [],
  productDelivery: '',
  firstTradeDate: '',
};

const selector = formValueSelector('CreateFuturesContract');

const mapStateToProps = (state) => ({
  firstTradeDate: selector(state, 'firstTradeDate'),
});

export default connect(mapStateToProps)(FuturesContract);
