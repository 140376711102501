import React from 'react';
import { Icon } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';

const scheduledACHMetadata = (deleteACHDeposit) => [
  {
    Header: 'Member',
    id: 'member_name',
    accessor: 'memberName',
  },
  {
    Header: 'Account',
    id: 'account',
    accessor: 'account',
  },
  {
    Header: 'Schedule',
    id: 'schedule',
    accessor: 'schedule',
  },
  {
    Header: 'Next Date',
    id: 'next_date',
    accessor: (row) => formatTimeLocal(row.nextDate),
  },
  {
    Header: 'Amount',
    id: 'amount',
    className: 'pull-right mono',
    headerClassName: 'pull-right',
    accessor: 'amount',
  },
  {
    Header: 'Actions',
    id: 'actions',
    headerClassName: 'pull-left',
    accessor: (d) => (
      <>
        <Icon
          onClick={() => deleteACHDeposit(d)}
          style={{ marginLeft: '5px' }}
          link
          name="trash"
        />
      </>
    ),
    filterable: false,
    resizable: false,
  },
];

export default scheduledACHMetadata;
