import BigNumber from 'bignumber.js';
import { formatFiat } from 'erisxkit/client';
import { formatUSD } from '../utils/methods';
import { formatTimeLocal } from '../utils/time';

export default [
  {
    Header: 'Time',
    id: 'time',
    accessor: (row) => formatTimeLocal(row.time),
  },
  {
    Header: 'Asset',
    id: 'product',
    accessor: 'product',
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: 'txType',
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: (row) => formatUSD(row.amount),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Fee',
    id: 'fee',
    accessor: (row) => formatUSD(row.otherFees || '0'),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Total',
    id: 'total',
    accessor: (row) =>
      formatUSD(
        BigNumber(row.amount)
          .plus(row.otherFees || 0)
          .toFixed(),
      ),
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: 'description',
  },
];
