import _ from 'lodash';
import { arrayToObject } from 'erisxkit/client';
import { createRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import {
  CREATE_LEDGER,
  LEDGERS,
  SWITCH_LEDGER,
} from '../constants/actionTypes';
import { DEFAULT_LEDGER } from '../constants/ledgers';

export const createLedger = createRoutine(CREATE_LEDGER);
export const ledgers = createRoutine(LEDGERS);
export const switchLedger = (payload) => ({
  type: SWITCH_LEDGER,
  payload,
});

export default (
  state = {
    activeLedgerId: DEFAULT_LEDGER.ledgerId,
    byId: {},
    count: 0,
  },
  { type, payload },
) => {
  switch (type) {
    case ledgers.SUCCESS:
      return {
        ...state,
        count: payload.count,
        byId: arrayToObject(_.get(payload, 'ledgers'), 'ledgerId'),
      };
    case createLedger.SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [_.get(payload, 'ledgerId')]: payload,
        },
        count: state.count + 1,
      };
    case SWITCH_LEDGER:
      return { ...state, activeLedgerId: payload };
    default:
      return state;
  }
};

export const getLedgers = (state) => _.get(state, ['ledgers', 'byId'], {});
export const getLedgerIds = createSelector([getLedgers], (ls) =>
  Object.keys(ls),
);

export const getActiveLedgerId = (state) =>
  _.get(state, ['ledgers', 'activeLedgerId']);

// returns boolean if the current active ledger is the default ledger or not
export const isDefaultLedgerActive = createSelector(
  [getActiveLedgerId],
  (id) => !id,
);

export const getActiveLedger = createSelector(
  [getActiveLedgerId, getLedgers],
  (activeLedgerId, allLedgers) => _.get(allLedgers, activeLedgerId),
);

export const getActiveLedgerName = createSelector(
  [getActiveLedger],
  (activeLedger) => _.get(activeLedger, 'name'),
);

export const getLedgerCount = (state) => _.get(state, ['ledgers', 'count']);
