import _ from 'lodash';
import React, { Component } from 'react';
import { Comment, Header, Input, Button } from 'semantic-ui-react';
import { formatTimeFromNow, formatTimeLocal } from '../utils/time';

const comment = (commentDetails) => (
  <Comment>
    <Comment.Content>
      <Comment.Author as="a">{commentDetails.email}</Comment.Author>
      <Comment.Metadata>
        <div title={formatTimeLocal(commentDetails.time)}>
          {formatTimeFromNow(commentDetails.time)}
        </div>
      </Comment.Metadata>
      <Comment.Text>{commentDetails.msg}</Comment.Text>
    </Comment.Content>
  </Comment>
);

const commentsContainer = (row, type, handleChange, addComment, value) => {
  let commentsDetails = [];
  if (type === 'External') {
    commentsDetails = _.get(row, 'txComments', []);
  }
  if (type === 'Internal') {
    commentsDetails = _.get(row, 'journalComments', []);
  }
  const comments = commentsDetails.map((commentDetails) =>
    comment(commentDetails),
  );
  return (
    <Comment.Group className="reconciliation">
      <Header as="h3" dividing>
        {type} Transaction Comments
      </Header>
      {comments}
      <Input
        id="comment"
        name="comment"
        onChange={handleChange}
        placeholder="Write a comment..."
        value={value}
      />
      <Button
        id="add-comment"
        onClick={(e) =>
          addComment({
            e,
            row,
            type,
          })
        }
        disabled={!value}
        content="Add"
      />
    </Comment.Group>
  );
};

class CommentSubComponent extends Component {
  state = {
    comment: '',
  };
  handleChange = (e, obj) => {
    const change = { [obj.name]: obj.value };
    this.setState(change);
  };

  addComment = ({ row, type }) => {
    let uuid = '';

    if (type === 'External') {
      uuid = row.txId;
    }

    if (type === 'Internal') {
      uuid = row.journalId;
    }
    const commentArgs = [
      {
        uuid,
        ...this.state,
      },
    ];
    this.props.addComment(commentArgs);
    this.setState({
      comment: '',
    });
  };

  render = () => {
    const { row, addComment } = this.props;
    return (
      <div className="flex-row reconciliation-comments">
        {row.original.txId &&
          commentsContainer(
            _.get(row, ['original'], {}),
            'External',
            this.handleChange,
            this.addComment,
            this.state.comment,
          )}
        {row.original.journalId &&
          commentsContainer(
            _.get(row, ['original'], {}),
            'Internal',
            this.handleChange,
            this.addComment,
            this.state.comment,
          )}
      </div>
    );
  };
}

export default CommentSubComponent;
