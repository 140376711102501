import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Fragment, Component } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import { titleCase } from 'change-case';
import {
  getSelectedAccount,
  getSelectedAccountId,
} from '../../reducers/reconciliationReducer';
import AccountHistoryContainer from '../ClearingMemberAccounts/AccountHistoryContainer';
import { fetchAccounts } from '../../actions/accountsActions';
import CreateAccountContainer from '../CreateAccountModalContainer';
import XTab from '../../common/tabs/XTab';
import BackButton from '../../common/components/BackButton';

const panes = [
  {
    url: 'transactions',
    menuItem: 'Transactions',
    render: ({ selectedAccount }) => (
      <AccountHistoryContainer selectedAccount={selectedAccount} />
    ),
  },
  {
    url: 'settings',
    menuItem: 'Settings',
    render: ({ selectedAccount }) => (
      <CreateAccountContainer
        selectedAccount={selectedAccount}
        type="finance"
        update
      />
    ),
  },
];

const mapStateToProps = (state, props) => ({
  selectedAccountId: getSelectedAccountId(state),
  selectedAccount: getSelectedAccount(state, props),
  loading: createLoadingSelector(['ACCOUNTS'])(state),
});

const mapDispatchToProps = {
  fetchAccounts,
};

class FinanceAccountDetailsContainer extends Component {
  componentDidMount = () => {
    // fetch the data
    const { fetchAccounts, selectedAccountId } = this.props;
    fetchAccounts({ accountIds: [selectedAccountId] });
  };

  render = () => {
    const { selectedAccount = {}, loading } = this.props;
    return (
      <Fragment>
        <BackButton
          header={get(selectedAccount, 'label')}
          headerLabels={[
            get(selectedAccount, 'accountType') && {
              content: titleCase(get(selectedAccount, 'accountType')),
              size: 'small',
              tag: true,
            },
          ]}
          subheader={get(selectedAccount, 'accountId')}
        />
        <XTab
          panes={panes}
          selectedAccount={selectedAccount}
          loading={loading || !selectedAccount.accountId}
        />
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinanceAccountDetailsContainer);
