import _ from 'lodash';
import { formatTimeLocal } from '../utils/time';

const columns = [
  {
    Header: 'Component',
    id: 'component',
    accessor: 'component',
  },
  {
    Header: 'Category',
    id: 'category',
    accessor: 'category',
  },
  {
    Header: 'Message',
    id: 'message',
    accessor: 'message',
  },
  {
    Header: 'Priority',
    id: 'pri',
    accessor: 'pri',
  },
  {
    Header: 'Time',
    id: 'time',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.receivedTime),
    getProps: (state, rowInfo) => ({
      title: formatTimeLocal(_.get(rowInfo, 'original.receivedTime')),
    }),
  },
];

export default columns;
