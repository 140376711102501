import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import appliedMarginRatesMetadata from '../../metadata/appliedMarginRatesMetadata';
import {
  fetchAppliedMarginRates,
  getAppliedMarginRates,
  getMarginRateInfoDate,
} from '../../reducers/marginRatesReducer';
import XTable7 from '../../common/table/XTable7';
import { MARGIN_RATES } from '../../constants/modalTypes';
import { PENDING } from '../../constants/approvalReqStates';
import { Button } from 'semantic-ui-react';
import { formatTimeLocal } from '../../utils/time';
import { getCurrentUser } from '../../reducers/usersReducer';
import { approvePromiseCreator } from '../../actions/approvalReqsActions';
import {
  fetchApprovalReqsMethod,
  isPreviousDateSelected,
  drawApprovers,
} from './commonMethods';
import {
  rejectPromiseCreator,
  getApprovalReqsList,
  REJECT,
} from '../../reducers/approvalReqsReducer';
import { get } from 'lodash';
import { APPROVE } from '../../constants/actionTypes';

const fetchData = () => {};

const AppliedMargin = () => {
  const dispatch = useDispatch();
  const appliedMarginRatesLoading = useSelector(
    createLoadingSelector([fetchAppliedMarginRates._PREFIX]),
  );
  const appliedMarginRates = useSelector(getAppliedMarginRates);
  const marginRateInfoDate = useSelector(getMarginRateInfoDate);
  const approvalReqs = useSelector(getApprovalReqsList(PENDING));
  const user = useSelector(getCurrentUser);

  React.useEffect(() => {
    dispatch(fetchAppliedMarginRates({ date: marginRateInfoDate }));
  }, [marginRateInfoDate]);

  React.useEffect(() => {
    //When approvalReqUuid is available, fetch the approvers of the margin rate
    if (appliedMarginRates?.approvalReqUuid) {
      dispatch(fetchApprovalReqsMethod(appliedMarginRates?.approvalReqUuid));
    }
  }, [appliedMarginRates]);

  const showModalButtons = (action) => {
    dispatch(
      showModal(MARGIN_RATES, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(MARGIN_RATES)),
        updateStatus: action === 'Confirm' ? handleApply : handleReject,
        action: action,
        date: formatTimeLocal(appliedMarginRates?.timestamp),
        initiator: user,
        title:
          action === 'Confirm'
            ? 'Approve Calculated Margin Rates'
            : 'Reject Calculated Margin Rates',
        message:
          action === 'Confirm'
            ? 'You will approve the calculated margin rates'
            : 'You will reject the calculated margin rates',
        loadingSelector: action === 'Confirm' ? APPROVE : REJECT,
      }),
    );
  };

  const handleReject = async () => {
    try {
      await rejectPromiseCreator(
        { uuid: appliedMarginRates?.approvalReqUuid },
        dispatch,
      );
      dispatch(hideModal(MARGIN_RATES));
      dispatch(fetchAppliedMarginRates({ date: marginRateInfoDate }));
    } catch (error) {
      console.log('Error on reject margin rates');
    }
  };

  const handleApply = async () => {
    try {
      await approvePromiseCreator(
        appliedMarginRates?.approvalReqUuid,
        dispatch,
      );
      dispatch(hideModal(MARGIN_RATES));
      dispatch(fetchAppliedMarginRates({ date: marginRateInfoDate }));
    } catch (error) {
      console.log('Error on approve margin rates');
    }
  };

  const isFirstApproverCurrentUser =
    appliedMarginRates?.state === PENDING &&
    get(approvalReqs[0]?.approvals, ['0', 'email'], '-') === user.email;

  return (
    <Fragment>
      <div className="topHeader">
        <div className="marginRateMainTab">
          <p className="marginRateMainTabP">
            <b className="marginRateText">Status:</b>{' '}
            {appliedMarginRates?.state || '-'}
          </p>
          {drawApprovers(approvalReqs)}
        </div>
        <div className="alignRight">
          <Button
            className="add-button rejectBtn"
            id="rejectRequest"
            onClick={() => showModalButtons('Reject')}
            disabled={
              isPreviousDateSelected(marginRateInfoDate) ||
              !(appliedMarginRates?.state === PENDING)
            }
          >
            Reject Request
          </Button>
          <Button
            className="add-button"
            id="applyRequest"
            onClick={() => showModalButtons('Confirm')}
            disabled={
              isPreviousDateSelected(marginRateInfoDate) ||
              !(appliedMarginRates?.state === PENDING) ||
              isFirstApproverCurrentUser
            }
          >
            Approve Request
          </Button>
        </div>
      </div>
      <XTable7
        metadata={appliedMarginRatesMetadata(
          appliedMarginRates?.tradeDate,
          appliedMarginRates?.approvalCompletedTime,
        )}
        data={appliedMarginRates?.marginRates || []}
        fetchData={fetchData}
        loading={appliedMarginRatesLoading}
        count={appliedMarginRates?.marginRates?.length}
        showPagination={false}
        localSortingAndFiltering={true}
      />
    </Fragment>
  );
};

AppliedMargin.propTypes = {
  fetchAppliedMarginRates: PropTypes.func.isRequired,
  appliedMarginRatesLoading: PropTypes.bool,
  appliedMarginRates: PropTypes.func.isRequired,
};

AppliedMargin.defaultProps = {
  count: 0,
};

export default AppliedMargin;
