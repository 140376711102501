import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fetchWithdrawalLimit,
  fetchDepositLimit,
  fetchAccountOverrides,
  updateAccountOverrides,
  UPDATE_ACCOUNT_OVERRIDES,
} from '../reducers/achReducer';

export default function* watchACH() {
  yield takeLatest(
    fetchWithdrawalLimit.TRIGGER,
    createSaga(
      fetchWithdrawalLimit,
      fetchWithdrawalLimit._PREFIX,
      '',
      '',
      '',
      '',
    ),
  );
  yield takeLatest(
    fetchDepositLimit.TRIGGER,
    createSaga(fetchDepositLimit, fetchDepositLimit._PREFIX, '', '', '', ''),
  );
  yield takeLatest(
    fetchAccountOverrides.TRIGGER,
    createSaga(
      fetchAccountOverrides,
      fetchAccountOverrides._PREFIX,
      '',
      '',
      '',
      '',
    ),
  );
  yield takeLatest(
    updateAccountOverrides.TRIGGER,
    createSaga(
      updateAccountOverrides,
      UPDATE_ACCOUNT_OVERRIDES,
      'Submitting ACH Overrides',
      'ACH Overrides submitted',
      '',
      '',
    ),
  );
}
