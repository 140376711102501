import { createRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

// action generator to get all supported asset_types from the backend.
export const assetTypes = () => ({
  type: actionTypes.ASSET_TYPES_REQUEST,
});

export const createAssetType = createRoutine(actionTypes.CREATE_ASSET_TYPE);
export const fetchAbout = createRoutine(actionTypes.ABOUT);
export const scaleBaseToMinUnit = createRoutine(
  actionTypes.SCALE_BASE_TO_MIN_UNIT,
);
