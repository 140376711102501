import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import colors from '../../../constants/colors';
import {
  Action,
  Label,
  MainAction,
  StyledField,
  StyledForm,
  StyledHeader,
  StyledInput,
  StyledTimerButton,
  Wrapper,
} from './styled';
import GroupedFormFields from './GroupedFormFields';

export const getGroupedFieldName = (groupName, fieldName) =>
  `${groupName}_${fieldName}`;
/**
 * @param group - object with a key and list of fields (name and initial value if any)
 * @returns object that maps a field name (preppended with group key) with its initial value for given group
 */
const getFieldGroupForm = (group) => {
  if (isArray(group)) {
    let fields = {};
    // The group itself is another group
    group.forEach((nestedGroup) => {
      fields = nestedGroup.fields.reduce(
        (acc, curr) => ({
          ...acc,
          [getGroupedFieldName(nestedGroup.key, curr.name)]:
            curr.initialValue || '',
        }),
        fields,
      );
    });
    return fields;
  }
  return group.fields.reduce(
    (acc, curr) => ({
      ...acc,
      [getGroupedFieldName(group.key, curr.name)]: curr.initialValue,
    }),
    {},
  );
};

/**
 * @param groupedFields - list of grouped fields (objects with a key and fields attribute)
 * @returns object that maps all the fields from all the groups with their initial values
 */
const getInitialValuesFromGroupedFields = (groupedFields) => {
  const res = groupedFields.reduce(
    (accFields, currGroup) => ({
      ...accFields,
      ...getFieldGroupForm(currGroup),
    }),
    {},
  );
  return res;
};

/**
 * @param fields - flat list of objects modeling the fields and their initial values
 * @returns object that maps a field name with its initial value
 */
const getInitialValuesFromFields = (fields) =>
  fields.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.initialValue || '' }),
    {},
  );

export const SubComponentField = ({
  field,
  disabled,
  onChange,
  value,
  hasChanged,
  name,
  reveal,
}) => {
  const showValue = useMemo(
    () => (field?.concealed ? reveal : true),
    [reveal, field?.concealed],
  );

  return (
    <StyledField inline>
      <Label>{field.label}:</Label>
      <StyledInput
        name={name || field.name}
        placeholder={field?.placeholder || field.label}
        disabled={disabled || !showValue}
        value={showValue ? value : '***********'}
        onChange={onChange}
        hasChanged={hasChanged}
      />
    </StyledField>
  );
};

SubComponentField.defaultProps = {
  value: '',
  hasChanged: false,
  name: '',
  reveal: false,
};

SubComponentField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    placeholder: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hasChanged: PropTypes.bool,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  name: PropTypes.string,
  reveal: PropTypes.bool,
};

const isConcealedField = (field) => !!field?.concealed;

const EditableRowSubComponent = ({
  readOnly,
  fields,
  className,
  onUpdate,
  toggleExpand,
  customHeader,
  groupedFields,
}) => {
  // Store initial values
  const initialValues = useMemo(
    () =>
      groupedFields
        ? getInitialValuesFromGroupedFields(groupedFields)
        : getInitialValuesFromFields(fields),
    [fields, groupedFields],
  );
  // Local form values
  const [values, setValues] = useState(initialValues);
  const [isEditing, setIsEditing] = useState(false);
  // A grouped field can consist of other grouped fields. We keep track of the active ones.
  const [activeGroups, setActiveGroups] = useState([]);
  const ActionCTA = useMemo(() => (isEditing ? 'Update' : 'Edit'));
  const hasChanges = useMemo(
    () => !isEqual(initialValues, values),
    [initialValues, values],
  );
  const onEdit = () => setIsEditing(true);
  // Reveal functionality
  const [revealFields, setRevealFields] = useState(false);

  const onSetActiveGroup = useCallback(
    (prev, newGroup) => {
      // If a from group had a previously active group - we need to remove it
      if (prev) {
        // remove the previously active group and add the new one
        setActiveGroups([
          ...activeGroups.filter((group) => group !== prev),
          newGroup,
        ]);
      } else {
        // If there was no prev, just add the new one
        setActiveGroups([...activeGroups, newGroup]);
      }
    },
    [activeGroups],
  );

  const onSubmit = useCallback(() => {
    setIsEditing(false);
    toggleExpand(false);
    onUpdate({ original: initialValues, updated: values, activeGroups });
  }, [values, activeGroups, initialValues]);

  const onActionClick = useMemo(
    () => (isEditing ? onSubmit : onEdit),
    [isEditing, onSubmit, onEdit],
  );
  const actionDisabled = useMemo(
    () => (isEditing ? !hasChanges : false),
    [isEditing, hasChanges],
  );
  const fieldDisabled = useMemo(
    () => readOnly || !isEditing,
    [isEditing, readOnly],
  );

  const onChange = useCallback(
    (e, { name, value }) => {
      setValues({ ...values, [name]: value });
    },
    [values],
  );
  const onDiscard = useCallback(
    () => setValues(initialValues),
    [initialValues],
  );
  const getFieldHasChanged = useCallback(
    (field) => get(initialValues, field) !== get(values, field),
    [initialValues, values],
  );

  const showTimerButton = useMemo(() => {
    // Non-grouped forms fields
    if (!isEmpty(fields)) return fields.some(isConcealedField);
    // Grouped
    if (!isEmpty(groupedFields))
      return groupedFields.some((group) => {
        // Is nested group
        if (isArray(group)) {
          return group.some((subGroup) =>
            subGroup?.fields?.some(isConcealedField),
          );
        }
        return group?.fields?.some(isConcealedField);
      });

    return false;
  }, [fields, groupedFields]);

  return (
    <Wrapper className={className}>
      {readOnly && showTimerButton && (
        <StyledHeader>
          <StyledTimerButton
            onTimerStart={() => setRevealFields(true)}
            onTimerEnd={() => setRevealFields(false)}
          />
        </StyledHeader>
      )}
      {!readOnly && (
        <StyledHeader>
          <>
            <Action
              onClick={onDiscard}
              color={colors.error}
              disabled={!hasChanges}
              text="Cancel"
            />
            <MainAction
              onClick={onActionClick}
              color={colors.accent3}
              disabled={actionDisabled}
              text={ActionCTA}
            />
          </>
          {showTimerButton && (
            <StyledTimerButton
              onTimerStart={() => setRevealFields(true)}
              onTimerEnd={() => setRevealFields(false)}
            />
          )}
          {!!customHeader && customHeader}
        </StyledHeader>
      )}
      {groupedFields ? (
        <GroupedFormFields
          groupedFields={groupedFields}
          onChange={onChange}
          values={values}
          getFieldHasChanged={getFieldHasChanged}
          disabled={fieldDisabled}
          onSetActiveGroup={onSetActiveGroup}
          reveal={revealFields}
        />
      ) : (
        <StyledForm className="subrow-form-grid">
          {fields.map((field) => (
            <SubComponentField
              field={field}
              key={field.name}
              disabled={fieldDisabled}
              onChange={onChange}
              value={get(values, field.name, '')}
              hasChanged={getFieldHasChanged(field.name)}
              reveal={revealFields}
            />
          ))}
        </StyledForm>
      )}
    </Wrapper>
  );
};

EditableRowSubComponent.defaultProps = {
  readOnly: false,
  fields: [],
  className: '',
  customHeader: null,
  groupedFields: null,
};

EditableRowSubComponent.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      initialValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      placeholder: PropTypes.string,
      concealed: PropTypes.bool,
    }),
  ),
  onUpdate: PropTypes.func.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  customHeader: PropTypes.node,
  groupedFields: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // A group can have a list of groups
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              label: PropTypes.string.isRequired,
              initialValue: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
              ]),
              placeholder: PropTypes.string,
            }),
          ),
        }),
      ),
      // or a single group
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            initialValue: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.bool,
            ]),
            placeholder: PropTypes.string,
          }),
        ),
      }),
    ]),
  ),
};

export default EditableRowSubComponent;
