import _ from 'lodash';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import * as actionTypes from '../constants/actionTypes';
import { getSelectedAccountId } from './reconciliationReducer';

export function reconciliationHistoryReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.RECONCILIATION_HISTORY_SUCCESS: {
      return action.history;
    }
    default:
      return state;
  }
}

const allReconciliationHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RECONCILIATION_HISTORY_SUCCESS:
      return {
        ...state,
        [action.accountId]: reconciliationHistoryReducer(
          state[action.accountId],
          action,
        ),
      };
    default:
      return state;
  }
};

export default combineReducers({
  byAccountId: allReconciliationHistoryReducer,
});

const getReconciliationHistories = (state) =>
  _.get(state.reconciliationHistory, 'byAccountId', {});

export const getReconciliationHistory = createSelector(
  [getSelectedAccountId, getReconciliationHistories],
  (accountId, reconciliationHistoriesByAccount) =>
    _.get(reconciliationHistoriesByAccount, accountId, []),
);
