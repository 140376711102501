export enum BlockTradeRequestAction {
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

export enum BlockTradeRequestSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum BlockTradeState {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

type BlockTradeRequest = {
  requestId?: string;
  state?: string;
  product?: string;
  contractSymbol?: string;
  qty?: number;
  px?: number;
  tradeDate?: Date;
  validator?: string;
  buySide: {
    accountLabel?: string;
    clearingFirm?: string;
    exchangeMember?: string;
    tradingParticipant?: string;
    customerAccountRef?: string;
    cgm?: string;
    clOrdId?: string;
    senderSubId?: string;
    customerOrderCapacity?: string;
    accountType?: string;
  };
  sellSide: {
    accountLabel?: string;
    clearingFirm?: string;
    exchangeMember?: string;
    tradingParticipant?: string;
    customerAccountRef?: string;
    cgm?: string;
    clOrdId?: string;
    senderSubId?: string;
    customerOrderCapacity?: string;
    accountType?: string;
  };
  reportingSide: BlockTradeRequestSide;
  blockTradeId: string;
};

export default BlockTradeRequest;
