import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Fragment, Component } from 'react';
import { Message } from 'semantic-ui-react';
import {
  fetchMemberComplianceQuestions,
  getComplianceQuestions,
} from '../../reducers/usersReducer';

const mapStateToProps = (state) => ({
  questions: getComplianceQuestions(state),
});

const mapDispatchToProps = {
  fetchMemberComplianceQuestions,
};

class ComplianceQuestionsContainer extends Component {
  componentDidMount = () => {
    this.props.fetchMemberComplianceQuestions(this.props.userId);
  };

  render = () => {
    const { questions } = this.props;
    return (
      <Fragment>
        {!_.isEmpty(questions)
          ? questions.map((question) => (
              <Message
                info
                header={question.question}
                content={`Answer: ${question.choice}`}
              />
            ))
          : ''}
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplianceQuestionsContainer);
