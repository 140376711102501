import { get } from 'lodash';
import { createRoutine } from 'redux-saga-routines';

export const API_CREDENTIALS_PERMISSIONS = 'API_CREDENTIALS_PERMISSIONS';
export const apiCredentialsPermissions = createRoutine(
  API_CREDENTIALS_PERMISSIONS,
);

export default (state = [], action = {}) => {
  switch (action.type) {
    case apiCredentialsPermissions.SUCCESS:
      return action.payload.permissions;
    default:
      return state;
  }
};

export const getApiCredentialPermissions = (state) =>
  get(state, 'apiCredentials', []);
