import { connect } from 'react-redux';
import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import { List, Header, Table, Input, Icon, Form } from 'semantic-ui-react';
import {
  createLoadingSelector,
  showModal,
  hideModal,
  inputOnChange,
} from 'erisxkit/client';
import PropTypes from 'prop-types';
import { titleCase } from 'change-case';
import { v4 as uuidv4 } from 'uuid';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import cogoToast from 'cogo-toast';
import {
  fetchTradeCaptureReports,
  reprocessTradeCaptureReport,
  requestReprocessTradeCaptureReport,
  clearTradeCaptureReport,
  getTradeCaptureReportsCount,
  getTradeCaptureReportsPaged,
  updateTradeCaptureReportPromiseCreator,
} from '../reducers/tradeCaptureReportsReducer';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../constants/modalTypes';
import tradeCaptureReportsMetadata from '../metadata/tradeCaptureReportsMetadata';
import GenericFilteredTable from '../common/containers/GenericFilteredTable';
import ProductContractSelection from '../common/containers/ProductContractSelection';
import tcrStates from '../constants/tcrStates';
import FullHeightWrapper from '../common/components/FullHeightWrapper';

const mapStateToProps = (state) => ({
  tcrs: getTradeCaptureReportsPaged(state),
  count: getTradeCaptureReportsCount(state),
  loading: createLoadingSelector(['TRADE_CAPTURE_REPORTS'])(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators({ updateTradeCaptureReportPromiseCreator }, dispatch),
  ...bindActionCreators(
    {
      fetchTradeCaptureReports,
      clearTradeCaptureReport,
      reprocessTradeCaptureReport,
      requestReprocessTradeCaptureReport,
      showModal,
      hideModal,
    },
    dispatch,
  ),
});

const reprocessMsg = (row) => (
  <Fragment>
    <p>Reprocess trade with:</p>
    <List>
      <List.Item>
        <span className="italic">Trade Link ID: </span>
        <span className="mono">{row.tradeLinkId}</span>
      </List.Item>
      <List.Item>
        <span className="italic">Buy Side Trade ID: </span>
        <span className="mono">{get(row, ['buySide', 'tradeId'])}</span>
      </List.Item>
      <List.Item>
        <span className="italic">Sell Side Trade ID: </span>
        <span className="mono">{get(row, ['sellSide', 'tradeId'])}</span>
      </List.Item>
    </List>
  </Fragment>
);

const editableFields = ['customerAccountRef', 'cti', 'origin'];

const createItems = (side) => {
  const sideObj = {
    tradeId: side.tradeId,
    clientOrderId: side.clOrdId,
    emarketAccountId: side.cxAccountId,
    fixId: side.fixId,
    customerAccountRef: side.customerAccountRef,
    cti: side.cti,
    origin: side.origin,
  };

  return Object.keys(sideObj).map((key) => ({
    key,
    value: sideObj[key],
  }));
};

export const editMsg = (row, handleChange) => {
  const { buySide = {}, sellSide = {} } = row;

  const renderBodyRow =
    (side) =>
    ({ key, value }, i) => ({
      key: key || `row-${i}`,
      cells: [
        key === 'cti' ? titleCase(key).toUpperCase() : titleCase(key),
        {
          key,
          content: editableFields.includes(key) ? (
            <Input
              type={key === 'customerAccountRef' ? 'text' : 'number'}
              defaultValue={value}
              name={key}
              onChange={(e, obj) => handleChange(e, obj, side)}
              icon="edit"
            />
          ) : (
            value || ''
          ),
        },
      ],
    });

  return (
    <Fragment>
      <div className="flex tcr-details">
        <Table
          collapsing
          striped
          celled
          headerRow={{
            key: 'header',
            cells: [{ colSpan: 2, content: 'Buy Side' }],
          }}
          renderBodyRow={renderBodyRow('buySide')}
          tableData={createItems(buySide)}
        />
        <Icon name="exchange" />
        <Table
          collapsing
          striped
          celled
          headerRow={{
            key: 'header',
            cells: [{ colSpan: 2, content: 'Sell Side' }],
          }}
          renderBodyRow={renderBodyRow('sellSide')}
          tableData={createItems(sellSide)}
        />
      </div>
    </Fragment>
  );
};

const filters = [
  {
    component: ProductContractSelection,
    contractName: 'symbol',
    name: 'symbol',
    onChange:
      (onChange) =>
      (e, { name, value }) =>
        onChange(name, value, 'eq'),
    upward: false,
  },
  {
    placeholder: 'Trade Link ID',
    label: 'Trade Link ID',
    component: Form.Input,
    name: 'trade_link_id',
    id: 'trade_link_id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'Buy Side Trade ID',
    label: 'Buy Side Trade ID',
    component: Form.Input,
    name: 'buy_side.trade_id',
    id: 'buy_side.trade_id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'Sell Side Trade ID',
    label: 'Sell Side Trade ID',
    component: Form.Input,
    name: 'sell_side.trade_id',
    id: 'sell_side.trade_id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'Spread ID',
    label: 'Spread ID',
    component: Form.Input,
    name: 'secondary_exec_id',
    id: 'secondary_exec_id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'State',
    component: Form.Select,
    clearable: true,
    selectOnBlur: false,
    options: tcrStates,
    name: 'state',
    id: 'state',
    label: 'State',
    multiple: true,
    onChange:
      (onChange) =>
      (e, { name, value }) =>
        onChange(name, value, 'eq'),
  },
];
class TradeCaptureReportsContainer extends Component {
  state = {};

  handleChange = (e, obj, side) => {
    const change = { [side]: { ...this.state[side], [obj.name]: obj.value } };
    this.setState(change);
  };

  showReprocessModal = (row) =>
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: 'Confirm Trade Reprocess',
      msg: reprocessMsg(row),
      hideModal: this.props.hideModal,
      closeOnDimmerClick: true,
      closeOnEscape: true,
      onConfirm: () => {
        this.props.reprocessTradeCaptureReport({
          tradeLinkId: row.tradeLinkId,
        });
        this.props.hideModal();
      },
      payload: row,
    });

  showRequestReprocessModal = (row) =>
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: 'Confirm Creation of Approval to reprocess trade',
      msg: reprocessMsg(row),
      hideModal: this.props.hideModal,
      closeOnDimmerClick: true,
      closeOnEscape: true,
      onConfirm: () => {
        this.props.requestReprocessTradeCaptureReport({
          tradeLinkId: row.tradeLinkId,
        });
        this.props.hideModal();
      },
      payload: row,
    });

  updateTCR = async () => {
    let buySide, sellSide;
    const side = {
      accountId: null,
      firm_code: null,
      sub_account_code: null,
      cgm: null,
    };

    if (this.state.buySide !== undefined) {
      buySide = {
        ...side,
        cti: this.toInteger(this.state.buySide.cti),
        origin: this.toInteger(this.state.buySide.origin),
        customerAccountRef:
          this.state.buySide.customerAccountRef !== undefined
            ? this.state.buySide.customerAccountRef
            : null,
      };
    } else if (this.state.sellSide !== undefined) {
      sellSide = {
        ...side,
        cti: this.toInteger(this.state.sellSide.cti),
        origin: this.toInteger(this.state.sellSide.origin),
        customerAccountRef:
          this.state.sellSide.customerAccountRef !== undefined
            ? this.state.sellSide.customerAccountRef
            : null,
      };
    }

    if (buySide !== undefined || sellSide !== undefined) {
      try {
        await this.props.updateTradeCaptureReportPromiseCreator({
          tradeLinkId: this.state.tradeLinkId,
          requestId: uuidv4(),
          buySide,
          sellSide,
        });
        cogoToast.info(
          `Trade Link ID ${this.state.tradeLinkId} updated. CANCEL and REPLACE trades were created`,
        );
        this.props.fetchTradeCaptureReports();
      } catch (error) {}
    }
  };

  toInteger = (value) => (value !== undefined ? parseInt(value) : null);

  edit = (row) => {
    this.setState({
      tradeLinkId: row.tradeLinkId,
    });

    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: `Edit TCR with Trade Link ID: ${row.tradeLinkId}`,
      msg: editMsg(row, this.handleChange),
      hideModal: this.props.hideModal,
      closeOnDimmerClick: true,
      closeOnEscape: true,
      onConfirm: () => {
        this.updateTCR();
        this.props.hideModal();
      },
    });
  };

  render = () => {
    const { tcrs, count, loading } = this.props;

    return (
      <FullHeightWrapper>
        <Header
          as="h1"
          dividing
          content="Trade Capture Reports"
          style={{ flexShrink: 0 }}
        />
        <GenericFilteredTable
          filters={filters}
          slice={['tradeCaptureReports']}
          routine={fetchTradeCaptureReports}
          key="tcrs"
          resetOnUnmount
          title="tcrs"
          metadata={tradeCaptureReportsMetadata(
            this.showReprocessModal,
            this.showRequestReprocessModal,
            this.props.clearTradeCaptureReport,
            this.edit,
          )}
          noDataText={<span className="italic">No TCRs found.</span>}
          sortable={false}
          getTdProps={(state, rowInfo, column) => ({
            className: column.id === 'more' && 'td-with-dropdown',
          })}
        />
      </FullHeightWrapper>
    );
  };
}

TradeCaptureReportsContainer.propTypes = {
  count: PropTypes.number,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  reprocessTradeCaptureReport: PropTypes.func.isRequired,
  requestReprocessTradeCaptureReport: PropTypes.func.isRequired,
  tcrs: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
};

TradeCaptureReportsContainer.defaultProps = {
  count: 0,
  tcrs: [],
  loading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TradeCaptureReportsContainer);
