import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { hideModal } from 'erisxkit/client';
import {
  createDeposit,
  createWithdrawal,
  createTransfer,
  createReversal,
  reconcile,
} from '../actions/reconciliationActions';
import {
  getTransaction,
  getSelectedAccountId,
  getSelectedReconciliation,
  getEndTime,
  getSelectedAccount,
} from '../reducers/reconciliationReducer';
import ReconciliationModal from '../components/ReconciliationModal';
import { fetchAccounts } from '../actions/accountsActions';
import * as accountTypes from '../constants/accountTypes';
import { createAccountsSelector } from '../reducers/accountsReducer';
import { assetTypes } from '../actions/utilActions';
import { getIsFiatAssetType } from '../selectors';

const mapStateToProps = (state, props) => ({
  selectedAccountId: getSelectedAccountId(state, props),
  selectedAccount: getSelectedAccount(state, props),
  endTime: getEndTime(state, props),
  transaction: getTransaction(state, props),
  accountsLoading: createLoadingSelector(['ACCOUNTS'])(state),
  accountList: createAccountsSelector([
    accountTypes.OWNERSHIP,
    accountTypes.HOUSE,
  ])(state),
  selectedReconciliation: getSelectedReconciliation(state, props),
  isFiatAssetType: getIsFiatAssetType(
    _.get(getSelectedAccount(state, props), 'assetType', ''),
  )(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideModal,
      createDeposit,
      createWithdrawal,
      createTransfer,
      reconcile,
      fetchAccounts,
      createReversal,
      assetTypes,
    },
    dispatch,
  );

const getFee = (transaction, type) => {
  if (type === 'deposit') {
    return {};
  } else if (type === 'withdrawal' || type === 'internal') {
    return { expenseFee: Math.abs(transaction.fee).toString() };
  }
};

class ReconciliationModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applyToAccountId: _.get(props.transaction, 'accountId', ''),
      type: _.get(props.transaction, 'type', ''),
      applyToAccountIdLabel: '',
    };
    this.onClose = this.onClose.bind(this);
    this.onApply = this.onApply.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onPopupOpen = this.onPopupOpen.bind(this);
  }

  componentDidMount() {
    // fetch the data
    const { fetchAccounts, assetTypes } = this.props;
    fetchAccounts({ categories: [accountTypes.OWNERSHIP, accountTypes.HOUSE] });
    assetTypes();
  }

  onClose() {
    this.props.hideModal();
  }

  onPopupClose() {
    this.setState({
      popupIsOpen: false,
    });
  }

  onPopupOpen() {
    this.setState({
      popupIsOpen: true,
    });
  }

  onApply() {
    this.props.hideModal();
    const { transaction } = this.props;
    if (transaction.state === 'external') {
      if (this.state.type === 'withdrawal') {
        const newTransaction = this.createPayload(transaction);
        this.props.createWithdrawal(newTransaction);
      }
      if (this.state.type === 'deposit') {
        const newTransaction = this.createPayload(transaction);
        this.props.createDeposit(newTransaction);
      }

      if (this.state.type === 'internal') {
        const newTransaction = this.createPayloadInternalTransfer(transaction);
        this.props.createTransfer(newTransaction);
      }
    } else if (transaction.state === 'internal') {
      const newTransaction = this.createPayloadReversal(transaction);
      this.props.createReversal(newTransaction);
    }
    // reload the reconciliation history to get updated reconciliation
    this.props.reconcile({
      ...this.props.selectedReconciliation,
      selectedAccountId: this.props.selectedAccountId,
      endTime: this.props.endTime,
    });
  }

  handleChange = (e, obj) => {
    const change = { [obj.name]: obj.value };
    this.setState({
      ...change,
      // get the accountLabelId from dropdown method and save it in state.
      ...(obj.name === 'applyToAccountId' && {
        applyToAccountIdLabel: _.get(
          _.find(obj.options, (option) => option.key === obj.value),
          'text',
          '',
        ),
      }),
    });
  };

  createPayload = (transaction) => ({
    custodianAccountId: this.props.selectedAccountId,
    applyToAccountId: this.state.applyToAccountId,
    txId: transaction.txId.split('.')[0],
    refCode: transaction.refCode,
    time: transaction.txTime,
    amount: Math.abs(transaction.amount).toString(),
    //  conditionally show the fee if it exists
    ...(transaction.fee && getFee(transaction, this.state.type)),
  });

  createPayloadReversal = (transaction) => ({ uuid: transaction.journalId });

  createPayloadInternalTransfer = (transaction) => ({
    fromAccountId: transaction.recAccountId,
    toAccountId: transaction.accountId,
    txId: transaction.txId.split('.')[0],
    refCode: transaction.refCode,
    time: transaction.txTime,
    amount: Math.abs(transaction.amount).toString(),
    //  conditionally show the fee if it exists
    ...(transaction.fee && {
      expenseFee: Math.abs(transaction.fee).toString(),
    }),
  });

  accountDropdownDisabled = (transaction) => {
    // disabled the account selected dropdown if the tx is 'internal' (journal side) or the type is an 'internal transfer'
    if (transaction.state === 'internal' || transaction.type === 'internal') {
      return true;
    }
    // enabled for deposit/withdrawals of external transactions
    return false;
  };

  render() {
    const {
      transaction,
      selectedAccount,
      assetTypes,
      accountsLoading,
      isFiatAssetType,
    } = this.props;
    const props = {
      transaction,
      onApply: this.onApply,
      onClose: this.onClose,
      onPopupClose: this.onPopupClose,
      onPopupOpen: this.onPopupOpen,
      popupIsOpen: this.state.popupIsOpen,
      handleChange: this.handleChange,
      applyToAccountId: this.state.applyToAccountId,
      disabled: this.accountDropdownDisabled(transaction),
      loading: accountsLoading,
      type: this.state.type,
      selectedAccount,
      applyToAccountIdLabel: this.state.applyToAccountIdLabel,
      isFiatAssetType,
    };

    return <ReconciliationModal {...props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconciliationModalContainer);
