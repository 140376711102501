import React, { Component } from 'react';

/**
 * Component that Auth0 redirects to after the user successfully logs in.
 * This Component is only shown until the user is redirected to the house_accounts page.
 */
class Login extends Component {
  render() {
    return <div />;
  }
}

export default Login;
