export default [
  {
    Header: 'Trade Date',
    id: 'reportDate',
    accessor: 'reportDate',
  },
  {
    Header: 'Clearing Member Firm',
    id: 'reportingFirm',
    accessor: 'reportingFirm',
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
  },
  {
    Header: 'Contract',
    id: 'contract',
    accessor: 'contract',
  },
];
