import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Level',
    id: 'level',
    accessor: 'level',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Metric Name',
    id: 'metricName',
    accessor: 'metricName',
    editInputType: TABLE_INPUT_TYPES.STRING,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Month Lookback',
    id: 'monthLookback',
    accessor: 'monthLookback',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 150,
  },
  {
    Header: 'Threshold',
    id: 'threshold',
    accessor: 'threshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
