import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  createSubExchange,
  fetchSubExchanges,
  SUB_EXCHANGES,
  CREATE_SUB_EXCHANGE,
  UPDATE_SUB_EXCHANGE,
  updateSubExchange,
} from '../reducers/subExchangesReducer';

export default function* watchSubExchanges() {
  yield takeLatest(
    fetchSubExchanges.TRIGGER,
    createSaga(fetchSubExchanges, SUB_EXCHANGES),
  );
  yield takeLatest(
    createSubExchange.TRIGGER,
    createSaga(
      createSubExchange,
      CREATE_SUB_EXCHANGE,
      'Creating sub exchange...',
      'New sub exchange created.',
    ),
  );
  yield takeLatest(
    updateSubExchange.TRIGGER,
    createSaga(
      updateSubExchange,
      UPDATE_SUB_EXCHANGE,
      'Updating sub exchange...',
      'Sub exchange updated.',
    ),
  );
}
