import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, Header } from 'semantic-ui-react';
import { showModal, hideModal } from 'erisxkit/client';

const InvalidFieldsModal = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Header icon="remove circle" content="Failed to Apply Changes" />
      <Modal.Content scrolling>
        <Header>
          <Header.Content>Invalid Fields</Header.Content>
          <Header.Subheader>
            Some of the changes introduced are not valid. Please correct them or
            remove them to apply changes.
          </Header.Subheader>
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => dispatch(hideModal())} content="Close" />
      </Modal.Actions>
    </>
  );
};

export default InvalidFieldsModal;
