import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fetchClientTransfer,
  approveAllClientTransfer,
  rejectAllClientTransfer,
} from '../reducers/clientTransferReducer';
import { MEMBER_TRANSFER_FINAL_APPROVALS } from '../constants/actionTypes';

export default function* watchContracts() {
  yield takeLatest(
    fetchClientTransfer.TRIGGER,
    createSaga(fetchClientTransfer, MEMBER_TRANSFER_FINAL_APPROVALS),
  );
  yield takeLatest(
    approveAllClientTransfer.TRIGGER,
    createSaga(approveAllClientTransfer, approveAllClientTransfer._PREFIX),
  );
  yield takeLatest(
    rejectAllClientTransfer.TRIGGER,
    createSaga(rejectAllClientTransfer, rejectAllClientTransfer._PREFIX),
  );
}
