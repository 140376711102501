import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { get } from 'lodash';

export const ACH_DEFAULTS = 'ACH_DEFAULTS';
export const UPDATE_ACH_DEFAULTS = 'UPDATE_ACH_DEFAULTS';
export const JURISDICTIONS = 'JURISDICTIONS';
export const UPDATE_JURISDICTIONS = 'UPDATE_JURISDICTIONS';
export const GET_COUNTRIES_FOR_JURISDICTIONS =
  'GET_COUNTRIES_FOR_JURISDICTIONS';
export const TRUST_THRESHOLD_SETTINGS = 'TRUST_THRESHOLD_SETTINGS';
export const SET_TRUST_THRESHOLD = 'SET_TRUST_THRESHOLD';

export const fetchAchDefaults = createRoutine(ACH_DEFAULTS);
export const fetchAchDefaultsPromiseCreator =
  promisifyRoutine(fetchAchDefaults);

export const fetchJurisdictions = createRoutine(JURISDICTIONS);
export const fetchJurisdictionsPromiseCreator =
  promisifyRoutine(fetchJurisdictions);

export const updateAchDefaults = createRoutine(UPDATE_ACH_DEFAULTS);
export const updateJurisdictions = createRoutine(UPDATE_JURISDICTIONS);
export const updateJurisdictionsPromiseCreator =
  promisifyRoutine(updateJurisdictions);
export const fetchCountriesForJurisdictions = createRoutine(
  GET_COUNTRIES_FOR_JURISDICTIONS,
);
export const fetchTrustThresholdSettings = createRoutine(
  TRUST_THRESHOLD_SETTINGS,
);
export const updateTrustThresholdSettings = createRoutine(SET_TRUST_THRESHOLD);

// Reducer
export default handleActions(
  {
    [fetchAchDefaults.SUCCESS]: (state, { payload }) => ({
      ...state,
      achDefaults: payload,
    }),
    [updateAchDefaults.SUCCESS]: (state, { payload }) => ({
      ...state,
      achDefaults: {
        ...state.achDefaults,
        ...payload,
      },
    }),
    [fetchJurisdictions.SUCCESS]: (state, { payload }) => {
      if (payload.accountId) {
        return {
          ...state,
          jurisdictions: payload,
        };
      }
      return {
        ...state,
        defaultJurisdictions: payload,
      };
    },
    [fetchCountriesForJurisdictions.SUCCESS]: (state, { payload }) => ({
      ...state,
      countries: payload,
    }),
    [updateJurisdictions.SUCCESS]: (state, { payload }) => {
      if (payload.accountId) {
        return {
          ...state,
          jurisdictions: payload,
        };
      }
    },
    [fetchTrustThresholdSettings.SUCCESS]: (state, { payload }) => ({
      ...state,
      thresholdSettings: payload,
    }),
    [updateTrustThresholdSettings.SUCCESS]: (state, { payload }) => ({
      ...state,
      thresholdSettings: payload,
      testo: 123,
    }),
  },
  {},
);

const selectBase = (state) => get(state, ['configuration'], []);
export const getAchDefaults = createSelector([selectBase], (base) =>
  get(base, ['achDefaults'], {}),
);
export const getJurisdictions = createSelector([selectBase], (base) =>
  get(base, ['jurisdictions'], []),
);
export const getDefaultJurisdictions = createSelector([selectBase], (base) =>
  get(base, ['defaultJurisdictions', 'countries'], []),
);
export const getCountriesForJurisdictions = createSelector(
  [selectBase],
  (base) => get(base, ['countries'], []),
);
export const getTrustThresholds = createSelector([selectBase], (base) =>
  get(base, ['thresholdSettings'], {}),
);
