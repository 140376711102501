import {
  fetchFcmMarginCalculations,
  fetchFcmMarginRequirements,
  setMarginRequirements,
} from '../reducers/initialMarginReducer';
import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    fetchFcmMarginCalculations.TRIGGER,
    createSaga(fetchFcmMarginCalculations, fetchFcmMarginCalculations._PREFIX),
  );
  yield takeLatest(
    fetchFcmMarginRequirements.TRIGGER,
    createSaga(fetchFcmMarginRequirements, fetchFcmMarginRequirements._PREFIX),
  );
  yield takeLatest(
    setMarginRequirements.TRIGGER,
    createSaga(
      setMarginRequirements,
      setMarginRequirements._PREFIX,
      'Creating Request',
      'Request Submitted',
    ),
  );
}
