import React, { Fragment } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';

const renderFiatFields = (account) => (
  <Fragment>
    <Form.Input label="Label" type="text" value={account.label} readOnly />
    <Form.Input
      label="Bank Name"
      type="text"
      value={account.bankName}
      readOnly
    />
    <Form.Group>
      <Form.Input
        label="Bank Account Number"
        type="text"
        value={account.accountNumber}
        readOnly
      />
      <Form.Input
        label="Bank Routing Number"
        type="text"
        value={account.routingNumber}
        readOnly
      />
      <Form.Input
        label="Created At"
        type="text"
        value={account.createdAt}
        readOnly
      />
    </Form.Group>
  </Fragment>
);

const renderCryptoFields = (account) => (
  <Fragment>
    <Form.Input label="Label" type="text" value={account.label} readOnly />
    <Form.Input
      label="Asset Type"
      type="text"
      value={account.assetType}
      readOnly
    />
    <Form.Group>
      <Form.Input
        label="Address"
        type="text"
        value={account.address}
        readOnly
      />
      <Form.Input
        label="Created At"
        type="text"
        value={account.createdAt}
        readOnly
      />
    </Form.Group>
  </Fragment>
);

const OverrideTxDestination = ({
  hideModal,
  confirm,
  selectedLinkedAccount,
}) => (
  <Fragment>
    <Modal.Header>
      Overriding the account hold of this linked account:
    </Modal.Header>
    <Modal.Content>
      <Form>
        {selectedLinkedAccount.hashId
          ? renderFiatFields(selectedLinkedAccount)
          : renderCryptoFields(selectedLinkedAccount)}
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button id="cancel" color="red" onClick={hideModal}>
        Cancel
      </Button>
      <Button id="confirm" color="green" onClick={confirm}>
        Confirm
      </Button>
    </Modal.Actions>
  </Fragment>
);

export default OverrideTxDestination;
