import React from 'react';
import { withFilters, inputOnChange } from 'erisxkit/client';
import { Dropdown } from 'semantic-ui-react';
import ExternalTable from '../common/table/ExternalTable';

const TableWithFilter = withFilters(ExternalTable);
const filters = (key, assetTypes = []) => [
  {
    placeholder: 'Asset Type',
    component: Dropdown,
    name: 'asset_type',
    key: `asset_type_${key}`,
    id: `asset_type_${key}`,
    label: 'Asset Type',
    onChange: inputOnChange,
    options: assetTypes.map((asset) => ({
      key: asset.symbol,
      value: asset.symbol,
      text: asset.symbol,
      description: asset.description,
    })),
    selection: true,
    search: true,
  },
];

const AccountHistory = ({ data }) => (
  <TableWithFilter
    title="accountHistory"
    onFetchData={data.fetchData}
    count={data.count}
    data={data.history || []}
    pageSize={20}
    className="-striped -highlight"
    columns={data.metadata || []}
    loading={data.loading}
    filters={filters(data.type, data.assetTypesData)}
  />
);

export default AccountHistory;
