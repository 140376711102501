import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';
import { get } from 'lodash';

export const fetchSettlementInstructions = createRoutine(
  actionTypes.SETTLEMENT_INSTRUCTIONS,
);
//export const fetchClientTransferPromiseCreator = promisifyRoutine(fetchClientTransfer);
export const confirmSettlement = createRoutine(actionTypes.CONFIRM_SETTLEMENT);
//export const approveAllClientTransferPromiseCreator = promisifyRoutine(approveAllClientTransfer);
export const rejectSettlement = createRoutine(actionTypes.REJECT_SETTLEMENT);
//export const rejectAllClientTransferPromiseCreator = promisifyRoutine(rejectAllClientTransfer);

export default function settlementReducer(state = {}, action) {
  switch (action.type) {
    case fetchSettlementInstructions.SUCCESS:
      return action.payload;
    case confirmSettlement.SUCCESS:
    case rejectSettlement.SUCCESS:
      return state.map((obj) =>
        obj.id === action.payload.id
          ? { ...obj, status: action.payload.status }
          : obj,
      );
    default:
      return state;
  }
}

export const getSettlementInstructions = (state) =>
  get(state, 'settlement', []);
