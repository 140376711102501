import React from 'react';
import { roundNumber } from '../utils/methods';

const columns = [
  {
    Header: 'From',
    id: 'from',
    accessor: 'from',
  },
  {
    Header: 'To',
    id: 'to',
    accessor: 'to',
  },
  {
    Header: 'Asset',
    id: 'asset',
    accessor: 'asset',
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: (row) => roundNumber(row.amount, 2),
    className: 'text-right mono',
  },
  {
    Header: 'Action',
    id: 'action',
    accessor: (row) => (
      <span>{row?.checked?.includes('approve') ? 'Approve' : 'Reject'}</span>
    ),
  },
];

export default columns;
