import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import history from '../constants/history';
import ReconciliationIndex from '../components/ReconciliationIndex';
import * as accountsActions from '../actions/accountsActions';
import * as accountTypes from '../constants/accountTypes';
import { showModal } from 'erisxkit/client';
import { selectAccountReconciliation } from '../actions/reconciliationActions';
import { createAccountsSelector } from '../reducers/accountsReducer';
import metadata from '../metadata/custodianAccountMetadata';
import { assetTypes } from '../actions/utilActions';
import { getAssetTypesAsOptions } from '../selectors';

const mapStateToProps = (state) => ({
  accountList: createAccountsSelector([accountTypes.CUSTODIAN])(state),
  assetTypeOptions: getAssetTypesAsOptions(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...accountsActions,
      showModal,
      selectAccountReconciliation,
      assetTypes,
    },
    dispatch,
  );

class ReconciliationIndexContainer extends Component {
  constructor(props) {
    super(props);
    this.viewReconciliations = this.viewReconciliations.bind(this);
  }
  componentDidMount() {
    // fetch the data
    const { fetchAccounts, assetTypes } = this.props;
    assetTypes();
    fetchAccounts({ categories: [accountTypes.CUSTODIAN] });
  }
  viewReconciliations(id) {
    this.props.selectAccountReconciliation(id);
    history.push(`/reconciliation/${id}`);
  }
  render() {
    const { accountList, assetTypeOptions } = this.props;
    return (
      <ReconciliationIndex
        data={accountList}
        metadata={metadata(assetTypeOptions, this.viewReconciliations)}
        viewReconciliations={this.viewReconciliations}
      />
    );
  }
}

ReconciliationIndexContainer.propTypes = {
  fetchAccounts: PropTypes.func.isRequired,
  accountList: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconciliationIndexContainer);
