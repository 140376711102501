export { WITHDRAWAL_CONFIRM } from 'erisxkit/client';
export const RECONCILIATION_MODAL = 'RECONCILIATION_MODAL';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const APPROVE_REJECT = 'APPROVE_REJECT';
export const ADD_REMOVE_ACCOUNTS = 'ADD_REMOVE_ACCOUNTS';
export const ADD_REMOVE_MEMBERS = 'ADD_REMOVE_MEMBERS';
export const CREATE_MEMBER = 'CREATE_MEMBER';
export const CREATE_ADMIN_KEY = 'CREATE_ADMIN_KEY';
export const ADMIN_KEY = 'ADMIN_KEY';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const ADD_CRYPTO_ADDRESS = 'ADD_CRYPTO_ADDRESS';
export const ADD_COLLATERAL_ACCOUNT = 'ADD_COLLATERAL_ACCOUNT';
export const FUNDING_PASSWORD = 'FUNDING_PASSWORD';
export const OVERRIDE_TX_DESTINATION = 'OVERRIDE_TX_DESTINATION';
export const CONFIRM_ACTION_WITH_PAYLOAD = 'CONFIRM_ACTION_WITH_PAYLOAD';
export const CONFIRM_NO_POST_TO_EXCHANGE = 'CONFIRM_NO_POST_TO_EXCHANGE';
export const TASK_LOG = 'TASK_LOG';
export const CREATE_MANUAL_EXTERNAL_TRANSACTION =
  'CREATE_MANUAL_EXTERNAL_TRANSACTION';
export const CREATE_SPOT_PRODUCT = 'CREATE_SPOT_PRODUCT';
export const CREATE_COLLATERAL_CONTRACT = 'CREATE_COLLATERAL_CONTRACT';
export const CREATE_COLLATERAL_PRODUCT = 'CREATE_COLLATERAL_PRODUCT';
export const CREATE_FUTURES_CONTRACT = 'CREATE_FUTURES_CONTRACT';
export const CREATE_FUTURES_PRODUCT = 'CREATE_FUTURES_PRODUCT';
export const CREATE_SUB_EXCHANGE = 'CREATE_SUB_EXCHANGE';
export const GENERAL_JOURNAL_CONFIRM_MODAL = 'GENERAL_JOURNAL_CONFIRM_MODAL';
export const CREATE_EMARKET_ACCOUNT = 'CREATE_EMARKET_ACCOUNT';
export const DELIVERY = 'DELIVERY';
export const CREATE_LEDGER = 'CREATE_LEDGER';
export const ADD_UBO = 'ADD_UBO';
export const UPDATE_RISK_ALERT_STATUS = 'UPDATE_RISK_ALERT_STATUS';
export const VIEW_PHOTO = 'VIEW_PHOTO';
export const EDIT_ONBOARDING_NOTE = 'EDIT_ONBOARDING_NOTE';
export const BLOCK_TRADE_REQUEST = 'BLOCK_TRADE_REQUEST';
export const CONFIRM_TRANSFER = 'CONFIRM_TRANSFER';
export const CONFIRM_REJECT_SETTLEMENT = 'CONFIRM_REJECT_SETTLEMENT';
export const ACTION_STATUS = 'ACTION_STATUS';
export const MARGIN_RATES = 'MARGIN_RATES';
export const ADD_REMOVE_AUTHORIZATION_IPS = 'ADD_REMOVE_AUTHORIZATION_IPS';
export const PARSED_REPORT = 'PARSED_REPORT';
export const ADD_JURISDICTION = 'ADD_JURISDICTION';
export const TABLE_INVALID_FIELDS = 'TABLE_INVALID_FIELDS';
export const TABLE_REVIEW_CHANGES = 'TABLE_REVIEW_CHANGES';
export const CONFIRM_POSITION_TRANSFER = 'CONFIRM_POSITION_TRANSFER';
export const CLEARING_REQUEST_ACTION = 'CLEARING_REQUEST_ACTION';
export const TRADING_PARTICIPANT_LINK_CGM = 'TRADING_PARTICIPANT_LINK_CGM';
export const TRADING_PARTICIPANT_LINK_CAR = 'TRADING_PARTICIPANT_LINK_CAR';
export const GENERIC_MODAL = 'GENERIC_MODAL';
export const ADD_AUTHORIZED_TRADER = 'ADD_AUTHORIZED_TRADER';
export const APPROVE_REJECT_BLOCK_TRADE_REQUEST =
  'APPROVE_REJECT_BLOCK_TRADE_REQUEST';
