export default {
  request_withdrawals: {
    header: 'Funding',
    text: 'Request Withdrawals',
  },
  read_movements: {
    header: 'Transactions',
    text: 'View Movements',
  },
  close_positions: {
    header: 'Positions',
    text: 'Close Positions',
  },
  read_transactions: {
    header: 'Transactions',
    text: 'View Recent Transactions',
  },
  read_daily_statements: {
    header: 'Transactions',
    text: 'EMP Statements',
  },
  destroy_linked_asset_account: {
    header: 'SSI',
    text: 'Delete SSI',
  },
  read_linked_asset_account: {
    header: 'SSI',
    text: 'View SSI',
  },
  read_trading_application_data: {
    header: 'Trading',
    text: 'Allow Trading',
  },
  create_linked_asset_account: {
    header: 'SSI',
    text: 'Add SSI',
  },
  send_deposits: {
    header: 'Funding',
    text: 'Send Deposits',
  },
  read_collateral_positions: {
    header: 'Positions',
    text: 'View Collateral Positions',
  },
  read_futures_positions: {
    header: 'Positions',
    text: 'View Futures Positions',
  },
  read_balances: {
    header: 'Transactions',
    text: 'View Balances',
  },
  read_trades: {
    header: 'Transactions',
    text: 'View Trades',
  },
  read_participant_account: {
    header: 'Margin Clearing',
    text: 'Read Participant Account',
  },
  write_participant_account: {
    header: 'Margin Clearing',
    text: 'Write Participant Account',
  },
  request_fcm_clearing: {
    header: 'Margin Clearing',
    text: 'Request FCM Clearing',
  },
  manage_block_trade_permissions: {
    header: 'Transactions',
    text: 'Manage Block Trade Permissions',
  },
};
