import React, { useCallback, useMemo } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import XTable7 from '../../../common/table/XTable7';
import fcmClearingRequestsMetadata from '../../../metadata/fcmClearingRequestMetadata';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  FETCH_FCM_CLEARING,
  fetchFcmClearing,
  getFcmMemberClearingRequestCount,
  getSortedFcmMemberClearingRequests,
} from '../../../reducers/fcmClearingReducer';
import Member from '../../../ts/models/Member.model';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { RootState } from '../../../ts/types/store';

interface Props {
  selectedMember: Member;
}
const FCMClearingRequests = ({ selectedMember }: Props) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getSortedFcmMemberClearingRequests);
  const count = useAppSelector(getFcmMemberClearingRequestCount);
  const loading = useAppSelector((state: RootState) =>
    createLoadingSelector([FETCH_FCM_CLEARING])(state),
  );
  const firmCode = useMemo(() => selectedMember?.firmCode, [selectedMember]);

  const fetchData = useCallback(() => {
    if (!firmCode) return;
    dispatch(fetchFcmClearing({ urlParams: { firmCode } }));
  }, [dispatch, firmCode]);

  const metadata = useMemo(() => fcmClearingRequestsMetadata(fetchData), []);

  return (
    <XTable7
      title="fcmClearingRequsts"
      metadata={metadata}
      data={data}
      fetchData={fetchData}
      loading={loading}
      count={count}
      showHeader
      showPagination={false}
    />
  );
};

export default FCMClearingRequests;
