import Big from 'bignumber.js';
import { Label } from 'semantic-ui-react';
import React from 'react';
import { formatTimeLocal } from '../utils/time';

const requestLog = [
  {
    Header: '',
    columns: [
      {
        Header: 'Time',
        id: 'time',
        headerClassName: 'pull-left',
        accessor: (row) => formatTimeLocal(row.time),
      },
      {
        Header: 'Clearing Member',
        id: 'memberName',
        headerClassName: 'pull-left',
        accessor: 'memberName',
      },
      {
        Header: 'Account',
        id: 'accountLabel',
        accessor: 'accountLabel',
      },
      {
        Header: 'Asset Type',
        id: 'product',
        headerClassName: 'pull-left',
        accessor: 'product',
      },
      {
        Header: 'Category',
        id: 'type',
        headerClassName: 'pull-left',
        accessor: 'txType',
      },
      {
        Header: 'Amount',
        id: 'amount',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: 'amount',
      },
      {
        Header: 'Fee',
        id: 'otherFees',
        accessor: ({ otherFees = 0, feeType = '' }) =>
          `${Big(otherFees).toFixed()} ${feeType}`,
        headerClassName: 'pull-left',
      },
      {
        Header: 'Total ',
        id: 'total',
        className: 'pull-right mono',
        headerClassName: 'pull-right',
        accessor: ({ amount = 0, otherFees = 0 }) =>
          Big(amount).plus(Big(otherFees)).toFixed(),
      },
      {
        Header: 'Detailed Status',
        id: 'explicitState',
        accessor: (d) => <Label content={d.explicitState} />,
        className: 'pull-right',
        headerClassName: 'pull-right',
      },
    ],
  },
];

export default requestLog;
