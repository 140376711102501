import { get } from 'lodash';
import React, { Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import HomeContainer from '../containers/HomeContainer';
import Login from '../common/Login';
import Auth from '../services/Auth';
import AppLayout from '../common/AppLayout';
import Restricted from '../common/Restricted';
import history from '../constants/history';
import routes from './routes';
import { isAuthorized } from '../utils/methods';
import PromotionsContainer from '../containers/Promotions/Promotions';

const auth = new Auth();

const handleAuthentication = (nextState) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

const withLayout = (ComponentComp, props) => (
  <AppLayout auth={auth}>
    <ComponentComp auth={auth} {...props} />
  </AppLayout>
);

export const PrivateRoute = withRouter(
  connect((state, props) => ({
    authorized: isAuthorized(
      get(state, ['users', 'currentUser', 'uiViews'], null),
      props.path,
      props.altPath,
    ),
  }))(({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        } else if (!rest.authorized) {
          return (
            <Redirect
              to={{
                pathname: '/restricted',
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  )),
);

export default () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/" exact component={() => withLayout(HomeContainer)} />
      <Route
        path="/restricted"
        exact
        component={() => withLayout(Restricted)}
      />
      <Route
        path="/login"
        render={(props) => {
          handleAuthentication(props);
          return <Login {...props} />;
        }}
      />
      {routes.map((route) => (
        <PrivateRoute
          path={route.path}
          altPath={route.altPath}
          key={route.path}
          exact={route.exact}
          component={(props) => withLayout(route.layout, props)}
        />
      ))}
    </Switch>
  </ConnectedRouter>
);
