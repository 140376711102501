import React, { Fragment } from 'react';
import { Header, Segment, List, Checkbox, Button } from 'semantic-ui-react';
import { Form, Input } from 'semantic-ui-react-form-validator';
import PropTypes from 'prop-types';

const CreateAssetType = ({
  assetTypes, assetTypesLoading, handleChange, handleSubmit, state
}) => (<Fragment>
  <Header as="h1" dividing>
    Create New Asset Types
  </Header>
  <Segment color="teal" padded loading={assetTypesLoading}>
    <Header as="h2">Existing Asset Types:</Header>
    <List horizontal divided relaxed="very">
      {assetTypes.length ? assetTypes.map(asset => (
        <List.Item>
          <span className={`crypto-icon-${asset.symbol}`} />
          <List.Content>
            <List.Header>{asset.symbol}</List.Header>
            <List.Description>{[
              asset.description,
              (asset.isFiat ? <span className="italic"> (fiat)</span> : null),
            ]}
            </List.Description>
            <List.Description>
              Precision: {asset.precision}
            </List.Description>
          </List.Content>
        </List.Item>
      )) : <List.Item>-</List.Item>}
    </List>
  </Segment>
  <Form onSubmit={handleSubmit}>
    <Input
      label="Symbol"
      placeholder="E.g. USD, BTC, etc."
      name="symbol"
      onChange={handleChange}
      value={state?.symbol}
      required
    />
    <Input
      label="Description"
      placeholder="US Dollar, Bitcoin, etc."
      name="description"
      onChange={handleChange}
      value={state?.description}
      required
    />
    <Input
      label="Precision"
      type="number"
      placeholder="16"
      name="precision"
      onChange={handleChange}
      value={state?.precision}
    />
    <Input
      type="number"
      label="Span Id"
      name='spanId'
      value={state?.spanId}
      onChange={handleChange}
      placeholder="100"
      validators={["minFloat:1", "maxNumber:9999"]}
      errorMessages={["Value is below minimum 1", "Value cannot exceed 9999"]}
    />
    <Checkbox
      toggle
      label="Is asset a fiat currency?"
      name="isFiat"
      value={state?.isFiat}
      onChange={handleChange}
    />
    <br />
    <br />
    <Button type="submit" content="Create Asset Type" disabled={!(state?.symbol?.length > 0 && state?.description?.length > 0)} />
  </Form>
</Fragment>
);

CreateAssetType.propTypes = {
  assetTypes: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      description: PropTypes.string,
      isFiat: PropTypes.bool,
    }),
  ),
  assetTypesLoading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

CreateAssetType.defaultProps = {
  assetTypes: [],
  assetTypesLoading: false,
};

export default CreateAssetType;
