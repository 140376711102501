import React, { Fragment, PureComponent } from 'react';
import { Button, Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { title } from 'change-case';
import ExternalTable from '../../common/table/ExternalTable';

class ProductsWithNestedContracts extends PureComponent {
  showProductModal = (updateProps = {}) => {
    const { productModalProps, hideModal, showModal } = this.props;
    showModal(productModalProps.modalType, {
      hideModal,
      ...productModalProps,
      ...updateProps,
    });
  };

  showAddContractModal = ({ delivery, symbol }) => {
    const { contractModalProps, hideModal, showModal } = this.props;
    showModal(contractModalProps.modalType, {
      confirmAction: this.confirmAction,
      hideModal,
      productSymbol: symbol,
      productDelivery: delivery,
      ...contractModalProps,
    });
  };

  render = () => {
    const { header, productsList, subheader, subtableProps, tableProps, type } =
      this.props;
    return (
      <Fragment>
        <div className="flex-row space-between">
          <Header as="h1">{header || `${title(type)} Products`}</Header>
          <Button
            floated="right"
            className="add-button"
            onClick={() => this.showProductModal()}
            size="small"
          >
            <Icon name="add" />
            Create {title(type)} Product
          </Button>
        </div>
        <Header.Subheader className="flex-row space-between">
          {subheader ||
            `Expand a ${type} product to view its associated contracts.`}
        </Header.Subheader>
        <ExternalTable
          columns={tableProps.metadata({
            showAddContractModal: this.showAddContractModal,
            showProductModal: this.showProductModal,
          })}
          className="-striped with-subtable"
          data={productsList}
          pageSize={10}
          showPagination
          title={`${type}Products`}
          SubComponent={({ original }) => (
            <ExternalTable
              className="subtable no-stripe"
              title={`${type}Contracts`}
              showPagination
              count={subtableProps.count}
              {...subtableProps}
              columns={subtableProps.metadata(original)}
              data={subtableProps.data}
              noDataText="No contracts were found for this product."
            />
          )}
          {...tableProps}
        />
      </Fragment>
    );
  };
}

ProductsWithNestedContracts.propTypes = {
  contractModalProps: PropTypes.objectOf(
    PropTypes.shape({
      createContract: PropTypes.func,
      modalType: PropTypes.string,
    }),
  ).isRequired,
  filterContractsBySymbol: PropTypes.func.isRequired,
  header: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  productsList: PropTypes.arrayOf(PropTypes.object),
  productModalProps: PropTypes.objectOf(
    PropTypes.shape({
      assetTypesOptions: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          value: PropTypes.string,
          text: PropTypes.string,
        }),
      ),
      createProduct: PropTypes.func,
      modalType: PropTypes.string,
      updateProduct: PropTypes.func,
    }),
  ).isRequired,
  showModal: PropTypes.func.isRequired,
  subheader: PropTypes.string,
  type: PropTypes.string.isRequired,
};

ProductsWithNestedContracts.defaultProps = {
  header: '',
  productsList: [],
  subheader: '',
};

export default ProductsWithNestedContracts;
