import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  collateralContracts,
  collateralProducts,
  createCollateralProduct,
  createCollateralContract,
  createCollateralDepositJournal,
  createCollateralWithdrawalJournal,
  updateCollateralProduct,
} from '../reducers/collateralReducer';

const createCollateralProductRequest = createSaga(
  createCollateralProduct,
  'CREATE_COLLATERAL_PRODUCT',
  'Creating collateral product...',
  'Collateral product created.',
);

const createCollateralContractRequest = createSaga(
  createCollateralContract,
  'CREATE_COLLATERAL',
  'Creating collateral contract...',
  'Approval Request for collateral contract created.',
);

const collateralContractsRequest = createSaga(
  collateralContracts,
  'COLLATERAL',
);

const collateralProductsRequest = createSaga(
  collateralProducts,
  'COLLATERAL_PRODUCTS',
);

const createCollateralDepositJournalRequest = createSaga(
  createCollateralDepositJournal,
  'CREATE_COLLATERAL_DEPOSIT_JOURNAL',
  'Attempting to deposit collateral...',
  'Collateral deposit journal created.',
);

const createCollateralWithdrawalJournalRequest = createSaga(
  createCollateralWithdrawalJournal,
  'CREATE_COLLATERAL_WITHDRAWAL_JOURNAL',
  'Attempting to withdraw collateral...',
  'Collateral withdrawal journal created.',
);

export default function* watchCollateral() {
  yield takeLatest(
    createCollateralProduct.TRIGGER,
    createCollateralProductRequest,
  );
  yield takeLatest(
    createCollateralContract.TRIGGER,
    createCollateralContractRequest,
  );
  yield takeLatest(collateralContracts.TRIGGER, collateralContractsRequest);
  yield takeLatest(collateralProducts.TRIGGER, collateralProductsRequest);
  yield takeLatest(
    createCollateralDepositJournal.TRIGGER,
    createCollateralDepositJournalRequest,
  );
  yield takeLatest(
    createCollateralWithdrawalJournal.TRIGGER,
    createCollateralWithdrawalJournalRequest,
  );
  yield takeLatest(
    updateCollateralProduct.TRIGGER,
    createSaga(
      updateCollateralProduct,
      'UPDATE_PRODUCT',
      'Updating collateral product...',
      'Collateral product updated.',
    ),
  );
}
