import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Product',
    id: 'product',
    accessor: 'product',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Scenario',
    id: 'scenario',
    accessor: 'scenario',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Scenario Name',
    id: 'scenarioName',
    accessor: 'scenarioName',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Scenario Type',
    id: 'scenarioType',
    accessor: 'scenarioType',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Target Portfolio',
    id: 'targetPortfolio',
    accessor: 'targetPortfolio',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Expiration Order',
    id: 'expirationOrder',
    accessor: 'expirationOrder',
    required: true,
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 150,
  },
  {
    Header: 'Shock Value',
    id: 'shockValue',
    accessor: 'shockValue',
    required: true,
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 150,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
