import sortBy from 'lodash/sortBy';
import React, { Fragment, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { positionSummaryPromiseCreator } from '../../actions/accountsActions';
import { getPositionsSummary } from '../../reducers/balancesReducer';
import { marginPositionsContractMetadata } from '../../metadata/marginPositionsContractMetadata';
import XTable7 from '../../common/table/XTable7';

const DcoMarginPositionsContainer = () => {
  const dispatch = useDispatch();
  const positionSummaryList = useSelector(getPositionsSummary);
  const [fetchDataLoading, setFetchDataLoading] = useState(false);

  const sortedPositionSummaryList = useMemo(() => {
    // Sort by Product, Maturity Date, Account, CGM
    const result = sortBy(positionSummaryList, ['productCode', 'maturityDate']);
    for (const row of result) {
      const subRow = row.subRows;
      for (const row2 of subRow) {
        const subRow2 = row2.subRows;
        row2.subRows = sortBy(subRow2, 'cgm');
      }
      row.subRows = sortBy(subRow, 'account');
    }
    return result;
  }, [positionSummaryList]);

  const fetchPositions = (fetchDataLoading) => {
    if (!fetchDataLoading) {
      return;
    }

    setFetchDataLoading(true);

    positionSummaryPromiseCreator(undefined, dispatch).then(() => {
      setFetchDataLoading(false);
    });
  };

  return (
    <Fragment>
      <div className="flex-row space-between">
        <Header as="h1">Margin Positions</Header>
      </div>
      <XTable7
        metadata={marginPositionsContractMetadata()}
        data={sortedPositionSummaryList}
        fetchData={() => {
          setFetchDataLoading(true);
          fetchPositions(true);
        }}
        loading={fetchDataLoading}
        showHeader={true}
        showPagination={true}
      ></XTable7>
    </Fragment>
  );
};

export default DcoMarginPositionsContainer;
