const entitlements = [
  'allowSpot',
  'allowFutures',
  'allowSpotBlock',
  'allowFuturesBlock',
  'allowCollateral',
  'allowFuturesMargin',
  'allowSettlementService',
  'allowFuturesMarginNonFcm',
];
export const entitlementDetails = [
  {
    value: 'allowSpot',
    name: 'Spot',
    onboardingDates: true,
  },
  {
    value: 'allowFutures',
    name: 'Futures',
    label: 'Allow Cboe Digital Fully Funded Futures',
    onboardingDates: true,
  },
  {
    value: 'allowFuturesBlock',
    name: 'Futures Block',
    onboardingDates: true,
  },
  ,
  {
    value: 'allowFuturesMargin',
    name: 'Futures Margin',
    onboardingDates: true,
  },
  {
    value: 'allowSpotBlock',
    name: 'Spot Block',
    onboardingDates: true,
  },
  {
    value: 'allowCollateral',
    name: 'Collateral',
    onboardingDates: true,
  },
  {
    value: 'allowSettlementService',
    name: 'Settlement Service',
    onboardingDates: true,
  },
  {
    value: 'allowFuturesMarginNonFcm',
    name: 'Futures Margin Non-FCM',
    onboardingDates: true,
  },
  {
    value: 'allowMarginFuturesBlock',
    name: 'Margin Futures Block Trades',
    onboardingDates: true,
  },
];
export default entitlements;
