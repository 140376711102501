import { CAR } from '../CAR.model';

export type ParticipantCAR = Omit<
  CAR,
  'accountLabel' | 'positionNettingEnabled'
>;

export type TradingParticipantCGMs = { [key: string]: ParticipantCAR[] };

// Payload Structure

export type ParticipantCGM = {
  cgmNumber: string;
  cgmType: string;
  description: string;
  tradingFirmMemberId: string;
  cars: ParticipantCAR[];
};

export type ParticipantFCM = {
  fcmName: string;
  cgmsLinkedToParticipant: ParticipantCGM[];
  carsLinkedToParticipant: ParticipantCAR[];
  participantEnabledForBlockTrades: boolean;
};

export type TradingParticipant = {
  participantId: string;
  participantName: string;
  participantFirmCode: string;
  allowFuturesBlock: boolean;
  fcms: ParticipantFCM[];
};

// Grid Rows

export type CARRow = {
  carName: string;
};

export type CGMRow = {
  cgmName: string;
  cgmType: string;
  cgmDescription: string;
  subRows?: CARRow[];
};

export type FCMRow = {
  fcmName: string;
  subRows?: CGMRow[];
};
export type ParticipantRow = TradingParticipant & { subRows?: CGMRow[] };

export type ApprovedParticipantTableRow = CGMRow | CARRow | FCMRow;

export type ParticipantManagementTableRow = ParticipantRow | CGMRow | CARRow;

export const isFCM = (row: ApprovedParticipantTableRow): row is FCMRow =>
  'fcmName' in row;

export const isCGM = (row: ApprovedParticipantTableRow): row is CGMRow =>
  'cgmName' in row;

export const isCAR = (row: ApprovedParticipantTableRow): row is CARRow =>
  'carName' in row;

export const isParticipant = (
  row: ParticipantManagementTableRow,
): row is ParticipantRow => 'participantName' in row;
