import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { MEMBERS } from '../../constants/actionTypes';
import {
  getMemberListOptions,
  fetchMembers,
} from '../../reducers/membersReducer';
import SearchSelect from '../../common/components/SearchSelect';

/** Container over SearcheableSelect that allows for searching Members.
 * Props are spread so to provide the input prop to the underlying form
 */

const SearcheableMemberSelect = ({ name, initialFilter = [], ...rest }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    createLoadingSelector([MEMBERS])(state),
  );
  const members = useSelector(getMemberListOptions);

  useEffect(
    () => dispatch(fetchMembers({ limit: 10, filter: initialFilter })),
    [],
  );
  return (
    <>
      <label style={{ fontWeight: 'bold' }}>Members</label>
      <SearchSelect
        fetch={fetchMembers}
        loading={loading}
        options={members}
        multiple
        searchAttr="name"
        name={name}
        {...rest}
        searchText="Search for a Member User"
        placeholder="Enter Member name"
      />
    </>
  );
};

export default SearcheableMemberSelect;
