import React, { Fragment, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Form, Modal, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderCheckbox, renderField } from 'erisxkit/client';
import AddAdditionalSsi from './AddAdditionalSsi';

// Must define this outside of Field component.
// See: https://github.com/erikras/redux-form/issues/2629#issuecomment-423264455

const AddBankAccount = ({
  hideModal,
  confirm,
  valid,
  env,
  change,
  isAdd = true,
}) => {
  const [showAdditionalSsi, setShowAdditionalSsi] = useState(false);
  const [isSenAccount, setIsSenAccount] = useState(false);
  const [isSignetAccount, setIsSignetAccount] = useState(false);

  const senAccountSelected = (value) => {
    setIsSenAccount(value);
    if (value) {
      setIsSignetAccount(!value);
    }
    change(
      'routingNumber',
      value ? get(env, 'silvergateRoutingNumber', '') : '',
    );
    change('isSignetAccount', false);
    change('bankName', '');
  };

  const SIGNETSelected = (value) => {
    setIsSignetAccount(value);
    if (value) {
      setIsSenAccount(!value);
    }
    change('routingNumber', 'signet_account');
    change('isSenAccount', false);
    change('bankName', value ? 'Signature Bank' : '');
  };

  return (
    <Fragment>
      <Modal.Header>{isAdd ? 'Add' : 'Edit'} Bank Account</Modal.Header>
      <Modal.Content>
        <Form>
          <div style={{ display: 'flex' }}>
            <Field
              label="SEN Account"
              component={renderCheckbox}
              name="isSenAccount"
              value={isSenAccount}
              onChange={(e) => {
                senAccountSelected(e);
              }}
              disabled={isSignetAccount}
            />
            <div style={{ marginLeft: '20px' }}>
              <Field
                label="SIGNET"
                component={renderCheckbox}
                name="isSignetAccount"
                value={isSignetAccount}
                onChange={(e) => {
                  SIGNETSelected(e);
                }}
                disabled={isSenAccount}
              />
            </div>
          </div>
          <Form.Group widths="equal">
            <Field
              label="Label"
              component={renderField}
              placeholder="Enter a name for this address..."
              type="text"
              name="accountLabel"
            />
            <Field
              label="Bank Name"
              component={renderField}
              placeholder="Enter bank name..."
              type="text"
              name="bankName"
            />
            <Field
              label={isSignetAccount ? 'Signet Address' : 'Bank Account Number'}
              component={renderField}
              placeholder={
                isSignetAccount
                  ? 'Enter Signet Address...'
                  : 'Enter bank account number...'
              }
              type="text"
              name="accountNumber"
            />
            <Field
              label="Bank Routing Number"
              component={renderField}
              placeholder="Enter routing number ..."
              type="text"
              name="routingNumber"
              disabled={isSenAccount || isSignetAccount}
            />
          </Form.Group>
          {isAdd && (
            <Button
              id="info"
              onClick={() => setShowAdditionalSsi(!showAdditionalSsi)}
              className="link"
            >
              {showAdditionalSsi ? 'Hide' : '+ Add Intermediary Bank'}
            </Button>
          )}
          {(showAdditionalSsi || !isAdd) && <AddAdditionalSsi isAdd={isAdd} />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button id="cancel" color="red" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          id="confirm"
          color="green"
          disabled={!valid}
          onClick={() => confirm('bank')}
        >
          {isAdd ? 'Confirm' : 'Save'}
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

AddBankAccount.propTypes = {
  confirm: PropTypes.func,
  hideModal: PropTypes.func,
  valid: PropTypes.bool,
  change: PropTypes.func,
  env: PropTypes.object,
};

AddBankAccount.defaultProps = {
  confirm: () => {},
  hideModal: () => {},
  valid: false,
  change: () => {},
  env: {},
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'add_linked_account',
  })(AddBankAccount),
);

function mapStateToProps(state, ownProps) {
  if (ownProps.isAdd) {
    return;
  } else {
    return {
      initialValues: {
        accountLabel: ownProps.linkedAccount.label,
        bankName: ownProps.linkedAccount.bankName,
        accountNumber: ownProps.linkedAccount.accountNumber,
        routingNumber: ownProps.linkedAccount.routingNumber,
        intermediaryAccountNumber:
          ownProps.linkedAccount.intermediaryAccountNumber,
        intermediaryBankName: ownProps.linkedAccount.intermediaryBankName,
        intermediaryBankAddress: ownProps.linkedAccount.intermediaryBankAddress,
        intermediaryRoutingNumber:
          ownProps.linkedAccount.intermediaryRoutingNumber,
        additionalSsi: ownProps.linkedAccount.additionalSsi,
        isSenAccount: ownProps.linkedAccount.isSenAccount,
      },
    };
  }
}
