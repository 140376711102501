import { connect } from 'react-redux';
import _ from 'lodash';

export const checkRoles = (userRoles, allowedRoles) => {
  if (allowedRoles.length === 0) {
    return true;
  }
  return userRoles.some((item) => allowedRoles.includes(item));
};

const RolesAccessControl = ({
  userRoles,
  allowedRoles,
  children,
  renderNoAccess,
}) => {
  const permitted = checkRoles(userRoles, allowedRoles);
  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

RolesAccessControl.defaultProps = {
  allowedRoles: [],
  userRoles: [],
  renderNoAccess: () => null,
};

// Compose RolesAccessControl component with redux

export default connect((state) => ({
  userRoles: _.get(state, ['users', 'currentUser', 'roles'], []),
}))(RolesAccessControl);
