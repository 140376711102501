import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import baseMarginRatesMetadata from '../../metadata/baseMarginRatesMetadata';
import { Button } from 'semantic-ui-react';
import {
  fetchBaseMarginRates,
  getBaseMarginRates,
  getMarginRateInfoDate,
  createAppliedMarginRatesPromiseCreator,
} from '../../reducers/marginRatesReducer';
import XTable7 from '../../common/table/XTable7';
import { MARGIN_RATES } from '../../constants/modalTypes';
import { PENDING } from '../../constants/approvalReqStates';
import { formatTimeLocal } from '../../utils/time';
import { getCurrentUser } from '../../reducers/usersReducer';
import {
  fetchApprovalReqsMethod,
  isPreviousDateSelected,
  drawApprovers,
} from './commonMethods';
import { getApprovalReqsList } from '../../reducers/approvalReqsReducer';
import { CREATE_APPLIED_MARGIN_RATES } from '../../constants/actionTypes';

const BaseMarginRates = () => {
  const dispatch = useDispatch();
  const baseMarginRatesLoading = useSelector(
    createLoadingSelector([fetchBaseMarginRates._PREFIX]),
  );
  const baseMarginRates = useSelector(getBaseMarginRates);
  const marginRateInfoDate = useSelector(getMarginRateInfoDate);
  const approvalReqs = useSelector(getApprovalReqsList(PENDING));
  const user = useSelector(getCurrentUser);

  const [arrOnEdit, setArrOnEdit] = useState([]);
  const [fieldEdited, setFieldEdited] = useState();
  const [mainData, setMainData] = useState([]);
  const [isResetStatus, setIsResetStatus] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  React.useEffect(() => {
    dispatch(fetchBaseMarginRates({ date: marginRateInfoDate }));
  }, [marginRateInfoDate]);

  React.useEffect(() => {
    //When approvalReqUuid is available, fetch the approvers of the margin rate
    if (baseMarginRates?.approvalReqUuid) {
      dispatch(fetchApprovalReqsMethod(baseMarginRates?.approvalReqUuid));
    }
    setMainData(baseMarginRates?.baseMarginRates || []);
    //If State is null, override and comments columns are editable
    if (
      !baseMarginRates?.state ||
      baseMarginRates?.state === 'approved' ||
      baseMarginRates?.state === 'rejected'
    ) {
      setIsResetStatus(true);
    }
    if (baseMarginRates?.state === PENDING) {
      setIsResetStatus(false);
    }
  }, [baseMarginRates]);

  const fetchData = (e) => {};

  const handleReset = () => {
    setMainData(baseMarginRates?.baseMarginRates || []);
    setIsDirty(false);
    setFieldEdited(undefined);
  };

  const handleApply = async () => {
    try {
      const args = {
        margin_rates: mainData,
      };
      await createAppliedMarginRatesPromiseCreator(args, dispatch);
      dispatch(hideModal(MARGIN_RATES));
      dispatch(fetchBaseMarginRates({ date: marginRateInfoDate }));
    } catch (error) {
      console.log('Error on reset margin rates');
    }
  };

  const showModalButtons = () => {
    dispatch(
      showModal(MARGIN_RATES, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(MARGIN_RATES)),
        updateStatus: handleApply,
        action: 'Confirm',
        date:
          mainData.length > 0
            ? formatTimeLocal(mainData[0].tailRiskCreatedAt)
            : '',
        initiator: user,
        title: 'Apply Margin Rates',
        message: 'You will submit an approval request to apply margin rates',
        loadingSelector: CREATE_APPLIED_MARGIN_RATES,
      }),
    );
  };

  const onEditMetadata = (row, field) => {
    setArrOnEdit([row]);
    setFieldEdited(field);
  };

  const onFocusOut = () => {
    setFieldEdited(undefined);
  };

  //When
  const setTempOverrideMarginValue = (row, value) => {
    //Search in mainData the row to edit its OverrrideMargin prop
    const result = mainData.map((x) =>
      x.product === row?.product &&
      x.expirationFront === row?.expirationFront &&
      x.expirationBack === row?.expirationBack
        ? { ...x, overrideMarginRateUsd: value }
        : x,
    );
    setMainData(result);
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const setTempCommentValue = (row, value) => {
    //Search in mainData the row to edit its OverrrideMargin prop
    const result = mainData.map((x) =>
      x.product === row?.product &&
      x.expirationFront === row?.expirationFront &&
      x.expirationBack === row?.expirationBack
        ? { ...x, comment: value }
        : x,
    );
    setMainData(result);
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  return (
    <Fragment>
      <div className="topHeader">
        <div className="marginRateMainTab"></div>
        <div className="alignRight">
          <Button
            className="add-button resetBtn"
            id="resetRates"
            onClick={() => handleReset()}
            disabled={
              isPreviousDateSelected(marginRateInfoDate) ||
              mainData.length === 0 ||
              baseMarginRates?.state === PENDING ||
              !isDirty
            }
          >
            Reset
          </Button>
          <Button
            className="add-button"
            id="applyRates"
            onClick={() => showModalButtons()}
            disabled={
              isPreviousDateSelected(marginRateInfoDate) ||
              mainData.length === 0 ||
              baseMarginRates?.state === PENDING
            }
          >
            Apply Rates
          </Button>
        </div>
      </div>
      <XTable7
        metadata={baseMarginRatesMetadata(
          onEditMetadata,
          setTempOverrideMarginValue,
          setTempCommentValue,
          isResetStatus && !isPreviousDateSelected(marginRateInfoDate),
          arrOnEdit,
          fieldEdited,
          onFocusOut,
        )}
        data={mainData}
        fetchData={fetchData}
        loading={baseMarginRatesLoading}
        count={baseMarginRates?.count}
        showPagination={false}
        localSortingAndFiltering={true}
      />
    </Fragment>
  );
};

BaseMarginRates.propTypes = {
  count: PropTypes.number,
  fetchBaseMarginRates: PropTypes.func.isRequired,
  baseMarginRatesLoading: PropTypes.bool,
  user: PropTypes.object,
  applyMarginRatesPromiseCreator: PropTypes.func.isRequired,
  rejectMarginRatesPromiseCreator: PropTypes.func.isRequired,
};

BaseMarginRates.defaultProps = {
  count: 0,
};

export default BaseMarginRates;
