import React, { Fragment, useState } from 'react';
import { isEmpty, get, capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Modal, Segment, Button, Checkbox, Label } from 'semantic-ui-react';
import JSONTree from 'react-json-tree';
import ExpandCollapseButton from '../common/components/ExpandCollapseButton';
import DetailItem from '../common/components/DetailItem';
import { formatDateLocal } from '../utils/time';

const ParsedReportModal = ({ report, onClose }) => {
  const [expandAll, setExpandAll] = useState(false);
  const positionMntReqs = [
    ...get(report, 'pcsPositionMntReqs', []),
    ...get(report, 'cgmFilePositionMntReqs', []),
  ];
  const parsed = positionMntReqs.reduce((prev, curr, i) => {
    prev[`position_mnt_req_${i + 1}`] = curr;
    return prev;
  }, {});
  const filename = get(report, 'fileName', '');
  const status = get(report, 'status', '');
  const dateReceived = get(report, 'receivedTime', '');
  const dateProcessed = get(report, 'processedTime', '');

  return (
    <Fragment>
      <Modal.Header>{`Parsed Report: ${filename}`}</Modal.Header>
      <Modal.Description className="fcm-parsed-report-desc">
        <Segment
          className="flex-row space-between"
          style={{ justifyContent: 'space-around' }}
        >
          <DetailItem
            title="Received Date"
            text={formatDateLocal(dateReceived)}
          />
          <DetailItem
            title="Processed Date"
            text={formatDateLocal(dateProcessed)}
          />
          <DetailItem title="Status" text={capitalize(status)} />
        </Segment>
        <div className="fcm-report-details">
          <h3>Details:</h3>
          <ExpandCollapseButton
            isExpanded={expandAll}
            onClick={() => setExpandAll(!expandAll)}
          />
        </div>
      </Modal.Description>
      <Modal.Content scrolling>
        <JSONTree data={parsed} hideRoot shouldExpandNode={() => expandAll} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} content="Close" />
      </Modal.Actions>
    </Fragment>
  );
};

ParsedReportModal.defaultProps = {
  report: {
    processedTime: '',
    receivedTime: '',
    fileName: '',
    status: '',
    origin: '',
    firmCode: '',
    pcsPositionMntReqs: {},
    cgmPositionMntReqs: {},
  },
  onClose: () => {},
};

ParsedReportModal.propTypes = {
  report: PropTypes.shape({
    processedTime: PropTypes.string,
    receivedTime: PropTypes.string,
    fileName: PropTypes.string,
    status: PropTypes.string,
    origin: PropTypes.string,
    firmCode: PropTypes.string,
    pcsPositionMntReqs: PropTypes.object,
    cgmPositionMntReqs: PropTypes.object,
  }),
  onClose: PropTypes.func,
};

export default ParsedReportModal;
