import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import RunTasks from '../../components/SystemLogs/RunTasks';
import {
  fetchTaskInfo,
  getActiveTaskLog,
  getTaskNames,
  getTaskOptionsByName,
  triggerTask,
} from '../../reducers/taskLogReducer';
import { getSelectorAsOptions } from '../../selectors';
import { formatTask } from '../../utils/methods';
import { TASK_INFO, TASK_LOG } from '../../constants/actionTypes';

const mapDispatchToProps = {
  fetchTaskInfo,
  triggerTask,
};

const selector = formValueSelector('taskTrigger');

const mapStateToProps = (state) => ({
  name: selector(state, 'name'),
  options: selector(state, 'options'),
  selectedTaskOptions: getTaskOptionsByName(selector(state, 'name'))(state),
  taskInfoLoading: createLoadingSelector([TASK_INFO])(state),
  taskLogsLoading: createLoadingSelector([TASK_LOG])(state),
  taskLogs: getActiveTaskLog(state),
  taskNameDropdownOptions: getSelectorAsOptions(getTaskNames, {
    key: 'name',
    value: 'name',
    text: 'name',
  })(state),
});

class RunTasksContainer extends PureComponent {
  state = { showDebug: false };

  componentDidMount = () => {
    this.props.fetchTaskInfo();
  };

  triggerTask = ({ name, options = '' }) => {
    this.props.triggerTask({ name, options: options.trim() });
  };

  render = () => (
    <RunTasks
      name={this.props.name}
      options={this.props.options}
      selectedTaskOptions={formatTask(this.props.selectedTaskOptions)}
      showDebug={this.state.showDebug}
      taskInfoLoading={this.props.taskInfoLoading}
      taskLogsLoading={this.props.taskLogsLoading}
      taskLogs={this.props.taskLogs}
      taskNameDropdownOptions={this.props.taskNameDropdownOptions}
      toggleDebug={() => this.setState({ showDebug: !this.state.showDebug })}
      triggerTask={this.triggerTask}
    />
  );
}

RunTasksContainer.propTypes = {
  fetchTaskInfo: PropTypes.func.isRequired,
  taskInfoLoading: PropTypes.bool,
  taskNameDropdownOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  taskOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  taskLogsLoading: PropTypes.bool,
  triggerTask: PropTypes.func.isRequired,
};

RunTasksContainer.defaultProps = {
  taskInfoLoading: false,
  taskNameDropdownOptions: [],
  taskOptions: [],
  taskLogsLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(RunTasksContainer);
