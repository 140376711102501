import React from 'react';
import styled from 'styled-components';
import { Modal, Form, Button } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { hideModal } from 'erisxkit/client';
import { getActiveMemberFirmCode } from '../../../reducers/membersReducer';
import {
  addAuthorizedBlockTraderPromise,
  fetchAuthorizedBlockTraders,
} from '../../../reducers/blockTradePermissionsReducer';
import { AddAuthorizedBlockTraderRequest } from '../../../ts/models/BlockTrades/PermissionManagement.model';
import * as rules from '../../../common/forms/rules';
import { renderField } from '../../../common/forms/component';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

type Props = {
  valid: boolean;
};

const Container = styled.div`
  padding: 15px;
`;

const StyledForm = styled(Form)`
  margin: 1em 0px;
  display: flex;
  padding: 2em;
`;

const StyledField = styled(Field)`
  flex: 1 1 0;
  margin-right: 10px !important;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(Button)`
  width: 150px;
`;
const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const ADD_AUTHORIZED_TRADER_FORM = 'addAuthorizedTraderForm';

const AddAuthorizedTrader = ({ valid }: Props) => {
  const dispatch = useAppDispatch();
  const values: Partial<AddAuthorizedBlockTraderRequest> = useAppSelector(
    (state) => getFormValues(ADD_AUTHORIZED_TRADER_FORM)(state),
  );
  const firmCode = useAppSelector(getActiveMemberFirmCode);

  const onSubmit = () => {
    addAuthorizedBlockTraderPromise(
      {
        urlParams: {
          firmCode,
          authorizedTraderEmail: values.authorizedTraderEmail,
        },
      },
      dispatch,
    ).then(() => {
      dispatch(fetchAuthorizedBlockTraders({ urlParams: { firmCode } }));
      dispatch(hideModal());
    });
  };

  return (
    <Container>
      <Header>{`Add Authorized Trader`}</Header>
      <Modal.Content>
        <StyledForm>
          <StyledField
            component={renderField}
            name="authorizedTraderEmail"
            id="authorizedTraderEmail"
            type="text"
            label="Authorized Trader Email"
            placeholder="trader@company.com"
            style={{ height: '38px' }}
            minimal
            required
            validate={[rules.required]}
          />
        </StyledForm>
      </Modal.Content>
      <Actions>
        <Action onClick={() => dispatch(hideModal())}>Cancel</Action>
        <Action onClick={onSubmit} primary disabled={!valid}>
          Submit
        </Action>
      </Actions>
    </Container>
  );
};

export default reduxForm({
  form: ADD_AUTHORIZED_TRADER_FORM,
})(AddAuthorizedTrader);
