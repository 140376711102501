import { isEmpty, union, flatten, find } from 'lodash';
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Dropdown, Checkbox } from 'semantic-ui-react';
import {
  spotProducts,
  getSpotProducts,
} from '../../reducers/spotProductsReducer';
import {
  futuresProducts,
  getFuturesProducts,
} from '../../reducers/futuresProductReducer';
import { getSelectorAsOptions } from '../../selectors';
import { getContracts, contractSymbols } from '../../reducers/contractsReducer';
import DetailsHeader from '../components/DetailsHeader';
import moment from 'moment';

export const dividedOptions = (categories = []) =>
  flatten(
    categories.map((category) =>
      !isEmpty(category) && !category.hide
        ? union(
            [
              {
                key: category.key,
                as: () => (
                  <Dropdown.Header
                    key={category.key}
                    content={category.header}
                  />
                ),
              },
            ],
            [
              {
                key: `${category.key}-divider`,
                as: () => <Dropdown.Divider key={`${category.key}-divider`} />,
              },
            ],
            category.options,
          )
        : [],
    ),
  );

const mapStateToProps = (state = {}) => ({
  contractsLoading: createLoadingSelector([
    'FUTURES_CONTRACTS',
    'SPOT_PRODUCTS',
  ])(state),
  futuresProductsList: getFuturesProducts(state),
  spotProductsOptions: getSelectorAsOptions(getSpotProducts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
  })(state),
  contracts: getContracts(state),
});

const mapDispatchToProps = {
  spotProducts,
  futuresProducts,
  contractSymbols,
};

const renderContractDetails = (contract) => (
  <DetailsHeader item={contract} className="contract-details" compact />
);
class ProductContractSelection extends PureComponent {
  state = {
    product: '',
    contract: {},
    showExpiredContracts: false,
  };
  componentDidMount = () => {
    this.props.spotProducts();
    this.props.futuresProducts();
  };

  getFutureProductsAsOptions = () => {
    const { subExchangeId, futuresProductsList } = this.props;
    const filteredProducts = subExchangeId
      ? futuresProductsList.filter(
          (each) => each.subExchangeId === subExchangeId,
        )
      : futuresProductsList;
    return filteredProducts.map((each) => ({
      key: each.symbol,
      text: each.symbol,
      value: each.symbol,
    }));
  };

  productSelected = (e, { value }) => {
    const {
      contracts,
      onChange,
      contractSymbols,
      spotProductsOptions,
      futuresProductsList,
    } = this.props;
    let selContract;

    const filter = [{ attr: 'product_symbol', op: 'match', value }];
    if (
      !this.state.showExpiredContracts &&
      futuresProductsList.some((fp) => fp.symbol === value)
    ) {
      filter.push({
        attr: 'expiration_time',
        op: 'gte',
        value: moment().subtract(7, 'days').format(),
      });
    }
    contractSymbols({ filter });

    // if there's only one contract for a product, select it.
    const filteredContracts = contracts.filter(
      (contract) => contract.productSymbol === value,
    );
    if (filteredContracts.length === 1) {
      selContract = filteredContracts[0];
      onChange(e, {
        name: this.props.contractName || 'contractSymbol',
        value: selContract.symbol,
      });
    } else {
      onChange(e, {
        name: this.props.contractName || 'contractSymbol',
        value: '',
      });
    }

    if (this.props.productName) {
      onChange(e, { name: this.props.productName, value });
    }

    this.setState({
      product: value,
      contract: selContract,
    });
  };

  contractSelected = (e, { name, value }) => {
    const { onChange, contracts } = this.props;
    const contract = find(contracts, { symbol: value });
    this.setState({
      contract,
    });
    onChange(e, { name, value, product: this?.state?.product });
  };

  handleSearchChange = (e, { searchQuery, name }) => {
    const filter = [
      { attr: 'contract_code', op: 'contain', value: searchQuery },
    ];

    if (!this.state.showExpiredContracts) {
      filter.push({
        attr: 'expiration_time',
        op: 'gte',
        value: moment().subtract(7, 'days').format(),
      });
    }

    this.props.contractSymbols({ filter });
  };

  render() {
    const {
      contractsLoading,
      contracts,
      subExchangeId,
      hideSpot,
      hideFutures,
      spotProductsOptions,
      futuresProductsOptions,
      required,
      disabled,
    } = this.props;

    const contractOptions = contracts
      .filter((contract) => contract.productSymbol === this.state.product)
      .map((c) => ({
        key: c.symbol,
        text: c.contractCode || c.symbol,
        value: c.symbol,
      }));

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Form.Group>
          <Form.Field
            disabled={disabled}
            clearable
            control={Form.Select}
            id="product"
            label="Product"
            loading={contractsLoading}
            options={dividedOptions([
              {
                header: 'Spot Products',
                hide: hideSpot,
                options: spotProductsOptions,
                key: 'spot',
              },
              {
                header: 'Futures Products',
                hide: hideFutures,
                options: this.getFutureProductsAsOptions(),
                key: 'futures',
              },
            ])}
            name="product"
            noResultsMessage="No products found."
            onChange={this.productSelected}
            placeholder="Select a product."
            search
            selectOnBlur={false}
            required={required}
            upward={this.props.upward}
          />
          <Form.Field
            onSearchChange={this.handleSearchChange}
            disabled={!this.state.product}
            clearable
            control={Form.Select}
            id="contract"
            label="Contract"
            loading={contractsLoading}
            options={contractOptions}
            name={this.props.contractName || 'contractSymbol'}
            noResultsMessage="No contracts found."
            onChange={this.contractSelected}
            value={this.state.contract && this.state.contract.symbol}
            placeholder="Select a contract."
            selectOnBlur={false}
            search
            required={required}
            upward={this.props.upward}
          />
        </Form.Group>
        <Checkbox
          className="show-expired"
          label="Show expired contracts"
          checked={this.state.showExpiredContracts}
          onChange={() =>
            this.setState({
              showExpiredContracts: !this.state.showExpiredContracts,
            })
          }
        />
        {!isEmpty(this.state.contract) && this.props.showDetails && (
          <>
            {renderContractDetails(this.state.contract)}
            <br />
          </>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductContractSelection);
