import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header, Form, Input, Button, Modal } from 'semantic-ui-react';

const CreateAdminKey = ({ handleChange, handleSubmit }) => (
  <Fragment>
    <Modal.Content>
      <Header as="h1" dividing>
        Set up Admin Key
      </Header>
      <Form>
        <Form.Field
          placeholder="Enter admin key:"
          labelPosition="left"
          name="password"
          id="password"
          type="password"
          control={Input}
          onChange={handleChange}
          required
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button fluid primary className="submit" onClick={handleSubmit}>
        Submit
      </Button>
    </Modal.Actions>
  </Fragment>
);

CreateAdminKey.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CreateAdminKey;
