import React from 'react';
import { get } from 'lodash';
import { Dropdown, Label } from 'semantic-ui-react';
import { iconColorForState } from '../utils/methods';
import { formatTimeLocal } from '../utils/time';

export default (override, remove) => [
  {
    Header: 'Label',
    accessor: 'label',
    id: 'label',
  },
  {
    Header: 'Counterparty ABA',
    accessor: 'counterpartyAba',
    id: 'counterpartyAba',
  },
  {
    Header: 'Counterparty/Customer',
    accessor: 'counterpartyCustomer',
    id: 'counterpartyCustomer',
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    id: 'comment',
  },
  {
    Header: 'Created At',
    accessor: (row) => formatTimeLocal(get(row, 'createdAt')),
    id: 'createdAt',
  },
  {
    Header: 'Usable At',
    accessor: (row) => formatTimeLocal(get(row, 'usableAt')),
    id: 'usableAt',
  },
  {
    Header: 'Status',
    accessor: (row) => (
      <Label
        color={iconColorForState(get(row, 'state'))}
        content={get(row, 'state')}
      />
    ),
    id: 'state',
  },
  {
    Header: '',
    id: 'more',
    accessor: (d) => (
      <Dropdown icon="ellipsis vertical" pointing="top right">
        <Dropdown.Menu>
          <Dropdown.Item
            key="override"
            text="Override 72-hour Hold"
            onClick={() => override(d)}
            icon="time"
          />
          <Dropdown.Item
            key="remove"
            text="Delete SSI"
            onClick={() => remove(d)}
            icon="remove"
          />
        </Dropdown.Menu>
      </Dropdown>
    ),
    filterable: false,
    resizable: false,
    width: 35,
  },
];
