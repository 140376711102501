import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindPromiseCreators } from 'redux-saga-routines';
import { Divider } from 'semantic-ui-react';
import {
  getSelectedUserId,
  memberSupportingDocPromiseCreator,
} from '../../reducers/usersReducer';
import { formatTimeLocal } from '../../utils/time';
import PdfViewer from '../../common/components/PdfViewer';

const mapStateToProps = (state) => ({
  selectedUserId: getSelectedUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      memberSupportingDocPromiseCreator,
    },
    dispatch,
  ),
});

class SupportingDocumentContainer extends Component {
  state = {};
  componentDidMount = () => {
    this.props
      .memberSupportingDocPromiseCreator({
        userId: this.props.userId,
      })
      .then((successPayload) => {
        this.setState(successPayload);
      });
  };

  render = () => (
    <div>
      {this.state.createdAt
        ? `Uploaded at ${formatTimeLocal(this.state.createdAt)}`
        : 'No supporting documents were uploaded by this user.'}
      <Divider />
      {this.state.image &&
        (this.state.image.split('/')[0].includes('image') ? (
          <img src={`data:${this.state.image}`} alt="supporting_doc" />
        ) : (
          <PdfViewer
            image={`data:${this.state.image}`}
            title="Supporting document"
          />
        ))}
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupportingDocumentContainer);
