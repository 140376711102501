import { handleActions } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import _ from 'lodash';
import { fetchEmarketAccountTypes } from '../actions/accountsActions';

//* Manual Onboarding actions */

// Currenex Actions
export const CREATE_AND_LINK_CLEARING_ACCOUNT =
  'CREATE_AND_LINK_CLEARING_ACCOUNT';
export const CREATE_EMARKET_ORGANIZATION = 'CREATE_EMARKET_ORGANIZATION';
export const CREATE_EMARKET_ACCOUNT = 'CREATE_EMARKET_ACCOUNT';
export const CREATE_CX_BALANCES = 'CREATE_CX_BALANCES';
export const CREATE_TRADE_CX_USER = 'CREATE_TRADE_CX_USER';
export const ROUTE_CX_USER_THROUGH_CQG = 'ROUTE_CX_USER_THROUGH_CQG';

// CQG Actions
export const CREATE_CQG_CUSTOMER = 'CREATE_CQG_CUSTOMER';
export const CREATE_CQG_TRADER = 'CREATE_CQG_TRADER';
export const CREATE_CQG_ACCOUNT = 'CREATE_CQG_ACCOUNT';
export const ADD_REQUIRED_CQG_SERVICES_TO_CQG_TRADER =
  'ADD_REQUIRED_CQG_SERVICES_TO_CQG_TRADER';
export const LINK_CQG_TRADER_TO_CQG_CUSTOMER =
  'LINK_CQG_TRADER_TO_CQG_CUSTOMER';
export const ADD_CQG_TRADER_TO_CQG_ACCOUNT = 'ADD_CQG_TRADER_TO_CQG_ACCOUNT';
export const ADD_CX_TRADER_ID_TO_CQG_TRADER = 'ADD_CX_TRADER_ID_TO_CQG_TRADER';
export const SEND_CQG_WELCOME_EMAIL = 'SEND_CQG_WELCOME_EMAIL';
export const SEND_EMAIL = 'SEND_EMAIL';

export const EMARKET_ACCOUNT_PROFILES = 'EMARKET_ACCOUNT_PROFILES';
export const EMARKET_FEE_PROFILES = 'EMARKET_FEE_PROFILES';
export const FEE_PROFILES = 'FEE_PROFILES';
export const EMARKET_INSTRUMENT_PERMISSION_PROFILES =
  'EMARKET_INSTRUMENT_PERMISSION_PROFILES';

export const MARGIN_PROFILES = 'MARGIN_PROFILES';
export const RISK_PROFILES = 'RISK_PROFILES';
/* Action generators */
export const fetchEmarketAccountProfiles = createRoutine(
  EMARKET_ACCOUNT_PROFILES,
);
export const fetchEmarketFeeProfiles = createRoutine(EMARKET_FEE_PROFILES);
export const fetchFeeProfiles = createRoutine(FEE_PROFILES);
export const fetchEmarketInstrumentPermissionProfiles = createRoutine(
  EMARKET_INSTRUMENT_PERMISSION_PROFILES,
);

//* Currenex actions */
/**
 * Action generator to create a ClearingAccount document and link it to a member
 * @param {string} userId
 */
export const createAndLinkClearingAccount = createRoutine(
  CREATE_AND_LINK_CLEARING_ACCOUNT,
);

/**
 * Action generator to create a Emarket organization
 * @param {string} accountId - member's linked ClearingAccount Id
 */
export const createEmarketOrganization = createRoutine(
  CREATE_EMARKET_ORGANIZATION,
);

/**
 * Action generator to create a Currenex account
 * @param {string} accountId - member's linked ClearingAccount Id
 */
export const createEmarketAccount = createRoutine(CREATE_EMARKET_ACCOUNT);

/**
 * Action generator to create a Currenex user associated to given Currenex account
 * @param {Object} user - {
 *  userId - partipant user ID
 *  accountId - member's linked ClearingAccount Id
 * }
 */
export const createTradeCxUser = createRoutine(CREATE_TRADE_CX_USER);

/**
 * Action generator to create Currenex account balances for a given Currenex account ID
 * @param {string} cxAccountId
 */
export const createCxBalances = createRoutine(CREATE_CX_BALANCES);

/**
 * Action generator to route a Currenex user through certain CQG IDs
 * @param {string} cxUserId - Currenex user ID
 */
export const routeCxUserThroughCQG = createRoutine(ROUTE_CX_USER_THROUGH_CQG);

//* CQG Actions */

/**
 * Action generator to create CQG customer
 * @param {string} email - member email
 */
export const createCqgCustomer = createRoutine(CREATE_CQG_CUSTOMER);

/**
 * Action generator to create CQG trader
 * @param {string} email - member email
 * @param {string} cqgCustomerId
 * @param {string} userId - Auth0 user
 */
export const createCqgTrader = createRoutine(CREATE_CQG_TRADER);

/**
 * Action generator to create CQG account
 * @param {string} cxAccountId - Currenex accountId
 * @param {string} cqgTraderId
 * @param {string} userId - Auth0 user
 * @param {string} accountId - member ID
 */
export const createCqgAccount = createRoutine(CREATE_CQG_ACCOUNT);

/**
 * Action generator to add required CQG services to CQG trader
 * @param {string} cqgTraderId
 */
export const addRequiredCqgServicesToCqgTrader = createRoutine(
  ADD_REQUIRED_CQG_SERVICES_TO_CQG_TRADER,
);

/**
 * Action generator to link CQG trader to CQG customer
 * @param {string} cqgTraderId
 * @param {string} cqgCustomerId
 */
export const linkCqgTraderToCqgCustomer = createRoutine(
  LINK_CQG_TRADER_TO_CQG_CUSTOMER,
);

/**
 * Action generator to add CQG trader to CQG account
 * @param {string} cqgAccountId
 * @param {string} cqgTraderId
 */
export const addCqgTraderToCqgAccount = createRoutine(
  ADD_CQG_TRADER_TO_CQG_ACCOUNT,
);

/**
 * Action generator to add Currenex trader id to CQG trader
 * @param {string} cqgTraderId
 * @param {string} cxTraderId
 */
export const addCxTraderIdToCqgTrader = createRoutine(
  ADD_CX_TRADER_ID_TO_CQG_TRADER,
);

/**
 * Action generator to send CQG welcome email
 * @param {string} cqgTraderId
 * @param {string} cqgCustomerId
 */
export const sendCqgWelcomeEmail = createRoutine(SEND_CQG_WELCOME_EMAIL);
export const sendEmail = createRoutine(SEND_EMAIL);

export const fetchMarginProfiles = createRoutine(MARGIN_PROFILES);
export const fetchRiskProfiles = createRoutine(RISK_PROFILES);

export default handleActions(
  {
    [createCqgCustomer.SUCCESS]: (state, action) => ({
      ...state,
      cqgCustomerId: action.payload.result.id,
    }),
    [createCqgTrader.SUCCESS]: (state, action) => ({
      ...state,
      cqgTraderId: action.payload.result.traderId,
    }),
    [createCqgAccount.SUCCESS]: (state, action) => ({
      ...state,
      cqgAccountId: action.payload.result.newAccountId,
    }),
    [fetchEmarketAccountProfiles.SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      emarketAccountProfiles: payload.response,
    }),
    [fetchEmarketFeeProfiles.SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      emarketFeeProfiles: payload.response,
    }),
    [fetchEmarketAccountTypes.SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      emarketAccountTypes: payload,
    }),
    [fetchEmarketInstrumentPermissionProfiles.SUCCESS]: (
      state,
      { payload = {} },
    ) => ({
      ...state,
      emarketInstrumentPermissionProfiles: payload.response,
    }),
    [fetchMarginProfiles.SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      marginProfiles: payload?.marginProfiles,
    }),
    [fetchRiskProfiles.SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      riskProfiles: payload?.riskProfiles,
    }),
    [fetchFeeProfiles.SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      feeProfiles: payload?.feeProfiles,
    }),
  },
  {},
);

//* Selectors */
export const getCqgCustomerId = (state) =>
  _.get(state, ['manualOnboarding', 'cqgCustomerId']);
export const getCqgTraderId = (state) =>
  _.get(state, ['manualOnboarding', 'cqgTraderId']);
export const getCqgAccountId = (state) =>
  _.get(state, ['manualOnboarding', 'cqgAccountId']);

export const getEmarketAccountProfiles = (state) =>
  _.get(state, ['manualOnboarding', 'emarketAccountProfiles']);
export const getEmarketFeeProfiles = (state) =>
  _.get(state, ['manualOnboarding', 'emarketFeeProfiles']);
export const getEmarketAccountTypes = (state) =>
  _.get(state, ['manualOnboarding', 'emarketAccountTypes']);
export const getEmarketInstrumentPermissionProfiles = (state) =>
  _.get(state, ['manualOnboarding', 'emarketInstrumentPermissionProfiles']);
export const getMarginProfiles = (state) =>
  _.get(state, ['manualOnboarding', 'marginProfiles']);
export const getRiskProfiles = (state) =>
  _.get(state, ['manualOnboarding', 'riskProfiles']);
export const getFeeProfiles = (state) =>
  _.get(state, ['manualOnboarding', 'feeProfiles']);
