import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import { connect } from 'react-redux';
import { getFormInitialValues } from 'redux-form';
import PropTypes from 'prop-types';
import { titleCase } from 'change-case';
import { createLoadingSelector } from 'erisxkit/client';
import {
  createCollateralProduct,
  createCollateralContract,
  collateralContracts,
  collateralProducts,
  getCollateralContracts,
  getCollateralProducts,
  updateCollateralProduct,
} from '../../reducers/collateralReducer';
import { updateContract } from '../../reducers/contractsReducer';
import collateralProductsMetadata from '../../metadata/collateralProductsMetadata';
import collateralContractsMetadata from '../../metadata/collateralContractsMetadata';
import { getFiatAssetTypesAsOptions } from '../../selectors';
import { showModal, hideModal } from 'erisxkit/client';
import {
  CONFIRM_ACTION_WITH_PAYLOAD,
  CREATE_COLLATERAL_CONTRACT,
  CREATE_COLLATERAL_PRODUCT,
} from '../../constants/modalTypes';
import ProductsWithNestedContracts from '../../common/components/ProductsWithNestedContracts';
import { shallowObjectDiff } from '../../utils/methods';

const mapStateToProps = (state) => ({
  assetTypesOptions: getFiatAssetTypesAsOptions(state),
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
  collateralProductsList: getCollateralProducts(state),
  collateralProductsLoading: createLoadingSelector(['COLLATERAL_PRODUCTS'])(
    state,
  ),
  collateralContractsList: getCollateralContracts(state),
  collateralContractsLoading: createLoadingSelector(['COLLATERAL_CONTRACTS'])(
    state,
  ),
  collateralContractInitialValues: getFormInitialValues(
    'CreateCollateralContract',
  )(state),
  collateralProductInitialValues: getFormInitialValues(
    'CreateCollateralProduct',
  )(state),
});

const mapDispatchToProps = {
  createCollateralProduct,
  createCollateralContract,
  collateralContracts,
  collateralProducts,
  hideModal,
  showModal,
  updateCollateralProduct,
  updateContract,
};

class CollateralProductsContainer extends PureComponent {
  componentWillMount = () => {
    this.props.collateralContracts();
    this.props.collateralProducts();
  };

  confirmAction =
    (documentType) =>
    (payload = {}) => {
      const {
        modalType,
        hideModal,
        showModal,
        collateralProductInitialValues,
        collateralContractInitialValues,
      } = this.props;
      const actionType = payload.type || 'create';
      const formPayload = omit(payload, ['type', 'actionType']);
      showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
        header: `Confirm ${titleCase(actionType)} Collateral ${titleCase(documentType)}`,
        msg: `${titleCase(actionType)} collateral ${documentType} with the following details?`,
        hideModal,
        onConfirm: () => {
          if (actionType === 'create') {
            if (documentType === 'contract') {
              this.props.createCollateralContract(formPayload);
            } else {
              this.props.createCollateralProduct(formPayload);
            }
          } else if (documentType === 'contract') {
            const update = shallowObjectDiff(
              formPayload,
              collateralContractInitialValues,
            );
            this.props.updateContract({
              contractSymbol: payload.symbol,
              update,
            });
          } else {
            const update = shallowObjectDiff(
              formPayload,
              collateralProductInitialValues,
            );
            this.props.updateCollateralProduct({
              productSymbol: payload.symbol,
              update,
            });
          }
          hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
          hideModal(modalType);
        },
        payload,
        action: `${titleCase(actionType)} collateral ${documentType}`,
      });
    };

  filterContractsBySymbol = ({ symbol }) =>
    this.props.collateralContractsList.filter(
      (contract) => contract.productSymbol === symbol,
    );

  render = () => (
    <ProductsWithNestedContracts
      contractModalProps={{
        confirmAction: this.confirmAction('contract'),
        modalType: CREATE_COLLATERAL_CONTRACT,
      }}
      createContract={this.props.createCollateralContract}
      createProduct={this.props.createCollateralProduct}
      filterContractsBySymbol={this.filterContractsBySymbol}
      hideModal={this.props.hideModal}
      productModalProps={{
        assetTypesLoading: this.props.assetTypesLoading,
        assetTypesOptions: this.props.assetTypesOptions,
        confirmAction: this.confirmAction('product'),
        modalType: CREATE_COLLATERAL_PRODUCT,
      }}
      tableProps={{
        metadata: collateralProductsMetadata,
        createContractModalType: CREATE_COLLATERAL_PRODUCT,
        data: this.props.collateralProductsList,
        loading: this.props.collateralProductsLoading,
      }}
      showModal={this.props.showModal}
      subtableProps={{
        metadata: collateralContractsMetadata({
          showModal: this.props.showModal,
          modalType: CREATE_COLLATERAL_CONTRACT,
          modalProps: {
            hideModal: this.props.hideModal,
            type: 'update',
            confirmAction: this.confirmAction('contract'),
          },
        }),
        loading: this.props.collateralContractsLoading,
      }}
      type="collateral"
    />
  );
}

CollateralProductsContainer.propTypes = {
  assetTypesLoading: PropTypes.bool,
  assetTypesOptions: PropTypes.arrayOf(PropTypes.object),
  createCollateralProduct: PropTypes.func.isRequired,
  collateralContracts: PropTypes.func.isRequired,
  collateralContractsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  collateralContractsLoading: PropTypes.bool,
  collateralProducts: PropTypes.func.isRequired,
  collateralProductsList: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  collateralProductsLoading: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

CollateralProductsContainer.defaultProps = {
  assetTypesLoading: false,
  assetTypesOptions: [],
  collateralContractsList: [],
  collateralContractsLoading: false,
  collateralProductsList: [],
  collateralProductsLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollateralProductsContainer);
