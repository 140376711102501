import { get } from 'lodash';
import React from 'react';
import { Label } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';
import { iconColorForState } from '../utils/methods';
import { getTransactionLink } from '../containers/ReconciliationContainer';
import { memberColumn, accountColumn } from '../common/table/commonMetadata';

const crypto_transaction_analyses = [
  {
    Header: 'Posted Time',
    id: 'postedTime',
    accessor: (row) => formatTimeLocal(row.postedTime),
  },
  memberColumn(),
  accountColumn(),
  {
    Header: 'Transaction',
    id: 'transactionHash',
    Cell: (d) => {
      const assetType = get(d, ['original', 'assetType'], '');
      const txLink = getTransactionLink(assetType.toUpperCase());

      return txLink ? (
        <a
          className="mono"
          target="_blank"
          href={`${txLink}${d.original.transactionHash}`}
        >
          {' '}
          {d.original.transactionHash}{' '}
        </a>
      ) : (
        <span>{d.original.transactionHash}</span>
      );
    },
    sortable: false,
  },
  {
    Header: 'Asset Type',
    id: 'assetType',
    accessor: 'assetType',
  },
  {
    Header: 'Address',
    id: 'address',
    accessor: 'address',
    sortable: false,
  },
  {
    Header: 'Risk Score',
    id: 'riskScore',
    accessor: 'riskScore',
  },
  {
    Header: 'Status',
    id: 'state',
    accessor: (d) => (
      <Label color={iconColorForState(d.state)} content={d.state} />
    ),
    className: 'pull-right',
    headerClassName: 'pull-right',
    resizable: false,
  },
];

export default crypto_transaction_analyses;
