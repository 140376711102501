import React from 'react';
import { compact, isNaN, get } from 'lodash';
import { formatTimeLocalWithDoW } from '../utils/time';
import { DayColumnFilter } from '../common/table/Filters';
import { formatAccountingValue, formatUsdValue } from '../utils/formatters';

const RightAlignCell = ({ value }) => (
  <div style={{ textAlign: 'right' }}>{value}</div>
);

export const marginPositionsContractMetadata = () =>
  compact([
    {
      Header: ' ',
      hideFilter: true,
      columns: [
        {
          // Build our expander column
          id: 'expander', // Make sure it has an ID
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}></span>
          ),
          hideFilter: true,
          Cell: ({ row }) => {
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            return row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                    color: row.depth ? 'SlateGrey' : 'black',
                  },
                  className: 'expandable',
                })}
              >
                {row.isExpanded ? '▼' : '►'}
              </span>
            ) : null;
          },
        },
      ],
    },
    {
      Header: ' ',
      hideFilter: true,
      columns: [
        {
          Header: 'Product Code',
          accessor: 'productCode',
          hideFilter: true,
        },
        {
          Header: 'Contract Code',
          accessor: 'contractCode',
          hideFilter: true,
        },
        {
          Header: 'Maturity Date',
          id: 'maturityDate',
          Filter: DayColumnFilter,
          accessor: (row) => formatTimeLocalWithDoW(row.maturityDate, true),
        },
        {
          Header: 'Account',
          accessor: 'account',
        },
        {
          Header: 'CGM',
          accessor: 'cgm',
        },
      ],
    },
    {
      Header: 'Start of Day (Gross)',
      hideFilter: true,
      columns: [
        {
          Header: 'Long',
          id: 'gLong',
          accessor: (row) => formatAccountingValue(get(row, 'gLong', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Short',
          id: 'gShort',
          accessor: (row) =>
            formatAccountingValue(get(row, 'gShort', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Net',
          id: 'gNet',
          accessor: (row) =>
            formatAccountingValue(isNaN(row.gNet) ? '' : row.gNet, true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
      ],
    },
    {
      Header: 'Start of Day (Net)',
      hideFilter: true,
      columns: [
        {
          Header: 'Long',
          id: 'nLong',
          accessor: (row) => formatAccountingValue(get(row, 'nLong', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Short',
          id: 'nShort',
          accessor: (row) =>
            formatAccountingValue(get(row, 'nShort', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Net',
          id: 'nNet',
          accessor: (row) =>
            formatAccountingValue(isNaN(row.nNet) ? '' : row.nNet, true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
      ],
    },
    {
      Header: 'Top Day',
      hideFilter: true,
      columns: [
        {
          Header: 'Long',
          id: 'tLong',
          accessor: (row) => formatAccountingValue(get(row, 'tLong', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Short',
          id: 'tShort',
          accessor: (row) =>
            formatAccountingValue(get(row, 'tShort', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Net',
          id: 'tNet',
          accessor: (row) =>
            formatAccountingValue(isNaN(row.tNet) ? '' : row.tNet, true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
      ],
    },
    {
      Header: 'Final',
      hideFilter: true,
      columns: [
        {
          Header: 'Long',
          id: 'fLong',
          accessor: (row) => formatAccountingValue(get(row, 'fLong', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Short',
          id: 'fShort',
          accessor: (row) =>
            formatAccountingValue(get(row, 'fShort', ''), true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Net',
          id: 'fNet',
          accessor: (row) =>
            formatAccountingValue(isNaN(row.fNet) ? '' : row.fNet, true),
          hideFilter: true,
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
      ],
    },
    {
      Header: ' ',
      hideFilter: true,
      columns: [
        {
          Header: 'Variation Margin',
          accessor: (row) => formatUsdValue(get(row, 'variationMargin', '')),
          Cell: (row) => <RightAlignCell value={row.value} />,
        },
        {
          Header: 'Asset',
          accessor: 'assetType',
        },
      ],
    },
  ]);
