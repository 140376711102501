import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useExpanded,
  useResizeColumns,
  useBlockLayout,
  useFlexLayout,
  useGroupBy,
  useRowState,
  useRowSelect,
} from 'react-table';
import styled from 'styled-components';
import { useExportData } from 'react-table-plugins';
import Papa from 'papaparse';
import {
  paramsToObject,
  filteredArrayToObject,
  showModal,
} from 'erisxkit/client';
import {
  reduce,
  findIndex,
  debounce,
  get,
  set,
  cloneDeep,
  isEqual,
  isEmpty,
  nth,
} from 'lodash';
import {
  Button,
  Popup,
  Table,
  Icon,
  Label,
  Input,
  FormField,
  Form,
  Loader,
  Checkbox,
} from 'semantic-ui-react';
import history from '../../constants/history';
import {
  addEditActions,
  dataHasInvalidRows,
  getFirstInvalidRow,
  isCreatedRow,
  isEditedCellValid,
  isEditedRow,
  getInvalidRows,
  getInvalidCells,
  getNewEditedRowsList,
  styles,
  isSubRow,
  getParentRowId,
} from './utils';
import EditableCell from './EditableCell';
import { getCurrentUser } from '../../reducers/usersReducer';
import {
  TABLE_INVALID_FIELDS,
  TABLE_REVIEW_CHANGES,
} from '../../constants/modalTypes';
import { TABLE_EDIT_ACTIONS } from './constants';
import GeneralFilters from './GeneralFilters';

const Cell = styled(Table.Cell)`
  background-color: ${({ showInvalidCells, isValid, isBeingEdited }) => {
    if (!showInvalidCells || isBeingEdited) return '';
    if (showInvalidCells && !isValid) return '#f3d6d6';
    return '';
  }};
`;
const DEFAULT_PAGE_SIZE = 40;
const COL_SPAN = 10000;
const PAGE_SIZES = [20, 40, 60, 80, 100];
const HEIGHT = '75vh';

// Merge 2 array objects by the prop passed, if the prop is the same in both, b has higher priority and overwrites a
function merge(a, b, prop) {
  const reduced = a.filter(
    (aItem) => !b.find((bItem) => aItem[prop] === bItem[prop]),
  );
  return reduced.concat(b);
}

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === 'csv') {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: 'text/csv' });
  }
  return false;
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows = [], setFilter },
}) {
  const [tempFilter, setTempFilter] = useState('');

  const handleApply = (filter) => {
    setFilter(filter || tempFilter);
  };

  const delayedApply = useCallback(
    debounce((filter) => {
      handleApply(filter);
    }, 1000),
    [],
  );

  return (
    <div>
      {filterValue ? (
        <Label>
          {filterValue}
          <Icon name="delete" onClick={() => setFilter('')} />
        </Label>
      ) : null}
      <input
        autoFocus
        value={tempFilter || ''}
        onChange={({ target = {} }) => {
          setTempFilter(target.value || undefined); // Set undefined to remove the filter entirely
          delayedApply(target.value);
        }}
        placeholder="Search records..."
        onKeyDown={(e) => e.key === 'Enter' && handleApply()}
      />
      <Button content="Apply" compact onClick={() => handleApply()} />
    </div>
  );
}

const updateURL = (filters) => {
  const urlParams = filteredArrayToObject(filters);
  const params = new URLSearchParams({});
  // over here we need to iterate over the url params and have custom params handling for arrays
  Object.keys(urlParams).map((key) => {
    // if the value of the param is an array then add a new search paramter to the URLSearchParam
    if (Array.isArray(urlParams[key])) {
      urlParams[key].forEach((item) => {
        params.append(key, item);
      });
    } else {
      // if it's not an array just add the value to the param as usual
      params.append(key, urlParams[key]);
    }
  });
  history.replace({
    ...history.location,
    search: `${params.toString()}`,
  });
};

function getInitialFilters(defaultFilters) {
  // Search as soon as it is mounted !!
  const params = new URLSearchParams(window.location.search);
  const urlFilters = paramsToObject(params);
  // always override default filters with the URL filters
  const paramsObjArray = [...urlFilters];
  // if there's a filterable object already there, add the new one to it as an array
  const filtered = reduce(
    paramsObjArray,
    (acc, curr) => {
      const foundIndex = findIndex(acc, (item) => item.id === curr.id);

      if (foundIndex === -1) {
        acc.push(curr);
      } else if (Array.isArray(acc[foundIndex].value)) {
        acc[foundIndex].value = [...acc[foundIndex].value, curr.value];
      } else {
        acc[foundIndex].value = [acc[foundIndex].value, curr.value];
      }
      return acc;
    },
    [],
  );
  return [...filtered, ...defaultFilters];
}

/*
 * Get a single column from a specific table stored localStorage
 * @param table { name of the table to get te column}
 * @param column { name or id of the column to get}
 * @returns { An object with the UI information of the column, or null }
 */
const getColumnFromLocalStorage = (table, column) => {
  const lsColumns = getColumnsFromLocalStorage(table);
  return get(lsColumns, column);
};

/*
 * Get all the columns from a specific table stored localStorage
 * @param table { name of the table to get te column}
 * @returns { An array of objects with the UI information of each column, or null }
 */
const getColumnsFromLocalStorage = (tableName) => {
  const erisx_user = localStorage.getItem('erisx_user');
  const columns = get(JSON.parse(localStorage.getItem('XTable7')), [
    erisx_user,
    tableName,
  ]);
  return columns;
};

/*
 * It checks is the default status of the columns' visibility in the table is the current status of the columns
 * @param metadata { metadata of the table }
 * @param hiddenColumns { current hiddenColums }
 * @returns { true if the current status of the hiddenColumns is the default or false if not }
 */
const isHiddenColumnsDefault = (metadata, hiddenColumns) => {
  const hiddenDefaultColumnsId = metadata
    .filter((x) => x.show === false)
    .map((obj) => obj.id);
  return (
    JSON.stringify(hiddenDefaultColumnsId) === JSON.stringify(hiddenColumns)
  );
};

/*
 * Checks if the visilibity of the columns changed, to know when the user change manually a column visibility
 * It compares the current visibility with the stored in the localStorage visibility
 * This function required a localStorage object stored, so is going to be called just when the localStorage exists.
 * @param tableName { name of the table }
 * @param hiddenColumns { current hiddenColums }
 * returns { An true if the user change a column visibility or false if not }
 */
const hasVisibilityChanged = (tableName, hiddenColumns) => {
  const localStorageHiddenColumns = gethiddenColumnsFromLocalStorage(
    null,
    tableName,
  );
  return (
    JSON.stringify(localStorageHiddenColumns) === JSON.stringify(hiddenColumns)
  );
};

/*
 * Return de withs of each column, if the width is stored return the stored width if not, returns the default width
 * @param metadata { data of the columns }
 * @param table  { table name }
 * @returns { an array with the width of each column in the table}
 */
export const columnsWithWidths = (metadata, table) =>
  metadata.map((column) => {
    // Columns with the neverShow flag are set to have 0 width
    if (column?.neverShow) {
      return { ...column, width: 0 };
    }
    if (!column.columns) {
      const obj = {
        ...column,
      };
      const lsColumn =
        getColumnFromLocalStorage(table, column.id || column.accessor) ||
        column.width;
      if (lsColumn?.width) {
        obj.width = lsColumn.width;
      }
      return obj;
    }
    return {
      ...column,
      columns: columnsWithWidths(column.columns, table),
    };
  });

/*
 * Return the hidden columns stored in the localStorage, if there is no localStorage, returns the default data passed by parameter
 * @param data { default data to return }
 * @param tableName  { name of the table }
 * @returns { Return the hidden columns stored in the localStorage, if there is no localStorage, returns the default data passed by parameter }
 */
const gethiddenColumnsFromLocalStorage = (data, tableName) => {
  const lsColumns = getColumnsFromLocalStorage(tableName);
  let lsHiddenColumns = data;

  if (lsColumns) {
    lsHiddenColumns = Object.keys(lsColumns).filter(
      (x) => lsColumns[x].show === false,
    );
  }
  return lsHiddenColumns;
};

/*
 * Creates the information in the localStorage
 * @param allColumns { metadata of the table }
 * @param erisx_user { erisx_user }
 * @param tableName { tableName }
 * @param columnId { when resize this data is sent to the function }
 * @param value  { when resize this data is sent to the function }
 */
const createsLocalStorageColumnsData = (
  allColumns,
  erisx_user,
  tableName,
  columnId = null,
  value = null,
) => {
  const tables = JSON.parse(localStorage.getItem('XTable7') || '{}');
  // When creates the key of the XTable7, all columns of the table are going to be set
  allColumns.forEach((column) => {
    if (columnId) {
      // When resize
      set(tables, [erisx_user, tableName, column.id], {
        width: columnId === column.id ? value : null,
        show: column.isVisible,
      });
    }
    // When resize and creates, it allways is going to set the visibility of each column based on their current visibility
    set(tables, [erisx_user, tableName, column.id], { show: column.isVisible });
  });
  localStorage.setItem('XTable7', JSON.stringify(tables));
};

/*
 * Updates the information in the localStorage
 * @param erisx_user { erisx_user }
 * @param tableName { tableName }
 * @param hiddenColumns { when visibility change this data is sent to the function }
 * @param columnId { when resize this data is sent to the function }
 * @param value  { when resize this data is sent to the function }
 */
const updatesLocalStorageColumnsData = (
  erisx_user,
  tableName,
  hiddenColumns = null,
  columnId = null,
  value = null,
) => {
  const lsTable = JSON.parse(localStorage.getItem('XTable7'));

  if (columnId) {
    // When resize
    set(lsTable, [erisx_user, tableName, columnId, 'width'], value);
  } else {
    // When visibility change
    for (const _columnId in lsTable[erisx_user][tableName]) {
      set(
        lsTable,
        [erisx_user, tableName, _columnId, 'show'],
        !hiddenColumns.includes(_columnId),
      );
    }
  }
  localStorage.setItem('XTable7', JSON.stringify(lsTable));
};

/*
 * Checks if exists the table in the localStorage
 * @param erisx_user  { erisx_user }
 * @param tableName { tableName }
 * @returns { true if the table exists in the localStorage, false if not }
 */
const existsTableInLocalStorage = (erisx_user, tableName) =>
  !!get(JSON.parse(localStorage.getItem('XTable7')), [erisx_user, tableName]);

const checkNestedColumns = (metadata) => !!metadata.find((x) => x.columns);

const DefaultCell = ({ value }) => <>{value}</>;

function XTable7({
  data,
  metadata,
  fetchData,
  loading,
  count,
  showPagination = true,
  showHeader = true,
  showFilter = true,
  title = '',
  showGeneralSearch = false,
  // TODO - Refactor / deprecate generalSearchOptions. Use generalFilters instead.
  generalSearchOptions = [],
  showFooterQuantity = true,
  height = HEIGHT,
  pageTableSize = pageTableSize || DEFAULT_PAGE_SIZE,
  onCellClick,
  localSortingAndFiltering = false,
  defaultFilters = [],
  flex = false,
  groupedBy = [],
  fixHeader = true, // This is going to be set in false if the metadata has nested columns (more than 1 deep)
  editable = false, // Makes the edit action column automatically appears and makes all fields editable
  onSaveChanges, // Callback fired when a user confirms the edit changes
  newRowTemplate = {}, // When editable, adding a new row will push a new object to <data> with <newRowTemplate> shape
  editActionsToHide = [], // Optional list of Edit actions to hide
  isAddNewButtonDisabled, // Optional callback to add custom logic to enable/disable Add new button
  className = '',
  showSelectRow = false,
  onChangeRowsSelected, // Optional list with the index of the rows selected.
  expandRows = false,
  expandedRowObj = {},
  renderRowSubComponent,
  getSubRows,
  generalFilters, // List of filter objects to render filter array
}) {
  const dispatch = useDispatch();
  const [controlledPageCount, setControlledPageCount] = useState(
    Math.ceil(count / 10),
  );
  const filterTypes = useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    [],
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // If its editable render the editableCell. If not, render a default cell with its value
      Cell: editable ? EditableCell : DefaultCell,
    }),
    [editable],
  );
  /** Edit functionality state */
  const [editedRows, setEditedRows] = useState([]);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [editableData, setEditableData] = useState(cloneDeep(data));
  const [newRow, setNewRow] = useState(false);
  const [showInvalidCells, setShowInvalidCells] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  // When removing a row - we store the original row states to keep it consistent after removing it
  const [prevRows, setPrevRows] = useState([]);
  const [lastRemovedRowId, setLastRemovedRowId] = useState('');
  const [hasDiscardedChanges, setHasDiscardedChanges] = useState(false);
  // For accountability on edit changes ownership we get the current user
  const currentUser = useSelector(getCurrentUser);

  const onEditRow = (row) => {
    // Toggle off edit mode if row is already being edited
    if (editedRows.includes(row.id)) {
      setEditedRows(editedRows.filter((id) => id !== row.id));
      return;
    }
    setEditedRows([...editedRows, row.id]);
    const isNew = get(row, 'state.isNew', false);
    // If row already has an original, keep that
    const original = get(row, 'state.original', false);
    row.setState({
      ...row.state,
      original: original || row.original,
      isEdited: !isNew,
    });
  };

  const onNew = useCallback(() => {
    setEditableData([...cloneDeep(editableData), newRowTemplate]);
    setNewRow(true);
  }, [editableData, data]);

  const columns = useMemo(() => {
    if (editable) {
      return addEditActions({
        metadata: columnsWithWidths(metadata, title),
        onEdit: (row) => onEditRow(row),
        onRemove: (row) => onRemoveRow(row),
        editActionsToHide,
      });
    }

    return columnsWithWidths(metadata, title);
  }, [metadata, editableData, editedRows]);

  const memoizedData = useMemo(() => {
    if (editable) return editableData;
    return data;
  }, [data, editable, editableData]);

  // Callback called onBlur every time a user edits a cell
  const updateEditableData = (rowIndex, columnId, value) => {
    // Turn off skipPageReset if pagination is being used
    setSkipPageReset(true);
    setEditableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const layoutHook = flex ? useFlexLayout : useBlockLayout;
  const [controlledPageIndex, setControlledPageIndex] = useState(0);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    allColumns,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    exportData,
    setFilter,
    setAllFilters,
    state: {
      pageIndex,
      pageSize,
      filters,
      sortBy,
      columnResizing,
      hiddenColumns,
      selectedRowIds,
    },
    setHiddenColumns,
    rows,
    rowsById,
  } = useTable(
    {
      getExportFileBlob,
      columns,
      data: memoizedData,
      initialState: {
        pageIndex: controlledPageIndex,
        filters: getInitialFilters(defaultFilters),
        sortBy: [],
        groupBy: groupedBy,
        pageSize: pageTableSize,
        expanded:
          expandRows && expandedRowObj.hasOwnProperty(0) ? expandedRowObj : {},
        // React Table v7 no longer supports 'show' attribute on column cell.
        // With this workaround we keep the same API for both versions
        hiddenColumns: gethiddenColumnsFromLocalStorage(
          columns
            .filter((col) => col.show === false)
            .map((col) => (col.accessor ? col.accessor : col.id)),
          title,
        ),
      }, // Pass our hoisted table state
      manualPagination: true,
      manualFilters: !localSortingAndFiltering,
      manualSortBy: !localSortingAndFiltering,
      autoResetPage: localSortingAndFiltering,
      // autoResetPage: !localSortingAndFiltering,
      pageCount: controlledPageCount,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      editedRows,
      updateEditableData,
      autoResetRowState: !editable, // If table is editable we keep table state even when data is changed
      getSubRows,
    },
    useFilters,
    useResizeColumns,
    useBlockLayout,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useResizeColumns,
    layoutHook,
    useExportData,
    useRowState,
    useRowSelect,
    (hooks) => {
      showSelectRow &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            selectedRowIds,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
            width: 50,
          },
          ...columns,
        ]);
    },
  );

  // Keep track of previous filter/sort values. Needed to make a deep comparison.
  const prevFilters = useRef(filters);
  const prevSortBy = useRef(sortBy);

  useEffect(() => {
    // Control the pageIndex variable so when table's initialState is recalculated
    // it's not re-set to 0
    setControlledPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (onChangeRowsSelected) {
      onChangeRowsSelected(selectedRowIds);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (!localSortingAndFiltering) {
      const hasChangedFilters = !isEqual(filters, prevFilters.current);
      const hasChangedSorting = !isEqual(sortBy, prevSortBy.current);
      const hasChanges = hasChangedFilters || hasChangedSorting;

      if (hasChangedFilters) prevFilters.current = filters;
      if (hasChangedSorting) prevSortBy.current = filters;

      const params = {
        pageIndex,
        pageSize,
        filters,
        sortBy,
        columns,
      };

      // If it has filter/sort changes we need to go back to page 0
      if (hasChanges) {
        // If it's already on page 0 manually refetch
        if (pageIndex === 0) {
          fetchData(params);
        } else {
          // If not, we go to page 0 which will refetch automatically preventing unnecessary fetch
          gotoPage(0);
        }
      } else {
        // On pageIndex / pageSize changes, manually refetch
        fetchData(params);
      }
    }
  }, [pageIndex, pageSize, filters, sortBy]);

  useEffect(() => {
    setControlledPageCount(Math.ceil(count / pageSize));
  }, [count, pageSize]);

  const stopEditing = useCallback(() => {
    setEditableData(data);
    setShowInvalidCells(false);
    setEditedRows([]);
    setRowsToDelete([]);
    setPrevRows([]);
    setLastRemovedRowId('');
    rows.forEach((row) => row.setState({}));
    setHasDiscardedChanges(true);
  }, [rows]);

  const onSave = useCallback(() => {
    setEditedRows([]);
    // First we reset previous invalidCells if any
    rows.forEach((row) => row.setState({ ...row.state, invalidCells: [] }));
    // Get current invalid rows
    const invalidRows = getInvalidRows(rows);
    if (!isEmpty(invalidRows)) {
      setShowInvalidCells(true);
      dispatch(showModal(TABLE_INVALID_FIELDS));
      // get the first invalid row and set is as editable
      invalidRows.forEach((row) => {
        const invalidCells = getInvalidCells(row);
        row.setState({ ...row.state, invalidCells });
      });
      setEditedRows([...editedRows, ...invalidRows.map((row) => row.id)]);
      return;
    }
    if (onSaveChanges) {
      const create = rows.filter(isCreatedRow).map((r) => r.original);
      const update = rows.filter(isEditedRow).map((r) => ({
        original: get(r, 'state.original', {}),
        updated: r.original,
      }));
      const remove = rowsToDelete;

      dispatch(
        showModal(TABLE_REVIEW_CHANGES, {
          create,
          update,
          remove,
          onConfirm: () => {
            onSaveChanges({
              currentUser,
              create,
              update,
              remove,
            });
            rows.forEach((row) => row.setState({}));
            setShowInvalidCells(false);
            stopEditing();
          },
        }),
      );
    }
  }, [onSaveChanges, editableData, rows, rowsToDelete]);

  const onRemoveRow = useCallback(
    (row) => {
      // Save current row list to then make sure all other rows state is consistent
      setPrevRows(rows);
      // Remove invalid cells
      row.setState({});
      setLastRemovedRowId(row.id);
      // Set new data list without the removed row
      const dataCopy = cloneDeep(editableData);
      dataCopy.splice(row.index, 1);
      setEditableData(dataCopy);
      // editedRows list has to be updated if a row was removed
      setEditedRows(getNewEditedRowsList(row.id, editedRows));
      if (!get(row, 'state.isNew', false)) {
        // If the row has been edited, use its original values.
        setRowsToDelete([
          ...rowsToDelete,
          get(row, 'state.original', row.original),
        ]);
      }
    },
    [editableData, rowsToDelete, editedRows, rows],
  );
  const [generalSearchInputValue, setGeneralSearchInputValue] = useState({});
  const erisx_user = localStorage.getItem('erisx_user');

  const delayedApplyGeneralSearch = useCallback(
    debounce((filter) => {
      handleApplyGeneralSearch(filter);
    }, 1000),
    [],
  );

  const handleApplyGeneralSearch = (filter) => {
    setAllFilters(filter);
  };

  useEffect(() => {
    if (showGeneralSearch && generalSearchInputValue) {
      const filtersArray = Object.keys(generalSearchInputValue).map((key) => ({
        id: key,
        value: generalSearchInputValue[key],
      }));
      // Match with filters
      const mergedFilter = merge(filters, filtersArray, 'id');
      delayedApplyGeneralSearch(mergedFilter);
    }
  }, [generalSearchInputValue]);

  // When the user resizes any column
  if (columnResizing.isResizingColumn) {
    const tableName = title;
    const columnId = columnResizing.isResizingColumn;
    const value =
      columnResizing.columnWidths[columnResizing.isResizingColumn] ||
      columnResizing.columnWidth;

    if (existsTableInLocalStorage(erisx_user, tableName)) {
      updatesLocalStorageColumnsData(
        erisx_user,
        tableName,
        null,
        columnId,
        value,
      );
    } else {
      createsLocalStorageColumnsData(
        allColumns,
        erisx_user,
        tableName,
        columnId,
        value,
      );
    }
  }

  useEffect(() => {
    const tableName = title;
    if (existsTableInLocalStorage(erisx_user, tableName)) {
      // When the user manually change the visibility of any column and exists the localStorage, update it
      if (!hasVisibilityChanged(tableName, hiddenColumns)) {
        updatesLocalStorageColumnsData(erisx_user, tableName, hiddenColumns);
      }
    } else {
      // When a column or columns visibility change, if the status of the table' columns is not equal to the default
      // it means that we need to store that change in the localStorage, so we create a localStorage status if it doens't exist
      if (!isHiddenColumnsDefault(metadata, hiddenColumns)) {
        createsLocalStorageColumnsData(allColumns, erisx_user, tableName);
      }
    }
  }, [hiddenColumns]);

  /** Edit functionality effects */
  // Keeps editableData consistent with data
  useEffect(() => {
    if (editable) setEditableData(data);
  }, [data]);

  // After user discards changes, resets the state of all the new rows
  useEffect(() => {
    if (hasDiscardedChanges) {
      setHasDiscardedChanges(false);
      rows.forEach((row) => row.setState({}));
    }
  }, [hasDiscardedChanges, rows]);

  // Whenever a new row is created, set its internal state to reflect that
  useEffect(() => {
    if (newRow) {
      setNewRow(false);
      const row = rows[rows.length - 1]; // Newly created row is the last one
      // Set row as new and save its original (empty) state
      row.setState({ isNew: true, original: row.original });
      // Add new row's id to editedRows list so it's automatically focused and editable
      setEditedRows([...editedRows, row.id]);
    }
  }, [newRow, rows]);

  // Whenever a user deletes a row, its place will be taken by the next rows and their
  // internal state would be inconsistent - so we manually update the state of all rows
  // subsequent to the removed one
  useEffect(() => {
    if (!isEmpty(prevRows)) {
      const removedRowId = Number(lastRemovedRowId);
      // Get the current rows that need to be updated
      const rowsToUpdate = rows.slice(removedRowId, prevRows.length);
      rowsToUpdate.forEach((row) => {
        // Get row's original state from prevRows
        const originalRow = nth(prevRows, Number(row.id) + 1);
        const originalState = get(originalRow, 'state', {});
        row.setState({ ...originalState });
      });
      // Reset prev rows to indicate that state is now consistent and removal is complete
      setPrevRows([]);
    }
  }, [prevRows, rows, setLastRemovedRowId]);

  const isAddNewDisabled = useMemo(
    () => (isAddNewButtonDisabled ? isAddNewButtonDisabled(rows) : false),
    [rows, isAddNewButtonDisabled],
  );

  const allColumnIds = useMemo(
    () => allColumns.map((column) => column.id),
    [allColumns],
  );

  return (
    <div className={className}>
      <div className={showHeader && showFilter ? 'riskTable' : 'riskTableX'}>
        {showFilter && (
          <Fragment>
            {generalFilters && (
              <GeneralFilters
                filters={generalFilters}
                setFilter={setFilter}
                currentFilters={filters}
                allColumnIds={allColumnIds}
              />
            )}
            {showGeneralSearch && (
              <div id="table-search" style={styles.tableSearch}>
                {/* TODO - Refactor / Remove generalSearchOptions so it's not coupled (uses lastName?) */}
                <Form>
                  <Form.Group>
                    {generalSearchOptions.map((op) => (
                      <FormField>
                        <label>{op.text}</label>
                        <Input
                          compact
                          name={op.value}
                          key={op.key}
                          icon="search"
                          iconPosition="left"
                          value={
                            typeof op.value !== 'object'
                              ? generalSearchInputValue[op.key] || ''
                              : generalSearchInputValue[op.key]?.lastName || ''
                          }
                          placeholder="Search"
                          onChange={(e) => {
                            const searchObj = {
                              ...generalSearchInputValue,
                            };
                            typeof op.value !== 'object'
                              ? (searchObj[op.key] = e.target.value)
                              : Object.entries(op.value).forEach(
                                  ([key, value]) => {
                                    searchObj[op.key] = {
                                      [key]: e.target.value,
                                    };
                                  },
                                );

                            // Merge generalSearch (quickSearch) with the current filters on the table
                            if (typeof op.value === 'object') {
                              filters.forEach((e) => {
                                // If exists in searchObj a property with the same id in the filters, we have a match
                                if (
                                  Object.keys(searchObj).find((x) => x === e.id)
                                ) {
                                  // if the property is an object we have to use the searchObj (generalSearch) filter
                                  if (typeof e.value === 'object') {
                                    // searchObj has higher priority, because this portion of code is executed when a generalSearch change
                                    // Generate an obj based on e.value and overwrite the lastName prop
                                    searchObj[e.id] = Object.assign(
                                      e.value,
                                      searchObj[e.id],
                                    );
                                  }
                                } else {
                                  searchObj[e.id] = e.value;
                                }
                              });
                            }
                            setGeneralSearchInputValue(searchObj);
                          }}
                        />
                      </FormField>
                    ))}
                  </Form.Group>
                </Form>
              </div>
            )}
            {editable && (
              <div style={styles.tableSettings}>
                {!editActionsToHide.includes(TABLE_EDIT_ACTIONS.CREATE) && (
                  <Button
                    icon="add"
                    content="New"
                    disabled={isAddNewDisabled}
                    onClick={onNew}
                    color="green"
                    id="xtable-add-new-btn"
                  />
                )}
                <Button
                  icon="save outline"
                  content="Apply Changes"
                  disabled={isEqual(data, editableData)}
                  onClick={onSave}
                  primary
                  id="xtable-save-edits-btn"
                />
                <Button
                  icon="undo"
                  content="Discard Changes"
                  disabled={isEqual(data, editableData)}
                  onClick={stopEditing}
                  color="red"
                  id="xtable-discard-edits-btn"
                />
              </div>
            )}
            <div id="table-settings" style={styles.tableSettings}>
              <Button
                icon="refresh"
                content="Refresh"
                onClick={() => {
                  stopEditing();
                  fetchData({
                    pageIndex,
                    pageSize,
                    filters,
                    sortBy,
                    columns,
                  });
                }}
                disabled={localSortingAndFiltering}
              />
              <Button
                disabled={!filters.length}
                as="div"
                labelPosition="right"
                onClick={() => {
                  setAllFilters([]);
                  setGeneralSearchInputValue({});
                }}
              >
                <Button icon>
                  <Icon name="filter" />
                  Clear All Filters
                </Button>
                <Label as="a" basic pointing="left">
                  <div style={styles.filterCount}>{filters.length}</div>
                </Label>
              </Button>
              <Popup
                trigger={<Button icon="columns" content="Show/Hide Columns" />}
                content={
                  <div>
                    {allColumns.map((column) =>
                      column.Header && !column.neverShow ? (
                        <div key={column.id}>
                          <label>
                            <input
                              type="checkbox"
                              {...column.getToggleHiddenProps()}
                            />{' '}
                            {column.Header}
                          </label>
                        </div>
                      ) : null,
                    )}
                  </div>
                }
                on="click"
                position="top right"
              />
              <Button
                icon="download"
                content="Export to CSV"
                onClick={() => {
                  exportData('csv', true);
                }}
              />
            </div>
          </Fragment>
        )}

        <Table
          {...getTableProps()}
          className="tableWrap"
          style={{ height }}
          compact="very"
          celled
        >
          <Table.Header
            style={{
              position:
                fixHeader && checkNestedColumns(metadata) ? 'sticky' : '',
              top: fixHeader ? '0' : '',
            }}
          >
            {headerGroups.map((headerGroup) => (
              <Table.Row
                {...headerGroup.getHeaderGroupProps()}
                style={{
                  ...headerGroup.getHeaderGroupProps().style,
                  ...styles.headerRow,
                  display:
                    fixHeader && !checkNestedColumns(metadata)
                      ? 'table-cell'
                      : 'flex',
                  position: fixHeader ? 'sticky' : 'initial',
                  top: '0',
                }}
              >
                {showHeader &&
                  headerGroup.headers.map((column) =>
                    !column.neverShow ? (
                      <Table.HeaderCell
                        {...column.getHeaderProps()}
                        style={{
                          ...column.getHeaderProps().style,
                          background: 'white',
                          display: 'table-cell',
                          minWidth: column.getHeaderProps().style.width,
                        }}
                      >
                        <div style={styles.header}>
                          <span {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                          {showFilter && !column.hideFilter && (
                            <Popup
                              trigger={
                                <Icon.Group>
                                  <Icon name="filter" link />
                                  {column.filterValue ? (
                                    <Icon
                                      corner="bottom right"
                                      color="red"
                                      name="circle"
                                      link
                                    />
                                  ) : null}
                                </Icon.Group>
                              }
                              content={
                                column.canFilter
                                  ? column.render('Filter')
                                  : null
                              }
                              on="click"
                              position="top right"
                            />
                          )}
                        </div>

                        <div
                          {...column.getResizerProps()}
                          style={styles.resizer}
                        />
                      </Table.HeaderCell>
                    ) : null,
                  )}
              </Table.Row>
            ))}
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const renderSubComponent =
                isSubRow(row) && !!renderRowSubComponent;
              const parentRow = renderSubComponent
                ? rowsById[getParentRowId(row.id)]
                : null;
              return (
                <>
                  <Table.Row
                    {...row.getRowProps({
                      style: {
                        minHeight: '31px',
                        cursor: onCellClick ? 'pointer' : undefined,
                      },
                      className: onCellClick
                        ? 'clickable-table-row'
                        : undefined,
                    })}
                  >
                    {renderSubComponent
                      ? renderRowSubComponent({ row, parentRow })
                      : row.cells.map((cell) =>
                          !cell.column.neverShow ? (
                            <Cell
                              {...cell.getCellProps([
                                {
                                  onClick: (e) =>
                                    onCellClick && onCellClick(cell, e),
                                  className: cell.column.className,
                                  style: {
                                    ...cell?.column?.style,
                                    overflowX: 'clip',
                                  },
                                },
                              ])}
                              showInvalidCells={showInvalidCells}
                              isValid={isEditedCellValid(cell)}
                              isBeingEdited={cell?.row?.id === editedRows}
                            >
                              {cell.isGrouped ? (
                                <>
                                  <span {...row.getToggleRowExpandedProps()}>
                                    {row.isExpanded ? '▼' : '►'}
                                  </span>
                                  {cell.render('Cell')}
                                  {row.subRows.length}
                                </>
                              ) : cell.isAggregated ? (
                                cell.render('Aggregated')
                              ) : cell.isPlaceholder ? null : (
                                cell.render('Cell') // For cells with repeated values, render null
                              )}
                            </Cell>
                          ) : null,
                        )}
                  </Table.Row>
                </>
              );
            })}
            {loading ? (
              <Table.Row>
                <Table.Cell colSpan={COL_SPAN}>
                  <Loader active>Loading...</Loader>
                </Table.Cell>
              </Table.Row>
            ) : (
              showPagination &&
              showFooterQuantity && (
                <Table.Row>
                  <Table.Cell colSpan={COL_SPAN}>
                    Showing {page.length} of approximately{' '}
                    {controlledPageCount === 1
                      ? page.length
                      : controlledPageCount * pageSize}{' '}
                    results
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
        {showPagination && (
          <div className="pagination">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '100px' }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {PAGE_SIZES.map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <Button
              className="pull-right"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </Button>
            <Button
              className="pull-right"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default XTable7;
