import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal, hideModal } from 'erisxkit/client';
import { Header, Divider, Grid, Segment, Dropdown } from 'semantic-ui-react';
import { trim } from 'lodash';
import riskAlertStatusMetadata from '../../metadata/riskAlertStatusMetadata';
import riskAlertByIdMetadata from '../../metadata/riskAlertByIdMetadata';
import RiskAlertsComments from './RiskAlertsComments';
import {
  fetchRiskAlertComments,
  fetchRiskAlertStatuses,
  fetchRiskAlerts,
  addRiskAlertComment,
  updateRiskAlertStatus,
  getRiskAlertComments,
  getRiskAlertStatuses,
  getFirstRiskAlertStatus,
  getRiskAlerts,
} from '../../reducers/riskReducer';
import XTable7 from '../../common/table/XTable7';
import { UPDATE_RISK_ALERT_STATUS } from '../../constants/modalTypes';
import riskAlertStatusesConst from '../../constants/riskAlertStatuses';
import { getRiskAlertStatusTextByStatus } from '../../utils/methods';

const RiskAlertsDetails = (props) => {
  const riskAlertComments = useSelector(getRiskAlertComments);
  const riskAlertStatuses = useSelector(getRiskAlertStatuses);
  const firstRiskAlertStatusById = useSelector(getFirstRiskAlertStatus);
  const riskAlerts = useSelector(getRiskAlerts);
  const dispatch = useDispatch();
  const { alertId } = props.match.params;

  const handleRiskStatusChange = (e, prop) => {
    if (firstRiskAlertStatusById.status === prop.value) {
      return;
    }

    const riskStateTo = {
      value: prop.value,
      text: getRiskAlertStatusTextByStatus(prop.value),
    };

    const riskStateFrom = {
      value: firstRiskAlertStatusById.status,
      text: getRiskAlertStatusTextByStatus(firstRiskAlertStatusById.status),
    };

    dispatch(
      showModal(UPDATE_RISK_ALERT_STATUS, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(UPDATE_RISK_ALERT_STATUS)),
        updateStatus: handleUpdateRiskAlertStatus,
        alertId,
        accountNumber: prop.accountNumber,
        from: riskStateFrom,
        to: riskStateTo,
      }),
    );
  };

  const createAlertIdFilter = (alertId) => [
    {
      attr: 'alert_id',
      op: 'eq',
      value: alertId,
    },
  ];

  const fetchDataStatus = React.useCallback(() => {
    const filter = createAlertIdFilter(alertId);
    dispatch(fetchRiskAlertStatuses({ filter }));
  }, []);

  const fetchDataComments = React.useCallback(() => {
    const filter = createAlertIdFilter(alertId);
    dispatch(fetchRiskAlertComments({ filter }));
  }, []);

  const fetchData = React.useCallback(() => {
    const filter = createAlertIdFilter(alertId);
    dispatch(
      fetchRiskAlerts({
        filter,
        with_latest_comment: false,
        with_latest_status: false,
      }),
    );
  }, []);

  const handleAddComment = (e, value) => {
    const args = { alert_id: alertId, comment: value };
    dispatch(addRiskAlertComment(args));
  };

  const handleUpdateRiskAlertStatus = (alertId, to) => {
    const args = { alert_id: alertId, status: to.value };
    dispatch(updateRiskAlertStatus(args));
    dispatch(hideModal(UPDATE_RISK_ALERT_STATUS));
  };
  return (
    <Fragment>
      <Header as="h1" dividing>
        Alert Details
      </Header>
      <Segment>
        <Grid columns={2} divided>
          <Grid.Column width={10}>
            <Divider section />
            <XTable7
              metadata={riskAlertByIdMetadata}
              data={riskAlerts}
              fetchData={fetchData}
              showHeader
              showFilter={false}
              showPagination={false}
            />
            <Divider section />
            <RiskAlertsComments
              addComment={handleAddComment}
              fetchData={fetchDataComments}
              data={riskAlertComments}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Header as="h3" style={{ marginTop: '35px' }}>
              Alert Status
            </Header>
            {firstRiskAlertStatusById && riskAlerts && (
              <Dropdown
                onChange={handleRiskStatusChange}
                options={riskAlertStatusesConst}
                placeholder="Select status"
                selection
                accountNumber={
                  riskAlerts.length > 0 && riskAlerts[0]?.accountNumber
                }
                value={trim(
                  firstRiskAlertStatusById?.status,
                )?.toLocaleUpperCase()}
              />
            )}
            <p style={{ marginTop: '10px' }}>History</p>
            <XTable7
              metadata={riskAlertStatusMetadata}
              data={riskAlertStatuses}
              fetchData={fetchDataStatus}
              showHeader={false}
              showPagination={false}
              showFilter={false}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </Fragment>
  );
};

export default RiskAlertsDetails;
