import React from 'react';
import { get } from 'lodash';
import { withFilters } from 'erisxkit/client';
import Table from '../../common/table/ExternalTable';
import CommonPageHeader from '../../common/components/CommonPageHeader';
import history from '../../constants/history';

const FilteredTradesTable = withFilters(Table);

const MemberIndex = ({
  data,
  metadata,
  fetchData,
  createMember,
  selectMember,
  clearSelectedMember,
  ...rest
}) => (
  <div>
    <CommonPageHeader
      headerProps={{ content: 'Members' }}
      buttonProps={{
        content: 'Create New Member',
        className: 'add-button',
        onClick: () => {
          clearSelectedMember();
          history.push('/members/create_member');
        },
      }}
    />
    <FilteredTradesTable
      title="MemberIndexTable"
      noDataText="No members were found."
      sortable
      data={data}
      className="-striped -highlight"
      columns={metadata}
      onFetchData={fetchData}
      getTrProps={(state, rowInfo) => ({
        className: 'pointer',
        onClick: () => {
          selectMember(get(rowInfo, 'original', {}));
        },
      })}
      {...rest}
    />
  </div>
);

export default MemberIndex;
