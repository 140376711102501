import React from 'react';
import { Icon } from 'semantic-ui-react';
import { format } from 'erisxkit/client';
import { get } from 'lodash';

export default (showModal, modalType, modalProps) => [
  {
    Header: '',
    columns: [
      {
        Header: 'Product Code',
        accessor: 'symbol',
        id: 'symbol',
      },
      {
        Header: 'Base Asset Type',
        accessor: 'baseAssetType',
        id: 'baseAssetType',
      },
      {
        Header: 'Quoted Asset Type',
        accessor: 'quotedAssetType',
        id: 'quotedAssetType',
      },
      {
        Header: 'Contract Size',
        accessor: (row) => format(get(row, 'contractSize', '')),
        id: 'contractSize',
      },
      {
        Header: 'Description',
        accessor: 'description',
        id: 'description',
      },
      {
        id: 'edit',
        Header: '',
        maxWidth: 30,
        resizable: false,
        Cell: ({ original }) => (
          <Icon
            id="edit-product"
            name="edit"
            link
            onClick={() => showModal(modalType, { ...modalProps, ...original })}
          />
        ),
      },
    ],
  },
];
