import React, { Fragment } from 'react';
import { Form, Label } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { normalization, renderField } from 'erisxkit/client';
import { formatDecimals } from '../../common/forms/normalization';

const AchDefaults = () => (
  <Fragment>
    <Form.Group>
      <Field
        label="Deposit ACH Fee"
        id="depositFee"
        name="depositFee"
        component={renderField}
        labelPosition="right"
        parse={formatDecimals}
      >
        <input />
        <Label>USD</Label>
      </Field>
      <Field
        label="Withdrawal ACH Fee"
        id="withdrawalFee"
        name="withdrawalFee"
        component={renderField}
        labelPosition="right"
        parse={formatDecimals}
      >
        <input />
        <Label>USD</Label>
      </Field>
      <Field
        label="Max. Deposit Per Member"
        id="maxDeposit"
        name="maxDeposit"
        component={renderField}
        labelPosition="right"
        parse={formatDecimals}
      >
        <input />
        <Label>USD</Label>
      </Field>
    </Form.Group>
    <Form.Group>
      <Field
        label="ACH Deposit Period"
        id="depositPeriod"
        name="depositPeriod"
        component={renderField}
        labelPosition="right"
        parse={normalization.numbersOnly}
      >
        <input />
        <Label>Days</Label>
      </Field>
      <Field
        label="ACH Embargo Period"
        id="embargoPeriod"
        name="embargoPeriod"
        component={renderField}
        labelPosition="right"
        parse={normalization.numbersOnly}
      >
        <input />
        <Label>Days</Label>
      </Field>
      <Field
        label="Min. Deposit Per Member"
        id="minDeposit"
        name="minDeposit"
        component={renderField}
        labelPosition="right"
        parse={formatDecimals}
      >
        <input />
        <Label>USD</Label>
      </Field>
    </Form.Group>
  </Fragment>
);

export default AchDefaults;
