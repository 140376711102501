import _ from 'lodash';
import React, { Fragment } from 'react';
import {
  Form,
  Header,
  Button,
  Label,
  Icon,
  List,
  Modal,
} from 'semantic-ui-react';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

const AddRemoveAccounts = ({
  user,
  addAccounts,
  removeAccount,
  saveUserDetails,
  saveDisabled,
  clearingAccountList,
  accounts,
  update,
  states,
  closeModal,
  handleSearchChange,
  userAccountLinks,
}) => {
  const panels = _.map(userAccountLinks, ({ accountId, accountLabel }) => (
    <List.Item>
      <Label key={accountLabel}>
        {accountLabel}
        <Icon
          className="pull-right"
          name="delete"
          id={accountId}
          link
          onClick={(e) => {
            removeAccount(e.target.id);
          }}
        />
      </Label>
    </List.Item>
  ));

  return (
    <Fragment>
      <Modal.Content>
        <Form>
          <Header as="h2" content={user.email} subheader={user.userId} />
          <AccountSelectionContainer
            multiple
            name="accountId"
            placeholder="Start typing to search for an account."
            label="Add account:"
            fluid
            search
            selection
            onChange={addAccounts}
          />
          <Header as="h3">Current Linked Clearing Account(s): </Header>
          <List>{panels}</List>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={closeModal}>
          {' '}
          Cancel{' '}
        </Button>
        <Button positive disabled={saveDisabled} onClick={saveUserDetails}>
          {' '}
          Save{' '}
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default AddRemoveAccounts;
