import _ from 'lodash';
import React from 'react';
import { Label, Dropdown } from 'semantic-ui-react';
import { formatTimeLocal, formatDateLocal } from '../utils/time';

const columns = (
  showReprocessModal,
  showRequestReprocessModal,
  clearTradeCaptureReport,
  edit,
) => [
  {
    Header: 'Execution Time',
    id: 'executionTime',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.executionTime),
  },
  {
    Header: 'Negotiated Time',
    id: 'negotiatedTime',
    accessor: ({ negotiatedTime }) => formatTimeLocal(negotiatedTime),
    width: 205,
  },
  {
    Header: 'Spread ID',
    id: 'secondaryExecId',
    accessor: (row) => _.get(row, ['buySide', 'secondaryExecId'], '-'),
  },
  {
    Header: 'Submitter',
    id: 'submitter',
    accessor: 'submitter',
  },
  {
    Header: 'Trade Date',
    id: 'tradeDate',
    headerClassName: 'pull-left',
    accessor: (row) => formatDateLocal(row.tradeDate),
  },
  {
    Header: 'Trade Link ID',
    id: 'tradeLinkId',
    accessor: 'tradeLinkId',
  },
  {
    Header: 'Trade Type',
    id: 'tradeType',
    accessor: 'trdType',
  },
  {
    Header: 'Product Code',
    id: 'productCode',
    accessor: 'productCode',
  },
  {
    Header: 'Contract Code',
    id: 'contractCode',
    accessor: 'contractCode',
  },
  {
    Header: 'Quantity',
    id: 'qty',
    accessor: 'qty',
    className: 'mono',
    width: 80,
  },
  {
    Header: 'Price',
    id: 'px',
    accessor: 'px',
    className: 'mono',
  },
  {
    Header: 'Notional',
    id: 'notional',
    accessor: 'notional',
    className: 'mono',
  },
  {
    Header: 'Buy Side Trade ID',
    id: 'buySideTradeId',
    accessor: (row) => _.get(row, ['buySide', 'tradeId'], '-'),
  },
  {
    Header: 'Buy Side CAR',
    id: 'buySideCAR',
    accessor: (row) => _.get(row, ['buySide', 'customerAccountRef'], '-'),
  },
  {
    Header: 'Buy Side CTI',
    id: 'buySideCTI',
    accessor: (row) => _.get(row, ['buySide', 'cti'], '-'),
  },
  {
    Header: 'Buy Side Origin',
    id: 'buySideOrigin',
    accessor: (row) => _.get(row, ['buySide', 'origin'], '-'),
  },
  {
    Header: 'Sell Side Trade ID',
    id: 'sellSideTradeId',
    accessor: (row) => _.get(row, ['sellSide', 'tradeId'], '-'),
  },
  {
    Header: 'Sell Side CAR',
    id: 'sellSideCar',
    accessor: (row) => _.get(row, ['sellSide', 'customerAccountRef'], '-'),
  },
  {
    Header: 'Sell Side CTI',
    id: 'sellSideCTI',
    accessor: (row) => _.get(row, ['sellSide', 'cti'], '-'),
  },
  {
    Header: 'Sell Side Origin',
    id: 'sellSideOrigin',
    accessor: (row) => _.get(row, ['sellSide', 'origin'], '-'),
  },
  {
    Header: 'State',
    accessor: (row) => (
      <Label color={_.get(row, 'state') === 'cleared' ? 'green' : 'yellow'}>
        {_.get(row, 'state')}
      </Label>
    ),
    id: 'state',
    width: 170,
  },
  {
    Header: '',
    id: 'more',
    accessor: (d) => (
      <Dropdown icon="ellipsis vertical" pointing="top right">
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              clearTradeCaptureReport({ tradeLinkId: d.tradeLinkId })
            }
            text="Check state"
            icon="check"
          />
          <Dropdown.Item
            onClick={() => showReprocessModal(d)}
            text="Reprocess"
            icon="redo"
          />
          <Dropdown.Item
            onClick={() => showRequestReprocessModal(d)}
            disabled={d.state === 'cleared'}
            text="Request Reprocess"
            icon="redo"
          />
          <Dropdown.Item
            onClick={() => edit(d)}
            text="Edit"
            icon="edit outline"
          />
        </Dropdown.Menu>
      </Dropdown>
    ),
    filterable: false,
    width: 35,
  },
];

export default columns;
