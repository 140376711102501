import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';
import { handleActions, combineActions } from 'redux-actions';
import { arrayToObject } from 'erisxkit/client';
import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  count: 0,
  byId: {},
  currentPage: 0,
};

export const reprocessTradeCaptureReport = createRoutine(
  actionTypes.REPROCESS_TRADE_CAPTURE_REPORT,
);
export const requestReprocessTradeCaptureReport = createRoutine(
  actionTypes.REQUEST_REPROCESS_TRADE_CAPTURE_REPORT,
);
export const fetchTradeCaptureReports = createRoutine(
  actionTypes.TRADE_CAPTURE_REPORTS,
);
export const clearTradeCaptureReport = createRoutine(
  actionTypes.CLEAR_TRADE_CAPTURE_REPORT,
);
export const updateTradeCaptureReport = createRoutine(
  actionTypes.UPDATE_TRADE_CAPTURE_REPORT,
);
export const updateTradeCaptureReportPromiseCreator = promisifyRoutine(
  updateTradeCaptureReport,
);

export default handleActions(
  {
    [fetchTradeCaptureReports.SUCCESS]: (state, { payload }) => {
      const page = _.isNumber(payload.page) ? payload.page : state.currentPage;
      return {
        ...state,
        count: payload.count,
        byId: {
          ...state.byId,
          ...arrayToObject(payload.tradeCaptureReports, 'tradeLinkId'),
        },
        currentPage: page,
        pages: {
          ...state.pages,
          [page]: payload.tradeCaptureReports.map((item) => item.tradeLinkId),
        },
      };
    },
    [combineActions(
      reprocessTradeCaptureReport.SUCCESS,
      requestReprocessTradeCaptureReport.SUCCESS,
      clearTradeCaptureReport.SUCCESS,
      updateTradeCaptureReport.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      byId: {
        ...state.byId,
        [payload.tradeLinkId]: {
          ...state.byId[payload.tradeLinkId],
          ...payload,
        },
      },
    }),
  },
  defaultState,
);

/* Selectors */
const getTradeCaptureReports = (state) =>
  _.get(state, ['tradeCaptureReports', 'byId'], {});

export const getTradeCaptureReportsList = (state) =>
  Object.values(getTradeCaptureReports(state));
export const getTradeCaptureReportsCount = (state) =>
  _.get(state, ['tradeCaptureReports', 'count'], 0);

const getTradeCaptureReportIdsPaged = (state) => {
  const page = _.get(state, ['tradeCaptureReports', 'currentPage']);
  const pageIds = _.get(state, ['tradeCaptureReports', 'pages', page]);
  if (_.isEmpty(pageIds)) {
    return [];
  }
  return pageIds;
};

export const getTradeCaptureReportsPaged = createSelector(
  [getTradeCaptureReports, getTradeCaptureReportIdsPaged],
  (tcrById, tcrIds) => tcrIds.map((o) => tcrById[o]),
);
