import get from 'lodash/get';
import {
  LEGAL_PERSON_KEY,
  NATURAL_PERSON_KEY,
  ORIGINATING_VASP_KEY,
  RECIPIENT_VASP_KEY,
  isNaturalPersonSelected,
  isOriginatorLegalPerson,
  isPendingTravelRule,
  isTrustTravelRule,
  isWithdrawalTravelRule,
} from './utils';
import {
  LEGAL_PERSON_ORIGINATOR_FIELDS,
  NATURAL_PERSON_ORIGINATOR_FIELDS,
  ORIGINATING_VASP_FIELDS,
  RECIPIENT_VASP_FIELDS,
} from './fields';
import getParsedFieldName from '../../../common/table/EditableRowSubComponent/getParsedFieldName';

/**
 * @param fields - fields to initialize
 * @param values - originator or originating_vasp object with values
 * @returns fields object with initialValue attribute populated accordingly
 */
const getInitializedFields = (fields, values = {}) =>
  fields.map((field) => ({
    ...field,
    initialValue: get(values, [field.name]) || '',
  }));

/**
 * @param travelRule - XTable7 TravelRule row from TravelRuleContainer
 * @returns list of objects that are used as groupedFields prop on EditableRowSubComponent
 */
const getGroupedFields = (travelRule) => {
  if (!travelRule) return [];
  const fields = [];
  const isLegalPerson = isOriginatorLegalPerson(travelRule);
  const isWithdrawal = isWithdrawalTravelRule(travelRule);
  const isTrust = isTrustTravelRule(travelRule);
  const isPending = isPendingTravelRule(travelRule);

  // Originator Fields
  const originatorFields = isLegalPerson
    ? LEGAL_PERSON_ORIGINATOR_FIELDS
    : NATURAL_PERSON_ORIGINATOR_FIELDS;

  const originatorTitle = isLegalPerson ? 'Legal Person' : 'Natural Person';
  // If its a non trust deposit withdrawal we must show two sets of originator fields
  if (!isPending && !isTrust && !isWithdrawal) {
    // Add the two sets of fields
    fields.push([
      {
        key: NATURAL_PERSON_KEY,
        title: 'Natural Person',
        fields: getInitializedFields(
          NATURAL_PERSON_ORIGINATOR_FIELDS,
          travelRule?.originator,
        ),
      },
      {
        key: LEGAL_PERSON_KEY,
        title: 'Legal Person',
        fields: getInitializedFields(
          LEGAL_PERSON_ORIGINATOR_FIELDS,
          travelRule?.originator,
        ),
      },
    ]);
  } else {
    fields.push({
      key: 'originator',
      title: originatorTitle,
      fields: getInitializedFields(originatorFields, travelRule?.originator),
    });
  }
  // Originating VASP
  fields.push({
    key: ORIGINATING_VASP_KEY,
    title: 'Financial Institution',
    fields: getInitializedFields(
      ORIGINATING_VASP_FIELDS,
      travelRule?.originatingVasp,
    ),
  });
  // If its a Trust Withdrawal add Recipient VASP Fields
  if (isWithdrawal && isTrust) {
    fields.push({
      key: RECIPIENT_VASP_KEY,
      title: 'Recipient VASP',
      fields: getInitializedFields(
        RECIPIENT_VASP_FIELDS,
        travelRule?.beneficiaryVasp,
      ),
    });
  }
  return fields;
};

/**
 * @param {{ [key:string]: string | number | ''}} formValues - Full EditableRowSubComponent form values. Includes non-active form fields
 * @param {string} pickKey - key to match on formValues keys
 * @description Filters out formValues keys returning only the ones that include pickKey
 */
export const pickFormValuesByKey = (formValues = {}, pickKey = '') =>
  !formValues
    ? {}
    : Object.entries(formValues).reduce((acc, curr) => {
        const [key, value] = curr;
        const shouldPick = key?.includes(pickKey);
        const parsedKey = getParsedFieldName(key);
        return shouldPick
          ? {
              ...acc,
              [parsedKey]: value,
            }
          : acc;
      }, {});

/**
 * @param {string[]} activeFormGroups - list of active EditableRowSubComponent from groups
 * @param {{ [key:string]: string | number | ''}} formFields - Full EditableRowSubComponent form values. Includes non-active form fields
 * @description Returns corresponding originating fields according to activeFormGroups. EditableRowSubComponent allows to fill
 * all forms and indicates which one is active via activeFormGroups list
 */
export const getOriginatorFields = (activeFormGroups = [], formFields = {}) => {
  const isNatural = isNaturalPersonSelected(activeFormGroups);
  // If it's Natural filter out all Legal Person fields or viceversa
  const pickKey = isNatural ? NATURAL_PERSON_KEY : LEGAL_PERSON_KEY;
  return pickFormValuesByKey(formFields, pickKey);
};

export const getOriginatingVaspFields = (formFields = {}) =>
  pickFormValuesByKey(formFields, ORIGINATING_VASP_KEY);

export const getRecipientVaspFields = (formFields = {}) =>
  pickFormValuesByKey(formFields, RECIPIENT_VASP_KEY);

export default getGroupedFields;
