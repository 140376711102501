import _ from 'lodash';
import React, { Fragment } from 'react';
import { Icon, Header, Segment, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReconciliationStats from './ReconciliationStats';
import { formatTimeLocalWithDoW } from '../utils/time';

const ReconciliationHeader = ({
  data,
  refresh,
  selectedAccount,
  reconciliationLoading,
  createReconciliation,
  ...rest
}) => (
  <Fragment>
    <Segment
      className="flex-row reconciliation-header"
      loading={reconciliationLoading}
      color={data.reconciled ? 'green' : 'red'}
    >
      <Header as="h1">
        <Header.Content>{_.get(selectedAccount, 'name', '')}</Header.Content>
        <Header.Subheader className="mono">
          {_.get(selectedAccount, 'accountId', '')}
        </Header.Subheader>
        <Header.Subheader>
          {`Starting Balance: ${_.get(data, 'prevReconciledBalance', '').toString()}`}
        </Header.Subheader>
        <Header.Subheader>
          {`Ending Time: ${formatTimeLocalWithDoW(data.endTime)}`}
        </Header.Subheader>
      </Header>
      <div className="flex-column">
        <div className="flex-row">
          <ReconciliationStats
            data={data || {}}
            reconciliationLoading={reconciliationLoading}
            {...rest}
          />
          <Icon link name="refresh" onClick={refresh} />
        </div>
        {data.state === 0 && (
          <Button
            className="add-button"
            floated="right"
            onClick={createReconciliation}
          >
            Confirm Reconciliation <Icon name="checkmark" />
          </Button>
        )}
      </div>
    </Segment>
  </Fragment>
);

ReconciliationHeader.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ReconciliationHeader;
