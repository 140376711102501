import React from 'react';
import Moment from 'moment-timezone';
import moment from 'moment';
import { fetchApprovalReqs } from '../../actions/approvalReqsActions';
import { get } from 'lodash';

const MAX_MARGIN_RATES_APPROVERS = 2;

export const fetchApprovalReqsMethod = (uuid) =>
  fetchApprovalReqs({
    filter: [{ attr: 'uuid', op: 'eq', value: uuid }],
  });

export const isPreviousDateSelected = (marginRateInfoDate) => {
  const currentDate = Moment().format('YYYY-MM-DD');
  return moment(currentDate).isAfter(moment(marginRateInfoDate));
};

export const drawApprovers = (data) => {
  let approvers = [];
  for (let i = 0; i < MAX_MARGIN_RATES_APPROVERS; i++) {
    approvers.push(
      <p className={i === 0 && 'marginRateMainTabP'}>
        <b className="marginRateText">Approver {i + 1}:</b>{' '}
        {get(data[0]?.approvals, [i.toString(), 'email'], '-')}
      </p>,
    );
  }
  return <div>{approvers}</div>;
};
