import _ from 'lodash';
import { createLoadingSelector } from 'erisxkit/client';
import React, { Component, Fragment } from 'react';
import { Dropdown, Segment, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  fetchMemberUserPermissions,
  getUserPermissions,
  routeSubmittingOrdersToApi,
  updateUser,
  getSelectedUserWithPermissions,
  memberStates,
  getUserStates,
  getAppStates,
  elevateTxAdmin,
  resetTxPassword,
  getCurrentAuthId,
  fetchEmployeeRoles,
  getEmployeeRoles,
  resetMf,
  getMemberUserTypes,
  fetchMemberUserTypes,
} from '../../reducers/usersReducer';
import { getSelectedEmployeeWithPermissions } from '../../reducers/employeesReducer';
import MemberUserSettings from '../../components/MemberUsers/MemberUserSettings';
import { showModal, hideModal } from 'erisxkit/client';
import {
  apiCredentialsPermissions as fetchApiCredentialsPermissions,
  getApiCredentialPermissions,
} from '../../reducers/apiCredentialsReducer';
import {
  ADMIN_KEY,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../constants/modalTypes';
import * as userTypes from '../../constants/userTypes';
import { signMsg, isAdmin } from '../../utils/methods';
import memberUserPermissions from '../../constants/memberUserPermissions';
import apiKeyPermissions from '../../constants/apiKeyPermissions';

const mapStateToProps = (state) => ({
  selectedUser: getSelectedUserWithPermissions(state),
  selectedEmployee: getSelectedEmployeeWithPermissions(state),
  apiCredentialsPermissions: getApiCredentialPermissions(state),
  apiCredentialsPermissionsLoading: createLoadingSelector([
    fetchApiCredentialsPermissions._PREFIX,
  ])(state),
  userStates: getUserStates(state),
  appStates: getAppStates(state),
  adminAuthId: getCurrentAuthId(state),
  employeeRoles: getEmployeeRoles(state),
  userPermissions: getUserPermissions(state),
  memberUserPermissionsLoading: createLoadingSelector([
    fetchMemberUserPermissions._PREFIX,
  ])(state),
  memberUserTypes: getMemberUserTypes(state),
});

const mapDispatchToProps = {
  fetchApiCredentialsPermissions,
  updateUser,
  memberStates,
  elevateTxAdmin,
  resetTxPassword,
  showModal,
  hideModal,
  fetchEmployeeRoles,
  routeSubmittingOrdersToApi,
  fetchMemberUserPermissions,
  resetMf,
  fetchMemberUserTypes,
};

export const addOptionsToUserPermissions = (ps) => {
  const v = _.chain(ps)
    .map((p) => ({
      key: p,
      value: p,
      text: _.get(memberUserPermissions, [p, 'text'], p),
      header: _.get(memberUserPermissions, [p, 'header'], 'Misc'),
    }))
    .groupBy('header')
    .value();
  return _.flatten(
    Object.keys(v).map((h) => [
      {
        key: h,
        className: 'dropdown-header',
        content: (
          <Fragment>
            <Dropdown.Header className="dropdown-header" content={h} />
            <Dropdown.Divider />
          </Fragment>
        ),
        disabled: true,
      },
      ...v[h],
    ]),
  );
};

export const memberUserPermissionsToStructure = (
  permissionsList,
  structure = memberUserPermissions,
) => {
  const dataFormatted = Object.entries(structure).map((x) => ({
    header: _.get(x[1], 'header', ''),
    text: _.get(x[1], 'text', ''),
    prop: _.get(x, '[0]', ''),
  }));

  const childs = dataFormatted.map((data) => data.prop);

  //If permissionsList (all permissions availables to select) contains extras permissions,
  //not includes in childs sctructure (permissions structureZ file),
  //this code adds it as Misc (with Misc header)

  let difference = permissionsList?.filter((x) => !childs.includes(x));

  if (difference?.length > 0) {
    difference.forEach((element) => {
      dataFormatted.push({
        header: 'Misc',
        text: element,
        prop: element,
      });
    });
  }

  var grouped = _.mapValues(_.groupBy(dataFormatted, 'header'));

  return grouped;
};

export const getUserType = (user) => user.type || userTypes.memberUser;
const selectUserType = (props) =>
  props.type === userTypes.employee
    ? props.selectedEmployee
    : props.selectedUser;

// Fields whose value should remain unchanged when passed to the 'update' object
const NON_LOWER_CASED_FIELDS = [
  'roles',
  'permissions',
  'apiKeyPermissions',
  'memberUserType',
];

class MemberUserSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: true,
      userId: _.get(selectUserType(props), 'userId', ''),
      update: {},
    };
  }

  componentDidMount = () => {
    const {
      fetchApiCredentialsPermissions,
      memberStates,
      fetchEmployeeRoles,
      fetchMemberUserPermissions,
      fetchMemberUserTypes,
    } = this.props;
    memberStates();
    fetchEmployeeRoles();
    fetchMemberUserPermissions();
    fetchApiCredentialsPermissions();
    fetchMemberUserTypes();
  };

  handleChange = (e, obj) => {
    let change;

    if (NON_LOWER_CASED_FIELDS.includes(obj.name)) {
      change = { [obj.name]: _.get(obj, ['value'], '') };
    } else {
      change = { [obj.name]: _.get(obj, ['value'], '').toLowerCase() };
    }
    this.setState({
      update: {
        ...this.state.update,
        ...change,
      },
      saveDisabled: false,
    });
  };

  saveUserDetails = () => {
    const { userId } = selectUserType(this.props);
    const { update } = this.state;
    this.props.updateUser({ userId, update });
  };

  signAndElevate = (password) => {
    const { adminAuthId, elevateTxAdmin, hideModal } = this.props;
    const selectedUser = selectUserType(this.props);
    const { userId } = selectedUser;
    const msg = [selectedUser.authId, adminAuthId, Date.now().toString()];
    const sig = signMsg(adminAuthId, password, msg);
    elevateTxAdmin({ msg, sig, userId });
    hideModal();
  };

  confirmPassword = (e, { name }) => {
    if (name === 'elevate') {
      this.props.showModal(ADMIN_KEY, { submit: this.signAndElevate });
    }
    if (name === 'reset') {
      this.props.showModal(ADMIN_KEY, { submit: this.signAndReset });
    }
  };

  signAndReset = (password) => {
    const { adminAuthId, resetTxPassword, hideModal } = this.props;
    const selectedUser = selectUserType(this.props);
    const { userId } = selectedUser;
    let userType = 'member';
    if (isAdmin([selectedUser.type])) {
      userType = 'admin';
    }
    const msg = [
      selectedUser.authId,
      adminAuthId,
      userType,
      Date.now().toString(),
    ];
    const sig = signMsg(adminAuthId, password, msg);
    resetTxPassword({ msg, sig, userId });
    hideModal();
  };

  resetMfClick = () => {
    const { authId } = selectUserType(this.props);
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: 'Reset 2FA',
      closeOnDimmerClick: true,
      closeOnEscape: true,
      confirmButtonText: 'Yes, Reset 2FA',
      cancelButtonTest: 'Cancel',
      confirmButtonColor: 'red',
      Content: (
        <div>
          Are you sure you want to reset 2FA enrollment for this user? This
          action cannot be undone!
        </div>
      ),
      hideModal: () => this.props.hideModal(),
      onConfirm: () => {
        this.props.resetMf({ authId });
        this.props.hideModal();
      },
    });
  };

  render = () => {
    const user = selectUserType(this.props);
    const {
      apiCredentialsPermissions,
      apiCredentialsPermissionsLoading,
      employeeRoles,
      routeSubmittingOrdersToApi,
      userPermissions,
      memberUserPermissionsLoading,
      memberUserTypes,
    } = this.props;
    const { saveDisabled, update } = this.state;
    const { handleChange, saveUserDetails, confirmPassword, resetMfClick } =
      this;
    if (!user) {
      // TODO: return some error that the user is not found.
      return null;
    }
    const userStates = _.map(this.props.userStates, (state) => ({
      key: state,
      value: state,
      text: state,
    }));
    const appStates = _.map(this.props.appStates, (state) => ({
      key: state,
      value: state,
      text: state,
    }));

    const props = {
      apiCredentialsPermissions,
      apiCredentialsPermissionsLoading,
      user,
      handleChange,
      saveUserDetails,
      saveDisabled,
      update,
      userStates,
      appStates,
      memberUserTypes,
      confirmPassword,
      employeeRoles,
      routeSubmittingOrdersToApi,
      userPermissionsStructure: memberUserPermissionsToStructure(
        userPermissions,
        memberUserPermissions,
      ),
      apiPermissionsStructure: memberUserPermissionsToStructure(
        apiCredentialsPermissions,
        apiKeyPermissions,
      ),
      resetMf: resetMfClick,
    };

    return (
      <Fragment>
        {this.props.memberUserPermissionsLoading ||
        this.props.apiCredentialsPermissionsLoading ? (
          <Segment padded="very" basic className="center-vertical">
            <Loader active content="Fetching settings data..." />
          </Segment>
        ) : (
          <MemberUserSettings {...props} />
        )}
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberUserSettingsContainer);
