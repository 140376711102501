import React, { Fragment, useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import { renderField, rules } from 'erisxkit/client';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import _ from 'lodash';
import { cidrIP } from '../utils/methods';
import { ADD } from '../containers/Members/AuthorizedIPs';

let ModalAddRemoveAuthorizationIPs = ({
  cancel,
  add,
  remove,
  type,
  removeIPAddress,
  ipAddress,
  comment,
}) => {
  return (
    <Fragment>
      <Modal.Header>{_.startCase(type)} IP Address</Modal.Header>
      <Modal.Content className="margin-top-10 margin-bottom-10">
        <Form>
          {type === ADD ? (
            <div>
              <Field
                validate={[rules.required, cidrIP]}
                name="ipAddress"
                id="ipAddress"
                component={renderField}
                label="IP Address"
                className={'max-width-100'}
                required={true}
              />
              <Field
                validate={[rules.required]}
                name="comment"
                id="comment"
                component={renderField}
                label="Comment"
                className={'margin-top-10 margin-bottom-10 max-width-100'}
                required={true}
              />
            </div>
          ) : (
            <div className="text-center">
              {removeIPAddress} will be removed from authorized IPs
            </div>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions className="space-between">
        <Button
          size="large"
          id="Cancel"
          onClick={() => cancel()}
          className={'button-big-width'}
        >
          {' '}
          Cancel{' '}
        </Button>
        <Button
          size="large"
          color={type === ADD ? 'teal' : 'red'}
          id="add"
          className={'button-big-width'}
          disabled={
            !(
              type === ADD &&
              comment?.length > 0 &&
              ipAddress?.length > 0 &&
              cidrIP(ipAddress) === undefined
            ) && type === ADD
          }
          onClick={() =>
            type === ADD ? add({ ipAddress, comment }) : remove()
          }
        >
          {type === ADD ? 'Add' : 'Remove IP'}
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

const selector = formValueSelector('modalAddRemoveAuthorizationIPs');

ModalAddRemoveAuthorizationIPs = connect((state) => ({
  ipAddress: selector(state, 'ipAddress'),
  comment: selector(state, 'comment'),
}))(ModalAddRemoveAuthorizationIPs);

ModalAddRemoveAuthorizationIPs = reduxForm({
  form: 'modalAddRemoveAuthorizationIPs',
})(ModalAddRemoveAuthorizationIPs);

export default ModalAddRemoveAuthorizationIPs;
