const columns = [
  {
    Header: 'Task ID',
    id: 'taskId',
    accessor: 'taskId',
  },
  {
    Header: 'State',
    id: 'state',
    accessor: 'state',
  },
  {
    Header: 'Filename',
    id: 'filename',
    accessor: (d) => d.value || '-',
  },
];

export default columns;
