import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../constants/colors';

const Action = styled.span`
  font-size: ${({ fontSize }) => fontSize};
  line-height: 22.12px;
  color: ${({ color, disabled }) => (disabled ? colors.primary3 : color)};
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '')};
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ color }) => color};
    text-decoration-thickness: ${({ underlineThickness }) =>
      underlineThickness};
  }
`;

const TextButton = ({
  color,
  disabled,
  text,
  onClick,
  className,
  fontSize,
  underlineThickness,
}) => (
  <Action
    color={color}
    disabled={disabled}
    onClick={onClick}
    className={className}
    underlineThickness={underlineThickness}
    fontSize={fontSize}
  >
    {text}
  </Action>
);

TextButton.defaultProps = {
  color: colors.accent4,
  disabled: false,
  className: '',
  underlineThickness: '2px',
  fontSize: '18px',
};
TextButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  underlineThickness: PropTypes.string,
  fontSize: PropTypes.string,
};
export default TextButton;
