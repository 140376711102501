import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Icon } from 'semantic-ui-react';
import ExternalTable from '../table/ExternalTable';

const SubExchange = ({
  createSubExchange,
  metadata,
  subExchangesCount,
  subExchangesList,
  subExchangesLoading,
}) => (
  <Fragment>
    <div className="flex-row space-between">
      <Header as="h1">SubExchange</Header>
      <Button
        floated="right"
        className="add-button"
        size="small"
        onClick={createSubExchange}
      >
        <Icon name="add" />
        Create SubExchange
      </Button>
    </div>
    <ExternalTable
      title="subExchanges"
      data={subExchangesList}
      metadata={metadata}
      count={subExchangesCount}
      loading={subExchangesLoading}
      noDataText="No SubExchanges found."
      minRows={10}
    />
  </Fragment>
);

SubExchange.propTypes = {
  createSubExchange: PropTypes.func,
  subExchangesCount: PropTypes.number,
  subExchangesList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  subExchangesLoading: PropTypes.bool,
};

SubExchange.defaultProps = {
  createSubExchange: () => {},
  subExchangesCount: 0,
  subExchangesList: [],
  subExchangesLoading: false,
};

export default SubExchange;
