import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { getFormValues, reduxForm } from 'redux-form';
import {
  fetchAchDefaultsPromiseCreator,
  getAchDefaults,
  updateAchDefaults,
} from '../../reducers/configurationReducer';
import { getCurrentUser } from '../../reducers/usersReducer';
import AchDefaults from '../../common/forms/AchDefaults';
import { grants, subjects } from '../../constants/userPermissions';
import AccessControl from '../../common/AccessControl';

const mapStateToProps = (state) => ({
  achDefaults: getAchDefaults(state),
  user: getCurrentUser(state),
  values: getFormValues('UpdateAchDefaults')(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchAchDefaultsPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      updateAchDefaults,
    },
    dispatch,
  ),
});

class AchContainer extends Component {
  componentDidMount() {
    this.props
      .fetchAchDefaultsPromiseCreator()
      .then(() => this.checkUpdateForm());
  }

  checkUpdateForm = () => {
    const { achDefaults, initialize, pristine } = this.props;
    if (pristine) {
      initialize({ ...achDefaults }, { keepDirty: true });
    }
  };

  submitAchDefaults = () => {
    const { values } = this.props;
    this.props.updateAchDefaults({ ...values });
  };

  render() {
    const { pristine, valid } = this.props;
    return (
      <Segment>
        <Form>
          <Header dividing>DM</Header>
          <AchDefaults />
          <AccessControl
            allowedPermissions={[`${grants.UPDATE}:${subjects.CONFIGURATION}`]}
            renderNoAccess={() => (
              <Message color="yellow">
                You do not have the permissions to modify these settings
              </Message>
            )}
          >
            <Button
              onClick={this.submitAchDefaults}
              disabled={pristine || !valid}
              size="large"
              className="add-button"
            >
              Update
            </Button>
          </AccessControl>
        </Form>
      </Segment>
    );
  }
}

AchContainer.propTypes = {
  achDefaults: PropTypes.objectOf(PropTypes.any),
  fetchAchDefaultsPromiseCreator: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  updateAchDefaults: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.any),
};

AchContainer.defaultProps = {
  achDefaults: {},
  pristine: true,
  valid: false,
  values: {},
};

export default reduxForm({
  form: 'UpdateAchDefaults',
})(connect(mapStateToProps, mapDispatchToProps)(AchContainer));
