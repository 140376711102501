import React from 'react';
import { get } from 'lodash';
import { Header, Button, Icon, Form } from 'semantic-ui-react';
import { withFilters, inputOnChange } from 'erisxkit/client';
import * as userTypes from '../../constants/userTypes';
import ExternalTable from '../../common/table/ExternalTable';
import XTable7 from '../../common/table/XTable7';
import { CLONE_MEMBER_USER_COLUMN } from '../../metadata/userIndexMetadata';

const FilteredExternalTable = withFilters(ExternalTable);

const filters = (userStates) => [
  {
    placeholder: 'Email',
    component: Form.Input,
    name: 'email',
    id: 'email',
    label: 'Email',
    onChange: inputOnChange,
  },
  {
    placeholder: 'User Id',
    component: Form.Input,
    name: 'user_id',
    id: 'user_id',
    label: 'User Id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'Auth Id',
    component: Form.Input,
    name: 'auth_Id',
    id: 'auth_id',
    label: 'Auth Id',
    onChange: inputOnChange,
  },
  {
    placeholder: 'State',
    component: Form.Select,
    clearable: true,
    selectOnBlur: false,
    options: userStates,
    name: 'state',
    id: 'state',
    label: 'State',
    multiple: true,
    onChange: inputOnChange,
  },
  {
    placeholder: 'Status',
    component: Form.Select,
    clearable: true,
    selectOnBlur: false,
    options: [
      { value: 'true', key: 'disabled', text: 'Disabled' },
      { value: 'false', key: 'active', text: 'Active' },
    ],
    name: 'disabled',
    id: 'disabled',
    label: 'Status',
    onChange: inputOnChange,
  },
];

const MemberIndex = React.forwardRef(
  (
    {
      data,
      metadata,
      addUser,
      selectUser,
      createMember,
      type,
      userIds,
      addMember,
      fetchData,
      loading,
      count,
      userStates,
    },
    ref,
  ) => (
    <div>
      <div className="flex-row space-between">
        <Header as="h1" dividing>
          {type === userTypes.memberUser ? 'Member Users' : 'Employees'}
        </Header>

        <Button
          onClick={userIds ? addMember : createMember}
          className="add-button"
          size="small"
          floated="right"
        >
          <Icon name="add" /> {userIds ? 'Add' : 'Create'} {type}
        </Button>
      </div>
      <XTable7
        title="MemberIndex"
        data={data}
        metadata={metadata}
        fetchData={fetchData}
        loading={loading}
        count={count}
        className="-striped -highlight"
        onCellClick={(cell) => {
          const column = get(cell, 'column.id', null);
          if (column === CLONE_MEMBER_USER_COLUMN) return;

          if (column !== 'disable') {
            const userId = get(cell, 'row.original.userId', {});
            selectUser({ userId });
          }
        }}
        showGeneralSearch={type === userTypes.memberUser}
        generalSearchOptions={[
          {
            key: 'member_name',
            text: 'Member Name',
            value: { lastName: 'lastName' },
          },
          { key: 'email', text: 'Email', value: 'email' },
        ]}
      />

      {/*
    <FilteredExternalTable
      //defaultFilters={[{ id: 'disabled', value: 'false', op: 'eq' }]}
      //wrappedComponentRef={ref}
      //noDataText="No member users were found."
      getTdProps={(state, rowInfo, column) => ({
        className: 'pointer',
        onClick: () => {
          if (column.id !== 'disable') {
            selectUser(get(rowInfo, 'original', {}));
          }
        },
      })}
      //filters={filters(userStates)}
    />
    */}
    </div>
  ),
);

export default MemberIndex;
