import React from 'react';
import _ from 'lodash';
import NavLink from 'react-router-dom/NavLink';
import { snakeCase } from 'change-case-object';
import { formatDateField, NANO_TO_MILLI_FACTOR } from './riskOverviewMetadata';
import riskAlertStatusesConst from '../constants/riskAlertStatuses';
import { Dropdown } from 'semantic-ui-react';
import { SelectColumnFilter } from '../common/table/Filters';
import { formatUSD } from '../utils/methods';

const MarginCallMetadata = (handleRiskStatusChange) => [
  {
    Header: 'Initial Entry',
    id: 'transaction_time',
    width: 200,
    accessor: ({ transactionTime }) => {
      const time = transactionTime.toString();
      const mils = parseInt(
        time.substring(0, time.length - NANO_TO_MILLI_FACTOR),
      );

      return formatDateField(mils);
    },
  },
  {
    Header: 'Member',
    id: 'member',
    accessor: 'memberLabel',
    defaultCanFilter: false,
  },
  {
    Header: 'Reg Type',
    id: 'reg_type',
    accessor: 'regType',
    defaultCanFilter: false,
  },
  {
    Header: 'Account',
    id: 'account_number',
    accessor: 'accountNumber',
    Cell: ({ row }) => (
      <NavLink
        target="_blank"
        className="text-right mono"
        to={`/balances/${_.trim(row?.original?.accountLabel || '')}`}
      >
        {row?.original?.accountNumber}
      </NavLink>
    ),
  },
  {
    Header: 'Margin Excess/Deficit',
    id: 'margin_excess_deficit',
    accessor: (row) => formatUSD(row.marginExcessDeficit),
    className: 'text-right mono',
    headerClassName: 'text-right',
    width: 180,
  },
  {
    Header: 'Funds Received',
    accessor: (row) => formatUSD(row.fundsReceived),
    id: 'funds_received',
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Delta',
    accessor: (row) => formatUSD(row.delta),
    id: 'delta',
    className: 'text-right mono',
    headerClassName: 'text-right',
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: 'status',
    width: 300,
    Filter: SelectColumnFilter,
    options: riskAlertStatusesConst.map((x) => x.text),
    Cell: ({ row }) => (
      <Dropdown
        id={row.original.id}
        onChange={(e, data) => handleRiskStatusChange(e, data)}
        accountNumber={_.get(row.original, 'accountNumber')}
        options={riskAlertStatusesConst}
        placeholder="Select status"
        selection
        previousValue={_.get(row.original, 'status', [])}
        value={_.get(
          riskAlertStatusesConst.find(
            (status) =>
              snakeCase(_.get(row, 'original.status', '')).toUpperCase() ===
              status.value,
          ),
          'value',
          '',
        )}
      />
    ),
  },
];

export default MarginCallMetadata;
