import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { XTable } from 'erisxkit/client';
import checkboxHOC from 'react-table-6/lib/hoc/selectTable';
import { Header, Accordion, Segment, Checkbox } from 'semantic-ui-react';
import ReconciliationHeader from './ReconciliationHeader';
import ReconciliationActions from './ReconciliationActions';
import ReconciliationFilters from './ReconciliationFilters';

const CheckboxTable = checkboxHOC(XTable);

class Reconciliation extends Component {
  state = {
    expanded: {},
    selection: [],
    filtered: [],
  };

  handleRowExpanded = ({ index }) => {
    this.setState({
      expanded: { [index]: !this.state.expanded[index] },
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  toggleSelection = (key, row) => {
    // This implementation uses an array stored in the component state.
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
  };

  isSelected = (key) =>
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    this.state.selection.includes(key);

  linkEntries = () => this.props.linkEntries(this.state.selection);
  filterColumn = (id, value) => {
    const newfilter = {
      id,
      value,
    };
    const filtersWhithoutNew = this.state.filtered.filter(
      (item) => item.id !== id,
    );
    this.setState({
      filtered: [...filtersWhithoutNew, newfilter],
    });
  };

  filterLinked = (row) => {
    const links = row.links.concat([row.key]);
    const filtered = [{ id: 'txId', value: links }];
    this.setState({
      filtered,
    });
  };

  removeFilter = (filter) => {
    const filtered = this.state.filtered.filter(
      (item) => item.id !== filter.id,
    );
    this.setState({
      filtered,
    });
  };

  showCheckbox = (row) =>
    !row.row.links ? (
      <Checkbox
        checked={this.state.selection.includes(row.row.key)}
        label="link"
        onChange={() => this.toggleSelection(row.row.key, row)}
      />
    ) : null;

  render = () => {
    const {
      reconciliation,
      refresh,
      metadata,
      reconciliationLoading,
      CommentsContainer,
      addComment,
      unReconciledEntries,
      reconciledEntries,
      createReconciliation,
      ...rest
    } = this.props;

    const { toggleSelection, isSelected } = this;

    const checkboxProps = {
      isSelected,
      toggleSelection,
      selectAll: false,
      SelectAllInputComponent: () => null,
      SelectInputComponent: (row) => this.showCheckbox(row),
    };

    const { activeIndex } = this.state;
    const columnMetadata = metadata(
      this.handleRowExpanded,
      this.filterLinked,
      this.props.unLinkEntries,
    );
    return (
      <Fragment>
        <ReconciliationHeader
          data={reconciliation || {}}
          reconciliationLoading={reconciliationLoading}
          createReconciliation={createReconciliation}
          {...rest}
        />
        <Segment>
          <ReconciliationActions
            selections={this.state.selection.length}
            linkEntries={this.linkEntries}
            createManualExternalTransaction={
              this.props.createManualExternalTransaction
            }
          />
          <ReconciliationFilters
            filterColumn={this.filterColumn}
            refresh={refresh}
            reconciliationLoading={reconciliationLoading}
            filtered={this.state.filtered}
            removeFilter={this.removeFilter}
          />
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Header
                color="red"
                className="flex"
                icon="dropdown"
                as="h2"
                content={`Unreconciled Transactions (${unReconciledEntries && unReconciledEntries.length}):`}
              />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <CheckboxTable
                id="unReconciledEntries"
                title="unReconciledEntries"
                ref={(r) => {
                  this.checkboxTable = r;
                }}
                loading={reconciliationLoading}
                showPageSizeOptions={false}
                showPagination={false}
                data={unReconciledEntries}
                pageSize={
                  _.get(unReconciledEntries, 'length') < 8
                    ? 8
                    : _.get(unReconciledEntries, 'length')
                }
                className="-highlight reconciliation"
                columns={columnMetadata}
                getTrProps={() => ({ className: 'negative' })}
                SubComponent={(row) => (
                  <CommentsContainer row={row} addComment={addComment} />
                )}
                expanded={this.state.expanded}
                style={{ height: '364px' }}
                {...checkboxProps}
                keyField="key"
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id])
                    .toUpperCase()
                    .includes(filter.value.toUpperCase())
                }
                filtered={this.state.filtered}
              />
            </Accordion.Content>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.handleClick}
            >
              <Header
                color="green"
                className="flex"
                icon="dropdown"
                as="h2"
                content={`Reconciled Transactions (${reconciledEntries && reconciledEntries.length}):`}
              />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <XTable
                title="reconciledEntries"
                loading={reconciliationLoading}
                showPageSizeOptions={false}
                showPagination={false}
                data={reconciledEntries}
                pageSize={
                  _.get(reconciledEntries, 'length') < 15
                    ? 15
                    : _.get(reconciledEntries, 'length')
                }
                className="-highlight reconciliation"
                columns={columnMetadata}
                getTrProps={() => ({ className: 'positive' })}
                SubComponent={(row) => (
                  <CommentsContainer row={row} addComment={addComment} />
                )}
                expanded={this.state.expanded}
                style={{ height: '600px' }}
                filtered={this.state.filtered}
              />
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  };
}

export default Reconciliation;
