import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hideModal } from 'erisxkit/client';
import { Modal, Form, Button } from 'semantic-ui-react';
import { getFormValues, reduxForm } from 'redux-form';
import { titleCase } from 'change-case';
import CreateSubExchange from '../components/CreateSubExchange';
import {
  createSubExchange,
  updateSubExchange,
} from '../reducers/subExchangesReducer';

const mapStateToProps = (state) => ({
  values: getFormValues('CreateSubExchange')(state),
});

const mapDispatchToProps = {
  createSubExchange,
  updateSubExchange,
  hideModal,
};

class CreateSubExchangeModalContainer extends Component {
  componentDidMount() {
    this.checkUpdate();
  }

  onClose = () => {
    this.props.hideModal();
  };

  onSubmit = () => {
    const { values, subExchangeId, type } = this.props;
    if (type === 'update') {
      this.props.updateSubExchange({ subExchangeId, update: values });
    } else {
      this.props.createSubExchange(values);
    }
    this.props.hideModal();
  };

  checkUpdate = () => {
    const {
      clearingFeePercentage,
      deliveryFee,
      deliveryFeeType,
      description,
      initialize,
      name,
      pristine,
      type,
    } = this.props;
    if (pristine && type === 'update') {
      // on update, only initialize with given form fields, not redux-form scaffolding
      initialize(
        {
          name,
          clearingFeePercentage,
          deliveryFee,
          deliveryFeeType,
          description,
        },
        { keepDirty: true },
      ); // flip pristine to false after initialize
    }
  };

  render() {
    const { type, valid, values } = this.props;
    return (
      <Fragment>
        <Modal.Header>
          {titleCase(type)} Sub Exchange{' '}
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={() => this.props.hideModal()}
          />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <CreateSubExchange
              clearingFeePercentage={values.clearingFeePercentage}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={this.onClose}>
            Cancel
          </Button>
          <Button color="green" disabled={!valid} onClick={this.onSubmit}>
            {titleCase(type)} Sub Exchange
          </Button>
        </Modal.Actions>
      </Fragment>
    );
  }
}

CreateSubExchangeModalContainer.propTypes = {
  clearingFeePercentage: PropTypes.string,
  createSubExchange: PropTypes.func,
  updateSubExchange: PropTypes.func,
  hideModal: PropTypes.func,
  type: PropTypes.string,
  valid: PropTypes.bool,
  values: PropTypes.objectOf(PropTypes.any),
  deliveryFee: PropTypes.string,
  deliveryFeeType: PropTypes.string,
  description: PropTypes.string,
  initialize: PropTypes.func,
  name: PropTypes.string,
  pristine: false,
  subExchangeId: PropTypes.string,
};

CreateSubExchangeModalContainer.defaultProps = {
  clearingFeePercentage: '',
  createSubExchange: () => {},
  updateSubExchange: () => {},
  hideModal: () => {},
  type: 'create',
  valid: false,
  values: {},
  deliveryFee: '',
  deliveryFeeType: '',
  description: '',
  initialize: () => {},
  name: '',
  pristine: false,
  subExchangeId: '',
};
export default reduxForm({
  form: 'CreateSubExchange',
})(
  connect(mapStateToProps, mapDispatchToProps)(CreateSubExchangeModalContainer),
);
