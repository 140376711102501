export default [
  {
    Header: 'Sent Date',
    id: 'sentDate',
    accessor: 'sentDate',
  },
  {
    Header: 'Last Submitted Date',
    id: 'lastSubmittedDate',
    accessor: 'lastSubmittedDate',
  },
  {
    Header: 'Last Modified Date',
    id: 'lastModifiedDate',
    accessor: (row) => (row.isModification ? row.sentDate : ''),
  },
  {
    Header: 'Firm Id',
    id: 'reportingFirm',
    accessor: 'reportingFirm',
  },
  {
    Header: 'Special Account Number',
    id: 'accountNumber',
    accessor: 'accountNumber',
  },
  {
    Header: 'Special Account Name',
    id: 'accountName',
    accessor: 'accountName',
  },
  {
    Header: 'Reportable Account Type',
    id: 'reportableAccountType',
    accessor: 'reportableAccountType',
  },
];
