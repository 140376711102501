import React, { Fragment, Component, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Header, Tab } from 'semantic-ui-react';
import get from 'lodash/get';
import CloseoutJournalsContainer from './CloseoutJournalsContainer';
import XTab from '../../common/tabs/XTab';
import DcoPositionsContainer from './DcoPositionsContainer';
import DcoMarginPositionsContainer from './DcoMarginPositionsContainer';
import MarginCallContainer from './MarginCallContainer';
import MarginDelivery from './MarginDelivery';
import { getCurrentUserUiViews } from '../../reducers/usersReducer';
import { userCanReadUIView } from '../../utils/uiViewsUtils';
import ADMIN_UI_VIEWS from '../../constants/adminUIViews';

// Since positions and deliveries tab use the same component, semantic-ui
// does not unmount it on tab change. To workaround it we use a wrapper component
// TODO: Refactor
const FilteredPositions = () => <DcoPositionsContainer excludeMargin />;

const panes = [
  {
    url: 'positions',
    menuItem: 'Positions (OLD)',
    requiredPermission: ADMIN_UI_VIEWS.OLD_POSITIONS_TAB,
    render: () => (
      <Tab.Pane attached={false}>
        <FilteredPositions />
      </Tab.Pane>
    ),
  },
  {
    url: 'closeouts',
    menuItem: 'Closeout Journals (OLD)',
    requiredPermission: ADMIN_UI_VIEWS.OLD_CLOSEOUT_JOURNALS_TAB,
    render: () => (
      <Tab.Pane attached={false}>
        <CloseoutJournalsContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'deliveries',
    menuItem: 'Deliveries (OLD)',
    requiredPermission: ADMIN_UI_VIEWS.OLD_DELIVERIES_TAB,
    render: () => (
      <Tab.Pane attached={false}>
        <DcoPositionsContainer isDelivery />
      </Tab.Pane>
    ),
  },
  {
    url: 'margin_positions',
    menuItem: 'Margin Positions',
    render: () => (
      <Tab.Pane attached={false}>
        <DcoMarginPositionsContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'margin_call',
    menuItem: 'Margin Call',
    render: () => (
      <Tab.Pane attached={false}>
        <MarginCallContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'margin_delivery',
    menuItem: 'Margin Delivery',
    render: () => (
      <Tab.Pane attached={false}>
        <MarginDelivery />
      </Tab.Pane>
    ),
  },
];

const canReadPane = (pane, userUIViews) => {
  const requiredPermission = get(pane, 'requiredPermission');
  // If no required permission is set, anyone can see tab
  return !requiredPermission
    ? true
    : userCanReadUIView(requiredPermission, userUIViews);
};

const TransactionLogsContainer = () => {
  const uiViews = useSelector(getCurrentUserUiViews);
  const filteredPanes = useMemo(
    () => panes.filter((pane) => canReadPane(pane, uiViews)),
    [uiViews],
  );

  return (
    <Fragment>
      <Header as="h1">
        <Header.Content>Positions</Header.Content>
      </Header>
      <XTab panes={filteredPanes} />
    </Fragment>
  );
};

export default TransactionLogsContainer;
