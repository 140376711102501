import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import metadata from '../metadata/aboutMetadata';
import { fetchAbout } from '../actions/utilActions';
import { getAbout } from '../reducers/aboutReducer';
import About from '../components/About';

const mapStateToProps = (state) => ({ about: getAbout(state) });
const mapDispatchToProps = {
  fetchAbout,
};

class AboutContainer extends Component {
  componentDidMount() {
    // fetch the data
    this.props.fetchAbout();
  }

  render() {
    const { about = [] } = this.props;
    return <About data={about} metadata={metadata} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer);
