import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'semantic-ui-react';
import { useAppSelector } from '../hooks/useAppSelector';
import { RootState } from '../ts/types/store';

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: center;
  &&& {
    padding: 1rem !important;
  }
`;

const StyledButton = styled(Button)`
  min-width: 150px;
  margin: 0px 15px;
`;
const ActionsMarginBottom = styled(Actions)`
  margin-bottom: ${(props) => props.marginBottom};
`;

type ButtonProps = {
  onClick: (params: any) => void;
  text: string;
  disabled?: boolean;
};

export type GenericModalProps = {
  content: string | JSX.Element;
  confirmProps?: ButtonProps;
  cancelProps?: ButtonProps;
  header?: string;
  loadingSelector?: (state: RootState) => boolean;
  marginBottom?: string;
};

const GenericModal = ({
  confirmProps,
  cancelProps,
  header,
  content,
  loadingSelector = () => false,
  marginBottom = '0px',
}: GenericModalProps) => {
  const loading = useAppSelector((state: RootState) => loadingSelector(state));
  return (
    <Fragment>
      {header && <Modal.Header>{header}</Modal.Header>}
      <Modal.Content>{content}</Modal.Content>
      <ActionsMarginBottom marginBottom={marginBottom}>
        {cancelProps && (
          <Button secondary {...cancelProps}>
            {cancelProps?.text}
          </Button>
        )}
        {confirmProps && (
          <StyledButton
            primary
            {...confirmProps}
            disabled={confirmProps?.disabled || loading}
            data-cy="generic-modal-confirm-btn"
          >
            {confirmProps?.text}
          </StyledButton>
        )}
      </ActionsMarginBottom>
    </Fragment>
  );
};

export default GenericModal;
