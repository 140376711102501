import * as actionTypes from '../constants/actionTypes';

/**
 * Action generator to store user information obtained from an access token.
 * @param {Object} user - The information obtained from decoding the token.
 */
export const storeUser = (user) => ({
  type: actionTypes.STORE_USER,
  payload: user,
});
