import React from 'react';
import PropTypes from 'prop-types';

const DetailItem = ({ title, text }) => (
  <div className="info-item">
    <p className="info-item-title">{title}</p>
    <p className="info-item-text">{text}</p>
  </div>
);

DetailItem.defaultProps = {
  title: '',
  text: 'PropTypes.string',
};

DetailItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default DetailItem;
