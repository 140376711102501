import React, { Component } from 'react';
import { connect } from 'react-redux';
import XTab from '../../common/tabs/XTab';
import AddressSurveillanceContainer from './AddressSurveillanceContainer';
import TransactionSurveillanceContainer from './TransactionSurveillanceContainer';
import RegReportsContainer from './RegReportsContainer';
import { assetTypes } from '../../actions/utilActions';
import largeTraderViewMetadata from '../../metadata/largeTraderViewMetadata';
import clearingVolumeMetadata from '../../metadata/clearingVolumeMetadata';
import ocrDataMetadata from '../../metadata/ocrDataMetadata';
import missingOcrDataMetadata from '../../metadata/missingOcrDataMetadata';
import SystemAlertsContainer from '../SystemLogs/SystemAlertsContainer';
import complianceAlertComponents from '../../constants/complianceAlertComponents';
import complianceAlertCategories from '../../constants/complianceAlertCategories';

const regReports = [
  {
    url: 'large_trader',
    menuItem: 'Rank by Size',
    metadata: largeTraderViewMetadata,
  },
  {
    url: 'clearing_volume',
    menuItem: 'Reconciliation',
    metadata: clearingVolumeMetadata,
  },
  {
    url: 'ocr_data',
    menuItem: 'OCR Data',
    metadata: ocrDataMetadata,
  },
  {
    url: 'missing_ocr_data',
    menuItem: 'Missing OCR Data',
    metadata: missingOcrDataMetadata,
  },
];

const panes = [
  {
    url: 'system_alerts',
    menuItem: 'Alerts',
    render: () => (
      <SystemAlertsContainer
        requiredFilters={[
          { attr: 'component', op: 'match', value: complianceAlertComponents },
          { attr: 'category', op: 'match', value: complianceAlertCategories },
        ]}
      />
    ),
  },
  {
    url: 'addresses',
    menuItem: 'Addresses',
    render: () => <AddressSurveillanceContainer />,
  },
  {
    url: 'transactions',
    menuItem: 'Transactions',
    render: () => <TransactionSurveillanceContainer />,
  },
];

const regPanes = regReports.map((r) => ({
  render: () => (
    <RegReportsContainer key={r.url} subject={r.url} metadata={r.metadata} />
  ),
  ...r,
}));

class SurveillanceContainer extends Component {
  componentDidMount = () => {
    this.props.assetTypes();
  };
  render = () => <XTab panes={panes.concat(regPanes)} />;
}
export default connect(null, { assetTypes })(SurveillanceContainer);
