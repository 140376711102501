import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sign from 'eris-ecdsa';
import XTable7 from '../../common/table/XTable7';
import authorizedIPsMetadata from '../../metadata/authorizedIPsMetadata';
import {
  fetchAuthorizedIps,
  getAuthorizedIps,
  addAuthorizedIpPromiseCreator,
  removeAuthorizedIpPromiseCreator,
} from '../../reducers/authorizationIpReducer';
import { showModal, hideModal, createLoadingSelector } from 'erisxkit/client';
import { Button } from 'semantic-ui-react';
import {
  ADD_REMOVE_AUTHORIZATION_IPS,
  ADMIN_KEY,
} from '../../constants/modalTypes';
import {
  getCurrentAuthId,
  getCurrentUserRoles,
} from '../../reducers/usersReducer';
import { roles } from '../../constants/userRoles';
import RolesAccessControl from '../../common/RolesAccessControl';
import {
  authorizedIpRoles,
  authorizedWriteIpRoles,
} from '../../reducers/employeesReducer';

export const REMOVE = 'remove';
export const ADD = 'add';

const AuthorizedIPs = ({ selectedMember }) => {
  const dispatch = useDispatch();
  const authorizedIpsObj = useSelector(getAuthorizedIps);
  const authorizedIpsLoading = useSelector(
    createLoadingSelector([fetchAuthorizedIps._PREFIX]),
  );
  const authId = useSelector(getCurrentAuthId);
  const userRoles = useSelector(getCurrentUserRoles);

  const _fetchAuthorizedIps = () => {
    dispatch(fetchAuthorizedIps({ memberIds: [selectedMember?.memberId] }));
  };

  const addIPAddress = async (data, password) => {
    const privateKey = sign.privateKeyFromPassword(authId, password);
    const msg = [authId, data.ipAddress, data.comment, Date.now().toString()];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);

    const payload = {
      ipAddress: data.ipAddress,
      comment: data.comment,
      memberId: selectedMember.memberId,
      msg,
      sig,
    };
    try {
      await addAuthorizedIpPromiseCreator(payload, dispatch);
      _fetchAuthorizedIps();
    } catch (error) {
      console.log('Error on add authorized IP');
    } finally {
      dispatch(hideModal());
    }
  };

  const removeIPAddress = async (ipAddress, password) => {
    const privateKey = sign.privateKeyFromPassword(authId, password);
    const msg = [authId, ipAddress, Date.now().toString()];
    const sig = sign.signMsgBs58(JSON.stringify(msg), privateKey);
    const payload = { ipAddress, memberId: selectedMember.memberId, msg, sig };

    try {
      await removeAuthorizedIpPromiseCreator(payload, dispatch);
      _fetchAuthorizedIps();
    } catch (error) {
      console.log('Error on remove authorized IP');
    } finally {
      dispatch(hideModal());
    }
  };

  const showFundingPassword = (data, type) => {
    if (type === REMOVE) {
      dispatch(
        showModal(ADMIN_KEY, {
          submit: (password) => removeIPAddress(data.ipAddress, password),
        }),
      );
    }
    if (type === ADD) {
      dispatch(
        showModal(ADMIN_KEY, {
          submit: (password) => addIPAddress(data, password),
        }),
      );
    }
  };

  const onRemoveAuthorizedIp = (ipAddress) => {
    dispatch(
      showModal(ADD_REMOVE_AUTHORIZATION_IPS, {
        type: REMOVE,
        removeIPAddress: ipAddress,
        cancel: () => dispatch(hideModal()),
        remove: () => showFundingPassword({ ipAddress }, REMOVE),
      }),
    );
  };

  const onAddAuthorizedIp = (type) => {
    dispatch(
      showModal(ADD_REMOVE_AUTHORIZATION_IPS, {
        type: type,
        cancel: () => dispatch(hideModal()),
        add: (data) => showFundingPassword(data, ADD),
      }),
    );
  };

  const readOnly = !userRoles.some((item) =>
    authorizedWriteIpRoles.includes(item),
  );
  return (
    <>
      <div style={{ display: 'block', height: '50px' }}>
        <RolesAccessControl
          allowedRoles={[
            `${roles.SUPER_USER}`,
            `${roles.ONBOARDING}`,
            `${roles.CLIENT_SERVICES}`,
          ]}
          renderNoAccess={() => <></>}
        >
          <Button
            className="add-button pull-right"
            onClick={() => {
              onAddAuthorizedIp(ADD);
            }}
          >
            Add IP Address
          </Button>
        </RolesAccessControl>
      </div>
      <XTable7
        data={authorizedIpsObj?.authorizedIps || []}
        metadata={authorizedIPsMetadata(onRemoveAuthorizedIp, readOnly)}
        title="authorizedIPS"
        fetchData={_fetchAuthorizedIps}
        loading={authorizedIpsLoading}
        showFilter={false}
        count={authorizedIpsObj?.count || 0}
      />
    </>
  );
};

export default AuthorizedIPs;
