import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getCurrentUser } from '../reducers/usersReducer';

/**
 * @param {string []} userRoles  - list of user roles to check
 * @returns boolean indicating if current user belongs to any of the given user roles
 */
const useRoleValidation = (userRoles = []) => {
  const currentUser = useSelector(getCurrentUser);
  return get(currentUser, 'roles', []).some((role) => userRoles.includes(role));
};

export default useRoleValidation;
