import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createLoadingSelector,
  filteredArrayToAttrValue,
  getItemsCount,
  getItemsPaged,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import MemberUserIndex from '../../components/MemberUsers/MemberUserIndex';
import userIndexMetadata from '../../metadata/userIndexMetadata';
import { updateUser, createMember, USERS } from '../../reducers/usersReducer';
import {
  fetchEmployees,
  selectEmployee,
} from '../../reducers/employeesReducer';
import * as userTypes from '../../constants/userTypes';
import { showModal, hideModal } from 'erisxkit/client';
import history from '../../constants/history';
import { CREATE_MEMBER } from '../../constants/modalTypes';

const mapStateToProps = (state = {}) => ({
  usersList: getItemsPaged('employees')(state),
  values: _.get(state, ['form', 'create_user', 'values'], {}),
  membersLoading: createLoadingSelector([USERS])(state),
  count: getItemsCount('employees')(state),
});
const mapDispatchToProps = {
  fetchEmployees,
  selectEmployee,
  showModal,
  updateUser,
  createMember,
  hideModal,
};

const getFilterOp = (columns, id) => {
  const column = columns.find((c) => c.id === id);
  return column?.filterOp || DEFAULT_FILTER_OP;
};

class EmployeeIndexContainer extends Component {
  componentDidMount = () => {
    //Search default just for active ones on the first time for employees and member users
    if (!history.location.search.includes('disabled')) {
      history.replace({
        ...history.location,
        search: `disabled=false`,
      });
    }
  };

  fetchData = ({ pageSize, pageIndex, filters, sortBy, columns }) => {
    // initialize the filter to be only employees, this is required.
    let filter = [{ attr: 'type', op: 'eq', value: userTypes.employee }];

    const historySearch = history.location.search;

    //If exists in the url the parameter disabled prepare the filter for the backend method
    if (
      historySearch.includes('disabled') &&
      (filters?.length === 0 || filters.some((x) => x.id === 'disabled'))
    ) {
      if (historySearch.includes('disabled=true')) {
        filter.push({
          attr: 'disabled',
          value: 'true',
          op: 'eq',
        });
      }
      if (historySearch.includes('disabled=false')) {
        filter.push({
          attr: 'disabled',
          value: 'false',
          op: 'eq',
        });
      }
    }

    if (filters?.length !== 0) {
      let _filter = [];
      filters.forEach((each) => {
        switch (each.id) {
          case 'status':
            //prepare the backend method filter for status column --> disabled parameter
            _filter.push({
              id: 'disabled',
              value: each.value === 'disabled' ? 'true' : 'false',
              op: 'eq',
            });
            break;
          case 'memberLinks':
            //prepare the backend method filter for memberLinks (memberName)
            for (const property in each.value) {
              if (each.value[property].length > 0) {
                _filter.push({
                  id: snakeCase(property),
                  value: each.value[property],
                  op: 'contain',
                });
              }
            }
            break;
          case 'member_name':
            if (each.value.firstName !== '') {
              _filter.push({
                id: 'first_name',
                op: 'contain',
                value: each.value.firstName,
              });
            }
            if (each.value.middleName !== '') {
              _filter.push({
                id: 'middle_name',
                op: 'contain',
                value: each.value.middleName,
              });
            }
            if (each.value.lastName !== '') {
              _filter.push({
                id: 'last_name',
                op: 'contain',
                value: each.value.lastName,
              });
            }
            break;
          default:
            //Avoid disabled filter be sent to the backend, because it is in the status column
            if (each.id !== 'disabled') {
              _filter.push({
                ...each,
                op: getFilterOp(columns, each.id),
              });
            }
        }
      });
      filter = filter.concat(filteredArrayToAttrValue(_filter));
    }

    this.props.fetchEmployees({
      limit: pageSize,
      offset: pageIndex * pageSize,
      page: pageIndex,
      filter,
      includeName: true,
    });
  };

  userDetails = ({ userId }) => {
    // 'select the user'
    this.props.selectEmployee(userId);
    history.push(`/employees/${userId}`);
  };
  createUserModal = () => {
    this.props.showModal(CREATE_MEMBER, {
      hideModal: this.props.hideModal,
      onApply: this.createEmployee,
      type: userTypes.employee,
    });
  };

  createEmployee = () => {
    this.props.createMember({
      type: userTypes.employee,
      payload: this.props.values,
    });
    this.props.hideModal();
  };

  render() {
    const metadata = userIndexMetadata(() => {}, userTypes.employee);
    const { usersList, membersLoading } = this.props;

    return (
      <MemberUserIndex
        metadata={metadata}
        data={usersList}
        type={userTypes.employee}
        createMember={this.createUserModal}
        fetchData={this.fetchData}
        count={this.props.count}
        selectUser={this.userDetails}
        loading={membersLoading}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeIndexContainer);
