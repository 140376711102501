import { get } from 'lodash';
import * as actionTypes from '../constants/actionTypes';

export default function unusedWalletsReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.UNUSED_WALLETS_REQUEST:
      return {};
    case actionTypes.UNUSED_WALLETS_SUCCESS:
      return {
        ...state,
        unusedWallets: action.unusedWallets,
      };
    default:
      return state;
  }
}

export const getUnusedWallets = (state) =>
  get(state, ['unusedWalletsReducer', 'unusedWallets'], []);
