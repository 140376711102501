import React, { Fragment } from 'react';
import {
  Button,
  Form as SemanticForm,
  Modal,
  Divider,
} from 'semantic-ui-react';
import {
  renderField,
  states,
  renderDropdown,
  renderCheckbox,
} from 'erisxkit/client';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { MAXIMUM_UBOS } from './UBOContainer';

const AddUBOContainer = ({ confirm, hideModal, initialValues, type }) => (
  <Fragment>
    <Modal.Header>Add UBO</Modal.Header>
    <Form
      onSubmit={(data) => {
        confirm(data);
      }}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({
        handleSubmit,
        form: {
          mutators: { push },
        },
        pristine,
        form,
        submitting,
        values,
      }) => (
        <Fragment>
          <Modal.Content>
            <SemanticForm>
              <FieldArray name="ubo">
                {({ fields }) =>
                  fields.map((name, index) => (
                    <div key={name}>
                      <SemanticForm.Group>
                        <Field
                          name={`${name}.name`}
                          component={renderField}
                          label="Name"
                          placeholder="Name"
                        />
                        <Field
                          component={renderField}
                          placeholder="Percentage Owned"
                          label="Percentage Owned"
                          name={`${name}percentageOwned`}
                        />
                      </SemanticForm.Group>
                      <Field
                        name={`${name}address1`}
                        component={renderField}
                        label="Street Address"
                      />
                      <Field
                        name={`${name}address2`}
                        component={renderField}
                        label="Street Address 2"
                      />
                      <SemanticForm.Group>
                        <Field
                          name={`${name}city`}
                          component={renderField}
                          label="City"
                        />
                        <Field
                          name={`${name}locationState`}
                          component={renderDropdown}
                          label="State"
                          options={states.map((state) => ({
                            key: state,
                            value: state,
                            text: state,
                          }))}
                        />
                        <Field
                          name={`${name}postalCode`}
                          component={renderField}
                          label="Postal Code"
                        />
                        <Field
                          name={`${name}country`}
                          component={renderField}
                          label="Country"
                        />
                      </SemanticForm.Group>
                      <div className="flex space-between">
                        <Field
                          name={`${name}documentsReceived`}
                          component={renderCheckbox}
                          label="Documentation Received"
                        />
                        <Field
                          name={`${name}controller`}
                          component={renderCheckbox}
                          label="Controller"
                        />
                        <Button
                          className="link negative"
                          content="Remove"
                          type="button"
                          onClick={() => fields.remove(index)}
                          // disabled when there is only one ubo
                          disabled={values.ubo.length === 1}
                        />
                      </div>
                      <Divider />
                    </div>
                  ))
                }
              </FieldArray>
              {type === 'create' && (
                <Button
                  className="link"
                  content="+Add Another UBO"
                  type="button"
                  onClick={() => push('ubo', {})}
                  disabled={values.ubo.length >= MAXIMUM_UBOS}
                />
              )}
            </SemanticForm>
          </Modal.Content>
          <Modal.Actions>
            <Button id="xwizard-cancel-button" onClick={hideModal}>
              Cancel
            </Button>
            <Button id="" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Actions>
        </Fragment>
      )}
    />
  </Fragment>
);

export default AddUBOContainer;
