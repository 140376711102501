import React, { Fragment } from 'react';
import _ from 'lodash';
import { Card, Form, Header, Icon, Segment } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { renderDropdown, renderField } from 'erisxkit/client';
import PropTypes from 'prop-types';
import { TaskDetails } from './TaskLog';
import OptionsToolbar from '../../common/components/OptionsToolbar';

const ArgumentCards = ({ change, name, options, selectedTaskOptions }) =>
  !_.isEmpty(selectedTaskOptions) ? (
    <Card.Group
      items={selectedTaskOptions.map(
        ({ desc, required, short, type, argument }) => ({
          color: required ? 'red' : undefined,
          description: desc,
          extra: required ? (
            <span>
              <Icon color="red" name="asterisk" /> Required{' '}
            </span>
          ) : null,
          header: (
            // if short key exists and is not 'none', render it alongside longform argument
            <p className="mono">
              {short && short !== 'none' ? `--${short},` : ''}--{argument}
            </p>
          ),
          key: argument,
          link: true,
          meta: <span className="darkGrey mono">[{type || '*'}]</span>,
          onClick: () => {
            // don't append argument to options if it or its shortened form exists
            if (
              options.indexOf(`--${argument}`) === -1 &&
              options.indexOf(`--${short}`) === -1
            ) {
              change('options', `${options} --${argument} `);

              // redux-form suggested method to focus an input.
              document.getElementById('options').focus();
            }
          },
        }),
      )}
    />
  ) : (
    <Segment className="italic" secondary={_.isEmpty(name)} compact>
      {!_.isEmpty(name)
        ? 'This task has no arguments.'
        : 'Applicable arguments will appear here.'}
    </Segment>
  );

const Tasks = ({
  change,
  focus,
  handleSubmit,
  name,
  options,
  selectedTaskOptions,
  showDebug,
  taskNameDropdownOptions,
  taskInfoLoading,
  taskLogs,
  taskLogsLoading,
  toggleDebug,
  triggerTask,
}) => (
  <Fragment>
    <Segment>
      <Form onSubmit={handleSubmit(triggerTask)}>
        <Field
          component={renderDropdown}
          clearable
          label="Task Name"
          loading={taskInfoLoading}
          name="name"
          noResultsMessage={
            taskInfoLoading ? 'Loading task names...' : 'No tasks found.'
          }
          options={taskNameDropdownOptions}
          placeholder="Eris::[Application]::[Task]"
          search
          selection
        />
        <Field
          component={renderField}
          className="command-line"
          fluid
          id="options"
          label="Options"
          loading={taskInfoLoading}
          name="options"
          placeholder={selectedTaskOptions
            .map(({ argument, type }, i) => `--${argument} [${type}]`)
            .join(' ')}
        />
        <Segment basic>
          <Header as="h3">Arguments</Header>
          <ArgumentCards
            change={change}
            name={name}
            options={options}
            selectedTaskOptions={selectedTaskOptions}
          />
        </Segment>
        <Form.Button type="submit" primary disabled={taskLogsLoading  || !name}>
          Run Task
        </Form.Button>
      </Form>
      {!_.isEmpty(taskLogs) || taskLogsLoading ? (
        <Segment>
          <OptionsToolbar
            groupProps={{
              compact: true,
              size: 'mini',
            }}
            toggleProps={{
              id: 'toggleDebug',
              toggle: toggleDebug,
              toggled: showDebug,
              text: 'Show debug information',
            }}
          />
          <TaskDetails showDebug={showDebug} taskLogs={taskLogs} />
        </Segment>
      ) : null}
    </Segment>
  </Fragment>
);

Tasks.propTypes = {
  options: PropTypes.string,
  selectedTaskOptions: PropTypes.arrayOf(PropTypes.object),
  taskNameDropdownOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ),
  taskInfoLoading: PropTypes.bool,
  taskLogsLoading: PropTypes.bool,
};

Tasks.defaultProps = {
  options: '',
  selectedTaskOptions: [],
  taskNameDropdownOptions: [],
  taskInfoLoading: false,
  taskLogsLoading: false,
};

export default reduxForm({
  form: 'taskTrigger',
})(Tasks);
