import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Header, Button, Message } from 'semantic-ui-react';
import AccountOveride from '../../components/ClearingMemberAccounts/AccountOverride';
import {
  fetchAchDefaults,
  getAchDefaults,
} from '../../reducers/configurationReducer';
import {
  fetchAccountOverrides,
  getAccountOverrides,
  updateAccountOverrides,
} from '../../reducers/achReducer';
import { grants, subjects } from '../../constants/userPermissions';
import AccessControl from '../../common/AccessControl';

const AccountOverrideContainer = ({
  selectedAccount,
  change,
  initialize,
  pristine,
  valid,
}) => {
  const dispatch = useDispatch();
  const achMemberParams = useSelector(getAccountOverrides);
  const achDefaults = useSelector(getAchDefaults);
  const formValues = useSelector(getFormValues('UpdateMemberAchParams'));
  const updateMemberParams = () => {
    dispatch(
      updateAccountOverrides({
        ...formValues,
        accountId: selectedAccount.accountId,
      }),
    );
    initialize({ ...formValues }, { keepDirty: true });
  };
  useEffect(() => {
    dispatch(fetchAchDefaults());
    dispatch(fetchAccountOverrides({ accountId: selectedAccount.accountId }));
  }, []);
  useEffect(() => {
    if (pristine) {
      initialize({ ...achMemberParams }, { keepDirty: true });
    }
  }, [achMemberParams]);
  const setDefaults = () => {
    change('depositFee', '-1.0');
    change('withdrawalFee', '-1.0');
    change('maxDeposit', '-1.0');
    change('minDeposit', '-1.0');
    change('depositPeriod', '-1');
    change('embargoPeriod', '-1');
    change('instantAchEnabled', false);
  };
  return (
    <Fragment>
      <div className="flex-row space-between">
        <Header as="h1" dividing>
          ACH Banking
        </Header>
        <Button
          as="h5"
          className="link edit"
          content="Reset all to default"
          onClick={setDefaults}
        />
      </div>
      <AccountOveride
        {...formValues}
        achDefaults={achDefaults}
        change={change}
      />
      <AccessControl
        allowedPermissions={[`${grants.UPDATE}:${subjects.CONFIGURATION}`]}
        renderNoAccess={() => (
          <Message color="yellow">
            You do not have the permissions to modify these settings
          </Message>
        )}
      >
        <Button
          size="large"
          disabled={pristine || !valid}
          className="add-button"
          onClick={updateMemberParams}
        >
          Update
        </Button>
      </AccessControl>
    </Fragment>
  );
};

AccountOverrideContainer.propTypes = {
  change: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  selectedAccount: PropTypes.objectOf(PropTypes.any).isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({ form: 'UpdateMemberAchParams' })(
  AccountOverrideContainer,
);
