import { call, takeLatest, put } from 'redux-saga/effects';
import { find } from 'lodash';
import api, * as urls from '../api';
import * as actionTypes from '../constants/actionTypes';
import {
  cryptoAddressAnalyses,
  cryptoTransactionAnalyses,
} from '../actions/surveillanceActions';
import { createSaga } from '../utils/createSaga';
import { surveillanceEntries } from '../reducers/surveillanceReducer';

function* fetchCryptoAddressAnalyses({ payload }) {
  try {
    yield put(cryptoAddressAnalyses.request());
    const data = yield call(
      api.post,
      urls.CRYPTO_ADDRESS_ANALYSES_API_ENDPOINT,
      payload,
    );
    yield put(cryptoAddressAnalyses.success(data));
  } catch (e) {
    yield put(cryptoAddressAnalyses.failure(e.response));
  }
}

function* fetchSurveillanceEntries({ payload }) {
  try {
    let priorData = {};
    yield put(surveillanceEntries.request());
    const data = yield call(
      api.post,
      urls.SURVEILLANCE_ENTRIES_API_ENDPOINT,
      payload,
    );
    // get previous day data
    if (payload.priorDate) {
      const priorDateArgs = {
        ...payload,
        filter: payload.filter.map((o) => {
          if (o.attr === 'trade_date') {
            return {
              attr: 'trade_date',
              op: 'eq',
              value: payload.priorDate,
            };
          }
          return o;
        }),
      };

      priorData = yield call(
        api.post,
        urls.SURVEILLANCE_ENTRIES_API_ENDPOINT,
        priorDateArgs,
      );
    }
    // add the previous day data into the entries array where account number, product, and contract matches.
    const newData = {
      ...data,
      entries: data.entries.map(
        ({ accountNumber, product, contract, ...rest }) => {
          const prevEntry = find(priorData.entries, {
            accountNumber,
            product,
            contract,
          });
          return {
            ...rest,
            product,
            accountNumber,
            contract,
            ...(prevEntry && {
              priorLong: prevEntry.long || 0,
              priorShort: prevEntry.short || 0,
              priorPercentOfMarket: prevEntry.percentOfMarket || 0,
              priorPercentNotionalValue: prevEntry.percentNotionalValue || 0,
              priorPositionEodLong: prevEntry.positionEodLong || 0,
              priorPositionEodShort: prevEntry.positionEodShort || 0,
            }),
          };
        },
      ),
    };
    yield put(surveillanceEntries.success(newData));
  } catch (e) {
    yield put(surveillanceEntries.failure(e.response));
  }
}

export default function* watchSurveillance() {
  yield takeLatest(cryptoAddressAnalyses.TRIGGER, fetchCryptoAddressAnalyses);
  yield takeLatest(
    cryptoTransactionAnalyses.TRIGGER,
    createSaga(
      cryptoTransactionAnalyses,
      actionTypes.CRYPTO_TRANSACTION_ANALYSES,
    ),
  );
  yield takeLatest(surveillanceEntries.TRIGGER, fetchSurveillanceEntries);
}
