import React, { Fragment } from 'react';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { renderCheckbox, renderDateTime } from 'erisxkit/client';
import { Form } from 'semantic-ui-react';

const renderExchangeEntitlements = ({
  fields,
  showSupportCheckboxes,
  showDates,
}) => (
  <Fragment>
    {fields.map((field, i) => (
      <Fragment>
        <Form.Group>
          <Field
            component={renderCheckbox}
            name={`${field}.allowSubExchange`}
            label={`Allow ${fields.get(i).name} Futures`}
            key={i}
          />
          {showDates && (
            <Fragment>
              <label className="field nowrap" htmlFor="onboardingStarted">
                {'Onboarding Started'}
                <Field
                  className="ui date-time"
                  clearable
                  component={renderDateTime}
                  inputProps={{
                    placeholder: 'MM/DD/YYYY',
                    readOnly: false,
                  }}
                  name={`${field}.onboardingStarted`}
                />
              </label>
              <label className="field nowrap" htmlFor="onboardingComplete">
                {'Documents Complete'}
                <Field
                  className="ui date-time"
                  clearable
                  component={renderDateTime}
                  inputProps={{
                    placeholder: 'MM/DD/YYYY',
                    readOnly: false,
                  }}
                  name={`${field}documentsComplete`}
                />
              </label>
            </Fragment>
          )}
        </Form.Group>
        {showSupportCheckboxes && (
          <Field
            component={renderCheckbox}
            name={`${field}.allowSupport`}
            label={`Allow ${fields.get(i).name} Support`}
            key={i}
          />
        )}
      </Fragment>
    ))}
  </Fragment>
);

const SubExchangeEntitlementsContainer = ({
  name,
  id,
  label,
  placeholder,
  subExchanges,
  subExchangesLoading,
  showSupportCheckboxes,
  showDates,
}) => (
  <FieldArray
    name="subExchangeEntitlements"
    component={renderExchangeEntitlements}
    showSupportCheckboxes={showSupportCheckboxes}
    showDates={showDates}
  />
);

SubExchangeEntitlementsContainer.propTypes = {
  label: PropTypes.string,
  fetchSubExchanges: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  validate: PropTypes.array,
  subExchangesOptions: PropTypes.array.isRequired,
  subExchangesLoading: PropTypes.bool.isRequired,
};

SubExchangeEntitlementsContainer.defaultProps = {
  name: 'subExchangeId',
  placeholder: 'Select Sub Exchange...',
  label: 'Sub Exchanges',
};

export default SubExchangeEntitlementsContainer;
