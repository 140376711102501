export const INVESTMENT_ADVISOR_MEMBER = {
  name: 'IA - Investment Advisor',
  type: 'investment_manager',
  code: 'IA',
};
export const DIRECT_MEMBER = {
  name: 'IN - Direct Member',
  type: 'direct',
  code: 'IN',
};
export const FIRM_MEMBER = {
  name: 'EN - Firm Member',
  type: 'firm',
  code: 'EN',
};
export const VENDOR_MEMBER = {
  name: 'VM - Vendor Member',
  type: 'vendor',
  code: 'VM',
};
export const EXECUTION_BROKER_MEMBER = {
  name: 'EB - Executing Broker',
  type: 'execution_broker',
  code: 'EB',
};
export const INTERMEDIARY = {
  name: 'IM - Intermediary',
  type: 'intermediary',
  code: 'IM',
};
export const FCM = { name: 'FCM - FCM', type: 'fcm', code: 'FCM' };
