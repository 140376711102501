import React, { Fragment } from 'react';
import { XTable } from 'erisxkit/client';
import DateTime from 'react-datetime';
import { Header, Icon, Button, Popup } from 'semantic-ui-react';
import metadata from '../metadata/reconciliationHistoryMetadata';
import { formatTimeLocalWithDoW, endingBusinessTime } from '../utils/time.js';
import history from '../constants/history';

const ReconciliationHistory = ({
  selectedAccount,
  startReconciliation,
  reconciliationHistory,
  viewReconciliationHistory,
  valid,
  dateTimeChanged,
  endTime,
  daysPrevious,
  daysPreviousChanged,
}) => (
  <Fragment>
    <div className="vert-flex">
      <Header floated="left" as="h1">
        <Icon
          name="left arrow"
          className="back-link"
          onClick={() => history.goBack()}
        />
        <Header.Content>
          Reconciliation History for {selectedAccount.label}
          <Header.Subheader>{selectedAccount.accountId}</Header.Subheader>
        </Header.Content>
      </Header>
      <Popup
        trigger={
          <Button className="add-button" size="small" floated="right">
            Start New Reconciliation <Icon name="triangle down" size="large" />
          </Button>
        }
        content={
          <div>
            <Header as="h3">
              <Icon name="time" />
              <Header.Content>
                End time:
                <Header.Subheader>
                  {formatTimeLocalWithDoW(endTime)}
                </Header.Subheader>
              </Header.Content>
            </Header>
            <DateTime
              defaultValue={endingBusinessTime()}
              open
              input={false}
              onChange={dateTimeChanged}
            />
            <Header as="h3">
              <Header.Content>
                Days Before Previous Reconciliation End Date/Time To Look Back
                (Advanced):
                <input
                  type="text"
                  name="daysPrevious"
                  value={daysPrevious}
                  onChange={daysPreviousChanged}
                />
              </Header.Content>
            </Header>
            <Button
              fluid
              onClick={startReconciliation}
              color="green"
              content="Start Reconciliation"
            />
          </div>
        }
        on="click"
        position="bottom right"
      />
      <div className="content">
        <XTable
          title="reconciliationHistory"
          noDataText="No reconciliation history was found for this account."
          showPageSizeOptions={false}
          minRows={5}
          data={reconciliationHistory}
          className="-striped -highlight"
          columns={metadata}
          getTrProps={(state, rowInfo) => ({
            onClick: (e) => {
              viewReconciliationHistory(rowInfo.original.uuid);
            },
            className: 'pointer',
          })}
        />
      </div>
    </div>
  </Fragment>
);

export default ReconciliationHistory;
