import { arrayToObject } from 'erisxkit/client';
import { createRoutine } from 'redux-saga-routines';
import { handleActions, combineActions } from 'redux-actions';
import { get, chain } from 'lodash';
import { createSelector } from 'reselect';
import * as actionTypes from '../constants/actionTypes';

//* Routines  */
export const futuresProducts = createRoutine(actionTypes.FUTURES_PRODUCTS);
export const createFuturesProduct = createRoutine(
  actionTypes.CREATE_FUTURES_PRODUCT,
);
export const updateFuturesProduct = createRoutine(
  actionTypes.UPDATE_FUTURES_PRODUCT,
);

//* Reducer  */
export default handleActions(
  {
    [futuresProducts.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      futuresProducts: arrayToObject(
        get(payload, 'futuresProducts', []),
        'symbol',
      ),
    }),
    [combineActions(
      createFuturesProduct.SUCCESS,
      updateFuturesProduct.SUCCESS,
    )]: (state, { payload }) => ({
      ...state,
      futuresProducts: {
        ...state.futuresProducts,
        [payload.symbol]: payload,
      },
    }),
  },
  {},
);

//* Selectors  */
export const getFuturesProducts = (state) =>
  chain(state).get(['futuresProducts', 'futuresProducts'], {}).values().value();
export const getFuturesProductSymbolsAsOptions = createSelector(
  [getFuturesProducts],
  (futuresProductsList) =>
    futuresProductsList.map((product) => ({
      key: product.symbol,
      text: product.symbol,
      description: product.description,
      value: product.symbol,
    })),
);
