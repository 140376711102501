import React, { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from 'erisxkit/client';
import { TradingParticipant } from '../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import { GENERIC_MODAL } from '../../../constants/modalTypes';
import { GenericModalProps } from '../../../common/GenericModal';
import { Checkbox } from 'semantic-ui-react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  enableBlockTradesPromise,
  disableBlockTradesPromise,
  fetchAccountTradingParticipants,
} from '../../../reducers/participantManagementReducer';
import Account from '../../../ts/models/Account.model';
import { RootState } from '../../../ts/types/store';
import { getCustomerAccountFromMember } from '../../../reducers/accountsReducer';
import usePaginatedFetch from '../../../hooks/usePaginatedFetch';

const BlockTradeCheckbox: React.FC<{
  participant: TradingParticipant;
  selectedMemberFirmCode: string;
  selectedMemberId: string;
}> = ({ participant, selectedMemberFirmCode, selectedMemberId }) => {
  const dispatch = useDispatch();
  const account: Account = useAppSelector((state: RootState) =>
    getCustomerAccountFromMember(state, selectedMemberId),
  );
  const accountLabel = useMemo(() => account?.label, [account]);
  const filteredFetch = usePaginatedFetch(fetchAccountTradingParticipants, {
    urlParams: {
      accountLabel,
    },
  });
  const fetchData = useCallback(() => {
    if (account) {
      filteredFetch();
    }
  }, [filteredFetch, account]);

  const fcmFirmCode = selectedMemberFirmCode;
  const tradingParticipantFirmCode = participant?.participantFirmCode;

  const onSubmit = useCallback(
    async (isAllowing: boolean) => {
      try {
        if (isAllowing) {
          await enableBlockTradesPromise(
            {
              fcmFirmCode,
              tradingParticipantFirmCode,
            },
            dispatch,
          );
        } else {
          await disableBlockTradesPromise(
            {
              fcmFirmCode,
              tradingParticipantFirmCode,
            },
            dispatch,
          );
        }
        dispatch(hideModal());
      } catch (error) {
        dispatch(hideModal());
      }
      fetchData();
    },
    [accountLabel, dispatch, fcmFirmCode, tradingParticipantFirmCode],
  );

  const onClick = useCallback(() => {
    // If it's already true it's disallowing. If it's false it's allowing
    const isAllowing = !participant?.fcms?.[0]?.participantEnabledForBlockTrades;
    const action = isAllowing ? 'Allow' : 'Disallow';
    const header = `${action} Block Trades`;
    const content = (
      <span>
        {`You are about to ${
          isAllowing ? 'allow' : 'disallow'
        } Block Trades for `}
        participant: <b>{participant?.participantName}</b>
      </span>
    );

    dispatch(
      showModal(GENERIC_MODAL, {
        header,
        content,
        cancelProps: {
          text: 'Cancel',
          onClick: () => dispatch(hideModal()),
        },
        confirmProps: {
          text: action,
          onClick: () => onSubmit(isAllowing),
        },
      } as GenericModalProps),
    );
  }, [dispatch, participant, onSubmit]);

  return isEmpty(participant) ? null : (
    <Checkbox checked={participant?.fcms?.[0]?.participantEnabledForBlockTrades} onChange={onClick} />
  );
};

export default BlockTradeCheckbox;
