import React from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';

export const DetailsHeader = ({ headerText, hideEdit, onEdit }) => (
  <div className="flex-row">
    <Header as="h3">{headerText}</Header>
    {!hideEdit && (
      <Button className="link edit" content="Edit" onClick={onEdit} />
    )}
  </div>
);

export const IndexHeader = ({ headerText }) => <div />;

export const WizardHeader = ({
  headerText,
  hideButton,
  buttonText,
  buttonDisabled,
  buttonOnClick,
  tertiaryButton,
  tertiaryButtonText,
  tertiaryButtonOnClick,
}) => (
  <div className="flex-row space-between">
    <h4>{headerText}</h4>
    <section>
      {tertiaryButton && (
        <Button
          className="link"
          content={tertiaryButtonText}
          onClick={tertiaryButtonOnClick}
        />
      )}
      {!hideButton && (
        <Button
          onClick={buttonOnClick}
          className="add-button"
          disabled={buttonDisabled}
          content={buttonText}
        />
      )}
    </section>
  </div>
);
