import _ from 'lodash';
import { fetchAbout } from '../actions/utilActions';

export default function aboutReducer(state = [], action) {
  switch (action.type) {
    case fetchAbout.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getAbout = (state) => _.get(state, ['about'], []);
