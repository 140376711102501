import Big from 'bignumber.js';
import React from 'react';
import { Icon, Message, Popup } from 'semantic-ui-react';
import surveillanceRules from '../utils/surveillanceRules';

export default [
  {
    Header: '',
    id: 'alert',
    accessor: 'alert',
    maxWidth: 35,
    Cell: ({ original }) => {
      const alerts = surveillanceRules(original);
      if (alerts.length) {
        return (
          <Popup
            // onClose={onClose}
            wide="very"
            trigger={
              <Icon
                name="warning sign"
                link
                color="red"
                title="Click to see alerts."
              />
            }
            content={
              <Message info>
                {alerts.map((a) => (
                  <p>{a}</p>
                ))}
              </Message>
            }
            on="click"
          />
        );
      }
      return '';
    },
  },
  {
    Header: 'Trade Date',
    id: 'reportDate',
    accessor: 'reportDate',
  },
  {
    Header: 'Reportable Account Number',
    id: 'accountNumber',
    accessor: 'accountNumber',
  },
  {
    Header: 'Reportable Account Name',
    id: 'accountName',
    accessor: 'accountName',
  },
  {
    Header: 'Clearing Member Firm',
    id: 'reportingFirm',
    accessor: 'reportingFirm',
  },
  {
    Header: 'Product',
    id: 'commodityCode',
    accessor: 'commodityCode',
  },
  {
    Header: 'Expiration',
    id: 'expirationDate',
    accessor: 'expirationDate',
  },
  {
    Header: 'Contract',
    id: 'contract',
    accessor: 'contract',
  },
  {
    Header: 'Clearing Member Type',
    id: 'reportingFirmType',
    accessor: 'reportingFirmType',
  },
  {
    Header: 'Prior Long',
    id: 'priorLong',
    accessor: 'priorLong',
  },
  {
    Header: 'Prior Short',
    id: 'priorShort',
    accessor: 'priorShort',
  },
  {
    Header: 'Long',
    id: 'long',
    accessor: 'long',
  },
  {
    Header: 'Short',
    id: 'short',
    accessor: 'short',
  },
  {
    Header: 'Change Long',
    id: 'changeLong',
    accessor: ({ long = 0, priorLong = 0 }) =>
      Big(long).minus(Big(priorLong)).toFixed(),
  },
  {
    Header: 'Change Short',
    id: 'changeShort',
    accessor: ({ short = 0, priorShort = 0 }) =>
      Big(short).minus(Big(priorShort)).toFixed(),
  },
  {
    Header: 'Net Position',
    id: 'netPosition',
    accessor: 'netPosition',
  },
  {
    Header: '% of Market',
    id: 'percentOfMarket',
    accessor: 'percentOfMarket',
  },
  {
    Header: '% of Market Change',
    id: 'marketChange',
    accessor: ({ percentOfMarket = 0, priorPercentOfMarket = 0 }) =>
      Big(percentOfMarket).minus(Big(priorPercentOfMarket)).toFixed(),
  },
  {
    Header: 'Notional Value Net Position',
    id: 'notionalValueNetPosition',
    accessor: 'notionalValueNetPosition',
  },
  {
    Header: '% Notional Value',
    id: 'percentNotionalValue',
    accessor: 'percentNotionalValue',
  },
  {
    Header: '% Notional Value Change',
    id: 'notionalValueChange',
    accessor: ({ percentNotionalValue = 0, priorPercentNotionalValue = 0 }) =>
      Big(percentNotionalValue).minus(Big(priorPercentNotionalValue)).toFixed(),
    resizable: false,
  },
];
