import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

export const fetchUpcomingDeposits = createRoutine(
  actionTypes.UPCOMING_DEPOSITS,
);

export const deleteFiatDepositSchedule = createRoutine(
  actionTypes.DELETE_FIAT_DEPOSIT_SCHEDULE,
);

export const deleteFiatDepositSchedulePromiseCreator = promisifyRoutine(
  deleteFiatDepositSchedule,
);

export default function upcomingDepositsReducer(state = {}, action) {
  switch (action.type) {
    case fetchUpcomingDeposits.SUCCESS: {
      return {
        ...state,
        upcomingDeposits: action.payload,
        count: action.payload.length,
      };
    }
    default:
      return state;
  }
}

export const getUpcomingDeposits = (state) => {
  return _.get(state, ['upcomingDeposits', 'upcomingDeposits'], []);
};
