import React from 'react';
import { Row, UseExpandedRowProps } from 'react-table';

type Props<T extends object> = {
  row: Row<T> & UseExpandedRowProps<T>;
};

export function ExpandCell<T extends object>({ row }: Props<T>) {
  return row.canExpand ? (
    <span
      {...row.getToggleRowExpandedProps({
        style: {
          paddingLeft: `${row.depth * 2}rem`,
          color: row.depth ? 'SlateGrey' : 'black',
        },
        className: 'expandable',
      })}
    >
      {row.isExpanded ? '▼' : '►'}
    </span>
  ) : null;
}

export default ExpandCell;
