import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { get } from 'lodash';
import { createSelector } from 'reselect';
import {
  AuthorizedBlockTraders,
  FetchAuthorizedBlockTradersResponse,
} from '../ts/models/BlockTrades/PermissionManagement.model';
import { RootState } from '../ts/types/store';

/** Actions */
export const GET_AUTHORIZED_BLOCK_TRADERS = 'GET_AUTHORIZED_BLOCK_TRADERS';
export const ADD_AUTHORIZED_BLOCK_TRADER = 'ADD_AUTHORIZED_BLOCK_TRADER';
export const REMOVE_AUTHORIZED_BLOCK_TRADER = 'REMOVE_AUTHORIZED_BLOCK_TRADER';

/** Routines */
export const fetchAuthorizedBlockTraders = createRoutine(
  GET_AUTHORIZED_BLOCK_TRADERS,
);
export const addAuthorizedBlockTrader = createRoutine(
  ADD_AUTHORIZED_BLOCK_TRADER,
);
export const removeAuthorizedBlockTrader = createRoutine(
  REMOVE_AUTHORIZED_BLOCK_TRADER,
);

/** Promisified Routines */
export const removeAuthorizedBlockTraderPromise = promisifyRoutine(
  removeAuthorizedBlockTrader,
);
export const addAuthorizedBlockTraderPromise = promisifyRoutine(
  addAuthorizedBlockTrader,
);

/** Initial State */
type BlockTradesState = {
  authorizedBlockTraders?: AuthorizedBlockTraders;
};

const initialState: BlockTradesState = {
  authorizedBlockTraders: [],
};

type ActionPayload = FetchAuthorizedBlockTradersResponse;

/** Reducer */
export default handleActions<BlockTradesState, ActionPayload>(
  {
    [fetchAuthorizedBlockTraders.SUCCESS]: (state, { payload }) => ({
      ...state,
      authorizedBlockTraders: payload.authorizedBlockTraders,
    }),
  },
  initialState,
);

/** Selectors */
export const getBlockTradePermissionsState = (
  state: RootState,
): BlockTradesState => get(state, 'blockTradePermissions', {});

export const getAuthorizedBlockTraders = createSelector(
  [getBlockTradePermissionsState],
  (state) => get(state, 'authorizedBlockTraders', []),
);

export const getAuthorizedBlockTradersForGrid = createSelector(
  [getAuthorizedBlockTraders],
  (authorizedUsers) =>
    authorizedUsers.map((au) => ({ authorizedTraderEmail: au })),
);
