import _ from 'lodash';
import * as actionTypes from '../constants/actionTypes';
import { createRoutine } from 'redux-saga-routines';
import { combineReducers } from 'redux';

export const fetchIraCustodians = createRoutine(actionTypes.IRA_CUSTODIANS);
export const fetchIraAccountTypesAndCodes = createRoutine(
  actionTypes.IRA_ACCOUNT_TYPES_AND_CODES,
);

function accountTypesReducer(state = {}, action) {
  switch (action.type) {
    case fetchIraAccountTypesAndCodes.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function providerReducer(state = [], action) {
  switch (action.type) {
    case fetchIraCustodians.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  providers: providerReducer,
  accountTypes: accountTypesReducer,
});

/* Selectors */
export const getProviders = (state) => _.get(state, ['ira', 'providers'], []);
export const getIRAAccountTypes = (state) =>
  _.get(state, ['ira', 'accountTypes'], {});
