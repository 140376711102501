import { createRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';
import promisifyRoutine from 'redux-saga-routines/dist/promisifyRoutine';

/**
 * @param {*} args: Arguments to send to create a new fee rebate request.
 */
export const createFeeRebate = (args) => ({
  type: actionTypes.CREATE_FEE_REBATE_REQUEST,
  args,
});

export const createGeneralJournal = createRoutine(
  actionTypes.CREATE_GENERAL_JOURNAL,
);
export const createGeneralJournalPromiseCreator =
  promisifyRoutine(createGeneralJournal);

export const generalJournalCategories = createRoutine(
  actionTypes.GENERAL_JOURNAL_CATEGORIES,
);

export const createTrade = createRoutine(actionTypes.CREATE_TRADE);

export const closingPrices = createRoutine(actionTypes.CLOSING_PRICES);
export const updateClosingPrice = createRoutine(
  actionTypes.UPDATE_CLOSING_PRICE,
);
export const createDesignationTransferJournal = createRoutine(
  actionTypes.CREATE_DESIGNATION_TRANSFER_JOURNAL,
);
export const createCryptoWithdrawalRequest = createRoutine(
  actionTypes.CREATE_CRYPTO_WITHDRAWAL_REQUEST,
);
export const createFiatWithdrawalRequest = createRoutine(
  actionTypes.CREATE_FIAT_WITHDRAWAL_REQUEST,
);
