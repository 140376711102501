export default {
  read_clearing_api: {
    header: 'General',
    text: 'Clearing (Read Only)',
  },
  view_market_data: {
    header: 'General',
    text: 'Market Data',
  },
  write_clearing_api: {
    header: 'General',
    text: 'Funding',
  },
  submit_order: {
    header: 'General',
    text: 'Trading',
  },
  submit_block_trade: {
    header: 'General',
    text: 'Submit Block Trade',
  },
  settlement_service: {
    header: 'General',
    text: 'Settlement Service',
  },
  write_pre_trade_risk: {
    header: 'Futures Risk Management',
    text: 'Write Pre-Trade Risk',
  },
  read_pre_trade_risk: {
    header: 'Futures Risk Management',
    text: 'Read Pre-Trade Risk',
  },
  update_trade: {
    header: 'Futures Adjustments',
    text: 'Update Trade',
  },
  update_position: {
    header: 'Futures Adjustments',
    text: 'Update Positions',
  },
  request_fcm_clearing: {
    header: 'Futures Exchange Member',
    text: 'Request FCM Clearing',
  },
  write_participant_account: {
    header: 'Futures Exchange Member',
    text: 'Write Participant Account',
  },
  read_participant_account: {
    header: 'Futures Exchange Member',
    text: 'Read Participant Account',
  },
  read_tax_documents: {
    header: 'Tax',
    text: 'Read Tax Documents',
  },
  write_tax_documents: {
    header: 'Tax',
    text: 'Write Tax Documents',
  },
  onboard_member_user: {
    header: 'Intermediaries',
    text: 'Onboarding',
  },
  manage_block_trade_permissions: {
    header: 'General',
    text: 'Manage Block Trade Permissions',
  },
};
