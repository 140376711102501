import { useRef, useEffect } from 'react';

/**
 * @param value - state value that we need to keep track of its previous value
 * @returns previous value
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
export default usePrevious;
