import React from 'react';
import get from 'lodash/get';
import { CellProps, Row } from 'react-table';
import styled from 'styled-components';

import BlockTradeRequest, {
  BlockTradeRequestAction,
  BlockTradeState,
} from '../ts/models/BlockTrades/BlockTradeRequest.model';
import { TradingParticipant } from '../ts/models/TradingParticipant/ApprovedParticipant.model';
import colors from '../constants/colors';
import TextButtons from '../common/TextButtons';
import { showApproveButton } from '../containers/Members/BlockTradeRequests/utils';

const StyledStatus = styled.p<{ status: BlockTradeState }>`
  color: ${({ status }) => {
    if (status === BlockTradeState.APPROVED) return colors.accent4;
    if (status === BlockTradeState.REJECTED) return colors.error2;
  }};
  font-weight: ${({ status }) =>
    status === BlockTradeState.PENDING ? 400 : 'bold'};
  text-transform: capitalize;
  text-align: center;
`;

const Status: React.FC<{ status: BlockTradeState }> = ({ status }) => {
  return <StyledStatus status={status}>{status}</StyledStatus>;
};
const blockTradeRequestsMetadata = (
  participantList: TradingParticipant[],
  onActionClick: (row: Row<BlockTradeRequest>, action: string) => void,
) => [
  {
    id: 'action',
    Header: 'Action',
    minSize: 130,
    hideFilter: true,
    Cell: ({ row }: CellProps<BlockTradeRequest>) => {
      const isPending =
        get(row?.original, ['state']) === BlockTradeState.PENDING;
      const showApprove = showApproveButton(row?.original, participantList);
      const buttons = [];

      if (isPending) {
        // Only add approve buttons if either buy or sell enabled
        if (showApprove) {
          buttons.push({
            text: 'Approve',
            onClick: () => onActionClick(row, BlockTradeRequestAction.APPROVE),
            fontSize: '14',
          });
        }
        // always add reject button if pending
        buttons.push({
          text: 'Reject',
          onClick: () => onActionClick(row, BlockTradeRequestAction.REJECT),
          fontSize: '14',
          color: colors.RED_ERROR,
        });
      }

      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {isPending ? <TextButtons buttons={buttons} /> : '-'}
        </div>
      );
    },
  },
  {
    accessor: 'state',
    id: 'state',
    Header: 'Status',
    minSize: 80,
    hideFilter: true,
    Cell: ({ row }: CellProps<BlockTradeRequest>) => {
      const status = row?.original.state;
      return <Status status={status} />;
    },
  },
  {
    accessor: 'product',
    id: 'product',
    Header: 'Product',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'contractSymbol',
    id: 'contractSymbol',
    Header: 'Contract Symbol',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'quantity',
    id: 'quantity',
    Header: 'Quantity',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'price',
    id: 'price',
    Header: 'Price',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'tradeDate',
    id: 'tradeDate',
    Header: 'Trade Date',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'validator',
    id: 'validator',
    Header: 'Validator',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.clearingFirm',
    id: 'buySide.clearingFirm',
    Header: 'Buy Clearing Firm',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.exchangeMember',
    id: 'buySide.exchangeMember',
    Header: 'Buy Exchange Member',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.tradingParticipant',
    id: 'buySide.tradingParticipant',
    Header: 'Buy Trading Pariticipant',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.customerAccountRef',
    id: 'buySide.customerAccountRef',
    Header: 'Buy Customer Account Ref',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.cgm',
    id: 'buySide.cgm',
    Header: 'Buy CGM',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.clOrdId',
    id: 'buySide.clOrdId',
    Header: 'Buy ClOrdId',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.senderSubId',
    id: 'buySide.senderSubId',
    Header: 'Buy Sender SubId',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.customerOrderCapacity',
    id: 'buySide.customerOrderCapacity',
    Header: 'Buy Customer Order Capacity',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'buySide.accountType',
    id: 'buySide.accountType',
    Header: 'Buy Account Type',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.clearingFirm',
    id: 'sellSide.clearingFirm',
    Header: 'Sell Clearing Firm',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.exchangeMember',
    id: 'sellSide.exchangeMember',
    Header: 'Sell Exchange Member',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.tradingParticipant',
    id: 'sellSide.tradingParticipant',
    Header: 'Sell Trading Participant',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.customerAccountRef',
    id: 'sellSide.customerAccountRef',
    Header: 'Sell Customer Account Ref',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.cgm',
    id: 'sellSide.cgm',
    Header: 'Sell CGM',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.clOrdId',
    id: 'sellSide.clOrdId',
    Header: 'Sell ClOrdId',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.senderSubId',
    id: 'sellSide.senderSubId',
    Header: 'Sell Sender SubId',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.customerOrderCapacity',
    id: 'sellSide.customerOrderCapacity',
    Header: 'Sell Customer Order Capacity',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'sellSide.accountType',
    id: 'sellSide.accountType',
    Header: 'Sell Account Type',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'reportingSide',
    id: 'reportingSide',
    Header: 'Reporting Side',
    minSize: 80,
    hideFilter: true,
  },
  {
    accessor: 'blockTradeId',
    id: 'blockTradeId',
    Header: 'Block Trade ID',
    minSize: 80,
    hideFilter: true,
  },
];

export default blockTradeRequestsMetadata;
