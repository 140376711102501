import { fork } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import reconciliationSaga from './reconciliationSaga';
import accountsSaga from './accountsSaga';
import usersSaga from './usersSaga';
import approvalReqsSaga from './approvalReqsSaga';
import systemAlertsSaga from './systemAlertsSaga';
import manualEntrySaga from './manualEntrySaga';
import tradesSaga from './tradesSaga';
import manualOnboardingSaga from './manualOnboardingSaga';
import coreFilesSaga from './coreFilesSaga';
import authSaga from './authSaga';
import linkedAccountsSaga from './linkedAccountsSaga';
import surveillanceSaga from './surveillanceSaga';
import membersSaga from './membersSaga';
import productsSaga from './productsSaga';
import collateralSaga from './collateralSaga';
import contractsSaga from './contractsSaga';
import linkedMemberAssetAccountsSaga from './linkedMemberAssetAccountsSaga';
import journalsSaga from './journalsSaga';
import balancesSaga from './balancesSaga';
import ledgersSaga from './ledgersSaga';
import subExchangeSaga from './subExchangesSaga';
import apiCredentialsSaga from './apiCredentialsSaga';
import utilSaga from './utilSaga';
import configurationSaga from './configurationSaga';
import achSaga from './achSaga';
import riskSaga from './riskSaga';
import feeProfileSaga from './feeProfileSaga';
import referralCodeSaga from './referralCodeSaga';
import iraSaga from './iraSaga';
import clientTransfer from './clientTransferSaga';
import settlementSaga from './settlementSaga';
import marginRatesSaga from './marginRatesSaga';
import authorizationIpSaga from './authorizationIpSaga';
import fcmReportsSaga from './fcmReportsSaga';
import initialMarginSaga from './initialMarginSaga';
import riskConfigurationsSaga from './riskConfigurationsSaga';
import travelRuleSaga from './travelRuleSaga';
import participantManagementSaga from './participantManagementSaga';
import cgmManagementSaga from './cgmManagementSaga';
import carManagementSaga from './carManagementSaga';
import fcmClearingSaga from './fcmClearingSaga';
import blockTradePermissionsSaga from './blockTradePermissionsSaga';
import blockTradeRequestsSaga from './blockTradeRequestsSaga';

// Here, we register our watcher saga(s) and export as a single generator
// function (startForeman) as our root Saga.
export default function* startForeman() {
  yield fork(routinePromiseWatcherSaga);
  yield fork(reconciliationSaga);
  yield fork(accountsSaga);
  yield fork(usersSaga);
  yield fork(approvalReqsSaga);
  yield fork(systemAlertsSaga);
  yield fork(manualEntrySaga);
  yield fork(tradesSaga);
  yield fork(manualOnboardingSaga);
  yield fork(coreFilesSaga);
  yield fork(authSaga);
  yield fork(linkedAccountsSaga);
  yield fork(surveillanceSaga);
  yield fork(membersSaga);
  yield fork(collateralSaga);
  yield fork(contractsSaga);
  yield fork(linkedMemberAssetAccountsSaga);
  yield fork(journalsSaga);
  yield fork(balancesSaga);
  yield fork(productsSaga);
  yield fork(ledgersSaga);
  yield fork(subExchangeSaga);
  yield fork(apiCredentialsSaga);
  yield fork(utilSaga);
  yield fork(configurationSaga);
  yield fork(achSaga);
  yield fork(riskSaga);
  yield fork(feeProfileSaga);
  yield fork(referralCodeSaga);
  yield fork(iraSaga);
  yield fork(clientTransfer);
  yield fork(settlementSaga);
  yield fork(marginRatesSaga);
  yield fork(authorizationIpSaga);
  yield fork(fcmReportsSaga);
  yield fork(initialMarginSaga);
  yield fork(riskConfigurationsSaga);
  yield fork(travelRuleSaga);
  yield fork(participantManagementSaga);
  yield fork(cgmManagementSaga);
  yield fork(carManagementSaga);
  yield fork(fcmClearingSaga);
  yield fork(blockTradePermissionsSaga);
  yield fork(blockTradeRequestsSaga);
}
