import React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import { overflowMenu } from '../common/table/commonMetadata';

export default ({ showAddContractModal, showProductModal }) => [
  {
    Header: 'Product Code',
    accessor: 'symbol',
    id: 'symbol',
  },
  {
    Header: 'Description',
    accessor: 'description',
    id: 'description',
  },
  {
    Header: 'Base asset type',
    accessor: 'baseAssetType',
    id: 'baseAssetType',
  },
  {
    Header: 'Contract size',
    accessor: 'contractSize',
    id: 'contractSize',
  },
  {
    Header: 'Haircut',
    accessor: 'haircut',
    id: 'haircut',
  },
  overflowMenu({
    items: ({ original }) => [
      <Dropdown.Item
        id="add-collateral-contract"
        icon="add"
        onClick={() => showAddContractModal(original.symbol)}
        text="Add Contract"
      />,
      <Dropdown.Item
        id="edit-product"
        icon="pencil"
        onClick={() => showProductModal({ type: 'update', ...original })}
        text="Edit Product"
      />,
    ],
  }),
];
