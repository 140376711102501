import _ from 'lodash';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import { getLatestError, clearLatestError } from 'erisxkit/client';
import PersistentCogoToast from './components/PersistentCogoToast';

const ErrorMessage = ({ error, clearLatestError }) => {
  const options = {
    heading: error.statusText,
    hideAfter: 5,
  };
  if (error) {
    const status = _.get(error, ['data', 'status'], 500);

    switch (status) {
      case 401:
        cogoToast.error(
          'Incorrect Password. Please check your password and try again.',
          options,
        );
        break;
      case 403:
        cogoToast.error(
          'You are not authorized to perform this action.',
          options,
        );
        break;
      case 500:
      default:
        PersistentCogoToast.error(error.data.error, { ...options });
        clearLatestError();
    }
  }
  return null;
};

const mapStateToProps = (state) => ({
  error: getLatestError(state),
});

const mapDispatchToProps = {
  clearLatestError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
