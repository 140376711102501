import { call, put, takeLatest, select } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import api, * as urls from '../api';
import { createSaga } from '../utils/createSaga';
import {
  TRADES_REQUEST,
  TRADES_FAILED,
  TRADES_SUCCESS,
  TRADE_JOURNALS_REQUEST,
  TRADE_JOURNALS_FAILED,
  TRADE_JOURNALS_SUCCESS,
} from '../reducers/tradesReducer';
import {
  fetchBlockTradeRequests,
  cancelBlockTradeRequest,
  commitBlockTradeRequest,
  BLOCK_TRADE_REQUESTS,
  CANCEL_BLOCK_TRADE,
  COMMIT_BLOCK_TRADE,
} from '../reducers/blockTradesReducer';
import {
  fetchTradeCaptureReports,
  reprocessTradeCaptureReport,
  requestReprocessTradeCaptureReport,
  clearTradeCaptureReport,
  updateTradeCaptureReport,
} from '../reducers/tradeCaptureReportsReducer';
import {
  UPDATE_TRADE_CAPTURE_REPORT,
  TRADE_CAPTURE_REPORTS,
} from '../constants/actionTypes';
import { getActiveLedgerId } from '../reducers/ledgersReducer';

function* trades({ payload }) {
  try {
    const ledgerId = yield select(getActiveLedgerId);
    const tradesList = yield call(api.post, urls.TRADES_API_ENDPOINT, {
      ...payload,
      ...(ledgerId && { ledgerId }),
    });
    yield put({ type: TRADES_SUCCESS, payload: tradesList });
  } catch (e) {
    yield put({ type: TRADES_FAILED, payload: e.response });
  }
}

function* trade_journals({ payload }) {
  try {
    const ledgerId = yield select(getActiveLedgerId);
    const tradesList = yield call(api.post, urls.TRADE_JOURNALS_API_ENDPOINT, {
      ...payload,
      ...(ledgerId && { ledgerId }),
    });
    yield put({ type: TRADE_JOURNALS_SUCCESS, payload: tradesList });
  } catch (e) {
    yield put({ type: TRADE_JOURNALS_FAILED, payload: e.response });
  }
}

function* reprocessTradeCaptureReportRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Reprocessing trade capture report...',
    { hideAfter: 0 },
  );
  try {
    const result = yield call(
      api.post,
      urls.REPROCESS_TRADE_CAPTURE_REPORT_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(reprocessTradeCaptureReport.success(result));
    cogoToast.success(
      `Trade capture report with link ID ${payload.tradeLinkId} reprocessed.`,
    );
  } catch (e) {
    loadingCallback();
    yield put(reprocessTradeCaptureReport.failure(e.response));
  }
}

function* requestReprocessTradeCaptureReportRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Creating approval to reprocess trade capture report...',
    { hideAfter: 0 },
  );
  try {
    const result = yield call(
      api.post,
      urls.REQUEST_REPROCESS_TRADE_CAPTURE_REPORT_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(requestReprocessTradeCaptureReport.success(result));
    cogoToast.success(
      `Approval to reprocess trade capture report with link ID ${payload.tradeLinkId} created.`,
    );
  } catch (e) {
    loadingCallback();
    yield put(requestReprocessTradeCaptureReport.failure(e.response));
  }
}

function* checkTradeCaptureReportCleared({ payload }) {
  const loadingCallback = cogoToast.loading(
    `Checking status of TCR ${payload.tradeLinkId}...`,
    { hideAfter: 0 },
  );
  try {
    yield put(clearTradeCaptureReport.request());
    const result = yield call(
      api.post,
      urls.CLEAR_TRADE_CAPTURE_REPORT_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(clearTradeCaptureReport.success(result));
    cogoToast.success(
      `TCR with link ID ${payload.tradeLinkId} state: ${result.state}`,
    );
  } catch (e) {
    loadingCallback();
    yield put(clearTradeCaptureReport.failure(e.response));
  }
}

export default function* watchFeeRebates() {
  yield takeLatest(TRADES_REQUEST, trades);
  yield takeLatest(TRADE_JOURNALS_REQUEST, trade_journals);
  yield takeLatest(
    fetchTradeCaptureReports.TRIGGER,
    createSaga(
      fetchTradeCaptureReports,
      TRADE_CAPTURE_REPORTS,
      '',
      '',
      '',
      '',
      { withPage: true },
    ),
  );
  yield takeLatest(
    reprocessTradeCaptureReport.trigger,
    reprocessTradeCaptureReportRequest,
  );
  yield takeLatest(
    requestReprocessTradeCaptureReport.trigger,
    requestReprocessTradeCaptureReportRequest,
  );
  yield takeLatest(
    clearTradeCaptureReport.trigger,
    checkTradeCaptureReportCleared,
  );
  yield takeLatest(
    updateTradeCaptureReport.TRIGGER,
    createSaga(updateTradeCaptureReport, UPDATE_TRADE_CAPTURE_REPORT),
  );
  yield takeLatest(
    fetchBlockTradeRequests.TRIGGER,
    createSaga(fetchBlockTradeRequests, BLOCK_TRADE_REQUESTS, '', '', '', '', {
      withPage: true,
    }),
  );
  yield takeLatest(
    cancelBlockTradeRequest.TRIGGER,
    createSaga(cancelBlockTradeRequest, CANCEL_BLOCK_TRADE),
  );
  yield takeLatest(
    commitBlockTradeRequest.TRIGGER,
    createSaga(commitBlockTradeRequest, COMMIT_BLOCK_TRADE),
  );
}
