import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, Icon, Label } from 'semantic-ui-react';

const EditableField = ({
  type,
  value,
  name,
  editing,
  onEdit,
  onChange,
  onBlur,
}) => {
  const [isEditing, setIsEditing] = useState(editing);

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);
  return isEditing ? (
    <input
      name={name}
      type={type}
      style={{ width: '72px' }}
      value={value}
      onChange={(e) => {
        const field = get(e, 'target.name', '');
        const newValue = get(e, 'target.value', '');
        onChange(field, newValue);
      }}
      onBlur={() => {
        if (onBlur) onBlur();
      }}
    />
  ) : (
    <div>
      <span className="margin-right-10">{value}</span>

      <Icon
        name="pencil"
        color="blue"
        onClick={() => {
          setIsEditing(true);
          if (onEdit) onEdit();
        }}
        className="margin-left-10"
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

EditableField.defaultProps = {
  type: 'text',
  value: '',
  editing: false,
  onEdit: null,
  onBlur: null,
};
EditableField.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  onEdit: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};
export default EditableField;
