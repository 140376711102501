import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import * as actionTypes from '../constants/actionTypes';
import {
  fetchIraCustodians,
  fetchIraAccountTypesAndCodes,
} from '../reducers/iraReducer';

export default function* watch() {
  yield takeLatest(
    fetchIraCustodians.TRIGGER,
    createSaga(fetchIraCustodians, actionTypes.IRA_CUSTODIANS),
  );
  yield takeLatest(
    fetchIraAccountTypesAndCodes.TRIGGER,
    createSaga(
      fetchIraAccountTypesAndCodes,
      actionTypes.IRA_ACCOUNT_TYPES_AND_CODES,
    ),
  );
}
