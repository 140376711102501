import React from 'react';
import { Button } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';

const PENDING_REMOVAL = 'pending_admin_approval';

const authorizedIPsMetadata = (onRemoveAuthorizedIp, readOnly) => [
  {
    Header: 'Date Added',
    id: 'dateAdded',
    accessor: (row) => formatTimeLocal(row.dateAdded),
    width: 200,
  },
  {
    id: 'updatedBy',
    Header: 'Updated By',
    accessor: 'updatedBy',
    width: 300,
  },
  {
    Header: 'IP Address',
    id: 'ipAddress',
    accessor: (row) => row.ipAddress,
    width: 200,
  },
  {
    Header: 'Comment',
    id: 'comment',
    accessor: (row) => row.comment,
    width: 200,
  },
  {
    Header: 'Action',
    id: 'action',
    accessor: (row) =>
      row?.state === PENDING_REMOVAL ? (
        <div>Pending Removal</div>
      ) : (
        !readOnly && (
          <Button
            className="add-button"
            onClick={() => {
              onRemoveAuthorizedIp(row.ipAddress);
            }}
          >
            Remove
          </Button>
        )
      ),
  },
];

export default authorizedIPsMetadata;
