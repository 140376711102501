import { createLoadingSelector } from 'erisxkit/client';
import {
  isEqual,
  difference,
  get,
  camelCase,
  upperFirst,
  find,
  isEmpty,
} from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { Icon, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import {
  getFormValues,
  isInvalid,
  isPristine,
  getFormInitialValues,
  getFormSyncWarnings,
} from 'redux-form';
import history from '../../../constants/history';
import XWizard from '../../../common/components/XWizard';
import PersonalInformation from '../CreateMember/PersonalInformation';
import {
  selectMember,
  createFirmMemberPromiseCreator,
  createVendorMemberPromiseCreator,
  createDirectMemberPromiseCreator,
  createExecutionBrokerMemberPromiseCreator,
  createInvestmentManagerMemberPromiseCreator,
  createIntermediaryMemberPromiseCreator,
  createFcmMemberPromiseCreator,
  fetchMembers,
  getSelectedMemberId,
  getSelectedMember,
  updateMember,
  addMemberToLedgerAccounts,
  removeMemberFromLedgerAccounts,
  addMemberToUsers,
  removeMemberFromUsers,
  addMemberToUsersPromiseCreator,
  removeMemberPromiseCreator,
} from '../../../reducers/membersReducer';
import AddLedgerAccountContainer from './AddLedgerAccountContainer';
import AddMemberUserContainer from './AddMemberUserContainer';
import { fetchAccountsV2 } from '../../../reducers/accountsReducer';
import {
  FIRM_MEMBER,
  VENDOR_MEMBER,
  FCM,
} from '../../../constants/memberTypes';
import { fetchUsers } from '../../../reducers/usersReducer';
import { createEmarketOrganization } from '../../../reducers/manualOnboardingReducer';
import LinkedMemberAssetAccountsContainer from '../../../containers/Members/LinkedMemberAssetAccountsContainer';
import UBOContainer from './UBOContainer';
import {
  getSubExchanges,
  fetchSubExchanges,
} from '../../../reducers/subExchangesReducer';

const mapStateToProps = (state = {}) => ({
  personalValues: getFormValues('member_personal')(state),
  ledgerAccounts: getFormValues('manage_ledger_account_for_member')(state),
  memberUsers: getFormValues('manage_member_user_for_member')(state),
  initialLedgerAccounts: getFormInitialValues(
    'manage_ledger_account_for_member',
  )(state),
  initialUserIds: getFormInitialValues('manage_member_user_for_member')(state),
  invalid: isInvalid('member_personal')(state),
  selectedMemberId: getSelectedMemberId(state),
  selectedMember: getSelectedMember(state),
  pristine: isPristine('member_personal')(state),
  memberWarnings: getFormSyncWarnings('member_personal')(state),
  memberLoading: createLoadingSelector([fetchMembers._PREFIX])(state),
  subExchanges: getSubExchanges(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createFirmMemberPromiseCreator,
      createVendorMemberPromiseCreator,
      createDirectMemberPromiseCreator,
      createExecutionBrokerMemberPromiseCreator,
      createInvestmentManagerMemberPromiseCreator,
      createIntermediaryMemberPromiseCreator,
      createFcmMemberPromiseCreator,
      addMemberToUsersPromiseCreator,
      removeMemberPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      selectMember,
      fetchMembers,
      updateMember,
      addMemberToLedgerAccounts,
      removeMemberFromLedgerAccounts,
      addMemberToUsers,
      removeMemberFromUsers,
      fetchAccountsV2,
      fetchUsers,
      createEmarketOrganization,
      fetchSubExchanges,
    },
    dispatch,
  ),
});

class CreateMemberContainer extends PureComponent {
  state = {
    update: false,
  };
  componentDidMount = () => {
    const parsedUrl = new URL(window.location.href);
    if (parsedUrl.pathname.includes('update_member')) {
      this.setState({
        update: true,
      });

      this.props.fetchMembers({
        filter: [{ attr: 'id', value: this.props.selectedMemberId, op: 'eq' }],
      });

      this.props.fetchAccountsV2({
        filter: [
          {
            attr: 'account_id',
            value: this.props.selectedMember.ledgerAccounts || [],
            op: 'eq',
          },
        ],
        page: 0,
      });

      this.props.fetchUsers({
        filter: [
          {
            attr: 'user_id',
            value: this.props.selectedMember.memberUsers || [],
            op: 'eq',
          },
        ],
      });
    }
    this.props.fetchSubExchanges();
  };
  componentWillReceiveProps = (nextProps) => {
    // if members call is finishes and member changes, call accounts again
    if (!isEqual(nextProps.selectedMember, this.props.selectedMember)) {
      this.props.fetchAccountsV2({
        filter: [
          {
            attr: 'account_id',
            value: nextProps.selectedMember.ledgerAccounts || [],
            op: 'eq',
          },
        ],
        page: 0,
      });

      this.props.fetchUsers({
        filter: [
          {
            attr: 'user_id',
            value: nextProps.selectedMember.memberUsers || [],
            op: 'eq',
          },
        ],
      });
    }

    // Set initial state with already assigned member users ids
    if (!isEqual(nextProps.initialUserIds, this.props.initialUserIds)) {
      this.setState({
        initialMemberUserIds: nextProps.initialUserIds?.userIds,
      });
    }
  };
  goBack = () => history.goBack();

  addLedgerAccounts = (account) => {
    const { selectedMemberId } = this.props;
    this.props.addMemberToLedgerAccounts({
      memberId: selectedMemberId,
      accountIds: [account],
    });
  };

  removeLedgerAccounts = (account) => {
    const { selectedMemberId } = this.props;
    this.props.removeMemberFromLedgerAccounts({
      memberId: selectedMemberId,
      accountIds: [account],
    });
  };

  // adding/removing member users is similar to adding/removing ledger accounts above
  addRemoveMemberUsers = () => {
    const {
      memberUsers,
      selectedMemberId,
      addMemberToUsersPromiseCreator,
      removeMemberPromiseCreator,
    } = this.props;
    const { userIds } = memberUsers;
    // The initialUserIds prop which takes its value from getFormInitialValues
    // can not be used to call 'difference' since it's not updated upon adding/removing
    // so we manually keep track of the new 'initial' states  after successful remove/add operations
    // and we use that state to call 'difference' instead of the form's initial values
    const { initialMemberUserIds } = this.state;
    const removedUsers = difference(initialMemberUserIds, userIds);
    const addedUsers = difference(userIds, initialMemberUserIds);

    // Only call the add/remove endpoints if necessary
    if (!isEmpty(addedUsers)) {
      addMemberToUsersPromiseCreator({
        memberId: selectedMemberId,
        userIds: addedUsers,
      }).then((resp) =>
        this.setState({
          initialMemberUserIds: [
            ...initialMemberUserIds,
            ...get(resp, 'users', []).map((user) => user.userId),
          ],
        }),
      );
    }

    if (!isEmpty(removedUsers)) {
      removeMemberPromiseCreator({
        memberId: selectedMemberId,
        userIds: removedUsers,
      }).then((resp) => {
        const removed = get(resp, 'users', []).map((user) => user.userId);
        this.setState({
          initialMemberUserIds: initialMemberUserIds.filter(
            (id) => !removed.includes(id),
          ),
        });
      });
    }
  };

  createMemberSuccess = ({ args, result }) => {
    const { createEmarketOrganization, selectedMember } = this.props;

    this.props.selectMember(result.memberId);
    if (args.createEmarketOrganization) {
      createEmarketOrganization({ memberId: result.memberId });
    }
    history.push(`${result.memberId}/update_member`);
  };

  updateMember = () => {
    const { updateMember, selectedMemberId, personalValues } = this.props;
    const update = {
      ...personalValues,
      subExchangeEntitlements: personalValues.subExchangeEntitlements.filter(
        (ent) => ent.allowSubExchange,
      ),
    };
    updateMember({ memberId: selectedMemberId, update });
  };

  createMember = () => {
    const { personalValues } = this.props;
    const functionName = upperFirst(camelCase(personalValues.type));

    const args = {
      ...personalValues,
      subExchangeEntitlements: personalValues.subExchangeEntitlements.filter(
        (ent) => ent.allowSubExchange,
      ),
    };

    if (functionName) {
      this.props[`create${functionName}MemberPromiseCreator`](args).then(
        (result) => {
          this.createMemberSuccess({
            args,
            result,
          });
        },
        (failurePayload) => {
          console.log('Failed to create member');
        },
      );
    } else {
      console.log(`Clearing member type of ${personalValues.type} not found.`);
    }
  };

  render = () => {
    const {
      selectedMember,
      selectedMemberId,
      createEmarketOrganization,
      personalValues,
      updateMember,
      invalid,
      pristine,
      subExchanges,
    } = this.props;

    let personalSaveProps = {
      onClick: this.createMember,
      content: 'Create Member',
      disabled: invalid,
      id: 'create_member',
    };

    let personalInitialProps = {
      subExchangeEntitlements: subExchanges,
    };

    let personalNextProps = {
      // it's always disabled when creating a member.
      disabled: true,
    };

    if (this.state.update) {
      personalSaveProps = {
        ...personalSaveProps,
        onClick: this.updateMember,
        content: 'Update Member',
        disabled: pristine || invalid,
      };

      personalInitialProps = {
        ...selectedMember,
        subExchangeEntitlements: subExchanges.map((ex) => {
          // if the subExchange entitlement is already 'allowed' on the member
          const ent = find(selectedMember.subExchangeEntitlements, {
            subExchangeId: ex.subExchangeId,
          });
          if (ent) {
            return {
              ...ent,
              allowSubExchange: true,
              name: ex.name,
            };
          }
          return ex;
        }),
      };

      personalNextProps = {
        // enabled when updating a member.
        disabled: false,
      };
    }

    return (
      <Fragment>
        <Header>
          <Header.Content className="back-link" onClick={this.goBack}>
            <Icon name="left arrow" />
            Back
          </Header.Content>
        </Header>
        <XWizard
          onCancel={this.goBack}
          horizontal
          step={get(this.props, ['location', 'state', 'step'])}
        >
          <PersonalInformation
            allowFutures={get(personalValues, 'allowFutures', false)}
            allowSpot={get(personalValues, 'allowSpot', false)}
            formValues={personalValues}
            saveProps={personalSaveProps}
            title="Details"
            initialValues={personalInitialProps}
            nextProps={personalNextProps}
            update={this.state.update}
            createEmarketOrganization={createEmarketOrganization}
            loading={this.props.memberLoading}
            warnings={this.props.memberWarnings}
          />
          <section
            className="xwizard-form-group"
            // if it's an update, show if selectedMember is a Firm otherwise show if the value is firm member
            hide={
              this.state.update
                ? ![FIRM_MEMBER.type, FCM.type].includes(selectedMember.type)
                : ![FIRM_MEMBER.type, FCM.type].includes(
                    get(personalValues, 'type'),
                  )
            }
            title="UBO"
            description="(Optional)"
            disabled={!this.state.update}
          >
            <UBOContainer
              memberId={selectedMember.memberId}
              ubos={selectedMember.ultimateBeneficialOwners}
            />
          </section>
          <section
            className="xwizard-form-group"
            hide={
              (get(personalValues, 'type') || get(personalValues, 'type')) ===
              VENDOR_MEMBER.type
            }
            saveProps={{
              hide: true,
            }}
            title="Linked Asset Accounts (SSI)"
            description="(Optional)"
            disabled={!this.state.update}
          >
            <LinkedMemberAssetAccountsContainer
              memberId={selectedMember.memberId}
            />
          </section>
          <AddLedgerAccountContainer
            hide={
              (get(personalValues, 'type') || get(personalValues, 'type')) ===
              VENDOR_MEMBER.type
            }
            saveProps={{
              hide: true,
            }}
            title="Ledger Accounts"
            description="(Optional)"
            accounts={this.state.update && selectedMember.ledgerAccounts}
            disabled={!this.state.update}
            personalValues={personalInitialProps}
            addLedgerAccounts={this.addLedgerAccounts}
            removeLedgerAccounts={this.removeLedgerAccounts}
          />
          <AddMemberUserContainer
            saveProps={{
              onClick: this.addRemoveMemberUsers,
              content: 'Save',
              id: 'save',
            }}
            title="Member Users"
            description="(Optional)"
            disabled={!this.state.update}
            initialValues={
              this.state.update && { userIds: selectedMember.memberUsers }
            }
          />
        </XWizard>
      </Fragment>
    );
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateMemberContainer);
