const colors = {
  primary1: '#f2f7fa',
  primary2: '#f5f5f5',
  primary3: '#aea6a6',
  primary4: '#FAFAFA',
  secondary2: '#333333',
  secondary3: '#4F4F4F',
  accent: '#00ebb9',
  accent2: '#00b891',
  accent3: '#47B0D1',
  accent4: '#1282B8',
  error: '#f66c6b',
  error2: '#e64e4d',
  GRAY_1: '#9696a0',
  GRAY_2: '#dcdce1',
  GRAY_3: '#668090',
  GRAY_4: '#F5F5F5',
  NAVY: '#002c46',
  NAVY_2: '#0c375a',
  NAVY_3: '#15446e',
  NAVY_4: '#205080',
  NAVY_5: '#74BCF6',
  RED_ERROR: '#ff6e6e',
};

export default colors;
