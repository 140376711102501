import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Header, Button, Divider, Segment } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';

const ModalMarginRates = ({
  hideModal,
  action,
  initiator,
  date,
  updateStatus,
  title,
  message,
  loadingSelector,
}) => {
  const actionLoadingSelector = useSelector(
    createLoadingSelector([loadingSelector]),
  );
  return (
    <Fragment>
      <Modal.Content>
        <Header as="h3">{title}</Header>
        <Divider clearing />
        <Segment basic textAlign="center">
          <Header.Subheader className="modalHeaderSizes">
            {message}
          </Header.Subheader>
          <div className="modalStyles">
            <div className="title">
              <p style={{ marginBottom: '5px' }}>
                {action === 'Confirm' ? 'Approver' : 'Rejecter'}
              </p>
              <p>Calculation Date</p>
            </div>
            <div className="row">
              <p>{initiator.email}</p>
              <p>{date}</p>
            </div>
          </div>
        </Segment>
      </Modal.Content>
      <Modal.Actions className="modal-form-actions space-between">
        <Button id="Cancel" onClick={() => hideModal()}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          color={action === 'Confirm' ? 'teal' : 'red'}
          id="confirm"
          onClick={() => updateStatus(initiator.email, date)}
          disabled={actionLoadingSelector}
        >
          {action === 'Confirm' ? 'Confirm' : 'Reject'}
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default ModalMarginRates;
