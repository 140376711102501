import { formatTimeLocalWithDoW } from '../utils/time';

const reconciliationHistory = [
  {
    columns: [
      {
        Header: 'Ending Time',
        id: 'endTime',
        accessor: ({ endTime }) => formatTimeLocalWithDoW(endTime, true),
      },
      {
        Header: 'State',
        id: 'state',
        accessor: (d) => {
          if (d.state === 0) {
            return 'Pending';
          } else if (d.state === 10) {
            return 'Posting';
          }
          return 'Posted';
        },
      },
      {
        Header: 'Starting Time',
        id: 'startTime',
        accessor: ({ startTime }) => formatTimeLocalWithDoW(startTime, true),
      },
      {
        Header: 'Ending External Balance',
        id: 'endExternalBalance',
        accessor: 'endExternalBalance',
      },
      {
        Header: 'Ending Internal Balance',
        id: 'endInternalBalance',
        accessor: 'endInternalBalance',
      },
    ],
  },
];

export default reconciliationHistory;
