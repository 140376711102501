import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select, Button, Dropdown } from 'semantic-ui-react';
import {
  getAllAccountsArray,
  fetchAccountsV2,
} from '../../reducers/accountsReducer';

const mapStateToProps = (state) => ({
  accountsList: getAllAccountsArray(state),
  accountsLoading: createLoadingSelector(['ACCOUNTS'])(state),
});

const mapDispatchToProps = {
  // keeping the name the same so bunch of snapshots don't change
  fetchAccounts: fetchAccountsV2,
};

const accountsOpts = ({ filteredAccounts, showCreate, createOnClick }) => {
  const options = [];
  if (showCreate) {
    options.push({
      content: (
        <Button
          fluid
          content="+ Create New Account"
          onClick={createOnClick}
          className="link"
        />
      ),
    });
  }

  return options.concat(
    filteredAccounts.map((account) => ({
      key: account.accountId,
      value: account.accountId,
      text: account.label,
      description: account.name,
    })),
  );
};

class AccountSelectionContainer extends Component {
  state = { searchQuery: '' };

  constructor(props) {
    super(props);
    // Debounce
    this.handleSearchChange = _.debounce(this.handleSearchChange, 500);
  }
  handleSearchChange = (e, { searchQuery }) => {
    this.props.fetchAccounts({
      filter: [
        { attr: 'label', op: 'contain', value: searchQuery },
        ...this.props.additionalFilters,
      ],
    });
    this.setState({ searchQuery });
  };

  render = () => {
    const {
      accountsList,
      accountType,
      withoutField,
      label,
      accountsLoading,
      showCreate,
      createOnClick,
    } = this.props;
    // filter the accounts to only show certain accounts.
    let filteredAccounts = [...accountsList];
    if (accountType) {
      filteredAccounts = accountsList.filter(
        (account) => account.accountType === accountType,
      );
    }

    if (withoutField) {
      return (
        <Select
          search
          selection
          clearable
          label={label}
          options={accountsOpts({
            filteredAccounts,
            showCreate,
            createOnClick,
          })}
          onChange={(e, { value }) =>
            _.get(this.props, ['input', 'onChange'], () => {})(value)
          }
          defaultValue={_.get(this.props, ['input', 'value'])}
          onSearchChange={this.handleSearchChange}
          loading={accountsLoading}
          noResultsMessage={
            this.state.searchQuery ? '' : 'Search for an account.'
          }
          {...this.props}
        />
      );
    }

    return (
      <Form.Field
        control={Select}
        search
        selection
        clearable
        label={this.props.label}
        options={accountsOpts({ filteredAccounts, showCreate, createOnClick })}
        onChange={(e, { value }) =>
          _.get(this.props, ['input', 'onChange'], () => {})(value)
        }
        defaultValue={_.get(this.props, ['input', 'value'])}
        onSearchChange={this.handleSearchChange}
        loading={this.props.accountsLoading}
        noResultsMessage={
          this.state.searchQuery ? '' : 'Search for an account.'
        }
        value={_.get(this.props, ['input', 'value'])}
        {...this.props}
      />
    );
  };
}

AccountSelectionContainer.propTypes = {
  additionalFilters: PropTypes.shape({
    attr: PropTypes.string,
    op: PropTypes.string,
    value: PropTypes.string,
  }),
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  accountsLoading: PropTypes.bool.isRequired,
  accountsList: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      name: PropTypes.string,
      members: PropTypes.array,
      balances: PropTypes.object,
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

// Specifies the default values for props:
AccountSelectionContainer.defaultProps = {
  additionalFilters: [],
  name: 'accountId',
  placeholder: 'Firm Code-Account Code-Sub Account Code',
  label:
    'Start typing the account label (Firm Code-Account Code-Sub Account Code)',
  multiple: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSelectionContainer);
