import React from 'react';
import styled from 'styled-components';
import TextButton from '../../../common/TextButton';
import colors from '../../../constants/colors';

const StatusCell = styled.div`
  display: flex;
`;

export const blockTradePermissionsMetadata = (
  onDelete: (authorizedTraderEmail: string) => void,
) => [
  {
    id: 'authorizedTraderEmail',
    accessor: 'authorizedTraderEmail',
    Header: 'Authorized Trader',
    width: 300,
  },
  {
    id: 'action',
    accessor: 'action',
    Header: 'Action',
    hideFilter: true,
    Cell: (props) => {
      return props.row?.original?.authorizedTraderEmail ? (
        <StatusCell>
          <TextButton
            text="Delete"
            onClick={() => onDelete(props.row.original.authorizedTraderEmail)}
            fontSize="14"
            color={colors.RED_ERROR}
          />
        </StatusCell>
      ) : null;
    },
  },
];
