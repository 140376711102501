const accountingValueFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 10,
});
const accountingValueFormatterNoMinFraction = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 10,
});
const usdValueFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const formatAccountingValue = (value, noFraction) => {
  if (value === 0 || value === '0' || !isFinite(value)) return value;
  if (!value) return '';
  if (noFraction) {
    return accountingValueFormatterNoMinFraction.format(value);
  }
  return accountingValueFormatter.format(value);
};

export const formatUsdValue = (value) => {
  if (!isFinite(value)) return value;
  if (!value) return '';
  if (value === 0 || value === '0') return '$0.00';
  return `$${usdValueFormatter.format(value)}`;
};
