import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Breach Threshold',
    id: 'breachThreshold',
    accessor: 'breachThreshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 200,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
