import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';
import { getAssetTypesAsOptions } from '../../selectors';

const AssetTypeSelectionContainer = ({
  name,
  id,
  input,
  label,
  placeholder,
  required,
}) => {
  const assetTypesOptions = useSelector(getAssetTypesAsOptions);
  const assetTypesLoading = useSelector(createLoadingSelector(['ASSET_TYPES']));

  const handleChange = (e, { value }) => {
    _.get(input, ['onChange'], () => {})(value);
  };

  return (
    <Form.Field
      control={Select}
      loading={assetTypesLoading}
      label={label}
      search
      name={input.name || name}
      id={id}
      placeholder={placeholder}
      options={assetTypesOptions}
      required={required}
      clearable
      onChange={handleChange}
      value={_.get(input, ['value'])}
    />
  );
};

AssetTypeSelectionContainer.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
  required: PropTypes.bool,
  assetTypesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  assetTypesLoading: PropTypes.bool.isRequired,
};

AssetTypeSelectionContainer.defaultProps = {
  id: '',
  input: {},
  name: 'assetType',
  placeholder: 'Select asset type...',
  label: 'Asset Type',
  required: false,
};

export default AssetTypeSelectionContainer;
