import { formatTimeLocal } from '../utils/time';

export default [
  {
    Header: 'Time',
    id: 'time',
    accessor: (row) => formatTimeLocal(row.time),
  },
  {
    Header: 'Journal Id',
    id: 'journalId',
    accessor: 'journalId',
  },
  {
    Header: 'Report Date',
    id: 'reportDate',
    accessor: 'reportDate',
  },
  {
    Header: 'Asset Type',
    id: 'assetType',
    accessor: 'assetType',
  },
  {
    Header: 'Product',
    id: 'productSymbol',
    accessor: 'productSymbol',
  },
  {
    Header: 'Contract',
    id: 'contractSymbol',
    accessor: 'contractSymbol',
  },
  {
    Header: 'Journal Type',
    id: 'type',
    accessor: 'type',
  },
  {
    Header: 'Posting Key',
    id: 'key',
    accessor: 'key',
  },
  {
    Header: 'Funds Designation',
    id: 'fundsDesignation',
    accessor: 'fundsDesignation',
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: 'amount',
  },
];
