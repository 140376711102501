import _ from 'lodash';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import {
  createById,
  createCount,
  createCurrentPage,
  createPages,
} from 'erisxkit/client';
import { getUserIdFromURL, CREATE_EMPLOYEE_SUCCESS } from './usersReducer';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const SELECT_EMPLOYEE = 'SELECT_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const onboardingRoles = ['super_user', 'onboarding'];
export const authorizedIpRoles = [
  'super_user',
  'onboarding',
  'client_services',
  'compliance',
  'risk',
];
export const authorizedWriteIpRoles = [
  'super_user',
  'onboarding',
  'client_services',
];
export const writeAccessFCMRoles = [
  'super_user',
  'onboarding',
  'client_services',
];

export const readAccessFCMRoles = ['compliance', 'risk'];

export const EMPLOYEES = 'EMPLOYEES';

export const fetchEmployees = createRoutine(EMPLOYEES);

export const fetchEmployeesPromiseCreator = promisifyRoutine(fetchEmployees);

export function employeesReducer(state = {}, action) {
  switch (action.type) {
    case fetchEmployees.SUCCESS:
      let {
        payload: { req, ...rest },
        ...restAction
      } = action;
      const newAction = { ...restAction, payload: { ...rest } };
      return createById(fetchEmployees, 'users', 'userId')(state, newAction);
    case CREATE_EMPLOYEE_SUCCESS:
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

function selectedEmployeeReducer(state = '', action) {
  switch (action.type) {
    case SELECT_EMPLOYEE:
      return action.payload;
    default:
      return state;
  }
}

const employeesCountCondition =
  (routine) =>
  (state = 0, action) => {
    return createCount(routine)(state, action);
  };

const employeesCurrentPageCondition =
  (routine) =>
  (state = 0, action) => {
    return createCurrentPage(routine)(state, action);
  };

export const employeesPagesCondition =
  (routine, slice, id) =>
  (state = {}, action) => {
    return createPages(routine, slice, id)(state, action);
  };

export const getEmployeesCollection = (state) =>
  _.get(state.employees, 'byId', {});

export const getEmployeeList = (type) => (state) => {
  const employeesCollection = getEmployeesCollection(state);
  if (_.isEmpty(employeesCollection)) {
    return [];
  }
  // return all users in store if props were not provided
  if (!type) {
    return Object.values(employeesCollection);
  }
  return Object.values(employeesCollection).filter(
    (employee) => _.get(employee, 'type', '') === type,
  );
};

/**
 * @param {string} userId - identifying the userId of the user that has been selected
 */

export const selectEmployee = (userId) => ({
  type: SELECT_EMPLOYEE,
  payload: userId,
});

export const getSelectedEmployeeId = (state) => {
  if (!_.get(state.employees, 'selectedEmployeeId')) {
    return getUserIdFromURL();
  }
  return _.get(state.employees, 'selectedEmployeeId');
};

export const getSelectedEmployee = createSelector(
  [getEmployeesCollection, getSelectedEmployeeId],
  (employees, selectedEmployeeId) => _.get(employees, selectedEmployeeId, {}),
);

export const getSelectedEmployeeWithPermissions = createSelector(
  [getSelectedEmployee],
  (selectedEmployee) => {
    return {
      ...selectedEmployee,
    };
  },
);

export const getOnboardingEmployees = createSelector(
  [getEmployeeList()],
  (employees) => {
    return employees.filter(
      (x) => x.roles && x.roles.some((role) => onboardingRoles.includes(role)),
    );
  },
);

export default combineReducers({
  selectedEmployeeId: selectedEmployeeReducer,
  byId: employeesReducer,
  count: employeesCountCondition(fetchEmployees),
  currentPage: employeesCurrentPageCondition(fetchEmployees),
  pages: employeesPagesCondition(fetchEmployees, 'users', 'userId'),
});
