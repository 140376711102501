import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import { Dropdown, Icon, Image, Label, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import PropTypes from 'prop-types';
import { getLoggedInUser, getSelectorAsOptions } from '../selectors';
import { storeUser } from '../actions/authActions';
import {
  createLedger,
  getActiveLedgerName,
  getLedgers,
  ledgers,
  switchLedger,
  getLedgerCount,
} from '../reducers/ledgersReducer';
import { showModal, hideModal } from 'erisxkit/client';
import {
  CREATE_LEDGER,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../constants/modalTypes';
import Breadcrumbs from './Breadcrumbs';
import history from '../constants/history';
import { DEFAULT_LEDGER } from '../constants/ledgers';
import { getEnv } from '../reducers/envReducer';
import { getCurrentUser } from '../reducers/usersReducer';

const mapDispatchToProps = {
  createLedger,
  hideModal,
  showModal,
  storeUser,
  switchLedger,
};

const mapStateToProps = (state) => ({
  activeLedgerName: getActiveLedgerName(state),
  enableLedgers: get(getEnv(state), 'enableFacilitiesManagement', 'false'),
  user: getLoggedInUser(state),
  currentUser: getCurrentUser(state),
  ledgers: getLedgers(state),
  ledgersLoading: createLoadingSelector([ledgers._PREFIX])(state),
  ledgerOptions: getSelectorAsOptions(getLedgers, {
    className: 'ledgerOption',
    description: (
      ledger, // add description for default ledger
    ) =>
      get(ledger, ['ledgerId']) === DEFAULT_LEDGER.ledgerId ? '(Default)' : '',
    key: 'ledgerId',
    text: 'name',
    value: 'ledgerId',
  })(state),
  ledgerCount: getLedgerCount(state),
});

class FixedMenu extends Component {
  logout = (e, data) => {
    this.props.auth.logout();
  };

  confirmAction = (payload) => {
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      closeOnEscape: true,
      header: 'Create New Ledger',
      hideModal: this.props.hideModal,
      msg: 'Create a new ledger with the following details?',
      onConfirm: () => {
        this.props.createLedger(payload);
        this.props.hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
        this.props.hideModal(CREATE_LEDGER);
      },
      payload,
    });
  };

  addLedger = () => {
    this.props.showModal(CREATE_LEDGER, {
      className: 'show-overflow',
      closeOnDimmerClick: true,
      closeOnEscape: true,
      confirmAction: this.confirmAction,
      defaultLedger: !this.props.ledgerCount,
      hideModal: () => this.props.hideModal(CREATE_LEDGER),
      ledgers: this.props.ledgers,
    });
  };

  renderLedgerLabel = () => {
    const { activeLedgerName } = this.props;
    return (
      <Label
        className={
          activeLedgerName === DEFAULT_LEDGER.name ? 'navy' : undefined
        }
        size="large"
        horizontal
      >
        {activeLedgerName || '(No default ledger created)'}
      </Label>
    );
  };

  render = () => {
    const { enableLedgers, ledgerCount, ledgerOptions, switchLedger } =
      this.props;
    const trigger = (
      <span>
        {enableLedgers === 'true' && this.renderLedgerLabel()}
        <Image avatar src={this.props.currentUser && this.props.currentUser.picture} />
      </span>
    );

    const options = [
      {
        key: 'name',
        as: () =>  <div role="option" className="selected item">
          {this.props.currentUser && this.props.currentUser.name}
        </div>
      },
      {
        key: 'profile',
        text: 'Profile',
        icon: 'user',
        onClick: () => history.push('/profile'),
      },
      {
        key: 'divider-1',
        as: () => <Dropdown.Divider />,
      },
      // show ledger settings only if feature flag is set
      ...((enableLedgers === 'true' && [
        {
          key: 'createLedger',
          id: 'createLedger',
          text: ledgerCount > 0 ? 'Create New Ledger' : 'Create Default Ledger',
          icon: 'plus',
          onClick: this.addLedger,
        },
        {
          key: 'switchLedger',
          as: () => (
            <Dropdown
              loading={this.props.ledgersLoading}
              pointing="right"
              icon={false}
              trigger={
                <Dropdown.Item id="switchLedger" disabled={!ledgerCount}>
                  <Icon name="refresh" />
                  <span>Switch Ledger</span>
                </Dropdown.Item>
              }
              options={[
                {
                  key: 'header',
                  as: () => (
                    <Dropdown.Header>Available Ledgers</Dropdown.Header>
                  ),
                },
                { key: 'divider-header', as: () => <Dropdown.Divider /> },
                ...ledgerOptions,
              ]}
              onChange={(e, { value }) => {
                switchLedger(value);
                if (value) {
                  localStorage.setItem('ledger_id', value);
                } else {
                  localStorage.removeItem('ledger_id');
                }
                history.push('/home');
              }}
            />
          ),
        },
        {
          key: 'divider-2',
          as: () => <Dropdown.Divider />,
        },
      ]) ||
        []),
      {
        key: 'sign-out',
        id: 'signOut',
        text: 'Sign Out',
        icon: 'sign out',
        onClick: this.logout,
      },
    ];

    return (
      <Menu borderless id="top-nav">
        {this.props.auth.isAuthenticated() && (
          <Fragment>
            <Menu.Item className="menu-item-breadcrumbs" position="left">
              <Breadcrumbs />
            </Menu.Item>
            <Menu.Item className="menu-item-user" position="right">
              <Dropdown
                trigger={trigger}
                options={options}
                pointing="top right"
                icon={null}
              />
            </Menu.Item>
          </Fragment>
        )}
      </Menu>
    );
  };
}

FixedMenu.propTypes = {
  activeLedgerName: PropTypes.string,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func,
    logout: PropTypes.func,
  }).isRequired,
  createLedger: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  ledgerCount: PropTypes.number,
  ledgersLoading: PropTypes.bool,
  ledgerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  showModal: PropTypes.func.isRequired,
  switchLedger: PropTypes.func.isRequired,
  user: PropTypes.shape({
    picture: PropTypes.string,
    nickname: PropTypes.string,
  }),
};

FixedMenu.defaultProps = {
  activeLedgerName: '',
  ledgerCount: 0,
  ledgersLoading: false,
  ledgerOptions: [],
  user: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixedMenu);
