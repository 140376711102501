import _ from 'lodash';
import React, { Fragment, Component } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { renderField } from 'erisxkit/client';
import { Field, reduxForm, FieldArray } from 'redux-form';

const Question = ({ field, index }) => (
  <Form.Group key={index} className="security-question">
    <Field
      label="Question:"
      component={renderField}
      name={`${field}.question`}
      fluid
    />
    <Field
      fluid
      label="Answer:"
      name={`${field}.choice`}
      component={renderField}
    />
  </Form.Group>
);

const renderQuestions = ({ fields }) => (
  <div>
    {fields.map((field, index) => (
      <Question field={field} key={index} index={index} />
    ))}
  </div>
);

class SecurityQuestionsForm extends Component {
  componentDidMount() {
    this.initializeForm(this.props.questions);
  }

  componentWillReceiveProps = (nextProps) => {
    if (!_.isEqual(this.props.questions, nextProps.questions)) {
      this.initializeForm(nextProps.questions);
    }
  };

  initializeForm = (questions) => {
    if (questions.length) {
      this.props.initialize({ answers: questions });
    } else {
      this.props.initialize({ answers: [{}, {}, {}] });
    }
  };
  render = () => (
    <Fragment>
      <FieldArray name="answers" component={renderQuestions} />
      <Button onClick={this.props.save}>Save</Button>
    </Fragment>
  );
}

export default reduxForm({
  form: 'security',
  enableReinitialize: true,
})(SecurityQuestionsForm);
