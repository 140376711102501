import { fetchFcmReports } from '../reducers/fcmReportsReducer';
import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    fetchFcmReports.TRIGGER,
    createSaga(fetchFcmReports, fetchFcmReports._PREFIX),
  );
}
