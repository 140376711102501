import React from 'react';
import ClearingMemberAccountsContainer from '../../ClearingMemberAccounts/ClearingMemberAccountsContainer';

const AddLedgerAccountContainer = ({
  accounts,
  personalValues,
  addLedgerAccounts,
  removeLedgerAccounts,
}) => (
  <div className="xwizard-form-group">
    <ClearingMemberAccountsContainer
      userAccounts={accounts}
      wizardView
      addLedgerAccounts={addLedgerAccounts}
      removeLedgerAccounts={removeLedgerAccounts}
      personalValues={personalValues}
    />
  </div>
);

export default AddLedgerAccountContainer;
