import { takeLatest, takeEvery } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  updateAchDefaults,
  fetchAchDefaults,
  fetchJurisdictions,
  fetchCountriesForJurisdictions,
  updateJurisdictions,
  ACH_DEFAULTS,
  JURISDICTIONS,
  UPDATE_ACH_DEFAULTS,
  UPDATE_JURISDICTIONS,
  GET_COUNTRIES_FOR_JURISDICTIONS,
  fetchTrustThresholdSettings,
  updateTrustThresholdSettings,
} from '../reducers/configurationReducer';
import generateSaga from '../utils/generateSaga';

export default function* watchConfiguration() {
  yield takeLatest(
    fetchAchDefaults.TRIGGER,
    createSaga(fetchAchDefaults, ACH_DEFAULTS),
  );
  yield takeLatest(
    updateAchDefaults.TRIGGER,
    createSaga(
      updateAchDefaults,
      UPDATE_ACH_DEFAULTS,
      'Submitting ACH defaults...',
      'ACH defaults submitted.',
    ),
  );
  yield takeEvery(
    fetchJurisdictions.TRIGGER,
    createSaga(fetchJurisdictions, JURISDICTIONS),
  );
  yield takeLatest(
    updateJurisdictions.TRIGGER,
    createSaga(
      updateJurisdictions,
      UPDATE_JURISDICTIONS,
      'Applying Jurisdictions defaults...',
      'Jurisdictions applied.',
    ),
  );
  yield takeLatest(
    fetchCountriesForJurisdictions.TRIGGER,
    createSaga(fetchCountriesForJurisdictions, GET_COUNTRIES_FOR_JURISDICTIONS),
  );
  yield takeLatest(
    fetchTrustThresholdSettings,
    generateSaga(fetchTrustThresholdSettings),
  );
  yield takeLatest(
    updateTrustThresholdSettings,
    generateSaga(updateTrustThresholdSettings, {
      loading: 'Updating TRUST configuration settings...',
      success: 'TRUST configurations updated successfully',
    }),
  );
}
