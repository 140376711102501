import React, { Fragment } from 'react';
import {
  Header,
  Button,
  Segment,
  Form,
  Dropdown,
  List,
  Checkbox,
} from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ManualOnboarding = (props) => {
  const {
    email = '',
    userId = '',
    state = '',
    accountLinks = [],
  } = props.selectedUser;
  return (
    <Fragment>
      <Header as="h1">Manual Onboarding</Header>
      <Form>
        <Segment color="teal">
          <Header as="h3">Clearing Member ID: {userId}</Header>
          <List>
            <List.Item icon="mail" content={email || '-'} />
            <List.Item icon="barcode" content={userId || '-'} />
            <List.Item icon="compass" content={state || '-'} />
          </List>
          <Header as="h3">
            Clearing Account:{' '}
            <Dropdown
              inline
              loading={props.accountsLoading}
              name="accountId"
              options={
                !_.isEmpty(props.accounts) && props.accounts[0]
                  ? props.accounts.map((account) => ({
                      text: _.get(account, 'accountId'),
                      description: _.get(account, 'name'),
                      value: _.get(account, 'accountId'),
                      key: _.get(account, 'accountId'),
                    }))
                  : []
              }
              onChange={props.handleChange}
              placeholder="Select a clearing account..."
            />
            <Button onClick={() => props.createAndLinkClearingAccount(userId)}>
              Create and link a new clearing account
            </Button>
          </Header>
          <Header as="h3">
            Emarket Account:{' '}
            <Dropdown
              inline
              loading={props.accountsLoading}
              name="emarketAccountNumber"
              options={
                !_.isEmpty(props.accounts) && props.accounts[0]
                  ? props.accounts.map((account) => ({
                      text: _.get(account, 'emarketAccountNumber'),
                      description: _.get(account, 'name'),
                      value: _.get(account, 'emarketAccountNumber'),
                      key: _.get(account, 'emarketAccountNumber'),
                    }))
                  : []
              }
              onChange={props.handleChange}
              placeholder="Select a Emarket account..."
            />
          </Header>
          <Header as="h3">
            Emarket Trading ID:{' '}
            <Dropdown
              inline
              loading={props.accountsLoading}
              name="emarketTradingId"
              options={
                !_.isEmpty(accountLinks) && accountLinks[0]
                  ? accountLinks.map((accountLink) => ({
                      text: _.get(accountLink, 'emarketLogin'),
                      value: _.get(accountLink, 'emarketLogin'),
                      key: _.get(accountLink, 'emarketLogin'),
                    }))
                  : []
              }
              onChange={props.handleChange}
              placeholder="Select a Emarket Trading ID..."
            />
          </Header>
        </Segment>
        <Header as="h2">Emarket Onboarding</Header>
        <Segment vertical padded="very">
          <h3>
            Create a Emarket Trading Id associated to a given Emarket account:
          </h3>
          <Form.Input
            label="Clearing Member User ID"
            name="userId"
            placeholder="User ID"
            defaultValue={userId}
            onChange={props.handleChange}
          />
          <Form.Input
            label="Clearing Member Account ID"
            name="accountId"
            placeholder="Account ID"
            defaultValue={props.selectedAccountId}
            onChange={props.handleChange}
          />
          <Form.Checkbox
            label="Cancel On Disconnect"
            name="cancelOnDisconnect"
            onChange={props.handleChange}
          />
          <Button
            onClick={() =>
              props.createTradeCxUser({
                memberUserId: userId,
                accountId: props.selectedAccountId,
                maxSpotOrderSize: props.maxSpotOrderSize,
                maxFuturesOrderSize: props.maxFuturesOrderSize,
                cancelOnDisconnect: props.cancelOnDisconnect,
              })
            }
          >
            Create Emarket Trading ID
          </Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Set the CQG routing relationship for a Emarket trading ID:</h3>
          <Form.Input
            label="Emarket Trading ID"
            name="emarketUserId"
            placeholder="Trading ID"
            onChange={props.handleChange}
            defaultValue={props.selectedCxTradingId}
          />
          <Form.Button
            onClick={() =>
              props.routeCxUserThroughCQG({
                memberUserId: userId,
                emarketUserId: props.selectedCxTradingId,
              })
            }
          >
            Create Routing Relationship
          </Form.Button>
        </Segment>
        <Header as="h2">CQG Onboarding</Header>
        <Segment vertical padded="very">
          <h3>Create CQG customer:</h3>
          <Form.Input
            id="create-cqg-customer_email"
            label="Clearing Member email"
            name="email"
            placeholder="Email"
            onChange={props.handleChange}
            defaultValue={email}
          />
          <Form.Button
            id="create-cqg-customer"
            onClick={() => props.createCqgCustomer({ email, userId })}
          >
            Create CQG Customer
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Create CQG trader</h3>
          <Form.Input
            label="Clearing Member email"
            name="email"
            placeholder="Email"
            onChange={props.handleChange}
            defaultValue={email}
          />
          <Form.Input
            label="CQG Customer ID"
            name="cqgCustomerId"
            placeholder="Customer ID"
            onChange={props.handleChange}
            defaultValue={props.cqgCustomerId}
          />
          <Form.Input
            label="Clearing Member user ID"
            name="userId"
            placeholder="User ID"
            onChange={props.handleChange}
            defaultValue={userId}
          />
          <Form.Button
            onClick={() =>
              props.createCqgTrader({
                email,
                cqgCustomerId: props.cqgCustomerId,
                userId,
              })
            }
          >
            Create CQG Trader
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Create CQG account:</h3>
          <Form.Input
            label="Emarket account Number"
            name="emarketAccountNumber"
            placeholder="Emarket account Number"
            onChange={props.handleChange}
            defaultValue={props.selectedCxAccountId}
          />
          <Form.Input
            label="CQG trader ID"
            name="cqgTraderId"
            placeholder="CQG trader ID"
            onChange={props.handleChange}
            defaultValue={props.cqgTraderId}
          />
          <Form.Input
            label="User ID"
            name="userId"
            placeholder="User ID"
            onChange={props.handleChange}
            defaultValue={userId}
          />
          <Form.Input
            label="Clearing Member Account ID"
            name="accountId"
            placeholder="Account ID"
            onChange={props.handleChange}
            defaultValue={props.selectedAccountId}
          />
          <Form.Button
            onClick={() => {
              props.createCqgAccount({
                emarketAccountId: props.selectedCxAccountId,
                cqgTraderId: props.cqgTraderId,
                userId,
                accountId: props.selectedAccountId,
              });
            }}
          >
            Create CQG Account
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Add required CQG services to a CQG trader:</h3>
          <Form.Input
            label="CQG trader ID"
            name="cqgTraderId"
            placeholder="CQG trader ID"
            onChange={props.handleChange}
            defaultValue={props.cqgTraderId}
          />
          <Form.Button
            onClick={() =>
              props.addRequiredCqgServicesToCqgTrader(props.cqgTraderId)
            }
          >
            Add Required CQG Services
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Link CQG Trader to CQG Customer</h3>
          <Form.Input
            label="CQG Trader ID"
            name="cqgTraderId"
            placeholder="Trader ID"
            onChange={props.handleChange}
            defaultValue={props.cqgTraderId}
          />
          <Form.Input
            label="CQG Customer ID"
            name="cqgCustomerId"
            placeholder="Customer ID"
            onChange={props.handleChange}
            defaultValue={props.cqgCustomerId}
          />
          <Form.Button
            onClick={() =>
              props.linkCqgTraderToCqgCustomer({
                cqgTraderId: props.cqgTraderId,
                cqgCustomerId: props.cqgCustomerId,
              })
            }
          >
            Link CQG Trader to CQG Customer
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Add CQG trader to a CQG account</h3>
          <Form.Input
            label="CQG Account ID"
            name="cqgAccountId"
            placeholder="Account ID"
            onChange={props.handleChange}
            defaultValue={props.cqgAccountId}
          />
          <Form.Input
            label="CQG Trader ID"
            name="cqgTraderId"
            placeholder="Trader ID"
            onChange={props.handleChange}
            defaultValue={props.cqgTraderId}
          />
          <Form.Button
            onClick={() =>
              props.addCqgTraderToCqgAccount({
                cqgAccountId: props.cqgAccountId,
                cqgTraderId: props.cqgTraderId,
              })
            }
          >
            Add CQG Trader to CQG Account
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Add a Emarket trader to a CQG trader</h3>
          <Form.Input
            label="CQG Trader ID"
            name="cqgTraderId"
            placeholder="Trader ID"
            onChange={props.handleChange}
            defaultValue={props.cqgTraderId}
          />
          <Form.Input
            label="Emarket Trading ID"
            name="emarketTradingId"
            placeholder="Trading ID"
            onChange={props.handleChange}
            defaultValue={props.selectedCxTradingId}
          />
          <Form.Button
            onClick={() =>
              props.addCxTraderIdToCqgTrader({
                cqgTraderId: props.cqgTraderId,
                emarketUserId: props.selectedCxTradingId,
              })
            }
          >
            Add Emarket Trader to CQG Trader
          </Form.Button>
        </Segment>
        <Segment vertical padded="very">
          <h3>Send a welcome email to a CQG trader</h3>
          <Form.Input
            label="CQG Trader ID"
            name="cqgTraderId"
            placeholder="Trader ID"
            onChange={props.handleChange}
            defaultValue={props.cqgTraderId}
          />
          <Form.Input
            label="CQG Customer ID"
            name="cqgCustomerId"
            placeholder="Customer ID"
            onChange={props.handleChange}
            defaultValue={props.cqgCustomerId}
          />
          <Form.Button
            onClick={() =>
              props.sendCqgWelcomeEmail({
                cqgTraderId: props.cqgTraderId,
                cqgCustomerId: props.cqgCustomerId,
              })
            }
          >
            Send CQG welcome email
          </Form.Button>
        </Segment>
      </Form>
    </Fragment>
  );
};

ManualOnboarding.propTypes = {
  selectedUser: PropTypes.shape({
    email: PropTypes.string,
    userId: PropTypes.string,
    state: PropTypes.string,
    accountLinks: PropTypes.arrayOf(PropTypes.object),
  }),
  accountsLoading: PropTypes.bool,
  accounts: PropTypes.arrayOf(PropTypes.object),
  selectedAccountId: PropTypes.string,
  selectedCxAccountId: PropTypes.string,
  selectedCxTradingId: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  createAndLinkClearingAccount: PropTypes.func.isRequired,
  createCxAccount: PropTypes.func.isRequired,
  createTradeCxUser: PropTypes.func.isRequired,
  routeCxUserThroughCQG: PropTypes.func.isRequired,
  createCqgCustomer: PropTypes.func.isRequired,
  createCqgTrader: PropTypes.func.isRequired,
  createCqgAccount: PropTypes.func.isRequired,
  addRequiredCqgServicesToCqgTrader: PropTypes.func.isRequired,
  linkCqgTraderToCqgCustomer: PropTypes.func.isRequired,
  addCqgTraderToCqgAccount: PropTypes.func.isRequired,
  addCxTraderIdToCqgTrader: PropTypes.func.isRequired,
  sendCqgWelcomeEmail: PropTypes.func.isRequired,
};

ManualOnboarding.defaultProps = {
  selectedUser: {},
  accountsLoading: false,
  accounts: [],
  selectedAccountId: '',
  selectedCxAccountId: '',
  selectedCxTradingId: '',
};

export default ManualOnboarding;
