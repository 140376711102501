import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Header, Segment, Label } from 'semantic-ui-react';
import { renderField, createLoadingSelector } from 'erisxkit/client';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { useSelector, useDispatch, connect } from 'react-redux';
import { roles } from '../../constants/userRoles';
import useRoleValidation from '../../hooks/useRoleValidation';
import {
  SET_TRUST_THRESHOLD,
  TRUST_THRESHOLD_SETTINGS,
  fetchTrustThresholdSettings,
  getTrustThresholds,
  updateTrustThresholdSettings,
} from '../../reducers/configurationReducer';

const TRUST_THRESHOLDS_FORM = 'trustThresholdsForm';
export const TRUST_THRESHOLDS_READ_ONLY_ROLES = [
  roles.ONBOARDING,
  roles.CLIENT_SERVICES,
];
export const TRUST_THRESHOLDS_READ_WRITE_ROLES = [
  roles.SUPER_USER,
  roles.TREASURY,
  roles.COMPLIANCE,
  roles.CLEARING_OPS,
];

const TrustThresholds = (props) => {
  const { pristine } = props;

  const dispatch = useDispatch();
  const isReadOnly = useRoleValidation(TRUST_THRESHOLDS_READ_ONLY_ROLES);
  const canUpdate = useRoleValidation(TRUST_THRESHOLDS_READ_WRITE_ROLES);

  const values = useSelector((state) =>
    getFormValues(TRUST_THRESHOLDS_FORM)(state),
  );

  const isUpdating = useSelector((state) =>
    createLoadingSelector([SET_TRUST_THRESHOLD])(state),
  );
  const isLoading = useSelector((state) =>
    createLoadingSelector([TRUST_THRESHOLD_SETTINGS])(state),
  );

  const onSubmit = useCallback(
    () => dispatch(updateTrustThresholdSettings({ ...values })),
    [values],
  );

  const updateDisabled = useMemo(
    () => pristine || isLoading || isUpdating || isReadOnly || !canUpdate,
    [pristine, isLoading, isUpdating, isReadOnly, canUpdate],
  );

  const fieldDisabled = useMemo(
    () => isReadOnly || !canUpdate || isUpdating || isLoading,
    [isReadOnly, canUpdate, isUpdating, isLoading],
  );

  useEffect(() => {
    dispatch(fetchTrustThresholdSettings());
  }, []);

  return (
    <Segment>
      <Form>
        <Header dividing>TRUST Threshold Configurations</Header>
        <Form.Group>
          <Field
            label="Notional Threshold"
            id="threshold"
            name="threshold"
            component={renderField}
            labelPosition="right"
            disabled={fieldDisabled}
          >
            <input />
            <Label>USD</Label>
          </Field>
          <Field
            label="Buffer Percentage"
            id="buffer"
            name="buffer"
            component={renderField}
            labelPosition="right"
            disabled={fieldDisabled}
          >
            <input />
            <Label>%</Label>
          </Field>
        </Form.Group>
      </Form>
      <Button
        onClick={onSubmit}
        disabled={updateDisabled}
        size="large"
        className="add-button"
      >
        Update
      </Button>
    </Segment>
  );
};

TrustThresholds.defaultProps = {
  pristine: true,
};

TrustThresholds.propTypes = {
  pristine: PropTypes.bool,
};

export default connect((state) => ({
  initialValues: getTrustThresholds(state),
}))(
  reduxForm({
    form: TRUST_THRESHOLDS_FORM,
    enableReinitialize: true,
  })(TrustThresholds),
);
