import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions, createAction } from 'redux-actions';
import { get } from 'lodash';
import * as actionTypes from '../constants/actionTypes';

export const fetchBaseMarginRates = createRoutine(
  actionTypes.BASE_MARGIN_RATES,
);
export const fetchMarginRatesMultipliers = createRoutine(
  actionTypes.MARGIN_RATES_MULTIPLIERS,
);
export const fetchAppliedMarginRates = createRoutine(
  actionTypes.APPLIED_MARGIN_RATES,
);

export const createAppliedMarginRates = createRoutine(
  actionTypes.CREATE_APPLIED_MARGIN_RATES,
);
export const createAppliedMarginRatesPromiseCreator = promisifyRoutine(
  createAppliedMarginRates,
);

export const rejectMarginRates = createRoutine(actionTypes.REJECT_MARGIN_RATES);
export const rejectMarginRatesPromiseCreator =
  promisifyRoutine(rejectMarginRates);
export const applyMarginRates = createRoutine(actionTypes.ACCEPT_MARGIN_RATES);
export const applyMarginRatesPromiseCreator =
  promisifyRoutine(applyMarginRates);
export const EDIT_MARGIN_RATES = 'EDIT_MARGIN_RATES';
export const UPDATE_MARGIN_RATE_INFO_DATE = 'UPDATE_MARGIN_RATE_INFO_DATE';
export const updateMarginRateInfoDate = createAction(
  UPDATE_MARGIN_RATE_INFO_DATE,
);
export const editMarginRates = createAction(EDIT_MARGIN_RATES);

// Reducer
export default handleActions(
  {
    [fetchBaseMarginRates.SUCCESS]: (state, { payload }) => ({
      ...state,
      baseMarginRates: payload,
    }),
    [fetchMarginRatesMultipliers.SUCCESS]: (state, { payload }) => ({
      ...state,
      marginRatesMultipliers: payload,
    }),
    [fetchAppliedMarginRates.SUCCESS]: (state, { payload }) => ({
      ...state,
      appliedMarginRates: payload,
    }),
    [editMarginRates]: (state, { payload }) => {
      let data = state.marginRate.marginRates.slice();
      data[payload.index].modifiedBase = payload.newValue;
      return {
        ...state,
        marginRates: data,
      };
    },
    [updateMarginRateInfoDate]: (state, { payload }) => {
      return {
        ...state,
        date: payload,
      };
    },
    [createAppliedMarginRates.SUCCESS]: (state, { payload }) => ({
      ...state,
    }),
  },
  {},
);

export const getMarginRateInfo = (state) => get(state, ['marginRateInfo'], []);
export const getMarginRateInfoDate = (state) =>
  get(state, ['marginRateInfo', 'date'], []);
export const getBaseMarginRates = (state) =>
  get(state, ['marginRateInfo', 'baseMarginRates'], []);
export const getMarginRatesMultipliers = (state) =>
  get(state, ['marginRateInfo', 'marginRatesMultipliers'], []);
export const getAppliedMarginRates = (state) =>
  get(state, ['marginRateInfo', 'appliedMarginRates'], []);
