import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fundsSegregationBalances,
  fetchMovements,
  MOVEMENTS,
  fetchTradeJournals,
  TRADE_JOURNALS,
} from '../reducers/balancesReducer';
import { FUNDS_SEGREGATION_BALANCES } from '../constants/actionTypes';

export default function* watchBalances() {
  yield takeLatest(
    fundsSegregationBalances.TRIGGER,
    createSaga(fundsSegregationBalances, FUNDS_SEGREGATION_BALANCES),
  );
  yield takeLatest(
    fetchMovements.TRIGGER,
    createSaga(fetchMovements, MOVEMENTS, '', '', '', '', { withPage: true }),
  );
  yield takeLatest(
    fetchTradeJournals.TRIGGER,
    createSaga(fetchTradeJournals, TRADE_JOURNALS, '', '', '', '', {
      withPage: true,
    }),
  );
}
