import * as actions from '../reducers/referralCodeReducer';
import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    actions.fetchReferralCode.TRIGGER,
    createSaga(actions.fetchReferralCode, actions.fetchReferralCode._PREFIX),
  );
  yield takeLatest(
    actions.setReferralCode.TRIGGER,
    createSaga(actions.setReferralCode, actions.setReferralCode._PREFIX),
  );
  yield takeLatest(
    actions.getPromotionReferrals.TRIGGER,
    createSaga(
      actions.getPromotionReferrals,
      actions.getPromotionReferrals._PREFIX,
    ),
  );
}
