import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  showModal,
  hideModal,
  states,
  renderDropdown,
  renderField,
  rules,
  renderCheckbox,
  fundsDesignation,
} from 'erisxkit/client';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Button } from 'semantic-ui-react';
import cti from '../constants/cti';
import origin from '../constants/origin';
import MemberSelectionContainer from '../common/containers/MemberSelectionContainer';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import { BROKER, CLEARING } from '../constants/accountTypes';
import { CREATE_EMARKET_ACCOUNT } from '../constants/modalTypes';
import { createEmarketAccount } from '../reducers/manualOnboardingReducer';
import SubExchangeEntitlementsContainer from '../common/containers/SubExchangeEntitlementsContainer';
import * as clearingAccountTypes from '../constants/clearingAccountTypes';
import * as clearingMemberTypes from '../constants/clearingMemberTypes';
import {
  fetchFCMFirmCodes,
  getFCMFirmCodes,
} from '../reducers/accountsReducer';
import TableAuthorizedJurisdictions from './TableAuthorizedJurisdictions';

const maxLength4 = rules.maxLength(4);
const maxLength6 = rules.maxLength(6);
const maxLength10 = rules.maxLength(10);

const noSpaces = (value, previousValue) => {
  if (!value) {
    return value;
  }
  return value.replace(/[ ,]+/g, '');
};

export const CreateClearingAccount = ({
  emarketAccountTypesOptions,
  defaultLedgerIsActive,
  accountType,
  clearingAccountType,
  ledgerIds,
  selectedAccount,
  update,
  showModal,
  hideModal,
  createEmarketAccount,
  fetchFCMFirmCodes,
  emarketFeeProfileOptions,
  saveChanges,
  initialValues,
  riskProfilesOptions,
  marginProfilesOptions,
  feeProfilesOptions,
  iraProviders,
  iraAccountTypes,
  change,
  FCMFirmCodes,
  allowAuthorizedJurisdictions,
  jurisdictions,
  defaultJurisdictions,
  allowFuturesMargin,
}) => {
  const createEmarketAccountModal = ({
    accountId,
    memberId,
    emarketFeeProfileId,
  }) => {
    showModal(CREATE_EMARKET_ACCOUNT, {
      hideModal,
      closeOnDimmerClick: false,
      closeOnEscape: true,
      emarketFeeProfileOptions,
      emarketFeeProfileId: emarketFeeProfileId
        ? Number(emarketFeeProfileId)
        : null,
      onConfirm: (values) => {
        createEmarketAccount({ ...values, accountId, memberId });
        hideModal();
      },
    });
  };

  const onChange = (data) => {
    change('authorizedJurisdictions', data);
  };

  const iraCustodians = iraProviders?.iraCustodians;

  // Order iraCustodians asc by name
  iraCustodians?.sort(function (a, b) {
    if (b.name > a.name) {
      return -1;
    }
  });

  useEffect(() => {
    if (clearingAccountType === clearingAccountTypes.SPOT_DELIVERY.type) {
      fetchFCMFirmCodes();
    }
  }, [clearingAccountType]);

  return (
    <Fragment>
      <Form.Group widths="equal">
        <Field
          name="firmCode"
          component={renderField}
          label="Firm Code"
          required
          validate={[maxLength4, rules.required]}
        />
        <Field
          name="accountCode"
          component={renderField}
          label="Account Code"
          required
          validate={[maxLength6, rules.required]}
        />
        <Field
          name="subAccountCode"
          component={renderField}
          label="Sub Account Code"
          validate={[maxLength10]}
        />
      </Form.Group>
      <Form.Group>
        <Field
          name="name"
          component={renderField}
          label="Account Name"
          required
          validate={[rules.required]}
        />
        <Field
          name="phoneNumber"
          component={renderField}
          label="Phone Number"
        />
        <Field
          clearable
          component={MemberSelectionContainer}
          label="Member"
          name="memberId"
        />
        <Field
          clearable
          component={AccountSelectionContainer}
          label="Parent Account"
          name="parentAccountId"
          defaultValue={selectedAccount.parentAccountId}
        />
      </Form.Group>
      {/* TODO: disable these fields if the member id isn't enabled for them */}
      {accountType === CLEARING && (
        <Fragment>
          <section className="xwizard-form-group">
            <h4 className="dividing">IRA</h4>
            <Form.Group widths="equal">
              <Field
                name="iraCustodianId"
                component={renderDropdown}
                label="IRA Provider"
                options={iraCustodians?.map((iraCustodianId) => ({
                  key: iraCustodianId.id,
                  value: iraCustodianId.id,
                  text: iraCustodianId.name,
                }))}
                onChange={(e, value) => {
                  const iraCustodian = iraCustodians?.find(
                    (x) => x.id == value,
                  );
                  const iraEin = iraCustodian?.ein;
                  const feeDesignation = iraCustodian?.feeDesignation;
                  change('iraEin', iraEin || '');
                  change('feesDesignation', feeDesignation || '');
                }}
              />
              <Field name="iraEin" component={renderField} label="EIN" />
              <Field
                name="iraAcctType"
                component={renderDropdown}
                label="IRA Account Type"
                options={iraAccountTypes?.map((iraAcctType) => ({
                  key: iraAcctType.id,
                  value: iraAcctType.id,
                  text: iraAcctType.name,
                }))}
                onChange={(e, value) => {
                  const subAccountCode = iraAccountTypes?.find(
                    (x) => x.id == value,
                  )?.subAccountCode;
                  change('subAccountCode', subAccountCode || '');
                }}
              />
              <Field
                name="iraAcctNum"
                component={renderField}
                label="IRA Account Number"
              />
            </Form.Group>
          </section>

          <section className="xwizard-form-group">
            <h4 className="dividing">Entitlements</h4>
            <Form.Group widths="equal">
              <Field
                name="allowCollateral"
                component={renderCheckbox}
                label="Allow Collateral"
              />
              <Field
                name="allowFutures"
                component={renderCheckbox}
                label="Allow Cboe Digital Fully Funded Futures"
              />
              <Field
                name="allowSpot"
                component={renderCheckbox}
                label="Allow Spot"
              />
              <Field
                name="allowFuturesMargin"
                component={renderCheckbox}
                label="Allow Futures Margin"
              />
              <Field
                name="allowAuthorizedJurisdictions"
                component={renderCheckbox}
                label="Allow Onboarding And Funding For Authorized Jurisdictions"
              />
            </Form.Group>
            {allowAuthorizedJurisdictions &&
              defaultJurisdictions.length > 0 && (
                <TableAuthorizedJurisdictions
                  onChange={onChange}
                  jurisdictions={jurisdictions}
                  defaultJurisdictions={defaultJurisdictions}
                  update
                />
              )}
          </section>
          <section className="xwizard-form-group">
            <h4 className="dividing">SubExchange Entitlements</h4>
            <SubExchangeEntitlementsContainer showSupportCheckboxes />
          </section>
        </Fragment>
      )}
      <h4 className="dividing">Account Details</h4>
      <Form.Group widths="equal">
        <Field
          name="accountDesignation"
          component={renderDropdown}
          options={[
            { key: 'C', value: 'C', text: 'C' },
            { key: 'F', value: 'F', text: 'F' },
          ]}
          label="Account Designation"
        />
        {accountType === CLEARING && (
          <Field
            name="clearingMemberType"
            component={renderDropdown}
            label="Clearing Member Type"
            options={Object.values(clearingMemberTypes).map((designation) => ({
              key: designation.code,
              text: designation.name,
              value: designation.code,
              description: designation.code,
            }))}
          />
        )}
        <Field
          name="subAccountType"
          component={renderField}
          label="Sub Account Type"
        />
        {/* hiding master accounts as part of CO-2811 */}
        {/* { accountType === CLEARING &&
        <Field
          name="parent"
          id="parent"
          component={AccountSelectionContainer}
          accountType={CLEARING}
          clearable
          label="Master Account"
          placeholder="Select account..."
          additionalFilters={[{ attr: 'account_type', op: 'eq', value: CLEARING }]}
          disabled={update}
        />
      } */}
        {accountType === 'finance' && (
          <Field
            name="generalLedgerAccountNumber"
            component={renderField}
            label="General Ledger Account Number"
          />
        )}
      </Form.Group>
      <Form.Group>
        {accountType === CLEARING && defaultLedgerIsActive && (
          <Field
            name="brokerAccountId"
            id="brokerAccountId"
            component={AccountSelectionContainer}
            label="Broker Account"
            accountType={BROKER}
            clearable
            disabled={update}
            placeholder="Select account..."
            additionalFilters={[
              { attr: 'account_type', op: 'eq', value: BROKER },
              { attr: 'ledger_id', op: 'eq', value: ledgerIds },
            ]}
          />
        )}
        <Field
          name="financeAccountDesignation"
          component={renderField}
          label="Finance Account Designation"
        />
        <Field
          name="assetClassDesignation"
          component={renderField}
          label="Asset Class Designation"
        />
        <Field
          name="omnibusTypeDesignation"
          component={renderField}
          label="Omnibus Type Designation"
        />
      </Form.Group>
      <Form.Group>
        <Field
          name="fundsDesignation"
          component={renderDropdown}
          label={"Funds Designation"}
          options={fundsDesignation.map((designation) => ({
            key: designation.value,
            text: designation.text,
            value: designation.value,
            description: designation.value,
          }))}
        />
        <Field
          name="feesDesignation"
          component={renderField}
          label="Fees Designation"
        />
        <Field
          name="type"
          component={renderDropdown}
          label="Account Type"
          options={Object.values(clearingAccountTypes).map((designation) => ({
            key: designation.type,
            text: designation.name,
            value: designation.type,
            description: designation.type,
          }))}
        />
      </Form.Group>
      <Fragment>
        <section className="xwizard-form-group">
          <h4 className="dividing">EXCHANGE</h4>
          <Form.Group>
            <Field
              name="emarketAccountType"
              component={renderDropdown}
              label="TME Account Type"
              clearable
              options={emarketAccountTypesOptions}
            />
            <Field
              name="feeProfileId"
              component={renderDropdown}
              label="TME Fee Profile"
              options={feeProfilesOptions.map((option) => ({
                key: option.profileId,
                value: option.profileId,
                text: option.name,
              }))}
            />
            {allowFuturesMargin && (
              <>
                <Field
                  name="marginProfileId"
                  component={renderDropdown}
                  label="TME Margin Profile"
                  options={marginProfilesOptions.map((option) => ({
                    key: option.profileId,
                    value: option.profileId,
                    text: option.name,
                  }))}
                />
                <Field
                  name="riskProfileId"
                  component={renderDropdown}
                  label="TME Risk Profile"
                  options={riskProfilesOptions.map((option) => ({
                    key: option.profileId,
                    value: option.profileId,
                    text: option.name,
                  }))}
                />
              </>
            )}
          </Form.Group>
        </section>
      </Fragment>
      {accountType === CLEARING && (
        <Fragment>
          <section className="xwizard-form-group">
            <h4 className="dividing">Futures</h4>
            <Form.Group>
              <Field
                name="cti"
                component={renderDropdown}
                label="CTI"
                clearable
                options={cti.map((option) => ({
                  key: option.value,
                  value: option.value,
                  text: `${option.name} (${option.value})`,
                }))}
              />
              <Field
                name="origin"
                component={renderDropdown}
                label="ORIGIN"
                clearable
                options={origin.map((option) => ({
                  key: option.value,
                  value: option.value,
                  text: `${option.name} (${option.value})`,
                }))}
              />
              <span
                style={{
                  display: `${
                    clearingAccountType ===
                    clearingAccountTypes.SPOT_DELIVERY.type
                      ? 'flex'
                      : 'none'
                  }`,
                  marginLeft: '10px',
                }}
              >
                <Field
                  name="fcmFirmCode"
                  component={renderDropdown}
                  label="FCM Firm Code "
                  clearable
                  options={FCMFirmCodes?.map((option) => ({
                    key: option.firmCode,
                    value: option.firmCode,
                    text: option.name,
                  }))}
                />
              </span>
              <span
                style={{
                  display: `${clearingAccountType === 'spot_delivery' ? 'flex' : 'none'}`,
                  marginLeft: '15px',
                }}
              >
                <Field
                  name="cgmRefId"
                  component={renderField}
                  label="CGM Ref Id"
                  normalize={noSpaces}
                />
              </span>
            </Form.Group>
          </section>
          {/* TODO: change this so that it shows up when creating an account too */}
          {update && (
            <section className="xwizard-form-group">
              <h4 className="dividing">E-Market</h4>
              <Field
                component={renderField}
                name="emarketAccountNumber"
                // readOnly={selectedAccount.emarketAccountNumber}
                action={
                  <Button
                    content="Create & Link New E-Market Account"
                    onClick={() =>
                      createEmarketAccountModal({
                        accountId: selectedAccount.accountId,
                        memberId: selectedAccount.memberId,
                        emarketFeeProfileId: selectedAccount.feeProfileId,
                      })
                    }
                    disabled={selectedAccount.emarketAccountNumber}
                    id="create_cx_account"
                  />
                }
              />
            </section>
          )}
        </Fragment>
      )}

      <section className="xwizard-form-group">
        <h4 className="dividing">Net Settlement</h4>
        <Field
          name="netSettled"
          component={renderCheckbox}
          label="Net Settled"
        />
      </section>
      <Field name="notes" component={renderField} label="Notes" />
      {update && (
        <Button positive onClick={saveChanges}>
          Save
        </Button>
      )}
    </Fragment>
  );
};

CreateClearingAccount.propTypes = {
  emarketAccountTypesOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ),
  accountType: PropTypes.string,
  clearingAccountType: PropTypes.string,
  fetchFCMFirmCodes: PropTypes.func,
  FCMFirmCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  defaultLedgerIsActive: PropTypes.bool,
  ledgerIds: PropTypes.arrayOf(PropTypes.string),
  selectedAccount: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  initialize: PropTypes.func,
  createEmarketAccount: PropTypes.func,
  emarketFeeProfileOptions: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.any),
  ),
  saveChanges: PropTypes.func,
  initialValues: PropTypes.objectOf(PropTypes.any),
  riskProfilesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  marginProfilesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  feeProfilesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  allowAuthorizedJurisdictions: PropTypes.bool,
};

CreateClearingAccount.defaultProps = {
  emarketAccountTypesOptions: [],
  accountType: CLEARING,
  clearingAccountType: '',
  fetchFCMFirmCodes: () => {},
  FCMFirmCodes: [],
  defaultLedgerIsActive: false,
  ledgerIds: [],
  selectedAccount: {},
  update: false,
  showModal: () => {},
  hideModal: () => {},
  initialize: () => {},
  createEmarketAccount: () => {},
  emarketFeeProfileOptions: [],
  saveChanges: () => {},
  initialValues: {},
  riskProfilesOptions: [],
  marginProfilesOptions: [],
  feeProfilesOptions: [],
  allowAuthorizedJurisdictions: false,
};

const selector = formValueSelector('create_account');

const mapStateToProps = (state) => ({
  FCMFirmCodes: getFCMFirmCodes(state),
  clearingAccountType: selector(state, 'type'),
  allowAuthorizedJurisdictions: selector(state, 'allowAuthorizedJurisdictions'),
  allowFuturesMargin: selector(state, 'allowFuturesMargin'),
});

const CreateClearingAccountForm = connect(mapStateToProps, {
  showModal,
  hideModal,
  createEmarketAccount,
  fetchFCMFirmCodes,
})(CreateClearingAccount);

export default reduxForm({
  form: 'create_account',
})(CreateClearingAccountForm);
