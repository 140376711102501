import { call, put, takeLatest } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import api, * as urls from '../api';
import {
  storeTxAuthKey,
  generateCollateralHashId,
  GENERATE_COLLATERAL_HASH_ID,
  generateHashId,
  removeTxAuthHoldDelay,
  removeTxAuthMemberHoldDelay,
  REMOVE_TX_AUTH_MEMBER_HOLD_DELAY,
} from '../reducers/authReducer';
import { elevateTxAdmin, resetTxPassword } from '../reducers/usersReducer';
import { hideModal } from 'erisxkit/client';
import { createSaga } from '../utils/createSaga';

function* storeTxAuthKeySaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Storing Tx Auth Key for Admin...',
    { hideAfter: 0 },
  );
  try {
    const user = yield call(
      api.post,
      urls.STORE_TX_AUTH_KEY_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    cogoToast.success('Tx Auth Key stored!');
    yield put(storeTxAuthKey.success(user));
  } catch (e) {
    loadingCallback();
    yield put(storeTxAuthKey.failure(e.response));
  }
}

function* elevateTxAdminSaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Elevating Admin to approved status...',
    { hideAfter: 0 },
  );
  try {
    const user = yield call(
      api.post,
      urls.ELEVATE_TX_ADMIN_KEY_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    cogoToast.success('Admin elevated!');
    yield put(storeTxAuthKey.success(user));
  } catch (e) {
    loadingCallback();
    yield put(storeTxAuthKey.failure(e.response));
  }
}

function* generateHashIdSaga({ payload }) {
  try {
    const hashId = yield call(
      api.post,
      urls.GENERATE_HASH_ID_API_ENDPOINT,
      payload,
    );
    yield put(generateHashId.success(hashId));
  } catch (e) {
    yield put(generateHashId.failure(e.response));
  }
}

function* resetTxPasswordSaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Attempting to reset user password...',
    { hideAfter: 0 },
  );
  try {
    yield call(api.post, urls.RESET_TX_PASSWORD_KEY_API_ENDPOINT, payload);
    loadingCallback();
    cogoToast.success('User password reset complete!');
    yield put(resetTxPassword.success());
  } catch (e) {
    loadingCallback();
    yield put(resetTxPassword.failure(e.response));
  }
}

function* removeTxAuthHoldDelaySaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Attempting to remove tx auth hold delay...',
    { hideAfter: 0 },
  );
  try {
    yield call(api.post, urls.REMOVE_TX_AUTH_HOLD_DELAY_API_ENDPOINT, payload);
    loadingCallback();
    cogoToast.success(
      'Hold delay removed!  This linked account should now be ready to use.',
    );
    yield put(hideModal());
    yield put(hideModal());
    yield put(removeTxAuthHoldDelay.success());
  } catch (e) {
    loadingCallback();
    yield put(hideModal());
    yield put(removeTxAuthHoldDelay.failure(e.response));
  }
}

export default function* watchFeeRebates() {
  yield takeLatest(storeTxAuthKey.TRIGGER, storeTxAuthKeySaga);
  yield takeLatest(elevateTxAdmin.TRIGGER, elevateTxAdminSaga);
  yield takeLatest(generateHashId.TRIGGER, generateHashIdSaga);
  yield takeLatest(
    generateCollateralHashId.TRIGGER,
    createSaga(generateCollateralHashId, GENERATE_COLLATERAL_HASH_ID),
  );
  yield takeLatest(resetTxPassword.TRIGGER, resetTxPasswordSaga);
  yield takeLatest(removeTxAuthHoldDelay.TRIGGER, removeTxAuthHoldDelaySaga);
  yield takeLatest(
    removeTxAuthMemberHoldDelay.TRIGGER,
    createSaga(
      removeTxAuthMemberHoldDelay,
      REMOVE_TX_AUTH_MEMBER_HOLD_DELAY,
      'Attempting to remove tx auth hold delay...',
      'Hold delay removed!  This linked account should now be ready to use.',
    ),
  );
}
