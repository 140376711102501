import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createLoadingSelector,
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import BackButton from '../../common/components/BackButton';
import XTable7 from '../../common/table/XTable7';
import riskDetailMetadata from '../../metadata/riskDetailMetadata';
import {
  formatDateField,
  NANO_TO_MILLI_FACTOR,
} from '../../metadata/riskOverviewMetadata';
import {
  fetchRiskDetail,
  getRiskDetail,
  RISK_DETAIL,
  getRiskAccount,
  getRiskSnapshotId,
  fetchRisk,
  getRiskSummary,
} from '../../reducers/riskReducer';

const formatterTime = (timeToFormat) => {
  const time = timeToFormat.toString();
  const mils = parseInt(time.substring(0, time.length - NANO_TO_MILLI_FACTOR));
  return formatDateField(mils);
};

export default () => {
  const dispatch = useDispatch();
  const riskDetails = useSelector(getRiskDetail);
  const riskDetailsLoading = useSelector(createLoadingSelector([RISK_DETAIL]));
  const selectedAccount = getRiskAccount();
  const snapshotId = getRiskSnapshotId();
  const riskData = useSelector(getRiskSummary);

  let filter = [
    {
      attr: 'account_number',
      op: 'eq',
      value: selectedAccount,
    },
    {
      attr: 'snapshot_id',
      op: 'eq',
      value: snapshotId,
    },
  ];

  // Hago un get del accountID
  const fetchRiskData = React.useCallback(() => {
    dispatch(fetchRisk({ filter }));
  }, []);

  React.useEffect(() => {
    fetchRiskData();
  }, []);

  const fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    let sort = [];
    const op = DEFAULT_FILTER_OP;

    if (filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrValue(filters.map((each) => ({ ...each, op }))),
      );
    }
    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }
    dispatch(
      fetchRiskDetail({
        limit: pageSize,
        page: pageIndex,
        offset: pageSize * pageIndex,
        filter,
        sort,
      }),
    );
  };
  return (
    <div>
      <BackButton
        header={riskData && riskData.length > 0 && riskData[0].tcsAccountLabel}
        subheader={`Snapshot ${snapshotId} was taken at ${
          riskData &&
          riskData.length > 0 &&
          formatterTime(riskData[0].transactionTime)
        }`}
      />
      <XTable7
        title="risk-detail"
        metadata={riskDetailMetadata}
        data={riskDetails}
        fetchData={fetchData}
        loading={riskDetailsLoading}
        height="63vh"
      />
    </div>
  );
};
