import Big from 'bignumber.js';

const REQUIRED_FIELD = 'This field is required.';

export const maxValue = (max) => (value) =>
  value && Big(value).gt(Big(max)) ? `Value cannot exceed ${max}` : undefined;

export const minValue = (min) => (value) =>
  value && Big(value).lt(Big(min))
    ? `Value is below minimum ${min}`
    : undefined;

export const required = (value) => (value ? undefined : REQUIRED_FIELD);

export const alphanumeric = (value) => {
  return value && !/^[a-zA-Z0-9]+$/i.test(value)
    ? 'Must be alphanumeric.'
    : undefined;
};
export const carCgmName = (value) => {
  return value && !/^[a-zA-Z0-9]{1,12}$/i.test(value)
    ? 'Must be alphanumeric with 12 characters max. Spaces not supported.'
    : undefined;
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
