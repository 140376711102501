import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { renderField, rules } from 'erisxkit/client';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import AssetTypeSelectionContainer from '../common/containers/AssetTypeSelectionContainer';

const CreateSubExchange = ({ clearingFeePercentage }) => (
  <Fragment>
    <Form.Group widths="1">
      <Field
        name="name"
        id="name"
        component={renderField}
        label="SubExchange Code"
        validate={[rules.required]}
      />
    </Form.Group>
    <Form.Group widths="4">
      <Field
        name="clearingFeePercentage"
        id="clearingFeePercentage"
        component={renderField}
        label="Clearing Fee Split %"
        validate={[rules.required]}
        required
      />
      <Field
        component={renderField}
        label="Exchange Fee Split %"
        input={{
          value: (clearingFeePercentage && 100 - clearingFeePercentage) || '',
        }}
        disabled
      />
      <Field
        name="deliveryFee"
        id="deliveryFee"
        component={renderField}
        label="Delivery Fee"
        validate={[rules.required]}
        required
      />
      <Field
        name="deliveryFeeType"
        id="deliveryFeeType"
        placeholder="Asset Type"
        component={AssetTypeSelectionContainer}
        label="Delivery Fee Currency"
        validate={[rules.required]}
        required
      />
    </Form.Group>
    <Field
      name="description"
      id="description"
      component={renderField}
      label="Description"
      validate={[rules.required]}
      required
    />
  </Fragment>
);

CreateSubExchange.propTypes = {
  clearingFeePercentage: PropTypes.string,
  valid: PropTypes.bool,
  cancel: PropTypes.func,
  create: PropTypes.func,
};

CreateSubExchange.defaultProps = {
  clearingFeePercentage: '',
  valid: PropTypes.bool,
  cancel: PropTypes.func,
  create: PropTypes.func,
};

export default CreateSubExchange;
