import React from 'react';
import NavLink from 'react-router-dom/NavLink';
import Moment from 'moment-timezone';
import { trim } from 'lodash';
import Big from 'bignumber.js';
import { format } from 'erisxkit/client';
import {
  DateRangeColumnFilter,
  SelectColumnFilter,
} from '../common/table/Filters';
import settlementTypes from '../constants/settlementTypes';
import booleanTypes from '../constants/booleanTypes';

const DEFAULT_TIME_ZONE = 'America/Chicago';
const DEFAULT_DATETIME_STRING_FORMAT = 'MMMM D, YYYY h:mm A z';
export const NANO_TO_MILLI_FACTOR = 6;

export const formatDateField = (date) =>
  new Moment.tz(Moment(date).format(), DEFAULT_TIME_ZONE).format(
    DEFAULT_DATETIME_STRING_FORMAT,
  );

export default [
  {
    Header: 'Transaction Time',
    id: 'transaction_time',
    accessor: ({ transactionTime }) => {
      const time = transactionTime?.toString();
      // times in backend are in nano seconds, we need to convert those to milliseconds
      const mils = parseInt(
        time?.substring(0, time.length - NANO_TO_MILLI_FACTOR),
      );

      return formatDateField(mils);
    },
    Filter: DateRangeColumnFilter,
    width: 200,
  },
  {
    Header: 'Member',
    id: 'member',
    accessor: 'member',
  },
  {
    Header: 'TCS Account',
    id: 'tcs_account_label',
    accessor: 'tcsAccountLabel',
    Cell: ({ row }) => (
      <NavLink
        target="_blank"
        className="text-right mono"
        to={`/balances/${trim(row?.original?.tcsAccountId || '')}`}
      >
        {row?.original?.tcsAccount}
      </NavLink>
    ),
  },
  {
    Header: 'TME Account',
    id: 'account_number',
    accessor: 'accountNumber',
    Cell: ({ row }) => (
      <NavLink
        target="_blank"
        className="text-right mono"
        to={`/risk/${row?.original?.snapshotId?.toString()}/${trim(
          row?.original?.tmeAccount || '',
        )}/detail`}
      >
        {row?.original?.tmeAccount}
      </NavLink>
    ),
  },
  {
    Header: 'Margin Exposure Limit',
    id: 'margin_exposure_limit',
    accessor: 'marginExposureLimit',
  },
  {
    Header: 'Total Balance',
    id: 'total_balance',
    accessor: ({ totalBalance }) => format(totalBalance),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Pay/Collect',
    id: 'payCollect',
    accessor: ({ variationMargin = 0, cdfFundingAmount = 0}) => {
      const varBig = Big(variationMargin);
      if (!cdfFundingAmount) {
        cdfFundingAmount = 0
      }
      const cdfBig = Big(cdfFundingAmount);
      const sum = varBig.plus(cdfBig)
      return sum.isNaN() ? "-" : format(sum.toString())
    },
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Span Margin',
    id: 'total_span_requirement',
    accessor: ({ totalSpanRequirement }) => format(totalSpanRequirement),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Span Spread',
    id: 'total_spread_requirement',
    accessor: ({ totalSpreadRequirement }) => format(totalSpreadRequirement),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Total Equity',
    id: 'total_equity',
    accessor: ({ totalEquity }) => format(totalEquity),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Margin Purchasing Power',
    id: 'margin_purchasing_power',
    accessor: ({ marginPurchasingPower }) => format(marginPurchasingPower),
    className: 'text-right mono',
    headerClassName: 'text-right',
    width: 200,
    filterOp: 'eq',
  },
  {
    Header: 'Margin Excess Deficit',
    id: 'margin_excess_deficit',
    accessor: ({ marginExcessDeficit }) => format(marginExcessDeficit),
    className: 'text-right mono',
    headerClassName: 'text-right',
    width: 200,
    filterOp: 'eq',
  },
  {
    Header: 'Long $ Exposure',
    id: 'long_exposure',
    accessor: ({ longExposure }) => format(longExposure),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Short $ Exposure',
    id: 'short_exposure',
    accessor: ({ shortExposure }) => format(shortExposure),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Net $ Exposure',
    id: 'net_exposure',
    accessor: ({ netExposure }) => format(netExposure),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Gross $ Exposure',
    id: 'gross_exposure',
    accessor: ({ grossExposure }) => format(grossExposure),
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Snapshot Type',
    id: 'snapshot_type',
    accessor: 'snapshotType',
    Filter: SelectColumnFilter,
    options: settlementTypes,
    filterOp: 'eq',
  },
  {
    Header: 'Snapshot ID',
    id: 'snapshot_id',
    accessor: 'snapshotId',
    className: 'text-right mono',
    headerClassName: 'text-right',
    filterOp: 'eq',
  },
  {
    Header: 'Inserted At',
    id: 'inserted_at',
    accessor: ({ insertedAt }) => formatDateField(insertedAt),
    width: 200,
  },
  {
    Header: 'Gross Stress Exposure',
    id: 'gross_stress_exposure',
    accessor: 'grossStressExposure',
  },
  {
    Header: 'Stress Scenario',
    id: 'stress_scenario',
    accessor: 'stressScenario',
  },
  {
    Header: 'Net Stress Exposure',
    id: 'net_stress_exposure',
    accessor: 'netStressExposure',
  },
  {
    Header: 'Stress Exposure Limit',
    id: 'stress_exposure_limit',
    accessor: 'stressExposureLimit',
  },
  {
    Header: 'Risk Profile',
    id: 'riskProfile',
    accessor: 'riskProfile',
  },
  {
    Header: 'Margin Profile',
    id: 'marginProfile',
    accessor: 'marginProfile',
  },
];
