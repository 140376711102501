import React from 'react';
import { get } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import Big from 'bignumber.js';
import { overflowMenu } from '../common/table/commonMetadata';

export default (showModal, modalType, modalProps) => [
  {
    Header: '',
    columns: [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => get(row, 'name', '-'),
        width: 200,
      },
      {
        Header: 'Clearing Fee Percentage Split',
        id: 'clearingFeePercentage',
        accessor: (row) => get(row, 'clearingFeePercentage'),
        width: 250,
      },
      {
        Header: 'Exchange Fee Percentage Split',
        id: 'exchangeFeePercentage',
        accessor: (row) =>
          Big(100)
            .minus(Big(get(row, 'clearingFeePercentage')))
            .toFixed(1),
        width: 250,
      },
      {
        Header: 'Delivery Fee',
        id: 'deliveryFee',
        accessor: (row) => get(row, 'deliveryFee'),
        width: 140,
      },
      {
        Header: 'Delivery Fee',
        id: 'deliveryFeeType',
        accessor: (row) => get(row, 'deliveryFeeType', '-'),
        width: 140,
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: (row) => get(row, 'description', '-'),
        width: 140,
      },
      overflowMenu({
        items: ({ original }) => [
          <Dropdown.Item
            id="edit-sub-exchange"
            icon="edit"
            onClick={() => showModal(modalType, { ...modalProps, ...original })}
            text="Edit Sub Exchange"
          />,
        ],
      }),
    ],
  },
];
