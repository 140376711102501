import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { renderField, rules } from 'erisxkit/client';

const maxLengthSixty = rules.maxLength(60);

const AddAdditionalSsi = ({ isAdd = true }) => (
  <Fragment>
    <h4 className="ui header">
      Intermediary Bank Details{' '}
      <span className="darkGrey">(if required by Beneficiary Bank)</span>
    </h4>
    <Field
      label="Intermediary Bank Account ID"
      component={renderField}
      placeholder="Enter bank account number..."
      type="text"
      name="intermediaryAccountNumber"
      disabled={isAdd ? '' : 'true'}
    />
    <Field
      label="Intermediary Bank Name"
      component={renderField}
      placeholder="Enter bank name..."
      type="text"
      name="intermediaryBankName"
      disabled={isAdd ? '' : 'true'}
    />
    <Field
      label="Intermediary Bank Address"
      component={renderField}
      placeholder="Enter bank account address..."
      type="text"
      name="intermediaryBankAddress"
      disabled={isAdd ? '' : 'true'}
    />
    <Field
      label="Intermediary Bank Rounting Number"
      component={renderField}
      placeholder="Enter routing number ..."
      type="text"
      name="intermediaryRoutingNumber"
      disabled={isAdd ? '' : 'true'}
    />
    <Field
      label="Other Bank Information (OBI)"
      component={renderField}
      placeholder="Optional, 60 character max."
      name="additionalSsi"
      type="text"
      fluid
      validate={maxLengthSixty}
    />
  </Fragment>
);

export default AddAdditionalSsi;
