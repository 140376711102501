import axios from 'axios';
import applyConverters from 'axios-case-converter';

// this was not used anywhere?
// import history from './constants/history';

export const STORE_TX_AUTH_KEY_API_ENDPOINT = '/api/store_tx_auth_key';

export const RECONCILE_API_ENDPOINT = '/api/reconcile';
export const RECONCILIATION_API_ENDPOINT = '/api/reconciliation';

export const RECONCILIATION_HISTORY_API_ENDPOINT =
  '/api/reconciliation_history';
export const UPDATE_RECONCILIATION_ENTRY_DETAILS_API_ENDPOINT =
  '/api/update_reconciliation_entry_details';

export const ACCOUNTS_API_ENDPOINT = '/api/accounts';
export const ACCOUNTS_V2_API_ENDPOINT = '/api/accounts_v2';
export const FCM_FIRM_CODES_API_ENDPOINT = '/api/fcm_firm_codes';
export const FUTURES_DCO_ACCOUNT_API_ENDPOINT = '/api/futures_dco_account';

export const UPDATE_ACCOUNT_API_ENDPOINT = '/api/update_account';
export const ACCOUNT_HISTORY_API_ENDPOINT = '/api/account_history';
export const REQUEST_HISTORY_API_ENDPOINT = '/api/request_history';
export const UPCOMING_DEPOSITS_API_ENDPOINT = '/api/upcoming_deposits';

export const CREATE_FINANCE_ACCOUNT_API_ENDPOINT =
  '/api/create_finance_account';
export const CREATE_CLEARING_ACCOUNT_API_ENDPOINT =
  '/api/create_clearing_account';
export const UNUSED_WALLETS_API_ENDPOINT = '/api/unused_wallets';

export const CREATE_WITHDRAWAL_API_ENDPOINT = '/api/create_withdrawal';
export const CREATE_DEPOSIT_API_ENDPOINT = '/api/create_deposit';
export const CREATE_TRANSFER_API_ENDPOINT = '/api/create_transfer';
export const CREATE_REVERSAL_API_ENDPOINT = '/api/create_reversal';
export const CREATE_MANUAL_EXTERNAL_TRANSACTION_API_ENDPOINT =
  '/api/create_manual_external_transaction';
export const CREATE_RECONCILIATION_API_ENDPOINT = '/api/create_reconciliation';
export const CREATE_FEE_REBATE_API_ENDPOINT = '/api/create_fee_rebate';
export const CREATE_GENERAL_JOURNAL_API_ENDPOINT =
  '/api/create_general_journal';
export const GENERAL_JOURNAL_CATEGORIES_API_ENDPOINT =
  '/api/general_journal_categories';
export const CREATE_TRADE_API_ENDPOINT = '/api/create_trade';
export const CREATE_EMARKET_BALANCE_ADJUSTMENT_API_ENDPOINT =
  '/api/create_emarket_balance_adjustment';
export const EMARKET_BALANCE_ADJUSTMENTS_API_ENDPOINT =
  '/api/emarket_balance_adjustments';
export const CREATE_DESIGNATION_TRANSFER_JOURNAL_API_ENDPOINT =
  '/api/create_designation_transfer_journal';

export const CREATE_CRYPTO_WITHDRAWAL_REQUEST_API_ENDPOINT =
  '/api/create_crypto_withdrawal_request';
export const CREATE_FIAT_WITHDRAWAL_REQUEST_API_ENDPOINT =
  '/api/create_fiat_withdrawal_request';
export const SCALE_BASE_TO_MIN_UNIT_API_ENDPOINT =
  '/api/scale_base_to_min_unit';

export const EMARKET_ACCOUNT_TYPES_API_ENDPOINT = '/api/emarket_account_types';
export const IRA_CUSTODIANS_API_ENDPOINT = '/api/ira_custodians';
export const IRA_ACCOUNT_TYPES_AND_CODES_API_ENDPOINT =
  '/api/ira_account_types_and_codes';

export const EMARKET_ACCOUNT_PROFILES_API_ENDPOINT =
  '/api/emarket_account_profiles';
export const EMARKET_FEE_PROFILES_API_ENDPOINT = '/api/emarket_fee_profiles';
export const FEE_PROFILES_API_ENDPOINT = '/api/fee_profiles';

export const EMARKET_INSTRUMENT_PERMISSION_PROFILES_API_ENDPOINT =
  '/api/emarket_instrument_permission_profiles';

export const CLOSING_PRICES_API_ENDPOINT = '/api/closing_prices';
export const UPDATE_CLOSING_PRICE_API_ENDPOINT = '/api/update_closing_price';
export const REPUBLISH_BATCH_API_ENDPOINT = '/api/republish_batch';
export const EMARKET_BALANCES_API_ENDPOINT = '/api/emarket_balances';
export const DESIGNATED_BALANCE_DETAILS_API_ENDPOINT =
  '/api/designated_balance_details';
export const BALANCES_API_ENDPOINT = '/api/balances';

export const MULTI_ACCOUNT_BALANCES_API_ENDPOINT =
  '/api/multi_account_balances';

export const ASSET_TYPES_API_ENDPOINT = '/api/asset_types';
export const CREATE_ASSET_TYPE_API_ENDPOINT = '/api/create_asset_type';

export const USERS_API_ENDPOINT = '/api/users';
export const CURRENT_USER_API_ENDPOINT = '/api/current_user';
export const CREATE_MEMBER_API_ENDPOINT = '/api/create_member';
export const CREATE_EMPLOYEE_API_ENDPOINT = '/api/create_employee';
export const ROUTE_SUBMITTING_ORDERS_TO_API_API_ENDPOINT =
  '/api/route_submitting_orders_to_api';
export const MEMBER_USER_TYPES_API_ENDPOINT = '/api/member_user_types';

export const MEMBERS_API_ENDPOINT = '/api/members';
export const CREATE_FIRM_MEMBER_API_ENDPOINT = '/api/create_firm_member';
export const CREATE_VENDOR_MEMBER_API_ENDPOINT = '/api/create_vendor_member';
export const CREATE_DIRECT_MEMBER_API_ENDPOINT =
  '/api/create_direct_member_admin';
export const CREATE_EXECUTION_BROKER_MEMBER_API_ENDPOINT =
  '/api/create_execution_broker_member';
export const CREATE_INVESTMENT_MANAGER_MEMBER_API_ENDPOINT =
  '/api/create_investment_manager_member';
export const CREATE_INTERMEDIARY_MEMBER_API_ENDPOINT =
  '/api/create_intermediary_member';
export const CREATE_FCM_MEMBER_API_ENDPOINT = '/api/create_fcm_member';
export const UPDATE_MEMBER_API_ENDPOINT = '/api/update_member';
export const ADD_MEMBER_TO_LEDGER_ACCOUNTS_API_ENDPOINT =
  '/api/add_member_to_ledger_accounts';
export const REMOVE_MEMBER_FROM_LEDGER_ACCOUNTS_API_ENDPOINT =
  '/api/remove_member_from_ledger_accounts';
export const ADD_MEMBER_TO_USERS_API_ENDPOINT = '/api/add_member_to_users';
export const REMOVE_MEMBER_FROM_USERS_API_ENDPOINT =
  '/api/remove_member_from_users';

export const ELEVATE_TX_ADMIN_KEY_API_ENDPOINT = '/api/elevate_tx_admin';
export const RESET_TX_PASSWORD_KEY_API_ENDPOINT = '/api/reset_tx_password';

export const MEMBER_COMPLIANCE_QUESTIONS_API_ENDPOINT =
  '/api/member_compliance_questions';
export const MEMBER_SECURITY_QUESTIONS_API_ENDPOINT =
  '/api/member_security_questions';
export const MEMBER_SUPPORTING_DOC_API_ENDPOINT = '/api/member_supporting_doc';
export const MEMBER_DOCUMENTS_API_ENDPOINT = '/api/user/:user_id/documents';
export const MEMBER_IMAGES_API_ENDPOINT = '/api/member_images';

export const UPDATE_USER_API_ENDPOINT = '/api/update_user';
export const ADD_CLEARING_ACCOUNT_API_ENDPOINT =
  '/api/add_clearing_account_to_member';
export const REMOVE_CLEARING_ACCOUNT_API_ENDPOINT =
  '/api/remove_clearing_account_from_member';

export const APPROVAL_REQS_API_ENDPOINT = '/api/approval_reqs';
export const APPROVAL_LOGS_API_ENDPOINT = '/api/approval_logs';
export const APPROVAL_REQ_PAYLOAD_API_ENDPOINT = '/api/approval_req_payload';

export const APPROVE_API_ENDPOINT = '/api/approve';
export const REJECT_API_ENDPOINT = '/api/reject';

export const SYSTEM_ALERTS_API_ENDPOINT = '/api/system_alerts';
export const SYSTEM_ALERT_INFO_API_ENDPOINT = '/api/system_alert_info';

export const TASKS_API_ENDPOINT = '/api/tasks';
export const TASK_LOG_API_ENDPOINT = '/api/task_log';
export const TASK_INFO_API_ENDPOINT = '/api/task_info';
export const TRIGGER_TASK_API_ENDPOINT = '/api/trigger_task';

export const EXCEPTIONS_API_ENDPOINT = '/api/exceptions';
export const EXCEPTION_INFO_API_ENDPOINT = '/api/exception_info';

export const CHANGE_LOGS_API_ENDPOINT = '/api/change_logs';

export const REJECTED_JOURNALS_API_ENDPOINT = '/api/rejected_journals';

export const ABOUT_API_ENDPOINT = '/api/about';

export const MEMBER_STATES_API_ENDPOINT = '/api/member_states';
export const EMPLOYEE_ROLES_API_ENDPOINT = '/api/employee_roles';
export const MEMBER_USER_PERMISSIONS_API_ENDPOINT =
  '/api/member_user_permissions';
export const TRADES_API_ENDPOINT = '/api/trades';
export const BLOCK_TRADE_REQUESTS_API_ENDPOINT = '/api/block_trade_requests';
export const CANCEL_BLOCK_TRADE_API_ENDPOINT = '/api/cancel_block_trade';
export const COMMIT_BLOCK_TRADE_API_ENDPOINT = '/api/commit_block_trade';

export const MOVEMENTS_API_ENDPOINT = '/api/movements';
export const TRADE_JOURNALS_API_ENDPOINT = '/api/trade_journals';

export const TRADE_CAPTURE_REPORTS_API_ENDPOINT = '/api/trade_capture_reports';
export const REPROCESS_TRADE_CAPTURE_REPORT_API_ENDPOINT =
  '/api/reprocess_trade_capture_report';
export const REQUEST_REPROCESS_TRADE_CAPTURE_REPORT_API_ENDPOINT =
  '/api/request_reprocess_trade_capture_report';
export const CLEAR_TRADE_CAPTURE_REPORT_API_ENDPOINT =
  '/api/clear_trade_capture_report';
export const UPDATE_TRADE_CAPTURE_REPORT_API_ENDPOINT = '/api/update_trade';

export const LINK_CLEARING_ACCOUNT_API_ENDPOINT = '/api/link_clearing_account';
export const CREATE_EMARKET_ACCOUNT_API_ENDPOINT =
  '/api/create_emarket_account';
export const CREATE_EMARKET_ORGANIZATION_API_ENDPOINT =
  '/api/create_emarket_organization';

export const CREATE_CX_BALANCES_API_ENDPOINT = '/api/create_cx_balances';
export const CREATE_TRADE_CX_USER_API_ENDPOINT = '/api/create_trade_cx_user';
export const ROUTE_CX_USER_THROUGH_CQG_API_ENDPOINT =
  '/api/route_cx_user_through_cqg';

export const CREATE_CQG_CUSTOMER_ENDPOINT = '/api/create_cqg_customer';
export const CREATE_CQG_TRADER_ENDPOINT = '/api/create_cqg_trader';
export const CREATE_CQG_ACCOUNT_ENDPOINT = '/api/create_cqg_account';
export const ADD_REQUIRED_CQG_SERVICES_TO_CQG_TRADER_ENDPOINT =
  '/api/add_required_cqg_services_to_cqg_trader';
export const LINK_CQG_TRADER_TO_CQG_CUSTOMER_ENDPOINT =
  '/api/link_cqg_trader_to_cqg_customer';
export const ADD_CQG_TRADER_TO_CQG_ACCOUNT_ENDPOINT =
  '/api/add_cqg_trader_to_cqg_account';
export const ADD_CX_TRADER_ID_TO_CQG_TRADER_ENDPOINT =
  '/api/add_cx_trader_id_to_cqg_trader';
export const SEND_CQG_WELCOME_EMAIL_ENDPOINT = '/api/send_cqg_welcome_email';

export const SEND_EMAIL_ENDPOINT = '/api/send_email';

export const EXPORT_CORE_FILE_API_ENDPOINT = '/api/export_core_file';
export const CORE_FILE_API_ENDPOINT = '/api/core_file';

export const LINKED_BANK_ACCOUNTS_API_ENDPOINT = '/api/linked_bank_accounts';
export const CREATE_LINKED_BANK_ACCOUNT_ADMIN_API_ENDPOINT =
  '/api/create_linked_bank_account_admin';

export const LINKED_CRYPTO_ADDRESSES_API_ENDPOINT =
  '/api/linked_crypto_addresses';
export const CREATE_LINKED_CRYPTO_ADDRESS_API_ENDPOINT =
  '/api/create_linked_crypto_address';

export const LINKED_MEMBER_ASSET_ACCOUNTS_API_ENDPOINT =
  '/api/linked_member_asset_accounts';
export const CREATE_LINKED_MEMBER_BANK_ACCOUNT_ADMIN_API_ENDPOINT =
  '/api/create_linked_member_bank_account_admin';
export const UPDATE_LINKED_MEMBER_ASSET_ACCOUNT_ADMIN_API_ENDPOINT =
  '/api/update_linked_member_asset_account_admin';
export const CREATE_LINKED_MEMBER_CRYPTO_ADDRESS_API_ENDPOINT =
  '/api/create_linked_member_crypto_address';
export const DELETE_LINKED_MEMBER_ASSET_ACCOUNT_API_ENDPOINT =
  '/api/delete_linked_member_asset_account';
export const CREATE_LINKED_MEMBER_COLLATERAL_ACCOUNT_API_ENDPOINT =
  '/api/create_linked_member_collateral_account';

export const GENERATE_COLLATERAL_HASH_ID_API_ENDPOINT =
  '/api/generate_collateral_hash_id';
export const GENERATE_HASH_ID_API_ENDPOINT = '/api/generate_hash_id';

export const REMOVE_TX_AUTH_HOLD_DELAY_API_ENDPOINT =
  '/api/remove_tx_auth_hold_delay';
export const REMOVE_TX_AUTH_MEMBER_HOLD_DELAY_API_ENDPOINT =
  '/api/remove_tx_auth_member_hold_delay';

export const CRYPTO_ADDRESS_ANALYSES_API_ENDPOINT =
  '/api/crypto_address_analyses';
export const CRYPTO_TRANSACTION_ANALYSES_API_ENDPOINT =
  '/api/crypto_transaction_analyses';
export const SURVEILLANCE_ENTRIES_API_ENDPOINT = 'api/surveillance_entries';

export const UPDATE_CONTRACT_API_ENDPOINT = '/api/update_contract';
export const UPDATE_PRODUCT_API_ENDPOINT = '/api/update_product';

export const SPOT_PRODUCTS_API_ENDPOINT = '/api/spot_products';
export const CREATE_SPOT_PRODUCT_API_ENDPOINT = '/api/create_spot_product';

export const CREATE_FUTURES_CONTRACT_API_ENDPOINT =
  '/api/create_futures_contract';
export const CREATE_FUTURES_PRODUCT_API_ENDPOINT =
  '/api/create_futures_product';
export const FUTURES_CONTRACTS_API_ENDPOINT = '/api/futures_contracts';
export const FUTURES_PRODUCTS_API_ENDPOINT = '/api/futures_products';

export const COLLATERAL_API_ENDPOINT = '/api/collateral_contracts';
export const COLLATERAL_PRODUCTS_API_ENDPOINT = '/api/collateral_products';
export const CREATE_COLLATERAL_API_ENDPOINT = '/api/create_collateral_contract';
export const CREATE_COLLATERAL_PRODUCT_API_ENDPOINT =
  '/api/create_collateral_product';
export const CREATE_COLLATERAL_DEPOSIT_JOURNAL_API_ENDPOINT =
  '/api/create_collateral_deposit_journal';
export const CREATE_COLLATERAL_WITHDRAWAL_JOURNAL_API_ENDPOINT =
  '/api/create_collateral_withdrawal_journal';

export const CONTRACT_SYMBOLS_API_ENDPOINT = '/api/contracts';

export const POSITIONS_API_ENDPOINT = '/api/positions';
export const POSITIONS_V2_API_ENDPOINT = '/api/positions_v2';
export const POSITIONS_AGGREGATED_API_ENDPOINT = '/api/positions_aggregated';
export const CREATE_CLOSEOUT_INSTRUCTION_API_ENDPOINT =
  '/api/create_closeout_instruction';
export const CLOSEOUT_JOURNALS_API_ENDPOINT = '/api/closeout_journals';
export const POSITION_SUMMARY_API_ENDPOINT = '/api/position_summary';

export const FUNDS_SEGREGATION_BALANCES_API_ENDPOINT =
  '/api/funds_segregation_balances';

export const CREATE_LEDGER_API_ENDPOINT = '/api/create_ledger';
export const LEDGERS_API_ENDPOINT = '/api/ledgers';

export const API_CREDENTIALS_PERMISSIONS_API_ENDPOINT =
  '/api/api_credentials_permissions';

export const ADD_ULTIMATE_BENEFICIAL_OWNER_API_ENDPOINT =
  '/api/add_ultimate_beneficial_owner';
export const REMOVE_ULTIMATE_BENEFICIAL_OWNER_API_ENDPOINT =
  '/api/remove_ultimate_beneficial_owner';
export const UPDATE_ULTIMATE_BENEFICIAL_OWNER_API_ENDPOINT =
  '/api/update_ultimate_beneficial_owner';

export const SUB_EXCHANGES_API_ENDPOINT = '/api/sub_exchanges';
export const CREATE_SUB_EXCHANGE_API_ENDPOINT = '/api/create_sub_exchange';
export const UPDATE_SUB_EXCHANGE_API_ENDPOINT = '/api/update_sub_exchange';

export const ACH_DEFAULTS_API_ENDPOINT = '/api/ach_defaults';
export const UPDATE_ACH_DEFAULTS_API_ENDPOINT = '/api/update_ach_defaults';

export const JURISDICTIONS_API_ENDPOINT = '/api/get_jurisdictions';
export const UPDATE_JURISDICTIONS_API_ENDPOINT = '/api/apply_jurisdictions';
export const GET_COUNTRIES_FOR_JURISDICTIONS_API_ENDPOINT =
  '/api/get_countries_for_jurisdictions';

export const ACCOUNT_OVERRIDES_API_ENDPOINT = '/api/ach_override';
export const UPDATE_ACCOUNT_OVERRIDES_API_ENDPOINT = '/api/update_ach_override';

export const WITHDRAWAL_LIMIT_API_ENDPOINT = '/api/withdrawal_limit';
export const DEPOSIT_LIMIT_API_ENDPOINT = '/api/deposit_limit';
export const PII_API_ENDPOINT = '/api/member_pii';
export const MEMBER_USER_NOTES_API_ENDPOINT = '/api/member_user_notes';
export const ADD_NOTE_TO_MEMBER_USER_API_ENDPOINT =
  '/api/add_note_to_member_user';
export const EDIT_NOTE_ON_MEMBER_USER_API_ENDPOINT =
  '/api/edit_note_on_member_user';

export const RISK_ACCOUNT_SUMMARY_API_ENDPOINT = '/api/v1/risk_account_summary';
export const RISK_DETAIL_API_ENDPOINT = '/api/v1/risk_detail';
export const MARGIN_CALL_ALERTS_API_ENDPOINT = '/api/v1/margin_call_alerts';

export const RISK_ALERTS_API_ENDPOINT = '/api/v1/risk_alerts';
export const RISK_ALERT_COMMENTS_API_ENDPOINT = '/api/v1/risk_alert_comments';
export const RISK_ALERT_STATUSES_API_ENDPOINT = '/api/v1/risk_alert_statuses';
export const ADD_RISK_ALERT_COMMENT_API_ENDPOINT =
  '/api/v1/add_risk_alert_comment';
export const UPDATE_RISK_ALERT_STATUS_API_ENDPOINT =
  '/api/v1/update_risk_alert_status';
export const RISK_CONFIGURATIONS_API_ENDPOINT = '/api/v1/risk_configurations';
export const CREATE_RISK_CONFIGURATION_API_ENDPOINT =
  '/api/v1/create_risk_configuration';
export const UPDATE_RISK_CONFIGURATION_API_ENDPOINT =
  '/api/v1/update_risk_configuration';
export const DELETE_RISK_CONFIGURATION_API_ENDPOINT =
  '/api/v1/delete_risk_configuration';

export const UPDATE_MARGIN_REQUIREMENT_STATUS_API_ENDPOINT =
  '/api/v1/update_margin_requirement_status';
export const FEES_ENDPOINT = '/api/v1/fees';
export const GENERATE_REFERRAL_CODE_API_ENDPOINT =
  '/api/generate_referral_code';
export const SET_REFERRED_BY_CODE_API_ENDPOINT = '/api/set_referred_by_code';
export const PROMOTION_REFERRALS_API_ENDPOINT = '/api/promotion_referrals';
export const MARGIN_PROFILES_API_ENDPOINT = '/api/margin_profiles';
export const RISK_PROFILES_API_ENDPOINT = '/api/risk_profiles';
export const MEMBER_TRANSFER_FINAL_APPROVALS_API_ENDPOINT =
  '/api/member_transfer_final_approvals';
export const APPROVE_ALL_API_ENDPOINT = '/api/approve_all';
export const REJECT_ALL_API_ENDPOINT = '/api/reject_all';
export const RESET_MF_API_ENDPOINT = '/api/reset_mf';

export const SETTLEMENT_INSTRUCTIONS_API_ENDPOINT =
  '/api/settlement_instructions';
export const REJECT_SETTLEMENT_API_ENDPOINT = '/api/reject_settlement';
export const CONFIRM_SETTLEMENT_API_ENDPOINT = '/api/confirm_settlement';

export const BASE_MARGIN_RATES_API_ENDPOINT = '/api/base_margin_rates';
export const MARGIN_RATES_MULTIPLIERS_API_ENDPOINT =
  '/api/margin_rates_multipliers';
export const APPLIED_MARGIN_RATES_API_ENDPOINT = '/api/applied_margin_rates';

export const CREATE_APPLIED_MARGIN_RATES_API_ENDPOINT =
  '/api/create_applied_margin_rates';

export const ACCEPT_MARGIN_RATES_API_ENDPOINT = '/api/accept_margin_rates';
export const REJECT_MARGIN_RATES_API_ENDPOINT = '/api/reject_margin_rates';

export const AUTHORIZED_IPS_API_ENDPOINT = '/api/authorized_ips';
export const IP_AUTHORIZATION_HISTORY_API_ENDPOINT =
  '/api/ip_authorization_history';

export const ADD_AUTHORIZED_IP_API_ENDPOINT = '/api/add_authorized_ip_admin';
export const REMOVE_AUTHORIZED_IP_API_ENDPOINT =
  '/api/remove_authorized_ip_admin';
export const FCM_FILES_API_ENDPOINT = '/api/fcm_files';
export const FCM_MARGIN_CALL_CALCULATIONS_API_ENDPOINT =
  '/api/fcm_margin_call_calculations';
export const FCM_MARGIN_CALL_REQUIREMENTS_API_ENDPOINT =
  '/api/fcm_margin_call_requirements';
export const SET_MARGIN_REQUIREMENTS_API_ENDPOINT =
  '/api/set_margin_requirements';

export const DELETE_FIAT_DEPOSIT_SCHEDULE_API_ENDPOINT =
  '/api/delete_fiat_deposit_schedule';

export const CGM_LIST_BY_ACCOUNT_BUY_API_ENDPOINT = '/api/cgm_list_by_account';
export const CGM_LIST_BY_ACCOUNT_SELL_API_ENDPOINT = '/api/cgm_list_by_account';
export const POSITION_TRANSFER_TYPES_API_ENDPOINT =
  '/api/position_transfer_types';
export const POSITION_TRANSFERS_API_ENDPOINT =
  '/api/current_account_positions_for_ptr';
export const CREATE_POSITION_TRANSFERS_REQUEST_API_ENDPOINT =
  '/api/create_position_transfer_request';

export const MARGIN_DELIVERY_API_ENDPOINT = '/api/margin_delivery';
export const TRAVEL_RULE_TRANSACTIONS_API_ENDPOINT =
  '/api/travel_rule_transactions';
export const SET_TRUST_PII_API_ENDPOINT = '/api/set_trust_pii';

export const SET_TRUST_THRESHOLD_API_ENDPOINT = '/api/set_trust_threshold';
export const TRUST_THRESHOLD_SETTINGS_API_ENDPOINT =
  '/api/trust_threshold_settings';

export const GET_TRADING_PARTICIPANTS_API_ENDPOINT = '/api/v2/participants';
export const GET_ACCOUNT_TRADING_PARTICIPANTS_API_ENDPOINT =
  '/api/v2/:account_label/participants';

export const DELETE_TRADING_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/delete_trading_participant_links';
// CAR
export const GET_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_LIST_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference';
export const CREATE_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference';
export const UPDATE_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const DELETE_CUSTOMER_ACCOUNT_REFERENCE_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const LINK_CAR_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference';
export const UNLINK_CAR_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/customer_account_reference/:customer_account_reference/unlink_participant';
// CGM
export const GET_CGM_LIST_API_ENDPOINT = '/api/v2/:account_label/cgm';
export const CREATE_CGM_API_ENDPOINT = '/api/v2/:account_label/cgm';
export const UPDATE_CGM_API_ENDPOINT =
  '/api/v2/accounts/:account_id/cgm/:cgm_id';
export const DELETE_CGM_API_ENDPOINT = '/api/v2/:account_label/cgm/:cgm_number';
export const LINK_CGM_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/cgm/:cgm_number';
export const UNLINK_CGM_TO_PARTICIPANT_API_ENDPOINT =
  '/api/v2/:account_label/cgm/:cgm_number/unlink_participant';

// FCM Clearing
export const FCM_CLEARING_API_ENDPOINT = '/api/v2/:firm_code/fcm_clearing';
export const FCM_CLEARING_ALL_FCM_MEMBERS_API_ENDPOINT = '/api/v2/fcm/all';
export const CREATE_FCM_CLEARING_API_ENDPOINT =
  '/api/v2/:firm_code/fcm_clearing';
export const REJECT_FCM_CLEARING_API_ENDPOINT =
  '/api/v2/:firm_code/fcm_clearing/reject/:request_id';
export const APPROVE_CLEARING_REQUEST_API_ENDPOINT =
  '/api/v2/:firm_code/fcm_clearing/approve/:request_id';

export const ENABLE_BLOCK_TRADES_API_ENDPOINT =
  '/api/v2/enable_block_trades/participant';
export const DISABLE_BLOCK_TRADES_API_ENDPOINT =
  '/api/v2/disable_block_trades/participant';
export const GET_AUTHORIZED_BLOCK_TRADERS_API_ENDPOINT =
  '/api/v2/:firm_code/authorized_block_traders';
export const ADD_AUTHORIZED_BLOCK_TRADER_API_ENDPOINT =
  '/api/v2/:firm_code/authorized_block_trader/:authorized_trader_email';
export const REMOVE_AUTHORIZED_BLOCK_TRADER_API_ENDPOINT =
  '/api/v2/:firm_code/authorized_block_trader/:removed_trader_email';

export const GET_BLOCK_TRADE_REQUESTS_API_ENDPOINT =
  '/api/v1/block_trade_requests';
export const GET_BLOCK_TRADE_REQUESTS_V2_API_ENDPOINT =
  '/api/v2/block_trade_requests';

export const APPROVE_BLOCK_TRADE_API_ENDPOINT =
  '/api/v2/approve_block_trade/:request_id';
export const REJECT_BLOCK_TRADE_API_ENDPOINT =
  '/api/v2/reject_block_trade/:request_id';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// create instance with default options.
// applyConverters will:
// Converts outgoing data params object keys into snake_case
// Converts incoming data object keys into camelCase
// Converts outgoing headers object keys into Header-Case
// Converts incoming headers object keys into camelCase
const api_server_instance = applyConverters(axios.create(defaultOptions));
api_server_instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const api_server_api = {
  post: (url, payload) =>
    api_server_instance
      .post(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      }),
  get: (url) =>
    api_server_instance
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      }),
};

// create instance with default options.
// applyConverters will:
// Converts outgoing data params object keys into snake_case
// Converts incoming data object keys into camelCase
// Converts outgoing headers object keys into Header-Case
// Converts incoming headers object keys into camelCase
const instance = applyConverters(axios.create(defaultOptions));
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // cannot use auth.logout() here as api is a dependency of Auth itself.
      // directly calling the implementation of auth.logout();
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      history.replace('/');
    }
    return Promise.reject(error);
  },
);

const api = {
  post: (url, payload) =>
    instance
      .post(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      }),
  get: (url) =>
    instance
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      }),
  put: (url, payload) =>
    instance
      .put(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      }),
  delete: (url, payload) =>
    instance
      .delete(url, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      }),
};

export default api;
