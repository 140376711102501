import { ColDef } from "ag-grid-community";

const SystemApplicationMetadataColDef: ColDef[] = [
  {
    field: 'name',
    headerName: 'Application Name',
  },
  {
    field: 'version',
    headerName: 'Version',
  },
];

export default SystemApplicationMetadataColDef;
