import _ from 'lodash';
import React from 'react';
import { List } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';
import { userColumn } from '../common/table/commonMetadata';
import { formatMoneyDescriptionRows } from '../utils/methods';

const columns = (subject) => [
  {
    id: 'state',
    Header: 'State',
    filterable: false,
    accessor: (d) =>
      d.state && d.state.charAt(0).toUpperCase() + d.state.slice(1),
    maxWidth: 100,
    minWidth: 50,
  },
  {
    id: 'time',
    Header: 'Time',
    filterable: false,
    accessor: (d) => formatTimeLocal(d.time),
    minWidth: 125,
  },
  userColumn({
    header: 'Initiator',
    id: 'initiatorId',
    label: 'initiator',
    type: 'employee',
  }),
  {
    id: 'description',
    Header: 'Description',
    accessor: (d) => formatMoneyDescriptionRows(d.description),
    filterable: false,
  },
  {
    id: 'reqApprovals',
    Header: 'Required Approvals',
    accessor: 'reqApprovals',
    maxWidth: 175,
    minWidth: 50,
    className: 'mono',
    filterable: false,
  },
  {
    id: 'approvals',
    Header: 'Approvals',
    Cell: (e) => (
      <List
        items={_.get(e.original, 'approvals', []).map((p) => p.email)}
        data-cy={`approval-btn-index-${e?.index}`}
      />
    ),
    maxWidth: 275,
    minWidth: 50,
    filterable: false,
  },
  {
    id: 'rejections',
    Header: 'Rejections',
    Cell: (e) => (
      <List items={_.get(e.original, 'rejections', []).map((p) => p.email)} />
    ),
    maxWidth: 275,
    minWidth: 50,
    filterable: false,
    show: subject === 'done',
  },
  {
    id: 'Approve',
    Header: '',
    filterable: false,
    accessor: () => (
      <i className="grey arrow alternate circle right outline link large icon" />
    ),
    maxWidth: 35,
    resizable: false,
  },
];

export default columns;
