import React, { useState, useEffect } from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';

const TableStyled = styled.table`
  width: 100% !important;
`;
const SubTableStyled = styled(Table)`
  width: 100% !important;
`;
const TDStyled = styled.td`
  vertical-align: baseline;
`;

const LabelStyled = styled.span`
  width: 30px;
  height: 30px;
  background: #f2f7fa;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
`;

const CheckedLabelStyled = styled.span`
  width: 30px;
  height: 30px;
  background: #f2f7fa;
  color: ${(props) => (props.checked ? 'black' : '#d4d4d5')};
  padding: 5px;
  text-align: center;
  cursor: pointer;
  margin-top: 5px;
  border: ${(props) => (props.checked ? 'solid 1px #10c110' : 'none')};
`;

const Countries = styled.div`
  display: grid;
  margin-top: 5px;
  grid-template-columns: repeat(6, 1fr);
`;

export const CreateCheckedProp = (
  update,
  jurisdictions,
  defaultJurisdictions,
) => {
  const _countries = cloneDeep(defaultJurisdictions || []);
  let _currentExceptionCountry;
  let _currentExceptionState;
  if (update) {
    for (let i = 0; i < _countries.length; i++) {
      _currentExceptionCountry = jurisdictions.find(
        (x) => x.symbol === get(_countries, [i, 'symbol']),
      );
      if (_currentExceptionCountry?.active) {
        set(_countries, [i, 'checked'], true);
        for (let c = 0; c < get(_countries, [i, 'states', 'length']); c++) {
          _currentExceptionState = _currentExceptionCountry.states.find(
            (x) => x.symbol === get(_countries, [i, 'states', c, 'symbol']),
          );
          //Exception (Not allowed) Jurisdiction active, means Allowed Jurisdiction not checked.
          set(
            _countries,
            [i, 'states', c, 'checked'],
            !get(_currentExceptionState, ['active']),
          );
        }
      }
    }
  }
  return _countries;
};

const TableAuthorizedJurisdictions = ({
  onChange,
  update,
  jurisdictions,
  defaultJurisdictions,
}) => {
  const formValues = useSelector((state) =>
    getFormValues('create_account')(state),
  );

  //Esta mostrando todos cuando se carga por primera vez. En Update deberia mostrar los de jurisdictions
  let authorizedJurisdictions = formValues?.authorizedJurisdictions || [];

  useEffect(() => {
    const _countries = CreateCheckedProp(
      update,
      jurisdictions,
      defaultJurisdictions,
    );
    onChange(_countries);
  }, [jurisdictions]);

  //Given a country it selects the jurisdiction.
  const selectJurisdictionByCountry = (country) =>
    authorizedJurisdictions?.find((x) => x.symbol === country);

  /*
   * Checks if a checkbox is checked
   * Returns true if the checkbox should be checked or false if it not.
   * @param {country: string} Country to which the Checkbox belongs
   * @param {state: string} State of the country to verify
   */
  const isChecked = (country, state) => {
    const selectedCountry = selectJurisdictionByCountry(country);
    return selectedCountry?.states
      .filter((x) => x.checked)
      .map((x) => x.name)
      .includes(state);
  };

  /*
   * Checks if Select All checkbox is checked
   * Returns true if all checkbox that belong to the country are checked
   * @param {country: string} Country to which the Checkbox belongs
   */
  const isAllChecked = (country) => {
    const selectedCountry = selectJurisdictionByCountry(country);
    return (
      selectedCountry?.states.some((x) => x.checked) &&
      !selectedCountry.states.filter((x) => x.active).some((x) => !x.checked)
    );
  };

  /*
   * Select/Deselect all checkbox for a country
   *
   * @param {country: string} Country of the Select All Checkbox
   * @param {checked: bool} Value of the checkbox
   */
  const onSelectAll = (country, checked) => {
    const tempSelectedJurisdictions = cloneDeep(authorizedJurisdictions);

    for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
      if (get(tempSelectedJurisdictions, [i, 'symbol']) === country) {
        for (
          let c = 0;
          c < get(tempSelectedJurisdictions, [i, 'states', 'length']);
          c++
        ) {
          if (get(tempSelectedJurisdictions, [i, 'states', c, 'active'])) {
            set(
              tempSelectedJurisdictions,
              [i, 'states', c, 'checked'],
              checked,
            );
          }
        }
      }
    }

    onChange(tempSelectedJurisdictions);
  };

  /*
   * Checks if Select All checkbox is Indeterminate
   * Returns true if some of the checkbox that belong to the country are checked
   * @param {country: string} Country to which the Checkbox belongs
   */
  const isSelectAllIndeterminate = (country) => {
    //Given a country checks if some textbox are checked, but not all of them.
    const selectedCountry = selectJurisdictionByCountry(country);
    return (
      selectedCountry?.states.some((x) => x.checked) &&
      selectedCountry.states.filter((x) => x.active).some((x) => !x.checked)
    );
  };

  /*
   * Select or deselect a checkbox
   *
   * @param {country: string} Country to which the Checkbox belongs
   * @param {state: string} State of the country to select or deselect
   */
  const onStateCheckboxChange = (country, state) => {
    const checked = !!isChecked(country, state.name);
    const tempSelectedJurisdictions = cloneDeep(authorizedJurisdictions);
    for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
      if (get(tempSelectedJurisdictions, [i, 'symbol']) === country) {
        for (
          let c = 0;
          c < get(tempSelectedJurisdictions, [i, 'states', 'length']);
          c++
        ) {
          if (
            get(tempSelectedJurisdictions, [i, 'states', c, 'name']) ===
            state.name
          ) {
            set(
              tempSelectedJurisdictions,
              [i, 'states', c, 'checked'],
              !checked,
            );
          }
        }
      }
    }
    onChange(tempSelectedJurisdictions);
  };

  return (
    <div>
      <TableStyled>
        <TDStyled>
          <SubTableStyled celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  Authorized Jurisdictions
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="2">
                  Exception Jurisdictions
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Country</Table.HeaderCell>
                <Table.HeaderCell>State/Dependent Locality</Table.HeaderCell>
                <Table.HeaderCell>Country</Table.HeaderCell>
                <Table.HeaderCell>State/Dependent Locality</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {authorizedJurisdictions
                ?.filter((country) => country.active)
                ?.map((country) => (
                  <Table.Row celled padded>
                    <Table.Cell>{country.symbol}</Table.Cell>
                    <Table.Cell>
                      <Countries>
                        {country.active &&
                          country.states
                            .filter((state) => state.active)
                            .map((state) => (
                              <CheckedLabelStyled
                                checked={!isChecked(country.symbol, state.name)}
                              >
                                {state.symbol}
                              </CheckedLabelStyled>
                            ))}
                      </Countries>
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        label={country.symbol}
                        onClick={(e, { checked }) => {
                          onSelectAll(country.symbol, checked);
                        }}
                        indeterminate={isSelectAllIndeterminate(country.symbol)}
                        checked={isAllChecked(country.symbol)}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Countries>
                        {country.active &&
                          country.states
                            .filter((state) => state.active)
                            .map((state) => (
                              <CheckedLabelStyled
                                checked={isChecked(country.symbol, state.name)}
                                onClick={() => {
                                  onStateCheckboxChange(country.symbol, state);
                                }}
                              >
                                {state.symbol}
                              </CheckedLabelStyled>
                            ))}
                      </Countries>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </SubTableStyled>
        </TDStyled>
      </TableStyled>
    </div>
  );
};

export default TableAuthorizedJurisdictions;
