import React from 'react';
import { Form } from 'semantic-ui-react';

const FloatInput = (props) => <Form.Input {...props} />;

class FloatInputContainer extends React.Component {
  state = { value: '' };

  floatsOnly = (e, { name, value }) => {
    // scrub the input so all invalid characters get replaced with empty
    const scrubbed = value ? value.replace(/[^\d.,-]/g, '') : '';
    const [integer, ...fractional] = scrubbed.split('.');
    // only include first period in the value
    const parsedValue = `${integer}${fractional.length ? `.${fractional.join('')}` : ''}`;
    this.setState(
      // set the value you see in the input to to the value containing commas, digits, and first period
      { value: parsedValue },
      // set the value in the component (what we send to backend) so the parsed value with the commas scrubbed out
      this.props.onChange(e, { name, value: parsedValue.replace(/[,]/g, '') }),
    );
  };

  render() {
    return (
      <FloatInput
        {...this.props}
        onChange={this.floatsOnly}
        value={this.state.value}
      />
    );
  }
}

export default FloatInputContainer;
