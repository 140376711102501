import get from 'lodash/get';
import BlockTradeRequest, {
  BlockTradeRequestSide,
  BlockTradeState,
} from '../../../ts/models/BlockTrades/BlockTradeRequest.model';
import { TradingParticipant } from '../../../ts/models/TradingParticipant/ApprovedParticipant.model';

export const isBlockTradeOfSide = (
  blockTrade: BlockTradeRequest,
  side: BlockTradeRequestSide,
) => {
  return blockTrade?.reportingSide?.toUpperCase() === side;
};
export const hasMatchingParticipant = (
  participantList: TradingParticipant[],
  tphId: string,
) => participantList.some((x) => x.participantFirmCode === tphId);

export const showApproveButton = (
  blockTrade: BlockTradeRequest,
  participants: TradingParticipant[],
) => {
  const buyTphId = get(blockTrade, ['buySide', 'tphId'], '');
  const sellTphId = get(blockTrade, ['sellSide', 'tphId'], '');
  const isPending = get(blockTrade, ['state']) === BlockTradeState.PENDING;
  const isBuy = isBlockTradeOfSide(blockTrade, BlockTradeRequestSide.BUY);
  // If side is buy, match against selling participant and viceversa
  const participantToMatch = isBuy ? sellTphId : buyTphId;
  // show if status is pending and a participant with firmCode matching the tphId is found
  return isPending && hasMatchingParticipant(participants, participantToMatch);
};
