import styled from 'styled-components';

const FullHeightWrapper = styled.div`
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  & h1 {
    flex-shrink: 0;
  }
  .ReactTable {
    overflow: hidden;
  }
  .ReactTable .rt-tbody::-webkit-scrollbar {
    display: none;
  }
`;

export default FullHeightWrapper;
