import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

const DeprecationNotice = ({ children }) => (
  <Message icon negative>
    <Icon name="warning" />
    <Message.Content>
      <Message.Header>Deprecation Notice.</Message.Header>
      <p>{children}</p>
    </Message.Content>
  </Message>
);

export default DeprecationNotice;
