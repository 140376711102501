export const SPOT_DELIVERY = {
  name: 'SD-Spot Delivery',
  type: 'spot_delivery',
};
export const COLLATERAL = { name: 'C-Collateral', type: 'collateral' };
export const GUARANTY_FUND = {
  name: 'GF-Guaranty Fund',
  type: 'guaranty_fund',
};
export const OMNIBUS = { name: 'O-Omnibus', type: 'omnibus' };
export const POSITION = { name: 'PA-Position', type: 'position' };
export const BANK = { name: 'B-Bank', type: 'bank' };
export const DCO = { name: 'F-DCO', type: 'DCO' };
export const WALLET = { name: 'W-Wallet', type: 'wallet' };
export const EXPENSE = { name: 'E-Expense', type: 'expense' };
export const EXPENSE_ACCRUAL = {
  name: 'EA-Expense Accrual',
  type: 'expense_accrual',
};
export const RETAINED_EARNINGS = {
  name: 'RE-Retained Earnings',
  type: 'retained_earnings',
};
export const REVENUE = { name: 'R-Revenue', type: 'revenue' };
export const REVENUE_ACCRUAL = {
  name: 'RA-Revenue Accrual',
  type: 'revenue_accrual',
};
