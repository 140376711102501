import React from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import {
  columns,
  fundsDesignation as designations,
  SEG,
  MEMBER_PROPERTY,
  NON_SEG,
  COLLATERAL
} from 'erisxkit/client';

const custodianAccountMetadata = (assetTypeOptions, viewReconciliations) => [
  {
    Header: 'Identity',
    columns: [
      {
        Header: 'Firm Code',
        id: 'firmCode',
        accessor: (d) => d.firmCode,
        width: columns.FIRM_CODE,
      },
      {
        Header: 'Account Code',
        id: 'accountCode',
        accessor: (d) => d.accountCode,
        width: columns.ACCOUNT_CODE,
      },
      {
        Header: 'Account Name',
        id: 'name',
        accessor: (d) => d.name,
        width: columns.ACCOUNT_NAME,
      },
      {
        Header: 'Subexchange',
        id: 'subExchangeLabel',
        accessor: 'subExchangeLabel',
      },
      {
        Header: 'Provider',
        id: 'provider',
        width: 80,
        accessor: (d) => _.get(d, 'provider', ''),
      },
      {
        Header: 'Provider Account Id',
        id: 'providerAccountId',
        accessor: 'providerAccountId',
        className: 'mono',
      },
      {
        Header: 'Funds Designation',
        id: 'fundsDesignation',
        accessor: ({ fundsDesignation = '' }) => {
          switch (fundsDesignation) {
            case designations[SEG].value:
              return designations[SEG].text;
            case designations[MEMBER_PROPERTY].value:
              return designations[MEMBER_PROPERTY].text;
            case designations[NON_SEG].value:
              return designations[NON_SEG].text;
            case designations[COLLATERAL].value:
              return designations[COLLATERAL].text;
            default:
              return fundsDesignation;
          }
        },
        width: 150,
      },
    ],
  },
  {
    Header: 'Balance',
    columns: [
      {
        Header: 'Asset Type',
        id: 'assetType',
        width: 80,
        accessor: 'assetType',
        filterMethod: (filter, row) => {
          if (filter.value.value === 'All') {
            return true;
          }
          return row[filter.id] === filter.value.value.toUpperCase();
        },
        Filter: ({ filter, onChange }) => (
          <Dropdown
            onChange={(event, data) => onChange(data)}
            options={assetTypeOptions}
            defaultValue="All"
            selectOnBlur={false}
          />
        ),
      },
      {
        Header: 'Amount',
        id: 'amount',
        accessor: (d) =>
          _.get(d, 'balances', {})[_.get(d, 'assetType', '').toLowerCase()],
        className: 'text-right mono',
        filterable: false,
      },
    ],
  },
  {
    columns: [
      {
        Header: '',
        id: 'action',
        maxWidth: 32,
        filterable: false,
        Cell: (row) => (
          <span>
            {
              <Icon
                link
                title="View Reconciliation History"
                onClick={() => viewReconciliations(row.original.accountId)}
                color="grey"
                name="arrow alternate circle right outline"
              />
            }
          </span>
        ),
      },
    ],
  },
];

export default custodianAccountMetadata;
