import { useMemo } from "react";

interface SettingsData {
  [key: string]: any
};

const agGridSettingsStoreKey = 'ag-grid-settings-tcs-admin-ui';

export const getGridSettings = (gridName: string): any => {
  const data = localStorage.getItem(agGridSettingsStoreKey);
  try {
    const gridSettingsData = JSON.parse(data ?? '{}');
    const currentGridSetting = gridSettingsData?.[gridName];
    if (currentGridSetting !== undefined) {
      return currentGridSetting;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};

export const setGridSettings = (gridName: string, value: any) => {
  const data = localStorage.getItem(agGridSettingsStoreKey);

  const setStorageData = (data: SettingsData) => {
    data[gridName] = value;
    const json = JSON.stringify(data);
    localStorage.setItem(agGridSettingsStoreKey, json);
  };

  try {
    const gridToggleData = JSON.parse(data ?? '{}');
    setStorageData(gridToggleData);
  } catch (e) {
    setStorageData({});
  }
}

export const useAgLocalStorage = (gridName: string) => {
  const gridSettings = useMemo(() => { 
    return getGridSettings(gridName);
  }, [gridName]);
  return gridSettings;
};
