import React from 'react';
import { connect } from 'react-redux';
import { Sidebar, Icon, Segment } from 'semantic-ui-react';
import { hideSidebar } from '../reducers/sidebarReducer';

/** Sidebar Components */

const SIDEBAR_COMPONENTS = {};

const SidebarContainer = (props) => {
  // initialize SidebarContent to an empty div, this is needed.
  let SidebarContent = () => <div />;

  // if there's a sidebarContent state, then set that component to SidebarContent
  if (props.sidebarContent) {
    SidebarContent = SIDEBAR_COMPONENTS[props.sidebarContent];
  }
  return (
    <Sidebar
      as={Segment}
      animation="overlay"
      direction="right"
      visible={!!props.sidebarContent}
      width="very wide"
      onHide={props.hideSidebar}
    >
      <Icon
        link
        name="close"
        onClick={props.hideSidebar}
        className="pull-right"
      />
      <div className="sidebar-content">
        <SidebarContent />
      </div>
    </Sidebar>
  );
};

const mapStateToProps = (state) => ({
  ...state.ui.sidebar,
});

export default connect(mapStateToProps, { hideSidebar })(SidebarContainer);
