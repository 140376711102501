import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Reconciliation from '../components/Reconciliation';
import history from '../constants/history';
import {
  reconcile,
  selectTransaction,
  createReconciliation,
  updateReconciliationEntryDetails,
} from '../actions/reconciliationActions';
import { fetchAccounts } from '../actions/accountsActions';
import { showModal, hideModal } from 'erisxkit/client';
import reconciliationTable from '../metadata/reconciliationTable';
import {
  RECONCILIATION_MODAL,
  CREATE_MANUAL_EXTERNAL_TRANSACTION,
} from '../constants/modalTypes';
import externalBlockchainExplorers from '../constants/externalBlockchainExplorers';
import CommentsContainer from './CommentsContainer';
import {
  isMatchedEnd,
  getDifferenceEnd,
  getCurrentReconciliation,
  getSelectedAccountId,
  getSelectedAccount,
  getSelectedReconciliation,
  getEndTime,
  getDaysPrevious,
} from '../reducers/reconciliationReducer';
import * as accountTypes from '../constants/accountTypes';

export const getTransactionLink = (assetType) =>
  _.get(externalBlockchainExplorers, assetType, null);

const mapStateToProps = (state, props) => ({
  reconciliationLoading: createLoadingSelector(['RECONCILIATION'])(state),
  selectedAccount: getSelectedAccount(state, props),
  selectedAccountId: getSelectedAccountId(state, props),
  endTime: getEndTime(state, props),
  daysPrevious: getDaysPrevious(state, props),
  reconciliation: getCurrentReconciliation(state, props),
  isMatchedEnd: isMatchedEnd(state, props),
  getDifferenceEnd: getDifferenceEnd(state, props),
  selectedReconciliation: getSelectedReconciliation(state, props),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reconcile,
      selectTransaction,
      createReconciliation,
      showModal,
      hideModal,
      fetchAccounts,
      updateReconciliationEntryDetails,
    },
    dispatch,
  );

class ReconciliationContainer extends Component {
  componentDidMount() {
    // fetch the data
    const {
      reconcile,
      selectedAccountId,
      fetchAccounts,
      endTime,
      daysPrevious,
    } = this.props;
    fetchAccounts({ accountIds: [selectedAccountId] });
    reconcile({
      ...this.props.selectedReconciliation,
      selectedAccountId,
      endTime,
      daysPrevious,
    });
  }

  refresh = () => {
    const { reconcile, selectedAccountId, endTime, daysPrevious } = this.props;
    reconcile({
      ...this.props.selectedReconciliation,
      selectedAccountId,
      endTime,
      daysPrevious,
    });
  };

  createJournal = (index) => {
    this.props.selectTransaction(index);
    this.props.showModal(RECONCILIATION_MODAL);
  };

  createReconciliation = () => {
    this.props.createReconciliation(this.props.reconciliation);
    // add promise and redirect to reconciliation accounts view only
    //  when it's done with the createReconciliation approval request.
    history.push('/reconciliation');
  };

  addComment = (args) => {
    const { reconcile, selectedAccountId, endTime, daysPrevious } = this.props;
    args = args.map((arg) => ({ ...arg, accountId: selectedAccountId }));
    this.props.updateReconciliationEntryDetails(args);

    reconcile({
      ...this.props.selectedReconciliation,
      selectedAccountId,
      endTime,
      daysPrevious,
    });
  };

  linkEntries = (selected) => {
    const { reconcile, selectedAccountId, endTime, daysPrevious } = this.props;
    const args = selected.map((uuid) => ({
      uuid,
      links: selected,
      accountId: selectedAccountId,
    }));

    this.props.updateReconciliationEntryDetails(args);
    reconcile({
      ...this.props.selectedReconciliation,
      selectedAccountId,
      endTime,
      daysPrevious,
    });
  };

  unLinkEntries = (row) => {
    const { reconcile, selectedAccountId, endTime, daysPrevious } = this.props;
    const links = row.links.concat([row.key]);
    const args = links.map((uuid) => ({
      uuid,
      links: [],
      accountId: selectedAccountId,
    }));

    this.props.updateReconciliationEntryDetails(args);
    reconcile({
      ...this.props.selectedReconciliation,
      selectedAccountId,
      endTime,
      daysPrevious,
    });
  };

  createManualExternalTransaction = () => {
    this.props.showModal(CREATE_MANUAL_EXTERNAL_TRANSACTION);
  };

  render() {
    const {
      reconciliationLoading,
      selectedAccountId,
      reconciliation,
      isMatchedEnd,
      getDifferenceEnd,
      selectedAccount,
      selectedReconciliation,
    } = this.props;

    // adding account type so 'confirmations column doesn't show for bank reconciliations.
    const accountType = _.chain(selectedAccount)
      .get('categories', [])
      .includes(accountTypes.BANK)
      .value()
      ? accountTypes.BANK
      : accountTypes.WALLET;
    // adding reconciliationState so 'confirmations column doesn't show for historical/confirmed reconciliations.
    const reconciliationState = _.get(reconciliation, 'state', '');
    const metadata = reconciliationTable(
      this.createJournal,
      getTransactionLink(selectedAccount.assetType),
      accountType,
      reconciliationState,
    );
    const unReconciledEntries = reconciliation.entries
      ? reconciliation.entries.filter((entry) => !entry.reconciled)
      : [];
    const reconciledEntries = reconciliation.entries
      ? reconciliation.entries.filter((entry) => entry.reconciled)
      : [];
    const props = {
      reconciliationLoading,
      selectedAccount,
      selectedAccountId,
      reconciliation,
      unReconciledEntries,
      reconciledEntries,
      metadata,
      refresh: this.refresh,
      isMatchedEnd,
      getDifferenceEnd,
      createReconciliation: this.createReconciliation,
      linkEntries: this.linkEntries,
      unLinkEntries: this.unLinkEntries,
      selectedReconciliation,
      CommentsContainer,
      addComment: this.addComment,
      createManualExternalTransaction: this.createManualExternalTransaction,
    };
    return (
      <div>
        <Reconciliation {...props} />
      </div>
    );
  }
}

ReconciliationContainer.propTypes = {
  reconcile: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconciliationContainer);
