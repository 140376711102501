import { call, takeLatest, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import * as actionTypes from '../constants/actionTypes';
import api, * as urls from '../api';
import {
  createManualExternalTransaction,
  CREATE_MANUAL_EXTERNAL_TRANSACTION,
} from '../reducers/reconciliationReducer';
import { createSaga } from '../utils/createSaga';

function* reconcile(arg) {
  try {
    let lookbackDays = arg.daysPrevious || 0;
    let reconciliation = {};
    if (arg.state === 0) {
      reconciliation = yield call(api.post, urls.RECONCILE_API_ENDPOINT, {
        accountId: arg.selectedAccountId,
        endTime: arg.endTime,
        daysPrevious: lookbackDays,
      });
    } else {
      reconciliation = yield call(api.post, urls.RECONCILIATION_API_ENDPOINT, {
        uuid: arg.uuid,
      });
    }
    yield put({ type: actionTypes.RECONCILIATION_SUCCESS, reconciliation });
  } catch (e) {
    yield put({ type: actionTypes.RECONCILIATION_FAILED, payload: e.response });
  }
}

function* fetchReconciliationHistory({ accountId }) {
  try {
    const history = yield call(
      api.post,
      urls.RECONCILIATION_HISTORY_API_ENDPOINT,
      { accountId },
    );
    yield put({
      type: actionTypes.RECONCILIATION_HISTORY_SUCCESS,
      history,
      accountId,
    });
  } catch (e) {
    yield put({
      type: actionTypes.RECONCILIATION_HISTORY_FAILED,
      payload: e.response,
    });
  }
}

function* createWithdrawal(arg) {
  try {
    yield call(api.post, urls.CREATE_WITHDRAWAL_API_ENDPOINT, arg.payload);
    yield put({ type: actionTypes.CREATE_WITHDRAWAL_SUCCESS });
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_WITHDRAWAL_FAILED,
      payload: e.response,
    });
  }
}

function* createDeposit(arg) {
  try {
    yield call(api.post, urls.CREATE_DEPOSIT_API_ENDPOINT, arg.payload);
    yield put({ type: actionTypes.CREATE_DEPOSIT_SUCCESS });
  } catch (e) {
    yield put({ type: actionTypes.CREATE_DEPOSIT_FAILED, payload: e.response });
  }
}

function* createTransfer(arg) {
  try {
    yield call(api.post, urls.CREATE_TRANSFER_API_ENDPOINT, arg.payload);
    yield put({ type: actionTypes.CREATE_TRANSFER_SUCCESS });
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_TRANSFER_FAILED,
      payload: e.response,
    });
  }
}

function* createReversal({ payload }) {
  const loadingCallback = payload.showToast
    ? cogoToast.loading('Submitting reversal journal...', { hideAfter: 0 })
    : () => {};
  try {
    yield call(api.post, urls.CREATE_REVERSAL_API_ENDPOINT, payload);
    yield put({ type: actionTypes.CREATE_REVERSAL_SUCCESS });

    if (payload.showToast) {
      loadingCallback();
      cogoToast.info('Approval request created for a new reversal journal.');
    }
  } catch (e) {
    loadingCallback();
    yield put({
      type: actionTypes.CREATE_REVERSAL_FAILED,
      payload: e.response,
    });
  }
}

function* createReconciliation(arg) {
  try {
    const reconciliation = yield call(
      api.post,
      urls.CREATE_RECONCILIATION_API_ENDPOINT,
      arg.payload,
    );
    yield put({
      type: actionTypes.CREATE_RECONCILIATION_SUCCESS,
      reconciliation,
    });
  } catch (e) {
    yield put({
      type: actionTypes.CREATE_RECONCILIATION_FAILED,
      payload: e.response,
    });
  }
}

function* updateReconciliationEntryDetails(arg) {
  try {
    const newEntry = yield call(
      api.post,
      urls.UPDATE_RECONCILIATION_ENTRY_DETAILS_API_ENDPOINT,
      arg.payload,
    );
    yield put({
      type: actionTypes.UPDATE_RECONCILIATION_ENTRY_DETAILS_SUCCESS,
      payload: newEntry,
    });
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_RECONCILIATION_ENTRY_DETAILS_FAILED,
      payload: e.response,
    });
  }
}

// watcher function
export default function* watchReconciliation() {
  yield takeLatest(actionTypes.RECONCILIATION_REQUEST, reconcile);
  yield takeLatest(actionTypes.CREATE_WITHDRAWAL_REQUEST, createWithdrawal);
  yield takeLatest(actionTypes.CREATE_DEPOSIT_REQUEST, createDeposit);
  yield takeLatest(actionTypes.CREATE_TRANSFER_REQUEST, createTransfer);
  yield takeLatest(actionTypes.CREATE_REVERSAL_REQUEST, createReversal);
  yield takeLatest(
    actionTypes.CREATE_RECONCILIATION_REQUEST,
    createReconciliation,
  );
  yield takeLatest(
    actionTypes.RECONCILIATION_HISTORY_REQUEST,
    fetchReconciliationHistory,
  );
  yield takeLatest(
    actionTypes.UPDATE_RECONCILIATION_ENTRY_DETAILS_REQUEST,
    updateReconciliationEntryDetails,
  );
  yield takeLatest(
    createManualExternalTransaction.TRIGGER,
    createSaga(
      createManualExternalTransaction,
      CREATE_MANUAL_EXTERNAL_TRANSACTION,
      'Creating approval request for external transaction',
      'Approval Request Successfully created!',
    ),
  );
}
