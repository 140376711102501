import _ from 'lodash';
import moment from 'moment';
import { formatTimeLocalWithDoW } from '../utils/time';

const taskLogMetadata = [
  {
    Header: '',
    columns: [
      {
        Header: 'Time Received',
        id: 'time',
        accessor: (row) => formatTimeLocalWithDoW(row.time, true), // condensed: true,
      },
      {
        Header: 'Task Name',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        sortable: false,
      },
      {
        Header: 'Duration',
        id: 'duration',
        accessor: (row) =>
          `${moment
            .duration(_.get(row, 'duration', 0), 'seconds')
            .asSeconds()
            .toFixed(3)} seconds`,
        sortable: false,
      },
    ],
  },
];

export default taskLogMetadata;
