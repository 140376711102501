import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

/** Actions */
export const RISK_CONFIGURATIONS = 'RISK_CONFIGURATIONS';
export const CREATE_RISK_CONFIGURATIONS = 'CREATE_RISK_CONFIGURATION';
export const UPDATE_RISK_CONFIGURATIONS = 'UPDATE_RISK_CONFIGURATION';
export const DELETE_RISK_CONFIGURATIONS = 'DELETE_RISK_CONFIGURATION';

/** Routines */
export const fetchRiskConfigurations = createRoutine(RISK_CONFIGURATIONS);
export const createRiskConfigurations = createRoutine(
  CREATE_RISK_CONFIGURATIONS,
);
export const updateRiskConfigurations = createRoutine(
  UPDATE_RISK_CONFIGURATIONS,
);
export const deleteRiskConfigurations = createRoutine(
  DELETE_RISK_CONFIGURATIONS,
);

/** Promise */
export const createRiskConfigPromise = promisifyRoutine(
  createRiskConfigurations,
);
export const updateRiskConfigPromise = promisifyRoutine(
  updateRiskConfigurations,
);
export const deleteRiskConfigPromise = promisifyRoutine(
  deleteRiskConfigurations,
);

/** Reducer */
export default handleActions(
  {
    [fetchRiskConfigurations.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

//* Selectors */
const getRiskConfigState = (state) => get(state, 'riskConfigurations', {});
const getRiskConfigurations = createSelector([getRiskConfigState], (state) =>
  get(state, 'riskConfig', []),
);

// This selector accepts a 2nd argument configSection that allows to
// select which config section to use
export const getRiskConfigSectionData = createSelector(
  [getRiskConfigurations, (state, configSection) => configSection],
  (configs, section) =>
    get(
      configs.find((config) => config?.configSection === section),
      'result',
      [],
    ),
);

export const getRiskConfigSectionCount = createSelector(
  [getRiskConfigurations, (state, configSection) => configSection],
  (configs, section) =>
    get(
      configs.find((config) => config?.configSection === section),
      'count',
      0,
    ),
);
