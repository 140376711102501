import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment, Button } from 'semantic-ui-react';
import JSONTree from 'react-json-tree';

const ConfirmActionWithPayload = ({
  header,
  hideModal,
  msg,
  onConfirm,
  payload,
  Content,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = 'primary',
}) => (
  <Fragment>
    <Modal.Header>{header}</Modal.Header>
    {msg && (
      <Modal.Description>
        <Segment>{msg}</Segment>
      </Modal.Description>
    )}
    {/* if a content component is given, show that, else show the default JSONTree */}
    {Content ? (
      <Modal.Content>{Content}</Modal.Content>
    ) : (
      !isEmpty(payload) && (
        <Modal.Content>
          <h3>Details:</h3>
          <JSONTree data={payload} />
        </Modal.Content>
      )
    )}
    <Modal.Actions>
      <Button onClick={hideModal} content={cancelButtonText} />
      <Button
        name="confirmAction"
        onClick={onConfirm}
        content={confirmButtonText}
        color={confirmButtonColor}
      />
    </Modal.Actions>
  </Fragment>
);

ConfirmActionWithPayload.propTypes = {
  header: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  msg: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  payload: PropTypes.objectOf(PropTypes.any),
  Content: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

ConfirmActionWithPayload.defaultProps = {
  header: 'Confirm Action',
  msg: '',
  payload: {},
  Content: '',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
};

export default ConfirmActionWithPayload;
