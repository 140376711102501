import React, { Fragment } from 'react';
import {
  createLoadingSelector,
  createErrorMessageSelector,
  hideModal,
} from 'erisxkit/client';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, List } from 'semantic-ui-react';

const getDetailsByStatus = ({ actionName, loadingTracker, errorTracker }) => {
  if (loadingTracker[actionName]) {
    return 'spinner yellow';
  }
  if (errorTracker[actionName]) {
    return 'close red';
  }
  return 'check circle green';
};

const ActionStatus = ({ actions = {} }) => {
  const actionNames = Object.keys(actions);
  const loadingTracker = actionNames.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: useSelector(createLoadingSelector([curr])),
    }),
    {},
  );
  const errorTracker = actionNames.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: useSelector(createErrorMessageSelector([curr])),
    }),
    {},
  );
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Modal.Header>Processing request...</Modal.Header>
      <Modal.Content>
        {' '}
        <List>
          {actionNames.map((actionName) => (
            <List.Item
              icon={getDetailsByStatus({
                actionName,
                loadingTracker,
                errorTracker,
              })}
              content={
                <div>
                  {actionName} - {actions[actionName]}
                </div>
              }
            />
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={() => dispatch(hideModal())} />
      </Modal.Actions>
    </Fragment>
  );
};

export default ActionStatus;
