import {
  fetchRiskConfigurations,
  createRiskConfigurations,
  updateRiskConfigurations,
  deleteRiskConfigurations,
} from '../reducers/riskConfigurationsReducer';
import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeLatest(
    fetchRiskConfigurations.TRIGGER,
    generateSaga(fetchRiskConfigurations),
  );
  yield takeLatest(
    createRiskConfigurations.TRIGGER,
    generateSaga(createRiskConfigurations, {
      loading: 'Creating Risk Configuration Items',
      success: 'Risk Configuration Items Created',
    }),
  );
  yield takeLatest(
    updateRiskConfigurations.TRIGGER,
    generateSaga(updateRiskConfigurations, {
      loading: 'Updating Risk Configuration Items',
      success: 'Risk Configuration Items Updated',
    }),
  );
  yield takeLatest(
    deleteRiskConfigurations.TRIGGER,
    generateSaga(deleteRiskConfigurations, {
      loading: 'Deleting Risk Configuration Items',
      success: 'Risk Configuration Items deleted',
    }),
  );
}
