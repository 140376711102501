import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Form, Input, Button, Label } from 'semantic-ui-react';
import { fetchPii } from '../../reducers/usersReducer';

const initialPii = { ssn: '*********', dob: '********' };
const initialTimeRemaining = '';

export default ({ selectedUser }) => {
  const dispatch = useDispatch();
  const [pii, setPii] = useState(initialPii);
  const [timeRemaining, setTimeRemaining] = useState(initialTimeRemaining);
  let timer = '';

  const resetPii = () => {
    setPii(initialPii);
  };

  useEffect(() => {
    if (get(selectedUser, 'pii', '')) {
      setPii(selectedUser.pii);
      setTimeout(() => {
        resetPii();
      }, 30000);

      timer = setInterval(() => {
        setTimeRemaining((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(timer);
            return initialTimeRemaining;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
  }, [selectedUser]);

  const revealPii = () => {
    setTimeRemaining(30);
    dispatch(fetchPii({ userId: selectedUser.userId }));
  };
  return (
    <div className="xwizard-form-group">
      <h4>Personal Information</h4>
      <Form>
        <Form.Group>
          <Form.Field>
            <label>SSN</label>
            <Input name="ssn" value={pii.ssn} />
          </Form.Field>
          <Form.Field>
            <label>DOB</label>
            <Input name="dob" value={pii.dob} />
          </Form.Field>
        </Form.Group>
        <Button
          as="div"
          labelPosition="right"
          onClick={revealPii}
          disabled={timeRemaining}
        >
          <Button color="red">Reveal</Button>
          {timeRemaining && (
            <Label as="a" basic color="red" pointing="left">
              {timeRemaining}
            </Label>
          )}
        </Button>
      </Form>
    </div>
  );
};
