import { takeLatest } from 'redux-saga/effects';
import {
  fetchBaseMarginRates,
  fetchMarginRatesMultipliers,
  fetchAppliedMarginRates,
  rejectMarginRates,
  applyMarginRates,
  createAppliedMarginRates,
} from '../reducers/marginRatesReducer';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    fetchBaseMarginRates.TRIGGER,
    createSaga(fetchBaseMarginRates, fetchBaseMarginRates._PREFIX),
  );
  yield takeLatest(
    fetchMarginRatesMultipliers.TRIGGER,
    createSaga(
      fetchMarginRatesMultipliers,
      fetchMarginRatesMultipliers._PREFIX,
    ),
  );
  yield takeLatest(
    fetchAppliedMarginRates.TRIGGER,
    createSaga(fetchAppliedMarginRates, fetchAppliedMarginRates._PREFIX),
  );
  yield takeLatest(
    rejectMarginRates.TRIGGER,
    createSaga(rejectMarginRates, rejectMarginRates._PREFIX),
  );
  yield takeLatest(
    applyMarginRates.TRIGGER,
    createSaga(applyMarginRates, applyMarginRates._PREFIX),
  );
  yield takeLatest(
    createAppliedMarginRates.TRIGGER,
    createSaga(
      createAppliedMarginRates,
      createAppliedMarginRates._PREFIX,
      'Submitting an approval request...',
      'Request submitted',
    ),
  );
}
