import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { assetTypes, createAssetType } from '../../actions/utilActions';
import { getAssetTypes } from '../../selectors';
import CreateAssetType from '../../components/Products/CreateAssetType';

const mapDispatchtoProps = {
  assetTypes,
  createAssetType,
};

const mapStateToProps = (state) => ({
  assetTypesList: getAssetTypes(state),
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
});

class CreateAssetTypeContainer extends Component {
  // Set to false in case user never toggles to "true" and back
  state = {
    isFiat: false,
  };

  componentWillMount = () => {
    this.props.assetTypes();
  };

  handleChange = (e, { name, value, checked }) => {
    if (name === 'isFiat') {
      this.setState({ ...this.state, [name]: checked });
    } else {
      this.setState({ ...this.state, [name]: value });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { symbol, description, isFiat, precision, spanId } = this.state;
    this.props.createAssetType({
      symbol,
      description,
      isFiat,
      precision,
      spanId,
    });
  };

  render = () => (
    <CreateAssetType
      assetTypes={this.props.assetTypesList}
      assetTypesLoading={this.props.assetTypesLoading}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
      state={this.state}
    />
  );
}

CreateAssetTypeContainer.propTypes = {
  assetTypesList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      description: PropTypes.string,
      isFiat: PropTypes.bool,
    }),
  ),
  assetTypesLoading: PropTypes.bool,
  assetTypes: PropTypes.func.isRequired,
  createAssetType: PropTypes.func.isRequired,
};

CreateAssetTypeContainer.defaultProps = {
  assetTypesList: [],
  assetTypesLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps,
)(CreateAssetTypeContainer);
