import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import marginProfileMultipliersMetadata from '../../metadata/marginProfileMultipliersMetadata';
import {
  fetchMarginRatesMultipliers,
  getMarginRatesMultipliers,
  getMarginRateInfoDate,
} from '../../reducers/marginRatesReducer';
import XTable7 from '../../common/table/XTable7';

const fetchData = () => {};

const MarginProfileMultipliers = () => {
  const dispatch = useDispatch();
  const marginRatesMultipliersLoading = useSelector(
    createLoadingSelector([fetchMarginRatesMultipliers._PREFIX]),
  );
  const marginRatesMultipliers = useSelector(getMarginRatesMultipliers);
  const marginRateInfoDate = useSelector(getMarginRateInfoDate);

  React.useEffect(() => {
    if (marginRateInfoDate) {
      dispatch(fetchMarginRatesMultipliers({ date: marginRateInfoDate }));
    } else {
      dispatch(fetchMarginRatesMultipliers());
    }
  }, [marginRateInfoDate]);

  return (
    <Fragment>
      <XTable7
        metadata={marginProfileMultipliersMetadata}
        data={marginRatesMultipliers?.multipliers || []}
        fetchData={fetchData}
        loading={marginRatesMultipliersLoading}
        count={marginRatesMultipliers?.multipliers?.length}
        showPagination={false}
        localSortingAndFiltering={true}
      />
    </Fragment>
  );
};

MarginProfileMultipliers.propTypes = {
  fetchMarginRatesMultipliers: PropTypes.func.isRequired,
  marginRatesMultipliersLoading: PropTypes.bool,
  marginRatesMultipliers: PropTypes.func.isRequired,
};

MarginProfileMultipliers.defaultProps = {
  count: 0,
};

export default MarginProfileMultipliers;
