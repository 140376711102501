import _ from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { getSelectedUser } from './usersReducer';
import { handleActions } from 'redux-actions';
import Big from 'bignumber.js';

//* actions */
export const fetchReferralCode = createRoutine('GENERATE_REFERRAL_CODE');
export const setReferralCode = createRoutine('SET_REFERRED_BY_CODE');
export const getPromotionReferrals = createRoutine('PROMOTION_REFERRALS');

//* Initial State */
const initialState = {
  referralCode: '',
};

export default handleActions(
  {
    [fetchReferralCode.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [setReferralCode.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [getPromotionReferrals.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

//* Selectors */
export const getReferralCode = (state) =>
  _.get(state, 'referrals.referralCode', '');
export const getReferredByCode = (state) =>
  _.get(state, 'referrals.referredByCode', '');
export const getUserReferralCodes = (state) => {
  const user = getSelectedUser(state);
  return {
    referralCode: _.get(user, 'referralCode', ''),
    referredByCode: _.get(user, 'referredByCode', ''),
  };
};
export const getReferralStats = (state) =>
  _.get(state, 'referrals.promotionReferrals', []);
export const getReferralCount = (state) => _.get(state, 'referrals.count', 0);
export const getCodesUsedAmount = (state) => {
  const referrals = getReferralStats(state);
  return referrals.filter((referral) =>
    _.get(referral, 'status', '').includes('used'),
  ).length;
};
export const getBonusBalance = (state) => {
  const referrals = getReferralStats(state);
  return referrals.reduce((acc, current) => {
    const bigCurrent = Big(_.get(current, 'referrerPayoutAmount', 0));
    return Big(acc).plus(bigCurrent);
  }, Big(0));
};
