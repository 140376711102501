import React from 'react';
import { Button } from 'semantic-ui-react';

export default ({ memberDetailsView, edit, remove }) => [
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
    width: 300,
  },
  {
    Header: 'Address',
    id: 'address',
    accessor: ({
      address1,
      address2,
      city,
      locationState,
      postalCode,
      country,
    }) =>
      `${address1 || ''} ${address2 || ''} ${city || ''}, ${locationState || ''} ${postalCode || ''} ${country || ''}`,
    width: 500,
  },
  {
    Header: 'Ownership',
    id: 'percentageOwned',
    accessor: 'percentageOwned',
    width: 80,
  },
  {
    Header: 'Documentation',
    id: 'documentation',
    accessor: ({ documentsReceived }) => (documentsReceived ? 'Yes' : 'No'),
    width: 115,
  },
  {
    Header: 'Controller',
    id: 'controller',
    accessor: 'controller',
    width: 80,
  },
  {
    Header: '',
    id: 'actions',
    show: !memberDetailsView,
    Cell: ({ original }) => (
      <div className="flex-row space-between">
        <Button
          className="link"
          content="Edit"
          onClick={() => edit(original)}
        />
        <Button
          className="link"
          content="Remove"
          onClick={() => remove(original)}
        />
      </div>
    ),
    resizable: false,
  },
];
