import React, { Fragment } from 'react';
import { Header, Form, Segment, Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import FloatInputContainer from '../../common/containers/FloatInputContainer';
import ProductContractSelection from '../../common/containers/ProductContractSelection';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

const ButtonContainer = styled.div`
  height: 30px;
`;

const betweenYesterdayAndToday = (date) =>
  date.isBetween(Datetime.moment().subtract(2, 'day'), Datetime.moment());

const validExecutionTime = (tradeDate, executionTime) =>
  executionTime.isBetween(
    Datetime.moment(tradeDate).subtract(1, 'day'),
    Datetime.moment(),
  );

const validEnableButton = (data) => {
  return !!(
    data.contractSymbol &&
    data.executionTime &&
    data.postToExchange &&
    data.px &&
    data.qty &&
    data.tradeDate &&
    data.trdType &&
    data.buySide.accountId &&
    data.buySide.clearingFee &&
    data.buySide.exchangeFee &&
    data.buySide.feeType &&
    data.sellSide.accountId &&
    data.sellSide.clearingFee &&
    data.sellSide.exchangeFee &&
    data.sellSide.feeType
  );
};

const TradeEntry = ({
  contractsLoading,
  data,
  handleChange,
  handleSubmit,
  handlePostToExchangeChanged,
  renderContractDetails,
  tradeEntryCgmsBuySide,
  tradeEntryCgmsSellSide,
}) => {
  return (
    <Fragment>
      <Header as="h2" dividing>
        Trade Entry
      </Header>
      <Form onSubmit={handleSubmit}>
        <label className="italic">
          Note: trade date and execution time are restricted to yesterday and
          today. The execution time must also be after the trade date.
        </label>
        <Form.Group>
          <Form.Field
            control={Datetime}
            className="ui input datetime"
            label="Trade Date"
            name="tradeDate"
            id="trade-date"
            defaultValue={Datetime.moment(data.tradeDate)}
            required
            dateFormat="MM/DD/YYYY"
            timeFormat={false}
            inputProps={{ placeholder: 'MM/DD/YYYY' }}
            onChange={(currentDate) =>
              handleChange(null, {
                name: 'tradeDate',
                value: Datetime.moment(currentDate._d).format('YYYY-MM-DD'),
              })
            }
            isValidDate={(current) => betweenYesterdayAndToday(current)}
            closeOnSelect
          />
          <Form.Field
            control={Datetime}
            className="ui input datetime"
            label="Execution Time"
            name="executionTime"
            id="execution-time"
            defaultValue={Datetime.moment(data.executionTime)}
            required
            inputProps={{ placeholder: 'MM/DD/YYYY HH:MI AM' }}
            onChange={(currentDate) =>
              handleChange(null, {
                name: 'executionTime',
                value: Datetime.moment(currentDate._d).format(),
              })
            }
            isValidDate={(current) =>
              validExecutionTime(data.tradeDate, current)
            }
            closeOnSelect
          />
        </Form.Group>
        <ProductContractSelection
          contractName="contractSymbol"
          showDetails
          onChange={handleChange}
          required
        />
        <Form.Group>
          <FloatInputContainer
            required
            label="Quantity"
            name="qty"
            id="quantity"
            placeholder="0.000"
            onChange={handleChange}
            value={data.qty}
          />
          <FloatInputContainer
            required
            label="Price"
            name="px"
            id="price"
            placeholder="0.000"
            onChange={handleChange}
            value={data.px}
          />
          <Form.Dropdown
            label="Trade Type"
            required
            name="trdType"
            id="trd-type"
            search
            selection
            placeholder="Trade type"
            options={[
              { text: 'Regular', value: 'REGULAR' },
              { text: 'Block', value: 'BLOCK' },
              { text: 'Correction', value: 'CORRECTION' },
              { text: 'ADP', value: 'ADP' },
            ]}
            onChange={handleChange}
            value={data.trdType}
            clearable
          />
        </Form.Group>
        <Segment.Group horizontal>
          <Segment>
            <Header as="h1">Buy Side</Header>
            <div className="flex-row space-between flex-wrap">
              <div className="margin-bottom-10">
                <AccountSelectionContainer
                  required
                  label="Account"
                  name="buy/accountId"
                  id="buy-account-id"
                  onChange={handleChange}
                />
              </div>
              <div style={{ flexBasis: '45%' }}>
                <Form.Field
                  control={Select}
                  clearable
                  label={'CGM'}
                  name="buy/cgm"
                  id="buy-cgm-id"
                  onChange={handleChange}
                  options={tradeEntryCgmsBuySide}
                />
              </div>
            </div>
            <Form.Input
              label="Fee Type"
              readOnly
              name="buy/feeType"
              id="buy-fee-type"
              loading={contractsLoading}
              required
              value={data.buySide.feeType}
            />
            <Form.Group widths="4">
              <FloatInputContainer
                fluid
                label="Clearing Fee"
                name="buy/clearingFee"
                id="buy-clearing-fee"
                placeholder="0.000"
                onChange={handleChange}
                required
                value={data.buySide.clearingFee}
              />
              <FloatInputContainer
                fluid
                label="Exchange Fee"
                name="buy/exchangeFee"
                id="buy-exchange-fee"
                placeholder="0.000"
                onChange={handleChange}
                required
                value={data.buySide.exchangeFee}
              />
              <Form.Input
                fluid
                label="Client Order ID"
                name="buy/clOrdId"
                id="buy-client-order-id"
                onChange={handleChange}
                value={data.buySide.clientOrderId}
              />
              <Form.Input
                fluid
                label="Customer Account Ref"
                name="buy/customerAccountRef"
                id="buy-customer-account-ref"
                onChange={handleChange}
                value={data.buySide.customerAccountRef}
              />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as="h1">Sell Side</Header>
            <div className="flex-row space-between flex-wrap">
              <div className="margin-bottom-10">
                <AccountSelectionContainer
                  required
                  label="Account"
                  name="sell/accountId"
                  id="sell-account-id"
                  onChange={handleChange}
                />
              </div>
              <div style={{ flexBasis: '45%' }}>
                <Form.Field
                  control={Select}
                  clearable
                  label={'CGM'}
                  name="sell/cgm"
                  id="sell-cgm-id"
                  onChange={handleChange}
                  options={tradeEntryCgmsSellSide}
                />
              </div>
            </div>
            <Form.Input
              label="Fee Type"
              readOnly
              name="sell/feeType"
              id="sell-fee-type"
              loading={contractsLoading}
              required
              onChange={handleChange}
              value={data.sellSide.feeType}
            />
            <Form.Group widths="4">
              <FloatInputContainer
                fluid
                label="Clearing Fee"
                name="sell/clearingFee"
                id="sell-clearing-fee"
                placeholder="0.000"
                onChange={handleChange}
                required
                value={data.sellSide.clearingFee}
              />
              <FloatInputContainer
                fluid
                label="Exchange Fee"
                name="sell/exchangeFee"
                id="sell-exchange-fee"
                placeholder="0.000"
                onChange={handleChange}
                required
                value={data.sellSide.exchangeFee}
              />
              <Form.Input
                fluid
                label="Client Order ID"
                name="sell/clOrdId"
                id="sell-client-order-id"
                onChange={handleChange}
                value={data.sellSide.clientOrderId}
              />
              <Form.Input
                fluid
                label="Customer Account Ref"
                name="sell/customerAccountRef"
                id="sell-customer-account-ref"
                onChange={handleChange}
                value={data.sellSide.customerAccountRef}
              />
            </Form.Group>
          </Segment>
        </Segment.Group>
        <Segment color="red">
          <Header as="h3">Post To Exchange</Header>
          <p>
            This option is{' '}
            <span className="bold italic">extremely dangerous</span>. Only
            disable it if you are sure that you do not need risk checking.
          </p>
          <Form.Checkbox
            toggle
            checked={data.postToExchange}
            onClick={() => handlePostToExchangeChanged()}
            label={
              data.postToExchange
                ? 'Post to exchange'
                : 'Do not post to exchange'
            }
            name="postToExchange"
            id="post-to-exchange"
          />
        </Segment>
        <ButtonContainer>
          <Form.Button
            floated="right"
            type="submit"
            disabled={!validEnableButton(data)}
            onSubmit={handleSubmit}
          >
            Submit
          </Form.Button>
        </ButtonContainer>
      </Form>
    </Fragment>
  );
};

TradeEntry.propTypes = {
  contractsLoading: PropTypes.bool,
  contractOptions: PropTypes.node,
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ),
  futuresContractOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  spotProductOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  handlePostToExchangeChanged: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tradeEntryCgmsBuySide: PropTypes.array,
  tradeEntryCgmsSellSide: PropTypes.array,
};

TradeEntry.defaultProps = {
  contractsLoading: false,
  contractOptions: [],
  data: {},
  futuresContractOptions: [],
  spotProductOptions: [],
  tradeEntryCgmsBuySide: [],
  tradeEntryCgmsSellSide: [],
};

export default TradeEntry;
