import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { RootState } from '../ts/types/store';
import BlockTradeRequest from '../ts/models/BlockTrades/BlockTradeRequest.model';

/** Actions */
export const GET_BLOCK_TRADE_REQUESTS = 'GET_BLOCK_TRADE_REQUESTS';
export const GET_BLOCK_TRADE_REQUESTS_V2 = 'GET_BLOCK_TRADE_REQUESTS_V2';
export const APPROVE_BLOCK_TRADE = 'APPROVE_BLOCK_TRADE';
export const REJECT_BLOCK_TRADE = 'REJECT_BLOCK_TRADE';
/** Routines */
export const fetchBlockTradeRequests = createRoutine(GET_BLOCK_TRADE_REQUESTS);
export const approveBlockTrade = createRoutine(APPROVE_BLOCK_TRADE);
export const rejectBlockTrade = createRoutine(REJECT_BLOCK_TRADE);
export const fetchBlockTradeRequestsV2 = createRoutine(
  GET_BLOCK_TRADE_REQUESTS_V2,
);
/** Promisified Routines */
export const approveBlockTradePromise = promisifyRoutine(approveBlockTrade);
export const rejectBlockTradePromise = promisifyRoutine(rejectBlockTrade);
/** Initial State */
type BlockTradesRequestsState = {
  requests: BlockTradeRequest[];
};

const initialState: BlockTradesRequestsState = {
  requests: [],
};

/** Reducer */
export default handleActions<
  BlockTradesRequestsState,
  { blockTradeRequests: BlockTradeRequest[] }
>(
  {
    [fetchBlockTradeRequests.SUCCESS]: (state, { payload }) => ({
      ...state,
      requests: payload.blockTradeRequests,
    }),
    [fetchBlockTradeRequestsV2.SUCCESS]: (state, { payload }) => ({
      ...state,
      requests: payload.blockTradeRequests,
    }),
  },
  initialState,
);

/** Selectors */
export const getBlockTradesRequestState = (
  state: RootState,
): BlockTradesRequestsState =>
  get(state, 'newBlockTradeRequestsReducer', { requests: [] });

export const getBlockTradeRequests = createSelector(
  [getBlockTradesRequestState],
  (state) => get(state, 'requests', []),
);
