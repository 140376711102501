import React, { Fragment, useState } from 'react';
import {
  Modal,
  Header,
  Button,
  Divider,
  Segment,
  Form,
  Comment,
} from 'semantic-ui-react';

const ModalEditOnboardingNote = ({ hideModal, msg, updateNote, key }) => {
  const [value, setValue] = useState(msg);
  return (
    <Fragment>
      <Modal.Header>Edit Note</Modal.Header>
      <Modal.Content>
        <Segment basic textAlign="center">
          <div className="center-flex">
            <Comment.Avatar as="i" className="avatar icon user" />
            <Form className="onboarding-note">
              <Form.Group inline>
                <Form.TextArea
                  width="15"
                  id="comment"
                  name="comment"
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="Add a note..."
                  value={value}
                />
              </Form.Group>
            </Form>
          </div>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button id="Cancel" onClick={() => hideModal()}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          color="teal"
          id="confirm"
          onClick={() => updateNote(value, key)}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default ModalEditOnboardingNote;
