import _ from 'lodash';
import React, { useState } from 'react';
import { Comment, Form, Checkbox } from 'semantic-ui-react';
import { formatTimeLocal } from '../../utils/time';

const comment = (
  noteDetails,
  showModalEditNote,
  showModalArchiveNote,
  showArchived,
) =>
  !showArchived && noteDetails.hidden ? (
    <span></span>
  ) : (
    <Comment key={noteDetails.idx}>
      <Comment.Avatar as="i" className="avatar icon user" />
      <Comment.Content>
        <Comment.Author as="a">{noteDetails.email}</Comment.Author>
        <Comment.Metadata className="member-user-note-comments">
          <div>
            {noteDetails.editHistory.length === 0 ? (
              <span>Created at: {formatTimeLocal(noteDetails.time)}</span>
            ) : (
              <span>Updated at: {formatTimeLocal(noteDetails.time)}</span>
            )}
          </div>
          {noteDetails.hidden ? (
            <div className="float-right-with-margin">
              <span>Archived</span>
            </div>
          ) : (
            <div className="float-right-with-margin blue-action-color">
              <span
                className="pointer"
                onClick={() => showModalEditNote(noteDetails)}
              >
                Edit
              </span>
              <span className="margin-left-right-10">|</span>
              <span
                className="pointer"
                onClick={() => showModalArchiveNote(noteDetails.idx)}
              >
                Archive
              </span>
            </div>
          )}
        </Comment.Metadata>
        <Comment.Text className="margin-top-10">{noteDetails.msg}</Comment.Text>
      </Comment.Content>
      <hr className="member-user-note-separator" />
    </Comment>
  );

const MemberUsersNotes = ({
  addNote,
  data,
  showModalEditNote,
  showModalArchiveNote,
}) => {
  const [value, setValue] = useState('');
  const [showArchived, setShowArchived] = useState(false);

  const handleClick = (e) => {
    addNote(e, value);
    setValue('');
  };

  const comments = data?.map((noteDetails) =>
    comment(noteDetails, showModalEditNote, showModalArchiveNote, showArchived),
  );

  return (
    <Comment.Group className="max-width-100">
      <Form>
        <Form.Group inline>
          <Form.TextArea
            width="15"
            id="comment"
            name="comment"
            onChange={(e) => setValue(e.target.value)}
            placeholder="Add a note..."
            value={value}
          />
          <Form.Button
            width="3"
            size="large"
            color={'teal'}
            id="send-comment"
            onClick={() => handleClick(value)}
            disabled={!value}
            className="button-large-width"
            content="Send"
          >
            Save
          </Form.Button>
        </Form.Group>
      </Form>
      <hr className="member-user-note-separator" />
      <div className="min-height-25">
        <div className="float-right-with-margin grey-bold">
          <span className="margin-right-10">Show Archive</span>{' '}
          <Checkbox
            toggle
            onChange={() => setShowArchived(!showArchived)}
          ></Checkbox>
        </div>
      </div>
      {comments}
    </Comment.Group>
  );
};

export default MemberUsersNotes;
