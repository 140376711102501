import React from 'react';
import { Loader, List } from 'semantic-ui-react';
import { format } from 'erisxkit/client';
import PropTypes from 'prop-types';
import { formatBalances } from '../utils/methods';

const AssetTypeBalances = ({ balances, loading }) => {
  if (loading) {
    return <Loader active inline="left" />;
  } else if (Object.keys(balances).length) {
    return (
      <List horizontal relaxed="very">
        {formatBalances(balances).map((balance) => (
          <List.Item key={balance.assetType}>
            <i className={`${balance.assetType} icon-md`} />
            <List.Content verticalAlign="top">
              <List.Header>{balance.assetType}</List.Header>
              <span className="mono">
                {(balance.value &&
                  format(balance.value, {
                    type: balance.assetType === 'usd' ? 'currency' : 'crypto',
                    currency: 'usd',
                  })) ||
                  '-'}
              </span>
            </List.Content>
          </List.Item>
        ))}
      </List>
    );
  }

  return <span className="italic">(No balances found.)</span>;
};

AssetTypeBalances.propTypes = {
  balances: PropTypes.objectOf(PropTypes.string),
  loading: PropTypes.bool,
};

AssetTypeBalances.defaultProps = {
  balances: {},
  loading: false,
};

export default AssetTypeBalances;
