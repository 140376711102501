import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createLoadingSelector, showModal } from 'erisxkit/client';
import SubExchange from '../../common/components/SubExchange';
import { CREATE_SUB_EXCHANGE } from '../../constants/modalTypes';
import {
  fetchSubExchanges,
  getSubExchanges,
  getSubExchangesCount,
} from '../../reducers/subExchangesReducer';
import subExchangesMetadata from '../../metadata/subExchangesMetadata';

const mapDispatchToProps = {
  showModal,
  fetchSubExchanges,
};

const mapStateToProps = (state) => ({
  subExchangesList: getSubExchanges(state),
  subExchangesCount: getSubExchangesCount(state),
  subExchangesLoading: createLoadingSelector(['SUB_EXCHANGES'])(state),
});

class SubExchangeContainer extends Component {
  componentDidMount() {
    this.props.fetchSubExchanges();
  }

  onClickCreate = () => {
    this.props.showModal(CREATE_SUB_EXCHANGE);
  };

  render() {
    const { subExchangesCount, subExchangesList, subExchangesLoading } =
      this.props;
    return (
      <SubExchange
        createSubExchange={this.onClickCreate}
        subExchangesCount={subExchangesCount}
        subExchangesList={subExchangesList}
        subExchangesLoading={subExchangesLoading}
        metadata={subExchangesMetadata(
          this.props.showModal,
          CREATE_SUB_EXCHANGE,
          {
            type: 'update',
          },
        )}
      />
    );
  }
}

SubExchangeContainer.propTypes = {
  fetchSubExchanges: PropTypes.func,
  showModal: PropTypes.func,
  subExchangesCount: PropTypes.number,
  subExchangesList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  subExchangesLoading: PropTypes.bool,
};

SubExchangeContainer.defaultProps = {
  fetchSubExchanges: () => {},
  showModal: () => {},
  subExchangesCount: 0,
  subExchangesList: [],
  subExchangesLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubExchangeContainer);
