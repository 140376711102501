import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fetchSettlementInstructions,
  confirmSettlement,
  rejectSettlement,
} from '../reducers/settlementReducer';

export default function* watchSettlement() {
  yield takeLatest(
    fetchSettlementInstructions.TRIGGER,
    createSaga(
      fetchSettlementInstructions,
      fetchSettlementInstructions._PREFIX,
    ),
  );
  yield takeLatest(
    confirmSettlement.TRIGGER,
    createSaga(confirmSettlement, confirmSettlement._PREFIX),
  );
  yield takeLatest(
    rejectSettlement.TRIGGER,
    createSaga(rejectSettlement, rejectSettlement._PREFIX),
  );
}
