import React, { useEffect } from 'react';
import { get } from 'lodash';
import { XTable } from 'erisxkit/client';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DetailsHeader from '../../common/components/DetailsHeader';
import achTransactionsMetadata from '../../metadata/achTransactionsMetadata';
import {
  fetchRequestHistory,
  getRequestHistory,
} from '../../reducers/accountHistoryReducer';
import {
  fetchDepositLimit,
  fetchWithdrawalLimit,
  getLimits,
  DEPOSIT_LIMIT,
  WITHDRAWAL_LIMIT,
} from '../../reducers/achReducer';
import { formatUSD } from '../../utils/methods';

export default ({ selectedAccount }) => {
  const dispatch = useDispatch();
  const history = useSelector(getRequestHistory);
  const limits = useSelector(getLimits);
  useEffect(() => {
    dispatch(
      fetchWithdrawalLimit({
        accountId: selectedAccount.accountId,
        assetType: 'USD',
      }),
    );
    dispatch(fetchDepositLimit({ accountId: selectedAccount.accountId }));
    dispatch(
      fetchRequestHistory({
        payMethod: 'ACH',
        beginTime: moment().subtract('30', 'days'),
        accountIds: selectedAccount.accountId,
      }),
    );
  }, []);
  return (
    <div>
      <DetailsHeader
        item={{
          maxDeposit: formatUSD(get(limits, [DEPOSIT_LIMIT, 'limit'], '0')),
          availableForDeposit: formatUSD(
            get(limits, [DEPOSIT_LIMIT, 'available'], '0'),
          ),
          maxWithdrawal: formatUSD(
            get(limits, [WITHDRAWAL_LIMIT, 'balance'], '0'),
          ),
          availableForWithdrawal: formatUSD(
            get(limits, [WITHDRAWAL_LIMIT, 'available'], '0'),
          ),
        }}
      />
      <XTable
        columns={achTransactionsMetadata}
        data={history}
        title="ach-tx"
        minRows={5}
      />
    </div>
  );
};
