import React from 'react';
import _ from 'lodash';
import { Dropdown, Label } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';
import { iconColorForState } from '../utils/methods';

const bankAccounts = (remove, edit) => [
  {
    Header: 'Label',
    id: 'label',
    accessor: (row) =>
      _.get(row, 'label', <span className="italic">(No label)</span>),
  },
  {
    id: 'bankName',
    Header: 'Bank Name',
    accessor: 'bankName',
  },
  {
    Header: 'Account Number',
    id: 'accountNumber',
    accessor: (row) => row.accountNumber,
  },
  {
    Header: 'Routing Number',
    id: 'routingNumber',
    accessor: (row) => row.routingNumber,
  },
  {
    Header: 'Additional SSI',
    id: 'additionalSsi',
    accessor: (row) => _.get(row, 'additionalSsi', '-'),
    minWidth: 200,
  },
  {
    Header: 'Date Added',
    id: 'createdAt',
    accessor: (row) => formatTimeLocal(row.createdAt),
  },
  {
    Header: 'Is Plaid',
    id: 'isPlaidAccount',
    accessor: (row) => `${_.get(row, 'isPlaidAccount', 'false')}`,
  },
  {
    Header: 'Status',
    id: 'state',
    accessor: (d) => (
      <Label color={iconColorForState(d.state)} content={d.state} />
    ),
    className: 'pull-right',
    headerClassName: 'pull-right',
    resizable: false,
  },
  {
    Header: '',
    id: 'more',
    accessor: (d) => (
      <Dropdown icon="ellipsis vertical" pointing="top right">
        <Dropdown.Menu>
          <Dropdown.Item
            key="edit"
            text="Edit SSI"
            onClick={() => edit(d)}
            icon="pencil"
          />
          <Dropdown.Item
            key="remove"
            text="Delete SSI"
            onClick={() => remove(d)}
            icon="remove"
          />
        </Dropdown.Menu>
      </Dropdown>
    ),
    filterable: false,
    resizable: false,
    width: 35,
  },
];

export default bankAccounts;
