import _ from 'lodash';
import { combineReducers } from 'redux';
import { scaleBaseToMinUnit } from '../actions/utilActions';

//* Utility Selectors */
export const getAmountMinUnit = (state) =>
  _.get(state, ['util', 'scaleBaseToMinUnit', 'amountMinUnit']);

//* Utility Reducers */
const scaleBaseToMinUnitReducer = (state = {}, action) => {
  switch (action.type) {
    case scaleBaseToMinUnit.SUCCESS:
      return {
        ...state,
        amountMinUnit: action.payload,
      };
    case scaleBaseToMinUnit.REQUEST:
      return {
        ...state,
        amountMinUnit: undefined, // Make sure any old/previous value is cleared out.
      };
    default:
      return state;
  }
};

export default combineReducers({
  scaleBaseToMinUnit: scaleBaseToMinUnitReducer,
});
