const postingTypes = {
  feeRebate: [
    {
      value: 'clearing_fee',
      text: 'Clearing Fee',
    },
    {
      value: 'exchange_fee',
      text: 'Exchange Fee',
    },
    {
      value: 'income_fee',
      text: 'Income Fee',
    },
    {
      value: 'delivery_fee',
      text: 'Delivery Fee',
    },
  ],
  generalJournal: [
    {
      value: 'amount',
      text: 'Asset Transfer',
    },
    {
      value: 'exchange_fee',
      text: 'Exchange Fee',
    },
    {
      value: 'clearing_fee',
      text: 'Clearing Fee',
    },
    {
      value: 'expense_fee',
      text: 'Expense Fee',
    },
    {
      value: 'income_fee',
      text: 'Income Fee',
    },
  ],
};

export default postingTypes;
