import { connect } from 'react-redux';
import {
  createLoadingSelector,
  filteredArrayToAttrValue,
  sortedArrayToAttrValue,
} from 'erisxkit/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cryptoTransactionAnalyses } from '../../actions/surveillanceActions';
import {
  getCryptoTransactionAnalyses,
  getCryptoTransactionAnalysesCount,
} from '../../reducers/surveillanceReducer';
import Surveillance from '../../components/Surveillance';
import { getAssetTypesAsOptions } from '../../selectors';

const mapStateToProps = (state) => ({
  cryptoTransactionAnalysesList: getCryptoTransactionAnalyses(state),
  cryptoTransactionAnalysesCount: getCryptoTransactionAnalysesCount(state),
  cryptoTransactionAnalysesLoading: createLoadingSelector([
    'CRYPTO_TRANSACTION_ANALYSES',
  ])(state),
  assetTypes: getAssetTypesAsOptions(state),
});

const mapDispatchToProps = {
  cryptoTransactionAnalyses,
};

class TransactionSurveillanceContainer extends Component {
  fetchData = (state) => {
    let filter = [];
    let sort = [];
    if (state.filtered.length !== 0) {
      filter = filteredArrayToAttrValue(state.filtered);
    }
    if (state.sorted.length !== 0) {
      sort = sortedArrayToAttrValue(state.sorted);
    }
    this.props.cryptoTransactionAnalyses({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      filter,
      sort,
    });
  };

  render = () => (
    <Surveillance
      data={this.props.cryptoTransactionAnalysesList}
      count={this.props.cryptoTransactionAnalysesCount}
      loading={this.props.cryptoTransactionAnalysesLoading}
      onFetchData={this.fetchData}
      assetTypes={this.props.assetTypes}
      type="Transaction"
    />
  );
}

TransactionSurveillanceContainer.propTypes = {
  cryptoTransactionAnalysesList: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      assetType: PropTypes.string,
      transaction: PropTypes.string,
      riskScore: PropTypes.string,
      rawData: PropTypes.string,
      updatedAt: PropTypes.string,
      userIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  cryptoTransactionAnalysesCount: PropTypes.number.isRequired,
  cryptoTransactionAnalysesLoading: PropTypes.bool.isRequired,
  cryptoTransactionAnalyses: PropTypes.func.isRequired,
};

TransactionSurveillanceContainer.defaultProps = {
  cryptoTransactionAnalysesList: [],
  cryptoTransactionAnalysesCount: 0,
  cryptoTransactionAnalysesLoading: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionSurveillanceContainer);
