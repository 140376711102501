import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { get, isEqual } from 'lodash';
import ManualOnboarding from '../components/ManualOnboarding';
import {
  selectUser,
  getAccountsForSelectedUser,
  getSelectedUser,
  fetchUsers,
  getClearingAccountIdsForSelectedUser,
} from '../reducers/usersReducer';
import {
  createAndLinkClearingAccount,
  createEmarketOrganization,
  createEmarketAccount,
  createTradeCxUser,
  routeCxUserThroughCQG,
  createCqgCustomer,
  createCqgTrader,
  createCqgAccount,
  addRequiredCqgServicesToCqgTrader,
  linkCqgTraderToCqgCustomer,
  addCqgTraderToCqgAccount,
  addCxTraderIdToCqgTrader,
  sendCqgWelcomeEmail,
  getCqgCustomerId,
  getCqgTraderId,
  getCqgAccountId,
} from '../reducers/manualOnboardingReducer';
import { fetchAccounts } from '../actions/accountsActions';
import { createAccountsSelector } from '../reducers/accountsReducer';
import * as accountTypes from '../constants/accountTypes';

// TODO: get info for currently selected user
//      - retrieve from URL param :userId
const mapStateToProps = (state) => ({
  selectedUser: getSelectedUser(state),
  accounts: getAccountsForSelectedUser(state),
  accountList: createAccountsSelector([accountTypes.OWNERSHIP])(state),
  accountsLoading: createLoadingSelector(['ACCOUNTS'])(state),
  cqgCustomerId: getCqgCustomerId(state),
  cqgTraderId: getCqgTraderId(state),
  cqgAccountId: getCqgAccountId(state),
  clearingAccountIds: getClearingAccountIdsForSelectedUser(state),
});

const mapDispatchToProps = {
  selectUser,
  fetchAccounts,
  fetchUsers,
  createAndLinkClearingAccount,
  createEmarketOrganization,
  createEmarketAccount,
  createTradeCxUser,
  routeCxUserThroughCQG,
  createCqgCustomer,
  createCqgTrader,
  createCqgAccount,
  addRequiredCqgServicesToCqgTrader,
  linkCqgTraderToCqgCustomer,
  addCqgTraderToCqgAccount,
  addCxTraderIdToCqgTrader,
  sendCqgWelcomeEmail,
};

class ManualOnboardingContainer extends Component {
  state = {
    cancelOnDisconnect: false,
  };

  componentDidMount = () => {
    const { userId } = get(this.props, ['match', 'params'], '');
    const { clearingAccountIds = [] } = this.props;
    // Handle case where operator directly copy-pastes a userId and
    //  wants to manually onboard via URL
    this.props.fetchUsers({
      filter: [{ attr: 'user_id', op: 'eq', value: userId }],
    });
    this.props.selectUser(userId);
    if (clearingAccountIds.length) {
      this.props.fetchAccounts({ accountIds: this.props.clearingAccountIds });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const { clearingAccountIds = [] } = nextProps;
    if (
      clearingAccountIds.length &&
      !isEqual(nextProps.clearingAccountIds, this.props.clearingAccountIds)
    ) {
      this.props.fetchAccounts({ accountIds: clearingAccountIds });
    }
  };

  handleChange = (e, { name, value, checked }) => {
    if (name === 'cancelOnDisconnect') {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  render = () => (
    <ManualOnboarding
      {...this.props}
      {...this.state}
      handleChange={this.handleChange}
      selectedAccountId={this.state.accountId}
      selectedCxAccountId={this.state.emarketAccountNumber}
      selectedCxTradingId={this.state.emarketTradingId}
      cqgCustomerId={this.state.cqgCustomerId || this.props.cqgCustomerId}
      cqgTraderId={this.state.cqgTraderId || this.props.cqgTraderId}
      cqgAccountId={this.state.cqgAccountId || this.props.cqgAccountId}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualOnboardingContainer);
