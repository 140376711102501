import { connect } from 'react-redux';
import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import { Segment, Form } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import { createLoadingSelector } from 'erisxkit/client';
import ExternalTable from '../../common/table/ExternalTable';
import {
  fetchHistory,
  fetchCurrenexBalances,
} from '../../actions/accountsActions';
import requestLogMetadata from '../../metadata/requestLogMetadata';
import {
  getRequestHistory,
  getRequestHistoryCount,
  fetchRequestHistory,
} from '../../reducers/accountHistoryReducer';
import { getMemberCollection } from '../../reducers/membersReducer';
import { getAssetTypesAsOptions } from '../../selectors';
import { assetTypes } from '../../actions/utilActions';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';
import MemberSelectionContainer from '../../common/containers/MemberSelectionContainer';

const mapStateToProps = (state) => ({
  memberList: getMemberCollection(state),
  requestHistory: getRequestHistory(state),
  requestHistoryCount: getRequestHistoryCount(state),
  requestHistoryLoading: createLoadingSelector(['REQUEST_HISTORY'])(state),
  assetTypeOptions: getAssetTypesAsOptions(state),
  categoryTypeOptions: [
    {
      key: 'deposit',
      value: 'deposit',
      text: 'Deposit',
    },
    {
      key: 'withdrawal',
      value: 'withdrawal',
      text: 'Withdrawal',
    },
    {
      key: 'achDeposit',
      value: 'achDeposit',
      text: 'ACH Deposit',
    },
    {
      key: 'achWithdrawal',
      value: 'achWithdrawal',
      text: 'ACH Withdrawal',
    },
  ],
});

const mapDispatchToProps = {
  fetchHistory,
  fetchCurrenexBalances,
  fetchRequestHistory,
  assetTypes,
};

class RequestLogContainer extends Component {
  state = { filtered: [{ id: 'state', value: this.props.state }] };
  componentDidMount = () => {
    this.props.assetTypes();
  };
  fetchData = (state) => {
    let filters = {};
    if (state.filtered.length !== 0) {
      filters = state.filtered.reduce((obj, item) => {
        if (item.id === 'time') {
          return item.value;
        }
        if (item.id === 'category') {
          if (item.value == 'achDeposit') {
            obj.transactionType = 'deposit';
            obj.payMethod = 'ACH';
          } else if (item.value == 'achWithdrawal') {
            obj.transactionType = 'withdrawal';
            obj.payMethod = 'ACH';
          } else {
            obj.transactionType = item.value;
          }
        } else {
          obj[item.id] = item.value;
        }
        return obj;
      }, {});
    }
    this.props.fetchRequestHistory({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      ...filters,
    });
  };

  filterColumn = (id, value) => {
    const newfilter = {
      id,
      value,
    };
    const filtersWithoutNew = this.state.filtered.filter(
      (item) => item.id !== id,
    );
    this.setState({
      filtered: [...filtersWithoutNew, value && newfilter],
    });
  };

  render = () => {
    const {
      requestHistory,
      requestHistoryCount,
      requestHistoryLoading,
      assetTypeOptions,
      categoryTypeOptions,
      clearingMemberOptions,
    } = this.props;
    return (
      <Fragment>
        <Segment>
          <h3>Filter By:</h3>
          <Form>
            <Form.Group>
              <Form.Field
                control={Datetime}
                className="ui input datetime"
                label="Begin Date/Time"
                name="beginTime"
                id="beginTime"
                defaultValue=""
                dateFormat="YYYY-MM-DD"
                timeFormat
                inputProps={{ placeholder: 'Filter Begin Time' }}
                onChange={(time) => this.filterColumn('beginTime', time)}
                closeOnSelect
              />
              <Form.Field
                control={Datetime}
                className="ui input datetime"
                label="End Date/Time"
                name="endTime"
                id="endTime"
                defaultValue=""
                dateFormat="YYYY-MM-DD"
                timeFormat
                inputProps={{ placeholder: 'Filter End Time' }}
                onChange={(time) => this.filterColumn('endTime', time)}
                closeOnSelect
              />
              {/* Commenting until backend has support */}
              {/* <Form.Field placeholder="Start Typing the Account Label..." control={Input} name="label" label="Account Label" onChange={(e, { name, value }) => this.filterColumn(name, value)} /> */}
              <Form.Dropdown
                label="Asset Type"
                placeholder="Asset type"
                className="pull-right"
                search
                selection
                clearable
                options={assetTypeOptions}
                onChange={(event, { value }) =>
                  this.filterColumn('assetType', value)
                }
                value={get(
                  this.state.filtered.find((val) => val.id === 'assetType'),
                  'value',
                  undefined,
                )}
              />
              <Form.Dropdown
                label="Category"
                placeholder="Category type"
                className="pull-right"
                search
                selection
                clearable
                options={categoryTypeOptions}
                onChange={(event, { value }) =>
                  this.filterColumn('category', value)
                }
                value={get(
                  this.state.filtered.find((val) => val.id === 'category'),
                  'value',
                  undefined,
                )}
              />
              <MemberSelectionContainer
                label="Clearing Member"
                onChange={(event, { value }) => {
                  const member = this.props.memberList[value] || {};
                  this.filterColumn('account_ids', member.ledgerAccounts || '');
                }}
              />
              <AccountSelectionContainer
                label="Account"
                onChange={(event, { value }) =>
                  this.filterColumn('account_id', value)
                }
              />
            </Form.Group>
          </Form>
        </Segment>
        <ExternalTable
          title="requestHistory"
          data={requestHistory}
          metadata={requestLogMetadata}
          onFetchData={this.fetchData}
          count={requestHistoryCount}
          loading={requestHistoryLoading}
          noDataText={<span className="italic">No requests were found.</span>}
          pageSize={100}
          pages={Math.ceil(requestHistoryCount / 100)}
          filtered={this.state.filtered}
        />
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestLogContainer);
