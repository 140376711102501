import _ from 'lodash';
import { arrayToObject } from 'erisxkit/client';

const initialState = {
  count: 0,
  byId: {},
};

// TODO : probably deprecate these at some point
export const TRADES_REQUEST = 'TRADES_REQUEST';
export const TRADES_SUCCESS = 'TRADES_SUCCESS';
export const TRADES_FAILED = 'TRADES_FAILED';

export const TRADE_JOURNALS_REQUEST = 'TRADE_JOURNALS_REQUEST';
export const TRADE_JOURNALS_SUCCESS = 'TRADE_JOURNALS_SUCCESS';
export const TRADE_JOURNALS_FAILED = 'TRADE_JOURNALS_FAILED';

export default function tradesReducer(state = initialState, action) {
  switch (action.type) {
    // TODO deprecate "trades" at some point
    case TRADES_SUCCESS: {
      return {
        ...state,
        count: action.payload.count,
        byId: arrayToObject(action.payload.trades, 'uuid'),
      };
    }
    case TRADE_JOURNALS_SUCCESS: {
      return {
        ...state,
        count: action.payload.count,
        byId: arrayToObject(action.payload.tradeJournals, 'uuid'),
      };
    }
    default:
      return state;
  }
}

export const fetchTrades = (payload) => ({
  type: TRADE_JOURNALS_REQUEST,
  payload,
});

/* Selectors */
const getTrades = (state) => _.get(state, ['trades', 'byId'], {});

export const getTradesList = (state) => Object.values(getTrades(state));
export const getTradesCount = (state) => _.get(state, ['trades', 'count'], 0);
