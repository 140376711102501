import _ from 'lodash';
import React from 'react';
import { Segment, Statistic } from 'semantic-ui-react';
import classnames from 'classnames';

const ReconciliationStats = ({ isMatchedEnd, getDifferenceEnd = '', data }) => (
  <Segment basic className="statistics">
    <Statistic.Group size="tiny">
      <Statistic>
        <Statistic.Label className="text-left">
          External Balance
        </Statistic.Label>
        <Statistic.Value>
          {_.get(data, 'endExternalBalance', '').toString()}
        </Statistic.Value>
      </Statistic>

      <Statistic>
        <Statistic.Label className="text-left">
          Internal Balance
        </Statistic.Label>
        <Statistic.Value>
          {_.get(data, 'endInternalBalance', '').toString()}
        </Statistic.Value>
      </Statistic>

      <Statistic>
        <Statistic.Label className="text-left">Difference</Statistic.Label>
        <Statistic.Value
          className={classnames(isMatchedEnd ? 'matched' : 'unmatched')}
        >
          {getDifferenceEnd.toString()}
        </Statistic.Value>
      </Statistic>
    </Statistic.Group>
  </Segment>
);

export default ReconciliationStats;
