import * as actionTypes from '../constants/actionTypes';

/**
 * Action generator for requesting the reconciliation history for an account.
 * @param {string} account - The account name that reconciliation is being requested for.
 */
export const reconcile = (selectedReconciliation) => ({
  type: actionTypes.RECONCILIATION_REQUEST,
  ...selectedReconciliation,
});

/**
 * Action generator for selecting a transaction from the reconciliation page.
 * @param {number} index - The index of the transaction in the 'entries' array.
 */
export const selectTransaction = (index) => ({
  type: actionTypes.SELECT_TRANSACTION,
  payload: { index },
});

/**
 * Action generator for selecting an account from the reconciliation index page.
 * @param {number} accountId - The accountId.
 */
export const selectAccountReconciliation = (accountId) => ({
  type: actionTypes.SELECT_ACCOUNT_RECONCILIATION,
  accountId,
});

/**
 * Action generator for selecting an reconciliation from the reconciliation history page.
 * @param {Object} reconciliation - The reconciliation UUID and state.
 */
export const selectReconciliation = (reconciliation) => ({
  type: actionTypes.SELECT_RECONCILIATION,
  ...reconciliation,
});

/**
 * Action generator for creating a new withdrawl.
 * @param {Object} data - An object with keys that map to what the rpc method expects but in camelCase.
 */
export const createWithdrawal = (data) => ({
  type: actionTypes.CREATE_WITHDRAWAL_REQUEST,
  payload: { ...data },
});

/**
 * Action generator for creating a new deposit.
 * @param {Object} data - An object with keys that map to what the rpc method expects but in camelCase.
 */
export const createDeposit = (data) => ({
  type: actionTypes.CREATE_DEPOSIT_REQUEST,
  payload: { ...data },
});

/**
 * Action generator for creating a new deposit.
 * @param {Object} data - An object with keys that map to what the rpc method expects but in camelCase.
 */
export const createReversal = (data) => ({
  type: actionTypes.CREATE_REVERSAL_REQUEST,
  payload: data,
});

/**
 * Action generator for creating a new internal transfer.
 * @param {Object} data - An object with keys that map to what the rpc method expects but in camelCase.
 */
export const createTransfer = (data) => ({
  type: actionTypes.CREATE_TRANSFER_REQUEST,
  payload: { ...data },
});

/**
 * Action generator for fetching reconciliation history.
 * @param {string} account - The account name that reconciliation is being requested for.
 */
export const fetchReconciliationHistory = (accountId) => ({
  type: actionTypes.RECONCILIATION_HISTORY_REQUEST,
  accountId,
});

/**
 * Action generator for creating a reconciliation.
 * @param {Object} data - the reconciliation object
 */
export const createReconciliation = (data) => ({
  type: actionTypes.CREATE_RECONCILIATION_REQUEST,
  payload: data,
});

/**
 * Action generator for update a reconciliation entry.
 * @param {Object} payload - the uuid and args you want to update
 */
export const updateReconciliationEntryDetails = (payload) => ({
  type: actionTypes.UPDATE_RECONCILIATION_ENTRY_DETAILS_REQUEST,
  payload,
});
