import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Icon } from 'semantic-ui-react';
import { XTable, showModal, createLoadingSelector } from 'erisxkit/client';
import history from '../../constants/history';
import * as accountsActions from '../../actions/accountsActions';
import * as accountTypes from '../../constants/accountTypes';
import { CREATE_ACCOUNT } from '../../constants/modalTypes';
import { selectAccountReconciliation } from '../../actions/reconciliationActions';
import { createAccountsSelector } from '../../reducers/accountsReducer';
import metadata from '../../metadata/custodianAccountMetadata';
import { assetTypes } from '../../actions/utilActions';
import { getAssetTypesAsOptions } from '../../selectors';

const mapStateToProps = (state) => ({
  accountList: createAccountsSelector([
    accountTypes.CUSTODIAN,
    accountTypes.HOUSE,
  ])(state),
  assetTypeOptions: getAssetTypesAsOptions(state),
  accountsLoading: createLoadingSelector(['ACCOUNTS'])(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...accountsActions,
      showModal,
      selectAccountReconciliation,
      assetTypes,
    },
    dispatch,
  );

class FinanceAccountIndexContainer extends Component {
  componentDidMount() {
    // fetch the data
    const { fetchAccounts, assetTypes } = this.props;
    assetTypes();
    fetchAccounts({ categories: [accountTypes.FINANCE] });
  }
  selectAccount = (id) => {
    this.props.selectAccountReconciliation(id);
    this.props.selectAccountHistory(id);
    history.push(`/finance_accounts/${id}`);
  };
  addAccount = () => {
    this.props.showModal(CREATE_ACCOUNT, { type: 'finance' });
  };
  render() {
    const { accountList, assetTypeOptions, accountsLoading } = this.props;
    return (
      <div className="vert-flex">
        <div className="flex-row space-between">
          <Header dividing floated="left" as="h1">
            Finance Accounts
          </Header>
          <Button
            onClick={this.addAccount}
            className="add-button"
            size="small"
            floated="right"
          >
            <Icon name="add" /> Create Finance Account
          </Button>
        </div>
        <XTable
          title="financeAccountIndex"
          noDataText="No accounts were found."
          minRows={5}
          data={accountList}
          showPageSizeOptions
          showPagination
          className="-striped -highlight"
          columns={metadata(assetTypeOptions)}
          filterable
          loading={accountsLoading}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id])
              .toUpperCase()
              .includes(filter.value.toUpperCase())
          }
          getTrProps={(state, rowInfo) => ({
            onClick: (e) => {
              this.selectAccount(rowInfo.original.accountId);
            },
            className: 'pointer',
          })}
        />
      </div>
    );
  }
}

FinanceAccountIndexContainer.propTypes = {
  fetchAccounts: PropTypes.func.isRequired,
  accountList: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinanceAccountIndexContainer);
