import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { errorReducer, loadingReducer, modalReducer } from 'erisxkit/client';
import { flatten, cloneDeep, set } from 'lodash';
import reconciliationReducer from './reconciliationReducer';
import upcomingDepositsReducer from './upcomingDepositsReducer';
import accountsReducer from './accountsReducer';
import accountHistoryReducer from './accountHistoryReducer';
import authReducer from './authReducer';
import assetTypesReducer from './assetTypesReducer';
import unusedWalletsReducer from './unusedWalletsReducer';
import usersReducer from './usersReducer';
import sidebarReducer from './sidebarReducer';
import reconciliationHistoryReducer from './reconciliationHistoryReducer';
import approvalReqsReducer from './approvalReqsReducer';
import systemAlertsReducer from './systemAlertsReducer';
import feeRebateReducer from './feeRebateReducer';
import tradesReducer from './tradesReducer';
import manualOnboardingReducer from './manualOnboardingReducer';
import coreFilesReducer from './coreFilesReducer';
import manualEntriesReducer from './manualEntriesReducer';
import aboutReducer from './aboutReducer';
import linkedAccountsReducer from './linkedAccountsReducer';
import linkedMemberAssetAccountsReducer from './linkedMemberAssetAccountsReducer';
import tradeCaptureReportsReducer from './tradeCaptureReportsReducer';
import taskLogReducer from './taskLogReducer';
import balancesReducer from './balancesReducer';
import surveillanceReducer from './surveillanceReducer';
import spotProductsReducer from './spotProductsReducer';
import membersReducer from './membersReducer';
import collateralReducer from './collateralReducer';
import envReducer from './envReducer';
import contractsReducer from './contractsReducer';
import futuresProductReducer from './futuresProductReducer';
import subExchangesReducer from './subExchangesReducer';
import journalsReducer from './journalsReducer';
import ledgersReducer from './ledgersReducer';
import apiCredentialsReducer from './apiCredentialsReducer';
import blockTradeRequestsReducer from './blockTradesReducer';
import utilReducer from './utilReducer';
import history from '../constants/history';
import configurationReducer from './configurationReducer';
import achReducer from './achReducer';
import riskReducer from './riskReducer';
import feesReducer from './feesReducer';
import referralCodeReducer from './referralCodeReducer';
import employeesReducer from './employeesReducer';
import iraReducer from './iraReducer';
import clientTransferReducer from './clientTransferReducer';
import settlementReducer from './settlementReducer';
import marginRatesReducer from './marginRatesReducer';
import authorizationIpReducer from './authorizationIpReducer';
import fcmReports from './fcmReportsReducer';
import initialMargins from './initialMarginReducer';
import riskConfigurations from './riskConfigurationsReducer';
import travelRule from './travelRuleReducer';
import participantManagement from './participantManagementReducer';
import cgmManagement from './cgmManagementReducer';
import carManagement from './carManagementReducer';
import fcmClearing from './fcmClearingReducer';
import blockTradePermissions from './blockTradePermissionsReducer';
import newBlockTradeRequestsReducer from './blockTradeRequestsReducer';

export const RESET_SLICE = 'RESET_SLICE';
export const resetSlice = (slice) => ({
  type: RESET_SLICE,
  payload: slice,
});

export const rootReducer = combineReducers({
  util: utilReducer,
  reconciliation: reconciliationReducer,
  reconciliationHistory: reconciliationHistoryReducer,
  ui: combineReducers({ modal: modalReducer, sidebar: sidebarReducer }),
  accounts: accountsReducer,
  apiCredentials: apiCredentialsReducer,
  balances: balancesReducer,
  loggedInUser: authReducer,
  history: accountHistoryReducer,
  upcomingDeposits: upcomingDepositsReducer,
  linkedAccounts: linkedAccountsReducer,
  linkedMemberAssetAccounts: linkedMemberAssetAccountsReducer,
  assetTypesReducer,
  unusedWalletsReducer,
  router: connectRouter(history),
  users: usersReducer,
  members: membersReducer,
  employees: employeesReducer,
  approvalReqs: approvalReqsReducer,
  ...systemAlertsReducer,
  feeRebate: feeRebateReducer,
  trades: tradesReducer,
  blockTradeRequests: blockTradeRequestsReducer,
  tradeCaptureReports: tradeCaptureReportsReducer,
  api: combineReducers({ loading: loadingReducer, error: errorReducer }),
  manualOnboarding: manualOnboardingReducer,
  coreFiles: coreFilesReducer,
  manualEntriesReducer,
  form: formReducer,
  about: aboutReducer,
  tasks: taskLogReducer,
  surveillance: surveillanceReducer,
  spotProducts: spotProductsReducer,
  futuresProducts: futuresProductReducer,
  collateral: collateralReducer,
  env: envReducer,
  subExchanges: subExchangesReducer,
  contracts: contractsReducer,
  journals: journalsReducer,
  ledgers: ledgersReducer,
  configuration: configurationReducer,
  ach: achReducer,
  risk: riskReducer,
  fees: feesReducer,
  referrals: referralCodeReducer,
  ira: iraReducer,
  clientTransfer: clientTransferReducer,
  settlement: settlementReducer,
  marginRateInfo: marginRatesReducer,
  authorizationIp: authorizationIpReducer,
  fcmReports,
  initialMargins,
  riskConfigurations,
  travelRule,
  participantManagement,
  cgmManagement,
  carManagement,
  fcmClearing,
  blockTradePermissions,
  newBlockTradeRequestsReducer,
});

const appReducer = (state, action) => {
  // little reducer to be able to clear out any slice of the state
  if (action.type === RESET_SLICE) {
    const slice = flatten(action.payload);
    // clone to we dont mutate state
    const newState = cloneDeep(state);
    set(newState, slice, undefined);
    return {
      ...state,
      ...newState,
    };
  }

  return rootReducer(state, action);
};

export default appReducer;
