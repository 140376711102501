import { takeLatest } from 'redux-saga/effects';
import {
  fetchAuthorizedIps,
  fetchIpAuthorizationHistory,
  addAuthorizedIp,
  removeAuthorizedIp,
} from '../reducers/authorizationIpReducer';
import { createSaga } from '../utils/createSaga';

export default function* watch() {
  yield takeLatest(
    fetchAuthorizedIps.TRIGGER,
    createSaga(fetchAuthorizedIps, fetchAuthorizedIps._PREFIX),
  );
  yield takeLatest(
    fetchIpAuthorizationHistory.TRIGGER,
    createSaga(
      fetchIpAuthorizationHistory,
      fetchIpAuthorizationHistory._PREFIX,
    ),
  );
  yield takeLatest(
    addAuthorizedIp.TRIGGER,
    createSaga(
      addAuthorizedIp,
      addAuthorizedIp._PREFIX,
      'Adding authorized IP',
      'Authorized IP added',
    ),
  );
  yield takeLatest(
    removeAuthorizedIp.TRIGGER,
    createSaga(
      removeAuthorizedIp,
      removeAuthorizedIp._PREFIX,
      'Removing authorized IP',
      'Authorized IP removed',
    ),
  );
}
