import { map, find, isEqual } from 'lodash';
import {
  change,
  isInvalid,
  getFormInitialValues,
  getFormValues,
} from 'redux-form';
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { bindPromiseCreators } from 'redux-saga-routines';
import { createLoadingSelector, hideModal } from 'erisxkit/client';
import { Modal, Button, Icon, Header, Form } from 'semantic-ui-react';
import { assetTypes } from '../actions/utilActions';
import {
  createAccount,
  createAccountPromiseCreator,
  unusedWallets,
  fetchEmarketAccountTypes,
  updateAccount,
  updateAccountPromiseCreator,
} from '../actions/accountsActions';
import { getUnusedWallets } from '../reducers/unusedWalletsReducer';
import CreateFinanceAccount from '../components/CreateFinanceAccount';
import CreateClearingAccount from '../components/CreateClearingAccount'; // eslint-disable-line import/no-named-as-default
import {
  getEmarketAccountTypes,
  getEmarketFeeProfiles,
  getMarginProfiles,
  getRiskProfiles,
  getFeeProfiles,
  fetchEmarketFeeProfiles,
  fetchMarginProfiles,
  fetchRiskProfiles,
  fetchFeeProfiles,
} from '../reducers/manualOnboardingReducer';
import { getMemberEntitlementsForSelectedAccount } from '../reducers/membersReducer';
import {
  isDefaultLedgerActive,
  getLedgerIds,
} from '../reducers/ledgersReducer';
import { getAssetTypesAsOptions, getSelectorAsOptions } from '../selectors';
import { accountTypeOptions } from '../constants/accountTypes';
import { shallowObjectDiff } from '../utils/methods';
import { sentenceCase } from 'change-case';
import {
  getSubExchanges,
  fetchSubExchanges,
} from '../reducers/subExchangesReducer';
import { getFeeProfilesForDropdown, fetchFees } from '../reducers/feesReducer';
import {
  fetchIraCustodians,
  fetchIraAccountTypesAndCodes,
  getProviders,
  getIRAAccountTypes,
} from '../reducers/iraReducer';
import {
  updateJurisdictionsPromiseCreator,
  fetchJurisdictions,
  fetchJurisdictionsPromiseCreator,
  getDefaultJurisdictions,
} from '../reducers/configurationReducer';

const mapStateToProps = (state = {}) => ({
  assetTypeOptions: getAssetTypesAsOptions(state),
  unusedWallets: getUnusedWallets(state),
  unusedWalletsOptions: getSelectorAsOptions(getUnusedWallets, {
    key: 'providerAccountId',
    value: 'providerAccountId',
    text: (o) => `${o.providerAccountId} - ${o.providerLabel}`,
  })(state),
  values: getFormValues('create_account')(state),
  initialValues: getFormInitialValues('create_account')(state),
  invalid: isInvalid('create_account')(state),
  emarketAccountTypesOptions: getSelectorAsOptions(getEmarketAccountTypes)(
    state,
  ),
  accountsLoading: createLoadingSelector(['ACCOUNTS'])(state),
  linkedMemberEntitlements: getMemberEntitlementsForSelectedAccount(state),
  emarketFeeProfileOptions: getSelectorAsOptions(getEmarketFeeProfiles, {
    key: 'id',
    text: 'name',
    value: 'id',
  })(state),
  ledgerIds: getLedgerIds(state),
  defaultLedgerIsActive: isDefaultLedgerActive(state),
  subExchanges: getSubExchanges(state),
  fees: getFeeProfilesForDropdown(state),
  riskProfilesOptions: getRiskProfiles(state),
  marginProfilesOptions: getMarginProfiles(state),
  feeProfilesOptions: getFeeProfiles(state),
  iraProviders: getProviders(state),
  iraAccountTypes: objectToIRAAccountTypesObjectArray(
    getIRAAccountTypes(state),
  ),
  defaultJurisdictions: getDefaultJurisdictions(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      createAccountPromiseCreator,
      updateJurisdictionsPromiseCreator,
      updateAccountPromiseCreator,
      fetchJurisdictionsPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      hideModal,
      assetTypes,
      createAccount,
      unusedWallets,
      change,
      fetchEmarketAccountTypes,
      fetchEmarketFeeProfiles,
      updateAccount,
      fetchSubExchanges,
      fetchFees,
      fetchIraCustodians,
      fetchIraAccountTypesAndCodes,
      fetchMarginProfiles,
      fetchRiskProfiles,
      fetchFeeProfiles,
      fetchJurisdictions,
    },
    dispatch,
  ),
});

const objectToIRAAccountTypesObjectArray = (obj) => {
  let arr = [];
  for (let key in obj) {
    arr.push({ id: key, subAccountCode: obj[key], name: sentenceCase(key) });
  }
  return arr;
};

class CreateAccountModalContainer extends Component {
  state = {
    jurisdictions: [],
  };

  componentDidMount = () => {
    this.props.assetTypes();
    this.props.fetchEmarketAccountTypes();
    this.props.fetchEmarketFeeProfiles();
    this.props.fetchSubExchanges();
    this.props.fetchFees();
    this.props.fetchMarginProfiles();
    this.props.fetchRiskProfiles();
    this.props.fetchFeeProfiles();

    if (this.props.update) {
      this.props
        .fetchJurisdictionsPromiseCreator({
          account_id: this.props.selectedAccount.accountId,
        })
        .then((res) => {
          this.setState({
            jurisdictions: res,
          });
          this.props.fetchJurisdictions();
        });
    } else {
      this.props.fetchJurisdictions();
    }

    if (this.props.iraProviders.length === 0) {
      this.props.fetchIraCustodians();
    }
    if (Object.keys(this.props.iraAccountTypes).length === 0) {
      this.props.fetchIraAccountTypesAndCodes();
    }
  };

  onClose = () => {
    this.props.hideModal();
  };

  //On Create
  onApply = () => {
    const { values = {}, type } = this.props;

    const payload = {
      ...values,
      subExchangeEntitlements: get(
        values,
        'subExchangeEntitlements',
        [],
      ).filter((ent) => ent.allowSubExchange),
    };

    let jurisdictionToSend;
    //Just a clearing account can have authorizedJurisdictions
    if (type === 'clearing' && payload.allowAuthorizedJurisdictions) {
      if (payload.authorizedJurisdictions) {
        const tempSelectedJurisdictions = cloneDeep(
          payload.authorizedJurisdictions,
        );

        for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
          for (
            let j = 0;
            j < get(tempSelectedJurisdictions, [i, 'states', 'length']);
            j++
          ) {
            //Exception (Not Allowed) Jurisdictions checked, means Allowed Jurisdiction not active.
            set(
              tempSelectedJurisdictions,
              [i, 'states', j, 'active'],
              !get(tempSelectedJurisdictions, [i, 'states', j, 'checked']),
            );
            delete tempSelectedJurisdictions[i].states[j].checked;
          }
        }

        jurisdictionToSend = {
          jurisdictions: tempSelectedJurisdictions,
        };

        //Delete from the createAccountPromiseCreator payload the authorized jurisdictions
        delete payload.authorizedJurisdictions;
      }
    }

    //If there is an error the system doesn't have to hide the modal
    //Calls to the backend

    this.props.createAccountPromiseCreator({ type, payload }).then((res) => {
      if (type === 'clearing' && payload.allowAuthorizedJurisdictions) {
        const accountId = get(Object.getOwnPropertyNames(res), [0]);

        //Call applyJurisdictions with the new account Id
        jurisdictionToSend.accountId = accountId;
        this.props
          .updateJurisdictionsPromiseCreator(jurisdictionToSend)
          .then(() => {
            this.props.hideModal();
          });
      } else {
        this.props.hideModal();
      }
    });
  };

  //On Update
  saveChanges = () => {
    const changes = shallowObjectDiff(
      this.props.values,
      this.props.initialValues,
    );

    const update = {
      ...changes,
      subExchangeEntitlements: get(
        changes,
        'subExchangeEntitlements',
        [],
      ).filter((ent) => ent.allowSubExchange),
    };

    let jurisdictionToSend;
    //Just a clearing account can have authorizedJurisdictions
    if (
      this.props.values.accountType === 'clearing' &&
      this.props.values.allowAuthorizedJurisdictions
    ) {
      const tempSelectedJurisdictions = cloneDeep(
        update.authorizedJurisdictions,
      );

      for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
        for (
          let j = 0;
          j < get(tempSelectedJurisdictions, [i, 'states', 'length']);
          j++
        ) {
          //Exception (Not allowed) Jurisdiction checked, means Allowed Jurisdiction not active.
          set(
            tempSelectedJurisdictions,
            [i, 'states', j, 'active'],
            !get(tempSelectedJurisdictions, [i, 'states', j, 'checked']),
          );
          delete tempSelectedJurisdictions[i].states[j].checked;
        }
        delete tempSelectedJurisdictions[i].checked;
      }

      jurisdictionToSend = {
        jurisdictions: tempSelectedJurisdictions,
      };
    }

    //Delete from the createAccountPromiseCreator update the authorized jurisdictions
    delete update.authorizedJurisdictions;

    this.props
      .updateAccountPromiseCreator({
        accountId: this.props.selectedAccount.accountId,
        update,
      })
      .then((res) => {
        //If allowAuthorizedJurisdictions exists in the value of the object for true or for false means that the user checked that prop.
        if (this.props.values.hasOwnProperty('allowAuthorizedJurisdictions')) {
          //If there is a change on the jurisdicitions
          if (
            !isEqual(
              this.state.jurisdictions.countries,
              jurisdictionToSend?.jurisdictions,
            )
          ) {
            const sendData = !jurisdictionToSend
              ? []
              : jurisdictionToSend?.jurisdictions;
            const accountId = get(Object.getOwnPropertyNames(res), [0]);
            //Call applyJurisdictions with the new account Id
            this.props
              .updateJurisdictionsPromiseCreator({
                accountId,
                jurisdictions: sendData,
              })
              .then(() => {});
          }
        }
      });
  };

  subAccountWarning = () => {
    const { values = {} } = this.props;
    return values.subAccountCode && !values.parent;
  };

  render = () => {
    const {
      emarketAccountTypesOptions,
      assetTypeOptions,
      invalid,
      type,
      unusedWallets,
      unusedWalletsOptions,
      update,
      emarketFeeProfileOptions,
      subExchanges,
      selectedAccount,
      fees,
      riskProfilesOptions,
      marginProfilesOptions,
      feeProfilesOptions,
      defaultJurisdictions,
    } = this.props;

    const typeOptions = map(accountTypeOptions, (accountType) => ({
      key: accountType,
      value: accountType,
      text: accountType.toUpperCase(),
    }));

    const financeAccountProps = {
      typeOptions,
      assetTypeOptions,
      unusedWalletsOptions,
      assetType: get(this.props.values, 'assetType', ''),
      type: get(this.props.values, 'accountType', ''),
      provider: get(this.props.values, 'provider', ''),
      change,
      unusedWallets,
    };

    const clearingAccountInitialValues = {
      ...selectedAccount,
      subExchangeEntitlements: subExchanges,
      feeProfileOptions: fees,
    };

    if (update) {
      clearingAccountInitialValues.subExchangeEntitlements = subExchanges.map(
        (ex) => {
          // if the subExchange entitlement is already 'allowed' on the member
          const ent = find(selectedAccount.subExchangeEntitlements, {
            subExchangeId: ex.subExchangeId,
          });
          if (ent) {
            return {
              ...ent,
              allowSubExchange: true,
              name: ex.name,
            };
          }
          return ex;
        },
      );
    }

    const formContent = (
      <Form>
        {type === 'finance' && (
          <CreateFinanceAccount
            saveChanges={this.saveChanges}
            {...financeAccountProps}
            selectedAccount={this.props.selectedAccount}
          />
        )}
        <CreateClearingAccount
          accountType={this.props.type}
          emarketAccountTypesOptions={emarketAccountTypesOptions}
          selectedAccount={this.props.selectedAccount}
          update={update}
          emarketFeeProfileOptions={emarketFeeProfileOptions}
          saveChanges={this.saveChanges}
          type={get(this.props.values, 'accountType', '')}
          ledgerIds={this.props.ledgerIds}
          defaultLedgerIsActive={this.props.defaultLedgerIsActive}
          initialValues={clearingAccountInitialValues || {}}
          riskProfilesOptions={riskProfilesOptions || []}
          marginProfilesOptions={marginProfilesOptions || []}
          feeProfilesOptions={feeProfilesOptions || []}
          iraProviders={this.props.iraProviders}
          iraAccountTypes={this.props.iraAccountTypes}
          jurisdictions={this.state.jurisdictions?.countries || []}
          defaultJurisdictions={defaultJurisdictions || []}
          change={change}
        />
      </Form>
    );

    return update ? (
      <Fragment>
        <h4 className="dividing">Account Details</h4>
        {formContent}
      </Fragment>
    ) : (
      <Fragment>
        <Header icon="add" content="Add Account" />
        <Modal.Content>
          {formContent}
          {/* hiding master accounts as part of CO-2811 */}
          {/* {this.subAccountWarning() &&
            <Message warning>
              <Message.Header>
                Are you sure you want to create a Sub Account without a Master Account?
              </Message.Header>
              <p>If required, add a Master Account now.  A Master Account cannot be added after creation.</p>
            </Message>} */}
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={this.onClose}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" disabled={invalid} onClick={this.onApply}>
            <Icon name="add" /> Create
          </Button>
        </Modal.Actions>
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAccountModalContainer);
