import _ from 'lodash';
import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';

const columns = (exceptionInfo) => [
  {
    expander: true,
  },
  {
    Header: 'Component',
    accessor: 'from',
    id: 'from',
    filterable: true,
    Filter: ({ filter, onChange }) => {
      let options = [
        {
          text: 'Show All',
          value: '',
        },
      ];
      if (exceptionInfo.from) {
        options = options.concat(
          exceptionInfo.from.map((item) => ({ text: item, value: item })),
        );
      }
      return (
        <Dropdown
          name="component"
          value={_.get(filter, 'value', '')}
          search
          selection
          options={options}
          onChange={(event, data) => onChange(data.value)}
        />
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    id: 'type',
    filterable: true,
    Filter: ({ filter, onChange }) => {
      let options = [
        {
          text: 'Show All',
          value: '',
        },
      ];
      if (exceptionInfo.type) {
        options = options.concat(
          exceptionInfo.type.map((item) => ({ text: item, value: item })),
        );
      }
      return (
        <Dropdown
          name="type"
          value={_.get(filter, 'value', '')}
          fluid
          search
          selection
          options={options}
          onChange={(event, data) => onChange(data.value)}
        />
      );
    },
  },
  {
    Header: 'Message',
    accessor: 'message',
    id: 'message',
  },
  {
    Header: 'PID',
    accessor: 'pid',
    id: 'pid',
  },
  {
    Header: 'Time',
    id: 'time',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.time),
    getProps: (state, rowInfo, column) => ({
      title: formatTimeLocal(_.get(rowInfo, 'original.time')),
    }),
  },
];

export default columns;
