import React from 'react';
import get from 'lodash/get';
import { formatTimeLocal } from '../utils/time';
import {
  DateRangeColumnFilter,
  SelectColumnFilter,
} from '../common/table/Filters';
import { Label } from 'semantic-ui-react';

const COLOR_BY_STATUS = {
  rejected: 'red',
  processed: 'green',
  failed: 'red',
};

const CGM_TYPE = 'cgm';
const PCS_TYPE = 'pcs';
const PROCESSED_STATUS = 'processed';
const REJECTED_STATUS = 'rejected';

const fcmReportsMetadata = (
  onOpenParsed,
  onDownloadRaw,
  memberOptions = [],
) => [
  {
    Header: 'Date Received',
    id: 'received_time',
    accessor: (row) => formatTimeLocal(row.receivedTime),
    filterable: false,
    Filter: (props) => <DateRangeColumnFilter {...props} />,
  },
  {
    Header: 'Date Processed',
    id: 'processed_time',
    accessor: (row) => formatTimeLocal(row.processedTime),
    filterable: false,
    Filter: (props) => <DateRangeColumnFilter {...props} />,
  },
  {
    Header: 'Filename',
    id: 'file_name',
    accessor: 'fileName',
  },
  {
    Header: 'Status',
    id: 'status',
    maxWidth: 170,
    accessor: (row) => get(row, 'status', ''),
    Cell: (props) => {
      const status = get(props, 'row.original.status', '');
      return (
        <div className="flex-row centered">
          <Label
            className="capitalised"
            color={COLOR_BY_STATUS[status.toLowerCase()]}
          >
            {status}
          </Label>
        </div>
      );
    },
    Filter: SelectColumnFilter,
    options: [
      { key: PROCESSED_STATUS, value: PROCESSED_STATUS, text: 'Processed' },
      { key: REJECTED_STATUS, value: REJECTED_STATUS, text: 'Rejected' },
    ],
  },
  {
    Header: 'Member',
    id: 'memberId',
    // We use memberName as accessor so its user friendly but
    // we keep memberId as id so we filter by ID under the hood
    accessor: 'memberName',
    Filter: SelectColumnFilter,
    options: memberOptions,
  },
  {
    Header: 'Firm Code',
    id: 'firm_code',
    accessor: (row) => get(row, 'firmCode', ''),
    Cell: (props) => (
      <div className="flex-row centered">
        {get(props, 'row.original.firmCode', '')}
      </div>
    ),
  },
  {
    Header: 'File Type',
    id: 'type',
    accessor: 'type',
    Filter: SelectColumnFilter,
    options: [
      { key: CGM_TYPE, value: CGM_TYPE, text: 'CGM' },
      { key: PCS_TYPE, value: PCS_TYPE, text: 'PCS' },
    ],
  },
  {
    Header: 'Raw',
    id: 'raw',
    width: 100,
    hideFilter: true,
    disableExport: true,
    Cell: (props) => (
      <div
        className="flex-row"
        style={{ justifyContent: 'center' }}
        onClick={() =>
          onDownloadRaw(
            props?.row?.original?.rawText,
            `${props?.row?.original?.fileName}`,
          )
        }
      >
        <i className="grey arrow alternate circle right outline link large icon" />
      </div>
    ),
  },
  {
    Header: 'Parsed',
    id: 'parsed',
    accessor: 'parsed',
    filter: false,
    width: 100,
    hideFilter: true,
    getCellExportValue: (row) => {
      const positionMntReqs = [
        ...get(row, 'original.pcsPositionMntReqs', []),
        ...get(row, 'original.cgmPositionMntReqs', []),
      ];

      const parsed = positionMntReqs.reduce((prev, curr, i) => {
        prev[`position_mnt_req_${i + 1}`] = curr;
        return prev;
      }, {});
      return JSON.stringify(parsed);
    },
    Cell: (props) => (
      <div
        className="flex-row"
        style={{ justifyContent: 'center' }}
        onClick={() => {
          onOpenParsed(props?.row?.original);
        }}
      >
        <i className="grey arrow alternate circle right outline link large icon" />
      </div>
    ),
  },
];
export default fcmReportsMetadata;
