import { difference, filter, get, snakeCase, omit } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  showModal,
  hideModal,
  createLoadingSelector,
  filteredArrayToAttrValue,
  getItemsCount,
  getItemsPaged,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import MemberUserIndex from '../../components/MemberUsers/MemberUserIndex';
import userIndexMetadata from '../../metadata/userIndexMetadata';
import {
  fetchUsers,
  selectUser,
  updateUser,
  createMember,
  USERS,
  memberStates,
  getUserStates,
  fetchMemberUserPermissions,
  getUserPermissions,
  getMemberUserTypes,
  fetchMemberUserTypes,
  createMemberUserPromiseCreator,
  addClearingAccountToMemberPromiseCreator,
} from '../../reducers/usersReducer';
import * as userTypes from '../../constants/userTypes';
import {
  apiCredentialsPermissions as fetchApiCredentialsPermissions,
  getApiCredentialPermissions,
} from '../../reducers/apiCredentialsReducer';
import { CREATE_MEMBER } from '../../constants/modalTypes';
import history from '../../constants/history';
import { getSelectorAsOptions } from '../../selectors';
import { addMemberToUsersPromiseCreator } from '../../reducers/membersReducer';

const mapStateToProps = (state = {}) => ({
  apiCredentialsPermissions: getApiCredentialPermissions(state),
  values: get(state.form, ['create_user', 'values'], {}),
  membersLoading: createLoadingSelector([USERS])(state),
  count: getItemsCount('users')(state),
  usersList: getItemsPaged('users')(state),
  userStates: getSelectorAsOptions(getUserStates)(state),
  userPermissions: getUserPermissions(state),
  memberUserTypes: getMemberUserTypes(state),
});
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchApiCredentialsPermissions,
      fetchUsers,
      selectUser,
      showModal,
      hideModal,
      updateUser,
      createMember,
      memberStates,
      fetchMemberUserPermissions,
      fetchMemberUserTypes,
    },
    dispatch,
  ),
  ...bindPromiseCreators(
    {
      createMemberUserPromiseCreator,
      addMemberToUsersPromiseCreator,
      addClearingAccountToMemberPromiseCreator,
    },
    dispatch,
  ),
});

const getFilterOp = (columns, id) => {
  const column = columns.find((c) => c.id === id);
  return column?.filterOp || DEFAULT_FILTER_OP;
};

class MemberUserIndexContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userArgs: {},
    };
  }

  componentDidMount = () => {
    this.props.fetchApiCredentialsPermissions();
    this.props.memberStates();
    this.props.fetchMemberUserPermissions();
    this.props.fetchMemberUserTypes();
    this.table = React.createRef();

    // Search default just for active ones on the first time for employees and member users
    if (!history.location.search.includes('disabled')) {
      history.replace({
        ...history.location,
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { userIds } = this.props;
    if (difference(userIds, prevProps.userIds).length) {
      if (this.state.userArgs.pageSize !== undefined) {
        this.fetchData(this.state.userArgs);
      }
    }
  }

  fetchData = ({ pageSize, pageIndex, filters, sortBy, columns }) => {
    const { userIds } = this.props;
    // initialize the filter to be non employees, this is required.
    let filter = [
      {
        attr: 'type',
        op: 'eq',
        value: this.props.memberUserTypes.map((el) => el.value),
      },
    ];

    const historySearch = history.location.search;

    // If exists in the url the parameter disabled prepare the filter for the backend method
    if (
      historySearch.includes('disabled') &&
      (filters?.length === 0 || filters.some((x) => x.id === 'disabled'))
    ) {
      if (historySearch.includes('disabled=true')) {
        filter.push({
          attr: 'disabled',
          value: 'true',
          op: 'eq',
        });
      }
      if (historySearch.includes('disabled=false')) {
        filter.push({
          attr: 'disabled',
          value: 'false',
          op: 'eq',
        });
      }
    }

    if (userIds) {
      filter.push({
        attr: 'user_id',
        op: 'eq',
        value: userIds,
      });
    }
    if (filters?.length !== 0) {
      const _filter = [];
      filters.forEach((each) => {
        switch (each.id) {
          case 'status':
            // prepare the backend method filter for status column --> disabled parameter
            _filter.push({
              id: 'disabled',
              value: each.value === 'disabled' ? 'true' : 'false',
              op: 'eq',
            });
            break;
          case 'memberLinks':
            // prepare the backend method filter for memberLinks (memberName)
            for (const property in each.value) {
              if (each.value[property].length > 0) {
                _filter.push({
                  id: snakeCase(property),
                  value: each.value[property],
                  op: 'contain',
                });
              }
            }
            break;
          case 'member_name':
            if (each.value.firstName !== '') {
              _filter.push({
                id: 'first_name',
                op: 'contain',
                value: each.value.firstName,
              });
            }
            if (each.value.middleName !== '') {
              _filter.push({
                id: 'middle_name',
                op: 'contain',
                value: each.value.middleName,
              });
            }
            if (each.value.lastName !== '') {
              _filter.push({
                id: 'last_name',
                op: 'contain',
                value: each.value.lastName,
              });
            }
            break;
          default:
            // Avoid disabled filter be sent to the backend, because it is in the status column
            if (each.id !== 'disabled') {
              _filter.push({
                ...each,
                op: getFilterOp(columns, each.id),
              });
            }
        }
      });
      filter = filter.concat(filteredArrayToAttrValue(_filter));
    }

    const argsFilter = {
      pageSize,
      pageIndex,
      filters,
      sortBy,
      columns,
    };

    // call the method
    this.props.fetchUsers({
      limit: pageSize,
      offset: pageIndex * pageSize,
      page: pageIndex,
      filter,
      includeName: true,
    });

    this.setState(argsFilter);
  };

  userDetails = ({ userId }) => {
    // 'select the user'
    this.props.selectUser(userId);
    history.push(`/member_users/${userId}`);
  };

  toggleDisable = (user) => {
    this.props.updateUser({
      userId: user.userId,
      update: { disabled: !user.disabled },
    });
  };

  createMemberModal = () => {
    const {
      apiCredentialsPermissions,
      showModal,
      userPermissions,
      memberUserTypes,
    } = this.props;
    showModal(CREATE_MEMBER, {
      onApply: this.createMember,
      userPermissions,
      apiCredentialsPermissions,
      type: userTypes.memberUser,
      memberUserTypes,
    });
  };

  cloneMember = (cloneMember) => {
    const {
      apiCredentialsPermissions,
      showModal,
      userPermissions,
      memberUserTypes,
    } = this.props;
    showModal(CREATE_MEMBER, {
      userPermissions,
      apiCredentialsPermissions,
      type: userTypes.memberUser,
      memberUserTypes,
      cloneMember,
    });
  };

  createMember = () => {
    this.props.createMember({
      type: userTypes.member,
      payload: this.props.values,
    });
    this.props.hideModal();
  };

  render = () => {
    const metadata = userIndexMetadata(
      this.toggleDisable,
      userTypes.memberUser,
      this.cloneMember,
    );
    let { usersList, addMember, userIds, userStates, membersLoading } =
      this.props;

    if (userIds) {
      usersList = filter(usersList, (user) => userIds.includes(user.userId));
    }

    return (
      <MemberUserIndex
        metadata={metadata}
        data={usersList}
        type={userTypes.memberUser}
        addMember={addMember}
        createMember={this.createMemberModal}
        userIds={userIds}
        fetchData={this.fetchData}
        loading={membersLoading}
        count={this.props.count}
        selectUser={this.userDetails}
        userStates={userStates}
        ref={this.table}
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberUserIndexContainer);
