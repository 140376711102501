import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';
import { getSubExchangesAsOptions } from '../../selectors';
import { fetchSubExchanges } from '../../reducers/subExchangesReducer';

const SubExchangeSelectionContainer = ({
  name,
  id,
  input,
  label,
  placeholder,
  required,
}) => {
  const dispatch = useDispatch();
  const subExchangesOptions = useSelector(getSubExchangesAsOptions);
  const subExchangesLoading = useSelector(
    createLoadingSelector(['SUB_EXCHANGES']),
  );

  useEffect(() => {
    dispatch(fetchSubExchanges());
  }, []);

  const handleChange = (e, { value }) => {
    _.get(input, ['onChange'], () => {})(value);
  };

  return (
    <Form.Field
      control={Select}
      loading={subExchangesLoading}
      label={input.label || label}
      search
      name={input.name || name}
      id={input.id || id}
      placeholder={placeholder}
      options={subExchangesOptions}
      required={required}
      clearable
      onChange={handleChange}
      value={_.get(input, ['value'])}
    />
  );
};

SubExchangeSelectionContainer.propTypes = {
  label: PropTypes.string,
  fetchSubExchanges: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.objectOf(PropTypes.any),
  required: PropTypes.bool,
  subExchangesOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  subExchangesLoading: PropTypes.bool.isRequired,
};

SubExchangeSelectionContainer.defaultProps = {
  input: {},
  name: 'subExchangeId',
  placeholder: 'Select Sub Exchange...',
  label: 'Sub Exchange',
  required: false,
  subExchangesOptions: [],
};

export default SubExchangeSelectionContainer;
