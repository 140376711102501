import { call, put, takeLatest } from 'redux-saga/effects';
import { createSaga } from '../utils/createSaga';
import {
  fetchRisk,
  fetchRiskAlerts,
  fetchRiskAlertComments,
  fetchRiskAlertStatuses,
  addRiskAlertComment,
  updateRiskAlertStatus,
  fetchMarginCallAlerts,
  fetchRiskDetail,
  updateMarginRequirementStatus,
  fetchMarginDelivery,
} from '../reducers/riskReducer';
import {
  api_server_api,
  RISK_ACCOUNT_SUMMARY_API_ENDPOINT,
  MARGIN_CALL_ALERTS_API_ENDPOINT,
  RISK_ALERTS_API_ENDPOINT,
  RISK_ALERT_COMMENTS_API_ENDPOINT,
  RISK_ALERT_STATUSES_API_ENDPOINT,
  ADD_RISK_ALERT_COMMENT_API_ENDPOINT,
  UPDATE_RISK_ALERT_STATUS_API_ENDPOINT,
  RISK_DETAIL_API_ENDPOINT,
} from '../api';
import generateSaga from '../utils/generateSaga';

function* riskSaga(arg) {
  try {
    yield put(fetchRisk.request());
    const data = yield call(
      api_server_api.post,
      RISK_ACCOUNT_SUMMARY_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchRisk.success({ ...data }));
  } catch (e) {
    yield put(fetchRisk.failure(e.response));
  }
}

function* riskDetailSaga(arg) {
  try {
    yield put(fetchRiskDetail.request());
    const data = yield call(
      api_server_api.post,
      RISK_DETAIL_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchRiskDetail.success({ ...data }));
  } catch (e) {
    yield put(fetchRiskDetail.failure(e.response));
  }
}

function* marginCallAlertsSaga(arg) {
  try {
    yield put(fetchMarginCallAlerts.request());
    const data = yield call(
      api_server_api.post,
      MARGIN_CALL_ALERTS_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchMarginCallAlerts.success({ ...data }));
  } catch (e) {
    yield put(fetchMarginCallAlerts.failure(e.response));
  }
}

function* riskAlertsSaga(arg) {
  try {
    yield put(fetchRiskAlerts.request());
    const data = yield call(
      api_server_api.post,
      RISK_ALERTS_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchRiskAlerts.success({ ...data }));
  } catch (e) {
    yield put(fetchRiskAlerts.failure(e.response));
  }
}

function* riskAlertCommentsByIdSaga(arg) {
  try {
    yield put(fetchRiskAlertComments.request());
    const data = yield call(
      api_server_api.post,
      RISK_ALERT_COMMENTS_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchRiskAlertComments.success({ ...data }));
  } catch (e) {
    yield put(fetchRiskAlertComments.failure(e.response));
  }
}

function* riskAlertStatusesByIdSaga(arg) {
  try {
    yield put(fetchRiskAlertStatuses.request());
    const data = yield call(
      api_server_api.post,
      RISK_ALERT_STATUSES_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchRiskAlertStatuses.success({ ...data }));
  } catch (e) {
    yield put(fetchRiskAlertStatuses.failure(e.response));
  }
}

function* addRiskAlertCommentSaga(arg) {
  try {
    yield put(addRiskAlertComment.request());
    const data = yield call(
      api_server_api.post,
      ADD_RISK_ALERT_COMMENT_API_ENDPOINT,
      arg.payload,
    );
    yield put(addRiskAlertComment.success({ ...data }));
  } catch (e) {
    yield put(addRiskAlertComment.failure(e.response));
  }
}

function* updateRiskAlertStatusSaga(arg) {
  try {
    yield put(updateRiskAlertStatus.request());
    const data = yield call(
      api_server_api.post,
      UPDATE_RISK_ALERT_STATUS_API_ENDPOINT,
      arg.payload,
    );
    yield put(updateRiskAlertStatus.success({ ...data }));
  } catch (e) {
    yield put(updateRiskAlertStatus.failure(e.response));
  }
}

export default function* watchConfiguration() {
  yield takeLatest(fetchRisk.TRIGGER, riskSaga);
  yield takeLatest(fetchRiskDetail.TRIGGER, riskDetailSaga);
  yield takeLatest(fetchMarginCallAlerts.TRIGGER, marginCallAlertsSaga);

  yield takeLatest(fetchRiskAlerts.TRIGGER, riskAlertsSaga);
  yield takeLatest(fetchRiskAlertComments.TRIGGER, riskAlertCommentsByIdSaga);
  yield takeLatest(fetchRiskAlertStatuses.TRIGGER, riskAlertStatusesByIdSaga);
  yield takeLatest(addRiskAlertComment.TRIGGER, addRiskAlertCommentSaga);
  yield takeLatest(updateRiskAlertStatus.TRIGGER, updateRiskAlertStatusSaga);

  yield takeLatest(
    updateMarginRequirementStatus.TRIGGER,
    createSaga(
      updateMarginRequirementStatus,
      updateMarginRequirementStatus._PREFIX,
    ),
  );

  yield takeLatest(
    fetchMarginDelivery.TRIGGER,
    generateSaga(fetchMarginDelivery),
  );
}
