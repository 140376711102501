import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Divider } from 'semantic-ui-react';
import RepublishBatch from '../../components/ManualEntries/RepublishBatch';
import { republishBatch } from '../../reducers/manualEntriesReducer';

const mapDispatchToProps = {
  republishBatch,
};

class BankCommunicationsContainer extends Component {
  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  republishBatch = () => {
    const { incrementalId } = this.state;
    this.props.republishBatch({ incrementalId });
    this.setState({ incrementalId: '' });
  };

  render = () => (
    <Fragment>
      <Header as="h2" dividing>
        Republish batch for bank transaction.
      </Header>
      <Divider />
      <RepublishBatch
        republishBatch={this.republishBatch}
        handleChange={this.handleChange}
        {...this.state}
      />
    </Fragment>
  );
}

BankCommunicationsContainer.propTypes = {
  republishBatch: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(BankCommunicationsContainer);
