import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { showModal, hideModal } from 'erisxkit/client';
import DesignationFundsTransfer from '../../components/ManualEntries/DesignationFundsTransfer';
import { assetTypes } from '../../actions/utilActions';
import { getAssetTypesAsOptions } from '../../selectors';
import { createDesignationTransferJournal } from '../../actions/manualEntryActions';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../../constants/modalTypes';

const mapStateToProps = (state) => ({
  assetTypesOptions: getAssetTypesAsOptions(state),
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
});

const mapDispatchToProps = {
  hideModal,
  showModal,
  assetTypes,
  createDesignationTransferJournal,
};

class DesignationTransferContainer extends PureComponent {
  componentDidMount = () => {
    this.props.assetTypes();
  };

  confirmTransfer = (payload = {}) => {
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: 'Confirm Designation Transfer',
      msg: `Create Designation Transfer Journal for account id: ${payload.accountId}?`,
      hideModal: this.props.hideModal,
      closeOnDimmerClick: true,
      closeOnEscape: true,
      onConfirm: () => {
        this.props.createDesignationTransferJournal(payload);
        this.props.hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
      },
      payload,
      action: 'Create Designation Transfer Journal',
    });
  };

  render = () => (
    <Fragment>
      <Header as="h1" dividing>
        Designated Funds Transfer
      </Header>
      <DesignationFundsTransfer
        {...this.props}
        confirmTransfer={this.confirmTransfer}
      />
    </Fragment>
  );
}

DesignationTransferContainer.propTypes = {
  assetTypes: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  createDesignationTransferJournal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesignationTransferContainer);
