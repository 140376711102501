import styled from 'styled-components';
import colors from '../../../constants/colors';

export const Details = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  padding-left: 5rem;
`;
export const DetailHeader = styled.p`
  font-size: 16px;
  color: ${colors.secondary3};
  margin-bottom: 10px;
  font-weight: bold;
`;
export const Info = styled.p`
  color: ${colors.secondary3};
`;

export const SubHeader = styled.p`
  font-size: 20px;
  text-align: center;
  color: ${colors.secondary3};
`;
