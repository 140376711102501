import React from 'react';
import { Button } from 'semantic-ui-react';
import { get } from 'lodash';
import Big from 'bignumber.js';

export const mainComponentMetadata = [
  {
    Header: 'Contract Symbol',
    accessor: 'contractSymbol',
    id: 'symbol',
    resizable: false,
  },
];

export const subComponentMetadata = (removeCollateral, isCloseoutItem) => [
  {
    Header: 'ID',
    accessor: 'positionId',
    id: 'id',
  },
  {
    Header: 'Quantity',
    accessor: (subRow) => (
      <span className="mono">
        {Big(get(subRow, 'qty', ''))
          .times(-1)
          .toFixed()}
      </span>
    ),
    id: 'qty',
  },
  {
    Header: 'Price',
    accessor: (subRow) => <span className="mono">{get(subRow, 'px', '')}</span>,
    id: 'px',
  },
  {
    Header: 'Funds Designation',
    accessor: 'fd',
    id: 'fundsDesignation',
  },
  {
    Header: 'CustAcctRef',
    accessor: 'customerAccountRef',
    id: 'customerAccountRef',
    show: isCloseoutItem,
  },
  {
    id: 'remove-collateral',
    width: 130,
    show: !!removeCollateral,
    accessor: (subRow) => (
      <Button
        className="remove-collateral-btn"
        size="tiny"
        fluid
        compact
        negative
        icon="minus circle"
        onClick={() => removeCollateral(subRow)}
        content="Withdraw"
      />
    ),
  },
];
