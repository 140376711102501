import React, { Fragment } from 'react';
import XTab from '../../common/tabs/XTab';
import AuthorizedIPs from './AuthorizedIPs';
import IpAuthorizationsHistory from './IpAuthorizationsHistory';

const AuthorizationsIPsContainer = ({ selectedMember }) => {
  const panes = [
    {
      url: 'authorized_ips',
      menuItem: 'Authorized IPS',
      render: () => <AuthorizedIPs selectedMember={selectedMember} />,
    },
    {
      url: 'ip_authorizations_history',
      menuItem: 'IP Authorizations History',
      render: () => <IpAuthorizationsHistory selectedMember={selectedMember} />,
    },
  ];

  return (
    <Fragment>
      <XTab panes={panes} />
    </Fragment>
  );
};

export default AuthorizationsIPsContainer;
