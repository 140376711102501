import React, { Fragment } from 'react';
import { XTable } from 'erisxkit/client';

const CryptoAddresses = ({ data, metadata, loading }) => (
  <Fragment>
    <XTable
      title="CryptoAddresses"
      showPageSizeOptions={false}
      showPagination={false}
      data={data}
      columns={metadata}
      minRows={3}
      small={false}
      loading={loading}
      getTdProps={(state, rowInfo, column) => ({
        className: column.id === 'more' && 'td-with-dropdown',
      })}
    />
  </Fragment>
);

export default CryptoAddresses;
