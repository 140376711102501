import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';
import FloatInputContainer from '../../common/containers/FloatInputContainer';

const BalanceAdjustment = ({
  data,
  handleChange,
  handleSubmit,
  assetTypes,
  assetTypesLoading,
  balanceAdjustmentLoading,
}) => {
  const enabled = !!(data?.accountId && data?.amount && data?.assetType);

  return (
    <Form onSubmit={handleSubmit}>
      <AccountSelectionContainer
        required
        label="Clearing Member Account"
        name="accountId"
        id="account-id"
        placeholder="Account ID"
        iconPosition="left"
        onChange={handleChange}
      />
      <Form.Select
        loading={assetTypesLoading}
        required
        label="Asset Type"
        search
        name="assetType"
        placeholder="Select or enter asset type..."
        options={assetTypes.map((asset) => ({
          key: asset.symbol,
          value: asset.symbol,
          text: asset.symbol,
          description: asset.description,
        }))}
        onChange={handleChange}
      />
      <FloatInputContainer
        required
        label="Amount"
        icon="cube"
        name="amount"
        id="amount"
        placeholder="Amount"
        iconPosition="left"
        onChange={handleChange}
      />
      <Button
        type="submit"
        disabled={!enabled}
        loading={balanceAdjustmentLoading}
        onSubmit={handleSubmit}
      >
        Submit
      </Button>
    </Form>
  );
};

BalanceAdjustment.propTypes = {
  assetTypes: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      description: PropTypes.string,
      isFiat: PropTypes.bool,
    }),
  ),
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  assetTypesLoading: PropTypes.bool,
  balanceAdjustmentLoading: PropTypes.bool,
};

BalanceAdjustment.defaultProps = {
  assetTypes: [],
  assetTypesLoading: false,
  balanceAdjustmentLoading: false,
};

export default BalanceAdjustment;
