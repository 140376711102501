import React, { Fragment } from 'react';
import { Modal, Image, Button, Divider } from 'semantic-ui-react';
import DetailsHeader from '../../common/components/DetailsHeader';

const ViewPhoto = ({ image, hideModal, createdAt, type, pii }) => (
  <Fragment>
    <Modal.Header>
      {type} | Uploaded: {createdAt}
      <Button
        id="closeButton"
        className="pull-right"
        circular
        size="mini"
        icon="times"
        onClick={hideModal}
      />
    </Modal.Header>
    <Modal.Content scrolling>
      <DetailsHeader
        hideEdit
        item={{
          firstName: pii.firstName,
          middleName: pii.middleName,
          lastName: pii.lastName,
          address1: pii.address1,
          address2: pii.address2,
          city: pii.city,
          state: pii.locationState,
          zipCode: pii.postalCode,
          country: pii.country,
          dateOfBirth: pii.dob || <i>No date of birth provided.</i>,
        }}
      />
      <Divider horizontal />
      <Image wrapped id="view-photo" src={image} alt="Id" />
    </Modal.Content>
  </Fragment>
);

export default ViewPhoto;
