import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Divider } from 'semantic-ui-react';
import UserSelectionContainer from '../../../common/containers/UserSelectionContainer';

const AddMemberUserContainer = () => (
  <Form>
    <div className="xwizard-form-group">
      <h4>Member Users</h4>
      <Divider />
      <Form.Group>
        <Field
          multiple
          component={UserSelectionContainer}
          id="userIds"
          label="Users"
          name="userIds"
          width={8}
        />
      </Form.Group>
    </div>
  </Form>
);

export default reduxForm({
  form: 'manage_member_user_for_member',
  enableReinitialize: true,
})(AddMemberUserContainer);
