import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import XTable7 from '../../common/table/XTable7';
import { fcmMemberMetadata } from '../../metadata/fcmMemberMetadata';
import {
  GET_TRADING_PARTICIPANTS,
  fetchTradingParticipants,
  getApprovedParticipantsTableRows,
} from '../../reducers/participantManagementReducer';

const FCMMemberContainer = (props: any) => {
  const selectedMember = props.selectedMember;
  const firmCode = selectedMember.firmCode;
  const dispatch = useDispatch();
  const data = useSelector(getApprovedParticipantsTableRows);

  /** Selectors */
  const loading = useSelector((state) =>
    createLoadingSelector([GET_TRADING_PARTICIPANTS])(state),
  );

  // /** Fetch Callback */
  const fetchData = useCallback(
    () => {
      dispatch(
        fetchTradingParticipants({
          filter: [
            {
              attr: 'firm_code',
              op: 'eq',
              value: firmCode,
            },
          ],
        }),
      );
    },
    [firmCode, dispatch],
  );

  return (
    <>
      <div className="flex-row space-between">
        <Header as="h1">FCM Clearing</Header>
      </div>
      <XTable7
        metadata={fcmMemberMetadata()}
        data={data}
        fetchData={fetchData}
        loading={loading}
        showHeader={true}
        showPagination={true}
      ></XTable7>
    </>
  );
};

export default FCMMemberContainer;
