import _ from 'lodash';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleAction } from 'redux-actions';
import {
  createById,
  createCount,
  createCurrentPage,
  createPages,
} from 'erisxkit/client';
import { createAndLinkClearingAccount } from './manualOnboardingReducer';
import { getApiCredentialPermissions } from './apiCredentialsReducer';
import * as userTypes from '../constants/userTypes';
import { sortTimeArrayNewerFirst } from '../utils/methods';

//* Action Types */
export const CURRENT_USER = 'CURRENT_USER';
export const MEMBER_COMPLIANCE_QUESTIONS = 'MEMBER_COMPLIANCE_QUESTIONS';
export const MEMBER_SECURITY_QUESTIONS = 'MEMBER_SECURITY_QUESTIONS';
export const ELEVATE_TX_ADMIN = 'ELEVATE_TX_ADMIN';
export const RESET_TX_PASSWORD = 'RESET_TX_PASSWORD';
export const EMPLOYEE_ROLES = 'EMPLOYEE_ROLES';
export const MEMBER_SUPPORTING_DOC = 'MEMBER_SUPPORTING_DOC';
export const MEMBER_DOCUMENTS = 'MEMBER_DOCUMENTS';
export const MEMBER_IMAGES = 'MEMBER_IMAGES';
export const USERS = 'USERS';
export const ROUTE_SUBMITTING_ORDERS_TO_API = 'ROUTE_SUBMITTING_ORDERS_TO_API';
export const MEMBER_USER_PERMISSIONS = 'MEMBER_USER_PERMISSIONS';
export const PII = 'PII';
export const MEMBER_USER_NOTES = 'MEMBER_USER_NOTES';
export const ADD_NOTE_TO_MEMBER_USER = 'ADD_NOTE_TO_MEMBER_USER';
export const EDIT_NOTE_ON_MEMBER_USER = 'EDIT_NOTE_ON_MEMBER_USER';
export const RESET_MF = 'RESET_MF';
export const MEMBER_USER_TYPES = 'MEMBER_USER_TYPES';
const CREATE_MEMBER = 'CREATE_MEMBER';

/**
 * fetch the current user from the clearing system
 */
export const fetchCurrentUser = createRoutine(CURRENT_USER);
export const fetchCurrentUserPromiseCreator =
  promisifyRoutine(fetchCurrentUser);
export const fetchMemberComplianceQuestions = createRoutine(
  MEMBER_COMPLIANCE_QUESTIONS,
);
export const elevateTxAdmin = createRoutine(ELEVATE_TX_ADMIN);
export const fetchMemberSecurityQuestions = createRoutine(
  MEMBER_SECURITY_QUESTIONS,
);
export const resetTxPassword = createRoutine(RESET_TX_PASSWORD);
export const fetchEmployeeRoles = createRoutine(EMPLOYEE_ROLES);
export const memberSupportingDoc = createRoutine(MEMBER_SUPPORTING_DOC);
export const fetchMemberImages = createRoutine(MEMBER_IMAGES);
export const fetchMemberUserDocuments = createRoutine(MEMBER_DOCUMENTS);
export const createMemberUser = createRoutine(CREATE_MEMBER);

export const memberSupportingDocPromiseCreator =
  promisifyRoutine(memberSupportingDoc);
export const fetchUsers = createRoutine(USERS);
export const fetchMemberUserTypes = createRoutine(MEMBER_USER_TYPES);
export const fetchUsersPromiseCreator = promisifyRoutine(fetchUsers);
export const routeSubmittingOrdersToApi = createRoutine(
  ROUTE_SUBMITTING_ORDERS_TO_API,
);
export const fetchMemberUserPermissions = createRoutine(
  MEMBER_USER_PERMISSIONS,
);
export const fetchPii = createRoutine(PII);
export const fetchMemberUserNotes = createRoutine(MEMBER_USER_NOTES);
export const addNoteToMemberUser = createRoutine(ADD_NOTE_TO_MEMBER_USER);
export const editNoteOnMemberUser = createRoutine(EDIT_NOTE_ON_MEMBER_USER);
export const createMemberUserPromiseCreator =
  promisifyRoutine(createMemberUser);

export const resetMf = createRoutine(RESET_MF);

export const SELECT_USER = 'SELECT_USER';

export const CREATE_MEMBER_REQUEST = 'CREATE_MEMBER_REQUEST';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_MEMBER_FAILED = 'CREATE_MEMBER_FAILED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const ADD_CLEARING_ACCOUNT_REQUEST = 'ADD_CLEARING_ACCOUNT_REQUEST';
export const ADD_CLEARING_ACCOUNT_SUCCESS = 'ADD_CLEARING_ACCOUNT_SUCCESS';
export const ADD_CLEARING_ACCOUNT_FAILED = 'ADD_CLEARING_ACCOUNT_FAILED';

export const REMOVE_CLEARING_ACCOUNT_REQUEST =
  'REMOVE_CLEARING_ACCOUNT_REQUEST';
export const REMOVE_CLEARING_ACCOUNT_SUCCESS =
  'REMOVE_CLEARING_ACCOUNT_SUCCESS';
export const REMOVE_CLEARING_ACCOUNT_FAILED = 'REMOVE_CLEARING_ACCOUNT_FAILED';

export const MEMBER_STATES_REQUEST = 'MEMBER_STATES_REQUEST';
export const MEMBER_STATES_SUCCESS = 'MEMBER_STATES_SUCCESS';
export const MEMBER_STATES_FAILED = 'MEMBER_STATES_FAILED';

//* Reducer */
function selectedUserReducer(state = '', action) {
  switch (action.type) {
    case SELECT_USER:
      return action.payload;
    default:
      return state;
  }
}

const addIdxAsProp = (notes) =>
  notes.map((note, index) => ({ idx: index, ...note }));

const isUpdateAssignedEmployeeUserId = (action) => {
  const updateRequest = action?.req?.update;
  return (
    updateRequest && updateRequest.hasOwnProperty('assignedEmployeeUserId')
  );
};

export function usersReducer(state = {}, action) {
  switch (action.type) {
    case fetchUsers.SUCCESS:
      const {
        payload: { req, ...rest },
        ...restAction
      } = action;
      const newAction = { ...restAction, payload: { ...rest } };
      return createById(fetchUsers, 'users', 'userId')(state, newAction);
    case createAndLinkClearingAccount.SUCCESS:
      return {
        ...state,
        [action.payload.userId]: action.payload,
      };
    case fetchMemberSecurityQuestions.SUCCESS:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          securityQuestions: action.payload.questions,
        },
      };
    case fetchPii.SUCCESS:
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          pii: {
            ...action.payload,
          },
        },
      };
    case UPDATE_USER_SUCCESS:
      if (isUpdateAssignedEmployeeUserId(action)) {
        return {
          ...state,
          [action.req.userId]: {
            ...state[action.req.userId],
            assignedEmployeeUserId:
              action.data[action.req.userId].assignedEmployeeUserId,
            updatedAt: action.data[action.req.userId].updatedAt,
          },
        };
      }
      return {
        ...state,
        ...action.data,
      };
    case CREATE_MEMBER_SUCCESS:
    case ADD_CLEARING_ACCOUNT_SUCCESS:
    case REMOVE_CLEARING_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case fetchMemberUserNotes.SUCCESS:
      let notes = addIdxAsProp(action.payload.notes);
      let payload = sortTimeArrayNewerFirst(notes);
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          notes: [...payload],
        },
      };
    case addNoteToMemberUser.SUCCESS:
      notes = addIdxAsProp(action.payload.notes);
      payload = sortTimeArrayNewerFirst(notes);
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          notes: [...payload],
        },
      };
    case editNoteOnMemberUser.SUCCESS:
      notes = addIdxAsProp(action.payload.notes);
      payload = sortTimeArrayNewerFirst(notes);
      return {
        ...state,
        [action.payload.userId]: {
          ...state[action.payload.userId],
          notes: [...payload],
        },
      };
    default:
      return state;
  }
}

const currentUser = handleAction(
  fetchCurrentUser.SUCCESS,
  (state, action) => action.payload,
  {},
);

function states(state = [], action) {
  switch (action.type) {
    case MEMBER_STATES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function roles(state = [], action) {
  switch (action.type) {
    case fetchEmployeeRoles.SUCCESS:
      return _.get(action, ['payload', 'roles'], []);
    default:
      return state;
  }
}

function permissions(state = [], action) {
  switch (action.type) {
    case fetchMemberUserPermissions.SUCCESS:
      return _.get(action, ['payload'], []);
    default:
      return state;
  }
}

function memberUserTypes(state = [], action) {
  switch (action.type) {
    case fetchMemberUserTypes.SUCCESS:
      return _.get(action, ['payload'], []);
    default:
      return state;
  }
}

function selectedMemberUserDocs(state = [], action) {
  switch (action.type) {
    case fetchMemberUserDocuments.SUCCESS:
      return _.get(action, ['payload'], []);
    default:
      return state;
  }
}

function complianceQuestions(state = [], action) {
  switch (action.type) {
    case fetchMemberComplianceQuestions.SUCCESS:
      return { ...state, [action.payload.userId]: action.payload };
    default:
      return state;
  }
}

function images(state = [], action) {
  switch (action.type) {
    case fetchMemberImages.SUCCESS:
      return { ...state, [action.payload.userId]: action.payload };
    default:
      return state;
  }
}

const usersCountCondition =
  (routine) =>
  (state = 0, action) =>
    createCount(routine)(state, action);

const usersCurrentPageCondition =
  (routine) =>
  (state = 0, action) =>
    createCurrentPage(routine)(state, action);

export const usersPagesCondition =
  (routine, slice, id) =>
  (state = {}, action) =>
    createPages(routine, slice, id)(state, action);

export default combineReducers({
  selectedUserId: selectedUserReducer,
  byId: usersReducer,
  currentUser,
  states,
  complianceQuestions,
  images,
  roles,
  permissions,
  memberUserTypes,
  selectedMemberUserDocs,
  count: usersCountCondition(fetchUsers),
  pages: usersPagesCondition(fetchUsers, 'users', 'userId'),
  currentPage: usersCurrentPageCondition(fetchUsers),
});

//* Selectors */

export const getUserIdFromURL = () => {
  const match = /(member_users|employees|onboarding)\/([-A-Za-z0-9]*)/.exec(
    window.location.href,
  );
  if (!match) return '';
  return match[2];
};

export const getSelectedUserId = (state) => {
  if (!_.get(state.users, 'selectedUserId')) {
    return getUserIdFromURL();
  }
  return _.get(state.users, 'selectedUserId');
};

//* get user details */
export const getUserCollection = (state) => _.get(state.users, 'byId', {});
const getAccountsCollection = (state) => _.get(state.accounts, 'byId', {});
const getAllComplianceQuestions = (state) =>
  _.get(state, ['users', 'complianceQuestions'], {});
const getAllMemberImages = (state) => _.get(state, ['users', 'images'], {});

//* returns the users as an array of the specified type */
export const getUsersList = (type) => (state) => {
  const usersCollection = getUserCollection(state);
  if (_.isEmpty(usersCollection)) {
    return [];
  }
  // return all users in store if props were not provided
  if (!type) {
    return Object.values(usersCollection);
  }
  return Object.values(usersCollection).filter(
    (user) => _.get(user, 'type', '') === type,
  );
};

// returns the selected user
export const getSelectedUser = createSelector(
  [getUserCollection, getSelectedUserId],
  (users, selectedUserId) => _.get(users, selectedUserId, {}),
);

export const getUserPermissions = (state) =>
  _.get(state.users, ['permissions'], []);
export const getMemberUserTypes = (state) =>
  _.get(state.users, ['memberUserTypes'], []);

// returns the selected user with all permissions when it is 'null'
export const getSelectedUserWithPermissions = createSelector(
  [getSelectedUser, getUserPermissions, getApiCredentialPermissions],
  (selectedUser, allPermissions, allApiKeyPermissions) => {
    let { permissions, apiKeyPermissions } = selectedUser;
    // if selectedUser has null permissions or apiKeyPermissions, return all of them
    if (!permissions) {
      permissions = allPermissions;
    }
    if (!apiKeyPermissions) {
      apiKeyPermissions = allApiKeyPermissions;
    }
    return {
      ...selectedUser,
      permissions,
      apiKeyPermissions,
    };
  },
);

//* get security questions */
export const getSecurityQuestions = createSelector([getSelectedUser], (user) =>
  _.get(user, 'securityQuestions', []),
);

export const getNotes = createSelector([getSelectedUser], (user) =>
  _.get(user, 'notes', []),
);

//* get compliance questions */
export const getComplianceQuestions = createSelector(
  [getSelectedUserId, getAllComplianceQuestions],
  (selectedUserId, questions) => _.get(questions, selectedUserId, {}).questions,
);

export const getMemberImages = createSelector(
  [getSelectedUserId, getAllMemberImages],
  (selectedUserId, images) => _.get(images, selectedUserId, {}),
);

// returns the current user
export const getCurrentUser = (state) => _.get(state.users, 'currentUser', {});
export const getCurrentAuthId = (state) =>
  _.get(state.users, ['currentUser', 'authId'], '');
// returns the curren user's categories
export const getCurrentUserCategories = createSelector(
  getCurrentUser,
  (currentUserObj) => _.get(currentUserObj, 'categories', []),
);
export const getCurrentUserUiViews = createSelector(
  getCurrentUser,
  (currentUserObj) => _.get(currentUserObj, 'uiViews', {}),
);

//* returns the linked accounts associated with the selected user */
export const getAccountsForSelectedUser = createSelector(
  [getSelectedUser, getAccountsCollection],
  (user, accounts) => {
    const clearingAccounts = _.map(
      _.get(user, 'accountLinks', []),
      'accountId',
    );
    return clearingAccounts.map((account) => accounts[account]);
  },
);

//* returns the list of linked accounts ids associated with the selected user */
export const getAccountIdsForSelectedUser = createSelector(
  [getSelectedUser],
  (user) => _.map(_.get(user, 'accountLinks', []), 'accountId'),
);

export const getAccountLinksForSelectedUser = createSelector(
  [getSelectedUser],
  (user) => _.get(user, 'accountLinks', []),
);

//* returns the list of clearing accounts */
export const getClearingAccountIdsForSelectedUser = createSelector(
  [getSelectedUser],
  (user) => _.map(_.get(user, 'accountLinks', []), 'accountId'),
);

// returns the possible user states, default are the user states
export const getUserStates = (state) =>
  _.get(state.users, ['states', 'state'], []);
export const getAppStates = (state) =>
  _.get(state.users, ['states', 'appState'], []);
export const getEmployeeRoles = (state) => _.get(state.users, ['roles'], []);
export const getCurrentUserRoles = (state) =>
  _.get(state.users, ['currentUser', 'roles'], []);

export const getSelectedMemberUserDocs = (state) =>
  _.get(state, ['users', 'selectedMemberUserDocs'], []);
//* action creators */
/**
* @param {Object} opts - specify the kind and details fo the user to create
+  'type' - the type of user. Must be one of 'member', 'admin', 'employee'
+  'userId' - the userId
+  'email' - the email
*/
export const createMember = (opts) => ({
  type: CREATE_MEMBER_REQUEST,
  payload: { ...opts },
});

/**
 * @param {string} userId - identifying the userId of the user that has been selected
 */

export const selectUser = (userId) => ({
  type: SELECT_USER,
  payload: userId,
});

/**
 * @param {Object} args - identifying the userId, new_email of the user that needs to be updated
 */
export const updateUser = (args) => ({
  type: UPDATE_USER_REQUEST,
  payload: args,
});

/**
 * @param {Object} args - identifying the userId,
 * new clearing accounts of the user that needs to be updated
 */
export const addClearingAccountToMember = createRoutine(
  ADD_CLEARING_ACCOUNT_REQUEST,
);

export const addClearingAccountToMemberPromiseCreator = promisifyRoutine(
  addClearingAccountToMember,
);

/**
 * @param {Object} args - identifying the userId,
 * which clearing accounts to delete from the user that needs to be updated
 */
export const removeClearingAccountFromMember = createRoutine(
  REMOVE_CLEARING_ACCOUNT_REQUEST,
);

export const removeClearingAccountFromMemberPromiseCreator = promisifyRoutine(
  removeClearingAccountFromMember,
);

/**
 * Get possible member states from the backend
 */
export const memberStates = () => ({
  type: MEMBER_STATES_REQUEST,
});
