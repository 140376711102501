import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import XTable7 from '../../common/table/XTable7';
import ipAuthorizationsHistoryMetadata from '../../metadata/ipAuthorizationsHistoryMetadata';
import {
  fetchIpAuthorizationHistory,
  getIpAuthorizationHistory,
} from '../../reducers/authorizationIpReducer';
import { createLoadingSelector } from 'erisxkit/client';

const IpAuthorizationsHistory = ({ selectedMember }) => {
  const dispatch = useDispatch();
  const ipAuthorizationHistoryObj = useSelector(getIpAuthorizationHistory);
  const ipAuthorizationsHistoryLoading = useSelector(
    createLoadingSelector([fetchIpAuthorizationHistory._PREFIX]),
  );

  const _fetchIpAuthorizationsHistory = () => {
    dispatch(
      fetchIpAuthorizationHistory({ memberIds: [selectedMember?.memberId] }),
    );
  };

  return (
    <XTable7
      data={ipAuthorizationHistoryObj?.ipAuthorizationHistory || []}
      metadata={ipAuthorizationsHistoryMetadata()}
      title="IPAuthorizationsHistory"
      fetchData={_fetchIpAuthorizationsHistory}
      loading={ipAuthorizationsHistoryLoading}
      showFilter={false}
      count={ipAuthorizationHistoryObj?.count || 0}
    />
  );
};

export default IpAuthorizationsHistory;
