import api from '../api';

export const quickHttpGet = async (route) => {
  const url = `${window.location.origin}${route}`;
  return await api.get(url);
};

export const quickHttpPost = async (route, payload) => {
  const url = `${window.location.origin}${route}`;
  return await api.post(url, payload);
};
