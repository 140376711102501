import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import snakeCase from 'lodash/snakeCase';
import {
  fetchMemberUserDocuments,
  getSelectedMemberUserDocs,
  getSelectedUser,
} from '../../reducers/usersReducer';
import { roles } from '../../constants/userRoles';
import Documents from '../../common/Documents';
import colors from '../../constants/colors';

const Header = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: ${colors.secondary2};
  margin-bottom: 20px;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChecksTable = styled.table`
  border-collapse: collapse;
  tr:nth-child(even) {
    background-color: white;
  }

  tr {
    height: 60px;
    background-color: ${colors.primary1};

    td:nth-child(odd) {
      font-weight: bold;
    }

    td {
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 10px;
    }
  }
`;

export const ALLOWED_ROLES_KYC_CHECKS = [
  roles.SUPER_USER,
  roles.ONBOARDING,
  roles.CLIENT_SERVICES,
  roles.TREASURY,
  roles.CLEARING_OPS,
  roles.FINANCE,
  roles.COMPLIANCE,
];

export const canUserSeeKycChecks = (currentUser) =>
  get(currentUser, 'roles', []).some((role) =>
    ALLOWED_ROLES_KYC_CHECKS.includes(role),
  );

const showData = (value) => (isArray(value) ? value.join(',') : value);
const RELIANCE_DOC_TYPE = 'reliance_kyc_document';

const KycChecks = () => {
  const selectedUser = useSelector(getSelectedUser);
  const docs = useSelector(getSelectedMemberUserDocs);
  const cipData = get(selectedUser, 'cipData', {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchMemberUserDocuments({
        urlParams: {
          userId: selectedUser?.userId,
        },
        queryParams: {
          docType: RELIANCE_DOC_TYPE,
        },
      }),
    );
  }, []);
  return (
    <Wrapper>
      <Header>Reliance KYC Documents</Header>
      <Documents
        noDocsText="No reliance KYC document provided"
        documents={docs.map((doc) => ({
          image: get(doc, 'docData', ''),
          createdAt: get(doc, 'createdAt', ''),
        }))}
      />
      <Header>KYC Checks</Header>
      <ChecksTable>
        <tr>
          <td>kyc_checks_ran</td>
          <td>{showData(get(cipData, 'kycChecksRan', '-'))}</td>
        </tr>
        <tr>
          <td>manual_review</td>
          <td>{showData(get(cipData, 'manualReview', '-'))}</td>
        </tr>
        <tr>
          <td>manual_review_details</td>
          <td>{showData(get(cipData, 'manualReviewDetail', '-'))}</td>
        </tr>
      </ChecksTable>
    </Wrapper>
  );
};

export default KycChecks;
