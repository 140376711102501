import React from 'react';
import get from 'lodash/get';
import { formatTimeLocal } from '../utils/time';
import { DateRangeColumnFilter } from '../common/table/Filters';

const promotionsMetadata = (onApprove, onReject, onFetch, initialDates) => [
  {
    Header: 'Date',
    id: 'time',
    accessor: 'time',
    filterable: false,
    width: 180,
    Filter: (props) => (
      <DateRangeColumnFilter {...props} defaultValues={initialDates} />
    ),
    Cell: (props) => (
      <div className="promotion-table-cell">
        <p className="ellipsis">
          {formatTimeLocal(get(props, 'row.original.time', ''))}
        </p>
      </div>
    ),
  },
  {
    Header: 'Status',
    id: 'state',
    accessor: () => 'state',
    filterable: false,
    width: 100,
    Cell: (props) => (
      <div className="promotion-table-cell status">
        <p style={{ textAlign: 'center', textTransform: 'capitalize' }}>
          {get(props, 'row.original.state', '')}
        </p>
      </div>
    ),
  },
  {
    Header: 'Description',
    id: 'description',
    accessor: 'description',
    filterable: false,
    width: 950,
    Cell: ({ row }) => {
      return (
        <div className="promotion-table-cell">
          <p className="ellipsis">{get(row, 'original.description', '')}</p>
        </div>
      );
    },
  },
  {
    Header: 'Action',
    id: 'action',
    filterable: false,
    minWidth: 240,
    Cell: ({ row, state }) => {
      const status = get(row, 'original.state', null);
      const reqId = get(row, 'original.uuid', '');

      return status === 'pending' ? (
        <div className="promotion-buttons-container">
          <button
            className="promotion-button approve"
            onClick={() => {
              onApprove(reqId);
              onFetch(state);
            }}
          >
            Approve
          </button>
          <button
            className="promotion-button reject"
            onClick={() => {
              onReject(reqId);
              onFetch(state);
            }}
          >
            Reject
          </button>
        </div>
      ) : null;
    },
  },
];
export default promotionsMetadata;
