import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';
import {
  fetchBlockTradeRequests,
  fetchBlockTradeRequestsV2,
  approveBlockTrade,
  rejectBlockTrade,
} from '../reducers/blockTradeRequestsReducer';

export default function* watch() {
  yield takeLatest(
    fetchBlockTradeRequests,
    generateSaga(fetchBlockTradeRequests, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    fetchBlockTradeRequestsV2,
    generateSaga(fetchBlockTradeRequestsV2, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    approveBlockTrade,
    generateSaga(approveBlockTrade, {
      method: HTTPMethod.POST,
      loading: 'Approving Block Trade...',
      success: 'Block Trade approved successfully!',
    }),
  );
  yield takeLatest(
    rejectBlockTrade,
    generateSaga(rejectBlockTrade, {
      method: HTTPMethod.POST,
      loading: 'Rejecting Block Trade...',
      success: 'Block Trade rejected successfully!',
    }),
  );
}
