const navItems = {
  alerts: [
    'systemAlerts',
    'changeLogs',
    'exceptions',
    'taskLogs',
    'triggerTasks',
  ],
  manualEntries: [
    'closingPrices',
    'feeRebate',
    'tradeEntry',
    'bankCommunication',
    'currenexAdjustment',
    'generalJournal',
  ],
  products: ['spotProducts'],
};

export default navItems;
