import _ from 'lodash';
import Moment from 'moment';

// Format time as a friendly "from now" string
export const formatTimeFromNow = (time) =>
  _.isEmpty(time) ? '' : new Moment(time).fromNow();

// Format time in "Browser Local" time (wherever the user is)
export const formatTimeLocal = (time) =>
  _.isEmpty(time)
    ? ''
    : new Moment(time).local().format('MMMM Do YYYY, h:mm:ss a');

// Format time in "Browser Local" time (wherever the user is)
export const formatDateLocal = (time) =>
  _.isEmpty(time) ? '' : new Moment(time).local().format('MMMM Do YYYY');

// Format time in "Browser Local" time (wherever the user is)
export const formatTimeLocalNumeric = (time) =>
  _.isEmpty(time)
    ? ''
    : new Moment(time).local().format('YYYY-MM-DD h:mm:ss Z');

// Format time to date
export const todaysDate = () => Moment().format('YYYY-MM-DD');

// Format time in "Browser Local" time, plus day of the week.
// Optional "condensed" parameter
export const formatTimeLocalWithDoW = (time, condensed = false) => {
  if (!_.isEmpty(time) && !condensed) {
    return Moment(time).local().format('dddd, MMMM Do YYYY, h:mm:ss a');
  } else if (!_.isEmpty(time)) {
    return Moment(time).local().format('ddd, ll LTS');
  }
  return '';
};

// Format time as a UTC string: YYYY-DD-MMTH:mm:ss.sssZ
export const formatUTC = (time) =>
  _.isEmpty(time) ? '' : Moment(time).utc().format();

// Get 6PM of current date. **not in UTC format.**
export const endingBusinessTime = () =>
  Moment().hours(18).minutes(0).seconds(0);
