import { get, filter } from 'lodash';
import { createRoutine } from 'redux-saga-routines';
import { createSelector } from 'reselect';

export const LINKED_MEMBER_ASSET_ACCOUNTS = 'LINKED_MEMBER_ASSET_ACCOUNTS';
export const CREATE_LINKED_MEMBER_BANK_ACCOUNT_ADMIN =
  'CREATE_LINKED_MEMBER_BANK_ACCOUNT_ADMIN';
export const UPDATE_LINKED_MEMBER_ASSET_ACCOUNT_ADMIN =
  'UPDATE_LINKED_MEMBER_ASSET_ACCOUNT_ADMIN';
export const CREATE_LINKED_MEMBER_CRYPTO_ADDRESS =
  'CREATE_LINKED_MEMBER_CRYPTO_ADDRESS';
export const DELETE_LINKED_MEMBER_ASSET_ACCOUNT =
  'DELETE_LINKED_MEMBER_ASSET_ACCOUNT';
export const CREATE_LINKED_MEMBER_COLLATERAL_ACCOUNT =
  'CREATE_LINKED_MEMBER_COLLATERAL_ACCOUNT';
const maskAccountNumber = '******';
/**
 * Action generator to fetch the linked bank accounts
 */
export const fetchLinkedMemberAssetAccounts = createRoutine(
  LINKED_MEMBER_ASSET_ACCOUNTS,
);
export const createLinkedMemberBankAccountAdmin = createRoutine(
  CREATE_LINKED_MEMBER_BANK_ACCOUNT_ADMIN,
);
export const updateLinkedMemberAssetAccountAdmin = createRoutine(
  UPDATE_LINKED_MEMBER_ASSET_ACCOUNT_ADMIN,
);
export const createLinkedMemberCryptoAddress = createRoutine(
  CREATE_LINKED_MEMBER_CRYPTO_ADDRESS,
);
export const deleteLinkedMemberAssetAccount = createRoutine(
  DELETE_LINKED_MEMBER_ASSET_ACCOUNT,
);
export const createLinkedMemberCollateralAccount = createRoutine(
  CREATE_LINKED_MEMBER_COLLATERAL_ACCOUNT,
);

//* Reducer */
export default function linkedAccountsReducer(state = [], action) {
  switch (action.type) {
    case fetchLinkedMemberAssetAccounts.SUCCESS:
      return action.payload;
    case updateLinkedMemberAssetAccountAdmin.SUCCESS:
      return {
        ...state,
        assetAccounts: state.assetAccounts.map((obj) =>
          obj.id === action.payload.id
            ? {
                ...obj,
                state: action.payload.state,
                additionalSsi: action.payload.additionalSsi,
              }
            : obj,
        ),
      };
    case createLinkedMemberBankAccountAdmin.SUCCESS:
    case createLinkedMemberCryptoAddress.SUCCESS:
    case createLinkedMemberCollateralAccount.SUCCESS:
      return {
        count: state.count + 1,
        assetAccounts: state.assetAccounts.concat([action.payload]),
      };
    default:
      return state;
  }
}

/* TODO: Refactor this into ErisXKit since it's common with ClearingPortal */
export const getLinkedAccountLabel = (linkedAccount) => {
  if (
    linkedAccount &&
    (linkedAccount.bankName || linkedAccount.accountNumber)
  ) {
    // Bank Account: "Bank Name - Label - *******AcctNum"
    return [
      get(linkedAccount, 'bankName'),
      get(linkedAccount, 'label'),
      [get(linkedAccount, 'accountNumber')]
        .filter((i) => i)
        .map((i) => `${maskAccountNumber}${i}`)[0],
    ]
      .filter((i) => i)
      .join(' - ');
  }

  // Crypto Address: "Label - Address"
  return [get(linkedAccount, 'label'), get(linkedAccount, 'address')]
    .filter((i) => i)
    .join(' - ');
};

/* Selectors */
export const getAllAssetAccounts = (state) =>
  get(state, ['linkedMemberAssetAccounts', 'assetAccounts'], []);

/* Pass props including filter to this selector to get linked accounts filtered by chosen properties/values */
export const getFilteredLinkedMemberAssetAccounts = createSelector(
  [getAllAssetAccounts, (_, props) => props.filter],
  (assetAccounts, filterObj) => filter(assetAccounts, filterObj),
);

/* Return a lookup function for retrieving one linked account by ID. */
export const getLinkedMemberAssetAccount = createSelector(
  [getAllAssetAccounts],
  (assetAccounts) => (id) => assetAccounts.find((a) => a.id === id),
);

/* Filtered by Crypto only */
export const getLinkedMemberCryptoAddresses = createSelector(
  getAllAssetAccounts,
  (assetAccounts) => assetAccounts.filter(({ type }) => type === 'crypto'),
);

/* Filtered by Bank (fiat) only */
export const getLinkedMemberBankAccounts = createSelector(
  getAllAssetAccounts,
  (assetAccounts) => assetAccounts.filter(({ type }) => type === 'bank'),
);

/* Filtered by Collateral only */
export const getLinkedMemberCollateralAccounts = createSelector(
  getAllAssetAccounts,
  (assetAccounts) => assetAccounts.filter(({ type }) => type === 'collateral'),
);
