import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { get } from 'lodash';

export const EXPORT_CORE_FILE = 'EXPORT_CORE_FILE';
export const CORE_FILES = 'CORE_FILES';

export const exportCoreFile = createRoutine(EXPORT_CORE_FILE);
export const coreFile = createRoutine(CORE_FILES);

//* Selectors  */
export const getCoreFileTasks = (state) =>
  Object.values(get(state, ['coreFiles', 'byId'], {}));
export const getCurrentTaskId = (state) =>
  get(state, ['coreFiles', 'currentTaskId'], '');

//* Reducer  */
export default handleActions(
  {
    [exportCoreFile.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: {
        ...get(state, 'byId', []),
        [payload.taskId]: {
          taskId: payload.taskId,
          state: 'Pending',
          value: '-',
        },
      },
      currentTaskId: payload.taskId,
    }),
    [coreFile.SUCCESS]: (state, { payload }) => ({
      ...state,
      byId: {
        ...get(state, 'byId', []),
        [payload.taskId]: {
          taskId: payload.taskId,
          state: payload.state,
          value: payload.value,
        },
      },
    }),
  },
  {},
);
