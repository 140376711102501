import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import {
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  createLoadingSelector,
} from 'erisxkit/client';
import { positionSummaryPromiseCreator } from '../../actions/accountsActions';
import {
  fetchMarginDelivery,
  getMarginDelivery,
  MARGIN_DELIVERY,
} from '../../reducers/riskReducer';
import { marginDeliveryMetadata } from '../../metadata/marginDeliveryMetadata';
import XTable7 from '../../common/table/XTable7';

export const getExpanded = (data, maxDepth = Infinity, depth = 0) => {
  if (data && depth <= maxDepth) {
    const result = {};
    data.forEach((element, index) => {
      const prefix = index.toString();
      result[prefix] = true;
      if (element.hasOwnProperty('subRows')) {
        const subResult = getExpanded(element.subRows, maxDepth, depth + 1);
        if (subResult) {
          Object.keys(subResult).forEach((key) => {
            if (depth + 1 <= maxDepth) {
              result[`${prefix}.${key}`] = true;
            }
          });
        }
      }
    });
    return result;
  }
};

const MarginDelivery = () => {
  const _marginDelivery = useSelector(getMarginDelivery);
  const loading = useSelector((state) =>
    createLoadingSelector([MARGIN_DELIVERY])(state),
  );
  const dispatch = useDispatch();
  const [expandColums, setExpandColums] = useState(false);

  const fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    let sort = [];
    let filter = [];
    if (filters && filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrValue(
          filters.map((each) => {
            if (each.id === 'account_id') {
              return { id: each.id, value: each?.value?.value, op: 'eq' };
            }
            return {
              ...each,
              op: 'eq',
            };
          }),
        ),
      );
    }

    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }

    setExpandColums(filter);

    dispatch(
      fetchMarginDelivery({
        limit: pageSize,
        offset: pageIndex * pageSize,
        page: pageIndex,
        filter,
        sort,
      }),
    );
  };

  let deep = -1;
  if (expandColums) {
    deep = expandColums.some(
      (e) => e.attr === 'product_symbol' || 'contract_symbol',
    )
      ? 0
      : deep;
    deep = expandColums.some((e) => e.attr === 'account_id') ? 1 : deep;
    deep = expandColums.some((e) => e.attr === 'cgm_number') ? 2 : deep;
  }

  return (
    <Fragment>
      <div className="flex-row space-between">
        <Header as="h1">Margin Delivery</Header>
      </div>
      <XTable7
        metadata={marginDeliveryMetadata()}
        data={_marginDelivery || []}
        fetchData={fetchData}
        showHeader={true}
        showPagination={true}
        expandRows
        loading={loading}
        expandedRowObj={deep > -1 && getExpanded(_marginDelivery, deep)}
      ></XTable7>
    </Fragment>
  );
};

export default MarginDelivery;
