import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { showModal, hideModal } from 'erisxkit/client';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import XTable7 from '../../common/table/XTable7';
import scheduledACHMetadata from '../../metadata/scheduledACHMetadata';
import {
  fetchUpcomingDeposits,
  deleteFiatDepositSchedulePromiseCreator,
  getUpcomingDeposits,
} from '../../reducers/upcomingDepositsReducer';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../../constants/modalTypes';

const filters = [
  {
    placeholder: 'Member',
    label: 'Member',
    component: Form.Input,
    name: 'member',
    id: 'member',
  },
  {
    placeholder: 'Account',
    label: 'Account',
    component: Form.Input,
    name: 'account',
    id: 'account',
  },
  {
    placeholder: 'Schedule',
    label: 'Schedule',
    component: Form.Input,
    name: 'schedule',
    id: 'schedule',
  },
  {
    placeholder: 'Date',
    label: 'Date',
    component: Form.Input,
    name: 'date',
    id: 'date',
  },
];
const fetchData = () => {};

const mapStateToProps = (state) => ({
  upcomingDeposits: getUpcomingDeposits(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators({ deleteFiatDepositSchedulePromiseCreator }, dispatch),
  ...bindActionCreators(
    {
      fetchUpcomingDeposits,
      showModal,
      hideModal,
    },
    dispatch,
  ),
});

class UpcomingDepositsContainer extends Component {
  componentDidMount = () => {
    this.props.fetchUpcomingDeposits();
  };

  deleteACHDeposit = (e) => {
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: `You have requested to cancel a recurring deposit`,
      hideModal: this.props.hideModal,
      closeOnEscape: true,
      confirmButtonText: 'Cancel Deposit',
      cancelButtonText: 'Keep Schedule',
      confirmButtonColor: 'red',
      Content: (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p
            style={{
              marginTop: '40px',
              marginBottom: '30px',
              fontSize: '18px',
            }}
          >
            The <b>{e.schedule}</b> scheduled deposit of <b>${e.amount}</b> to{' '}
            <b>{e.account}</b> will be removed
          </p>
        </div>
      ),
      onConfirm: (payload) => {
        this.props
          .deleteFiatDepositSchedulePromiseCreator({
            accountId: e.accountId,
            linkedMemberAssetAccountId: e.linkedMemberAssetAccountId,
          })
          .then(() => {
            this.props.hideModal();
            this.props.fetchUpcomingDeposits();
          })
          .catch((error) => {
            console.error(error);
            this.props.hideModal();
          });
      },
    });
  };

  render = () => {
    const { upcomingDeposits } = this.props;

    return (
      <Fragment>
        <XTable7
          title="risk-scheduled_ach"
          metadata={scheduledACHMetadata(this.deleteACHDeposit) || []}
          data={upcomingDeposits || []}
          height={'0vh'}
          fetchData={fetchData}
          showHeader={true}
          showFilter={false}
          showFooterQuantity={false}
          showPagination={false}
        />
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpcomingDepositsContainer);
