export default [
  'Eris::Clearing::CryptoTransactionAnalysis::Task',
  'Eris::Clearing::CryptoAddressAnalysis::Task',
  'Eris::Clearing::CryptoMemberAddressAnalysis::Task',
  'Eris::Clearing::CryptoMemberTransactionAnalysis::Task',
  'Eris::Clearing::CryptoAddressAnalyzer::Task',
  'Eris::Clearing::CryptoMemberAddressAnalyzer::Task',
  'Eris::Clearing::AMLHandler',
  'Eris::Clearing::ComplyAdvantageService',
];
