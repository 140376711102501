import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

/** Actions */
export const TRAVEL_RULE_TRANSACTIONS = 'TRAVEL_RULE_TRANSACTIONS';
export const SET_TRUST_PII = 'SET_TRUST_PII';

/** Routines */
export const fetchTravelRules = createRoutine(TRAVEL_RULE_TRANSACTIONS);
export const setTrustPii = createRoutine(SET_TRUST_PII);

/** Promises */
export const setTrustPiiPromise = promisifyRoutine(setTrustPii);

/** Reducer */
export default handleActions(
  {
    [fetchTravelRules.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  {},
);

/** Selectors */
const getTravelRuleState = (state) => get(state, 'travelRule', {});
export const getTravelRules = createSelector([getTravelRuleState], (state) =>
  get(state, 'result', []),
);
export const getTravelRulesCount = createSelector(
  [getTravelRuleState],
  (state) => get(state, 'count', 0),
);
