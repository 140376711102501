import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { showModal, hideModal } from 'erisxkit/client';
import { Menu, MenuItem, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { TradingParticipant } from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import {
  TRADING_PARTICIPANT_LINK_CGM,
  GENERIC_MODAL,
  TRADING_PARTICIPANT_LINK_CAR,
} from '../../../../constants/modalTypes';
import TextButton from '../../../../common/TextButton';
import colors from '../../../../constants/colors';
import { GenericModalProps } from '../../../../common/GenericModal';
import { deleteTradingParticipantPromise } from '../../../../reducers/participantManagementReducer';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import Account from '../../../../ts/models/Account.model';
import { RootState } from '../../../../ts/types/store';
import { getCustomerAccountFromMember } from '../../../../reducers/accountsReducer';

const Separator = styled.span`
  width: 1px;
  margin: 0px 5px;
  font-size: 19px;
  color: ${colors.secondary3};
`;

export enum ApprovedParticipantAction {
  LINK = 'link',
  UNLINK = 'unlink',
}

const ParticipantActions: React.FC<{
  participant: TradingParticipant;
  memberId: string;
  fetchData: (params: Record<string, string>) => void;
}> = ({ participant, memberId, fetchData }) => {
  const dispatch = useDispatch();
  const [timeoutId, setTimeoutId] = React.useState<null | NodeJS.Timeout>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const account: Account = useAppSelector((state: RootState) =>
    getCustomerAccountFromMember(state, memberId),
  );
  const accountLabel = account?.label;
  const handleOpen = useCallback(() => {
    setIsOpen(true);

    const id = setTimeout(() => {
      setIsOpen(false);
    }, 2500);

    setTimeoutId(id);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (timeoutId) clearTimeout(timeoutId);
  }, [timeoutId]);

  const onLinkCGM = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CGM, {
        action: ApprovedParticipantAction.LINK,
        participant,
        memberId,
        className: 'revert-overflow',
        fetchData,
      }),
    );
    handleClose();
  }, [dispatch, handleClose, participant, memberId]);

  const onLinkCAR = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CAR, {
        action: ApprovedParticipantAction.LINK,
        participant,
        memberId,
        className: 'revert-overflow',
        fetchData,
      }),
    );
    handleClose();
  }, [dispatch, handleClose, participant, fetchData]);

  const onDeleteParticipant = useCallback(async () => {
    const firmCode = participant?.participantFirmCode || '';
    try {
      await deleteTradingParticipantPromise(
        {
          tradingParticipantFirmCode: firmCode,
          urlParams: {
            accountLabel,
          },
        },
        dispatch,
      );
      dispatch(hideModal());
      fetchData();
    } catch (error) {
      dispatch(hideModal());
    }
  }, [dispatch, participant, accountLabel, fetchData]);

  const onDeleteClicked = useCallback(
    () =>
      dispatch(
        showModal(GENERIC_MODAL, {
          header: 'Delete Participant',
          content:
            'This will delete the Approved Participant. Do you want to continue?',
          cancelProps: {
            text: 'Cancel',
            onClick: () => dispatch(hideModal()),
          },
          confirmProps: {
            text: 'Delete',
            onClick: () => onDeleteParticipant(),
            danger: true,
          },
        } as GenericModalProps),
      ),
    [onDeleteParticipant],
  );

  return !isEmpty(participant) ? (
    <div>
      <Popup
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        on={['hover', 'click']}
        style={{
          borderRadius: '10px',
          padding: '0px',
          // Make popup container transparent without making content
          // inherit opacity
          backgroundColor: 'rgb(255 255 255 / 0%)',
        }}
        content={
          <Menu vertical style={{ width: '8rem' }}>
            <MenuItem onClick={onLinkCGM}>Link a CGM</MenuItem>
            <MenuItem onClick={onLinkCAR}>Link a CAR</MenuItem>
          </Menu>
        }
        trigger={<TextButton fontSize="14px" text="Link" />}
      />
      <Separator />
      <TextButton
        fontSize="14px"
        color={colors.error2}
        text="Delete"
        onClick={onDeleteClicked}
      />
    </div>
  ) : null;
};

export default ParticipantActions;
