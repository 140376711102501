import { isNumber, union, some } from 'lodash';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import * as actionTypes from '../constants/actionTypes';
import { createAssetType } from '../actions/utilActions';
import {
  fetchCurrenexBalances,
  createEmarketBalanceAdjustment,
  emarketBalanceAdjustments,
  fetchDesignatedBalanceDetails,
  fetchMultiAccountBalances,
  positions,
  positionSummary,
  positionsAggregated,
  createCloseoutInstruction,
  fetchEmarketAccountTypes,
  createAccount,
  positionTransferTypes,
  positionTransfers,
  createPositionTransfersRequest,
  updateAccount,
  fetchBalances,
} from '../actions/accountsActions';
import api, * as urls from '../api';
import { fetchRequestHistory } from '../reducers/accountHistoryReducer';
import {
  fetchUpcomingDeposits,
  deleteFiatDepositSchedule,
} from '../reducers/upcomingDepositsReducer';
import { createSaga } from '../utils/createSaga';
import { todaysDate } from '../utils/time';
import { fetchUsers } from '../reducers/usersReducer';
import {
  futuresDcoAccount,
  fetchAccountsV2,
  fetchFCMFirmCodes,
} from '../reducers/accountsReducer';
import { getActiveLedgerId } from '../reducers/ledgersReducer';
import ToastWithLink from '../common/components/ToastWithLink';
import { getEnv } from '../reducers/envReducer';
import generateSaga from '../utils/generateSaga';

function* fetchAccountsV2Saga({ payload }) {
  const { page, balances, showUsers } = payload;

  try {
    yield put(fetchAccountsV2.request());
    const ledgerId = yield select(getActiveLedgerId);
    if (ledgerId) {
      payload.filter.push({ attr: 'ledger_id', op: 'eq', value: ledgerId });
    }
    const result = yield call(api.post, urls.ACCOUNTS_V2_API_ENDPOINT, {
      ...payload,
      includeUsers: !!showUsers,
    });
    yield put(
      fetchAccountsV2.success({ ...result, ...(isNumber(page) && { page }) }),
    );
    // only call the balances method if it's asked for in the payload
    if (balances) {
      const balancesArgs = {
        accountIds: result.accounts.map((account) => account.accountId),
        closingPriceDate: todaysDate(),
        ledgerId,
      };
      yield put(fetchMultiAccountBalances(balancesArgs));
    }
  } catch (e) {
    yield put(fetchAccountsV2.failure(e.response));
  }
}
function* fetchAccounts(arg) {
  const currentPage = arg.payload.page;
  const { balances } = arg.payload;
  const { showUsers } = arg.payload;
  try {
    const ledgerId = yield select(getActiveLedgerId);
    const accountsResult = yield call(api.post, urls.ACCOUNTS_API_ENDPOINT, {
      ...arg.payload,
      ...(ledgerId && { ledgerId }),
    });
    // this will conditionally add the page attribute to the success payload if it exists.
    yield put({
      type: actionTypes.ACCOUNTS_SUCCESS,
      payload: {
        ...accountsResult,
        ...(isNumber(currentPage) && { page: currentPage }),
      },
    });
    // only call the balances method if it's asked for in the payload
    if (balances) {
      const balancesArgs = {
        accountIds: accountsResult.accounts.map((account) => account.accountId),
        closingPriceDate: todaysDate(),
        ledgerId,
      };
      const balancesResult = yield call(
        api.post,
        urls.MULTI_ACCOUNT_BALANCES_API_ENDPOINT,
        balancesArgs,
      );
      yield put(fetchMultiAccountBalances.success(balancesResult));
    }
    // only call users method if showUsers is enabled
    if (showUsers) {
      let uniqueUsers;
      // get all unique users from member_user property of an account
      if (accountsResult.accounts) {
        uniqueUsers = accountsResult.accounts.reduce(
          (users, account) => union(users, account.memberUsers),
          [],
        );
        // if there are users we need to call
        if (uniqueUsers.length) {
          // build the filter for users
          const usersArgs = {
            filter: [
              {
                attr: 'user_id',
                op: 'eq',
                value: uniqueUsers,
              },
            ],
          };
          // call users
          const usersResult = yield call(
            api.post,
            urls.USERS_API_ENDPOINT,
            usersArgs,
          );
          yield put(fetchUsers.success(usersResult));
        }
      }
    }
  } catch (e) {
    yield put({ type: actionTypes.ACCOUNTS_FAILED, payload: e.response });
  }
}

function* fetchRequestHistorySaga(arg) {
  try {
    yield put(fetchRequestHistory.request());
    const payload = yield call(
      api.post,
      urls.REQUEST_HISTORY_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchRequestHistory.success(payload));
  } catch (e) {
    yield put(fetchRequestHistory.failure(e.response));
  }
}

function* fetchAccountHistory(arg) {
  try {
    const payload = yield call(
      api.post,
      urls.ACCOUNT_HISTORY_API_ENDPOINT,
      arg.payload,
    );
    yield put({ type: actionTypes.ACCOUNT_HISTORY_SUCCESS, payload });
  } catch (e) {
    yield put({
      type: actionTypes.ACCOUNT_HISTORY_FAILED,
      payload: e.response,
    });
  }
}

function* getAssetTypes() {
  try {
    const assetTypes = yield call(api.get, urls.ASSET_TYPES_API_ENDPOINT);
    yield put({ type: actionTypes.ASSET_TYPES_SUCCESS, assetTypes });
  } catch (e) {
    yield put({ type: actionTypes.ASSET_TYPES_FAILED, payload: e.response });
  }
}

function* createAssetTypeRequest({ payload }) {
  try {
    yield call(api.post, urls.CREATE_ASSET_TYPE_API_ENDPOINT, payload);
    yield put(createAssetType.success());
    cogoToast.success(
      `Approval request for asset type ${payload.symbol} created.`,
    );
  } catch (e) {
    yield put(createAssetType.failure(e.response));
  }
}

function* _createAccount({ payload = {} }) {
  const loadingCallback = cogoToast.loading('Creating account...', {
    hideAfter: 0,
  });
  let newAccount;
  try {
    const ledgerId = yield select(getActiveLedgerId);

    if (payload.type === 'finance') {
      newAccount = yield call(
        api.post,
        urls.CREATE_FINANCE_ACCOUNT_API_ENDPOINT,
        {
          ...payload.payload,
          ledgerId,
        },
      );
    }
    if (payload.type === 'clearing') {
      newAccount = yield call(
        api.post,
        urls.CREATE_CLEARING_ACCOUNT_API_ENDPOINT,
        {
          ...payload.payload,
          ledgerId,
        },
      );
    }
    if (newAccount) {
      yield put(createAccount.success({ [newAccount.accountId]: newAccount }));
      loadingCallback();
      ToastWithLink({
        type: 'success',
        item: 'account',
        href: `/${payload.type === 'finance' ? 'finance_accounts' : 'clearing_accounts'}/${
          newAccount.accountId
        }`,
      });
    }
  } catch (e) {
    loadingCallback();
    yield put(createAccount.failure(e.response));
  }
}

function* _updateAccount({ payload = {} }) {
  const loadingCallback = cogoToast.loading('Attempting to update account...', {
    hideAfter: 0,
  });
  try {
    const ledgerId = yield select(getActiveLedgerId);
    const { emarketAccountNumberCacheMaxTime } = yield select(getEnv);
    const account = yield call(api.post, urls.UPDATE_ACCOUNT_API_ENDPOINT, {
      ...payload,
      ledgerId,
    });

    yield put(updateAccount.success({ [account.accountId]: account }));

    // if unlocking or unflagging, an approval req gets created and is not instantaneous.
    if (
      some(payload, { lockedState: 'unlocked' }) ||
      some(payload, { amlState: 'unflagged' })
    ) {
      cogoToast.info(
        'An approval request has been created and is pending approval.',
      );
    } else {
      cogoToast.success(
        `Account updated successfully! Changes will take place for trades in ${emarketAccountNumberCacheMaxTime} seconds.`,
        { hideAfter: 3 },
      );
    }

    loadingCallback();
  } catch (e) {
    loadingCallback();
    yield put(updateAccount.failure(e.response));
  }
}

function* getUnusedWallets(arg) {
  try {
    const unusedWallets = yield call(
      api.post,
      urls.UNUSED_WALLETS_API_ENDPOINT,
      arg.payload,
    );
    yield put({ type: actionTypes.UNUSED_WALLETS_SUCCESS, unusedWallets });
  } catch (e) {
    yield put({ type: actionTypes.UNUSED_WALLETS_FAILED, payload: e.response });
  }
}

function* createEmarketBalanceAdjustmentRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Submitting approval request...', {
    hideAfter: 0,
  });
  try {
    yield call(
      api.post,
      urls.CREATE_EMARKET_BALANCE_ADJUSTMENT_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    cogoToast.info('Approval request created.');
    yield put(createEmarketBalanceAdjustment.success());
  } catch (e) {
    loadingCallback();
    yield put(createEmarketBalanceAdjustment.failure(e.response));
  }
}

function* emarketBalanceAdjustmentsRequest({ payload }) {
  try {
    const adjustments = yield call(
      api.post,
      urls.EMARKET_BALANCE_ADJUSTMENTS_API_ENDPOINT,
      payload,
    );
    yield put(emarketBalanceAdjustments.success(adjustments));
  } catch (e) {
    yield put(emarketBalanceAdjustments.failure(e.response));
  }
}

function* fetchDesignatedBalanceDetailsSaga({ payload }) {
  try {
    yield put(fetchDesignatedBalanceDetails.request());
    const ledgerId = yield select(getActiveLedgerId);
    const details = yield call(
      api.post,
      urls.DESIGNATED_BALANCE_DETAILS_API_ENDPOINT,
      {
        ...payload,
        ledgerId,
      },
    );
    yield put(
      fetchDesignatedBalanceDetails.success({
        details,
        rollup: payload.rollup,
      }),
    );
  } catch (e) {
    yield put(fetchDesignatedBalanceDetails.failure(e.response));
  }
}

const positionsRequest = createSaga(positions, actionTypes.POSITIONS);

export default function* watchAccounts() {
  yield takeLatest(actionTypes.ACCOUNTS_REQUEST, fetchAccounts);
  yield takeLatest(updateAccount.TRIGGER, _updateAccount);
  yield takeLatest(actionTypes.ACCOUNT_HISTORY_REQUEST, fetchAccountHistory);
  yield takeLatest(fetchRequestHistory.TRIGGER, fetchRequestHistorySaga);
  yield takeLatest(actionTypes.ASSET_TYPES_REQUEST, getAssetTypes);
  yield takeLatest(createAssetType.TRIGGER, createAssetTypeRequest);
  yield takeLatest(createAccount.TRIGGER, _createAccount);
  yield takeLatest(actionTypes.UNUSED_WALLETS_REQUEST, getUnusedWallets);
  yield takeLatest(
    fetchCurrenexBalances.TRIGGER,
    createSaga(fetchCurrenexBalances, actionTypes.CURRENEX_BALANCES),
  );
  yield takeLatest(
    createEmarketBalanceAdjustment.TRIGGER,
    createEmarketBalanceAdjustmentRequest,
  );
  yield takeLatest(
    emarketBalanceAdjustments.TRIGGER,
    emarketBalanceAdjustmentsRequest,
  );
  yield takeLatest(
    fetchDesignatedBalanceDetails.TRIGGER,
    fetchDesignatedBalanceDetailsSaga,
  );
  yield takeLatest(
    fetchMultiAccountBalances.TRIGGER,
    createSaga(fetchMultiAccountBalances, actionTypes.MULTI_ACCOUNT_BALANCES),
  );
  yield takeLatest(positions.TRIGGER, positionsRequest);
  yield takeLatest(
    positionsAggregated.TRIGGER,
    createSaga(positionsAggregated, actionTypes.POSITIONS_AGGREGATED),
  );
  yield takeLatest(
    createCloseoutInstruction.TRIGGER,
    createSaga(
      createCloseoutInstruction,
      actionTypes.CREATE_CLOSEOUT_INSTRUCTION,
      'Attempting to closeout positions...',
      'Positions closed successfully.',
    ),
  );
  yield takeLatest(
    futuresDcoAccount.TRIGGER,
    createSaga(futuresDcoAccount, actionTypes.FUTURES_DCO_ACCOUNT),
  );
  yield takeLatest(
    fetchEmarketAccountTypes.TRIGGER,
    createSaga(fetchEmarketAccountTypes, actionTypes.EMARKET_ACCOUNT_TYPES),
  );
  yield takeLatest(fetchAccountsV2.TRIGGER, fetchAccountsV2Saga);
  yield takeLatest(
    fetchUpcomingDeposits.TRIGGER,
    createSaga(fetchUpcomingDeposits, actionTypes.UPCOMING_DEPOSITS),
  );
  yield takeLatest(
    positionSummary.TRIGGER,
    createSaga(positionSummary, actionTypes.POSITION_SUMMARY),
  );
  yield takeLatest(
    fetchFCMFirmCodes.TRIGGER,
    createSaga(fetchFCMFirmCodes, fetchFCMFirmCodes._PREFIX),
  );
  yield takeLatest(
    deleteFiatDepositSchedule.TRIGGER,
    createSaga(
      deleteFiatDepositSchedule,
      actionTypes.DELETE_FIAT_DEPOSIT_SCHEDULE,
      'Deleting Deposit Schedule...',
    ),
  );
  yield takeLatest(positionTransfers.TRIGGER, generateSaga(positionTransfers));
  yield takeLatest(
    positionTransferTypes.TRIGGER,
    generateSaga(positionTransferTypes),
  );
  yield takeLatest(
    createPositionTransfersRequest.TRIGGER,
    generateSaga(createPositionTransfersRequest, {
      loading: 'Creating Position Transfer Request',
      success: 'Position Transfer Request created',
    }),
  );
  yield takeLatest(fetchBalances.TRIGGER, generateSaga(fetchBalances));
}
