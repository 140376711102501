import React from 'react';
import moment from 'moment';
import { Label } from 'semantic-ui-react';

const stateToColor = (state) => {
  switch (state) {
    case 'succeeded':
      return 'green';
    case 'failed':
      return 'red';
    case 'pending':
    default:
      return 'yellow';
  }
};

const emarketBalanceAdjustmentsMetadata = [
  {
    Header: '',
    columns: [
      {
        Header: 'Request ID',
        accessor: 'requestId',
        id: 'requestId',
      },
      {
        Header: 'Account ID',
        id: 'accountId',
        accessor: (row) => (
          <a href={`/clearing_accounts/${row.accountId}`}>{row.accountId}</a>
        ),
      },
      {
        Header: 'Source Type',
        accessor: 'sourceType',
        id: 'sourceType',
      },
      {
        Header: 'State',
        id: 'state',
        accessor: (row) => (
          <Label color={stateToColor(row.state)} content={row.state} />
        ),
        width: 90,
      },
      {
        Header: 'Time',
        id: 'time',
        accessor: (row) => moment(row.time).format('ddd LL LTS'),
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        id: 'amount',
        width: 80,
      },
      {
        Header: 'Asset Type',
        accessor: 'assetType',
        id: 'assetType',
        width: 75,
      },
    ],
  },
];

export default emarketBalanceAdjustmentsMetadata;
