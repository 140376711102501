import React from 'react';
import { Form } from 'semantic-ui-react';

const getError = (error, minimal) => (error && minimal ? true : error);

export const renderField = ({
  input,
  required,
  placeholder,
  meta: { touched, error, warning },
  'data-cy': dataCy = '',
  className = '',
  minimal = false,
  ...rest
}) => (
  <Form.Field data-cy={dataCy} className={className}>
    <Form.Input
      placeholder={required ? `${placeholder} *` : placeholder}
      {...input}
      error={touched && (getError(error, minimal) || warning)}
      {...rest}
      autoComplete="off"
    />
    {touched &&
      ((error && minimal && <small className="helper error">{error}</small>) ||
        (warning && !minimal && (
          <small className="helper error">{warning}</small>
        )))}
  </Form.Field>
);
