import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { hideModal } from 'erisxkit/client';
import CreateManualExternalTransaction from '../components/CreateManualExternalTransaction';
import {
  createManualExternalTransaction,
  getSelectedAccountId,
} from '../reducers/reconciliationReducer';

const mapDispatchToProps = {
  hideModal,
  createManualExternalTransaction,
};

const mapStateToProps = (state, props) => ({
  values: getFormValues('create_manual_external_transaction')(state),
  accountId: getSelectedAccountId(state, props),
});

class CreateManualExternalTransactionContainer extends Component {
  handleSubmit = (e) => {
    this.props.createManualExternalTransaction({
      ...this.props.values,
      accountId: this.props.accountId,
    });
    this.props.hideModal();
  };

  render = () => (
    <CreateManualExternalTransaction
      handleSubmit={this.handleSubmit}
      hideModal={this.props.hideModal}
    />
  );
}

CreateManualExternalTransactionContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateManualExternalTransactionContainer);
