import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { createLoadingSelector } from 'erisxkit/client';
import BankAccounts from '../../components/MemberUsers/BankAccounts';
import CryptoAddresses from '../../components/MemberUsers/CryptoAddresses';
import { showModal, hideModal } from 'erisxkit/client';
import {
  ADD_BANK_ACCOUNT,
  ADD_CRYPTO_ADDRESS,
  ADMIN_KEY,
  OVERRIDE_TX_DESTINATION,
} from '../../constants/modalTypes';
import bankAccountsMetadata from '../../metadata/bankAccountsMetadata';
import cryptoAddressMetadata from '../../metadata/cryptoAddressMetadata';
import {
  getCurrentAuthId,
  getSelectedUserId,
  getSelectedUser,
} from '../../reducers/usersReducer';
import { signMsg } from '../../utils/methods';
import {
  fetchLinkedBankAccounts,
  fetchLinkedCryptoAddresses,
  createLinkedCryptoAddress,
  createLinkedBankAccountAdmin,
  getLinkedCryptoAddresses,
  getLinkedBankAccounts,
} from '../../reducers/linkedAccountsReducer';
import {
  generateHashIdPromiseCreator,
  removeTxAuthHoldDelay,
} from '../../reducers/authReducer';
import { getCryptoAssetTypesAsOptions } from '../../selectors';
import { memberUser } from '../../constants/userTypes';
import DeprecationNotice from '../../common/components/DeprecationNotice';

const mapStateToProps = (state) => ({
  linkedCryptoAddresses: getLinkedCryptoAddresses(state),
  linkedBankAccounts: getLinkedBankAccounts(state),
  linkedBankAccountsLoading: createLoadingSelector(['LINKED_BANK_ACCOUNTS'])(
    state,
  ),
  selectedUserId: getSelectedUserId(state),
  values: _.get(state.form, ['add_linked_account', 'values'], {}),
  authId: getCurrentAuthId(state),
  selectedUser: getSelectedUser(state),
  assetTypeOptions: getCryptoAssetTypesAsOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      generateHashIdPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      showModal,
      hideModal,
      fetchLinkedBankAccounts,
      fetchLinkedCryptoAddresses,
      createLinkedBankAccountAdmin,
      createLinkedCryptoAddress,
      removeTxAuthHoldDelay,
    },
    dispatch,
  ),
});

class LinkedAccountsContainer extends Component {
  state = {
    activeIndex: 0,
    selectedLinkedAccount: {},
  };

  componentDidMount = () => {
    this.props.fetchLinkedBankAccounts({ memberId: this.props.selectedUserId });
  };

  getLinkedAccounts = (index) => {
    if (index === 0) {
      this.props.fetchLinkedBankAccounts({
        memberId: this.props.selectedUserId,
      });
    } else {
      this.props.fetchLinkedCryptoAddresses({
        memberId: this.props.selectedUserId,
      });
    }
  };

  handleTabChange = (e, data) =>
    this.setState(
      {
        activeIndex: data.activeIndex,
      },
      this.getLinkedAccounts(data.activeIndex),
    );

  addNew = () => {
    this.props.showModal(
      this.state.activeIndex === 0 ? ADD_BANK_ACCOUNT : ADD_CRYPTO_ADDRESS,
      {
        hideModal: this.props.hideModal,
        confirm: this.showFundingPassword,
        assetTypes: this.props.assetTypeOptions,
      },
    );
  };

  override = (selectedLinkedAccount) => {
    this.setState({
      selectedLinkedAccount,
    });
    this.props.showModal(OVERRIDE_TX_DESTINATION, {
      hideModal: this.props.hideModal,
      confirm: this.showFundingPasswordOverride,
      selectedLinkedAccount,
    });
  };

  showFundingPassword = () => {
    this.props.showModal(ADMIN_KEY, { submit: this.confirm });
  };

  showFundingPasswordOverride = () => {
    this.props.showModal(ADMIN_KEY, {
      submit: this.confirmRemoveTxAuthHoldDelay,
    });
  };

  confirmRemoveTxAuthHoldDelay = (password) => {
    const selectedUserAuthId = _.get(this.props.selectedUser, 'authId');
    const userId = this.props.selectedUserId;
    const linkedAccountId = _.get(this.state.selectedLinkedAccount, 'id', '');

    let type;
    let msg;
    // if we're overriding a bank
    if (this.state.activeIndex === 0) {
      type = 'fiat';
      msg = [
        selectedUserAuthId,
        this.state.selectedLinkedAccount.hashId,
        this.props.authId,
        type,
        Date.now().toString(),
      ];
    }
    // if we're overriding a crypto addr
    if (this.state.activeIndex === 1) {
      type = 'crypto';
      msg = [
        selectedUserAuthId,
        this.state.selectedLinkedAccount.address,
        this.props.authId,
        type,
        Date.now().toString(),
      ];
    }
    const sig = signMsg(this.props.authId, password, msg);
    this.props.removeTxAuthHoldDelay({
      userId,
      linkedAccountId,
      msg,
      sig,
    });
  };

  confirm = (password) => {
    if (this.state.activeIndex === 0) {
      this.props
        .generateHashIdPromiseCreator({
          accountNumber: this.props.values.accountNumber,
          routingNumber: this.props.values.routingNumber,
        })
        .then((successPayload) => {
          const msg = [
            this.props.selectedUser.authId,
            successPayload.hashId,
            'fiat',
            Date.now().toString(),
          ];
          const sig = signMsg(this.props.authId, password, msg);
          const payload = {
            ...this.props.values,
            memberId: this.props.selectedUserId,
            sig,
            msg,
          };
          this.props.createLinkedBankAccountAdmin(payload);
          this.props.hideModal();
          this.props.hideModal();
        });
    } else if (this.state.activeIndex === 1) {
      const msg = [
        this.props.selectedUser.authId,
        this.props.values.address,
        'crypto',
        Date.now().toString(),
      ];
      const sig = signMsg(this.props.authId, password, msg);
      const payload = {
        ...this.props.values,
        memberId: this.props.selectedUserId,
        sig,
        msg,
      };
      this.props.createLinkedCryptoAddress(payload);
      this.props.hideModal();
      this.props.hideModal();
    }
  };

  render() {
    const panes = [
      {
        url: 'bank_accounts',
        menuItem: 'Bank Accounts',
        render: () => (
          <BankAccounts
            data={this.props.linkedBankAccounts}
            metadata={bankAccountsMetadata(this.override)}
          />
        ),
      },
      {
        url: 'crypto_addresses',
        menuItem: 'Crypto Addresses',
        render: () => (
          <CryptoAddresses
            data={this.props.linkedCryptoAddresses}
            metadata={cryptoAddressMetadata(this.override)}
          />
        ),
      },
    ];

    return (
      <Fragment>
        {/* temp code to add deprecation notice to this page */}
        {window.location.href.includes(memberUser) && (
          <DeprecationNotice>
            This functionality has been deprecated for Member Users. Please
            manage SSI from the {<NavLink to="/members"> Members</NavLink>}{' '}
            view.
          </DeprecationNotice>
        )}
        <Tab panes={panes} onTabChange={this.handleTabChange} />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkedAccountsContainer);
