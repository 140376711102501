import { get } from 'lodash';
import cogoToast from 'cogo-toast';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from '../constants/actionTypes';
import { fetchApprovalLogs, approve } from '../actions/approvalReqsActions';
import api, * as urls from '../api';
import { reject } from '../reducers/approvalReqsReducer';
import { createSaga } from '../utils/createSaga';

function* fetchApprovalReqs(arg) {
  try {
    const approvalReqs = yield call(
      api.post,
      urls.APPROVAL_REQS_API_ENDPOINT,
      arg.payload,
    );
    yield put({
      type: actionTypes.APPROVAL_REQS_SUCCESS,
      payload: approvalReqs,
    });
  } catch (e) {
    yield put({ type: actionTypes.APPROVAL_REQS_FAILED, payload: e.response });
  }
}

function* fetchApprovalLogsSaga(arg) {
  try {
    yield put(fetchApprovalLogs.request());
    const approvalReqs = yield call(
      api.post,
      urls.APPROVAL_LOGS_API_ENDPOINT,
      arg.payload,
    );
    yield put(fetchApprovalLogs.success(approvalReqs));
  } catch (e) {
    yield put(fetchApprovalLogs.success(e.response));
  }
}

function* fetchApprovalReqPayload(arg) {
  try {
    const approvalReq = yield call(
      api.post,
      urls.APPROVAL_REQ_PAYLOAD_API_ENDPOINT,
      { uuid: arg.payload },
    );
    yield put({
      type: actionTypes.APPROVAL_REQ_PAYLOAD_SUCCESS,
      payload: approvalReq,
    });
  } catch (e) {
    yield put({
      type: actionTypes.APPROVAL_REQ_PAYLOAD_FAILED,
      payload: e.response,
    });
  }
}

function* _approve(arg) {
  let closeToast;
  try {
    closeToast = cogoToast.loading('Approving approval request...', {
      hideAfter: 0,
    });
    const approveReq = yield call(api.post, urls.APPROVE_API_ENDPOINT, {
      uuid: arg.payload,
    });
    // only do approve success if it's been fully approved.
    if (get(approveReq, 'state', '') === 'approved') {
      cogoToast.success('Request successfully approved.', { hideAfter: 3 });
      closeToast();
      yield put(approve.success(approveReq));
    } else {
      const reqApprovals = get(approveReq, 'reqApprovals', '');
      const approvals = get(approveReq, 'approvals', []).length;
      yield put(approve.success(approveReq));
      closeToast();
      cogoToast.success(`Request approved. (${approvals}/${reqApprovals})`, {
        hideAfter: 3,
      });
    }
  } catch (e) {
    closeToast();
    yield put(approve.failure(e.response));
  }
}

export default function* watchApprovalReqs() {
  yield takeLatest(actionTypes.APPROVAL_REQS_REQUEST, fetchApprovalReqs);
  yield takeLatest(fetchApprovalLogs.TRIGGER, fetchApprovalLogsSaga);
  yield takeLatest(
    actionTypes.APPROVAL_REQ_PAYLOAD_REQUEST,
    fetchApprovalReqPayload,
  );
  yield takeLatest(approve.TRIGGER, _approve);
  yield takeLatest(
    reject.TRIGGER,
    createSaga(
      reject,
      reject._PREFIX,
      'Rejecting approval request...',
      'Request rejected',
    ),
  );
}
