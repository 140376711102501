import { takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../constants/actionTypes';
import { scaleBaseToMinUnit } from '../actions/utilActions';
import { createSaga } from '../utils/createSaga';

const scaleBaseToMinUnitSaga = createSaga(
  scaleBaseToMinUnit,
  actionTypes.SCALE_BASE_TO_MIN_UNIT,
);

export default function* watchUtils() {
  yield takeLatest(scaleBaseToMinUnit.TRIGGER, scaleBaseToMinUnitSaga);
}
