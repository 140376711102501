import _ from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import BigNumber from 'bignumber.js';
import { handleActions } from 'redux-actions';
import { arrayToObject, fundsDesignation, NON_SEG } from 'erisxkit/client';
import * as actionTypes from '../constants/actionTypes';
import {
  fetchCurrenexBalances,
  clearCurrenexBalances,
  createAccount,
  updateAccount,
} from '../actions/accountsActions';
import { getSelectedAccount as getSelectedAccountHistory } from './accountHistoryReducer';
import { ALL, CUSTODIAN } from '../constants/accountTypes';
import { createEmarketAccount } from './manualOnboardingReducer';
import {
  getTotalUSDValues,
  getAllDesignatedBalanceDetails,
} from './balancesReducer';
import { getAssetTypes } from '../selectors';
import { ORIGINS } from '../constants/origin';

/** Routines */
export const futuresDcoAccount = createRoutine(actionTypes.FUTURES_DCO_ACCOUNT);
export const fetchAccountsV2 = createRoutine(actionTypes.ACCOUNTS_V2);
export const fetchFCMFirmCodes = createRoutine(actionTypes.FCM_FIRM_CODES);

/** Promisified Routines */
export const futuresDcoAccountPromiseCreator =
  promisifyRoutine(futuresDcoAccount);
export const fetchAccountsV2Promise = promisifyRoutine(fetchAccountsV2);

function selectedAccountIdReducer(state = '', action) {
  switch (action.type) {
    case actionTypes.SELECT_ACCOUNT_HISTORY:
      return action.accountId;
    default:
      return state;
  }
}

function firmCodes(state = [], action) {
  switch (action.type) {
    case fetchFCMFirmCodes.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function byId(state = {}, action) {
  switch (action.type) {
    case actionTypes.ACCOUNTS_SUCCESS:
    case fetchAccountsV2.SUCCESS:
      return {
        ...state,
        ...arrayToObject(action.payload.accounts, 'accountId'),
      };
    case createAccount.SUCCESS:
    case updateAccount.SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case createEmarketAccount.SUCCESS:
      return {
        ...state,
        [action.payload.selectedAccountId]: {
          ...state[action.payload.selectedAccountId],
          ...action.payload,
        },
      };
    // switching ledgers should clear out old accounts
    case actionTypes.SWITCH_LEDGER:
      return {};
    default:
      return state;
  }
}

function count(state = 0, action) {
  switch (action.type) {
    case actionTypes.ACCOUNTS_SUCCESS:
    case fetchAccountsV2.SUCCESS:
      return action.payload.count;
    default:
      return state;
  }
}

const pages = (state = {}, action) => {
  let pageIds;
  switch (action.type) {
    case actionTypes.ACCOUNTS_SUCCESS:
    case fetchAccountsV2.SUCCESS:
      pageIds = action.payload.accounts.map((item) => item.accountId);
      return {
        ...state,
        [action.payload.page]: pageIds,
      };
    // switching ledgers should clear out old pages
    case actionTypes.SWITCH_LEDGER:
      return {};
    default:
      return state;
  }
};

const currentPage = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNTS_SUCCESS:
    case fetchAccountsV2.SUCCESS:
      return _.isNumber(action.payload.page) ? action.payload.page : state;
    default:
      return state;
  }
};

const currenexBalances = handleActions(
  {
    [fetchCurrenexBalances.SUCCESS]: (state, { payload }) => payload,
    [fetchCurrenexBalances.TRIGGER]: () => ({}),
    [clearCurrenexBalances.TRIGGER]: () => ({}),
  },
  {},
);

export default combineReducers({
  selectedAccountId: selectedAccountIdReducer,
  firmCodes,
  byId,
  count,
  currenexBalances,
  pages,
  currentPage,
});

//* selectors */
export const getDomain = (state) => _.get(state, 'accounts', {});
export const getAllAccountsMap = createSelector([getDomain], (state) =>
  _.get(state, 'byId', {}),
);
export const getAllAccountsList = createSelector(
  [getAllAccountsMap],
  (accountsMap) => Object.values(accountsMap),
);

export const getAllAccountsFromMember = createSelector(
  [getAllAccountsList, (_, memberId) => memberId],
  (accountList, memberId) =>
    accountList.filter((account) => account.memberId === memberId),
);

export const getCustomerAccountFromMember = createSelector(
  [getAllAccountsList, (_, memberId) => memberId],
  (accountList, memberId) => {
    const memberAccounts = getAllAccountsFromMember.resultFunc(
      accountList,
      memberId,
    );
    return memberAccounts.find((acc) => acc.origin === ORIGINS.CUSTOMER);
  },
);
export const getAllAccounts = (state) => _.get(state, ['accounts', 'byId'], {});
export const getFCMFirmCodes = (state) =>
  _.get(state, ['accounts', 'firmCodes'], []);
export const getAccountCount = (state) =>
  _.get(state, ['accounts', 'count'], {});
export const getAllAccountsArray = (state) =>
  Object.values(getAllAccounts(state));

export const getSelectedAccountId = (state) =>
  _.get(state, ['accounts', 'selectedAccountId']);
export const getSelectedAccount = createSelector(
  [getAllAccounts, getSelectedAccountId],
  (accounts, selectedAccountId) => _.get(accounts, selectedAccountId),
);

// provide a function for getting an account by ID.
export const getAccountSelector = createSelector(
  [getAllAccounts],
  (accounts) => (accountId) => _.get(accounts, accountId),
);

export const createAccountsSelector = (types) => (state) => {
  // returns all the accounts of the types provided
  const accountsArray = getAllAccountsArray(state);
  if (_(types).includes(ALL)) {
    return accountsArray;
  }
  return accountsArray.filter((account) =>
    _.get(account, 'categories', []).some((cat) => types.includes(cat)),
  );
};

export const getCustodianAccountsAsOptions = (state) =>
  createAccountsSelector([CUSTODIAN])(state).map((account) => ({
    key: account.accountId,
    value: account.accountId,
    text: account.accountCode,
    description: account.name,
  }));

export const createAccountsSelectorObject = (types) => (state) =>
  arrayToObject(createAccountsSelector(types)(state), 'accountId');

export const getCurrenexBalances = (state) =>
  _.get(state, ['accounts', 'currenexBalances'], {});

const getAccountIdsPaged = (state) => {
  const page = _.get(state, ['accounts', 'currentPage'], 0);
  const pageIds = _.get(state, ['accounts', 'pages'], [])[page];
  if (pageIds === undefined) {
    return [];
  }
  return pageIds;
};

export const getAccountsPaged = createSelector(
  [getAllAccounts, getAccountIdsPaged],
  (aById, aIds) => aIds.map((o) => aById[o]),
);

export const getAccountListWithBalances = createSelector(
  [getAccountsPaged, getTotalUSDValues],
  (accounts, balances) =>
    accounts.map((account) => ({
      ...account,
      balancesUSD: _.get(balances, account.accountId, {}).balances,
    })),
);

export const getCurrenexBalancesWithDetails = createSelector(
  [
    getCurrenexBalances,
    getAssetTypes,
    getAllDesignatedBalanceDetails,
    getSelectedAccountHistory,
  ],
  (currenexBalance, assetTypesData, allBalances, selectedAccount) => {
    const assetTypesWithFd = assetTypesData.flatMap((each) =>
      fundsDesignation.map((fd) => ({
        symbol: each.symbol,
        accountLabel: selectedAccount.label,
        accountId: selectedAccount.accountId,
        fd: fd.value,
      })),
    );
    const designatedBalanceDetails = allBalances.map((b) => ({
      ...b,
      accountLabel: selectedAccount.label,
      accountId: selectedAccount.accountId,
    }));
    const balanceDetailsWithCurrenex = assetTypesWithFd.map((assetType) => {
      const balanceDetailsData = designatedBalanceDetails.find(
        (balance) =>
          assetType.accountId === balance.accountId &&
          assetType.fd === balance.fd &&
          assetType.symbol.toLowerCase() === balance.assetType.toLowerCase(),
      );
      return {
        ...assetType,
        ...balanceDetailsData,
        purchasingPower: _.get(currenexBalance, [
          assetType.symbol.toLowerCase(),
          'purchasingPower',
        ]),
        currenexBalance: _.get(currenexBalance, [
          assetType.symbol.toLowerCase(),
          'balance',
        ]),
      };
    });
    // Display all TCS balances according to Designation. Any TME balance additional to the TCS balance should be displayed as a Non Seg balance for the asset.
    // Filter out all non-NonSeg balances that weren't in the original designatedBalanceDetails
    return balanceDetailsWithCurrenex.filter((each) => {
      if (
        _.find(designatedBalanceDetails, {
          assetType: each.symbol,
          fd: each.fd,
        })
      ) {
        return true;
      }
      // these are rows that are not in TSC, so only TME.  If there's no TME balance, then filter that out too.
      if (
        each.fd === fundsDesignation[NON_SEG].value &&
        !_.find(designatedBalanceDetails, { assetType: each.symbol }) &&
        each.currenexBalance &&
        !BigNumber(each.currenexBalance).isZero()
      ) {
        return true;
      }
      return false;
    });
  },
);

export const getAccountListOptions = createSelector(
  [getAllAccounts],
  (accounts) =>
    Object.values(accounts).map((acc) => ({
      key: acc?.accountId,
      description: acc?.accountId,
      value: acc?.accountId,
      text: acc?.name || '-',
    })),
);
