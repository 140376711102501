import React, { Fragment } from 'react';
import { Header, Button, Divider } from 'semantic-ui-react';

const CommonPageHeader = ({ headerProps, buttonProps }) => (
  <Fragment>
    <div className="flex-row space-between">
      <Header as="h1" {...headerProps} />
      <Button {...buttonProps} floated="right" />
    </div>
    <Divider />
  </Fragment>
);

export default CommonPageHeader;
