import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from 'erisxkit/client';
import {
  TradingParticipant,
  CGMRow,
} from '../../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import TextButton from '../../../../common/TextButton';
import { TRADING_PARTICIPANT_LINK_CGM } from '../../../../constants/modalTypes';
import { ApprovedParticipantAction } from './ParticipantActions';
import { NAKED_CAR_LABEL } from '../../../../reducers/participantManagementReducer';

const CgmActions: React.FC<{
  cgm: CGMRow;
  participant: TradingParticipant;
  memberId: string;
  fetchData: (params: Record<string, string>) => void;
}> = ({ cgm, participant, memberId, fetchData }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CGM, {
        action: ApprovedParticipantAction.UNLINK,
        cgm,
        participant,
        memberId,
        fetchData
      }),
    );
  }, [dispatch, cgm, participant, memberId]);

  if (cgm.cgmName === NAKED_CAR_LABEL) return null;
  return <TextButton text="Unlink CGM" onClick={onClick} fontSize="14" />;
};

export default CgmActions;
