import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hideModal } from 'erisxkit/client';
import {
  updateUser,
  addClearingAccountToMember,
  removeClearingAccountFromMember,
  getSelectedUser,
  memberStates,
  getUserStates,
  getAccountLinksForSelectedUser,
} from '../../reducers/usersReducer';
import { createAccountsSelector } from '../../reducers/accountsReducer';
import { fetchAccounts } from '../../actions/accountsActions';
import * as accountTypes from '../../constants/accountTypes';
import AddRemoveAccounts from '../../components/MemberUsers/AddRemoveAccounts';

const mapStateToProps = (state) => ({
  selectedUser: getSelectedUser(state),
  accountList: createAccountsSelector([accountTypes.OWNERSHIP])(state),
  accountLinksForSelectedUser: getAccountLinksForSelectedUser(state),
  userStates: getUserStates(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser,
      addClearingAccountToMember,
      removeClearingAccountFromMember,
      fetchAccounts,
      hideModal,
      memberStates,
    },
    dispatch,
  );

class AddRemoveAccountsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: true,
      userId: _.get(props.selectedUser, 'userId', ''),
      update: {},
      addAccounts: [],
      removeAccounts: [],
      userAccountLinks: props.accountLinksForSelectedUser,
    };
  }

  componentDidMount() {
    const { memberStates } = this.props;
    memberStates();
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({
  //     userAccountLinks: nextProps.accountLinksForSelectedUser,
  //   });
  // }

  saveUserDetails = () => {
    const { userId, addAccounts, removeAccounts } = this.state;

    if (addAccounts.length) {
      this.props.addClearingAccountToMember({
        userId,
        accountIds: addAccounts,
      });
    }
    if (removeAccounts.length) {
      this.props.removeClearingAccountFromMember({
        userId,
        accountIds: removeAccounts,
      });
    }
    this.props.hideModal();
  };

  removeAccount = (removedAccountId) => {
    this.setState({
      removeAccounts: this.state.removeAccounts.concat([removedAccountId]),
      userAccountLinks: _.filter(
        this.state.userAccountLinks,
        ({ accountId }) => !(accountId === removedAccountId),
      ),
      saveDisabled: false,
    });
  };

  closeModal = () => {
    this.props.hideModal();
  };

  addAccounts = (e, { value }) => {
    this.setState({
      addAccounts: value,
      saveDisabled: false,
    });
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.props.fetchAccounts({ accountIds: [searchQuery], limit: 10 });
  };

  render() {
    const user = this.props.selectedUser;
    const { saveDisabled, update, userAccountLinks } = this.state;
    const {
      addAccounts,
      removeAccount,
      saveUserDetails,
      closeModal,
      handleSearchChange,
    } = this;
    const { hideSidebar } = this.props;
    if (!user) {
      // TODO: return some error that the user is not found.
      return null;
    }
    const accounts = _.map(this.props.accountList, (account) => ({
      key: account.accountId,
      value: account.accountId,
      text: `${account.name} - ${account.accountId}`,
    }));

    const props = {
      user,
      addAccounts,
      removeAccount,
      accounts,
      saveUserDetails,
      saveDisabled,
      update,
      userAccountLinks,
      closeModal,
      handleSearchChange,
    };
    return <AddRemoveAccounts {...props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddRemoveAccountsContainer);
