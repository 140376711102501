import { connect } from 'react-redux';
import _ from 'lodash';

export const checkPermissions = (
  userPermissions,
  allowedPermissions,
  op = 'every',
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }
  const fn = _[op];
  if (typeof fn === 'function') {
    return _[op](allowedPermissions, (p) => {
      const [permission, subject] = p.split(':');
      return _.get(userPermissions, [subject, permission], false);
    });
  }
  return false;
};

const AccessControl = ({
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  op,
}) => {
  const permitted = checkPermissions(userPermissions, allowedPermissions, op);
  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
  op: 'every',
};

// Compose AccessControl component with redux

export default connect((state) => ({
  userPermissions: _.get(state, ['users', 'currentUser', 'uiViews'], []),
}))(AccessControl);
