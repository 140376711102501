import isObject from 'lodash/isObject';

/**
 * @param {Object} original
 * @param {Object} updated
 * @param {string[]} keysToKeep - optional parameter to whitelist keys
 * @description Makes a shallow diff of two objects.
 * @returns Object with the diff between original and updated objects.
 *          This object has the values of the updated object.
 *          Optionally includes values includes on the keysToKeep list
 */
const objectDiff = (original, updated, keysToKeep = []) => {
  // Return null if diff can not be calculated - empty object would imply no changes
  if (!original || !updated) return null;
  if (!isObject(original) || !isObject(updated)) return null;

  const originalKeys = Object.keys(original);
  const modifiedEntries = Object.entries(original).reduce((acc, curr) => {
    const [key, value] = curr;
    if (keysToKeep.includes(key)) return { ...acc, [key]: updated[key] };
    return updated[key] !== value ? { ...acc, [key]: updated[key] } : acc;
  }, {});
  const newEntries = Object.entries(updated)
    .filter((entry) => {
      const [key] = entry;
      return !originalKeys.includes(key);
    })
    .reduce((acc, curr) => {
      const [key, value] = curr;
      return { ...acc, [key]: value };
    }, {});
  return { ...modifiedEntries, ...newEntries };
};

export default objectDiff;
