import React, { Component } from 'react';
import { groupBy, forEach, every, map, get, isEmpty } from 'lodash';
import { Field, FieldArray } from 'redux-form';
import { Form, Button, Header, Table, Segment } from 'semantic-ui-react';
import { renderField, rules, bigSum, bigSumFixed } from 'erisxkit/client';
import FormProvider from '../../common/FormProvider';
import GeneralJournal from './GeneralJournal';

export const valuesByAssetType = (items = []) => {
  const assetTypeValues = groupBy(items, 'assetType');
  forEach(assetTypeValues, (v, k) => {
    assetTypeValues[k] = groupBy(v, 'fundsDesignation');
  });
  return assetTypeValues || {};
};

const footerRow = (data) => ({
  key: 'footer',
  error: !bigSum(data, (o) => o.amount).eq(0),
  content: bigSumFixed(data, (o) => o.amount),
});

const renderBodyRow = ({ accountId, amount, category }, i) => ({
  key: accountId || `row-${i}`,
  cells: [`${amount || '[amount]'} (${category || '[category]'})`],
});

export const SummaryTable = ({ header = '', data }) => (
  <Table
    className="extra-compact postings-table"
    striped
    collapsing
    headerRow={[header]}
    renderBodyRow={renderBodyRow}
    footerRow={[footerRow(data)]}
    tableData={data}
  />
);

export const submitDisabled = (items = []) => {
  if (isEmpty(items)) {
    return true;
  }
  const assetTypeValues = valuesByAssetType(items);
  const valid = every(assetTypeValues, (fds) =>
    every(fds, (values) => bigSum(values, (o) => o.amount).eq(0)),
  );
  return !valid;
};

export default class GeneralJournalAdvanced extends Component {
  editableColumnProps = {
    Cell: (props) => {
      const fieldProps = {
        props,
        component: renderField,
      };

      return <FieldArray name={props.column.id} {...fieldProps} />;
    },
  };

  columns = [
    { Header: 'Account', accessor: 'account', ...this.editableColumnProps },
    {
      Header: 'Funds Designation',
      accessor: 'fd',
      ...this.editableColumnProps,
    },
    {
      Header: 'Asset Type',
      accessor: 'assetType',
      ...this.editableColumnProps,
    },
    { Header: 'Category', accessor: 'category', ...this.editableColumnProps },
    { Header: 'Amount', accessor: 'amount', ...this.editableColumnProps },
    {
      Cell: (props) => (
        <Button
          negative
          content="Remove"
          onClick={() => this.removeRow(props)}
        />
      ),
      resizable: false,
    },
  ];

  render = () => {
    const { items = [] } = get(this.props, 'formValues', {});
    const assetTypeValues = valuesByAssetType(items);
    return (
      <FormProvider
        form="general-journal"
        onSubmit={this.props.handleSubmit}
        initialValues={{ items: [{}] }}
      >
        {({ handleSubmit, valid }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              label="Description"
              name="description"
              id="description"
              placeholder="Enter the description that will show up on member statements."
              component={renderField}
              required
              validate={[rules.required]}
            />
            <FieldArray
              name="items"
              component={GeneralJournal}
              activeLedgerId={this.props.activeLedgerId}
              categories={this.props.categories}
              valid={valid}
              assetTypes={this.props.assetTypes}
            />
            <Header content="Summary:" />
            <Segment tertiary className="extra-compact">
              <div className="flex-row">
                {map(
                  assetTypeValues,
                  (vals, key) =>
                    key !== 'undefined' &&
                    map(
                      vals,
                      (d, fd) =>
                        fd !== 'undefined' && (
                          <SummaryTable
                            key={`${key}-${fd}`}
                            header={`${key}-${fd}`}
                            data={assetTypeValues[key][fd]}
                          />
                        ),
                    ),
                )}
              </div>
            </Segment>
            <Form.Button
              type="submit"
              id="submit"
              floated="right"
              className="flex-right"
              primary
              disabled={submitDisabled(this.props.formValues) || !valid}
            >
              {' '}
              Submit{' '}
            </Form.Button>
          </Form>
        )}
      </FormProvider>
    );
  };
}
