import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import { ACCOUNTS_V2 } from '../../constants/actionTypes';
import {
  fetchAccountsV2,
  getAccountListOptions,
} from '../../reducers/accountsReducer';
import SearchSelect from './SearchSelect';

const SearcheableAccountSelect = ({ name, initialFilter, ...rest }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) =>
    createLoadingSelector([ACCOUNTS_V2])(state),
  );
  const accounts = useSelector(getAccountListOptions);
  useEffect(
    () => dispatch(fetchAccountsV2({ limit: 10, filter: initialFilter })),
    [],
  );

  return (
    <>
      <label style={{ fontWeight: 'bold' }}>Clearing Accounts</label>
      <SearchSelect
        fetch={fetchAccountsV2}
        loading={loading}
        options={accounts}
        searchAttr="name"
        multiple
        name={name}
        {...rest}
        searchText="Search for a Clearing Account"
        placeholder="Enter Account Name"
      />
    </>
  );
};

export default SearcheableAccountSelect;
