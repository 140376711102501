import React, { Fragment } from 'react';
import { renderTextArea } from 'erisxkit/client';
import { Field } from 'redux-form';
import { Button, Form, Modal } from 'semantic-ui-react';
import FormProvider from '../../common/FormProvider';

export default ({ onConfirm, handleSubmit, hideModal }) => (
  <FormProvider
    form="general-journal"
    onSubmit={handleSubmit}
    destroyOnUnmount={false}
  >
    {(formProps) => (
      <Fragment>
        <Modal.Header>
          Create General Journal{' '}
          <Button
            className="pull-right"
            circular
            size="mini"
            icon="times"
            onClick={hideModal}
          />
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={formProps.handleSubmit(onConfirm)}>
            <Field
              name="comment"
              id="comment"
              component={renderTextArea}
              label="Approval Request Comment"
              placeholder="Add a comment for the approval request."
            />
            <Modal.Actions className="modal-form-actions">
              <Form.Button onClick={() => hideModal()} content="Close" />
              <Form.Button
                primary
                id="confirm"
                content="Create new general journal"
              />
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Fragment>
    )}
  </FormProvider>
);
