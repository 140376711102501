import * as actionTypes from '../constants/actionTypes';

export default function feeRebateReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.CREATE_FEE_REBATE_SUCCESS:
      return {
        ...state,
        result: action.result,
      };
    default:
      return state;
  }
}
