import React from 'react';
import XTab from '../../common/tabs/XTab';
import sections from './riskConfigSections';
import RiskConfigTable from './RiskConfigTable';
import financialReviewMetricsMetadata from '../../metadata/riskConfigurationsMetadata/financialReviewMetricsMetadata';
import {
  FINANCIAL_REVIEW_METRICS_TEMPLATE,
  NSE_THRESHOLD_CONFIG_TEMPLATE,
  WINJAMMER_ACCOUNT_MAP_TEMPLATE,
} from './newRowTemplates';
import winjammerAccountMetadata from '../../metadata/riskConfigurationsMetadata/winjammerAccountMetadata';
import nseThresholdConfigMetadata from '../../metadata/riskConfigurationsMetadata/nseThresholdConfigMetadata';

const panes = [
  {
    url: 'financial_review_metrics',
    menuItem: 'Financial Review Metrics',
    render: () => (
      <RiskConfigTable
        configSection={sections.FINANCIAL_REVIEW_METRICS}
        metadata={financialReviewMetricsMetadata}
        editable
        template={FINANCIAL_REVIEW_METRICS_TEMPLATE}
        key={sections.FINANCIAL_REVIEW_METRICS}
      />
    ),
  },
  {
    url: 'winjammer_acct_map',
    menuItem: 'Winjammer Account Map',
    render: () => (
      <RiskConfigTable
        configSection={sections.WINJAMMER_ACCT_MAP}
        metadata={winjammerAccountMetadata}
        editable
        template={WINJAMMER_ACCOUNT_MAP_TEMPLATE}
        key={sections.WINJAMMER_ACCT_MAP}
      />
    ),
  },
  {
    url: 'nse_threshold_config',
    menuItem: 'NSE Threshold Config',
    render: () => (
      <RiskConfigTable
        configSection={sections.NSE_THRESHOLD_CONFIG}
        metadata={nseThresholdConfigMetadata}
        editable
        template={NSE_THRESHOLD_CONFIG_TEMPLATE}
        key={sections.NSE_THRESHOLD_CONFIG}
      />
    ),
  },
];

const FinancialTabs = () => {
  return <XTab panes={panes} />;
};

export default FinancialTabs;
