import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMemberUserNotes,
  getNotes,
  addNoteToMemberUser,
  editNoteOnMemberUser,
} from '../../reducers/usersReducer';
import { showModal, hideModal } from 'erisxkit/client';
import {
  EDIT_ONBOARDING_NOTE,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../constants/modalTypes';
import MemberUsersNotes from './MemberUsersNotes';

export default ({ selectedUser }) => {
  const dispatch = useDispatch();
  const userMemberNotes = useSelector(getNotes) || [];

  useEffect(() => {
    if (selectedUser.userId) {
      dispatch(fetchMemberUserNotes({ userId: selectedUser.userId }));
    }
  }, []);

  const handleAddNote = (msg) => {
    dispatch(addNoteToMemberUser({ userId: selectedUser.userId, msg: msg }));
  };

  const handleArchiveNote = (idx) => {
    dispatch(
      editNoteOnMemberUser({
        userId: selectedUser.userId,
        hidden: true,
        idx: idx,
      }),
    );
    dispatch(hideModal(EDIT_ONBOARDING_NOTE));
  };

  const showModalArchiveNote = (key) => {
    dispatch(
      showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
        closeOnEscape: true,
        header: 'Archive a note',
        msg: (
          <p className="memberUserOnboardingMain">
            Are you sure you want to archive this note? You cannot undo this
            operation
          </p>
        ),
        hideModal: () => dispatch(hideModal(CONFIRM_ACTION_WITH_PAYLOAD)),
        onConfirm: () => {
          handleArchiveNote(key);
        },
        confirmButtonText: 'Archive',
        key: key,
        confirmButtonColor: 'teal',
      }),
    );
  };

  const handleEditNote = (msg, idx) => {
    dispatch(
      editNoteOnMemberUser({ userId: selectedUser.userId, msg: msg, idx: idx }),
    );
    dispatch(hideModal(EDIT_ONBOARDING_NOTE));
  };

  const showModalEditNote = (noteDetails) => {
    dispatch(
      showModal(EDIT_ONBOARDING_NOTE, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(EDIT_ONBOARDING_NOTE)),
        updateNote: (msj) => handleEditNote(msj, noteDetails.idx),
        msg: noteDetails.msg,
        key: noteDetails.idx,
      }),
    );
  };

  return (
    <div className="xwizard-form-group">
      <h4></h4>
      <MemberUsersNotes
        addNote={handleAddNote}
        data={userMemberNotes}
        showModalEditNote={showModalEditNote}
        showModalArchiveNote={showModalArchiveNote}
      />
    </div>
  );
};
