import { call, takeLatest, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import {
  fetchLinkedBankAccounts,
  fetchLinkedCryptoAddresses,
  createLinkedCryptoAddress,
  createLinkedBankAccountAdmin,
} from '../reducers/linkedAccountsReducer';
import api, * as urls from '../api';

function* linkedBankAccounts({ payload }) {
  try {
    const accounts = yield call(
      api.post,
      urls.LINKED_BANK_ACCOUNTS_API_ENDPOINT,
      payload,
    );
    yield put(fetchLinkedBankAccounts.success(accounts));
  } catch (e) {
    yield put(fetchLinkedBankAccounts.failure(e.response));
  }
}

function* createLinkedBankAccountSaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Attempting to create linked bank account for user.',
    { hideAfter: 0 },
  );
  try {
    const accounts = yield call(
      api.post,
      urls.CREATE_LINKED_BANK_ACCOUNT_ADMIN_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(createLinkedBankAccountAdmin.success(accounts));
    cogoToast.info('Success! Approval Req created for linked Bank account.');
  } catch (e) {
    loadingCallback();
    yield put(createLinkedBankAccountAdmin.failure(e.response));
  }
}

function* linkedCryptoAddresses({ payload }) {
  try {
    const addresses = yield call(
      api.post,
      urls.LINKED_CRYPTO_ADDRESSES_API_ENDPOINT,
      payload,
    );
    yield put(fetchLinkedCryptoAddresses.success(addresses));
  } catch (e) {
    yield put(fetchLinkedCryptoAddresses.failure(e.response));
  }
}

function* createLinkedCryptoAddressSaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Attempting to create linked crypto address for user.',
    { hideAfter: 0 },
  );
  try {
    const address = yield call(
      api.post,
      urls.CREATE_LINKED_CRYPTO_ADDRESS_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(createLinkedCryptoAddress.success(address));
    cogoToast.info('Success! Approval Req created for linked crypto address.');
  } catch (e) {
    loadingCallback();
    yield put(createLinkedCryptoAddress.failure(e.response));
  }
}

export default function* watch() {
  yield takeLatest(fetchLinkedBankAccounts.TRIGGER, linkedBankAccounts);
  yield takeLatest(
    createLinkedBankAccountAdmin.TRIGGER,
    createLinkedBankAccountSaga,
  );
  yield takeLatest(fetchLinkedCryptoAddresses.TRIGGER, linkedCryptoAddresses);
  yield takeLatest(
    createLinkedCryptoAddress.TRIGGER,
    createLinkedCryptoAddressSaga,
  );
}
