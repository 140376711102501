import React from 'react';
import { Form } from 'semantic-ui-react';
import { setGridToggleData, useGridToggle } from './useGridToggle';
import styled from 'styled-components';

interface AgGridWrapperProps {
  agGridComponent: any;
  reactTableComponent: any;
  gridName: string;
}

const StyledToggle = styled(Form.Checkbox)`
  margin: 10px 5px 10px 0;
  text-align: right;
`;

export const AgGridWrapper: React.FC<AgGridWrapperProps> = ( { gridName, agGridComponent, reactTableComponent } ) => {
  const toggle = useGridToggle(gridName);

  return (
    <>
      <StyledToggle
        toggle
        checked={toggle}
        onClick={() => {
          setGridToggleData(gridName, !toggle);
          window.location.reload();
        }}
        label={'New Grid UX'}
        name="newGridUx"
        id="newGridUx"
      />
      <div>
        {toggle ? agGridComponent : reactTableComponent}
      </div>
    </>
  );
};

export default AgGridWrapper;
