import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import * as actionTypes from '../constants/actionTypes';

/**
 * @param {string} type - identifying the type of account to query for, defaults to 'all'
 *  'ownership' - clearing accounts
 *  'wallet'    - Wallet accounts
 *  'bank'      - BankAccount accounts
 *  'custodian' - CustodianAccounts, includes 'wallet' & 'bank'
 *  'fee'       - FeeAccounts
 *  'eris'      - includes 'wallet','bank', and 'fee'
 *  'all'       - ClearingAccounts & 'ownership' accounts
 */
export const fetchAccounts = (payload) => ({
  type: actionTypes.ACCOUNTS_REQUEST,
  payload,
});

export const fetchMultiAccountBalances = createRoutine(
  actionTypes.MULTI_ACCOUNT_BALANCES,
);
export const fetchEmarketAccountTypes = createRoutine(
  actionTypes.EMARKET_ACCOUNT_TYPES,
);

/**
 * Action generator for selecting an account from the clearing accounts page.
 * @param {number} accountId - The accountId.
 */
export const selectAccountHistory = (accountId) => ({
  type: actionTypes.SELECT_ACCOUNT_HISTORY,
  accountId,
});

/**
 * @param {string} accountId - identifying the account the history is requested for.
 */
export const fetchHistory = (payload) => ({
  type: actionTypes.ACCOUNT_HISTORY_REQUEST,
  payload,
});

/**
 * Action generator for creating a new wallet.
 * @param {Object} data -
 *  An object with keys that map to what the rpc method expects but in camelCase.
 */
export const createAccount = createRoutine(actionTypes.CREATE_ACCOUNT);
export const createAccountPromiseCreator = promisifyRoutine(createAccount);

/**
 * Action generator for getting all unused wallets by asset type.
 * @param {String} type - Asset Type
 */
export const unusedWallets = (assetType) => ({
  type: actionTypes.UNUSED_WALLETS_REQUEST,
  payload: { assetType },
});

/**
 * @param {Object} args - identifying the userId, new_email of the user that needs to be updated
 */
export const updateAccount = createRoutine(actionTypes.UPDATE_ACCOUNT);
export const updateAccountPromiseCreator = promisifyRoutine(updateAccount);

/**
 * Routine to fetch Currenex account balances, by assetType
 * @param {string} - Currenex account
 */
export const fetchCurrenexBalances = createRoutine(
  actionTypes.CURRENEX_BALANCES,
);

/**
 * Routine to fetch designated balance details
 * @param {string} - account id
 */
export const fetchDesignatedBalanceDetails = createRoutine(
  actionTypes.DESIGNATED_BALANCE_DETAILS,
);

/**
 * Routine to fetch designated balance details
 * @param {string} - account id
 * @param {string} - date
 */
export const fetchBalances = createRoutine(actionTypes.BALANCES);

/** Accompanying routine to clear out old Currenex balances */
export const clearCurrenexBalances = createRoutine(
  actionTypes.CLEAR_CURRENEX_BALANCES,
);

export const clearCurrenexBalancesPromiseCreator = promisifyRoutine(
  clearCurrenexBalances,
);

/**
 * Routine to create approval reqs for eMarket (e.g. Currenex) balance adjustment
 *
 * @param {string} cxAccountId - eMarket accountId
 * @param {number} amount - positive or negative number
 * @param {string} assetType - "TBTC", "TLTC", etc.
 */
export const createEmarketBalanceAdjustment = createRoutine(
  actionTypes.CREATE_EMARKET_BALANCE_ADJUSTMENT,
);

/**
 * Routine to fetch eMarket balance adjustments. Takes no args
 */
export const emarketBalanceAdjustments = createRoutine(
  actionTypes.EMARKET_BALANCE_ADJUSTMENTS,
);

/**
 * Routine to get contract positions.
 */
export const positions = createRoutine(actionTypes.POSITIONS);
export const positionSummary = createRoutine(actionTypes.POSITION_SUMMARY);
export const positionsAggregated = createRoutine(
  actionTypes.POSITIONS_AGGREGATED,
);
export const clearPositions = createRoutine(actionTypes.CLEAR_POSITIONS);
export const clearPositionsPromiseCreator = promisifyRoutine(clearPositions);
export const positionSummaryPromiseCreator = promisifyRoutine(positionSummary);
/**
 * Routine to get close contract positions.
 */
export const createCloseoutInstruction = createRoutine(
  actionTypes.CREATE_CLOSEOUT_INSTRUCTION,
);
export const createCloseoutInstructionPromiseCreator = promisifyRoutine(
  createCloseoutInstruction,
);

export const positionTransferTypes = createRoutine(
  actionTypes.POSITION_TRANSFER_TYPES,
);
export const clearPositionTransfers = createRoutine(
  actionTypes.CLEAR_POSITION_TRANSFERS,
);
export const positionTransfers = createRoutine(actionTypes.POSITION_TRANSFERS);
export const createPositionTransfersRequest = createRoutine(
  actionTypes.CREATE_POSITION_TRANSFERS_REQUEST,
);
export const createPositionTransfersRequestPromise = promisifyRoutine(
  createPositionTransfersRequest,
);
