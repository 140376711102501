import React, { Fragment } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { renderField, rules } from 'erisxkit/client';
import PropTypes from 'prop-types';

const AddCollateralAccount = ({ hideModal, confirm, valid }) => (
  <Fragment>
    <Modal.Header>
      Add Collateral Account
      <Button
        className="pull-right"
        circular
        size="mini"
        icon="times"
        onClick={() => hideModal()}
      />
    </Modal.Header>
    <Modal.Content>
      <Form>
        <Field
          label="Label"
          component={renderField}
          placeholder="Enter a name for this account..."
          type="text"
          name="label"
          validate={[rules.required]}
        />
        <Form.Group>
          <Field
            label="Counterparty ABA"
            component={renderField}
            placeholder="Enter counterparty ABA..."
            type="text"
            name="counterpartyAba"
            validate={[rules.required]}
          />
          <Field
            label="Counterparty/Customer"
            component={renderField}
            placeholder="Enter counterparty/customer..."
            type="text"
            name="counterpartyCustomer"
            validate={[rules.required]}
          />
        </Form.Group>
        <Field
          label="Comment"
          component={renderField}
          placeholder="(Optional)"
          name="comment"
          type="text"
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button id="cancel" color="red" onClick={hideModal}>
        Cancel
      </Button>
      <Button
        id="confirm"
        color="green"
        disabled={!valid}
        onClick={() => confirm('collateral')}
      >
        Confirm
      </Button>
    </Modal.Actions>
  </Fragment>
);

AddCollateralAccount.propTypes = {
  confirm: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'add_linked_account',
})(AddCollateralAccount);
