import React from 'react';
import DateTime from 'react-datetime';
import { UsersColumnFilter } from '../common/table/Filters';
import _ from 'lodash';

const columns = [
  {
    Header: 'Timestamp',
    id: 'timestamp',
    accessor: (row) => _.get(row, 'time', '-'),
    filterable: true,
    Filter: ({ onChange }) => (
      <DateTime
        name="date"
        htmlProps={{ readonly: true }}
        onChange={(data) => onChange(data)}
      />
    ),
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: 'type',
    minWidth: 25,
  },
  {
    Header: 'Email',
    id: 'userId',
    accessor: (row) => (
      <p>
        <span className="mono">{_.get(row, ['user', 'userId'], '-')}</span>{' '}
        {_.has(row.user, 'email') ? (
          <span className="italic">({_.get(row, ['user', 'email'])})</span>
        ) : (
          ''
        )}
      </p>
    ),
    minWidth: 140,
    filterable: true,
    Filter: UsersColumnFilter,
  },
  {
    Header: 'Object',
    id: 'object',
    accessor: (row) => _.get(row, ['object', 'type'], '-'),
    minWidth: 80,
  },
];

export default columns;
