import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, flatten, union } from 'lodash';
import { Header, Segment, Dropdown, Button } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import {
  createLoadingSelector,
  withFilters,
  datetimeOnchange,
  inputOnChange,
  filteredArrayToAttrValue,
} from 'erisxkit/client';
import ExternalTable from '../../common/table/ExternalTable';
import closingPricesMetadata from '../../metadata/closingPricesMetadata';
import {
  closingPrices,
  updateClosingPrice,
} from '../../actions/manualEntryActions';
import UpdateClosingPrice from '../../components/ManualEntries/UpdateClosingPrice';
import {
  getClosingPrices,
  getClosingPricesCount,
} from '../../reducers/manualEntriesReducer';
import {
  getContracts,
  getContractsWithClearingCode,
  contractSymbols,
} from '../../reducers/contractsReducer';
import {
  getSelectorAsOptions,
  getSubExchangesAsOptions,
} from '../../selectors';
import DetailsHeader from '../../common/components/DetailsHeader';
import { getSpotProducts } from '../../reducers/spotProductsReducer';
import { getFuturesProducts } from '../../reducers/futuresProductReducer';

const Table = withFilters(ExternalTable);

export const dividedOptions = (categories = []) =>
  flatten(
    categories.map((category) =>
      !isEmpty(category) && !category.hide
        ? union(
            [
              {
                key: category.key,
                as: () => (
                  <Dropdown.Header
                    key={category.key}
                    content={category.header}
                  />
                ),
              },
            ],
            [
              {
                key: `${category.key}-divider`,
                as: () => <Dropdown.Divider key={`${category.key}-divider`} />,
              },
            ],
            category.options,
          )
        : [],
    ),
  );

const tableFilters = (
  codes,
  symbols,
  spotProductsOptions,
  futuresProductsOptions,
  subExchangesOptions,
) => [
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'Date',
    name: 'date',
    eqid: 'date',
    eqdefaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: false,
    inputProps: { placeholder: 'Select Date' },
    closeOnSelect: true,
    onChange: datetimeOnchange('date', 'eq'),
  },
  {
    placeholder: 'Select SubExchange',
    component: Dropdown,
    name: 'sub_exchange_id',
    id: 'sub_exchange_id',
    label: 'Subexchange',
    onChange: inputOnChange,
    options: subExchangesOptions,
    selection: true,
    clearable: true,
  },
  {
    placeholder: 'Select a product',
    component: Dropdown,
    name: 'product_symbol',
    id: 'product_symbol',
    label: 'Product',
    clearable: true,
    selection: true,
    onChange: inputOnChange,
    options: dividedOptions([
      {
        header: 'Spot Products',
        options: spotProductsOptions,
        key: 'spotFilter',
      },
      {
        header: 'Futures Products',
        options: futuresProductsOptions,
        key: 'futuresFilter',
      },
    ]),
  },
  {
    placeholder: 'Select an exchange symbol',
    component: Dropdown,
    name: 'contract_symbol',
    id: 'contract_symbol',
    label: 'Exchange Symbol',
    onChange: inputOnChange,
    options: symbols,
    selection: true,
    search: true,
    clearable: true,
  },
  {
    placeholder: 'Select a contract',
    component: Dropdown,
    name: 'contract_code',
    id: 'contract_code',
    label: 'Contract',
    onChange: inputOnChange,
    options: codes,
    selection: true,
    search: true,
    clearable: true,
  },
  {
    placeholder: 'Select a settlement type',
    component: Dropdown,
    name: 'settlement_price_type',
    id: 'settlement_price_type',
    label: 'Settlement Type',
    onChange: inputOnChange,
    options: [
      { key: 'midDay', value: 'mid_day', text: 'Mid Day' },
      { key: 'adHoc', value: 'ad_hoc', text: 'Ad Hoc' },
      { key: 'endOfDay', value: 'end_of_day', text: 'End Of Day' },
      { key: 'final', value: 'final', text: 'Final' },
    ],
    selection: true,
    search: true,
    clearable: true,
  },
];

const mapStateToProps = (state) => ({
  closingPricesList: getClosingPrices(state),
  closingPricesCount: getClosingPricesCount(state),
  closingPricesLoading: createLoadingSelector(['CLOSING_PRICES'])(state),
  contractSymbolsLoading: createLoadingSelector(['CONTRACT_SYMBOLS'])(state),
  contracts: getContracts(state),
  contractSymbolOptions: getSelectorAsOptions(getContracts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
  })(state),
  contractCodeOptions: getSelectorAsOptions(getContractsWithClearingCode, {
    key: 'contractCode',
    value: 'contractCode',
    text: 'contractCode',
  })(state),
  futuresProductsOptions: getSelectorAsOptions(getFuturesProducts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
  })(state),
  spotProductsOptions: getSelectorAsOptions(getSpotProducts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
  })(state),
  subExchangesOptions: getSubExchangesAsOptions(state),
});

const mapDispatchToProps = {
  closingPrices,
  updateClosingPrice,
  contractSymbols,
};

class ClosingPricesContainer extends Component {
  state = {};

  componentDidUpdate = (prevProps) => {
    const { contracts, closingPricesList, contractSymbols } = this.props;

    /* Parent container only sends first 100 contracts. Some table rows
    may have an exchange symbol that isn't in those contracts, so we retrieve
    them separately on demand */

    if (
      !isEmpty(contracts) &&
      (prevProps.contracts !== contracts ||
        prevProps.closingPricesList !== closingPricesList)
    ) {
      const missingContracts = closingPricesList.filter((tableRow) =>
        contracts.every(
          (contract) => contract.symbol !== tableRow.contractSymbol,
        ),
      );

      if (!isEmpty(missingContracts)) {
        contractSymbols({
          filter: [
            {
              attr: 'symbol',
              value: missingContracts.map(
                (contract) => contract.contractSymbol,
              ),
              op: 'eq',
            },
          ],
          // Since we can only fetch 100 contracts at a time, this flag makes it so
          // the store does not squash previous contracts and concatenates new ones to the list
          keepPrevious: true,
        });
      }
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updateClosingPrice(this.state);
    this.setState({
      contractSymbol: '',
      productSymbol: '',
    });
  };

  fetchData = (state) => {
    this.props.closingPrices({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      filter: filteredArrayToAttrValue(state.filtered),
    });
  };

  render = () => {
    const {
      closingPricesList,
      closingPricesCount,
      closingPricesLoading,
      contracts,
      contractCodeOptions,
      contractSymbolOptions,
      contractSymbolsLoading,
      futuresProductsOptions,
      subExchangesOptions,
      spotProductsOptions,
    } = this.props;
    const { contractSymbol } = this.state;
    return (
      <Fragment>
        <Segment>
          <Header as="h2">Closing Prices</Header>
          <UpdateClosingPrice
            data={this.state}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
          {contractSymbol && (
            <DetailsHeader
              item={contracts.find((each) => each.symbol === contractSymbol)}
              className="contract-details"
              compact
            />
          )}
          <Button
            type="button"
            id="SubmitClosingPrices"
            onClick={this.handleSubmit}
          >
            Submit
          </Button>
        </Segment>
        <Segment>
          <Header as="h3">History</Header>
          <Table
            title="closingPrices"
            data={closingPricesList}
            metadata={closingPricesMetadata(contractSymbolOptions, contracts)}
            onFetchData={this.fetchData}
            count={closingPricesCount}
            loading={closingPricesLoading || contractSymbolsLoading}
            noDataText="No closing prices found."
            minRows={10}
            filters={tableFilters(
              contractCodeOptions,
              contractSymbolOptions,
              spotProductsOptions,
              futuresProductsOptions,
              subExchangesOptions,
            )}
          />
        </Segment>
      </Fragment>
    );
  };
}

ClosingPricesContainer.propTypes = {
  closingPrices: PropTypes.func.isRequired,
  updateClosingPrice: PropTypes.func.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  contractCodeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  contractSymbolOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  closingPricesList: PropTypes.arrayOf(
    PropTypes.shape({
      baseAssetType: PropTypes.string,
      quotedAssetType: PropTypes.string,
      price: PropTypes.string,
      date: PropTypes.string,
    }),
  ),
  subExchangesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  contractSymbolsLoading: PropTypes.bool,
  closingPricesLoading: PropTypes.bool,
  closingPricesCount: PropTypes.number,
  futuresProductsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  spotProductsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

ClosingPricesContainer.defaultProps = {
  contractSymbolsLoading: false,
  closingPricesLoading: false,
  contractCodeOptions: [],
  contractSymbolOptions: [],
  closingPricesList: [],
  closingPricesCount: 0,
  futuresProductsOptions: [],
  spotProductsOptions: [],
  subExchangesOptions: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClosingPricesContainer);
