/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import {
  createLoadingSelector,
  withFilters,
  filteredArrayToAttrValue,
  sortedArrayToAttrValue,
  datetimeOnchange,
  inputOnChange,
  checkboxOnChange,
} from 'erisxkit/client';
import Table from '../../common/table/ExternalTable';
import metadata from '../../metadata/taskLogMetadata';
import { showModal } from 'erisxkit/client';
import { TASK_LOG } from '../../constants/modalTypes';
import {
  fetchTasks,
  getTasksPaged,
  getTasksCount,
  fetchTaskInfo,
  getTaskInfo,
} from '../../reducers/taskLogReducer';

class DropdownWithCheckboxes extends Component {
  state = {};
  onChangeCheckbox = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    if (data.checked) {
      this.props.onChange(e, { name: this.props.name, value: data.label });
    } else {
      this.props.onChange(e, { name: this.props.name, value: '' });
    }
    this.setState({
      ...this.state.checked,
      [data.id]: data.checked,
    });
  };
  render = () => (
    <Dropdown selection closeOnChange={false} selectOnBlur={false}>
      <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
        {this.props.info.map((i) => (
          <Dropdown.Item
            as={() => (
              <Checkbox
                defaultChecked
                checked={this.state[i]}
                id={i}
                label={i}
                className="item"
                onClick={this.onChangeCheckbox}
              />
            )}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const TableWithFilter = withFilters(Table);

const tasksFilters = (info) => [
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'Begin Date/Time',
    name: 'min-time',
    id: 'min-time',
    defaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: true,
    inputProps: { placeholder: 'Filter Begin Time' },
    closeOnSelect: true,
    onChange: datetimeOnchange('min-time', 'gte'),
  },
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'End Date/Time',
    name: 'max-time',
    id: 'max-time',
    defaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: true,
    inputProps: { placeholder: 'Filter End Time' },
    closeOnSelect: true,
    onChange: datetimeOnchange('max-time', 'lte'),
  },
  {
    placeholder: 'Task Name',
    component: Dropdown,
    className: 'dropdown autolistbox highZIndex',
    name: 'name',
    id: 'name',
    label: 'Task Name',
    onChange: inputOnChange,
    options:
      info.names &&
      info.names.map((name) => ({ key: name, value: name, text: name })),
    multiple: true,
    selection: true,
    search: true,
  },
  {
    placeholder: 'Status',
    component: Dropdown,
    className: 'highZIndex',
    name: 'type',
    id: 'type',
    label: 'Status',
    onChange: inputOnChange,
    options: ['success', 'fail'].map((name) => ({
      key: name,
      value: name,
      text: name,
    })),
    selection: true,
    search: true,
    clearable: true,
    selectOnBlur: false,
  },
  {
    component: Checkbox,
    name: 'show_frequent_tasks',
    id: 'show_frequent_tasks',
    label: 'Show Frequent Tasks',
    onChange: checkboxOnChange,
    defaultChecked: true,
  },
];

const mapStateToProps = (state) => ({
  tasksList: getTasksPaged(state),
  tasksLoading: createLoadingSelector(['TASKS'])(state),
  taskLogLoading: createLoadingSelector(['TASK_LOG'])(state),
  tasksCount: getTasksCount(state),
  taskInfo: getTaskInfo(state),
});

const mapDispatchToProps = { showModal, fetchTasks, fetchTaskInfo };

export const initialState = {
  accountId: '',
  amount: '',
  assetType: '',
};

class TasksContainer extends Component {
  componentDidMount = () => {
    this.props.fetchTaskInfo();
  };
  fetchData = (state) => {
    // initialize the filter to have trigger for type, this is required.
    let filter = [];
    let sort = [];
    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }
    if (state.sorted.length !== 0) {
      sort = sortedArrayToAttrValue(state.sorted);
    }
    this.props.fetchTasks({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
      sort,
    });
  };

  selectTask = (i) => {
    this.props.showModal(TASK_LOG, {
      selectedTaskId: i.taskId,
      name: i.name,
      closeOnEscape: true,
      loading: this.props.taskLogLoading,
    });
  };

  render = () => (
    <Fragment>
      <TableWithFilter
        title="tasksList"
        className="-striped"
        data={this.props.tasksList}
        onFetchData={this.fetchData}
        metadata={metadata}
        count={this.props.tasksCount}
        loading={this.props.tasksLoading}
        noDataText="No task logs found."
        minRows={5}
        pageSize={100}
        filterable={false}
        getTrProps={(state, rowInfo) => ({
          onClick: () => {
            this.selectTask(rowInfo.original);
          },
          className: 'pointer',
        })}
        filters={tasksFilters(this.props.taskInfo)}
        sortable
      />
    </Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);
