import React from 'react';
import { NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import routes from '../routes/routes';

const Breadcrumbs = ({ breadcrumbs }) => (
  <div>
    {breadcrumbs.map((breadcrumb, index) => (
      <span className="breadcrumb" key={breadcrumb.props.match.path}>
        {index < breadcrumbs.length - 1 ? (
          <NavLink to={breadcrumb.props.match.url}>{breadcrumb}</NavLink>
        ) : (
          <span>{breadcrumb}</span>
        )}
        {index < breadcrumbs.length - 1 ? (
          <i className="angle right icon" />
        ) : null}
      </span>
    ))}
  </div>
);

export default withBreadcrumbs(routes)(Breadcrumbs);
