import { takeLatest } from 'redux-saga/effects';
import { fetchTravelRules, setTrustPii } from '../reducers/travelRuleReducer';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeLatest(fetchTravelRules, generateSaga(fetchTravelRules));
  yield takeLatest(
    setTrustPii,
    generateSaga(setTrustPii, {
      loading: 'Updating TRUST PII',
      success: 'TRUST PII Updated successfully',
    }),
  );
}
