import React from 'react';
import XTab from '../../common/tabs/XTab';
import sections from './riskConfigSections';
import RiskConfigTable from './RiskConfigTable';
import largeTradingMetadata from '../../metadata/riskConfigurationsMetadata/largeTradingMonitoringMetadata';
import largeTraderReportFirmMetadata from '../../metadata/riskConfigurationsMetadata/largeTraderReportFirmMetadata';
import {
  LARGE_TRADER_MONITORING_TEMPLATE,
  LARGE_TRADER_REPORT_FIRM_MAPPING_TEMPLATE,
} from './newRowTemplates';
import { TABLE_EDIT_ACTIONS } from '../../common/table/constants';
import hasOneRow from './utils';

const panes = [
  {
    url: 'large_trader_parameters',
    menuItem: 'Large Trader Parameters',
    render: () => (
      <RiskConfigTable
        configSection={sections.LARGE_TRADER_MONITORING}
        metadata={largeTradingMetadata}
        editable
        template={LARGE_TRADER_MONITORING_TEMPLATE}
        hideActions={[TABLE_EDIT_ACTIONS.DELETE]}
        isAddNewDisabled={hasOneRow}
        key={sections.LARGE_TRADER_MONITORING}
      />
    ),
  },
  {
    url: 'large_trader_report_firm_mapping',
    menuItem: 'Large Trader Report Firm Mapping',
    render: () => (
      <RiskConfigTable
        configSection={sections.LARGE_TRADER_REPORT_FIRM_MAPPING}
        metadata={largeTraderReportFirmMetadata}
        editable
        template={LARGE_TRADER_REPORT_FIRM_MAPPING_TEMPLATE}
        key={sections.LARGE_TRADER_REPORT_FIRM_MAPPING}
      />
    ),
  },
];

const LargeTraderTabs = () => {
  return <XTab panes={panes} />;
};

export default LargeTraderTabs;
