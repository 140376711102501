import React, { Fragment } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import BackButton from '../../common/components/BackButton';
import AddCollateralContainer from '../../containers/ManualEntries/AddCollateralContainer';

const AddCollateralPerAccountWrapper = ({ location, match }) => (
  <Fragment>
    <BackButton
      header="Back to Balance Details"
      subheader={`Back to details for ${get(location, ['state', 'label'], 'selected account')}`}
    />
    <AddCollateralContainer accountId={get(match, ['params', 'accountId'])} />
  </Fragment>
);

AddCollateralPerAccountWrapper.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      accountId: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default AddCollateralPerAccountWrapper;
