import React from 'react';
import { Tab, Header, Icon } from 'semantic-ui-react';
import XTab from '../../common/tabs/XTab';
import sections from './riskConfigSections';
import RiskConfigTable from './RiskConfigTable';
import guarantyMetadata from '../../metadata/riskConfigurationsMetadata/guarantyFundAllocationParamsMetadata';
import dfrrMetadata from '../../metadata/riskConfigurationsMetadata/defaultFinancialResourceParamsMetadata';
import { TABLE_EDIT_ACTIONS } from '../../common/table/constants';
import {
  DFRR_SIZING_PARAMS_TEMPLATE,
  FCM_GROUPS_TEMPLATE,
  GUARANTEE_FUND_ALLOC_TEMPLATE,
} from './newRowTemplates';
import hasOneRow from './utils';
import fcmGroupsMetadata from '../../metadata/riskConfigurationsMetadata/fcmGroupsMetadata';

const panes = [
  {
    url: 'dfrr_sizing_params',
    menuItem: 'DFRR Sizing Parameters',
    render: () => (
      <RiskConfigTable
        configSection={sections.DFRR_SIZING_PARAMS}
        metadata={dfrrMetadata}
        editable
        hideActions={[TABLE_EDIT_ACTIONS.DELETE]}
        isAddNewDisabled={hasOneRow}
        template={DFRR_SIZING_PARAMS_TEMPLATE}
        key={sections.DFRR_SIZING_PARAMS}
      />
    ),
  },
  {
    url: 'guarantee_fund_allocation_params',
    menuItem: 'Guarantee Fund Allocation Parameters',
    render: () => (
      <RiskConfigTable
        configSection={sections.GUARANTY_FUND_ALLOCATION_PARAMS}
        metadata={guarantyMetadata}
        editable
        hideActions={[TABLE_EDIT_ACTIONS.DELETE]}
        isAddNewDisabled={hasOneRow}
        template={GUARANTEE_FUND_ALLOC_TEMPLATE}
        key={sections.GUARANTY_FUND_ALLOCATION_PARAMS}
      />
    ),
  },
  {
    url: 'fcm_groups',
    menuItem: 'FCM Groups',
    render: () => (
      <RiskConfigTable
        configSection={sections.CLEARING_MEMBER_GROUP}
        metadata={fcmGroupsMetadata}
        editable
        hideActions={[TABLE_EDIT_ACTIONS.DELETE]}
        template={FCM_GROUPS_TEMPLATE}
        key={sections.CLEARING_MEMBER_GROUP}
      />
    ),
  },
];

const DefaultFinancialResourceParameters = () => <XTab panes={panes} />;

export default DefaultFinancialResourceParameters;
