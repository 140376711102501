import { some } from 'lodash';
import React, { Fragment, PureComponent } from 'react';
import { renderField, rules } from 'erisxkit/client';
import { Form, Modal } from 'semantic-ui-react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import FormProvider from '../../common/FormProvider';
import MemberSelectionContainer from '../../common/containers/MemberSelectionContainer';
import { DEFAULT_LEDGER } from '../../constants/ledgers';

class CreateLedger extends PureComponent {
  uniqueLedgerName = (value) =>
    some(this.props.ledgers, (o) => o.name === value)
      ? 'A ledger with this name already exists.'
      : undefined;

  render = () => {
    const { confirmAction, defaultLedger, handleSubmit, hideModal } =
      this.props;
    return (
      <FormProvider form="createLedger" onSubmit={handleSubmit}>
        {({ handleSubmit, initialize, pristine, valid }) => {
          // for default ledger creation, initialize to default ledger name
          if (defaultLedger && pristine) {
            initialize({ name: DEFAULT_LEDGER.name }, { keepDirty: true });
          }
          return (
            <Fragment>
              <Modal.Header>
                Create{defaultLedger && ' Default'} Ledger
                <Form.Button
                  id="closeButton"
                  className="pull-right"
                  circular
                  size="mini"
                  icon="times"
                  onClick={() => hideModal()}
                />
              </Modal.Header>
              <Modal.Content>
                <Form onSubmit={handleSubmit(confirmAction)}>
                  <Field
                    id="name"
                    component={renderField}
                    label="Name"
                    name="name"
                    placeholder="Ledger name"
                    readOnly={defaultLedger}
                    required
                    validate={[rules.required, this.uniqueLedgerName]}
                    value={defaultLedger && DEFAULT_LEDGER.name}
                  >
                    <input />
                  </Field>
                  {!defaultLedger && (
                    <Field
                      clearable
                      component={MemberSelectionContainer}
                      label="Member"
                      name="memberId"
                      required
                      validate={[rules.required]}
                    />
                  )}
                  <Modal.Actions className="modal-form-actions">
                    <Form.Button
                      id="Cancel"
                      onClick={() => hideModal()}
                      content="Cancel"
                    />
                    <Form.Button
                      primary
                      disabled={!valid}
                      id="confirm"
                      content="Create new ledger"
                    />
                  </Modal.Actions>
                </Form>
              </Modal.Content>
            </Fragment>
          );
        }}
      </FormProvider>
    );
  };
}

CreateLedger.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  defaultLedger: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  ledgers: PropTypes.arrayOf(
    PropTypes.shape({
      ledgerId: PropTypes.string,
      memberId: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

CreateLedger.defaultProps = {
  defaultLedger: false,
  ledgers: [],
};

export default CreateLedger;
