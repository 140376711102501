import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Cover 2 Scale Factor',
    id: 'cover2ScaleFactor',
    accessor: 'cover2ScaleFactor',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 200,
  },
  {
    Header: 'Anti Procyclicality',
    id: 'antiprocyclicality',
    accessor: 'antiprocyclicality',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 200,
  },
  {
    Header: 'Minimum Dfrr',
    id: 'minimumDfrr',
    accessor: 'minimumDfrr',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Cboe Margin Call Threshold',
    id: 'cboeMarginCallThreshold',
    accessor: 'cboeMarginCallThreshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 250,
  },
  {
    Header: 'Intramonth Resizing Threshold',
    id: 'intramonthResizingThreshold',
    accessor: 'intramonthResizingThreshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 250,
  },
  {
    Header: 'Cboe Max Contribution',
    id: 'cboeMaxContribution',
    accessor: 'cboeMaxContribution',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 205,
  },
  {
    Header: 'Cboe Incremental Margin Call',
    id: 'cboeIncrementalMarginCall',
    accessor: 'cboeIncrementalMarginCall',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 240,
  },
  {
    Header: 'Lookback Period',
    id: 'lookbackPeriod',
    accessor: 'lookbackPeriod',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Moving Average Window',
    id: 'movingAverageWindow',
    accessor: 'movingAverageWindow',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 210,
  },
  {
    Header: 'Cover 2 Tie Threshold',
    id: 'cover2TieThreshold',
    accessor: 'cover2TieThreshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 210,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
