export const customerOrderCapacity = [
  { key: '1', value: '1', text: '1' },
  { key: '2', value: '2', text: '2' },
  { key: '3', value: '3', text: '3' },
  { key: '4', value: '4', text: '4' },
];

export const accountType = [
  { key: 'C', value: 'C', text: 'C (customer)' },
  { key: 'H', value: 'H', text: 'H (House)' },
];

export const side = [
  { key: 'BUY', value: 'BUY', text: 'BUY' },
  { key: 'SELL', value: 'SELL', text: 'SELL' },
];
