import React from 'react';
import { Header, Icon, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import history from '../../constants/history';
import CopyInput from './CopyInput';
import CopyField from './CopyField';

const BackButton = ({
  header,
  headerLabels = [],
  subheader,
  headerActions = [],
  renderHeader,
}) => (
  <Header as="h1">
    <Header.Content>
      <Icon
        className="back-link"
        name="left arrow"
        onClick={() => history.goBack()}
      />
      {/* supports conditionally rendered labels and actions that may result in empty objects */}
      <CopyField value={header} renderValue={renderHeader} />
      {headerActions.map(
        (actionProps) => !!actionProps && <Icon {...actionProps} />,
      )}{' '}
      {headerLabels.map(
        (labelProps) => !!labelProps && <Label {...labelProps} />,
      )}
      <Header.Subheader>{subheader}</Header.Subheader>
    </Header.Content>
  </Header>
);

// header and subheader can be any React node
BackButton.propTypes = {
  header: PropTypes.node,
  subheader: PropTypes.node,
  renderHeader: PropTypes.node,
};

BackButton.defaultProps = {
  header: 'Back',
  subheader: '',
  renderHeader: null,
};

export default BackButton;
