import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { fundsDesignation } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';

const FundsDesignationSelectionContainer = (props) => {
  const select = () => {
    const { placeholder } = props;

    // Redux-form compatibility
    const reduxFormProps = props.input
      ? {
          ...props.input,
          onChange: (e, { value }) => props.input.onChange(value),
        }
      : {};

    return (
      <Select
        {...reduxFormProps}
        search
        placeholder={placeholder}
        options={fundsDesignation.map((designation) => ({
          key: designation.value,
          text: designation.text,
          value: designation.value,
          description: designation.value,
        }))}
        clearable
      />
    );
  };

  return <Form.Field {...props} control={select} />;
};

FundsDesignationSelectionContainer.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.array,
};

FundsDesignationSelectionContainer.defaultProps = {
  name: 'fundsDesignation',
  placeholder: 'Select designation...',
  label: 'Funds Designation',
};

export default FundsDesignationSelectionContainer;
