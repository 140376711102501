import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Header,
  Message,
  Segment,
  Table,
  Checkbox,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { showModal, hideModal } from 'erisxkit/client';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { ADD_JURISDICTION } from '../../constants/modalTypes';
import {
  fetchJurisdictionsPromiseCreator,
  getDefaultJurisdictions,
  fetchCountriesForJurisdictions,
  getCountriesForJurisdictions,
  updateJurisdictionsPromiseCreator,
} from '../../reducers/configurationReducer';

const MainDiv = styled.div`
  width: 80%;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const CellTable = styled(Table.Cell)`
  display: flex;
  flex-direction: column;
`;

const StateChechbox = styled(Checkbox)`
  margin-top: 10px;
`;

const Countries = styled.div`
  display: grid;
  margin-top: 5px;
  grid-template-columns: repeat(5, 1fr);
`;

/*
 * Get just actives jurisdictions and add for first time the checked state.
 * Because actives means that the checkboxes need to be drawn, and checked by default, but if the user wants to deselect them
 * the frontend has to track that action that the backend doesnt track.
 *
 * Returns all the actives jurisdictions with a new checked property for each state of each country
 * @param {jurisdictions: array} All the jurisdictions
 */
export const addTempCheckedProp = (jurisdictions) => {
  const tempActiveJurisdictions = cloneDeep(jurisdictions);
  for (let i = 0; i < tempActiveJurisdictions.length; i++) {
    for (
      let c = 0;
      c < get(tempActiveJurisdictions, [i, 'states', 'length']);
      c++
    ) {
      if (get(tempActiveJurisdictions, [i, 'states', c, 'active'])) {
        set(tempActiveJurisdictions, [i, 'states', c, 'checked'], true);
      }
    }
  }
  return tempActiveJurisdictions;
};

const Jurisdictions = () => {
  const dispatch = useDispatch();
  const currentJurisdictions = useSelector(getDefaultJurisdictions);
  const countries = useSelector(getCountriesForJurisdictions);

  const [selectedJurisdictions, setSelectedJurisdictions] = useState([]);

  useEffect(() => {
    dispatch(fetchCountriesForJurisdictions());
    fetchJurisdictionsPromiseCreator({}, dispatch).then((data) => {
      setSelectedJurisdictions(addTempCheckedProp(data.countries));
    });
  }, []);

  //Given a country it selects the jurisdiction.
  const selectJurisdictionByCountry = (country) =>
    selectedJurisdictions?.find((x) => x.symbol === country);

  /*
   * Select/Deselect all checkbox for a country
   *
   * @param {country: string} Country of the Select All Checkbox
   * @param {checked: bool} Value of the checkbox
   */
  const onSelectAll = (country, checked) => {
    const tempSelectedJurisdictions = cloneDeep(selectedJurisdictions);

    for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
      if (get(tempSelectedJurisdictions, [i, 'symbol']) === country) {
        for (
          let c = 0;
          c < get(tempSelectedJurisdictions, [i, 'states', 'length']);
          c++
        ) {
          if (get(tempSelectedJurisdictions, [i, 'states', c, 'active'])) {
            set(
              tempSelectedJurisdictions,
              [i, 'states', c, 'checked'],
              checked,
            );
          }
        }
      }
    }

    setSelectedJurisdictions(tempSelectedJurisdictions);
  };

  /*
   * Select or deselect a checkbox
   *
   * @param {country: string} Country to which the Checkbox belongs
   * @param {state: string} State of the country to select or deselect
   * @param {checked: bool} Value of the checkbox
   */
  const onStateCheckboxChange = (country, state, checked) => {
    const tempSelectedJurisdictions = cloneDeep(selectedJurisdictions);
    for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
      if (get(tempSelectedJurisdictions, [i, 'symbol']) === country) {
        for (
          let c = 0;
          c < get(tempSelectedJurisdictions, [i, 'states', 'length']);
          c++
        ) {
          if (
            get(tempSelectedJurisdictions, [i, 'states', c, 'name']) ===
            state.name
          ) {
            set(
              tempSelectedJurisdictions,
              [i, 'states', c, 'checked'],
              checked,
            );
          }
        }
      }
    }
    setSelectedJurisdictions(tempSelectedJurisdictions);
  };

  /*
   * Checks if a checkbox is checked
   * Returns true if the checkbox should be checked or false if it not.
   * @param {country: string} Country to which the Checkbox belongs
   * @param {state: string} State of the country to verify
   */
  const isChecked = (country, state) => {
    const selectedCountry = selectJurisdictionByCountry(country);
    return selectedCountry?.states
      .filter((x) => x.checked)
      .map((x) => x.name)
      .includes(state);
  };
  /*
   * Checks if Select All checkbox is Indeterminate
   * Returns true if some of the checkbox that belong to the country are checked
   * @param {country: string} Country to which the Checkbox belongs
   */
  const isSelectAllIndeterminate = (country) => {
    //Given a country checks if some textbox are checked, but not all of them.
    const selectedCountry = selectJurisdictionByCountry(country);
    return (
      selectedCountry.states.some((x) => x.checked) &&
      selectedCountry.states.filter((x) => x.active).some((x) => !x.checked)
    );
  };

  /*
   * Checks if Select All checkbox is checked
   * Returns true if all checkbox that belong to the country are checked
   * @param {country: string} Country to which the Checkbox belongs
   */
  const isAllChecked = (country) => {
    const selectedCountry = selectJurisdictionByCountry(country);
    return (
      selectedCountry.states.some((x) => x.checked) &&
      !selectedCountry.states.filter((x) => x.active).some((x) => !x.checked)
    );
  };

  const confirmData = (selectedCountry, selectedStates) => {
    const states = countries.find((x) => x.symbol === selectedCountry);
    const tempSelectedJurisdictions = cloneDeep(selectedJurisdictions);
    const statesToAdd = states.states
      ?.filter((x) => selectedStates.includes(x.name))
      .map((x) => {
        return { ...x, active: true, checked: true };
      });

    const existsCountry = tempSelectedJurisdictions.find(
      (x) => x.symbol === selectedCountry,
    );

    let jurisdictionToAdd;

    if (existsCountry) {
      for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
        if (tempSelectedJurisdictions[i].symbol === selectedCountry) {
          tempSelectedJurisdictions[i].states = statesToAdd;
          tempSelectedJurisdictions[i].active = true;
        }
      }
      jurisdictionToAdd = tempSelectedJurisdictions;
    } else {
      jurisdictionToAdd = [
        ...tempSelectedJurisdictions,
        {
          symbol: selectedCountry,
          active: true,
          states: statesToAdd,
        },
      ];
    }
    setSelectedJurisdictions(jurisdictionToAdd);
    dispatch(hideModal(ADD_JURISDICTION));
  };

  //Show modal add Jurisdiction
  const addJurisdiction = () => {
    dispatch(
      showModal(ADD_JURISDICTION, {
        className: 'show-overflow',
        data: selectedJurisdictions,
        countries,
        onClose: () => dispatch(hideModal(ADD_JURISDICTION)),
        onConfirm: (selectedCountry, selectedStates) => {
          confirmData(selectedCountry, selectedStates);
        },
      }),
    );
  };

  //Show modal add Jurisdiction
  const confirmJurisdiction = () => {
    const tempSelectedJurisdictions = cloneDeep(selectedJurisdictions);

    for (let i = 0; i < tempSelectedJurisdictions.length; i++) {
      for (
        let j = 0;
        j < get(tempSelectedJurisdictions, [i, 'states', 'length']);
        j++
      ) {
        set(
          tempSelectedJurisdictions,
          [i, 'states', j, 'active'],
          get(tempSelectedJurisdictions, [i, 'states', j, 'checked']),
        );
        delete tempSelectedJurisdictions[i].states[j].checked;
      }
      const someActive = get(tempSelectedJurisdictions, [i, 'states'])?.some(
        (y) => y.active,
      );
      set(tempSelectedJurisdictions, [i, 'active'], someActive);
    }

    //Loop all states of all countries
    const currentJurisdictionsCopy = cloneDeep(currentJurisdictions);
    let currentCountry;
    let currentState;
    for (let i = 0; i < currentJurisdictionsCopy?.length; i++) {
      currentCountry = tempSelectedJurisdictions?.find(
        (x) => x.symbol === get(currentJurisdictionsCopy, [i, 'symbol']),
      );
      if (currentCountry?.active) {
        set(currentJurisdictionsCopy, [i, 'active'], true);
        for (
          let c = 0;
          c < get(currentJurisdictionsCopy, [i, 'states', 'length']);
          c++
        ) {
          currentState = currentCountry.states.find(
            (x) =>
              x.name ===
              get(currentJurisdictionsCopy, [i, 'states', c, 'name']),
          );
          set(
            currentJurisdictionsCopy,
            [i, 'states', c, 'active'],
            !!(currentState && currentState.active),
          );
        }
      }
    }

    const jurisdictionToSend = {
      jurisdictions: currentJurisdictionsCopy,
    };

    updateJurisdictionsPromiseCreator(jurisdictionToSend, dispatch).then(() => {
      dispatch(fetchCountriesForJurisdictions());
      fetchJurisdictionsPromiseCreator({}, dispatch).then((data) => {
        setSelectedJurisdictions(addTempCheckedProp(data.countries));
      });
    });
  };

  return (
    <Segment>
      <MainDiv>
        <Header>Authorized Jurisdictions</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>State/Dependent Locality</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {currentJurisdictions
              ?.filter((x) => x.active)
              ?.map((x) => (
                <Table.Row>
                  <Table.Cell>{x.symbol}</Table.Cell>
                  <Table.Cell>
                    {x.active &&
                      x.states
                        .filter((x) => x.active)
                        .map((x) => x.symbol)
                        .join(', ')}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <HeaderDiv>
          <Header>Jurisdictions</Header>
          <div>
            <Button primary id="confirm" onClick={addJurisdiction}>
              + Add Jurisdiction
            </Button>
            <Button primary id="secondary" onClick={confirmJurisdiction}>
              Apply
            </Button>
          </div>
        </HeaderDiv>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>State/Dependent Locality</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedJurisdictions
              .filter((x) => x.active)
              ?.map((x) => (
                <Table.Row>
                  <Table.Cell>{x.symbol}</Table.Cell>
                  <CellTable>
                    {x.states.filter((x) => x.active).length > 0 && (
                      <div>
                        <Checkbox
                          label={'ALL'}
                          onClick={(e, { checked }) => {
                            onSelectAll(x.symbol, checked);
                          }}
                          indeterminate={isSelectAllIndeterminate(x.symbol)}
                          checked={isAllChecked(x.symbol)}
                        />
                      </div>
                    )}
                    <Countries>
                      {x.states
                        .filter((x) => x.active)
                        .map((state) => (
                          <StateChechbox
                            label={state.name}
                            checked={isChecked(x.symbol, state.name)}
                            onClick={(e, { checked }) => {
                              onStateCheckboxChange(x.symbol, state, checked);
                            }}
                          />
                        ))}
                    </Countries>
                  </CellTable>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </MainDiv>
    </Segment>
  );
};

export default Jurisdictions;
