import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Header, Label } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import get from 'lodash/get';
import moment from 'moment';
import XTable7 from '../../../common/table/XTable7';
import travelRuleMetadata, {
  startTimeColId,
  endTimeColId,
} from '../../../metadata/travelRuleMetadata';
import {
  TRAVEL_RULES,
  fetchTravelRules,
  getTravelRules,
  getTravelRulesCount,
  setTrustPiiPromise,
} from '../../../reducers/travelRuleReducer';
import EditableRowSubComponent from '../../../common/table/EditableRowSubComponent/EditableRowSubComponent';
import getGroupedFields, {
  getOriginatingVaspFields,
  getOriginatorFields,
} from './getGroupedFields';
import { getAssetTypesAsOptions } from '../../../selectors';
import { GENERAL_TABLE_FILTER_TYPES } from '../../../common/table/GeneralFilters';
import usePaginatedFetch from '../../../hooks/usePaginatedFetch';
import { TABLE_FILTER_OP } from '../../../common/table/constants';
import useTravelRuleEnabled, {
  TRAVEL_RULE_ALLOWED_ROLES,
} from './useTravelRuleEnabled';
import RolesAccessControl from '../../../common/RolesAccessControl';
import MemberSelectionContainer from '../../../common/containers/MemberSelectionContainer';
import AccountSelectionContainer from '../../../common/containers/AccountSelectionContainer';

const AccountInfoSubComponent = styled(EditableRowSubComponent)`
  .subrow-form-grid {
    grid-template-columns: 350px 500px 600px;
  }
`;

const StyledChip = styled(Label)`
  &&& {
    padding: 7px 12px 1px 12px !important;
    margin-right: 10px;
    margin-bottom: 2px;
    font-weight: 400;
    background-color: ${({ isTrust }) => (isTrust ? '#E2F3DC' : '#F1F1F1')};
    opacity: ${({ isTrust }) => (isTrust ? '1' : '0.8')};
    text-transform: capitalize;
  }
`;
export const TrustChip = ({ isTrust, text }) => (
  <StyledChip circular isTrust={isTrust}>
    {text}
  </StyledChip>
);

TrustChip.defaultProps = {
  isTrust: false,
  text: '',
};
TrustChip.propTypes = {
  isTrust: PropTypes.bool,
  text: PropTypes.string,
};
// Value is a null string and is parsed on BE
// Semantic UI does not support null as dropdown option
const PENDING_TRAVEL_RULE_VALUE = 'null';

const FROM_TRUST_OPTIONS = [
  {
    key: 'true',
    text: 'True',
    value: true,
  },
  {
    key: 'false',
    text: 'False',
    value: false,
  },
  {
    key: 'pending',
    text: 'Pending',
    value: PENDING_TRAVEL_RULE_VALUE,
  },
];

const EDITED_FILTER_OPTIONS = [
  {
    key: 'true',
    text: 'True',
    // Values must be strings representing boolean values
    value: 'true',
  },
  {
    key: 'false',
    text: 'False',
    value: 'false',
  },
];

const CATEGORY_OPTIONS = [
  {
    key: 'withdrawal',
    text: 'Withdrawal',
    value: 'withdrawal',
  },
  {
    key: 'deposit',
    text: 'Deposit',
    value: 'deposit',
  },
];

const StyledAuditTrail = styled.p`
  margin-bottom: 0px;
  margin-left: 0.5em;
`;
const AuditTrail = ({ editedBy, editedAt }) =>
  moment(editedAt).isValid() && editedBy ? (
    <StyledAuditTrail>
      Last edited at: <b>{moment(editedAt).format('lll')}</b> by{' '}
      <b>{editedBy}</b>
    </StyledAuditTrail>
  ) : null;

AuditTrail.propTypes = {
  editedBy: PropTypes.string.isRequired,
  editedAt: PropTypes.string.isRequired,
};
const TravelRuleContainer = () => {
  const dispatch = useDispatch();
  const { canWrite } = useTravelRuleEnabled();
  const data = useSelector(getTravelRules);
  const count = useSelector(getTravelRulesCount);
  const loading = useSelector(createLoadingSelector(TRAVEL_RULES));
  const assetTypes = useSelector(getAssetTypesAsOptions);

  const generalFilters = useMemo(
    () => [
      {
        text: 'Begin Date/Time',
        name: startTimeColId,
        type: GENERAL_TABLE_FILTER_TYPES.DATE,
      },
      {
        text: 'End Date/Time',
        name: endTimeColId,
        type: GENERAL_TABLE_FILTER_TYPES.DATE,
      },
      {
        text: 'TRUST',
        name: 'fromTrust',
        options: FROM_TRUST_OPTIONS,
      },
      {
        text: 'Edited',
        name: 'edited',
        options: EDITED_FILTER_OPTIONS,
      },
      { text: 'Asset Type', name: 'assetType', options: assetTypes },
      {
        text: 'Clearing Member',
        name: 'memberId',
        type: GENERAL_TABLE_FILTER_TYPES.CUSTOM,
        component: MemberSelectionContainer,
      },
      {
        text: 'Account',
        name: 'accountId',
        type: GENERAL_TABLE_FILTER_TYPES.CUSTOM,
        component: AccountSelectionContainer,
      },
      { text: 'Source Address', name: 'sourceAddress' },
      { text: 'Category', name: 'category', options: CATEGORY_OPTIONS },
      { text: 'Amount', name: 'amount' },
    ],
    [assetTypes],
  );

  const fetchData = usePaginatedFetch(
    fetchTravelRules,
    {},
    {
      opByColumnId: {
        [startTimeColId]: TABLE_FILTER_OP.GreaterThanOrEq,
        [endTimeColId]: TABLE_FILTER_OP.LessThanOrEq,
      },
    },
  );

  const getSubRows = useCallback(
    (row) => (row?.originator ? [row.originator] : []),
    [],
  );

  const onUpdate = useCallback(
    ({ updated, activeGroups, parentRow }) => {
      // User may have picked Natural or Legal person.
      // Depending on that, we change the originator shape
      const travelRule = get(parentRow, 'original', {});
      const originator = getOriginatorFields(activeGroups, updated);
      const originatingVasp = getOriginatingVaspFields(updated);
      setTrustPiiPromise(
        {
          ...travelRule,
          transactionRequestId: get(travelRule, 'transactionId'),
          originator,
          originatingVasp,
        },
        dispatch,
      ).then(() => dispatch(fetchTravelRules()));
    },
    [dispatch],
  );

  const renderRowSubComponent = useCallback(
    ({ parentRow }) => {
      // Parent row is the complete TravelRule. Row is the originator.
      const editable = canWrite && get(parentRow, 'original.editable', false);
      const groupedFields = getGroupedFields(parentRow?.original);
      return (
        <AccountInfoSubComponent
          groupedFields={groupedFields}
          toggleExpand={parentRow.toggleRowExpanded}
          readOnly={!editable}
          onUpdate={(updateParams) => onUpdate({ ...updateParams, parentRow })}
          customHeader={
            <AuditTrail
              editedBy={get(parentRow, 'original.lastUpdatedBy', '-')}
              editedAt={get(parentRow, 'original.lastUpdatedAt', '-')}
            />
          }
        />
      );
    },
    [canWrite],
  );

  return (
    <RolesAccessControl allowedRoles={TRAVEL_RULE_ALLOWED_ROLES}>
      <Segment>
        <Header as="h3">Travel Rule</Header>
        <XTable7
          data={data}
          metadata={travelRuleMetadata}
          fetchData={fetchData}
          loading={loading}
          count={count}
          generalFilters={generalFilters}
          showPagination
          renderRowSubComponent={renderRowSubComponent}
          getSubRows={getSubRows}
        />
      </Segment>
    </RolesAccessControl>
  );
};

export default TravelRuleContainer;
