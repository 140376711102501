export const LEGAL_PERSON_ORIGINATOR_FIELDS = [
  {
    name: 'accountNumber',
    label: 'Account Number',
  },
  {
    name: 'legalPersonName',
    label: 'Name',
  },
  {
    name: 'address1',
    label: 'Street Address',
  },
  {
    name: 'address2',
    label: 'Street Address 2',
  },
  {
    name: 'townName',
    label: 'City',
  },
  {
    name: 'countrySubDivision',
    label: 'State',
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'nationIdentifier',
    label: 'National Identification Number',
    concealed: true,
  },
];

export const NATURAL_PERSON_ORIGINATOR_FIELDS = [
  {
    name: 'accountNumber',
    label: 'Account Number',
  },
  {
    name: 'primaryIdentifier',
    label: 'First Name',
  },
  {
    name: 'secondaryIdentifier',
    label: 'Last Name',
  },
  {
    name: 'address1',
    label: 'Street Address',
  },
  {
    name: 'address2',
    label: 'Street Address 2',
  },
  {
    name: 'townName',
    label: 'City',
  },
  {
    name: 'countrySubDivision',
    label: 'State',
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    concealed: true,
  },
  {
    name: 'nationIdentifier',
    label: 'National Identification Number',
    concealed: true,
  },
];

export const ORIGINATING_VASP_FIELDS = [
  {
    name: 'legalPersonName',
    label: 'Name',
  },
  {
    name: 'nationIdentifier',
    label: 'National Identification Number',
    concealed: true,
  },
  {
    name: 'address1',
    label: 'Street Address',
  },
  {
    name: 'address2',
    label: 'Street Address 2',
  },
  {
    name: 'townName',
    label: 'City',
  },
  {
    name: 'countrySubDivision',
    label: 'State',
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
  },
  {
    name: 'country',
    label: 'Country',
  },
];

export const RECIPIENT_VASP_FIELDS = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'nationIdentifier',
    label: 'National Identification Number',
    concealed: true,
  },
];
