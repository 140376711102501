import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Risk Profile',
    id: 'riskProfile',
    accessor: 'riskProfile',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Alert Type',
    id: 'alertType',
    accessor: 'alertType',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Symbol',
    id: 'symbol',
    accessor: 'symbol',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Threshold',
    id: 'threshold',
    accessor: 'threshold',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    minWidth: 150,
  },
  {
    Header: 'Severity',
    id: 'severity',
    accessor: 'severity',
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Send Notification',
    id: 'sendNotification',
    accessor: 'sendNotification',
    editInputType: TABLE_INPUT_TYPES.BOOLEAN,
    minWidth: 150,
  },
  {
    Header: 'Auto Close Alert',
    id: 'autoCloseAlert',
    accessor: 'autoCloseAlert',
    editInputType: TABLE_INPUT_TYPES.BOOLEAN,
    minWidth: 150,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
