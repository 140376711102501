import React from 'react';
import get from 'lodash/get';
import { Button } from 'semantic-ui-react';
import {
  DateRangeColumnFilter,
  SelectColumnFilter,
} from '../common/table/Filters';

const SettlementServiceMetadata = (approveReject) => {
  const columns = [
    {
      Header: 'Requested',
      id: 'time',
      accessor: 'time',
      className: 'hidden-column',
      Filter: DateRangeColumnFilter,
      with: 120,
    },
    {
      Header: 'Updated',
      id: 'updated',
      accessor: 'updated',
      className: 'hidden-column',
      hideFilter: true,
      with: 120,
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: 'status',
      Cell: (props) => {
        const _status = get(props, 'row.original.status', '');
        return (
          <div>
            <p className={'settlement-service-' + _status}>{_status}</p>
          </div>
        );
      },
      className: 'hidden-column',
      filterOp: 'eq',
      Filter: SelectColumnFilter,
      options: [
        'cleared',
        'rejected',
        'confirmed_from',
        'confirmed_to',
        'alleged',
        'admin_cxl',
      ],
      with: 120,
    },
  ];

  approveReject &&
    columns.push({
      Header: 'Actions',
      id: 'actions',
      accessor: 'actions',
      Cell: (props) => {
        const _row = get(props, 'row.original', '');
        return (
          _row.status !== 'admin_cxl' &&
          _row.status !== 'cleared' &&
          _row.status !== 'rejected' && (
            <div className="center">
              <Button
                primary
                className="w95"
                onClick={() => approveReject(_row, 'approve')}
              >
                Approve
              </Button>
              <Button
                secondary
                className="w95"
                onClick={() => approveReject(_row, 'reject')}
              >
                Reject
              </Button>
            </div>
          )
        );
      },
      className: 'hidden-column',
      width: 200,
      hideFilter: true,
    });

  columns.push(
    {
      Header: 'From Account',
      id: 'from_account_label',
      accessor: 'fromAccountLabel',
      className: 'hidden-column',
      hideFilter: true,
      with: 120,
    },
    {
      Header: 'To Account',
      id: 'to_account_label',
      accessor: 'toAccountLabel',
      className: 'hidden-column',
      hideFilter: true,
      with: 120,
    },
    {
      Header: 'Settlement Amounts',
      id: 'settlement',
      accessor: 'settlement',
      Cell: (props) => (
        <div>
          <p>
            {get(props, 'row.original.settlement', []).map((x) => (
              <p className="no-margin">
                {x.assetType} {x.amount}
              </p>
            ))}
          </p>
        </div>
      ),
      className: 'hidden-column',
      width: 200,
      hideFilter: true,
    },
    {
      Header: 'Text',
      id: 'text',
      accessor: 'text',
      className: 'hidden-column',
      hideFilter: true,
      with: 120,
    },
    {
      Header: 'Submitter',
      id: 'submitter',
      accessor: 'submitter',
      className: 'hidden-column',
      width: 200,
      hideFilter: true,
    },
    {
      Header: 'From Validator',
      id: 'from_account_validator',
      accessor: 'fromAccountValidator',
      className: 'hidden-column',
      hideFilter: true,
      with: 120,
    },
    {
      Header: 'To Validator',
      id: 'to_account_validator',
      accessor: 'toAccountValidator',
      className: 'hidden-column',
      width: 200,
      hideFilter: true,
    },
    {
      Header: 'Id',
      id: 'settlement_request_id',
      accessor: 'id',
      Cell: (props) => {
        const _id = get(props, 'row.original.id', '');
        return (
          <div>
            <p className={'capitalize settlement-service-'}>{_id}</p>
          </div>
        );
      },
      width: 250,
    },
  );

  return columns;
};

export default SettlementServiceMetadata;
