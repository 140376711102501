/** Action Types */
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

/** Initial State */
const initialState = {
  sidebarContent: null,
};

/** sidebar reducer */
export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return { ...state, sidebarContent: action.sidebarContent };
    case HIDE_SIDEBAR:
      return initialState;
    default:
      return state;
  }
}

/**
 * Action generator to show a sidebar based on a sidebarContent.
 * @param {String} sidebarContent - The name of component of the content to be shown in sidebar.
 */
export const showSidebar = (sidebarContent) => ({
  type: SHOW_SIDEBAR,
  sidebarContent,
});

/**
 * Action generator to hide the sidebar.
 */
export const hideSidebar = () => ({
  type: HIDE_SIDEBAR,
});
