import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import {
  clearPositions,
  positions,
  createCloseoutInstructionPromiseCreator,
} from '../../actions/accountsActions';
import {
  getAggregatedPositions,
  getAllValidPositions,
} from '../../reducers/balancesReducer';
import {
  futuresContractsPromiseCreator,
  getFuturesContracts,
} from '../../reducers/contractsReducer';
import { FUTURES_CONTRACTS, POSITIONS } from '../../constants/actionTypes';
import { getSelectedAccountIdBySlice } from '../../selectors';
import { getFuturesProducts } from '../../reducers/futuresProductReducer';
import XTable7 from '../../common/table/XTable7';
import { futuresPositionMarginDeliveryMetadata } from '../../metadata/marginDeliveryMetadata';
import {
  getFuturesPositionMarginDelivery,
  fetchMarginDelivery,
  MARGIN_DELIVERY,
} from '../../reducers/riskReducer';

const mapStateToProps = (state) => ({
  aggregatedPositionsList: getAggregatedPositions(state),
  futuresContracts: getFuturesContracts(state),
  futuresProducts: getFuturesProducts(state),
  futuresContractsLoading: createLoadingSelector([FUTURES_CONTRACTS])(state),
  loading: createLoadingSelector([MARGIN_DELIVERY])(state),
  positionsList: getAllValidPositions(state),
  positionsLoading: createLoadingSelector([POSITIONS])(state),
  selectedAccountId: getSelectedAccountIdBySlice('accounts')(state),
  futuresPositionMarginDelivery: getFuturesPositionMarginDelivery(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindPromiseCreators(
    {
      futuresContractsPromiseCreator,
      createCloseoutInstructionPromiseCreator,
    },
    dispatch,
  ),
  ...bindActionCreators(
    {
      clearPositions,
      hideModal,
      fetchMarginDelivery,
      positions,
      showModal,
    },
    dispatch,
  ),
});

class FuturesPositionsContainer extends PureComponent {
  state = {
    selection: [],
  };

  componentDidMount = () => {};

  fetchData = ({ pageSize, pageIndex }) => {
    let sort = [];
    let filter = [
      { attr: 'account_id', op: 'eq', value: this.props.selectedAccountId },
    ];

    this.props.fetchMarginDelivery({
      limit: pageSize,
      offset: pageIndex * pageSize,
      page: pageIndex,
      filter,
      sort,
    });
  };

  render = () => {
    return (
      <Fragment>
        <Header as="h2">
          Futures Positions
          <Header.Subheader content="* not affected by report date selection." />
        </Header>
        <XTable7
          metadata={futuresPositionMarginDeliveryMetadata()}
          data={this.props.futuresPositionMarginDelivery || []}
          fetchData={this.fetchData}
          showHeader={true}
          showPagination={false}
          expandRows
          loading={this.loading}
        ></XTable7>
      </Fragment>
    );
  };
}

FuturesPositionsContainer.propTypes = {
  futuresProducts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  futuresContractsPromiseCreator: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  positions: PropTypes.func.isRequired,
  positionsAggregated: PropTypes.func.isRequired,
  positionsList: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        symbol: PropTypes.string,
        positions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            qty: PropTypes.string,
            px: PropTypes.string,
          }),
        ),
      }),
    ),
  ),
  loading: PropTypes.bool,
  selectedAccount: PropTypes.objectOf(PropTypes.any),
  selectedAccountId: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
};

FuturesPositionsContainer.defaultProps = {
  positionsList: [],
  loading: false,
  futuresProducts: [],
  selectedAccount: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuturesPositionsContainer);
