import { arrayToObject } from 'erisxkit/client';
import { createRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import _ from 'lodash';
import { createSelector } from 'reselect';
import * as actionTypes from '../constants/actionTypes';

//* Routines  */
export const spotProducts = createRoutine(actionTypes.SPOT_PRODUCTS);
export const createSpotProduct = createRoutine(actionTypes.CREATE_SPOT_PRODUCT);
export const updateSpotProduct = createRoutine(actionTypes.UPDATE_SPOT_PRODUCT);

//* Reducer  */
export default handleActions(
  {
    [spotProducts.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      spotProducts: arrayToObject(_.get(payload, 'spotProducts', []), 'symbol'),
    }),
    [createSpotProduct.SUCCESS]: (state, { payload }) => ({
      ...state,
      spotProducts: {
        ...state.spotProducts,
        [payload.symbol]: payload,
      },
    }),
    [updateSpotProduct.SUCCESS]: (state, { payload }) => ({
      ...state,
      spotProducts: {
        ...state.spotProducts,
        [payload.symbol]: payload,
      },
    }),
  },
  {},
);

//* Selectors  */
export const getSpotProducts = (state) =>
  _.chain(state).get(['spotProducts', 'spotProducts'], {}).values().value();
export const getSpotProductSymbolsAsOptions = createSelector(
  [getSpotProducts],
  (spotProductsList) =>
    spotProductsList.map((product) => ({
      key: product.symbol,
      text: product.symbol,
      description: product.description,
      value: product.symbol,
    })),
);
