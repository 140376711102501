export const [BMO, SILVERGATE, SILVERGATE_SEN] = [0, 1, 2];

export const providerTypes = [
  {
    key: 'bmo',
    value: 'bmo',
    text: 'bmo',
  },
  {
    key: 'silvergate',
    value: 'silvergate',
    text: 'silvergate',
  },
  {
    key: 'silvergate_sen',
    value: 'silvergate_sen',
    text: 'silvergate_sen',
  },
  {
    key: 'signature',
    value: 'signature',
    text: 'signature',
  },
  {
    key: 'brown_brothers',
    value: 'brown_brothers',
    text: 'brown_brothers',
  },
  {
    key: 'signet',
    value: 'signet',
    text: 'signet',
  },
];
