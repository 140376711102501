import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import TextButton from '../common/TextButton';
import colors from '../constants/colors';
import { TrustChip } from '../containers/Transactions/TravelRuleContainer/TravelRuleContainer';

export const startTimeColId = 'startTime';
export const endTimeColId = 'endTime';
export default [
  {
    Header: 'TRUST',
    id: 'fromTrust',
    accessor: 'fromTrust',
    Cell: ({ row }) => {
      const isTrust = get(row, 'original.fromTrust', null);
      const text = isTrust === null ? 'Pending' : String(isTrust);
      return (
        <div className="flex-row centered">
          <TrustChip isTrust={!!isTrust} text={text} />
        </div>
      );
    },
    filter: 'exact',
    maxWidth: 110,
    hideFilter: true,
    getCellExportValue: (row) => {
      const isTrust = get(row, 'original.fromTrust');
      const text = isTrust === null ? 'Pending' : isTrust;
      return text;
    },
    disableSortBy: true,
  },
  {
    Header: 'Time',
    id: 'time',
    accessor: (row) => moment(row.time).format('lll'),
    getCellExportValue: (row) =>
      moment(get(row, 'original.time', '')).format('lll'),
    width: 230,
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: '',
    id: 'endTime',
    accessor: 'endTime',
    width: 230,
    neverShow: true,
    hideFilter: true,
    disableExport: true,
    disableSortBy: true,
  },
  {
    Header: '',
    id: 'startTime',
    accessor: 'startTime',
    width: 230,
    neverShow: true,
    hideFilter: true,
    disableExport: true,
    disableSortBy: true,
  },
  {
    Header: 'Clearing Member',
    id: 'memberId',
    accessor: 'memberId',
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Account',
    id: 'account',
    accessor: 'account',
    width: 230,
    Cell: ({ row }) => (
      <TextButton
        {...row.getToggleRowExpandedProps()}
        color={colors.accent4}
        text={`${row.isExpanded ? '▼' : '►'} ${row.original?.accountLabel}`}
        fontSize="15px"
        underlineThickness="1px"
      />
    ),
    hideFilter: true,
    disableExport: true,
    disableSortBy: true,
  },
  {
    Header: 'Source Address',
    id: 'sourceAddress',
    accessor: 'sourceAddress',
    width: 230,
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Asset Type',
    id: 'assetType',
    accessor: 'assetType',
    width: 230,
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Category',
    id: 'category',
    accessor: 'category',
    width: 230,
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: 'amount',
    width: 230,
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Account Name',
    id: 'name',
    accessor: 'name',
    neverShow: true,
    getCellExportValue: (row) => get(row, 'original.accountLabel', ''),
  },
  {
    Header: 'Account Number',
    id: 'accountNumber',
    accessor: 'accountNumber',
    neverShow: true,
    getCellExportValue: (row) => get(row, 'original.accountId', ''),
  },
  {
    Header: 'Address',
    id: 'address1',
    accessor: 'address1',
    neverShow: true,
    getCellExportValue: (row) => get(row, 'original.originator.address1', ''),
  },
  {
    Header: 'Address2',
    id: 'address2',
    accessor: 'address2',
    neverShow: true,
    getCellExportValue: (row) => get(row, 'original.originator.address2', ''),
  },
  {
    Header: 'Town Name',
    id: 'townName',
    accessor: 'townName',
    neverShow: true,
    getCellExportValue: (row) => get(row, 'original.originator.townName', ''),
  },
  {
    Header: 'Country',
    id: 'country',
    accessor: 'country',
    neverShow: true,
    getCellExportValue: (row) => get(row, 'original.originator.country', ''),
  },
  {
    Header: 'Financial Institution',
    id: 'financialInstitution',
    accessor: 'financialInstitution',
    neverShow: true,
    getCellExportValue: (row) =>
      get(row, 'original.originatingVasp.legalPersonName', ''),
  },
  {
    Header: 'Fee',
    id: 'incomeFee',
    accessor: 'incomeFee',
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Total',
    id: 'total',
    accessor: 'total',
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Detailed Status',
    id: 'state',
    accessor: 'state',
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Notional Threshold',
    id: 'notionalThreshold',
    accessor: 'notionalThreshold',
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: 'Buffer Percentage',
    id: 'thresholdBuffer',
    accessor: 'thresholdBuffer',
    hideFilter: true,
    disableSortBy: true,
  },
  {
    Header: '',
    id: 'edited',
    accessor: 'edited',
    neverShow: true,
    disableExport: true,
  },
  {
    Header: '',
    id: 'accountId',
    accessor: 'accountId',
    neverShow: true,
    disableExport: true,
  },
];
