import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Alpha',
    id: 'alpha',
    accessor: 'alpha',
    required: true,
    minWidth: 180,
  },
  {
    Header: 'Funding Rate Interval',
    id: 'fundingRateInterval',
    accessor: 'fundingRateInterval',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 200,
  },
  {
    Header: 'Reference Price Interval',
    id: 'referencePriceInterval',
    accessor: 'referencePriceInterval',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 210,
  },
  {
    Header: 'Funding Rate Price Scale',
    id: 'fundingRatePriceScale',
    accessor: 'fundingRatePriceScale',
    required: true,
    minWidth: 230,
  },
  {
    Header: 'Max Bid Ask Spread',
    id: 'maxBidAskSpread',
    accessor: 'maxBidAskSpread',
    required: true,
    minWidth: 210,
  },
  {
    Header: 'Max Diff Theo Vwap Price',
    id: 'maxDiffTheoVwapPrice',
    accessor: 'maxDiffTheoVwapPrice',
    required: true,
    minWidth: 230,
  },
  {
    Header: 'Max Diff Reference Previous Price',
    id: 'maxDiffReferencePreviousPrice',
    accessor: 'maxDiffReferencePreviousPrice',
    required: true,
    minWidth: 250,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
