import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { getColorFromUserState } from 'erisxkit/client';
import { memberUser } from '../constants/userTypes';
import { memberColumnXTable7 } from '../common/table/commonMetadata';
import { formatTimeLocal } from '../utils/time';
import {
  SelectColumnFilter,
  DefaultColumnNoDelayFilter,
  NamesFilter,
  DateRangeColumnFilter,
  SelectColumnFilterWithUrl,
} from '../common/table/Filters';
import * as USER_STATES from '../constants/userStates';
import { concatStrings } from '../utils/methods';
import { clone } from 'lodash';

export const CLONE_MEMBER_USER_COLUMN = 'clone';

const IndexMetadata = (toggleDisable, type, onClone) => {
  const columns = [
    {
      Header: 'Member Name',
      id: 'member_name',
      width: 200,
      Filter: NamesFilter,
      accessor: (u) => concatStrings([u.firstName, u.middleName, u.lastName]),
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: 'email',
      hideFilter: type === memberUser,
    },
    memberColumnXTable7({ hideFilter: true }),
    {
      Header: 'Created Date',
      id: 'created_at',
      accessor: (u) => formatTimeLocal(u.createdAt),
      Filter: DateRangeColumnFilter,
      hideFilter: true,
    },
    {
      Header: 'User Id',
      id: 'user_id',
      accessor: 'userId',
      filterOp: 'match',
      Filter: DefaultColumnNoDelayFilter,
    },
    {
      Header: 'Auth Id',
      id: 'auth_id',
      accessor: 'authId',
    },
  ];
  type === memberUser &&
    columns.push({
      Header: 'State',
      id: 'state',
      Filter: SelectColumnFilter,
      options: Object.values(USER_STATES),
      accessor: (d) => (
        <Label
          content={d.state || 'pending_pii'}
          color={getColorFromUserState(d.state)}
        />
      ),
      width: 140,
      filterOp: 'eq',
    });
  columns.push({
    Header: 'Status',
    id: 'disabled',
    accessor: (d) => (
      <Label
        color={!d.disabled ? 'green' : 'red'}
        content={!d.disabled ? 'Active' : 'Disabled'}
      />
    ),
    width: 80,
    filterOp: 'eq',
    Filter: SelectColumnFilterWithUrl,
    options: ['active', 'disabled'],
  });
  type === memberUser &&
    columns.push({
      Header: '',
      id: 'disable',
      accessor: (d) => (
        <Icon
          onClick={() => toggleDisable(d)}
          link
          name={d.disabled ? 'check' : 'eye slash'}
        />
      ),
      filterable: false,
      hideFilter: true,
      width: 35,
    });

  type === memberUser &&
    columns.push({
      Header: 'Clone',
      id: CLONE_MEMBER_USER_COLUMN,
      accessor: (memberUser) => (
        <Icon onClick={() => onClone(memberUser)} name="clone" />
      ),
      filterable: false,
      hideFilter: true,
      width: 50,
    });

  return columns;
};
export default IndexMetadata;
