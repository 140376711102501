import React from 'react';
import { Button } from 'semantic-ui-react';
import Big from 'bignumber.js';
import { columns } from 'erisxkit/client';
import { formatTimeLocal, formatDateLocal } from '../utils/time';

const cols = (showReversalModal, balancesView, futures = false) => [
  {
    Header: 'Trade Date',
    id: 'tradeDate',
    filterable: false,
    headerClassName: 'pull-left',
    accessor: ({ date, reportDate }) =>
      reportDate ? formatDateLocal(reportDate) : formatDateLocal(date),
    width: 100,
  },
  {
    Header: 'Subexchange',
    accessor: 'subExchange',
    id: 'subExchange',
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: 'productSymbol',
  },
  {
    Header: 'Contract',
    id: 'contractCode',
    accessor: 'contractCode',
  },
  {
    Header: 'Member Name',
    id: 'memberName',
    accessor: 'memberName',
  },
  {
    Header: 'Account',
    id: 'accountLabel',
    accessor: 'accountLabel',
    width: columns.ACCOUNT_NAME,
  },
  {
    Header: 'Trade Type',
    accessor: (row) => row.tradeType || row.trdType,
    id: 'tradeType',
    show: futures,
  },
  {
    Header: 'Quantity',
    accessor: 'qty',
    className: 'mono',
    id: 'qty',
  },
  {
    Header: 'Price',
    accessor: 'px',
    className: 'mono',
    id: 'px',
  },
  {
    Header: 'Trade Type',
    accessor: (row) => row.tradeType || row.trdType,
    id: 'tradeType',
    show: !futures,
  },
  {
    Header: 'Reserved Margin',
    accessor: (row) => row.initialMargin,
    className: 'mono',
    id: 'initialMargin',
    show: futures,
  },
  {
    Header: 'Execution Time',
    id: 'execTime',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.time),
  },
  {
    Header: 'Report Time',
    id: 'reportTime',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.reportTime),
  },
  {
    Header: 'Aggressor',
    accessor: 'aggressor',
    id: 'aggressor',
  },
  {
    Header: 'Exchange Fee',
    id: 'exchange',
    className: 'mono',
    accessor: (row) =>
      `${row.exchangeFee || row.exchangeFees || ''} ${row.feeType || ''}`,
  },
  {
    Header: 'Clearing Fee',
    id: 'clearingFee',
    className: 'mono',
    accessor: ({
      clearingFee = 0,
      clearingFees = 0,
      feeType,
      deliveryFee = 0,
    }) => {
      // need this because 'trades' and 'trade_journals' endpoints have
      // different names for the same attr, can be removed once we deprecate one of the endpoints
      const feeClearing = clearingFee || clearingFees || 0;
      const feeClearingTotal = Big(feeClearing).plus(deliveryFee).toFixed();
      return `${feeClearingTotal} ${feeType || ''}`;
    },
  },
  {
    Header: 'Spread ID',
    id: 'secondaryExecId',
    accessor: 'secondaryExecId',
  },
  {
    Header: 'Submitter',
    id: 'submitter',
    accessor: 'submitter',
  },
  {
    Header: 'Trade Id',
    accessor: 'tradeId',
    id: 'tradeId',
  },
  {
    Header: 'CAR',
    accessor: 'customerAccountRef',
    id: 'customerAccountRef',
  },
  {
    Header: 'CTI',
    id: 'cti',
    accessor: 'cti',
  },
  {
    Header: 'Origin',
    id: 'origin',
    accessor: 'origin',
  },
  {
    id: 'reversal',
    accessor: (row) => (
      <Button
        disabled={!!row.reversalUuid}
        circular
        className="reversalButton"
        size="mini"
        onClick={() => showReversalModal(row)}
        icon="undo"
        color="red"
        content={`Reverse${row.reversalUuid ? 'd' : ''}`}
      />
    ),
    width: 95,
    show: !balancesView,
  },
];

export default cols;
