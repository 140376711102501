import React from 'react';
import { create } from 'cogo-toast';

export default ({
  type = 'success',
  item = 'item',
  href = '#',
  ...options
}) => {
  const hide = create(
    <p>
      New {item} created.{' '}
      <a target="_blank" href={href}>
        Click here to view it.
      </a>
    </p>,
    {
      hideAfter: 0,
      onClick: () => hide(),
      type,
      ...options,
    },
  );
  return hide; // return cogo-toast create so we can test arguments
};
