import React, { useMemo } from 'react';
import { Header } from 'semantic-ui-react';
import RequestLogContainer from './RequestLogContainer';
import UpcomingDepositsContainer from './UpcomingDepositsContainer';
import XTab from '../../common/tabs/XTab';
import TravelRuleContainer from './TravelRuleContainer/TravelRuleContainer';
import useTravelRuleEnabled from './TravelRuleContainer/useTravelRuleEnabled';

const getPanes = (travelRuleEnabled) => {
  const mainPanes = [
    {
      url: 'pending',
      menuItem: 'Pending',
      render: () => <RequestLogContainer state="pending" key="pending" />,
    },
    {
      url: 'rejected',
      menuItem: 'Rejected',
      render: () => <RequestLogContainer state="rejected" key="rejected" />,
    },
    {
      url: 'approved',
      menuItem: 'Approved',
      render: () => <RequestLogContainer state="posting" key="posting" />,
    },
    {
      url: 'scheduled_ach',
      menuItem: 'Scheduled ACH',
      render: () => <UpcomingDepositsContainer />,
    },
  ];
  if (travelRuleEnabled)
    mainPanes.push({
      url: 'travel_rule',
      menuItem: 'Travel Rule',
      render: () => <TravelRuleContainer />,
    });

  return mainPanes;
};

const TransactionLogsContainer = () => {
  const { isEnabled: travelRuleEnabled } = useTravelRuleEnabled();
  const panes = useMemo(() => getPanes(travelRuleEnabled), [travelRuleEnabled]);
  return (
    <>
      <Header as="h1">
        <Header.Content>Movements</Header.Content>
      </Header>
      <XTab panes={panes} />
    </>
  );
};

export default TransactionLogsContainer;
