import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import { Form, Select } from 'semantic-ui-react';
import {
  fetchEmployees,
  getOnboardingEmployees,
} from '../../reducers/employeesReducer';

export const UNASSIGNED_EMPLOYEE_USER_ID = '0';
export const PENDING_CLIENT_ACTION_EMPLOYEE_USER_ID = '1';

const mapDispatchToProps = {
  fetchEmployees,
};

const employeeToOptions = (employees) =>
  _.map(employees, (o) => ({
    key: o.userId,
    value: o.userId,
    text: o.email,
    description: o.userId,
  }));

const mapStateToProps = (state) => {
  const filteredEmployees = getOnboardingEmployees(state);
  const options = employeeToOptions(filteredEmployees);
  options.unshift(
    {
      key: UNASSIGNED_EMPLOYEE_USER_ID,
      value: UNASSIGNED_EMPLOYEE_USER_ID,
      text: 'Unassigned',
      description: 'Unassigned',
    },
    {
      key: PENDING_CLIENT_ACTION_EMPLOYEE_USER_ID,
      value: PENDING_CLIENT_ACTION_EMPLOYEE_USER_ID,
      text: 'Pending Client Action',
      description: 'Pending Client Action',
    },
  );

  return {
    options,
    loading: createLoadingSelector(['USERS'])(state),
  };
};

// TODO: generalize this further into a generic search component
class EmployeeSelectionContainer extends Component {
  constructor(props) {
    super(props);
    // Debounce
    this.handleSearchChange = _.debounce(this.handleSearchChange, 500);
  }

  state = { searchQuery: '' };

  _fetchEmployees = (searchQuery) => {
    const args = {
      filter: searchQuery
        ? [
            ...this.props.additionalFilters,
            { attr: 'email', op: 'contain', value: searchQuery },
          ]
        : this.props.additionalFilters,
    };
    searchQuery ? (args.limit = 10) : (args.page = 0);
    this.props.fetchEmployees(args);
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState({ searchQuery });
    this._fetchEmployees(searchQuery);
  };

  render = () => (
    <Form.Field
      control={Select}
      search
      selection
      options={
        this.state.searchQuery
          ? this.props.optionsSearchQuery
          : this.props.options
      }
      onChange={(e, { value }) =>
        _.get(this.props, ['input', 'onChange'], () => {})(value)
      }
      defaultValue={_.get(this.props, ['input', 'value'])}
      value={_.get(this.props, ['input', 'value'])}
      onSearchChange={this.handleSearchChange}
      loading={this.props.loading}
      noResultsMessage={this.state.searchQuery ? '' : 'Search for a user.'}
      label="Start typing to search for a user"
      selectOnBlur={false}
      {...this.props}
    />
  );
}

EmployeeSelectionContainer.propTypes = {
  additionalFilters: PropTypes.arrayOf(
    PropTypes.shape({
      attr: PropTypes.string,
      op: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

// Specifies the default values for props:
EmployeeSelectionContainer.defaultProps = {
  additionalFilters: [],
  name: 'userId',
  placeholder: 'Email of user',
  multiple: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeSelectionContainer);
