import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import moment from 'moment';
import CoreFiles from '../components/CoreFiles';
import {
  exportCoreFile,
  coreFile as queryCoreFiles,
  getCoreFileTasks,
  getCurrentTaskId,
} from '../reducers/coreFilesReducer';
import { triggerTask } from '../reducers/taskLogReducer';
import { ACH_MOVEMENTS } from '../constants/tasks';

const mapDispatchToProps = {
  exportCoreFile,
  queryCoreFiles,
  triggerTask,
};

const mapStateToProps = (state) => ({
  coreFileTasks: getCoreFileTasks(state),
  currentTaskId: getCurrentTaskId(state),
});

class CoreFilesContainer extends Component {
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.exportCoreFile(this.state);
  };

  triggerACHTask = () => {
    const currentDate = Moment().format('YYYYMMDD');
    if (this.state?.beginDate && this.state?.endDate) {
      this.props.triggerTask({
        name: ACH_MOVEMENTS,
        options: `--date ${currentDate} --start-date ${this.state.beginDate.replace(/-/g, '')} --end-date ${this.state.endDate.replace(/-/g, '')}`,
        time: moment().toISOString(),
      });
    }
  };

  render = () => (
    <CoreFiles
      coreFileTasks={this.props.coreFileTasks}
      onChange={this.handleChange}
      onSubmit={this.handleSubmit}
      triggerACHTask={this.triggerACHTask}
      state={this.state}
    />
  );
}

CoreFilesContainer.propTypes = {
  exportCoreFile: PropTypes.func.isRequired,
  coreFileTasks: PropTypes.arrayOf(
    PropTypes.shape({
      taskId: PropTypes.string,
      state: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

CoreFilesContainer.defaultProps = {
  coreFileTasks: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreFilesContainer);
