import { roles } from '../../../constants/userRoles';
import useRoleValidation from '../../../hooks/useRoleValidation';

export const TRAVEL_RULE_READ_ONLY_ROLES = [
  roles.ONBOARDING,
  roles.CLIENT_SERVICES,
];

export const TRAVEL_RULE_WRITE_ROLES = [
  roles.ONBOARDING,
  roles.CLIENT_SERVICES,
  roles.SUPER_USER,
  roles.COMPLIANCE,
  roles.TREASURY,
  roles.CLEARING_OPS,
];
export const TRAVEL_RULE_ALLOWED_ROLES = [
  ...TRAVEL_RULE_READ_ONLY_ROLES,
  ...TRAVEL_RULE_WRITE_ROLES,
];

const useTravelRuleEnabled = () => {
  // All roles can read
  const canRead = true;
  const canWrite = useRoleValidation(TRAVEL_RULE_WRITE_ROLES);
  return { canRead, canWrite, isEnabled: canRead || canWrite };
};

export default useTravelRuleEnabled;
