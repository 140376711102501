import _ from 'lodash';
import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { Table, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MainDiv = styled.div`
  margin-top: 20px;
  margin: auto;
`;

const SubHeader = styled.div`
  display: flex;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CheckboxHeader = styled(Checkbox)`
  width: 150px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const CheckboxAll = styled(Checkbox)`
  font-weight: bold;
  float: right;
  margin-bottom: 20px;
`;

const Item = styled.span`
  margin-right: 20px;
  display: inline-block;
  width: 220px;
  margin-bottom: 10px;
`;

const NoBorderTable = styled(Table)`
  border: none !important;
`;

const Permissions = ({
  header,
  permissions,
  permissionsStructure,
  permissionsWithHeaderStructure,
  onChange,
  name,
  dataWithHeader,
}) => {
  let permissionsWithHeaderStructureEntries = null;

  if (dataWithHeader) {
    permissionsWithHeaderStructureEntries = Object.entries(
      permissionsWithHeaderStructure,
    );
  }

  const onPermissionsChange = (value) => {
    onChange(null, {
      name: name,
      value: value,
    });
  };

  const changeCheck = (checked, permission) => {
    if (checked) {
      //if is checked adds the permission from the list
      onPermissionsChange([...permissions, permission]);
    } else {
      //if is not checked removes the permission to the list
      onPermissionsChange(permissions.filter((p) => p !== permission));
    }
  };

  const changeHeaderCheck = (checked, childPermissions) => {
    const childs = childPermissions.map((x) => x.prop);
    if (checked) {
      //if is checked adds the permission from the list
      const tempPermissions = permissions.filter((p) => !childs.includes(p));
      onPermissionsChange([...tempPermissions, ...childs]);
    } else {
      //if is not checked removes the permission to the list
      onPermissionsChange(permissions.filter((p) => !childs.includes(p)));
    }
  };

  const selectAll = (e) => {
    if (e) {
      if (dataWithHeader) {
        onPermissionsChange(
          _.flatten(
            permissionsWithHeaderStructureEntries?.map(([key, value]) =>
              value.map((x) => x.prop),
            ),
          ),
        );
      } else {
        onPermissionsChange(
          _.flatten(permissionsStructure?.map((x) => x.prop)),
        );
      }
    } else {
      onPermissionsChange([]);
    }
  };

  const isChecked = (prop) => {
    if (permissions === null) {
      return true;
    }
    return permissions?.includes(prop);
  };

  const isSubArray = (currentPermissions, sub) =>
    sub.every((elem) => currentPermissions?.includes(elem));

  const isHeaderIndeterminate = (prop) => {
    const childProps = prop.map((x) => x.prop);
    const some = permissions?.some((permision) =>
      childProps.includes(permision),
    );
    const all = isSubArray(permissions, childProps);

    if (all || permissions === null) {
      return false;
    }
    return some;
  };

  const isHeaderChecked = (prop) => {
    const childProps = prop.map((x) => x.prop);
    const all = isSubArray(permissions, childProps);

    return all || permissions === null;
  };

  const allSelected = () => {
    let selectedOnes = [];
    if (dataWithHeader) {
      selectedOnes = _.flatten(
        permissionsWithHeaderStructureEntries?.map(([key, value]) =>
          value.map((x) => isChecked(x.prop)),
        ),
      );
    } else {
      selectedOnes = _.flatten(
        permissionsStructure?.map((x) => isChecked(x.prop)),
      );
    }
    return !selectedOnes.includes(false);
  };

  return (
    <MainDiv>
      <SubHeaderContainer>
        <Header as="h2">{header}</Header>
        {(permissionsStructure?.length > 0 ||
          permissionsWithHeaderStructureEntries?.length > 0) && (
          <CheckboxAll
            label={'Select / Deselect All'}
            checked={allSelected()}
            onChange={(e, { checked }) => selectAll(checked)}
          />
        )}
      </SubHeaderContainer>
      <NoBorderTable class="ui very basic collapsing celled table">
        <Table.Body>
          {dataWithHeader &&
            permissionsWithHeaderStructureEntries?.map(([key, value]) => {
              return (
                <Table.Row>
                  <SubHeader>
                    <Table.Cell>
                      <CheckboxHeader
                        label={key}
                        indeterminate={isHeaderIndeterminate(value)}
                        checked={isHeaderChecked(value)}
                        onChange={(e, { checked }) =>
                          changeHeaderCheck(checked, value)
                        }
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <span>
                        {value.map((x) => (
                          <Item>
                            <Checkbox
                              label={x.text}
                              checked={isChecked(x.prop)}
                              onChange={(e, { checked }) =>
                                changeCheck(checked, x.prop)
                              }
                            />
                          </Item>
                        ))}
                      </span>
                    </Table.Cell>
                  </SubHeader>
                </Table.Row>
              );
            })}
          {!dataWithHeader && (
            <Table.Row>
              <Table.Cell>
                {permissionsStructure.map((x) => (
                  <Item>
                    <Checkbox
                      label={x.text}
                      checked={isChecked(x.prop)}
                      onChange={(e, { checked }) =>
                        changeCheck(checked, x.prop)
                      }
                    />
                  </Item>
                ))}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </NoBorderTable>
    </MainDiv>
  );
};

export default Permissions;

Permissions.defaultProps = {
  header: '',
  permissionsStructure: [],
  permissionsWithHeaderStructure: {},
  name: '',
  dataWithHeader: false,
  permissions: [],
};

Permissions.propTypes = {
  header: PropTypes.string,
  permissionsWithHeaderStructure: PropTypes.object,
  permissionsStructure: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      prop: PropTypes.string,
    }),
  ),
  permissions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  name: PropTypes.string,
  dataWithHeader: PropTypes.bool,
};
