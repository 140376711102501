import { formatTimeLocal } from '../utils/time';
import { getStatusName } from '../utils/methods';

export default [
  {
    Header: 'Clearing Member Type',
    id: 'clearingMemberType',
    accessor: 'clearingMemberType',
  },
  {
    Header: 'Firm Code',
    id: 'firmCode',
    accessor: 'firmCode',
  },
  {
    Header: 'Name',
    id: 'name',
    accessor: 'name',
  },
  {
    Header: 'E-Market Organization Name',
    id: 'emarketOrganizationName',
    accessor: 'emarketOrganizationName',
  },
  {
    Header: 'State',
    id: 'locationState',
    accessor: 'locationState',
  },
  {
    Header: 'Country',
    id: 'country',
    accessor: 'country',
  },
  {
    Header: 'Created Date',
    id: 'createdAt',
    accessor: (m) => formatTimeLocal(m.createdAt),
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: (m) => getStatusName(m.status),
  },
  {
    Header: 'Status Date',
    id: 'statusDate',
    accessor: (m) => formatTimeLocal(m.statusDate),
  },
];
