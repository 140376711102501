const ADMIN_UI_VIEWS = {
  APPROVALS: 'approvals',
  RECONCILIATION: 'reconciliation',
  FINANCE_ACCOUNTS: 'financeAccounts',
  CLEARING_ACCOUNTS: 'clearingAccounts',
  BALANCES: 'balances',
  DESIGNATED_FUNDS: 'designatedFunds',
  MEMBERS: 'members',
  MEMBER_USERS: 'memberUsers',
  ONBOARDING: 'onboarding',
  EMPLOYEES: 'employees',
  SPOT_PRODUCTS: 'spotProducts',
  COLLATERAL_PRODUCTS: 'collateralProducts',
  COLLATERAL_CONTRACTS: 'collateralContracts',
  ADD_COLLATERAL: 'addCollateral',
  EXTERNAL_TRANSFER: 'externalTransfer',
  CLOSING_PRICES: 'closingPrices',
  FEE_REBATE: 'feeRebate',
  TRADE_ENTRY: 'tradeEntry',
  BANK_COMMUNCIATION: 'bankCommunication',
  TME_ADJUSTMENT: 'tmeAdjustment',
  GENERAL_JOURNAL: 'generalJournal',
  TRADES: 'trades',
  REQUESTS: 'requests',
  CREATE_ASSET_TYPE: 'createAssetType',
  CORE_FILES: 'coreFiles',
  SYSTEM_ALERTS: 'systemAlerts',
  CHANGE_LOGS: 'changeLogs',
  EXCEPTIONS: 'exceptions',
  TASK_LOGS: 'taskLogs',
  TRIGGER_TASKS: 'triggerTasks',
  SURVEILLANCE: 'surveillance',
  ABOUT: 'about',
  POSITIONS: 'positions',
  JOURNALS: 'journals',
  DESIGNATION_TRANSFER: 'designationTransfer',
  CONFIGURATION: 'configuration',
  CLIENT_TRANSFERS: 'clientTransfers',
  SETTLEMENT_SERVICE: 'settlementService',
  MARGIN_RATES: 'marginRates',
  MARGIN_REQUIREMENTS: 'marginRequirements',
  IP_AUTHORIZATION: 'ipAuthorization',
  MODIFY_IPS: 'modifyIps',
  POSITION_TRANSFER: 'positionTransfer',
  TRUST_THRESHOLD_SETTINGS: 'trustThresholdSettings',
  TRUST_ADMIN: 'trustAdmin',
  OLD_POSITIONS_TAB: 'oldPositionsTab',
  OLD_CLOSEOUT_JOURNALS_TAB: 'oldCloseoutJournalsTab',
  OLD_DELIVERIES_TAB: 'oldDeliveriesTab',
};

export default ADMIN_UI_VIEWS;
