import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { RootState } from '../ts/types/store';
import { CGM } from '../ts/models/CGM.model';
import { GetCGMListResponse } from '../ts/api/cgm';

/** Action names */
export const FETCH_CGM_LIST = 'GET_CGM_LIST';
export const DELETE_CGM = 'DELETE_CGM';
export const LINK_CGM_TO_PARTICIPANT = 'LINK_CGM_TO_PARTICIPANT';
export const UNLINK_CGM_TO_PARTICIPANT = 'UNLINK_CGM_TO_PARTICIPANT';

/** Routines */
export const createCGM = createRoutine('CREATE_CGM');
export const updateCGM = createRoutine('UPDATE_CGM');
export const fetchCgmList = createRoutine(FETCH_CGM_LIST);
export const deleteCgm = createRoutine(DELETE_CGM);
export const linkCgmToParticipant = createRoutine(LINK_CGM_TO_PARTICIPANT);
export const unlinkCgmToParticipant = createRoutine(UNLINK_CGM_TO_PARTICIPANT);

/** Routines */
export const createCgmPromiseCreator = promisifyRoutine(createCGM);
export const updateCgmPromiseCreator = promisifyRoutine(updateCGM);
export const deleteCgmPromiseCreator = promisifyRoutine(deleteCgm);

/** Promisified Routines */
export const linkCgmToParticipantPromise =
  promisifyRoutine(linkCgmToParticipant);
export const unlinkCgmToParticipantPromise = promisifyRoutine(
  unlinkCgmToParticipant,
);

/** Initial State */
type CgmManagementState = {
  cgms?: GetCGMListResponse;
};

const initialState: CgmManagementState = {};

type ActionPayload = GetCGMListResponse;

/** Reducer */
export default handleActions<CgmManagementState, ActionPayload>(
  {
    [fetchCgmList.SUCCESS]: (state, { payload }) => ({
      ...state,
      cgms: payload,
    }),
  },
  initialState,
);

/** Selectors */
export const getCGMState = (state: RootState) =>
  get(state, 'cgmManagement', {});

export const getCGMS = createSelector(
  [getCGMState],
  (state) => get(state, 'cgms', {}) as GetCGMListResponse,
);

export const getCGMList = createSelector(
  [getCGMS],
  (state) => get(state, 'cgmList', []) as CGM[],
);

export const getCgmSelectOptions = createSelector([getCGMList], (cgms) =>
  cgms.map((e: CGM) => ({
    key: e.cgmNumber,
    value: e,
    text: e.cgmNumber,
  })),
);

export const getCgmNumberSelectOptions = createSelector([getCGMList], (cgms) =>
  cgms.map((cgm) => ({
    key: cgm.cgmNumber,
    value: cgm.cgmNumber,
    text: cgm.cgmNumber,
  })),
);

export const getCarTableCgmSelectOptions = createSelector(
  [getCGMList],
  (cgms) =>
    cgms.map((e: CGM) => ({
      key: e.cgmNumber,
      value: e.cgmNumber,
      text: e.cgmNumber,
    })),
);

export const getCgmOmnibusAccountNumber = createSelector(
  [getCGMState],
  (cgms) => get(cgms, 'omnibusAccountNumber', ''),
);

export const getCgmOmnibusAccountId = createSelector([getCGMState], (cgms) =>
  get(cgms, 'omnibusAccountId', ''),
);
