import _ from 'lodash';
import { combineReducers } from 'redux';
import { createRoutine } from 'redux-saga-routines';
import {
  createCount,
  createPages,
  createCurrentPage,
  createById,
} from 'erisxkit/client';

const initialState = {
  count: 0,
  byId: {},
};

export const CANCEL_BLOCK_TRADE = 'CANCEL_BLOCK_TRADE';
export const COMMIT_BLOCK_TRADE = 'COMMIT_BLOCK_TRADE';
export const BLOCK_TRADE_REQUESTS = 'BLOCK_TRADE_REQUESTS';

export const cancelBlockTradeRequest = createRoutine(CANCEL_BLOCK_TRADE);
export const commitBlockTradeRequest = createRoutine(COMMIT_BLOCK_TRADE);
export const fetchBlockTradeRequests = createRoutine(BLOCK_TRADE_REQUESTS);

export default combineReducers({
  byId: createById(fetchBlockTradeRequests, 'blockTradeRequests', 'requestId'),
  count: createCount(fetchBlockTradeRequests),
  pages: createPages(
    fetchBlockTradeRequests,
    'blockTradeRequests',
    'requestId',
  ),
  currentPage: createCurrentPage(fetchBlockTradeRequests),
});

/* Selectors */
const getTrades = (state) => _.get(state, ['blockTradeRequests', 'byId'], {});

export const getTradesList = (state) => Object.values(getTrades(state));
export const getTradesCount = (state) =>
  _.get(state, ['blockTradeRequests', 'count'], 0);
