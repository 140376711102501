import Big from 'bignumber.js';
import React from 'react';
import EditableField from '../common/EditableField';
import { formatTimeLocal } from '../utils/time';
import { formatUSD } from '../utils/methods';

/**
 * @param {bigNumber} bigNumber - initial value to sum
 * @param {number | undefined} value - margin property value
 * @param {number | undefined} valueOverride - margin property override
 * @returns result of adding value or valueOverride to bigNumber if any is defined
 */
const addValue = (bigNumber, value, valueOverride) => {
  // Shortcircuit if not bigNumber
  if (!Big.isBigNumber(bigNumber)) return bigNumber;
  if (valueOverride) return bigNumber.plus(Big(valueOverride));
  if (value) return bigNumber.plus(Big(value));
  return bigNumber;
};
const initialMarginCalculationsMetadata = (
  onEdit,
  onSetValue,
  isBeingEdited,
  onBlur,
) => [
  {
    Header: 'Time',
    id: 'time',
    accessor: (row) => formatTimeLocal(row.time),
    hideFilter: true,
  },
  {
    Header: 'Trade Date',
    id: 'trade_date',
    accessor: (row) => formatTimeLocal(row.tradeDate),
    hideFilter: true,
  },
  {
    Header: 'Type',
    id: 'type',
    accessor: 'type',
    hideFilter: true,
  },
  {
    Header: 'Firm',
    id: 'firmCode',
    accessor: 'firmCode',
    hideFilter: true,
  },
  {
    Header: 'Account',
    id: 'accountCode',
    accessor: 'accountCode',
    hideFilter: true,
  },
  {
    Header: 'Account Type',
    id: 'accountType',
    accessor: 'accountType',
    hideFilter: true,
  },
  {
    Header: 'Origin',
    id: 'origin',
    accessor: 'origin',
    hideFilter: true,
  },
  {
    Header: 'Guaranty Fund',
    id: 'guaranty_fund',
    accessor: (row) => formatUSD(row.guarantyFund),
    hideFilter: true,
  },
  {
    Header: 'Guaranty Fund Override',
    id: 'guaranty_fund_override',
    accessor: (row, id) => {
      const field = 'guaranty_fund_override';
      return (
        <EditableField
          value={row?.guarantyFundOverride}
          type="number"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
    hideFilter: true,
  },
  {
    Header: 'Span Margin',
    id: 'span_margin_requirement',
    accessor: (row) => formatUSD(row.spanMarginRequirement),
    hideFilter: true,
  },
  {
    Header: 'Span Margin Override',
    id: 'span_margin_requirement_override',
    accessor: (row, id) => {
      const field = 'span_margin_requirement_override';
      return (
        <EditableField
          value={row?.spanMarginRequirementOverride}
          type="number"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
    hideFilter: true,
  },
  {
    Header: 'Concentration Add On',
    id: 'concentration_add_on',
    accessor: (row) => formatUSD(row.concentrationAddOn),
    hideFilter: true,
  },
  {
    Header: 'Concentration Add On Override',
    id: 'concentration_add_on_override',
    accessor: (row, id) => {
      const field = 'concentration_add_on_override';
      return (
        <EditableField
          value={row?.concentrationAddOnOverride}
          type="number"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
    hideFilter: true,
  },
  {
    Header: 'Stress Add On',
    id: 'stress_add_on',
    accessor: (row) => formatUSD(row.stressAddOn),
    hideFilter: true,
  },
  {
    Header: 'Stress Add On Override',
    id: 'stress_add_on_override',
    accessor: (row, id) => {
      const field = 'stress_add_on_override';
      return (
        <EditableField
          value={row?.stressAddOnOverride}
          type="number"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
  },
  {
    Header: 'Cover 2 Add On',
    id: 'cover_2_add_on',
    accessor: (row) => formatUSD(row.cover_2AddOn),
    hideFilter: true,
  },
  {
    Header: 'Cover 2 Add On Override',
    id: 'cover_2_add_on_override',
    accessor: (row, id) => {
      const field = 'cover_2_add_on_override';
      return (
        <EditableField
          value={row?.cover2AddOnOverride}
          type="number"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
    hideFilter: true,
  },
  {
    Header: 'Discretionary Add On',
    id: 'discretionary_add_on',
    accessor: (row) => formatUSD(row.discretionaryAddOn),
    hideFilter: true,
  },
  {
    Header: 'Discretionary Add On Override',
    id: 'discretionary_add_on_override',
    accessor: (row, id) => {
      const field = 'discretionary_add_on_override';
      return (
        <EditableField
          value={row?.discretionaryAddOnOverride}
          type="number"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
    hideFilter: true,
  },
  {
    Header: 'Original Calculated Requirement',
    id: 'original_margin_requirement',
    accessor: (row) => formatUSD(row.marginRequirement),
    hideFilter: true,
  },
  {
    Header: 'Margin Requirement',
    id: 'margin_requirement',
    accessor: (row) => {
      const init = Big(
        row?.spanMarginRequirementOverride
          ? row.spanMarginRequirementOverride
          : row.spanMarginRequirement,
      );
      const guarantyFund = addValue(
        init,
        row.guarantyFund,
        row?.guarantyFundOverride,
      );

      const concentrationAddOn = addValue(
        guarantyFund,
        row.concentrationAddOn,
        row?.concentrationAddOnOverride,
      );
      const stressAddOn = addValue(
        concentrationAddOn,
        row.stressAddOn,
        row?.stressAddOnOverride,
      );
      const cover2AddOn = addValue(
        stressAddOn,
        row.cover_2AddOn,
        row?.cover2AddOnOverride,
      );
      const discretionaryAddOn = addValue(
        cover2AddOn,
        row.discretionaryAddOn,
        row?.discretionaryAddOnOverride,
      );
      return formatUSD(discretionaryAddOn.toString());
    },
    hideFilter: true,
  },
  {
    Header: 'Comment',
    id: 'comment',
    accessor: (row, id) => {
      const field = 'comment';
      return (
        <EditableField
          value={row?.comment}
          type="text"
          name={field}
          editing={isBeingEdited(id, field)}
          onEdit={() => onEdit(row, id, field)}
          onChange={(editedField, newValue) =>
            onSetValue(id, editedField, newValue)
          }
          onBlur={onBlur}
        />
      );
    },
    hideFilter: true,
  },
];
export default initialMarginCalculationsMetadata;
