import React, { Fragment } from 'react';
import { Header, Form, Button } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import { XTable } from 'erisxkit/client';
import metadata from '../metadata/coreFilesMetadata';

const CoreFile = ({
  onChange,
  onSubmit,
  coreFileTasks,
  triggerACHTask,
  state,
}) => (
  <Fragment>
    <Header as="h1" dividing>
      Export Core Files
      <Header.Subheader>
        Note: reports may take up to several minutes to export.
      </Header.Subheader>
    </Header>
    <Header as="h2">Core File Export Status</Header>
    <XTable
      title="coreFiles"
      noDataText="No reports exported during this session."
      className="-striped -highlight"
      data={coreFileTasks}
      columns={metadata}
      pageSize={4}
    />
    <Header as="h2">Core File Export Form</Header>
    <Form onSubmit={onSubmit} className="segment">
      <Form.Group>
        <Form.Field>
          <label>Report Date</label>
          <Datetime
            inputProps={{ placeholder: 'YYYY-MM-DD' }}
            className="ui input"
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            closeOnSelect
            name="date"
            onChange={(currentDate) =>
              onChange(null, {
                name: 'date',
                value: Datetime.moment(currentDate._d).format('YYYY-MM-DD'),
              })
            }
            required
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Select
          options={[
            {
              text: 'Balances',
              value: 'balances',
            },
            {
              text: 'Movements',
              value: 'movements',
            },
            {
              text: 'Spot Trades',
              value: 'trades',
            },
            {
              text: 'Futures Trades',
              value: 'futures_trades',
            },
            {
              text: 'Futures Positions',
              value: 'futures_positions',
            },
            {
              text: 'Closeouts',
              value: 'closeouts',
            },
            {
              text: 'Collateral Movements',
              value: 'collateral_movements',
            },
            {
              text: 'Collateral Positions',
              value: 'collateral_positions',
            },
            {
              text: 'Contracts',
              value: 'contracts',
            },
            {
              text: 'Market Data',
              value: 'market_data',
            },
            {
              text: 'Clearing Accounts Static',
              value: 'clearing_acc_static',
            },
            {
              text: 'Accounts Onboarded',
              value: 'accounts_onboarded',
            },
            {
              text: 'All Accounts',
              value: 'all_accounts',
            },
            {
              text: 'Settlements',
              value: 'settlements',
            },
            {
              text: 'Seg/Member Property Report',
              value: 'seg_report',
            },
            {
              text: 'Block Trades',
              value: 'block_trades',
            },
            {
              text: 'Hub Spot',
              value: 'hub_spot',
            },
            {
              text: 'Member Users',
              value: 'member_users',
            },
            {
              text: 'TCS Admin Users',
              value: 'tcs_admin_users',
            },
            {
              text: 'Direct Member',
              value: 'direct_member',
            },
            {
              text: 'Settlement Service',
              value: 'settlement_service',
            },
            {
              text: 'Members',
              value: 'members',
            },
            {
              text: 'DFRR/Guaranty Fund/Cover 2',
              value: 'dfrr',
            },
          ]}
          name="report"
          label="Report Type"
          placeholder="Type of report to export"
          search
          onChange={onChange}
          required
        />
      </Form.Group>
      <Form.Button type="submit" disabled={!state?.date || !state?.report} content="Export Core File" />
    </Form>

    <Header as="h2">ACH Transaction Core File</Header>
    <Form className="segment margin-bottom-140">
      <Form.Group>
        <Form.Field>
          <label>Begin Date</label>
          <Datetime
            inputProps={{ placeholder: 'YYYY-MM-DD' }}
            className="ui input"
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            closeOnSelect
            name="beginDate"
            onChange={(currentDate) =>
              onChange(null, {
                name: 'beginDate',
                value: Datetime.moment(currentDate._d).format('YYYY-MM-DD'),
              })
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field>
          <label>End Date</label>
          <Datetime
            inputProps={{ placeholder: 'YYYY-MM-DD' }}
            className="ui input"
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            closeOnSelect
            name="endDate"
            onChange={(currentDate) =>
              onChange(null, {
                name: 'endDate',
                value: Datetime.moment(currentDate._d).format('YYYY-MM-DD'),
              })
            }
          />
        </Form.Field>
      </Form.Group>
      <Button
        disabled={!state?.beginDate || !state?.endDate}
        className="add-button"
        type="button"
        content="Export Core File"
        onClick={triggerACHTask}
      />
    </Form>
  </Fragment>
);

CoreFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  coreFileTasks: PropTypes.arrayOf(
    PropTypes.shape({
      taskId: PropTypes.string,
      state: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
};

export default CoreFile;
