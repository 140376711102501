import React from 'react';
import { useDispatch } from 'react-redux';
import { formatTimeLocalWithDoW } from '../utils/time';
import { DayColumnFilter, AccountColumnFilter } from '../common/table/Filters';
import styled from 'styled-components';
import colors from '../constants/colors';
import { Button } from 'semantic-ui-react';
import { showModal, hideModal } from 'erisxkit/client';
import { DELIVERY } from '../constants/modalTypes';

const Long = styled.label`
  background-color: ${colors.accent};
  padding: 5px;
`;

const Short = styled.label`
  background-color: ${colors.error};
  padding: 5px;
`;

const Satisfied = styled.label`
  background-color: ${colors.accent};
  padding: 5px;
`;

const NotSatisfied = styled.label`
  background-color: ${colors.error};
  padding: 5px;
`;

const DeliveryTypes = {
  MarginContinuous: 'margin_continuous',
};

export const marginDeliveryMetadata = (onShowModal, onHideModal) => {
  const dispatch = useDispatch();

  return [
    {
      Header: ' ',
      hideFilter: true,
      columns: [
        {
          // Build our expander column
          id: 'expander', // Make sure it has an ID
          Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}></span>
          ),
          hideFilter: true,
          Cell: ({ row }) => {
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            return row.canExpand ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 2}rem`,
                    color: row.depth ? 'SlateGrey' : 'black',
                  },
                  className: 'expandable',
                })}
              >
                {row.isExpanded ? '▼' : '►'}
              </span>
            ) : null;
          },
        },
      ],
    },
    {
      Header: ' ',
      hideFilter: true,
      columns: [
        {
          Header: 'Product Code',
          accessor: 'productSymbol',
          id: 'product_symbol',
        },
        {
          Header: 'Contract Code',
          accessor: 'contractSymbol',
          id: 'contract_symbol',
        },
        {
          Header: 'Maturity Date',
          id: 'maturity_date',
          Filter: DayColumnFilter,
          accessor: (row) => formatTimeLocalWithDoW(row.maturityDate, true),
          hideFilter: true,
        },
        {
          Header: 'Delivery Type',
          accessor: 'deliveryType',
          id: 'delivery_type',
          hideFilter: true,
        },
        {
          Header: 'L/S',
          Cell: ({ row }) =>
            row?.original?.ls === 'long' || row?.original?.ls === 'short' ? (
              row?.original?.ls === 'long' ? (
                <Long>{row?.original?.ls}</Long>
              ) : (
                <Short>{row?.original?.ls}</Short>
              )
            ) : null,
          hideFilter: true,
        },
        {
          Header: 'Account',
          accessor: 'account',
          id: 'account_id',
          Filter: AccountColumnFilter,
        },
        {
          Header: 'CGM',
          accessor: 'cgm',
          id: 'cgm_number',
        },
        {
          Header: 'Delivery Acct',
          accessor: 'deliveryAcct',
          hideFilter: true,
        },
        {
          Header: 'Quantity',
          accessor: 'qty',
          hideFilter: true,
        },
        {
          Header: 'Price',
          accessor: 'price',
          hideFilter: true,
        },
        {
          Header: 'DelAssetPrice',
          accessor: 'deliveryAsset',
          hideFilter: true,
        },
        {
          Header: 'DelReq',
          accessor: 'deliveryRequirement',
          hideFilter: true,
        },
        {
          Header: 'DelAmt',
          accessor: 'deliveryAmount',
          hideFilter: true,
        },
        {
          Header: 'delBal',
          accessor: 'deliveryBalance',
          hideFilter: true,
        },
        {
          Header: 'Satisfied',
          accessor: (row) =>
            row?.ls &&
            (row.satisfied ? (
              <Satisfied>Y</Satisfied>
            ) : (
              <NotSatisfied>N</NotSatisfied>
            )),
          hideFilter: true,
        },
        {
          Header: ' ',
          accessor: (row) =>
            !row.ls &&
            row.deliveryType !== DeliveryTypes.MarginContinuous && (
              <Button
                size="mini"
                content="Delivery"
                onClick={() =>
                  dispatch(
                    showModal(DELIVERY, {
                      contractCode: row.contractCode,
                      onConfirm: () => dispatch(hideModal()),
                      productCode: row.productSymbol,
                    }),
                  )
                }
              />
            ),
          hideFilter: true,
        },
      ],
    },
  ];
};

export const futuresPositionMarginDeliveryMetadata = () => [
  {
    Header: ' ',
    hideFilter: true,
    columns: [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}></span>
        ),
        hideFilter: true,
        Cell: ({ row }) => {
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          return row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 2}rem`,
                  color: row.depth ? 'SlateGrey' : 'black',
                },
                className: 'expandable',
              })}
            >
              {row.isExpanded ? '▼' : '►'}
            </span>
          ) : null;
        },
      },
    ],
  },
  {
    Header: ' ',
    hideFilter: true,
    columns: [
      {
        Header: 'Product Code',
        accessor: 'productSymbol',
        id: 'product_symbol',
        hideFilter: true,
      },
      {
        Header: 'Contract Code',
        accessor: 'contractSymbol',
        id: 'contract_symbol',
        hideFilter: true,
      },
      {
        Header: 'Account',
        accessor: 'account',
        id: 'account_id',
        hideFilter: true,
      },
      {
        Header: 'CGM',
        accessor: 'cgm',
        id: 'cgm_number',
        hideFilter: true,
      },
      {
        Header: 'Maturity Date',
        id: 'maturity_date',
        accessor: (row) => formatTimeLocalWithDoW(row.maturityDate, true),
        hideFilter: true,
      },
      {
        Header: 'Total Long',
        accessor: 'qtyLong',
        hideFilter: true,
      },
      {
        Header: 'Total Short',
        accessor: 'qtyShort',
        hideFilter: true,
      },
    ],
  },
];
