import React, { Fragment, PureComponent } from 'react';
import {
  renderField,
  renderDropdown,
  rules,
  normalization,
  fundsDesignation,
} from 'erisxkit/client';
import { Field, reduxForm } from 'redux-form';
import { Form, Segment, Header, List, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

const contractFields = [
  {
    label: 'Product Code',
    accessor: 'productSymbol',
  },
  {
    label: 'Contract Code',
    accessor: 'symbol',
  },
  {
    label: 'Maturity Date',
    accessor: 'expiryDate',
  },
  {
    label: 'Coupon',
    accessor: 'coupon',
  },
];

class AddCollateral extends PureComponent {
  state = {
    selectedContract: {},
  };

  renderContractDetails = (fields) =>
    fields.map((field) => (
      <List.Item as="li">
        <span className="bold">{field.label}: </span>
        {_.get(
          this.state,
          ['selectedContract', field.accessor],
          <span className="grey">&mdash;</span>,
        )}
      </List.Item>
    ));

  // takes input and uses it to filter collateral contracts
  handleContractChange = (e, value) => {
    const selectedContract = _.chain(this.props)
      .get('collateralContractsList', [])
      .find((c) => c.symbol === value)
      .value();
    this.setState({ selectedContract });
  };

  render = () => {
    const {
      collateralContractsList,
      collateralContractsLoading,
      collateralContractsOptions,
      createCollateralDepositJournal,
      custodianAccountOptions,
      handleSubmit,
      hideModal,
      selectCollateralContract,
      valid,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(createCollateralDepositJournal)}>
        <Form.Group>
          <Field
            clearable
            component={renderDropdown}
            id="contractSymbol"
            label={
              <Fragment>
                <label htmlFor="symbol">Contract Code</label>
                <p className="italic">Select a contract by name or CUSIP.</p>
              </Fragment>
            }
            loading={collateralContractsLoading}
            name="contractSymbol"
            noResultsMessage="No contracts found."
            onChange={this.handleContractChange}
            options={collateralContractsOptions}
            placeholder="Select a collateral contract. Its details will be shown below."
            search
          />
        </Form.Group>
        {!_.isEmpty(this.state.selectedContract) ? (
          <Segment id="contract-details">
            <Header>Collateral Contract Details</Header>
            <List as="ul">{this.renderContractDetails(contractFields)}</List>
          </Segment>
        ) : (
          <Segment placeholder compact>
            <span className="italic grey">
              Contract details will appear here.
            </span>
          </Segment>
        )}
        <Divider horizontal>
          <Header as="h3">Deposit Details</Header>
        </Divider>
        <Segment>
          <Field
            component={AccountSelectionContainer}
            id="accountId"
            label="Account"
            name="accountId"
            required
            validate={[rules.required]}
          />
          <Field
            component={renderDropdown}
            id="custodianAccountId"
            label="Custodian Account"
            name="custodianAccountId"
            options={custodianAccountOptions}
            placeholder="Select a custodian account..."
            required
            validate={[rules.required]}
            search
          />
          <Field
            component={renderField}
            id="qty"
            label="Quantity"
            name="qty"
            parse={normalization.floatsOnly}
            placeholder="0.000"
            required
            validate={[rules.required]}
          />
          <Field
            component={renderDropdown}
            id="fundsDesignation"
            label="Funds Designation"
            name="fundsDesignation"
            options={fundsDesignation}
            placeholder="Select a funds designation."
            required
            validate={[rules.required]}
          />
        </Segment>
        <Form.Button disabled={!valid} primary>
          Add Collateral to Account
        </Form.Button>
      </Form>
    );
  };
}

AddCollateral.propTypes = {
  collateralContractsLoading: PropTypes.bool,
  collateralContractsOptions: PropTypes.arrayOf(PropTypes.object),
  createCollateralDepositJournal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  valid: PropTypes.bool,
};

AddCollateral.defaultProps = {
  collateralContractsLoading: false,
  collateralContractsOptions: [],
  collateralProductsLoading: false,
  collateralProductsOptions: [],
  valid: false,
};

export default reduxForm({
  form: 'AddCollateral',
})(AddCollateral);
