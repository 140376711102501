import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import {
  createCollateralContract,
  createCollateralDepositJournal,
  collateralContracts,
  collateralProducts,
  getCollateralContracts,
  getCollateralContractsAsSearchField,
  getCollateralProductsAsOptions,
} from '../../reducers/collateralReducer';
import { showModal, hideModal } from 'erisxkit/client';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../../constants/modalTypes';
import AddCollateral from '../../components/ManualEntries/AddCollateral';
import { getCustodianAccountsAsOptions } from '../../reducers/accountsReducer';
import { CUSTODIAN } from '../../constants/accountTypes';
import { fetchAccounts } from '../../actions/accountsActions';

const mapStateToProps = (state) => ({
  collateralContractsLoading: createLoadingSelector(['COLLATERAL_CONTRACTS'])(
    state,
  ),
  collateralContractsOptions: getCollateralContractsAsSearchField(state),
  collateralContractsList: getCollateralContracts(state),
  collateralProductsLoading: createLoadingSelector(['COLLATERAL_PRODUCTS'])(
    state,
  ),
  collateralProductOptions: getCollateralProductsAsOptions(state),
  custodianAccountOptions: getCustodianAccountsAsOptions(state),
});

const mapDispatchToProps = {
  createCollateralContract,
  createCollateralDepositJournal,
  collateralContracts,
  collateralProducts,
  fetchAccounts,
  hideModal,
  showModal,
};

class AddCollateralContainer extends PureComponent {
  componentWillMount = () => {
    this.props.fetchAccounts({ categories: [CUSTODIAN] });
    this.props.collateralContracts();
  };

  createCollateralDepositJournal = (payload = {}) => {
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      header: 'Confirm Collateral Deposit',
      msg: `Add collateral to account ${payload.accountId}?`,
      hideModal: this.props.hideModal,
      closeOnDimmerClick: true,
      closeOnEscape: true,
      onConfirm: () => {
        this.props.createCollateralDepositJournal(payload);
        this.props.hideModal(CONFIRM_ACTION_WITH_PAYLOAD);
      },
      payload,
      action: 'Add Collateral',
    });
  };

  render = () => (
    <Fragment>
      <Header as="h1" dividing>
        Add Collateral
      </Header>
      <AddCollateral {...this.props} />
    </Fragment>
  );
}

AddCollateralContainer.propTypes = {
  accountId: PropTypes.string,
  collateralContracts: PropTypes.func.isRequired,
  collateralContractsLoading: PropTypes.bool,
  collateralContractsOptions: PropTypes.arrayOf(PropTypes.object),
  createCollateralDepositJournal: PropTypes.func.isRequired,
  custodianAccountOptions: PropTypes.arrayOf(PropTypes.object),
  fetchAccounts: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

AddCollateralContainer.defaultProps = {
  accountId: undefined,
  collateralContractsLoading: false,
  collateralContractsOptions: [],
  custodianAccountOptions: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCollateralContainer);
