import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { showModal, hideModal } from 'erisxkit/client';
import {
  Card,
  Header,
  Segment,
  Button,
  Table,
  Message,
  Icon,
} from 'semantic-ui-react';
import DetailsHeader from '../../common/components/DetailsHeader';
import {
  fetchMemberImages,
  fetchPii,
  getMemberImages,
  getSelectedUser,
  getSelectedUserId,
} from '../../reducers/usersReducer';
import {
  fetchApprovalReqs,
  selectApproval,
} from '../../actions/approvalReqsActions';
import ComplianceQuestionsContainer from './ComplianceQuestionsContainer';
import SupportingDocumentContainer from './SupportingDocumentContainer';
import CopyInput from '../../common/components/CopyInput';
import { formatTimeLocal } from '../../utils/time';
import BackButton from '../../common/components/BackButton';
import {
  APPROVE_REJECT,
  VIEW_PHOTO,
  CONFIRM_ACTION_WITH_PAYLOAD,
} from '../../constants/modalTypes';
import { getApprovalReqsList } from '../../reducers/approvalReqsReducer';
import { sendEmail } from '../../reducers/manualOnboardingReducer';
import NotesContainer from './NotesContainer';

const userImages = {
  idFrontImage: 'ID Front Image',
  idBackImage: 'ID Back Image',
  faceImage: 'Face Image',
};

const APPROVAL_STATES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const formatAddress = ({
  address1,
  address2,
  city,
  locationState,
  postalCode,
  country,
}) => {
  if (address1 || address2 || city || locationState || postalCode || country) {
    return `${address1 || ''} ${address2 || ''} ${city || ''}, ${locationState || ''} ${postalCode || ''} ${
      country || ''
    }`;
  }
  return <i>No address provided.</i>;
};

const headerRow = [
  'Date',
  'Status',
  'Description',
  'Approvals',
  'Rejections',
  'Action',
];

export default () => {
  const dispatch = useDispatch();
  const [showSsn, setShowSsn] = useState(false);
  const selectedUser = useSelector(getSelectedUser) || {};
  const selectedUserId = useSelector(getSelectedUserId) || '';
  const images = useSelector(getMemberImages) || {};
  const approvalReqs = useSelector(getApprovalReqsList()) || [];

  useEffect(() => {
    if (selectedUserId) {
      dispatch(fetchPii({ userId: selectedUserId }));
      dispatch(
        fetchApprovalReqs({
          filter: [{ attr: 'initiator_id', op: 'eq', value: selectedUserId }],
        }),
      );
      dispatch(fetchMemberImages(selectedUserId));
    }
  }, [selectedUserId]);
  const { pii = {} } = selectedUser;

  const renderApproveRejectCell = (values) =>
    values ? (
      <>
        {values.email} <i>({formatTimeLocal(values.time)})</i>
      </>
    ) : (
      '-'
    );
  const renderBodyRow = (
    { time, state, description, uuid, approvals, rejections, ...rest },
    i,
  ) => ({
    key: `row-${i}`,
    warning: !!(state && state.match(APPROVAL_STATES.PENDING)),
    positive: !!(state && state.match(APPROVAL_STATES.APPROVED)),
    negative: !!(state && state.match(APPROVAL_STATES.REJECTED)),
    cells: [
      formatTimeLocal(time) || '',
      state || '',
      description,
      approvals.map((approval) => renderApproveRejectCell(approval)),
      rejections.map((rejection) => renderApproveRejectCell(rejection)),
      <td>
        <Button
          primary
          content={
            state === APPROVAL_STATES.PENDING
              ? 'Approve/Reject'
              : 'Show Details'
          }
          onClick={() => {
            dispatch(selectApproval(uuid));
            dispatch(
              showModal(APPROVE_REJECT, {
                selectedApprovalReq: {
                  time,
                  state,
                  description,
                  uuid,
                  ...rest,
                },
              }),
            );
          }}
        />
      </td>,
    ],
  });

  const item = [
    {
      firstName: pii.firstName,
      middleName: pii.middleName,
      lastName: pii.lastName,
      address1: pii.address1,
      address2: pii.address2,
      city: pii.city,
      state: pii.locationState,
      zipCode: pii.postalCode,
      country: pii.country,
    },
    {
      phoneNumber: pii.phoneNumber || <i>No phone number provided.</i>,
      dateOfBirth: pii.dob || <i>No date of birth provided.</i>,
      socialSecurityNumber: (
        <div>
          {showSsn ? pii.ssn : '*********'}
          <Icon
            name={showSsn ? 'eye slash' : 'eye'}
            link
            style={{ paddingLeft: '10px' }}
            onClick={() => setShowSsn(!showSsn)}
          />
        </div>
      ),
      createdAt: formatTimeLocal(selectedUser.createdAt),
      lastUpdated: formatTimeLocal(pii.updatedAt),
      memberUserId: (
        <CopyInput id="memberId" className="compact" value={selectedUserId} />
      ),
    },
  ];

  const confirmAction = () => {
    dispatch(
      showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
        closeOnEscape: true,
        header: 'Send Email',
        msg: `Are you sure that you want to send an email to: ${selectedUser.email}?`,
        hideModal: () => dispatch(hideModal()),
        onConfirm: () => {
          dispatch(
            sendEmail({
              emailId: 'address_verification',
              recipient: selectedUser.email,
            }),
          );
          dispatch(hideModal());
        },
      }),
    );
  };

  return (
    <Fragment>
      <BackButton
        headerLabels={[
          selectedUser.state && {
            content: startCase(selectedUser.state),
            size: 'small',
            tag: true,
          },
          selectedUser.appState && {
            content: startCase(selectedUser.appState),
            size: 'small',
            tag: true,
          },
        ]}
        headerActions={[
          selectedUser.email && {
            name: 'settings',
            size: 'small',
            title: 'Member User Settings',
            link: true,
            onClick: () =>
              window.open(`/member_users/${selectedUser.userId}#settings`),
          },
        ]}
        header={selectedUser.email}
      />
      {approvalReqs
        .filter((a) => a.state === 'pending')
        .map((a) => (
          <Message content={a.description} negative />
        ))}
      <DetailsHeader item={item} />
      <Fragment>
        <Header content={`ID Type: ${pii.idType || 'No Id Type Defined'}`} />
        <Card.Group>
          {Object.keys(images).map(
            (i) =>
              userImages[i] && (
                <Card
                  style={{ width: '30%' }}
                  onClick={() =>
                    dispatch(
                      showModal(VIEW_PHOTO, {
                        closeOnDimmerClick: true,
                        pii,
                        type: userImages[i],
                        image: images[i].image,
                        createdAt: formatTimeLocal(images[i].createdAt),
                        hideModal: () => {
                          dispatch(hideModal());
                        },
                      }),
                    )
                  }
                >
                  <Card.Content>
                    <Card.Header>{userImages[i]}</Card.Header>
                    <Card.Meta>
                      Uploaded: {formatTimeLocal(images[i].createdAt)}
                    </Card.Meta>
                    <img
                      className="full-width"
                      src={`data:${images[i].image}`}
                      alt={i}
                    />
                  </Card.Content>
                </Card>
              ),
          )}
        </Card.Group>
        <Card className="full-width">
          <Card.Content>
            <Button floated="right" onClick={() => confirmAction()}>
              <Icon name="mail" /> Request Address Verification
            </Button>
            <Card.Header>{`Supporting Document - Address entered: ${formatAddress(pii)}`}</Card.Header>
            <SupportingDocumentContainer userId={selectedUserId} />
          </Card.Content>
        </Card>
        <Segment id="compliance-questions">
          <Header>Compliance Questions</Header>
          <ComplianceQuestionsContainer userId={selectedUserId} />
        </Segment>
        <Segment>
          <Header>Notes</Header>
          <NotesContainer selectedUser={{ userId: selectedUserId }} />
        </Segment>
        <Segment>
          <Header>Approval(s)</Header>
          <Table
            celled
            headerRow={headerRow}
            renderBodyRow={renderBodyRow}
            tableData={approvalReqs}
          />
        </Segment>
      </Fragment>
    </Fragment>
  );
};
