import React from 'react';
import { XTable } from 'erisxkit/client';

const ExternalTable = ({
  data,
  metadata,
  onFetchData,
  count,
  loading,
  noDataText,
  ...rest
}) => (
  <XTable
    loading={loading}
    sortable={false}
    minRows={5}
    noDataText={noDataText}
    className="-striped -highlight"
    columns={metadata}
    data={data}
    onFetchData={onFetchData}
    manual
    pages={Math.ceil(count / 20)}
    showPageSizeOptions={false}
    defaultPageSize={20}
    {...rest}
  />
);
export default ExternalTable;
