import React from 'react';
import { Form, Select, Input } from 'semantic-ui-react';
import { filter, debounce } from 'lodash';
import { formatDecimals } from '../common/forms/normalization';
import {
  calculateFinalPositionTransfer,
  roundToNearestInteger,
} from '../utils/methods';

const positionTransferMetadata = (
  allFirms,
  allAccounts,
  positionsTransfersType,
  state,
  change,
  toFirmSelected,
  accountsLoading,
  changeMultiple,
) => {
  const changeInputData = (name, current, pending, transfer, row) => {
    const calculatedResponse = isNaN(Number(current) - Number(pending))
      ? '0'
      : Number(current) - Number(pending);
    if (Number(calculatedResponse) - Number(transfer) >= 0) {
      change(row, transfer, name);
    } else {
      change(row, calculatedResponse, name);
    }
  };

  return [
    {
      id: 'main',
      Header: '',
      columns: [
        {
          Header: 'Firm',
          id: 'firm',
          width: 240,
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                control={Select}
                search
                selection
                options={allFirms.map((o) => ({
                  key: o.key,
                  text: o.text,
                  value: o.value,
                }))}
                onChange={(e, { value }) => {
                  change(row, row.original.contractSymbol, 'contractSymbol');
                  change(row, row.original.productSymbol, 'productSymbol');
                  change(row, value, 'firmSelected');
                  toFirmSelected(value);
                }}
                selectOnBlur
                clearable
                disabled={!row.isSelected}
                defaultValue={state[row.id]?.firmSelected}
              />
            </span>
          ),
        },
        {
          Header: 'Account',
          accessor: 'account',
          width: 240,
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                control={Select}
                search
                selection
                options={filter(allAccounts, {
                  memberId: state[row.id]?.firmSelected,
                }).map((o) => ({
                  key: o.label,
                  text: o.label,
                  value: o.label,
                }))}
                onChange={(e, { value }) => {
                  change(row, value, 'accountSelected');
                }}
                selectOnBlur={false}
                clearable
                disabled={!row.isSelected || accountsLoading}
                defaultValue={state[row.id]?.accountSelected}
              />
            </span>
          ),
        },
        {
          Header: 'CGM',
          accessor: 'cgm',
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                labelPosition="left"
                control={Input}
                style={{ width: '100px' }}
                onChange={debounce((e, { value }) => {
                  change(row, value, 'cgm');
                }, 500)}
                required
                disabled={!row.isSelected}
                defaultValue={state[row.id]?.cgm}
              />
            </span>
          ),
          width: 120,
        },
        {
          Header: 'Transfer Type',
          accessor: 'transferType',
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                control={Select}
                search
                selection
                options={positionsTransfersType.map((o) => ({
                  key: o.type,
                  text: o.type,
                  value: o.type,
                }))}
                onChange={(e, { value }) => {
                  if (value !== 'adp' && value !== 'default_auction') {
                    changeMultiple(row, [
                      ['price', undefined],
                      ['transferTypeSelected', value],
                    ]);
                  } else {
                    change(row, value, 'transferTypeSelected');
                  }
                }}
                selectOnBlur={false}
                clearable
                disabled={!row.isSelected}
                defaultValue={state[row.id]?.transferTypeSelected}
              />
            </span>
          ),
          width: 220,
        },
        {
          Header: 'Price',
          accessor: 'price',
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                labelPosition="left"
                control={Input}
                parse={formatDecimals}
                style={{ width: '100px' }}
                onBlur={(e) => {
                  const numericValue = Number(e.target.value);
                  const value = isNaN(numericValue) ? 0 : numericValue;
                  change(row, value, 'price');
                }}
                disabled={
                  !row.isSelected ||
                  !(
                    state[row.id]?.transferTypeSelected === 'adp' ||
                    state[row.id]?.transferTypeSelected === 'default_auction'
                  )
                }
                defaultValue={state[row.id]?.price}
              />
            </span>
          ),
          width: 120,
        },
        {
          Header: 'Product',
          accessor: 'productSymbol',
          width: 160,
        },
        {
          Header: 'Contract',
          accessor: 'contractSymbol',
          width: 160,
        },
      ],
    },
    {
      id: 'current',
      Header: 'Current',
      columns: [
        {
          Header: 'Long',
          accessor: 'currentLongQty',
          width: 90,
        },
        {
          Header: 'Short',
          accessor: 'currentShortQty',
          width: 90,
        },
      ],
    },
    {
      id: 'pending',
      Header: 'Pending',
      columns: [
        {
          Header: 'Long',
          accessor: 'pendingLongQty',
          width: 90,
        },
        {
          Header: 'Short',
          accessor: 'pendingShortQty',
          width: 90,
        },
      ],
    },
    {
      id: 'transfer',
      Header: 'Transfer',
      columns: [
        {
          Header: 'Long',
          accessor: 'longTransfer',
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                labelPosition="left"
                control={Input}
                parse={formatDecimals}
                style={{ width: '100px' }}
                required
                defaultValue={state[row.id]?.longTransfer}
                onBlur={(e) => {
                  const valueFormatted = roundToNearestInteger(e.target.value);
                  changeInputData(
                    'longTransfer',
                    row.original.currentLongQty,
                    row.original.pendingLongQty,
                    valueFormatted,
                    row,
                  );
                }}
                disabled={!row.isSelected}
              />
            </span>
          ),
          width: 120,
        },
        {
          Header: 'Short',
          accessor: 'shortTransfer',
          Cell: ({ row }) => (
            <span className="full-width">
              <Form.Field
                labelPosition="left"
                control={Input}
                parse={formatDecimals}
                style={{ width: '100px' }}
                onChange={debounce((e, { value }) => {
                  const valueFormatted = roundToNearestInteger(value);
                  changeInputData(
                    'shortTransfer',
                    row.original.currentShortQty,
                    row.original.pendingShortQty,
                    valueFormatted,
                    row,
                  );
                }, 500)}
                required
                defaultValue={state[row.id]?.shortTransfer}
                disabled={!row.isSelected}
              />
            </span>
          ),
          width: 120,
        },
      ],
    },
    {
      id: 'final',
      Header: 'Final',
      columns: [
        {
          Header: 'Long',
          accessor: 'longFinal',
          Cell: ({ row }) => (
            <span className="full-width">
              {calculateFinalPositionTransfer(
                row.original.currentLongQty,
                row.original.pendingLongQty,
                state[row.id]?.longTransfer,
              )}
            </span>
          ),
          width: 100,
        },
        {
          Header: 'Short',
          accessor: 'shortFinal',
          Cell: ({ row }) => (
            <span className="full-width">
              {calculateFinalPositionTransfer(
                row.original.currentShortQty,
                row.original.pendingShortQty,
                state[row.id]?.shortTransfer,
              )}
            </span>
          ),
          width: 100,
        },
      ],
    },
  ];
};

export default positionTransferMetadata;
