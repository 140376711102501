import React, { Component, Fragment } from 'react';
import { get } from 'lodash';
import { Tab, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import history from '../../constants/history';
import { getCurrentUser } from '../../reducers/usersReducer';
import { isAuthorized, isLedgerSensitive } from '../../utils/methods';
import { getActiveLedgerId } from '../../reducers/ledgersReducer';

const showTabs = (panes, authorize, user, activeLedgerId) => {
  const filteredPanes = panes.filter((pane) =>
    pane.hasOwnProperty('show') ? pane.show : true,
  );
  const authorizedPanes = authorize
    ? filteredPanes.filter(
        (pane) =>
          isAuthorized(user.uiViews, pane.url) &&
          isLedgerSensitive(activeLedgerId, pane.url),
      )
    : filteredPanes;
  let activeIndex = authorizedPanes.findIndex((pane) =>
    window.location.href.includes(pane.url),
  );
  if (activeIndex === -1) {
    activeIndex = 0;
  }
  return { authorizedPanes, activeIndex };
};

class XTab extends Component {
  state = {
    activeIndex: 0,
    authorizedPanes: [],
  };
  componentDidMount = () => {
    const { panes, authorize, user, activeLedgerId } = this.props;
    const tabsInfo = showTabs(panes, authorize, user, activeLedgerId) || {};
    this.setState(
      tabsInfo,
      history.replace({
        ...this.props.history.location,
        hash: get(tabsInfo.authorizedPanes[tabsInfo.activeIndex], 'url', ''),
      }),
    );
  };

  onTabChange = (callback) => (e, data) => {
    history.replace({
      hash: data.panes[data.activeIndex].url,
    });

    this.setState({
      activeIndex: data.activeIndex,
    });

    if (typeof callback === 'function') {
      callback(e, data);
    }
  };

  render = () => {
    const { panes, callback, loading, authorize, dispatch, user, ...rest } =
      this.props;
    return (
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <Tab
            menu={{ pointing: true }}
            activeIndex={this.state.activeIndex}
            onTabChange={this.onTabChange(callback)}
            panes={this.state.authorizedPanes}
            {...rest}
          />
        )}
      </Fragment>
    );
  };
}

export default withRouter(
  connect((state) => ({
    user: getCurrentUser(state),
    activeLedgerId: getActiveLedgerId(state),
  }))(XTab),
);
