import React, { useState } from 'react';
import {
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  getItemsCount,
  getItemsPaged,
  createLoadingSelector,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import { useSelector, useDispatch } from 'react-redux';
import XTable7 from '../../common/table/XTable7';
import onboardingWorkflowMetadata from '../../metadata/onboardingWorkflowMetadata';
import {
  fetchUsers,
  selectUser,
  updateUser,
} from '../../reducers/usersReducer';
import { fetchEmployeesPromiseCreator } from '../../reducers/employeesReducer';
import history from '../../constants/history';
import {
  PENDING_MARC_REVIEW,
  PENDING_PII_REVIEW,
  FUTURES_UPGRADE_PENDING_REVIEW,
} from '../../constants/userStates';
import _ from 'lodash';
import { employee } from '../../constants/userTypes';
import { employeeToOptions } from '../../utils/methods';

export const APPROVAL_REQUIRED_STATES = [
  PENDING_MARC_REVIEW,
  PENDING_PII_REVIEW,
  FUTURES_UPGRADE_PENDING_REVIEW,
];

export const UNASSIGNED_EMPLOYEE_USER_ID = '0';

const SECONDS_TO_NANO = '000000000';

const getFilterOp = (columnId) => {
  return columnId === 'assigned_employee_user_id' ? 'eq' : DEFAULT_FILTER_OP;
};

const OnboardingWorkflowContainer = () => {
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState();
  const count = useSelector(getItemsCount('users'));
  const usersList = useSelector(getItemsPaged('users'));
  const loading = useSelector(createLoadingSelector(['USERS']));

  const onClick = (user) => {
    dispatch(selectUser(user.userId));
    history.push(`/onboarding/${user.userId}/`);
  };

  React.useEffect(async () => {
    //Get Users binded with its selector
    fetchData({ pageSize: 100, pageIndex: 0, filters: [], sortBy: [] });

    let args = {
      filter: [
        { attr: 'type', op: 'eq', value: employee },
        { attr: 'disabled', op: 'eq', value: 'false' },
      ],
    };
    args.page = 0;

    //Get the employees, just the first time, this one is not binded to a selector
    fetchEmployeesPromiseCreator(args, dispatch).then((filteredEmployees) => {
      const options = employeeToOptions(filteredEmployees.users);
      options.unshift({
        key: UNASSIGNED_EMPLOYEE_USER_ID,
        value: UNASSIGNED_EMPLOYEE_USER_ID,
        text: 'Unassigned',
        description: 'Unassigned',
      });
      setEmployees(options);
    });
  }, []);

  const handleAssignedEmployeeUserChange = (row, obj) => {
    const args = {
      userId: row.userId,
      update: {
        assignedEmployeeUserId: obj.value,
      },
    };
    //When updates an user, the selector will trigger and refresh the table.
    dispatch(updateUser(args));
  };

  const fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    let filter = [
      {
        attr: 'state',
        op: 'match',
        value: APPROVAL_REQUIRED_STATES,
      },
      {
        attr: 'disabled',
        op: 'eq',
        value: false,
      },
    ];
    // default sort by oldest applications first
    let sort = [
      {
        attr: 'updated_at',
        value: 'desc',
      },
    ];

    if (filters.length !== 0) {
      const filtersWithNames = filters.filter((x) => x.id !== 'member_name');

      const nameFilter = filters.find((x) => x.id === 'member_name');
      if (nameFilter) {
        if (nameFilter?.value.firstName !== '') {
          filtersWithNames.push({
            id: 'first_name',
            value: nameFilter.value.firstName,
          });
        }
        if (nameFilter?.value.middleName !== '') {
          filtersWithNames.push({
            id: 'middle_name',
            value: nameFilter.value.middleName,
          });
        }
        if (nameFilter?.value.lastName !== '') {
          filtersWithNames.push({
            id: 'last_name',
            value: nameFilter.value.lastName,
          });
        }
      }
      filter = filter.concat(
        filteredArrayToAttrValue(
          filtersWithNames.map((each) => ({
            ...each,
            op: getFilterOp(each.id),
          })),
        ),
      );
    }

    const filterWithMaxMin = filter.reduce((acc, curr) => {
      if (_.isArray(curr.value)) {
        return acc.concat([
          ...(curr.value[2]
            ? [
                {
                  ...curr,
                  op: 'gte',
                  value: `${curr.value[2]}${SECONDS_TO_NANO}`,
                },
              ]
            : []),
          ...(curr.value[3]
            ? [
                {
                  ...curr,
                  op: 'lte',
                  value: `${curr.value[3]}${SECONDS_TO_NANO}`,
                },
              ]
            : []),
        ]);
      }
      return acc.concat([curr]);
    }, []);

    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }

    dispatch(
      fetchUsers({
        limit: pageSize,
        offset: pageIndex * pageSize,
        page: pageIndex,
        filter,
        includeName: true,
        sort,
      }),
    );
  };

  return (
    <div>
      {employees ? (
        <XTable7
          title="onboarding-workflow"
          metadata={onboardingWorkflowMetadata({
            onClick,
            handleAssignedEmployeeUserChange,
            options: employees,
          })}
          data={usersList}
          fetchData={fetchData}
          loading={loading}
          count={count}
          pageTableSize={100}
        />
      ) : (
        <XTable7
          title="onboarding-workflow"
          metadata={onboardingWorkflowMetadata({
            onClick,
            handleAssignedEmployeeUserChange,
            options: [],
          })}
          data={usersList}
          fetchData={fetchData}
          loading={loading}
          count={count}
          pageTableSize={100}
        />
      )}
    </div>
  );
};

export default OnboardingWorkflowContainer;
