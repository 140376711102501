import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Label } from 'semantic-ui-react';
import moment from 'moment';
import {
  createLoadingSelector,
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import DetailsHeader from '../../common/components/DetailsHeader';
import XTable7 from '../../common/table/XTable7';
import marginCallMetadata from '../../metadata/marginCallMetadata';
import {
  fetchMarginCallAlerts,
  getMarginCallAlerts,
  getMarginCallAlertsCount,
  getMpFundsOwed,
  getSegFundsOwed,
  getTotalUsdOwed,
  updateMarginRequirementStatus,
  MARGIN_CALL_ALERTS,
} from '../../reducers/riskReducer';
import {
  getRiskAlertStatusTextByStatus,
  getRiskAlertStatusTextByStatusText,
  getRiskAlertStatusTextByStatusValue,
} from '../../utils/methods';
import { showModal, hideModal } from 'erisxkit/client';
import { UPDATE_RISK_ALERT_STATUS } from '../../constants/modalTypes';

const getFilterOp = (columns, id) => {
  const column = columns.find((c) => c.id === id);
  return column.filterOp || DEFAULT_FILTER_OP;
};

export default () => {
  const dispatch = useDispatch();
  const marginCallAlerts = useSelector(getMarginCallAlerts);
  const segFundsOwed = useSelector(getSegFundsOwed);
  const mpFundsOwed = useSelector(getMpFundsOwed);
  const totalUsdOwed = useSelector(getTotalUsdOwed);

  const count = useSelector(getMarginCallAlertsCount);
  const loading = useSelector(createLoadingSelector([MARGIN_CALL_ALERTS]));

  const handleRiskStatusChange = (e, prop) => {
    const previousValue = getRiskAlertStatusTextByStatusValue(
      prop.previousValue,
    );
    if (previousValue === prop.value) {
      return;
    }

    const riskStateTo = {
      value: prop.value,
      text: getRiskAlertStatusTextByStatus(prop.value),
    };

    //The BE sends the previousValue as the statusText
    const riskStateFrom = {
      value: getRiskAlertStatusTextByStatusValue(prop.previousValue),
      text: getRiskAlertStatusTextByStatusText(prop.previousValue),
    };

    dispatch(
      showModal(UPDATE_RISK_ALERT_STATUS, {
        closeOnEscape: true,
        hideModal: () => dispatch(hideModal(UPDATE_RISK_ALERT_STATUS)),
        updateStatus: handleUpdateMarginCallStatus,
        alertId: prop.id,
        accountNumber: prop.accountNumber,
        from: riskStateFrom,
        to: riskStateTo,
      }),
    );
  };

  const handleUpdateMarginCallStatus = (marginRequirementId, to) => {
    const args = { marginRequirementId, status: to.value };
    dispatch(updateMarginRequirementStatus(args));
    dispatch(hideModal(UPDATE_RISK_ALERT_STATUS));
  };

  const metadata = marginCallMetadata(handleRiskStatusChange);

  const fetchData = ({ pageSize, pageIndex, filters, sortBy, columns }) => {
    let filter = [];
    let sort = [];
    if (filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrValue(
          filters.map((each) => ({
            ...each,
            op: getFilterOp(columns, each.id),
          })),
        ),
      );
    }
    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }
    dispatch(
      fetchMarginCallAlerts({
        limit: pageSize,
        page: pageIndex,
        offset: pageSize * pageIndex,
        filter,
        sort,
      }),
    );
  };
  return (
    <div>
      {moment().format()}
      <Divider />
      <DetailsHeader
        item={{
          accountsInMarginCall: count,
        }}
      />
      <Divider />
      <XTable7
        title="margin-call"
        metadata={metadata}
        data={marginCallAlerts}
        fetchData={fetchData}
        loading={loading}
      />
    </div>
  );
};
