import React, { Fragment, useState, useEffect } from 'react';
import {
  Modal,
  Select as SemanticSelect,
  Button,
  Segment,
  Dropdown,
} from 'semantic-ui-react';
import styled from 'styled-components';

const SelectStyled = styled(SemanticSelect)`
  width: 300px;
  margin-right: 30px;
  height: 40px;
`;

const ModalAddJurisdiction = ({ onClose, data, countries, onConfirm }) => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedStates, setSelectedStates] = useState([]);
  const [options, setOptions] = useState([]);
  const [disabledStates, setDisabledStates] = useState(true);

  const statesByCountry = (country) => {
    setSelectedCountry(country);
    const selectedJurisdiction = countries.find((x) => x.symbol === country);
    const statedSelectedByCountry = data
      ?.find((x) => x.symbol === country)
      ?.states.filter((x) => x.checked)
      .map((x) => x.name);

    if (selectedJurisdiction?.states?.length === 0) {
      setDisabledStates(true);
      setOptions([]);
      return;
    }

    setDisabledStates(false);

    setOptions([
      statedSelectedByCountry.length === selectedJurisdiction.states.length
        ? { key: 'clear', content: <b>Clear</b>, value: 'clear' }
        : { key: 'all', content: <b>Select All</b>, value: 'all' },
      ...selectedJurisdiction.states.map((x) => ({
        key: x.name,
        text: x.name,
        value: x.name,
      })),
    ]);

    setSelectedStates(statedSelectedByCountry);
  };

  const onChangeState = (e, state) => {
    let selectedValues = state.value;
    const allPosibleOptions = state.options;

    if (
      selectedValues.includes('all') ||
      selectedValues.length >= allPosibleOptions.length - 1
    ) {
      selectedValues = allPosibleOptions
        .filter((x) => x.key !== 'all')
        .map((x) => x.key);

      setOptions(
        options.map((x) =>
          x.key === 'all'
            ? { key: 'clear', content: <b>Clear</b>, value: 'clear' }
            : x,
        ),
      );
    }

    if (selectedValues.includes('clear')) {
      selectedValues = [];
      setOptions(
        options.map((x) =>
          x.key === 'clear'
            ? { key: 'all', content: <b>Select All</b>, value: 'all' }
            : x,
        ),
      );
    }

    if (selectedValues.length < allPosibleOptions.length - 1) {
      setOptions(
        options.map((x) =>
          x.key === 'clear'
            ? { key: 'all', content: <b>Select All</b>, value: 'all' }
            : x,
        ),
      );
    }
    setSelectedStates(selectedValues);
  };

  return (
    <Fragment>
      <Modal.Header>Add Jurisdiction</Modal.Header>
      <Modal.Content>
        <Segment basic textAlign="center">
          <div className="center-flex">
            <SelectStyled
              onChange={(e, { value }) => {
                statesByCountry(value);
              }}
              placeholder="Country"
              options={countries?.map((x) => {
                return {
                  key: x.symbol,
                  text: x.symbol,
                  value: x.symbol,
                  description: x.symbol,
                };
              })}
            />
            <Dropdown
              placeholder="State/Province"
              fluid
              multiple
              selection
              options={options}
              onChange={onChangeState}
              value={selectedStates}
              disabled={disabledStates}
            />
          </div>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button id="Cancel" onClick={onClose}>
          {' '}
          Cancel{' '}
        </Button>
        <Button
          color="teal"
          id="confirm"
          onClick={() => onConfirm(selectedCountry, selectedStates)}
        >
          Confirm
        </Button>
      </Modal.Actions>
    </Fragment>
  );
};

export default ModalAddJurisdiction;
