import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createLoadingSelector } from 'erisxkit/client';
import { Action, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
  ApprovedParticipantTableRow,
  CARRow,
  CGMRow,
  FCMRow,
  ParticipantCAR,
  ParticipantCGM,
  ParticipantManagementTableRow,
  TradingParticipant,
} from '../ts/models/TradingParticipant/ApprovedParticipant.model';
import { RootState } from '../ts/types/store';
import {
  GET_CUSTOMER_ACCOUNT_REFERENCE_LIST,
  LINK_CAR_TO_PARTICIPANT,
  UNLINK_CAR_TO_PARTICIPANT,
} from './carManagementReducer';
import { FetchTradingParticipantsResponse } from '../ts/api/participants';
import {
  LINK_CGM_TO_PARTICIPANT,
  UNLINK_CGM_TO_PARTICIPANT,
} from './cgmManagementReducer';

/** Action Names */
export const GET_TRADING_PARTICIPANTS = 'GET_TRADING_PARTICIPANTS';
export const GET_ACCOUNT_TRADING_PARTICIPANTS =
  'GET_ACCOUNT_TRADING_PARTICIPANTS';
export const DELETE_TRADING_PARTICIPANT = 'DELETE_TRADING_PARTICIPANT';

export const ENABLE_BLOCK_TRADES = 'ENABLE_BLOCK_TRADES';
export const DISABLE_BLOCK_TRADES = 'DISABLE_BLOCK_TRADES';

/** Routines */
export const fetchTradingParticipants = createRoutine(GET_TRADING_PARTICIPANTS);
export const fetchAccountTradingParticipants = createRoutine(
  GET_ACCOUNT_TRADING_PARTICIPANTS,
);

export const deleteTradingParticipant = createRoutine(
  DELETE_TRADING_PARTICIPANT,
);

export const enableBlockTrades = createRoutine(ENABLE_BLOCK_TRADES);
export const disableBlockTrades = createRoutine(DISABLE_BLOCK_TRADES);

/** Promisified Routines */
export const deleteTradingParticipantPromise = promisifyRoutine(
  deleteTradingParticipant,
);

export const enableBlockTradesPromise = promisifyRoutine(enableBlockTrades);
export const disableBlockTradesPromise = promisifyRoutine(disableBlockTrades);

/** Initial State */
type ParticipantManagementState = {
  participantList: FetchTradingParticipantsResponse;
};

const initialState: ParticipantManagementState = {
  participantList: [],
};

type ActionPayload = FetchTradingParticipantsResponse;

/** Reducer */
export default handleActions<ParticipantManagementState, ActionPayload>(
  {
    [fetchTradingParticipants.SUCCESS]: (
      state: ParticipantManagementState,
      action: Action<ActionPayload>,
    ) => {
      return { ...state, participantList: action.payload };
    },
    [fetchAccountTradingParticipants.SUCCESS]: (
      state: ParticipantManagementState,
      action: Action<ActionPayload>,
    ) => {
      return { ...state, participantList: action.payload };
    },
  },
  initialState,
);

//* Selectors */
export const getParticipantManagementState = (
  state: RootState,
): ParticipantManagementState =>
  get(state, 'participantManagement', initialState);

export const getParticipantCGMLinkModalLoading = (state: any) => {
  const isFetchingParticipants = createLoadingSelector([
    GET_TRADING_PARTICIPANTS,
  ]);
  const isLinkingCgm = createLoadingSelector([LINK_CGM_TO_PARTICIPANT]);
  const isUnLinkingCgm = createLoadingSelector([UNLINK_CGM_TO_PARTICIPANT]);
  return (
    isFetchingParticipants(state) ||
    isLinkingCgm(state) ||
    isUnLinkingCgm(state)
  );
};

export const getParticipantList = createSelector(
  [getParticipantManagementState],
  (state: any): FetchTradingParticipantsResponse =>
    get(state, 'participantList', []) as FetchTradingParticipantsResponse,
);

export const NAKED_CAR_LABEL = 'NAKED';

export const processCgm = (
  cgmName: string,
  cars: ParticipantCAR[],
  cgmType: string,
  cgmDescription: string,
): CGMRow => ({
  cgmName,
  cgmType,
  cgmDescription,
  subRows: cars?.map<CARRow>((car) => ({
    carName: car.customerAccountReference,
  })),
});

const getParticipantCGMs = (participant: TradingParticipant) => {
  return participant.fcms.reduce<CGMRow[]>((allCgms, fcm) => {
    const cgmRows = fcm.cgmsLinkedToParticipant.map(
      (cgm: ParticipantCGM): CGMRow =>
        processCgm(cgm.cgmNumber, cgm.cars, cgm.cgmType, cgm.description),
    );
    const nakedCars = processCgm(
      NAKED_CAR_LABEL,
      fcm.carsLinkedToParticipant,
      '',
      '',
    );
    allCgms.push(...cgmRows);
    if (nakedCars.subRows?.length) {
      allCgms.push(nakedCars);
    }
    return allCgms;
  }, [] as CGMRow[]);
};

const getParticpantFCMs = (participant: TradingParticipant) => {
  return participant.fcms.reduce<FCMRow[]>((allFcms, fcm) => {
    const fcmData: FCMRow = {
      fcmName: fcm.fcmName,
      subRows: [],
    };
    const cgmRows = fcm.cgmsLinkedToParticipant.map(
      (cgm: ParticipantCGM): CGMRow =>
        processCgm(cgm.cgmNumber, cgm.cars, cgm.cgmType, cgm.description),
    );
    const nakedCars = processCgm(
      NAKED_CAR_LABEL,
      fcm.carsLinkedToParticipant,
      '',
      '',
    );
    fcmData?.subRows?.push(...cgmRows);
    if (nakedCars.subRows?.length) {
      fcmData?.subRows?.push(nakedCars);
    }
    allFcms.push(fcmData);
    return allFcms;
  }, [] as FCMRow[]);
};

export const getParticipantManagementTableRows = createSelector(
  [getParticipantList],
  (participants) =>
    participants.map<ParticipantManagementTableRow>((participant) => {
      const cgms = getParticipantCGMs(participant);
      return { ...participant, subRows: cgms };
    }),
);

export const getApprovedParticipantsTableRows = createSelector(
  [getParticipantList],
  (participants) =>
    participants.flatMap<ApprovedParticipantTableRow>((participant) => {
      const cgms = getParticpantFCMs(participant);
      return cgms;
    }),
);

export const getApprovedParticipantsTableRowCount = createSelector(
  [getApprovedParticipantsTableRows],
  (rows) => (rows && rows?.length ? rows?.length : 0),
);

export const getParticipantListSelectOptions = createSelector(
  [getParticipantList],
  (participants) =>
    participants.map((p) => ({
      key: p.participantId,
      value: p,
      text: p.participantName,
    })),
);

export const getParticipantCARLinkModalLoading = (state: RootState) => {
  const isFetchingCARs = createLoadingSelector([
    GET_CUSTOMER_ACCOUNT_REFERENCE_LIST,
  ]);
  const isFetchingParticipants = createLoadingSelector([
    GET_TRADING_PARTICIPANTS,
  ]);
  const isLinkingCar = createLoadingSelector([LINK_CAR_TO_PARTICIPANT]);
  const isUnLinkingCar = createLoadingSelector([UNLINK_CAR_TO_PARTICIPANT]);

  return (
    isFetchingCARs(state) ||
    isFetchingParticipants(state) ||
    isLinkingCar(state) ||
    isUnLinkingCar(state)
  );
};
