import React from 'react';
import { get } from 'lodash';
import { Button, Label } from 'semantic-ui-react';
import { formatTimeLocal } from '../utils/time';

const blockTradeRequestsMetadata = (actionBlockTrade) => [
  {
    Header: 'Request Id',
    accessor: 'requestId',
    id: 'requestId',
  },
  {
    Header: 'Product',
    accessor: 'productSymbol',
    id: 'productSymbol',
  },
  {
    Header: 'Contract',
    accessor: 'contractCode',
    id: 'contractCode',
  },
  {
    Header: 'Exchange Symbol',
    id: 'contractSymbol',
    accessor: 'contractSymbol',
  },
  {
    Header: 'PX',
    accessor: 'px',
    id: 'px',
  },
  {
    Header: 'QTY',
    accessor: 'qty',
    id: 'qty',
  },
  {
    Header: 'Trade Date',
    id: 'tradeDate',
    accessor: 'tradeDate',
  },
  {
    Header: 'Negotiated Time',
    id: 'negotiatedTime',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.negotiatedTime),
  },
  {
    Header: 'Submitted Time',
    id: 'submittedTime',
    width: 205,
    headerClassName: 'pull-left',
    accessor: (row) => formatTimeLocal(row.submittedTime),
  },
  {
    Header: 'Submitter',
    id: 'submitter',
    accessor: 'createdBy',
  },
  {
    Header: 'Buy Side',
    id: 'buySide',
    accessor: (row) => row.buySide.accountLabel,
  },
  {
    Header: 'Sell Side',
    id: 'sellSide',
    accessor: (row) => row.sellSide.accountLabel,
  },
  {
    Header: 'Error Code',
    id: 'errorCode',
    accessor: (row) => row.errorCode,
  },
  {
    Header: 'Error Reason',
    id: 'errorReason',
    accessor: (row) => row.errorReason,
  },
  {
    Header: 'State',
    id: 'state',
    resizable: false,
    accessor: (row) => (
      <Label color={get(row, 'state') === 'cleared' ? 'green' : 'yellow'}>
        {get(row, 'state')}
      </Label>
    ),
  },
  {
    Header: 'Submission Type',
    id: 'submissionType',
    width: 150,
    accessor: (row) =>
      row.userSide ? <span>Alleged</span> : <span>Confirmed</span>,
  },
  {
    Header: 'Action',
    id: 'action',
    width: 200,
    accessor: (row) =>
      row.userSide && row.state.toLowerCase() === 'pending' ? (
        <div>
          <Button
            className="add-button"
            onClick={() => actionBlockTrade(row, 'confirm')}
          >
            Confirm
          </Button>
          <Button id="cancel" onClick={() => actionBlockTrade(row, 'reject')}>
            Cancel
          </Button>
        </div>
      ) : (
        <div></div>
      ),
  },
];

export default blockTradeRequestsMetadata;
