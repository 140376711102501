import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createLoadingSelector } from 'erisxkit/client';
import _ from 'lodash';
import { Form, Header, Container } from 'semantic-ui-react';
import Big from 'bignumber.js';
import { createFeeRebate } from '../../actions/manualEntryActions';
import { fetchAccounts } from '../../actions/accountsActions';
import { assetTypes } from '../../actions/utilActions';
import * as accountTypes from '../../constants/accountTypes';
import FeeRebate from '../../components/ManualEntries/FeeRebate';
import { createAccountsSelectorObject } from '../../reducers/accountsReducer';
import { getAssetTypes } from '../../selectors';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

const mapStateToProps = (state) => ({
  feeAccounts: createAccountsSelectorObject([accountTypes.HOUSE])(state),
  feeRebateLoading: createLoadingSelector(['CREATE_FEE_REBATE'])(state),
  assetTypesList: getAssetTypes(state),
});

const mapDispatchToProps = {
  fetchAccounts,
  createFeeRebate,
  assetTypes,
};

export const initialState = {
  accountId: '',
  assetType: '',
  amount: '',
  lowFeeAccountBalance: false,
};

class FeeRebateContainer extends Component {
  state = initialState;
  componentDidMount = () => {
    const { fetchAccounts, assetTypes } = this.props;
    assetTypes();
    fetchAccounts({ categories: [accountTypes.HOUSE] });
  };

  handleChange = (e, { name, value }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        let balance;
        let lowFeeAccountBalance;

        // check if we have enough of an asset in our fee account
        if (
          this.state.assetType &&
          (name === 'assetType' || name === 'amount')
        ) {
          balance = _.get(
            this.props.feeAccounts,
            [
              this.state.feeAccountId,
              'balances',
              this.state.assetType.toLowerCase(),
            ],
            null,
          );
          lowFeeAccountBalance =
            balance && Big(this.state.amount).lt(Big(balance));
          this.setState({ lowFeeAccountBalance });
        }
      },
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createFeeRebate(this.state);
  };

  submitDisabled = () => {
    const requiredFields = [
      'postingKey',
      'feeAccountId',
      'productSymbol',
      'assetType',
      'amount',
      'accountId',
      'fundsDesignation',
      'contractSymbol',
    ];
    return !requiredFields.every((key) => this.state[key]);
  };

  render = () => {
    const feeAccount = this.props.feeAccounts[this.state.feeAccountId];
    const options = Object.values(this.props.feeAccounts).map((account) => ({
      key: account.accountId,
      value: account.accountId,
      text: account.label,
      description: account.name,
    }));

    return (
      <Container textAlign="left" text className="tab-container">
        <Header as="h2" dividing>
          Fee Rebate
        </Header>

        <Form>
          <AccountSelectionContainer
            onChange={this.handleChange}
            name="feeAccountId"
            label="Select Fee Account"
            required
            options={options}
          />
        </Form>
        <FeeRebate
          disabled={!this.state.feeAccountId}
          data={this.state}
          assetTypes={this.props.assetTypesList}
          feeAccount={feeAccount}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          lowFeeAccountBalance={this.state.lowFeeAccountBalance}
          feeRebateLoading={this.props.feeRebateLoading}
          accountsLoading={this.props.accountsLoading}
          submitDisabled={this.submitDisabled()}
        />
      </Container>
    );
  };
}

FeeRebateContainer.propTypes = {
  spotProducts: PropTypes.func.isRequired,
  fetchAccounts: PropTypes.func.isRequired,
  feeAccount: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      name: PropTypes.string,
      members: PropTypes.array,
      balances: PropTypes.object,
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  createFeeRebate: PropTypes.func.isRequired,
  accountsList: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      name: PropTypes.string,
      members: PropTypes.array,
      balances: PropTypes.object,
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  spotProductsList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
      text: PropTypes.string,
      baseassettype: PropTypes.string,
    }),
  ),
};

FeeRebateContainer.defaultProps = {
  spotProductsList: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(FeeRebateContainer);
