import { get } from 'lodash';
import { format } from 'erisxkit/client';
import { categoryFromAssetOption } from '../utils/methods';
import { formatDateLocal } from '../utils/time';

const columns = (spotProductsOptions, contracts) => [
  {
    Header: 'Date',
    id: 'date',
    accessor: (row) => formatDateLocal(get(row, 'date')),
    width: 200,
  },
  {
    Header: 'Subexchange',
    id: 'subExchangeLabel',
    accessor: (row) => get(row, 'subExchangeLabel', 'ErisX'),
    width: 140,
  },
  {
    Header: 'Exchange Symbol',
    id: 'exchangeCode',
    accessor: (row) => get(row, 'contractSymbol', '-'),
    width: 140,
  },
  {
    Header: 'Product',
    id: 'product',
    accessor: (row) => {
      const contract = contracts.find(
        (each) => each.symbol === row.contractSymbol,
      );
      return contract && contract.productSymbol;
    },
    width: 140,
  },
  {
    Header: 'Contract',
    id: 'contract',
    accessor: (row) => {
      const contract = contracts.find(
        (each) => each.symbol === row.contractSymbol,
      );
      return contract && contract.contractCode;
    },
    width: 140,
  },
  {
    Header: 'Price',
    id: 'price',
    className: 'mono',
    accessor: (row) =>
      format(get(row, 'price', '-'), {
        type: categoryFromAssetOption(
          get(row, 'quotedAssetType'),
          spotProductsOptions,
        ),
        currency: get(row, 'quotedAssetType', ''),
      }),
  },
  {
    Header: 'Settlement Price Type',
    id: 'settlementPriceType',
    accessor: (row) => get(row, 'settlementPriceType', '-'),
    width: 200,
  },
];

export default columns;
