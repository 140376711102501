import {
  deleteTradingParticipant,
  fetchTradingParticipants,
  fetchAccountTradingParticipants,
  disableBlockTrades,
  enableBlockTrades,
} from '../reducers/participantManagementReducer';
import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';

export default function* watch() {
  yield takeLatest(
    fetchTradingParticipants,
    generateSaga(fetchTradingParticipants),
  );
  yield takeLatest(
    fetchAccountTradingParticipants,
    generateSaga(fetchAccountTradingParticipants),
  );
  yield takeLatest(
    deleteTradingParticipant,
    generateSaga(deleteTradingParticipant, {
      loading: 'Deleting Participant...',
    }),
  );
  yield takeLatest(
    enableBlockTrades,
    generateSaga(enableBlockTrades, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    disableBlockTrades,
    generateSaga(disableBlockTrades, {
      method: HTTPMethod.POST,
    }),
  );
}
