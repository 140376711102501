import React, { useCallback, useEffect, useMemo } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import cogoToast from 'cogo-toast';
import get from 'lodash/get';
import XTable7 from '../../../common/table/XTable7';
import participantManagementMetadata from '../../../metadata/participantManagementMetadata';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  GET_ACCOUNT_TRADING_PARTICIPANTS,
  GET_TRADING_PARTICIPANTS,
  fetchAccountTradingParticipants,
  getParticipantManagementTableRows,
} from '../../../reducers/participantManagementReducer';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { RootState } from '../../../ts/types/store';
import Member from '../../../ts/models/Member.model';
import {
  fetchAccountsV2Promise,
  getCustomerAccountFromMember,
} from '../../../reducers/accountsReducer';
import Account from '../../../ts/models/Account.model';
import { GetAccountsV2Response } from '../../../ts/api/accounts_v2';
import { ORIGINS } from '../../../constants/origin';
import usePaginatedFetch from '../../../hooks/usePaginatedFetch';
import { ACCOUNTS_V2 } from '../../../constants/actionTypes';

interface Props {
  selectedMember: Member;
}
const ParticipantManagement = ({ selectedMember }: Props) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getParticipantManagementTableRows);
  const account: Account = useAppSelector((state: RootState) =>
    getCustomerAccountFromMember(state, selectedMember?.memberId),
  );

  const accountLabel = useMemo(() => account?.label, [account]);

  const filteredFetch = usePaginatedFetch(fetchAccountTradingParticipants, {
    urlParams: {
      accountLabel,
    },
  });
  const fetchData = useCallback(
    (params) => {
      if (account) {
        filteredFetch(params);
      }
    },
    [filteredFetch, dispatch, account],
  );

  const loadingParticipants = useAppSelector((state: RootState) =>
    createLoadingSelector([GET_ACCOUNT_TRADING_PARTICIPANTS])(state),
  );
  const loadingAccounts = useAppSelector((state: RootState) =>
    createLoadingSelector([ACCOUNTS_V2])(state),
  );

  const loading = loadingAccounts || loadingParticipants;
  useEffect(() => {
    const initialFetch = async () => {
      try {
        const userAccounts = selectedMember?.ledgerAccounts;
        if (userAccounts) {
          // Get all accounts linked to selectedMember
          const data: GetAccountsV2Response = await fetchAccountsV2Promise(
            {
              filter: [{ attr: 'account_id', value: userAccounts, op: 'eq' }],
            },
            dispatch,
          );
          // Get the account that's of CUSTOMER origin
          const customerAcc = get(data, 'accounts', []).find(
            (acc) => acc.origin === ORIGINS.CUSTOMER,
          );
          // Fetch participants for that account only
          dispatch(
            fetchAccountTradingParticipants({
              urlParams: {
                accountLabel: customerAcc?.label,
              },
            }),
          );
        }
      } catch (e) {
        cogoToast.error('Could not apply changes');
      }
    };
    initialFetch();
  }, [selectedMember, dispatch]);

  const metadata = useMemo(
    () =>
      participantManagementMetadata(
        selectedMember?.memberId,
        fetchData,
        selectedMember?.firmCode,
      ),
    [selectedMember, fetchData],
  );
  return (
    <XTable7
      metadata={metadata}
      data={data}
      fetchData={fetchData}
      loading={loading}
      showHeader
      showPagination={false}
    />
  );
};

export default ParticipantManagement;
