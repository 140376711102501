import { get } from 'lodash';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import { GetFCMClearingRequestsResponse } from '../ts/api/fcm_clearing';
import { RootState } from '../ts/types/store';
import { createSelector } from 'reselect';
import { FCMClearingRequest, FCMEntity } from '../ts/models/FCMClearing.model';

/** Action Names */
export const FETCH_FCM_CLEARING = 'FCM_CLEARING';
export const FETCH_ALL_FCMS = 'FCM_CLEARING_ALL_FCM_MEMBERS';
export const CREATE_FCM_CLEARING = 'CREATE_FCM_CLEARING';
export const REJECT_FCM_CLEARING = 'REJECT_FCM_CLEARING';
export const APPROVE_FCM_CLEARING = 'APPROVE_CLEARING_REQUEST';

/** Routines */
export const fetchFcmClearing = createRoutine(FETCH_FCM_CLEARING);
export const fetchAllFcms = createRoutine(FETCH_ALL_FCMS);
export const createFcmClearing = createRoutine(CREATE_FCM_CLEARING);
export const rejectFcmClearing = createRoutine(REJECT_FCM_CLEARING);
export const approveFcmClearing = createRoutine(APPROVE_FCM_CLEARING);

/** Promisified Routines */
export const createFcmClearingPromiseCreator =
  promisifyRoutine(createFcmClearing);
export const rejectFcmClearingPromiseCreator =
  promisifyRoutine(rejectFcmClearing);
export const approveFcmClearingPromiseCreator =
  promisifyRoutine(approveFcmClearing);

/** Initial State */
type FCMClearingState = {
  fcmMemberClearingRequests: GetFCMClearingRequestsResponse | null;
};

const initialState: FCMClearingState = {
  fcmMemberClearingRequests: null,
};

/** Reducer */
export default handleActions<FCMClearingState, GetFCMClearingRequestsResponse>(
  {
    [fetchFcmClearing.SUCCESS]: (state, { payload }) => ({
      ...state,
      fcmMemberClearingRequests: payload,
    }),
  },
  initialState,
);

/** Selectors */
export const getFcmClearingState = (state: RootState): FCMClearingState =>
  get(state, 'fcmClearing', initialState);

export const getFcmMemberClearingRequestsResponse = createSelector(
  [getFcmClearingState],
  (state) => state?.fcmMemberClearingRequests,
);

export const getFcmMemberClearingRequests = createSelector(
  [getFcmMemberClearingRequestsResponse],
  (clearingRequestsResponse) =>
    get(clearingRequestsResponse, 'fcmClearingRequests', []),
);

export const getSortedFcmMemberClearingRequests = createSelector(
  [getFcmMemberClearingRequests],
  (requests = []) =>
    requests.sort((req1, req2) => {
      const date1 = new Date(req1.createdAt)?.getTime();
      const date2 = new Date(req2.createdAt)?.getTime();
      return date2 - date1;
    }),
);
export const getFcmMemberClearingRequestCount = createSelector(
  [getFcmMemberClearingRequestsResponse],
  (clearingRequestsResponse) => get(clearingRequestsResponse, 'count'),
);
