import React, { Fragment } from 'react';
import { Header, Tab } from 'semantic-ui-react';
import XTab from '../../common/tabs/XTab';
import CreateAssetTypeContainer from './CreateAssetTypeContainer';
import CollateralProductsContainer from './CollateralProductsContainer';
import SpotProductsContainer from './SpotProductsContainer';
import FutureProductsContainer from './FuturesProductsContainer';
import SubExchangeContainer from './SubExchangeContainer';

const panes = [
  {
    menuItem: 'Assets',
    url: 'create_asset_type',
    render: () => (
      <Tab.Pane attached={false}>
        <CreateAssetTypeContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Collateral',
    url: 'collateral',
    render: () => (
      <Tab.Pane attached={false}>
        <CollateralProductsContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Spot',
    url: 'spot_products',
    render: () => (
      <Tab.Pane attached={false}>
        <SpotProductsContainer />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Futures',
    url: 'futures_products',
    render: () => (
      <Tab.Pane attached={false}>
        <FutureProductsContainer />
      </Tab.Pane>
    ),
  },
];

export default () => (
  <Fragment>
    <Header as="h1">Products</Header>
    <XTab panes={panes} />
  </Fragment>
);
