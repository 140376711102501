export const DIRECT_MEMBER = { name: 'DM - Direct Member', code: 'DM' };
export const ENTITY = {
  name: 'EN - Entity (Individual LLC/Sole Proprietor)',
  code: 'EN',
};
export const PROFESSIONAL_TRADING_FIRM = {
  name: 'PTG - Professional Trading Firm',
  code: 'PTG',
};
export const MARKET_MAKER = { name: 'MM - Market Maker', code: 'MM' };
export const INTERMEDIARY = { name: 'IM - Intermediary', code: 'IM' };
export const FCM = { name: 'FCM - FCM', code: 'FCM' };
export const VENDOR = { name: 'VEND - Vendor', code: 'VEND' };
export const BUYSIDE = { name: 'BS - Buyside', code: 'BS' };
