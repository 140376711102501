import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import debounce from 'lodash/debounce';

export const GENERAL_TABLE_FILTER_TYPES = {
  STRING: 'text',
  NUMBER: 'number',
  DATE: 'date',
  CUSTOM: 'custom',
};

/**
 * @param currentFilters - list of objects with id and value attributes modelling currently applied filters
 * @returns an object that maps the id of each column to its initial value
 */
const getInitialLocalValuesFromFilters = (currentFilters) =>
  currentFilters.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr.value || '' }),
    {},
  );

const StyledField = styled(Form.Field)`
  flex-shrink: 0 !important;
`;

const StyledGroup = styled(Form.Group)`
  flex-wrap: wrap;
  row-gap: 1em;
`;

/**
 * @description Component to render array of table filter fields on XTable7.
 * Allows for Date, Dropdown and Input components
 * @prop filters - list of filters to render
 * @prop currentFilters - list of the currently applied filters on the XTable7
 * @prop setFilters - XTable7 instance method to set a filter value by column id
 * @prop allColumnIds - list of ids of the table columns

 */
const GeneralFilters = ({
  filters,
  setFilter,
  currentFilters,
  allColumnIds,
}) => {
  // Handle filter state locally without debounced on change for a smooth UX
  // then the debounced onChange callback will setFilters
  const [localFilterValues, setLocalFilterValues] = useState({});

  const getCurrentFilterValue = useCallback(
    (columnId) =>
      get(
        currentFilters.find((filter) => filter.id === columnId),
        'value',
        '',
      ),
    [currentFilters],
  );

  const debouncedSetFilter = useCallback(
    debounce((column, value) => {
      setFilter(column, value);
    }, 250),
    [],
  );

  // If a filter uses a columnId which is not present in the table's metadata columns
  // react-table-v7 would error out. We ignore those filters.
  const isValidFilter = useCallback(
    (columnId) => allColumnIds.includes(columnId),
    [allColumnIds],
  );

  useEffect(() => {
    setLocalFilterValues(getInitialLocalValuesFromFilters(currentFilters));
  }, [currentFilters]);

  const getFilterField = useCallback(
    (filter) => {
      // Custom Filter

      if (filter.type === GENERAL_TABLE_FILTER_TYPES.CUSTOM) {
        const CustomComponent = filter.component;
        return (
          <CustomComponent
            onChange={(event, { value }) => setFilter(filter.name, value)}
            key={filter.name}
            label={filter.text}
            name={filter.name}
            id={filter.name}
          />
        );
      }

      // Date Filter
      if (filter.type === GENERAL_TABLE_FILTER_TYPES.DATE) {
        return (
          <StyledField
            control={Datetime}
            className="ui input datetime"
            key={filter.name}
            label={filter.text}
            name={filter.name}
            id={filter.name}
            defaultValue=""
            dateFormat="YYYY-MM-DD"
            timeFormat
            inputProps={{
              placeholder: filter?.placeholder || 'Filter Begin Time',
            }}
            onChange={(time) => setFilter(filter.name, time)}
            closeOnSelect
            value={get(localFilterValues, filter.name, '')}
          />
        );
      }

      // Select filter
      if (!isEmpty(filter.options)) {
        return (
          <Form.Dropdown
            label={filter.text}
            name={filter.name}
            placeholder={filter?.placeholder || filter.text}
            className="pull-right"
            search
            selection
            clearable
            options={filter.options}
            onChange={(event, { value }) => setFilter(filter.name, value)}
            value={getCurrentFilterValue(filter.name)}
          />
        );
      }

      return (
        <Form.Input
          label={filter.text}
          name={filter.name}
          id={filter.name}
          type={filter.type}
          key={filter.name}
          placeholder={filter?.placeholder || filter.text}
          value={get(localFilterValues, filter.name, '')}
          onChange={(e, { value }) => {
            setLocalFilterValues({
              ...localFilterValues,
              [filter.name]: value,
            });
            debouncedSetFilter(filter.name, value);
          }}
        />
      );
    },
    [filters, setFilter, localFilterValues, debouncedSetFilter],
  );

  return (
    <Form>
      <StyledGroup>
        {filters.map((filter) =>
          isValidFilter(filter.name) ? getFilterField(filter) : null,
        )}
      </StyledGroup>
    </Form>
  );
};

GeneralFilters.defaultProps = {
  filters: [],
  currentFilters: [],
  allColumnIds: [],
};

GeneralFilters.propTypes = {
  setFilter: PropTypes.func.isRequired,
  allColumnIds: PropTypes.arrayOf(PropTypes.string),
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        GENERAL_TABLE_FILTER_TYPES.STRING,
        GENERAL_TABLE_FILTER_TYPES.STRING,
        GENERAL_TABLE_FILTER_TYPES.DATE,
      ]),
      text: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          description: PropTypes.string.description,
        }),
      ),
      component: PropTypes.element,
    }),
  ),
};

export default GeneralFilters;
