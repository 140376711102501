import { formatTimeLocal } from '../utils/time';

const ipAuthorizationsHistoryMetadata = () => [
  {
    Header: 'Date/Time',
    id: 'dateTime',
    accessor: (row) => formatTimeLocal(row.dateTime),
    width: 120,
  },
  {
    id: 'action',
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Updated By',
    id: 'updatedBy',
    accessor: (row) => row.updatedBy,
    width: 120,
  },
  {
    Header: 'IP Address',
    id: 'ipAddress',
    accessor: (row) => row.ipAddress,
    width: 120,
  },
  {
    Header: 'Comment',
    id: 'comment',
    width: 200,
    accessor: (row) => row.comment,
  },
];

export default ipAuthorizationsHistoryMetadata;
