import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Modal, Form, Button } from 'semantic-ui-react';
import { titleCase } from 'change-case';
import PropTypes from 'prop-types';
import SpotProductFields from '../../common/forms/SpotProduct';

let CreateSpotProduct = ({
  confirmAction,
  handleSubmit,
  hideModal,
  initialize,
  pristine,
  type,
  baseAssetType,
  contractSize,
  quotedAssetType,
  allowBlockTrades,
  symbol,
  minimumBlockSize,
  description,
  valid,
  ...rest
}) => {
  if (type === 'update' && pristine) {
    // only initialize with given form fields, not redux-form scaffolding
    initialize(
      {
        baseAssetType,
        contractSize,
        quotedAssetType,
        symbol,
        description,
        type,
        allowBlockTrades,
        minimumBlockSize,
      },
      { keepDirty: true },
    );
  }

  return (
    <Fragment>
      <Modal.Header>
        {titleCase(type)} Spot Product{' '}
        <Button
          className="pull-right"
          circular
          size="mini"
          icon="times"
          onClick={() => hideModal()}
        />
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit(confirmAction)}>
          <SpotProductFields {...rest} />
          <Modal.Actions className="modal-form-actions">
            <Form.Button onClick={() => hideModal()}>Cancel</Form.Button>
            <Form.Button primary disabled={!valid || pristine} id={type}>
              {titleCase(type)} spot product
            </Form.Button>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Fragment>
  );
};

const selector = formValueSelector('CreateSpotProduct');

CreateSpotProduct = connect((state) => ({
  formBaseAssetType: selector(state, 'baseAssetType'),
  formQuotedAssetType: selector(state, 'quotedAssetType'),
  formSymbol: `${selector(state, 'baseAssetType') || '—'}/${selector(state, 'quotedAssetType') || '—'}`,
  formAllowBlockTrades: selector(state, 'allowBlockTrades'),
}))(CreateSpotProduct);

CreateSpotProduct.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  type: PropTypes.string,
  valid: PropTypes.bool,
  baseAssetType: PropTypes.string,
  contractSize: PropTypes.string,
  quotedAssetType: PropTypes.string,
  symbol: PropTypes.string,
  description: PropTypes.string,
  minimumBlockSize: PropTypes.string,
  allowBlockTrades: PropTypes.bool,
};

CreateSpotProduct.defaultProps = {
  pristine: true,
  type: 'create',
  valid: false,
  baseAssetType: '',
  contractSize: '',
  quotedAssetType: '',
  symbol: '',
  description: '',
  minimumBlockSize: '',
  allowBlockTrades: false,
};

export default reduxForm({
  form: 'CreateSpotProduct',
})(CreateSpotProduct);
