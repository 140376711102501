import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Form } from 'semantic-ui-react';
import {
  createLoadingSelector,
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  getItemsCount,
  getItemsPaged,
  inputOnChange,
} from 'erisxkit/client';
import MemberIndex from '../../components/Members/MemberIndex';
import memberIndexMetadata from '../../metadata/memberIndexMetadata';
import { fetchMembers, selectMember } from '../../reducers/membersReducer';
import { showModal, hideModal } from 'erisxkit/client';
import * as actionTypes from '../../constants/actionTypes';
import * as statuses from '../../constants/statuses';
import history from '../../constants/history';

const mapStateToProps = (state = {}) => ({
  membersLoading: createLoadingSelector([actionTypes.MEMBERS])(state),
  count: getItemsCount('members')(state),
  members: getItemsPaged('members')(state),
});

const mapDispatchToProps = {
  fetchMembers,
  selectMember,
  showModal,
  hideModal,
};

class MemberIndexContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusOptions: this.calculateStatusOptions(),
    };
  }

  calculateStatusOptions = () =>
    Object.values(statuses).map((s) => ({
      key: s.code,
      value: s.code,
      text: s.name,
    }));

  membersFilters = (statusOptions) => [
    {
      placeholder: 'Firm Code',
      component: Form.Input,
      name: 'firm_code',
      id: 'firmCode',
      label: 'Firm Code',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Name',
      component: Form.Input,
      name: 'name',
      id: 'name',
      label: 'Name',
      onChange: inputOnChange,
    },
    {
      placeholder: 'E-Market Organization Name',
      component: Form.Input,
      name: 'emarket_organization_name',
      id: 'emarket_organization_name',
      label: 'E-Market Org Name',
      onChange: inputOnChange,
    },
    {
      placeholder: 'State',
      component: Form.Input,
      name: 'location_state',
      id: 'locationState',
      label: 'State',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Country',
      component: Form.Input,
      name: 'country',
      id: 'country',
      label: 'Country',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Member Id',
      component: Form.Input,
      name: 'id',
      id: 'id',
      label: 'Member Id',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Status',
      component: Dropdown,
      name: 'status',
      id: 'status',
      label: 'Status',
      onChange: inputOnChange,
      options: statusOptions,
      search: true,
      selection: true,
      clearable: true,
    },
  ];

  fetchData = (state) => {
    // initialize the filter to have trigger for type, this is required.
    let filter = [];
    let sort = [];
    if (state.filtered.length !== 0) {
      filter = filteredArrayToAttrValue(state.filtered);
    }
    if (state.sorted.length !== 0) {
      sort = sortedArrayToAttrValue(state.sorted);
    }
    this.props.fetchMembers({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
      sort,
      includeName: true,
    });
  };

  clearSelectedMember = () => {
    this.props.selectMember('');
  };

  selectMember = ({ memberId }) => {
    this.props.selectMember(memberId);
    history.push(`/members/${memberId}/details`);
  };

  render = () => {
    const { members, membersLoading } = this.props;
    return (
      <MemberIndex
        metadata={memberIndexMetadata}
        data={members}
        fetchData={this.fetchData}
        loading={membersLoading}
        selectMember={this.selectMember}
        clearSelectedMember={this.clearSelectedMember}
        count={this.props.count}
        filters={this.membersFilters(this.state.statusOptions)}
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MemberIndexContainer);
