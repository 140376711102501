import React from 'react';
import styled from 'styled-components';
import { Modal, Form, Icon, Button } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues, InjectedFormProps } from 'redux-form';
import { renderDropdown, hideModal } from 'erisxkit/client';
import { Column, Row } from 'react-table';

import * as rules from '../../../common/forms/rules';
import { accountType, customerOrderCapacity, side } from './menuOptions';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { RootState } from '../../../ts/types/store';
import BlockTradeRequest, {
  BlockTradeRequestAction,
} from '../../../ts/models/BlockTrades/BlockTradeRequest.model';
import { renderField } from '../../../common/forms/component';
import XTable7 from '../../../common/table/XTable7';

type Props = {
  row: BlockTradeRequest;
  memoizedConfirmColumns: Column<object>[];
  action: BlockTradeRequestAction;
  valid: boolean;
  onSave: (
    action: BlockTradeRequestAction,
    row: Row<BlockTradeRequest>,
    isApprove: boolean,
  ) => void;
};

export type ApproveBlockTradeRequestForm = {
  clOrdId: string;
  exchangeMemberId?: string;
  customerAccountRef?: string;
  senderSubId: number;
  custOrderCapacity: string;
  accountType: string;
  cgm: string;
};

export type RejectBlockTradeRequestForm = {
  side: string;
};

const Container = styled.div`
  padding: 15px;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(Button)`
  width: 150px;
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;
const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const Table = styled(XTable7)`
  .table {
    overflow-y: hidden;
  }
  #table-settings {
    display: none;
  }
`;

const FieldGrid = styled(Modal.Content)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.2em;
  column-gap: 3em;
  margin-top: 2em;
  margin-bottom: 1.6em;
  padding: 1em;
`;
const APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM = 'BlockTradeRequestFormForm';

type FormValues = ApproveBlockTradeRequestForm | RejectBlockTradeRequestForm;

const BlockTradeRequestForm: React.FC<
  InjectedFormProps<FormValues, Props> & Props
> = ({ row, memoizedConfirmColumns, action, valid, onSave }: Props) => {
  const dispatch = useAppDispatch();
  const approveValues: Partial<ApproveBlockTradeRequestForm> = useAppSelector(
    (state: RootState) =>
      getFormValues(APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM)(state),
  );

  const rejectValues: Partial<RejectBlockTradeRequestForm> = useAppSelector(
    (state: RootState) =>
      getFormValues(APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM)(state),
  );

  return (
    <Container>
      <Header>{`${action} Block Trade Request`}</Header>
      <Modal.Content>
        <Form>
          <Table
            title="BlockTradeRequestsModal"
            data={[row]}
            localSortingAndFiltering
            metadata={memoizedConfirmColumns}
            fetchData={() => {}}
            count={1}
            minimumRows={1}
            height="100px"
            showFooterQuantity={false}
            showPagination={false}
          />
          <FieldGrid>
            {action == BlockTradeRequestAction.APPROVE && (
              <>
                <Field
                  component={renderField}
                  label="Client Order ID *"
                  name="clOrdId"
                  type="text"
                  placeholder="Enter the Client Order Id..."
                  required
                  validate={[rules.required]}
                />
                <Field
                  component={renderField}
                  label="Sender SubId *"
                  name="senderSubId"
                  type="text"
                  minLength="3"
                  maxLength="18"
                  required
                  validate={[
                    rules.required,
                    rules.alphanumeric,
                    rules.minLength(3),
                    rules.maxLength(18),
                  ]}
                  placeholder="Enter the Sender SubId..."
                />

                <Field
                  component={renderField}
                  label="Exchange Member ID *"
                  name="exchangeMemberId"
                  type="text"
                  required
                  validate={[rules.required, rules.alphanumeric]}
                  placeholder="Enter the Exchange Member ID..."
                />
                <Field
                  component={renderField}
                  label="CGM"
                  name="cgm"
                  type="text"
                  validate={[rules.alphanumeric]}
                />

                <Field
                  component={renderField}
                  label="Customer Account Reference *"
                  name="customerAccountRef"
                  type="text"
                  required
                  validate={[rules.required, rules.carCgmName]}
                  placeholder="Enter the Customer Account Reference..."
                />
                <Field
                  component={renderDropdown}
                  label="Customer Order Capacity"
                  name="custOrderCapacity"
                  fluid
                  options={customerOrderCapacity}
                  required
                  minimal
                  validate={[rules.required]}
                />
                <Field
                  component={renderDropdown}
                  label="Account Type"
                  name="accountType"
                  fluid
                  options={accountType}
                  minimal
                  required
                  validate={[rules.required]}
                />
              </>
            )}
            {action == BlockTradeRequestAction.REJECT && (
              <Field
                component={renderDropdown}
                label="Side"
                name="side"
                fluid
                options={side}
                minimal
                required
                validate={[rules.required]}
              />
            )}
          </FieldGrid>
        </Form>
      </Modal.Content>
      <Actions>
        <Action onClick={() => dispatch(hideModal())}>Cancel</Action>
        <Action
          onClick={() =>
            onSave(
              action,
              row,
              action === BlockTradeRequestAction.APPROVE
                ? approveValues
                : rejectValues,
            )
          }
          danger={action === BlockTradeRequestAction.REJECT}
          disabled={!valid}
          data-cy="create-cgm-save-btn"
        >
          Save
        </Action>
      </Actions>
    </Container>
  );
};

export default reduxForm<FormValues, Props>({
  form: APPROVE_REJECT_BLOCK_TRADE_REQUEST_FORM,
})(BlockTradeRequestForm);
