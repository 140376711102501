import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Header, Segment, Grid, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  renderDropdown,
  createLoadingSelector,
  renderField,
  showModal,
  hideModal,
} from 'erisxkit/client';
import { useSelector, useDispatch, connect } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import XTable7 from '../../common/table/XTable7';
import {
  fetchAccountsV2,
  getAllAccountsArray,
} from '../../reducers/accountsReducer';
import {
  getPositionsTransferTypes,
  getPositionsTransfers,
} from '../../reducers/balancesReducer';
import {
  positionTransferTypes,
  positionTransfers,
  clearPositionTransfers,
  createPositionTransfersRequestPromise,
} from '../../actions/accountsActions';
import positionTransferMetadata from '../../metadata/positionTransferMetadata';
import {
  fetchMembers,
  getMemberCollection,
} from '../../reducers/membersReducer';
import { FCM } from '../../constants/memberTypes';
import { CONFIRM_POSITION_TRANSFER } from '../../constants/modalTypes';
import { deleteRowIfNotChecked } from '../../utils/methods';
import { POSITION_TRANSFERS } from '../../constants/actionTypes';

const StyledXTable7 = styled(XTable7)`
  .riskTableX {
    display: block;
    max-width: 100%;
    table {
      td {
        overflow: visible !important;
      }
    }
  }
`;

let PositionTransfer = ({ fcmMemberId, fromAccount, cgm, change }) => {
  const dispatch = useDispatch();
  const accountsArray = useSelector(getAllAccountsArray);
  const accountsLoading = useSelector((state) =>
    createLoadingSelector(['ACCOUNTS_V2'])(state),
  );
  const fcmMembers = useSelector(getMemberCollection);
  const membersLoading = useSelector((state) =>
    createLoadingSelector(['MEMBERS'])(state),
  );
  const positionsTransfers = useSelector(getPositionsTransfers);
  const positionsTransfersTypeData = useSelector(getPositionsTransferTypes);
  const positionTransfersLoading = useSelector((state) =>
    createLoadingSelector([POSITION_TRANSFERS])(state),
  );

  const [tableState, setTableState] = useState([]);
  const [rowsSelected, setRowsSelected] = useState({});

  useEffect(() => {
    // Fetch Member to get the Firm Code
    dispatch(
      fetchMembers({ filter: [{ attr: 'type', value: FCM.type, op: 'eq' }] }),
    );
    // Fetch TransferTypes for the select option
    dispatch(positionTransferTypes());
  }, []);

  useEffect(() => {
    // On select the From Firm gets the related accounts to that FirmCode
    if (fcmMemberId?.length > 0) {
      // Fetch accounts
      dispatch(
        fetchAccountsV2({
          filter: [{ attr: 'member_id', value: fcmMemberId, op: 'eq' }],
          showUsers: true,
        }),
      );
    } else {
      if (positionsTransfers?.positions?.length > 0) {
        dispatch(clearPositionTransfers());
      }
      change('fcmMemberId', '');
      change('fromAccount', '');
    }
  }, [fcmMemberId]);

  useEffect(() => {
    // On select the From Account loads the table data
    if (fromAccount?.length > 0) {
      dispatch(positionTransfers({ account: fromAccount }));
    } else if (positionsTransfers?.positions?.length > 0) {
      dispatch(clearPositionTransfers());
    }
  }, [fromAccount]);

  // Handles change event for the selects that are inside the table metadata
  const handleChange = (row, value, target) => {
    setTableState({
      ...tableState,
      [row.index]: {
        ...tableState[row.index],
        [target]: value,
        index: row.index,
      },
    });
  };
  const handleMultipleChange = (row, valueTargetPair) => {
    const overlay = Object.fromEntries(valueTargetPair);
    setTableState({
      ...tableState,
      [row.index]: {
        ...tableState[row.index],
        index: row.index,
        ...overlay,
      },
    });
  };

  const toFirmSelected = useCallback(
    (firmValue) => {
      const existsFirmInRedux = !!accountsArray.find(
        (x) => x.firmCode === firmValue,
      );
      if (firmValue?.length > 0 && !existsFirmInRedux) {
        // Fetch FROM accounts
        dispatch(
          fetchAccountsV2({
            filter: [{ attr: 'member_id', value: firmValue, op: 'eq' }],
            showUsers: true,
          }),
        );
      }
    },
    [accountsArray, dispatch],
  );

  const fetchData = () => {};

  const submit = () => {
    dispatch(
      showModal(CONFIRM_POSITION_TRANSFER, {
        onCancel: () => {
          dispatch(hideModal(CONFIRM_POSITION_TRANSFER));
        },
        payload: {
          cgm,
          fromAccount,
          fcmMemberId,
          tableState,
          positionsTransfers: positionsTransfers?.positions,
        },
        size: 'large',
        className: 'fullScreen',
        onConfirm: async () => {
          try {
            await createPositionTransfersRequestPromise(
              {
                origin_cgm: cgm,
                origin_account: fromAccount,
                transfers: Object.values(tableState).map((x) => ({
                  destination_account: get(x, 'accountSelected', ''),
                  cgm: get(x, 'cgm', ''),
                  long_qty: get(x, 'longTransfer', ''),
                  short_qty: get(x, 'shortTransfer', ''),
                  type: get(x, 'transferTypeSelected', ''),
                  price: get(x, 'price'),
                  current_long_qty: get(
                    positionsTransfers,
                    `positions[${x.index}].currentLongQty`,
                    0.0,
                  ),
                  pending_long_qty: get(
                    positionsTransfers,
                    `positions[${x.index}].pendingLongQty`,
                    0.0,
                  ),
                  current_short_qty: get(
                    positionsTransfers,
                    `positions[${x.index}].currentShortQty`,
                    0.0,
                  ),
                  pending_short_qty: get(
                    positionsTransfers,
                    `positions[${x.index}].pendingShortQty`,
                    0.0,
                  ),
                  product_symbol: get(
                    positionsTransfers,
                    `positions[${x.index}].productSymbol`,
                    '',
                  ),
                  contract_symbol: get(
                    positionsTransfers,
                    `positions[${x.index}].contractSymbol`,
                    '',
                  ),
                })),
              },
              dispatch,
            );
            dispatch(hideModal());
          } catch (error) {
            dispatch(hideModal());
          }
        },
      }),
    );
  };

  const memberOptions = useMemo(
    () =>
      Object.values(fcmMembers).map((m) => ({
        key: m.memberId,
        value: m.memberId,
        text: m.firmCode,
      })),
    [fcmMembers],
  );

  const availablestToAccounts = useMemo(
    () => accountsArray.filter((x) => x.label !== fromAccount),
    [accountsArray, fromAccount],
  );

  const allSelectedRowsShouldBeComplete = useCallback(() => {
    const data = Object.entries(tableState);
    for (let i = 0; i < data.length; i++) {
      // CGM is optional
      if (rowsSelected[i.toString()]) {
        if (
          data[i][1]?.accountSelected?.length > 0 &&
          data[i][1]?.firmSelected?.length > 0 &&
          data[i][1]?.longTransfer !== null &&
          data[i][1]?.longTransfer !== undefined &&
          data[i][1]?.shortTransfer !== null &&
          data[i][1]?.shortTransfer !== undefined &&
          data[i][1]?.transferTypeSelected?.length > 0
        ) {
          if (
            data[i][1]?.transferTypeSelected === 'adp' ||
            data[i][1]?.transferTypeSelected === 'default_auction'
          ) {
            if (!isNil(data[i][1]?.price)) {
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    }
    // If at least one row is selected, return true, if not return false;
    return Object.values(rowsSelected).some((x) => x);
  }, [tableState]);

  const onChangeRowsSelected = (values) => {
    if (!isEqual(rowsSelected, values)) {
      // Delete row if is not checked
      const filterTable = deleteRowIfNotChecked(tableState, values);
      setTableState(filterTable);
      setRowsSelected(values);
    }
  };

  const disableSubmit =
    fcmMemberId &&
    fromAccount &&
    Object.entries(tableState).length > 0 &&
    allSelectedRowsShouldBeComplete();

  const fromAccountByFirm = useMemo(
    () => accountsArray.filter((x) => x.memberId === fcmMemberId),
    [accountsArray, fcmMemberId],
  );

  return (
    <Fragment>
      <Header as="h1">
        <Header.Content>Position Transfer</Header.Content>
      </Header>
      <Segment style={{ width: '100%' }}>
        <Header as="h3" dividing>
          Transfer Details
        </Header>
        <Grid columns="equal" className="flex" style={{ width: '75%' }}>
          <Grid.Row>
            <Grid.Column>
              <Field
                style={{ width: '100%', zIndex: '1001' }}
                component={renderDropdown}
                loading={membersLoading}
                id="fcmMemberId"
                label="From Firm"
                name="fcmMemberId"
                options={memberOptions}
                required
                search
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                style={{ width: '100%', zIndex: '1001' }}
                component={renderDropdown}
                loading={accountsLoading}
                disabled={!fromAccountByFirm}
                id="fromAccount"
                label="From Account"
                name="fromAccount"
                options={fromAccountByFirm.map((o) => ({
                  key: o.label,
                  text: o.label,
                  value: o.label,
                }))}
                required
                search
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                style={{ width: '100%', zIndex: '1001' }}
                label="CGM"
                id="cgm"
                name="cgm"
                component={renderField}
                labelPosition="right"
                required
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Header as="h3" dividing />
        <h3>To</h3>
        {memberOptions.length > 0 && fromAccount?.length > 0 ? (
          <StyledXTable7
            metadata={positionTransferMetadata(
              memberOptions,
              availablestToAccounts,
              positionsTransfersTypeData,
              tableState,
              handleChange,
              toFirmSelected,
              accountsLoading,
              handleMultipleChange,
            )}
            data={
              fromAccount?.length > 0 ? positionsTransfers?.positions || [] : []
            }
            fetchData={fetchData}
            showHeader
            showFilter={false}
            showPagination={false}
            height="60vh"
            showSelectRow
            count={positionsTransfers?.count}
            onChangeRowsSelected={onChangeRowsSelected}
            loading={positionTransfersLoading}
          />
        ) : (
          <StyledXTable7
            metadata={positionTransferMetadata([])}
            data={[]}
            fetchData={fetchData}
            showHeader
            showFilter={false}
            showPagination={false}
            selectInsideTD
            height="60vh"
            showSelectRow
            count={positionsTransfers?.count}
          />
        )}
        <Button
          className="add-button margin-top-10"
          id="submit"
          disabled={!disableSubmit}
          onClick={submit}
        >
          Submit
        </Button>
      </Segment>
    </Fragment>
  );
};

const selector = formValueSelector('position_transfer');

PositionTransfer = connect((state) => ({
  fcmMemberId: selector(state, 'fcmMemberId'),
  fromAccount: selector(state, 'fromAccount'),
  cgm: selector(state, 'cgm'),
}))(PositionTransfer);

export default reduxForm({
  form: 'position_transfer',
})(PositionTransfer);
