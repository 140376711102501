import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Form } from 'semantic-ui-react';
import {
  renderDropdown,
  showModal,
  hideModal,
  createLoadingSelector,
} from 'erisxkit/client';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import XTable7 from '../common/table/XTable7';
import fcmReportsMetadata from '../metadata/fcmReportsMetadata';
import { PARSED_REPORT } from '../constants/modalTypes';
import usePaginatedFetch from '../hooks/usePaginatedFetch';
import { fetchMembers, getMemberCollection } from '../reducers/membersReducer';
import { FCM } from '../constants/memberTypes';
import {
  fetchFcmReports,
  getAllFcmReports,
} from '../reducers/fcmReportsReducer';
import downloadXML from '../utils/downloadXML';

const Reports = () => {
  const dispatch = useDispatch();
  const fcmMembers = useSelector(getMemberCollection);
  const fcmReports = useSelector(getAllFcmReports);
  const loading = useSelector((state) =>
    createLoadingSelector(['FCM_FILES'])(state),
  );
  const loadingMembers = useSelector((state) =>
    createLoadingSelector(['MEMBERS'])(state),
  );

  const reportCount = useMemo(() => fcmReports.length, [fcmReports]);
  const memberOptions = useMemo(
    () =>
      Object.values(fcmMembers).map((m) => ({
        key: m.memberId,
        text: m.name,
        value: m.memberId,
      })),
    [fcmMembers],
  );

  const onOpenParsed = (report) => {
    dispatch(
      showModal(PARSED_REPORT, {
        report,
        onClose: () => dispatch(hideModal(PARSED_REPORT)),
        size: 'large',
      }),
    );
  };

  const fetchReports = usePaginatedFetch(fetchFcmReports, {
    // Makes it so it returns the raw base64 string for the reports
    return_raw: true,
  });

  useEffect(() => {
    dispatch(
      fetchMembers({ filter: [{ attr: 'type', value: FCM.type, op: 'eq' }] }),
    );
  }, []);

  return (
    <div className="vert-flex">
      <Header dividing floated="left" as="h1">
        Inbound Files
      </Header>
      <XTable7
        title="reports-table"
        metadata={fcmReportsMetadata(onOpenParsed, downloadXML, memberOptions)}
        data={fcmReports}
        fetchData={fetchReports}
        loading={loading}
        count={reportCount}
        flex
      />
    </div>
  );
};

export default reduxForm({
  form: 'reports',
})(Reports);
