import React, { Fragment } from 'react';
import { Form, Button, Icon, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  renderField,
  renderDropdown,
  rules,
  fundsDesignation,
} from 'erisxkit/client';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';

const GeneralJournal = ({
  activeLedgerId,
  assetTypes,
  assetTypesLoading,
  categories,
  categoriesLoading,
  fields,
  valid,
}) => (
  <Fragment>
    <Form loading={assetTypesLoading}>
      {fields.map((field, index) => (
        <Form.Group
          key={`${field}-index`}
          widths="equal"
          className="flex-row space-between"
        >
          <Field
            component={AccountSelectionContainer}
            id="accountId"
            label={index === 0 ? 'Account' : ''}
            name={`${field}.accountId`}
            required={index === 0}
            validate={[rules.required]}
            placeholder="Select account..."
          />
          <Field
            loading={assetTypesLoading}
            required={index === 0}
            label={index === 0 ? 'Asset Type' : ''}
            search
            id="assetType"
            name={`${field}.assetType`}
            placeholder="Select asset type..."
            validate={[rules.required]}
            options={assetTypes.map((asset) => ({
              key: asset.symbol,
              value: asset.symbol,
              text: asset.description,
              description: asset.symbol,
            }))}
            component={renderDropdown}
          />
          <Field
            placeholder="ex. Segregated..."
            id="fundsDesignation"
            name={`${field}.fundsDesignation`}
            label={index === 0 ? 'Funds Designation' : ''}
            component={renderDropdown}
            options={fundsDesignation.map((designation) => ({
              key: designation.value,
              text: designation.text,
              value: designation.value,
              description: designation.value,
            }))}
            required={index === 0}
            validate={[rules.required]}
          />
          <Field
            id="category"
            label={index === 0 ? 'Category' : ''}
            required={index === 0}
            component={renderDropdown}
            search
            name={`${field}.category`}
            placeholder="Select category..."
            options={categories}
            validate={[rules.required]}
          />
          <Field
            required={index === 0}
            label={index === 0 ? 'Amount' : ''}
            name={`${field}.amount`}
            id="amount"
            placeholder="Journal amount"
            component={renderField}
            validate={[rules.required]}
          />
          <Button
            small
            basic
            icon
            negative
            type="button"
            onClick={() => fields.remove(index)}
            style={{
              height: '36px',
              marginTop: `${index === 0 ? '25px' : '5px'}`,
            }}
          >
            <Icon name="remove" />
          </Button>
        </Form.Group>
      ))}
      <Button.Group className="pull-right" basic positive>
        <Button
          type="button"
          id="add-posting"
          disabled={!valid}
          onClick={() => {
            fields.push({});
          }}
        >
          <Icon name="add" /> Add Posting
        </Button>
        <Dropdown
          disabled={!valid}
          id="add-opposing-posting"
          className="button icon"
          floating
          options={[
            {
              key: 'opposing',
              icon: 'edit',
              text: 'Add Opposing Posting',
              value: 'opposing',
              onClick: () => {
                const prev = fields.get(fields.length - 1);
                const { assetType, amount, fundsDesignation } = prev;
                fields.push({
                  assetType,
                  amount: -amount,
                  fundsDesignation,
                });
              },
            },
          ]}
          trigger={<React.Fragment />}
        />
      </Button.Group>
    </Form>
  </Fragment>
);

GeneralJournal.propTypes = {
  assetTypes: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      description: PropTypes.string,
      isFiat: PropTypes.bool,
    }),
  ),
};

GeneralJournal.defaultProps = {
  assetTypes: [],
};

export default GeneralJournal;
