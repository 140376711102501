import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Checkbox, Form, Label } from 'semantic-ui-react';
import {
  normalization,
  renderField,
  rules,
  renderCheckbox,
} from 'erisxkit/client';
import Big from 'bignumber.js';
import { formatDecimals } from '../../common/forms/normalization';

const AccountOverride = ({
  change,
  depositFee,
  withdrawalFee,
  maxDeposit,
  minDeposit,
  depositPeriod,
  embargoPeriod,
  achDefaults,
}) => {
  const onUseDefault = (checked, field, value) => {
    if (checked) {
      change(field, value);
    } else {
      change(field, '');
    }
  };

  const isDefault = (value) => value === '-1' || value === '-1.0';

  return (
    <Form className="member-settings">
      <Form.Group>
        <Field
          label="ACH Deposit Fee"
          id="depositFee"
          name="depositFee"
          component={renderField}
          labelPosition="right"
          validate={[rules.required]}
          parse={formatDecimals}
        >
          <input
            disabled={isDefault(depositFee)}
            value={isDefault(depositFee) ? achDefaults.depositFee : depositFee}
          />
          <Label>USD</Label>
          <Checkbox
            checked={isDefault(depositFee)}
            onChange={(e, { checked }) =>
              onUseDefault(checked, 'depositFee', '-1.0')
            }
            label={
              <label htmlFor="depositFee">
                Use Global ACH Deposit Fee:
                <span className="bold">
                  {' '}
                  {Big(achDefaults.depositFee).toFixed(2)} USD
                </span>
              </label>
            }
          />
        </Field>
        <Field
          label="ACH Withdrawal Fee"
          id="withdrawalFee"
          name="withdrawalFee"
          component={renderField}
          labelPosition="right"
          validate={[rules.required]}
          parse={formatDecimals}
        >
          <input
            disabled={isDefault(withdrawalFee)}
            value={
              isDefault(withdrawalFee)
                ? achDefaults.withdrawalFee
                : withdrawalFee
            }
          />
          <Label>USD</Label>
          <Checkbox
            checked={isDefault(withdrawalFee)}
            onChange={(e, { checked }) =>
              onUseDefault(checked, 'withdrawalFee', '-1.0')
            }
            label={
              <label htmlFor="withdrawalfee">
                Use Global ACH Withdrawal Fee:
                <span className="bold">
                  {' '}
                  {Big(achDefaults.withdrawalFee).toFixed(2)} USD
                </span>
              </label>
            }
          />
        </Field>
        <Field
          label="Max. Deposit"
          id="maxDeposit"
          name="maxDeposit"
          component={renderField}
          labelPosition="right"
          validate={[rules.required]}
          parse={formatDecimals}
        >
          <input
            disabled={isDefault(maxDeposit)}
            value={isDefault(maxDeposit) ? achDefaults.maxDeposit : maxDeposit}
          />
          <Label>USD</Label>
          <Checkbox
            checked={isDefault(maxDeposit)}
            onChange={(e, { checked }) =>
              onUseDefault(checked, 'maxDeposit', '-1.0')
            }
            label={
              <label htmlFor="maxDeposit">
                Use Global Max. Deposit:
                <span className="bold">
                  {' '}
                  {Big(achDefaults.maxDeposit).toFixed(2)} USD
                </span>
              </label>
            }
          />
        </Field>
        <Field
          label="ACH Deposit Period"
          id="depositPeriod"
          name="depositPeriod"
          component={renderField}
          labelPosition="right"
          validate={[rules.required]}
          parse={normalization.numbersOnly}
        >
          <input
            disabled={isDefault(depositPeriod)}
            value={
              isDefault(depositPeriod)
                ? achDefaults.depositPeriod
                : depositPeriod
            }
          />
          <Label>Days</Label>
          <Checkbox
            checked={isDefault(depositPeriod)}
            onChange={(e, { checked }) =>
              onUseDefault(checked, 'depositPeriod', '-1')
            }
            label={
              <label htmlFor="depositPeriod">
                Use Global ACH Deposit Period:
                <span className="bold"> {achDefaults.depositPeriod} Days</span>
              </label>
            }
          />
        </Field>
      </Form.Group>
      <Form.Group>
        <Field
          label="ACH Embargo Period"
          id="embargoPeriod"
          name="embargoPeriod"
          component={renderField}
          labelPosition="right"
          validate={[rules.required]}
          parse={normalization.numbersOnly}
        >
          <input
            disabled={isDefault(embargoPeriod)}
            value={
              isDefault(embargoPeriod)
                ? achDefaults.embargoPeriod
                : embargoPeriod
            }
          />
          <Label>Days</Label>
          <Checkbox
            checked={isDefault(embargoPeriod)}
            onChange={(e, { checked }) =>
              onUseDefault(checked, 'embargoPeriod', '-1')
            }
            label={
              <label htmlFor="embargoPeriod">
                Use Global ACH Deposit Embargo:
                <span className="bold"> {achDefaults.embargoPeriod} Days</span>
              </label>
            }
          />
        </Field>
        <Field
          label="Min. Deposit"
          id="minDeposit"
          name="minDeposit"
          component={renderField}
          labelPosition="right"
          validate={[rules.required]}
          parse={formatDecimals}
        >
          <input
            disabled={isDefault(minDeposit)}
            value={isDefault(minDeposit) ? achDefaults.minDeposit : minDeposit}
          />
          <Label>USD</Label>
          <Checkbox
            checked={isDefault(minDeposit)}
            onChange={(e, { checked }) =>
              onUseDefault(checked, 'minDeposit', '-1.0')
            }
            label={
              <label htmlFor="minDeposit">
                Use Global Min. Deposit:
                <span className="bold">
                  {' '}
                  {Big(achDefaults.minDeposit).toFixed(2)} USD
                </span>
              </label>
            }
          />
        </Field>
        <div className="instant-ach-enabled">
          <Field
            component={renderCheckbox}
            name="instantAchEnabled"
            label="Instant ACH Enabled"
            type="checkbox"
          />
        </div>
      </Form.Group>
    </Form>
  );
};

AccountOverride.propTypes = {
  achDefaults: PropTypes.objectOf(PropTypes.any),
  change: PropTypes.func.isRequired,
  depositFee: PropTypes.string,
  withdrawalFee: PropTypes.string,
  maxDeposit: PropTypes.string,
  depositPeriod: PropTypes.string,
  embargoPeriod: PropTypes.string,
};

AccountOverride.defaultProps = {
  achDefaults: {},
  depositFee: '0',
  withdrawalFee: '0',
  maxDeposit: '0',
  depositPeriod: '0',
  embargoPeriod: '0',
};

export default AccountOverride;
