import React from 'react';
import { compact } from 'lodash';

export const fcmMemberMetadata = () =>
  compact([
    {
      // Build our expander column
      id: 'expander', // Make sure it has an ID
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}></span>
      ),
      hideFilter: true,
      Cell: ({ row }) => {
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        return row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
                color: row.depth ? 'SlateGrey' : 'black',
              },
              className: 'expandable',
            })}
          >
            {row.isExpanded ? '▼' : '►'}
          </span>
        ) : null;
      },
    },
    {
      Header: 'FCM Name',
      accessor: 'fcmName',
    },
    {
      Header: 'CGM Name',
      accessor: 'cgmName',
    },
    {
      Header: 'CGM Type',
      accessor: 'cgmType',
    },
    {
      Header: 'CGM Description',
      accessor: 'cgmDescription',
    },
    {
      Header: 'CAR Name',
      accessor: 'carName',
    },
  ]);
