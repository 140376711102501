import { get } from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Step } from 'semantic-ui-react';

class XWizard extends PureComponent {
  state = {
    page: this.props.step,
  };

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  };

  // WizardSteps contained within component such that it has access to setState
  // allowClick defaults to true, but can be replaced with a conditional or just a boolean,
  //  e.g. to prevent clicking ahead or to force 1-way linearity
  WizardSteps = ({ steps, page, allowClick = true, horizontal, ...rest }) => (
    <Step.Group vertical={!horizontal} className="xwizard-nav">
      {steps.map((step, index) =>
        !steps[index].props.hide ? (
          <Step
            completed={page > index}
            active={page === index}
            key={index}
            onClick={() =>
              allowClick && page !== index
                ? this.setState({ page: index })
                : null
            }
            disabled={steps[index].props.disabled || !allowClick}
            {...rest}
          >
            <Step.Content>
              <Step.Title>{steps[index].props.title}</Step.Title>
              <Step.Description>
                {steps[index].props.description}
              </Step.Description>
            </Step.Content>
          </Step>
        ) : null,
      )}
    </Step.Group>
  );

  render = () => {
    const { page } = this.state;
    let { children = [], onCancel, horizontal } = this.props;
    children = React.Children.toArray(children).filter(
      (each) => !each.props.hide,
    );
    const childPage = get(children, [page], '');
    const childProps = get(childPage, 'props', {});

    return (
      <div className={`flex xwizard-main${horizontal ? ' horizontal' : ''}`}>
        {this.WizardSteps({
          steps: children,
          page,
          horizontal,
          ...childProps,
        })}
        <div className="xwizard-content">
          {childPage
            ? React.cloneElement(childPage, {
                onSubmit: this.nextPage,
                previousPage: this.previousPage,
              })
            : ''}
          <div className="xwizard-footer">
            <div>
              <Button
                id="xwizard-cancel-button"
                onClick={onCancel}
                content="Cancel"
              />
              <Button
                id="xwizard-prev-button"
                disabled={this.state.page === 0}
                onClick={this.previousPage}
                content="Previous"
              />
            </div>
            <div>
              <Button
                id="xwizard-next-button"
                primary
                onClick={this.nextPage}
                disabled={this.state.page + 1 === children.length}
                {...childProps.nextProps}
                content="Next"
              />
              {!get(childProps, ['saveProps', 'hide'], false) && (
                <Button
                  id="xwizard-save-button"
                  primary
                  content="Save"
                  {...childProps.saveProps}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

XWizard.propTypes = {
  children: PropTypes.objectOf(PropTypes.node).isRequired,
  onCancel: PropTypes.func,
  step: PropTypes.number,
};

XWizard.defaultProps = {
  onCancel: undefined,
  step: 0,
};

export default XWizard;
