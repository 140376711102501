export const TABLE_INPUT_TYPES = {
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  DATE: 'date',
  LIST: 'list',
};

export const TABLE_EDIT_ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

export const TABLE_FILTER_OP = {
  Contains: 'contain',
  Equals: 'eq',
  NotEquals: 'ne',
  LessThanOrEq: 'lte',
  GreaterThanOrEq: 'gte',
  GreaterThan: 'gt',
  LessThan: 'lt',
  Exists: 'exists',
  Match: 'match',
};
