import {
  sortedArrayToAttrValue,
  filteredArrayToAttrFalsyValue,
} from 'erisxkit/client';
import { useDispatch } from 'react-redux';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import snakeCase from 'lodash/snakeCase';
import moment from 'moment';
import { TABLE_FILTER_OP } from '../common/table/constants';

const parseEpoch = (epochValue) => {
  if (!epochValue) return;
  return epochValue.toString().length > 10 ? epochValue / 1000 : epochValue;
};

const snakeCaseAttrs = (filterList = []) =>
  filterList.map((filter) => {
    const attr = get(filter, 'attr', null);
    if (attr) return { ...filter, attr: snakeCase(attr) };
    return filter;
  });

const DEFAULT_FILTER_OP = TABLE_FILTER_OP.Equals;

const getColumnFilterOp = (columnId, opByColumnIdMap) =>
  get(opByColumnIdMap, columnId, DEFAULT_FILTER_OP);

/**
    Hook returns a function that automatically implements XTable7's pagination, filtering and sorting
    * @param {function} callback - Callback to use onFetch.
        Can be a saga routine. No need to pass function mapped to dispatch.
    * @param {object} args - Object with additional arguments use when calling <callback>
    * @param {object} opts - Object used to set a default filter / sorting. Can also include a opByColumnId object that maps a filter name to an operation.
*/
const usePaginatedFetch = (
  callback,
  params = {},
  opts = { sort: [], filter: [], opByColumnId: {} },
) => {
  const dispatch = useDispatch();
  const paginatedCallback = ({
    pageSize = 10,
    pageIndex = 0,
    filters = [],
    sortBy = [],
    args = {},
  } = {}) => {
    let { sort = [], filter = [] } = opts;
    const { opByColumnId = {} } = opts;
    if (filters && filters.length !== 0) {
      filter = filter.concat(
        filteredArrayToAttrFalsyValue(
          filters.map((filter) => ({
            ...filter,
            op: getColumnFilterOp(filter?.id, opByColumnId),
          })),
        ),
      );
    }
    const filterWithMaxMin = filter.reduce((acc, curr) => {
      if (isArray(curr.value)) {
        return acc.concat([
          ...(curr.value[0]
            ? [
                {
                  ...curr,
                  op: 'gte',
                  value: `${moment.unix(parseEpoch(curr.value[0]))}`,
                },
              ]
            : []),
          ...(curr.value[1]
            ? [
                {
                  ...curr,
                  op: 'lte',
                  value: `${moment.unix(parseEpoch(curr.value[1]))}`,
                },
              ]
            : []),
        ]);
      }
      return acc.concat([curr]);
    }, []);

    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }

    dispatch(
      callback({
        limit: pageSize,
        offset: pageIndex * pageSize,
        page: pageIndex,
        filter: snakeCaseAttrs(filterWithMaxMin),
        sort,
        ...params,
        ...args,
      }),
    );
  };
  return paginatedCallback;
};

export default usePaginatedFetch;
