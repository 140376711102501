import { format } from 'erisxkit/client';
import ReactTable from 'react-table-6';
import React from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import get from 'lodash/get';
import { formatUSD } from '../utils/methods';

const assetBalances = (showCurrenexBalances, cdfEnabled = false) => [
  {
    Header: 'Account Balances',
    columns: [
      {
        Header: 'Account Label',
        expander: true,
        resizable: true,
        Expander: (row) => {
          // Only show row expander when the row has _subRows
          if (get(row, ['original', '_subRows', 'length'], 0)) {
            return (
              <div className={`rt-cell-${row.level}`}>
                <ReactTable.defaultProps.ExpanderComponent {...row} />
                <NavLink
                  target="_blank"
                  className="mono"
                  to={`/balances/${get(row, ['original', 'accountId'])}#requests`}
                >
                  {get(row, ['original', 'accountLabel'])}
                </NavLink>
              </div>
            );
          }
          return (
            <div className={`rt-cell-${row.level}`}>
              <NavLink
                target="_blank"
                className="mono"
                to={`/balances/${get(row, ['original', 'accountId'])}#requests`}
              >
                {get(row, ['original', 'accountLabel'])}
              </NavLink>
            </div>
          );
        },
      },
      {
        Header: 'Asset (FD)',
        id: 'asset',
        accessor: (row) =>
          row.symbol ? `${row.symbol.toUpperCase()} (${row.fd})` : '',
        className: 'text-right',
        headerClassName: 'text-right',
      },

      {
        Header: 'Opening',
        accessor: (row) => format(row.openingBalance) || 0,
        id: 'openingBalance',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Spot Movements',
        accessor: (row) => row.spotMovement || 0,
        id: 'spotMovement',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Ex Fees',
        accessor: (row) => format(row.exchangeFees) || 0,
        id: 'exchangeFees',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Cl Fees',
        accessor: (row) => format(row.clearingFees) || 0,
        id: 'clearingFees',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Other Fees',
        accessor: (row) => format(row.otherFees) || 0,
        id: 'otherFees',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Asset Movements',
        accessor: (row) => row.assetMovement || 0,
        id: 'assetMovement',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Realized P&L',
        accessor: (row) => row.realizedPAndL || 0,
        id: 'realizedPAndL',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Futures Delivery',
        accessor: (row) => row.futuresDelivery || 0,
        id: 'futuresDelivery',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Variation Margin',
        accessor: (row) => format(row.variationMargin) || 0,
        id: 'variationMargin',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      ...(cdfEnabled
        ? [
            {
              Header: 'Funding Amount',
              accessor: (row) => format(row.fundingAmount) || '-',
              id: 'fundingAmount',
              className: 'text-right mono',
              headerClassName: 'text-right',
            },
          ]
        : []),
      {
        Header: 'Closing',
        accessor: (row) => format(row.closingBalance) || 0,
        id: 'closingBalance',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Total Equity',
        accessor: (row) => format(row.totalEquity) || 0,
        id: 'totalEquity',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'TME Balance',
        accessor: (row) => format(row.currenexBalance) || 0,
        id: 'tmeBalance',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'TME Available Balance',
        accessor: (row) => format(row.purchasingPower) || 0,
        id: 'purchasingPower',
        className: 'text-right mono',
        headerClassName: 'text-right',
        show: showCurrenexBalances,
      },
      {
        Header: 'Initial Margin',
        accessor: (row) => format(row.initialMargin) || 0,
        id: 'initialMargin',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Maintenance Margin',
        accessor: (row) => format(row.maintenanceMargin) || 0,
        id: 'maintenanceMargin',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Pending',
        accessor: (row) => format(row.pendingBalance) || '',
        id: 'pending',
        className: 'text-right mono',
        headerClassName: 'text-right',
      },
      {
        Header: 'Total USD value',
        accessor: (row) => (row?.usdValue ? formatUSD(row.usdValue) : '-'),
        id: 'usdValue',
        className: 'text-right mono',
        headerClassName: 'text-right',
        width: 160,
      },
      {
        Header: 'Total Excess/Deficit',
        accessor: (row) => format(row.totalExcessDeficit) || 0,
        id: 'totalExcessDeficit',
        className: 'text-right mono',
        headerClassName: 'text-right',
        width: 160,
      },
      {
        Header: 'Settlement Balance',
        accessor: (row) => {
          const settlementBalance = get(row, [
            'settlementDetail',
            'settlementBalance',
          ]);
          return settlementBalance || '-';
        },
        id: 'settlementBalance',
        className: 'text-right mono',
        headerClassName: 'text-right',
        width: 150,
      },
      {
        Header: 'Settlement Time',
        accessor: (row) => {
          console.log('row', row);
          const settlementTime = get(row, [
            'settlementDetail',
            'settlementTime',
          ]);
          return settlementTime
            ? moment(settlementTime).format('YYYY-MM-DD HH:mm')
            : '-';
        },
        id: 'settlementTime',
        className: 'text-right mono',
        headerClassName: 'text-right',
        width: 190,
      },
    ],
  },
];

export default assetBalances;
