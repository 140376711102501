import _ from 'lodash';
import { connect } from 'react-redux';
import { XTable, createLoadingSelector, showModal } from 'erisxkit/client';
import React, { Fragment, Component } from 'react';
import { Header } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import moment from 'moment';
import { titleCase } from 'change-case';
import AccountHistoryContainer from './AccountHistoryContainer';
import {
  getSelectedAccount,
  getSelectedAccountId,
} from '../../reducers/accountHistoryReducer';
import {
  fetchDesignatedBalanceDetails,
  fetchCurrenexBalances,
  fetchBalances,
} from '../../actions/accountsActions';
import CollateralPositionsContainer from './CollateralPositionsContainer';
import FuturesPositionsContainer from '../Balances/FuturesPositionsContainer';
import MemberUserIndexContainer from '../MemberUsers/MemberUserIndexContainer';
import {
  fetchMemberUserTypes,
  getMemberUserTypes,
} from '../../reducers/usersReducer';
import { ADD_REMOVE_MEMBERS } from '../../constants/modalTypes';
import XTab from '../../common/tabs/XTab';
import assetBalancesMetadata from '../../metadata/assetBalancesMetadata';
import BackButton from '../../common/components/BackButton';
import { fetchMembers } from '../../reducers/membersReducer';
import DetailsHeader from '../../common/components/DetailsHeader';
import {
  getActiveBalanceDetails,
  fetchMovements,
  fetchTradeJournals,
} from '../../reducers/balancesReducer';
import {
  fetchAccountsV2,
  getCurrenexBalancesWithDetails,
} from '../../reducers/accountsReducer';
import CloseoutJournalsContainer from '../Transactions/CloseoutJournalsContainer';
import {
  BANK,
  CUSTODIAN,
  WALLET,
  HOUSE,
  MASTER,
  PARENT,
} from '../../constants/accountTypes';
import { assetTypes } from '../../actions/utilActions';
import { DESIGNATED_BALANCE_DETAILS } from '../../constants/actionTypes';
import GenericFilteredTable from '../../common/containers/GenericFilteredTable';
import movementsMetadata from '../../metadata/movementsMetadata';
import tradeLogMetadata from '../../metadata/tradeLogMetadata';
import { isMasterAccount, isParentAccount } from '../../utils/methods';
import CreateClearingAccount from '../CreateAccountModalContainer';
import ACHContainer from '../Transactions/ACHTransactionsContainer';
import AccountOverrideContainer from './AccountOverrideContainer';
import { fetchIraCustodians, getProviders } from '../../reducers/iraReducer';
import useCdfEnabled from '../../hooks/useIsFeatureFlagEnabled/useCdfEnabled';

const getTypedMemberUsers = (memberUsers) => {
  if (memberUsers && Array.isArray(memberUsers)) {
    // if the user ids is an array of objects, convert it to an array of strings.
    // this is needed because accountV2 call and accounts call don't have the same shape for member_users
    return memberUsers.map((id) => {
      if (typeof id === 'string') {
        return id;
      }
      if (typeof id === 'object') {
        return _.get(id, 'userId', '');
      }
    });
  }
  return memberUsers;
};

const panes = (addMember, subject) => [
  {
    url: 'requests',
    menuItem: 'Requests',
    render: ({ selectedAccount }) => (
      <AccountHistoryContainer selectedAccount={selectedAccount} />
    ),
    show: subject === 'balances',
  },
  {
    url: 'movements',
    menuItem: 'Movements',
    render: ({ selectedAccount, date }) => (
      <GenericFilteredTable
        slice={['balances', 'movements']}
        routine={fetchMovements}
        key="movements"
        title="movements"
        metadata={movementsMetadata}
        filterArgs={[
          { attr: 'account_id', op: 'eq', value: selectedAccount.accountId },
        ]}
        resetOnUnmount
      />
    ),
    show: subject === 'balances',
  },
  {
    url: 'spot-trades',
    menuItem: 'Spot Trades',
    render: ({ selectedAccount }) => (
      <GenericFilteredTable
        slice={['balances', 'tradeJournals']}
        routine={fetchTradeJournals}
        key="spot_trades"
        title="spot_trades"
        metadata={tradeLogMetadata('', true)}
        filterArgs={[
          { attr: 'trade_type', op: 'eq', value: 'spot' },
          { attr: 'account_id', op: 'eq', value: selectedAccount.accountId },
        ]}
        resetOnUnmount
      />
    ),
    show: subject === 'balances',
  },
  {
    url: 'futures-trades',
    menuItem: 'Futures Trades',
    render: ({ selectedAccount }) => (
      <GenericFilteredTable
        slice={['balances', 'tradeJournals']}
        routine={fetchTradeJournals}
        key="futures_trades"
        title="futures_trades"
        metadata={tradeLogMetadata('', true, true)}
        filterArgs={[
          {
            attr: 'trade_type',
            op: 'eq',
            value: ['futures', 'delivery', 'fcm_delivery'],
          },
          { attr: 'account_id', op: 'eq', value: selectedAccount.accountId },
        ]}
        resetOnUnmount
      />
    ),
    show: subject === 'balances',
  },
  {
    url: 'collateral',
    menuItem: 'Collateral Positions',
    render: ({ selectedAccount }) => (
      <CollateralPositionsContainer selectedAccount={selectedAccount} />
    ),
    show: subject === 'balances',
  },
  {
    url: 'futures',
    menuItem: 'Open Positions',
    render: ({ selectedAccount }) => (
      <FuturesPositionsContainer selectedAccount={selectedAccount} />
    ),
    show: subject === 'balances',
  },
  {
    url: 'closeouts',
    menuItem: 'Closeouts',
    render: ({ selectedAccount }) => (
      <CloseoutJournalsContainer
        selectedAccount={selectedAccount}
        showAccountCol={false}
      />
    ),
    show: subject === 'balances',
  },
  {
    url: 'ach',
    menuItem: 'ACH',
    render: ({ selectedAccount }) => (
      <ACHContainer selectedAccount={selectedAccount} showAccountCol={false} />
    ),
    show: subject === 'balances',
  },
  {
    url: 'users',
    menuItem: 'Users',
    render: ({ selectedAccount }) => (
      <MemberUserIndexContainer
        userIds={getTypedMemberUsers(selectedAccount.memberUsers) || []}
        addMember={addMember}
        selectedAccount={selectedAccount}
      />
    ),
    show: subject === 'static',
  },
  {
    url: 'settings',
    menuItem: 'Settings',
    render: ({ selectedAccount }) => (
      <CreateClearingAccount
        selectedAccount={selectedAccount}
        update
        type="clearing"
      />
    ),
    show: subject === 'static',
  },
  {
    url: 'ACH Override',
    menuItem: 'ACH Override',
    render: ({ selectedAccount }) => (
      <AccountOverrideContainer selectedAccount={selectedAccount} />
    ),
    show: subject === 'static',
  },
];

const mapStateToProps = (state, props) => ({
  selectedAccountId: getSelectedAccountId(state),
  selectedAccount: getSelectedAccount(state, props),
  balanceDetails: getActiveBalanceDetails(state),
  currenexAccountBalances: getCurrenexBalancesWithDetails(state),
  balanceDetailsLoading: createLoadingSelector([DESIGNATED_BALANCE_DETAILS])(
    state,
  ),
  iraProviders: getProviders(state),
  memberUserTypes: getMemberUserTypes(state),
});

const mapDispatchToProps = {
  fetchCurrenexBalances,
  fetchAccountsV2,
  showModal,
  fetchMembers,
  fetchDesignatedBalanceDetails,
  fetchBalances,
  fetchIraCustodians,
  fetchMemberUserTypes,
  assetTypes,
};
class ClearingMemberAccountDetails extends Component {
  state = {
    date: moment().format('YYYY-MM-DD'),
    expanded: [],
  };
  componentDidMount = () => {
    // fetch the data
    const {
      fetchAccountsV2,
      selectedAccountId,
      selectedAccount,
      fetchCurrenexBalances,
    } = this.props;
    const cxAccountId = _.get(selectedAccount, 'emarketAccountNumber', '');

    fetchCurrenexBalances({ cxAccountId });
    this.props.assetTypes();
    fetchAccountsV2({
      filter: [{ attr: 'account_id', value: selectedAccountId, op: 'eq' }],
      showUsers: true,
    });
    selectedAccount.memberId &&
      this.props.fetchMembers({
        filter: [{ attr: 'id', op: 'eq', value: selectedAccount.memberId }],
      });
    this.props.fetchBalances({
      date: this.state.date,
      accountId: selectedAccountId,
    });
    this.props.fetchIraCustodians();
  };

  componentWillReceiveProps = (nextProps) => {
    if (!_.isEqual(this.props.selectedAccount, nextProps.selectedAccount)) {
      const { selectedAccount = {} } = nextProps;
      selectedAccount.memberId &&
        this.props.fetchMembers({
          filter: [{ attr: 'id', op: 'eq', value: selectedAccount.memberId }],
        });
    }

    const { fetchCurrenexBalances } = this.props;

    const cxAccountId = _.get(this.props, [
      'selectedAccount',
      'emarketAccountNumber',
    ]);
    const nextCxAccountId = _.get(
      nextProps,
      ['selectedAccount', 'emarketAccountNumber'],
      null,
    );
    if (nextCxAccountId && cxAccountId !== nextCxAccountId) {
      fetchCurrenexBalances({ cxAccountId: nextCxAccountId });
    }
  };

  addMember = () => {
    this.props.showModal(ADD_REMOVE_MEMBERS, { className: 'show-overflow' });
  };

  showCurrenexBalances = () => {
    const categories = _.get(this.props.selectedAccount, 'categories', []);
    return !(
      categories.includes(BANK) ||
      categories.includes(CUSTODIAN) ||
      categories.includes(WALLET) ||
      categories.includes(HOUSE)
    );
  };

  dateChanged = (date) => {
    const { selectedAccountId } = this.props;
    let newDate = date;
    if (moment.isMoment(date)) {
      newDate = date.format('YYYY-MM-DD');
    }
    this.setState(
      {
        date: newDate,
      },
      () => {
        this.props.fetchBalances({
          date: this.state.date,
          accountId: selectedAccountId,
        });
      },
    );
  };

  selectDate = () => {
    const props = {
      className: 'ui input datetime',
      label: 'Balances for Date:',
      name: 'date',
      id: 'date',
      value: this.state.date,
      dateFormat: 'YYYY-MM-DD',
      timeFormat: false,
      inputProps: { placeholder: 'Report Date' },
      closeOnSelect: true,
      onChange: this.dateChanged,
      style: {
        marginLeft: '-65px',
        marginTop: '45px',
      },
    };
    return (
      <div className="flex-column">
        <div className="flex-row" style={{ marginRight: '55px' }}>
          {' '}
          <Header id="balances-date-picker">Balances for Date:</Header>
          <Datetime {...props} />
        </div>
        <small>
          {' '}
          Includes trades before 4pm. CST and movements before 6pm CST of date.{' '}
        </small>
      </div>
    );
  };

  render = () => {
    const {
      match = {},
      selectedAccount,
      currenexAccountBalances,
      balanceDetailsLoading,
      cdfEnabled,
    } = this.props;

    const subject = _.get(match, 'path', '').includes('balances')
      ? 'balances'
      : 'static';
    const selectedProvider = this.props.iraProviders?.iraCustodians?.find(
      (iraCustodian) => iraCustodian.id === selectedAccount.iraCustodianId,
    );

    const itemDetails = {
      contactName: selectedAccount.name,
      firmCode: selectedAccount.firmCode,
      accountCode: selectedAccount.accountCode,
      subAccountCode: selectedAccount.subAccountCode,
      memberId: selectedAccount.memberId,
      emarketAccountId: selectedAccount.emarketAccountNumber,
      iraProviderName: selectedProvider?.name,
      iraAccountType: selectedAccount.iraAcctType,
    };
    const labels = {
      futures: selectedAccount.allowFutures,
      spot: selectedAccount.allowSpot,
      collateral: selectedAccount.allowCollateral,
    };
    const detailsProps = {
      item: itemDetails,
      labels,
      ...(subject === 'balances' && {
        Component: (
          <XTable
            className="-striped -highlight"
            title="assetBalances"
            showPageSizeOptions={false}
            showPagination={false}
            data={currenexAccountBalances}
            columns={assetBalancesMetadata(
              this.showCurrenexBalances(),
              cdfEnabled,
            )}
            minRows={3}
            noDataText="There are no balances for this account."
            loading={balanceDetailsLoading}
          />
        ),
      }),
    };

    return (
      <Fragment>
        <div className="flex-row space-between">
          <BackButton
            header={selectedAccount.label}
            renderHeader={
              <span>
                {selectedAccount.label}{' '}
                <span className="grey">{selectedAccount.name}</span>
              </span>
            }
            headerLabels={[
              selectedAccount.accountType && {
                content: titleCase(selectedAccount.accountType),
                tag: true,
                size: 'small',
              },
              isMasterAccount(selectedAccount) && {
                color: 'orange',
                content: titleCase(MASTER),
                tag: true,
                size: 'small',
              },
              isParentAccount(selectedAccount) && {
                color: 'blue',
                content: titleCase(PARENT),
                tag: true,
                size: 'small',
              },
            ]}
            subheader={selectedAccount.accountId}
          />
          {subject === 'balances' && this.selectDate()}
        </div>
        <DetailsHeader {...detailsProps} />
        <XTab
          panes={panes(this.addMember, subject)}
          selectedAccount={selectedAccount}
          date={this.state.date}
          loading={!selectedAccount.accountId}
        />
      </Fragment>
    );
  };
}

const ClearingMemberAccountDetailsWrapper = (props) => {
  const cdfEnabled = useCdfEnabled();
  return <ClearingMemberAccountDetails cdfEnabled={cdfEnabled} {...props} />;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClearingMemberAccountDetailsWrapper);
