import React from 'react';
import cogoToast from 'cogo-toast';
import { call, takeLatest, put } from 'redux-saga/effects';
import api, * as urls from '../api';
import { createSaga } from '../utils/createSaga';
import {
  CREATE_FIRM_MEMBER,
  CREATE_VENDOR_MEMBER,
  UPDATE_MEMBER,
  ADD_MEMBER_TO_LEDGER_ACCOUNTS,
  REMOVE_MEMBER_FROM_LEDGER_ACCOUNTS,
  ADD_MEMBER_TO_USERS,
  REMOVE_MEMBER_FROM_USERS,
  CREATE_DIRECT_MEMBER,
  CREATE_INTERMEDIARY_MEMBER,
  CREATE_FCM_MEMBER,
} from '../constants/actionTypes';
import {
  fetchMembers,
  createFirmMember,
  createVendorMember,
  createDirectMember,
  createExecutionBrokerMember,
  createInvestmentManagerMember,
  createIntermediaryMember,
  createFcmMember,
  addMemberToLedgerAccounts,
  updateMember,
  removeMemberFromLedgerAccounts,
  addMemberToUsers,
  removeMemberFromUsers,
  addUltimateBeneficialOwner,
  removeUltimateBeneficialOwner,
  updateUltimateBeneficialOwner,
} from '../reducers/membersReducer';

function* members(arg) {
  const { page } = arg.payload;
  try {
    yield put(fetchMembers.request());
    const data = yield call(api.post, urls.MEMBERS_API_ENDPOINT, arg.payload);
    yield put(fetchMembers.success({ ...data, page }));
  } catch (e) {
    yield put(fetchMembers.failure(e.response));
  }
}

const addRemoveMemberToLedgerAccountsSaga = (routine) =>
  function* addRemove(arg) {
    let closeToast;
    try {
      closeToast = cogoToast.loading('Updating member ledger accounts...', {
        hideAfter: 0,
      });
      yield put(routine.request());
      const result = yield call(
        api.post,
        urls[`${routine._PREFIX}_API_ENDPOINT`],
        arg.payload,
      );
      // if successful, call the member that was changed.  this updates the ui
      const member = yield call(api.post, urls.MEMBERS_API_ENDPOINT, {
        filter: [{ attr: 'id', op: 'eq', value: arg.payload.memberId }],
      });
      closeToast();
      cogoToast.success('Member ledger account successfully updated');
      yield put(routine.success(result));
      yield put(fetchMembers.success(member));
    } catch (e) {
      closeToast();
      yield put(routine.failure(e.response));
    }
  };

export default function* watch() {
  yield takeLatest(fetchMembers.TRIGGER, members);
  yield takeLatest(
    createFirmMember.TRIGGER,
    createSaga(
      createFirmMember,
      CREATE_FIRM_MEMBER,
      'Creating Firm Member',
      <div>Firm Member successfully created.</div>,
    ),
  );
  yield takeLatest(
    createVendorMember.TRIGGER,
    createSaga(
      createVendorMember,
      CREATE_VENDOR_MEMBER,
      'Creating Vendor Member',
      <div>Vendor Member successfully created.</div>,
    ),
  );
  yield takeLatest(
    createDirectMember.TRIGGER,
    createSaga(
      createDirectMember,
      CREATE_DIRECT_MEMBER,
      'Creating Direct Member',
      <div>Direct Member successfully created.</div>,
    ),
  );
  yield takeLatest(
    createExecutionBrokerMember.TRIGGER,
    createSaga(
      createExecutionBrokerMember,
      createExecutionBrokerMember._PREFIX,
      'Creating Execution Broker Member',
      <div>Execution Broker Member successfully created.</div>,
    ),
  );
  yield takeLatest(
    createInvestmentManagerMember.TRIGGER,
    createSaga(
      createInvestmentManagerMember,
      createInvestmentManagerMember._PREFIX,
      'Creating Investment Manager Member',
      <div>Investment Advisor successfully created.</div>,
    ),
  );
  yield takeLatest(
    createIntermediaryMember.TRIGGER,
    createSaga(
      createIntermediaryMember,
      createIntermediaryMember._PREFIX,
      'Creating Intermediary Member',
      <div>Intermediary Member successfully created.</div>,
    ),
  );
  yield takeLatest(
    createFcmMember.TRIGGER,
    createSaga(
      createFcmMember,
      createFcmMember._PREFIX,
      'Creating Fcm Member',
      <div>Fcm Member successfully created.</div>,
    ),
  );
  yield takeLatest(
    updateMember.TRIGGER,
    createSaga(
      updateMember,
      UPDATE_MEMBER,
      'Updating Member',
      <div>Member successfully updated.</div>,
    ),
  );
  yield takeLatest(
    addMemberToLedgerAccounts.TRIGGER,
    addRemoveMemberToLedgerAccountsSaga(addMemberToLedgerAccounts),
  );
  yield takeLatest(
    removeMemberFromLedgerAccounts.TRIGGER,
    addRemoveMemberToLedgerAccountsSaga(removeMemberFromLedgerAccounts),
  );
  yield takeLatest(
    addMemberToUsers.TRIGGER,
    createSaga(
      addMemberToUsers,
      ADD_MEMBER_TO_USERS,
      'Updating member users...',
      <div>Member users successfully updated.</div>,
    ),
  );
  yield takeLatest(
    removeMemberFromUsers.TRIGGER,
    createSaga(
      removeMemberFromUsers,
      REMOVE_MEMBER_FROM_USERS,
      'Updating member users...',
      <div>Member users successfully updated.</div>,
    ),
  );
  yield takeLatest(
    addUltimateBeneficialOwner.TRIGGER,
    createSaga(
      addUltimateBeneficialOwner,
      addUltimateBeneficialOwner._PREFIX,
      'Adding UBO...',
      <div>UBO(s) successfully added.</div>,
    ),
  );
  yield takeLatest(
    removeUltimateBeneficialOwner.TRIGGER,
    createSaga(
      removeUltimateBeneficialOwner,
      removeUltimateBeneficialOwner._PREFIX,
      'Removing UBO...',
      <div>UBO(s) successfully removed.</div>,
    ),
  );
  yield takeLatest(
    updateUltimateBeneficialOwner.TRIGGER,
    createSaga(
      updateUltimateBeneficialOwner,
      updateUltimateBeneficialOwner._PREFIX,
      'Updating UBO...',
      <div>UBO(s) successfully updated.</div>,
    ),
  );
}
