import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import CreateFuturesProduct from '../../components/Products/CreateFuturesProduct';

const selector = formValueSelector('CreateFuturesProduct');

const mapStateToProps = (state) => ({
  deliveryFormValue: selector(state, 'delivery'),
  symbolFormValue: selector(state, 'symbol'),
  allowBlockTradesFormValue: selector(state, 'allowBlockTrades'),
});

const CreateFuturesProductContainer = (props) => (
  <CreateFuturesProduct {...props} />
);

export default connect(mapStateToProps, {})(CreateFuturesProductContainer);
