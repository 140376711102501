import React, { Fragment, PureComponent } from 'react';
import { Icon, Input, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const timeoutLength = 1500;
class CopyInput extends PureComponent {
  state = { isOpen: false };

  copyField = () => {
    /* Get the text field */
    const copyText = document.getElementById(this.props.id);

    // Don't try to get property "select" of null if we forgot an ID
    if (copyText === null) {
      return;
    }

    /* Select the text field */
    copyText.select();
    /* Copy the text inside the text field */
    document.execCommand('copy');
    this.handleOpen();
  };

  handleOpen = () => {
    this.setState({ isOpen: true });

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false });
    }, timeoutLength);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
    clearTimeout(this.timeout);
  };

  getWidth = (fontSize, value) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    div.style.fontSize = fontSize;
    div.style.width = 'auto';
    div.style.display = 'inline-block';
    div.style.visibility = 'hidden';
    div.style.position = 'fixed';
    div.style.overflow = 'auto';
    document.body.append(div);
    const width = div.clientWidth;
    div.remove();
    return width;
  };

  render = () => (
    <Fragment>
      <Input
        readOnly
        style={{
          width: this.props.value
            ? `${this.getWidth('2rem', this.props.value)}px`
            : 'auto',
        }}
        {...this.props}
        // spread props but keep .copy class
        className={`copy ${this.props.className}`}
      />
      <Popup
        // we can't copy text from fields without an ID and value
        trigger={
          this.props.value &&
          this.props.id && (
            <Icon link name="copy outline" onClick={this.copyField} />
          )
        }
        content="Copied!"
        on="click"
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        position="bottom right"
        pointing
        inverted
      />
    </Fragment>
  );
}

CopyInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

CopyInput.defaultProps = {
  className: '',
};

export default CopyInput;
