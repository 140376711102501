import { connect } from 'react-redux';
import React, { PureComponent, Fragment } from 'react';
import { Segment, List, Header, Form, Dropdown } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import {
  createLoadingSelector,
  withFilters,
  filteredArrayToObject,
  filteredArrayToAttrValue,
  datetimeOnchange,
  inputOnChange,
} from 'erisxkit/client';
import Trades from '../common/table/ExternalExpandableTable';
import {
  fetchTrades,
  getTradesCount,
  getTradesList,
} from '../reducers/tradesReducer';
import { showModal, hideModal } from 'erisxkit/client';
import { CONFIRM_ACTION_WITH_PAYLOAD } from '../constants/modalTypes';
import { createReversal } from '../actions/reconciliationActions';
import tradeLogMetadata from '../metadata/tradeLogMetadata';
import { getSubExchangesAsOptions, getSelectorAsOptions } from '../selectors';
import { fetchSubExchanges } from '../reducers/subExchangesReducer';
import { getContracts, contractSymbols } from '../reducers/contractsReducer';
import { spotProducts, getSpotProducts } from '../reducers/spotProductsReducer';
import {
  futuresProducts,
  getFuturesProducts,
} from '../reducers/futuresProductReducer';
import AccountSelectionContainer from '../common/containers/AccountSelectionContainer';
import FullHeightWrapper from '../common/components/FullHeightWrapper';

const FilteredTradesTable = withFilters(Trades);

const mapStateToProps = (state) => ({
  trades: getTradesList(state),
  count: getTradesCount(state),
  loading: createLoadingSelector(['TRADE_JOURNALS'])(state),
  subExchangeOptions: getSubExchangesAsOptions(state),
  contractCodeOptions: getSelectorAsOptions(getContracts, {
    key: 'symbol',
    value: 'symbol',
    text: (o) => (o.contractCode ? o.contractCode : o.symbol),
    subExchangeId: 'subExchangeId',
    productSybmol: 'productSymbol',
    contractSymbol: 'symbol',
  })(state),
  futuresProductsOptions: getSelectorAsOptions(getFuturesProducts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
    subExchangeId: 'subExchangeId',
  })(state),
  spotProductsOptions: getSelectorAsOptions(getSpotProducts, {
    key: 'symbol',
    value: 'symbol',
    text: 'symbol',
    subExchangeId: 'subExchangeId',
  })(state),
});

const mapDispatchToProps = {
  fetchTrades,
  createReversal,
  showModal,
  hideModal,
  fetchSubExchanges,
  contractSymbols,
  spotProducts,
  futuresProducts,
};

export const SubComponent = (row) => (
  <Segment tertiary>
    <List>
      <List.Item content={`UUID: ${row.original.uuid}`} />
      <List.Item content={`Description: ${row.original.description}`} />
      <List.Item content={`Created By: ${row.original.createdBy}`} />
      <List.Item content={`Account ID: ${row.original.accountId}`} />
      <List.Item
        content={`Dco Account ID - Name: ${row.original.dcoAccountId} - ${row.original.dcoAccountName}`}
      />
      <List.Item
        content={`Clearing Fee Account ID - Name: ${row.original.clearingFeeAccountId} - ${row.original.clearingFeeAccountName}`}
      />
      <List.Item
        content={`Exchange Fee Account ID - Name: ${row.original.exchangeFeeAccountId} - ${row.original.exchangeFeeAccountName}`}
      />
    </List>
  </Segment>
);

class TradesLogContainer extends PureComponent {
  tradeLogFilters = (
    subExchangeOptions,
    contractCodeOptions,
    productSymbolOptions,
  ) => [
    {
      component: Datetime,
      className: 'ui input datetime',
      label: 'Start Date',
      name: 'trade_date_start',
      id: 'trade_date_start',
      defaultValue: '',
      dateFormat: 'YYYY-MM-DD',
      timeFormat: false,
      inputProps: { placeholder: 'Filter Begin Time' },
      closeOnSelect: true,
      onChange: datetimeOnchange('date', 'gte'),
    },
    {
      component: Datetime,
      className: 'ui input datetime',
      label: 'End Date',
      name: 'trade_date_end',
      id: 'trade_date_end',
      defaultValue: '',
      dateFormat: 'YYYY-MM-DD',
      timeFormat: false,
      inputProps: { placeholder: 'Filter End Time' },
      closeOnSelect: true,
      onChange: datetimeOnchange('date', 'lt'),
    },
    {
      placeholder: 'Enter subexchange',
      component: Dropdown,
      name: 'sub_exchange_id',
      id: 'sub_exchange_id',
      label: 'Subexchange',
      onChange: inputOnChange,
      options: subExchangeOptions,
      search: true,
      selection: true,
      clearable: true,
    },
    {
      placeholder: 'Select Product',
      component: Dropdown,
      name: 'product_symbol',
      id: 'product_symbol',
      label: 'Product',
      onChange: inputOnChange,
      options: productSymbolOptions,
      search: true,
      selection: true,
      clearable: true,
    },
    {
      placeholder: 'Select Contract',
      component: Dropdown,
      name: 'contract_symbol',
      id: 'contract_symbol',
      label: 'Contract',
      onChange: inputOnChange,
      options: contractCodeOptions,
      search: true,
      selection: true,
      clearable: true,
    },
    {
      placeholder: 'Enter Price',
      component: Form.Input,
      name: 'px',
      id: 'px',
      label: 'Price',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Enter account',
      component: AccountSelectionContainer,
      name: 'account_id',
      id: 'account_id',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Enter trade ID',
      component: Form.Input,
      name: 'trade_id',
      id: 'trade_id',
      label: 'Trade ID',
      onChange: inputOnChange,
    },
    {
      placeholder: 'Spread ID',
      label: 'Spread ID',
      component: Form.Input,
      name: 'secondary_exec_id',
      id: 'secondary_exec_id',
      onChange: inputOnChange,
    },
  ];

  componentDidMount() {
    this.props.fetchSubExchanges();
    this.props.contractSymbols();
    this.props.spotProducts();
    this.props.futuresProducts();
  }

  fetchData = (state) => {
    let filters = [];
    if (state.filtered.length !== 0) {
      filters = filters.concat(filteredArrayToAttrValue(state.filtered));
    }
    this.props.fetchTrades({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      filter: [...filters],
    });
  };

  showReversalModal = (row) =>
    this.props.showModal(CONFIRM_ACTION_WITH_PAYLOAD, {
      closeOnDimmerClick: true,
      closeOnEscape: true,
      payload: row,
      header: 'Confirm Trade Reversal',
      hideModal: this.props.hideModal,
      onConfirm: () => {
        this.props.createReversal({ uuid: row.uuid, showToast: true });
        this.props.hideModal();
      },
      msg: (
        <p>
          Reverse trade with ID <span className="mono">${row.uuid}</span>?
        </p>
      ),
    });

  render = () => {
    const {
      trades,
      count,
      loading,
      subExchangeOptions,
      contractCodeOptions,
      futuresProductsOptions,
      spotProductsOptions,
    } = this.props;

    let productOptions = futuresProductsOptions.concat(spotProductsOptions);
    let _contractCodeOptions = contractCodeOptions;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const subExchangeId = urlParams.get('sub_exchange_id');
    const productSymbol = urlParams.get('product_symbol');

    if (subExchangeId) {
      productOptions = productOptions.filter(
        (value) => value.subExchangeId == subExchangeId,
      );
    }

    if (productSymbol) {
      _contractCodeOptions = _contractCodeOptions.filter(
        (element) => element.productSybmol == productSymbol,
      );
    } else {
      const distinctProducts = [];

      productOptions.forEach((element) => {
        if (!distinctProducts.includes(element.value)) {
          distinctProducts.push(element.value);
        }
      });

      _contractCodeOptions = _contractCodeOptions.filter((element) =>
        distinctProducts.includes(element.productSybmol),
      );
    }

    return (
      <FullHeightWrapper>
        <Header as="h1" dividing content="Trade Log" />
        <FilteredTradesTable
          title="trades"
          data={trades}
          metadata={tradeLogMetadata(this.showReversalModal)}
          onFetchData={this.fetchData}
          filterable={false}
          count={count}
          loading={loading}
          SubComponent={SubComponent}
          noDataText={<span className="italic">No trades were found.</span>}
          pageSize={15}
          pages={Math.ceil(count / 15)}
          filters={this.tradeLogFilters(
            subExchangeOptions,
            _contractCodeOptions,
            productOptions,
          )}
        />
      </FullHeightWrapper>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TradesLogContainer);
