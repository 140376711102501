import React from 'react';
import { Button } from 'semantic-ui-react';

const ReconciliationActions = ({
  selections,
  linkEntries,
  createManualExternalTransaction,
}) => (
  <Button.Group
    buttons={[
      {
        key: 'create_external_tx',
        icon: 'plus',
        onClick: () => createManualExternalTransaction(),
        content: 'Create External Transaction',
        id: 'create_external_tx',
      },
      {
        key: 'link',
        icon: 'linkify',
        onClick: () => linkEntries(),
        content: 'Link Entries',
        disabled: selections < 2,
      },
    ]}
  />
);
export default ReconciliationActions;
