import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Header, Divider } from 'semantic-ui-react';
import { createLoadingSelector } from 'erisxkit/client';
import {
  createEmarketBalanceAdjustment,
  emarketBalanceAdjustments,
} from '../../actions/accountsActions';
import {
  getEmarketBalanceAdjustments,
  getEmarketBalanceAdjustmentsCount,
} from '../../reducers/manualEntriesReducer';
import { assetTypes } from '../../actions/utilActions';
import BalanceAdjustment from '../../components/ManualEntries/BalanceAdjustment';
import { getAssetTypes } from '../../selectors';
import Table from '../../common/table/ExternalTable';
import metadata from '../../metadata/emarketBalanceAdjustmentsMetadata';

const mapStateToProps = (state) => ({
  assetTypesList: getAssetTypes(state),
  assetTypesLoading: createLoadingSelector(['ASSET_TYPES'])(state),
  balanceAdjustmentsLoading: createLoadingSelector([
    'EMARKET_BALANCE_ADJUSTMENTS',
  ])(state),
  balanceAdjustments: getEmarketBalanceAdjustments(state),
  balanceAdjustmentsCount: getEmarketBalanceAdjustmentsCount(state),
});

const mapDispatchToProps = {
  assetTypes,
  createEmarketBalanceAdjustment,
  emarketBalanceAdjustments,
};

export const initialState = {
  accountId: '',
  amount: '',
  assetType: '',
};

class BalanceAdjustmentContainer extends Component {
  state = initialState;
  componentWillMount = () => {
    this.props.assetTypes();
  };

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createEmarketBalanceAdjustment(this.state);
  };

  fetchData = (state) => {
    this.props.emarketBalanceAdjustments({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
    });
  };

  render = () => (
    <Fragment>
      <Header as="h2" dividing>
        TME Balance Adjustment
      </Header>
      <BalanceAdjustment
        data={this.state}
        assetTypes={this.props.assetTypesList}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        assetTypesLoading={this.props.assetTypesLoading}
      />
      <Divider />
      <Table
        title="balanceAdjustments"
        data={this.props.balanceAdjustments}
        onFetchData={this.fetchData}
        metadata={metadata}
        count={this.props.balanceAdjustmentsCount}
        loading={this.props.balanceAdjustmentsLoading}
        noDataText="No balance adjustments found."
        minRows={10}
        filterable={false}
      />
    </Fragment>
  );
}

BalanceAdjustmentContainer.propTypes = {
  createEmarketBalanceAdjustment: PropTypes.func.isRequired,
  assetTypes: PropTypes.func.isRequired,
  assetTypesList: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string,
      description: PropTypes.string,
      isFiat: PropTypes.bool,
    }),
  ).isRequired,
  assetTypesLoading: PropTypes.bool,
  balanceAdjustmentsLoading: PropTypes.bool,
  emarketBalanceAdjustments: PropTypes.func.isRequired,
  balanceAdjustments: PropTypes.arrayOf(PropTypes.object),
  balanceAdjustmentsCount: PropTypes.number,
};

BalanceAdjustmentContainer.defaultProps = {
  assetTypesLoading: false,
  balanceAdjustmentsLoading: false,
  balanceAdjustments: [],
  balanceAdjustmentsCount: 0,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BalanceAdjustmentContainer);
