import React, { Fragment } from 'react';
import {
  renderDropdown,
  renderField,
  rules,
  normalization,
  renderCheckbox,
} from 'erisxkit/client';
import { Form } from 'semantic-ui-react';
import { Field } from 'redux-form';

export default ({
  assetTypesOptions,
  assetTypesLoading,
  change,
  formBaseAssetType,
  formQuotedAssetType,
  formSymbol,
  formAllowBlockTrades,
}) => (
  <Fragment>
    <Form.Group>
      <Field
        name="baseAssetType"
        id="baseAssetType"
        placeholder="base"
        component={renderDropdown}
        onChange={(e, value) => {
          const newSymbol = `${value}/${formQuotedAssetType || '—'}`;
          change('symbol', newSymbol);
          change('description', `${newSymbol} Spot`);
        }}
        label="Base asset type"
        loading={assetTypesLoading}
        options={assetTypesOptions}
        required
        validate={[rules.required]}
      />
      /
      <Field
        name="quotedAssetType"
        id="quotedAssetType"
        placeholder="quote"
        component={renderDropdown}
        label="Quoted asset type"
        loading={assetTypesLoading}
        options={assetTypesOptions}
        required
        validate={[rules.required]}
        onChange={(e, value) => {
          const newSymbol = `${formBaseAssetType || '—'}/${value}`;
          change('symbol', newSymbol);
          change('description', `${newSymbol} Spot`);
        }}
      />
      <Field
        name="allowBlockTrades"
        component={renderCheckbox}
        label="Allow Block Trades"
      />
    </Form.Group>
    <Form.Group>
      <Field
        name="symbol"
        id="symbol"
        placeholder="Product Code"
        component={renderField}
        label="Product Code"
        loading={assetTypesLoading}
        required
        validate={[rules.required]}
      />
      <Field
        name="contractSize"
        id="contractSize"
        component={renderField}
        label="Contract Size"
        parse={normalization.floatsOnly}
      />
      <Field
        name="minimumBlockSize"
        id="minimumBlockSize"
        component={renderField}
        label="Minimum Block Size"
        disabled={!formAllowBlockTrades}
        parse={normalization.floatsOnly}
      />
    </Form.Group>
    <Form.Group>
      <Field
        name="description"
        component={renderField}
        label={
          <span>
            Description{' '}
            <small className="italic">
              (defaults to &quot;(Product Code) Spot&quot;)
            </small>
          </span>
        }
        placeholder="base/quote Spot"
        fluid
      />
    </Form.Group>
  </Fragment>
);
