import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import sign from 'eris-ecdsa';
import { storeTxAuthKey } from '../reducers/authReducer';
import CreateAdminKey from '../components/CreateAdminKey';
import { hideModal } from 'erisxkit/client';
import { getCurrentAuthId } from '../reducers/usersReducer';
import { signMsg } from '../utils/methods';

const mapDispatchToProps = {
  hideModal,
  storeTxAuthKey,
};

const mapStateToProps = (state) => ({
  authId: getCurrentAuthId(state),
});

class CreateAdminKeyContainer extends Component {
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    const { authId, storeTxAuthKey, hideModal } = this.props;
    const publicKey = sign.pubKeyBs58(authId, this.state.password);
    const msg = [authId, publicKey, 'admin', Date.now().toString()];
    const sig = signMsg(authId, this.state.password, msg);
    storeTxAuthKey({ msg, sig });
    hideModal();
  };

  render = () => (
    <CreateAdminKey
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
    />
  );
}

CreateAdminKeyContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  storeTxAuthKey: PropTypes.func.isRequired,
  authId: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAdminKeyContainer);
