/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import get from 'lodash/get';
import unset from 'lodash/unset';
import api, * as urls from '../api';
import replaceUrlParams from './replaceUrlParams';
import HTTP_METHODS from '../constants/httpMethods';
import parseUrl from './parseUrl';

/** DEPRECATED - DO NOT USE - USE generateSaga */
export const createSaga = (
  routine,
  type,
  loadingMessage = '',
  successMessage = '',
  loadingOptions = {},
  successOptions = {},
  payloadOptions = {
    method: HTTP_METHODS.POST,
  },
) =>
  function* generator({ payload }) {
    let closeToast;
    if (loadingMessage) {
      closeToast = cogoToast.loading(loadingMessage, {
        ...loadingOptions,
        hideAfter: 0,
      });
    }

    try {
      yield put(routine.request());
      // Populate params if necessary
      const url = parseUrl(urls[`${type}_API_ENDPOINT`], payload);
      // Remove queryParams and urlParams from payload
      unset(payload, 'urlParams');
      unset(payload, 'queryParams');
      // Make request with corresponding HTTP method - default to POST
      const method = get(payloadOptions, 'method', HTTP_METHODS.POST);
      const result = yield call(api[method], url, payload);
      const returnPayload = payloadOptions.withPage
        ? { ...result, page: payload.page }
        : result;
      loadingMessage && closeToast();
      successMessage &&
        cogoToast.success(successMessage, {
          ...successOptions,
          onClick: () => successOptions.onClick(returnPayload),
        });
      yield put(routine.success(returnPayload));
    } catch (e) {
      loadingMessage && closeToast();
      yield put(routine.failure(e.response));
    }
  };
