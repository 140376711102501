import { takeLatest } from 'redux-saga/effects';
import generateSaga from '../utils/generateSaga';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';
import {
  addAuthorizedBlockTrader,
  fetchAuthorizedBlockTraders,
  removeAuthorizedBlockTrader,
} from '../reducers/blockTradePermissionsReducer';

export default function* watch() {
  yield takeLatest(
    fetchAuthorizedBlockTraders,
    generateSaga(fetchAuthorizedBlockTraders, {
      method: HTTPMethod.GET,
    }),
  );
  yield takeLatest(
    addAuthorizedBlockTrader,
    generateSaga(addAuthorizedBlockTrader, {
      method: HTTPMethod.POST,
    }),
  );
  yield takeLatest(
    removeAuthorizedBlockTrader,
    generateSaga(removeAuthorizedBlockTrader, {
      method: HTTPMethod.DELETE,
    }),
  );
}
