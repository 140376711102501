import { arrayToObject } from 'erisxkit/client';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions } from 'redux-actions';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  COLLATERAL,
  COLLATERAL_CONTRACTS,
  COLLATERAL_PRODUCTS,
  CREATE_COLLATERAL_CONTRACT,
  CREATE_COLLATERAL_DEPOSIT_JOURNAL,
  CREATE_COLLATERAL_PRODUCT,
  CREATE_COLLATERAL_WITHDRAWAL_JOURNAL,
  UPDATE_COLLATERAL_PRODUCT,
} from '../constants/actionTypes';

export const collateral = createRoutine(COLLATERAL);
export const collateralContracts = createRoutine(COLLATERAL_CONTRACTS);
export const collateralContractsPromiseCreator =
  promisifyRoutine(collateralContracts);
export const collateralProducts = createRoutine(COLLATERAL_PRODUCTS);
export const createCollateralContract = createRoutine(
  CREATE_COLLATERAL_CONTRACT,
);
export const createCollateralDepositJournal = createRoutine(
  CREATE_COLLATERAL_DEPOSIT_JOURNAL,
);
export const createCollateralProduct = createRoutine(CREATE_COLLATERAL_PRODUCT);
export const createCollateralWithdrawalJournal = createRoutine(
  CREATE_COLLATERAL_WITHDRAWAL_JOURNAL,
);
export const updateCollateralProduct = createRoutine(UPDATE_COLLATERAL_PRODUCT);

// Reducer

export default handleActions(
  {
    [collateralContracts.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [collateralProducts.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [createCollateralProduct.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      collateralProducts: {
        ...state.collateralProducts,
        [payload.symbol]: payload,
      },
    }),
    [updateCollateralProduct.SUCCESS]: (state, { payload }) => ({
      ...state,
      collateralProducts: {
        ...state.collateralProducts,
        [payload.symbol]: payload,
      },
    }),
  },
  {},
);

//* Selectors */
export const getCollateralContracts = (state) =>
  _.get(state, ['collateral', 'collateralContracts'], []);
export const getCollateralContractsAsSymbols = createSelector(
  [getCollateralContracts],
  (collateralContractsList) =>
    collateralContractsList.map((contract) => contract.symbol),
);
export const getCollateralProducts = (state) =>
  _.chain(state).get(['collateral', 'collateralProducts'], []).values().value();

export const getCollateralContractsAsOptions = createSelector(
  [getCollateralContracts],
  (collateralContractsList) =>
    collateralContractsList.map((contract) => ({
      key: contract.productSymbol,
      value: contract.symbol,
      text: contract.symbol,
    })),
);

export const getCollateralContractsAsSearchField = createSelector(
  [getCollateralContracts],
  (collateralContractsList) =>
    collateralContractsList.map((contract) => ({
      key: contract.symbol,
      text: `${contract.symbol} (${contract.cusip})`,
      value: contract.symbol,
    })),
);

export const getCollateralProductsAsOptions = createSelector(
  [getCollateralProducts],
  (collateralProductsList) =>
    collateralProductsList.map((product) => ({
      key: product.symbol,
      text: product.symbol,
      description: product.description,
      value: product.symbol,
    })),
);
