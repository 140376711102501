import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment, Button, Input } from 'semantic-ui-react';
import XTable7 from '../common/table/XTable7';
import metadata from '../metadata/confirmTransferMetadata';

const fetchData = () => {};

const ConfirmTransfer = ({
  data,
  confirm,
  cancel,
  accFromNs,
  accFromMp,
  approvedNet,
}) => {
  const [modalInput, setModalInput] = useState({
    transactionAmt: '',
    bankRef: '',
  });

  const handleModalInputChange = (evt) => {
    const value = evt.target.value;
    setModalInput({
      ...modalInput,
      [evt.target.name]: value,
    });
  };

  const confirmAction = () => {
    confirm(modalInput['bankRef']);
  };

  //If there are not approve transactions disables the bankRef field
  const disabledBankRef =
    data.filter((x) => x.checked.includes('approve')).map((x) => x.id)
      .length === 0;

  const disabledButton = !(
    modalInput['transactionAmt'] === approvedNet &&
    (modalInput['bankRef'] !== '' || disabledBankRef) &&
    data.length > 0
  );

  return (
    <Fragment>
      <Modal.Header>Summary</Modal.Header>
      <Modal.Description>
        <Segment>
          <XTable7
            metadata={metadata}
            data={data || []}
            fetchData={fetchData}
            showHeader={true}
            showFilter={false}
            showPagination={false}
            height="60px"
          />
          {!disabledBankRef && (
            <>
              <Segment textAlign="center">
                Net Movement: ${approvedNet} from{' '}
                {accFromNs <= accFromMp ? 'MP' : 'NS'} To{' '}
                {accFromNs < accFromMp ? 'NS' : 'MP'}
              </Segment>
              <Segment.Group horizontal>
                <Segment>Bank Ref #:</Segment>
                <Segment>
                  <Input
                    name="bankRef"
                    type="number"
                    value={modalInput['bankRef']}
                    disabled={disabledBankRef}
                    onChange={handleModalInputChange}
                  />
                </Segment>
                <Segment>Transaction AMT:</Segment>
                <Segment>
                  <Input
                    name="transactionAmt"
                    type="number"
                    value={modalInput['transactionAmt']}
                    onChange={handleModalInputChange}
                  />
                </Segment>
              </Segment.Group>
            </>
          )}
        </Segment>
      </Modal.Description>
      <Modal.Content></Modal.Content>
      <Modal.Actions>
        <Button name="cancelAction" onClick={cancel} content="Cancel" />
        <Button
          name="confirmAction"
          onClick={confirmAction}
          primary
          disabled={disabledButton && !disabledBankRef}
          content="Confirm"
        />
      </Modal.Actions>
    </Fragment>
  );
};

ConfirmTransfer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      checked: PropTypes.string,
      from: PropTypes.string,
      amount: PropTypes.number,
      to: PropTypes.string,
      asset: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  accFromNs: PropTypes.number,
  accFromMp: PropTypes.number,
};

export default ConfirmTransfer;
