import styled from 'styled-components';
import { Form, Input } from 'semantic-ui-react';
import TextButton from '../../TextButton';
import colors from '../../../constants/colors';
import TimerButton from '../../components/TimerButton';

export const Wrapper = styled.div`
  background-color: ${colors.primary4};
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: end;
`;

export const Action = styled(TextButton)`
  margin-right: 10px;
`;

export const MainAction = styled(Action)`
  min-width: 57px;
  text-align: center;
`;

export const Label = styled.label`
  font-size: 17px !important;
  color: ${colors.secondary3} !important;
  font-weight: 400 !important;
  white-space: nowrap;
  flex-basis: 40%;
`;

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 30px;
`;

export const StyledField = styled(Form.Field)`
  display: flex;
  align-items: baseline;
  height: 30px;
  // Input
  & :nth-child(2) {
    height: 30px;
    flex-basis: 60%;
  }
`;

export const StyledInput = styled(Input)`
  & > input {
    // Fixes default semantic ui opacities that are stacking
    opacity: 1 !important;
    // Makes the input use accent color if field has changed
    color: ${({ hasChanged }) => (hasChanged ? colors.accent2 : '')} !important;
  }
`;

export const StyledTimerButton = styled(TimerButton)`
  margin-right: 5px;
  // Button
  .ui.red.button {
    padding: 0px 10px;
  }
  // Timer indicator
  .ui.red.left.pointing.basic.label {
    padding: 3px 5px;
  }
`;
