import React, { Fragment } from 'react';
import { Header } from 'semantic-ui-react';
import RejectedJournalsContainer from './RejectedJournalsContainer';
import XTab from '../../common/tabs/XTab';

const panes = [
  {
    url: 'rejected',
    menuItem: 'Rejected',
    render: () => <RejectedJournalsContainer key="rejectedJournals" />,
  },
];

export default () => (
  <Fragment>
    <Header as="h1">
      <Header.Content>Journals</Header.Content>
    </Header>
    <XTab panes={panes} />
  </Fragment>
);
