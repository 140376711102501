import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'Avg Net Stress Exposure Weight',
    id: 'avgNetStressExposureWeight',
    accessor: 'avgNetStressExposureWeight',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 250,
  },
  {
    Header: 'Avg Gross Portfolio Notional Exposure Weight',
    id: 'avgGrossPortfolioNotionalExposureWeight',
    accessor: 'avgGrossPortfolioNotionalExposureWeight',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 340,
  },
  {
    Header: 'Avg Daily Gross Notional Volume Traded Weight',
    id: 'avgDailyGrossNotionalVolumeTradedWeight',
    accessor: 'avgDailyGrossNotionalVolumeTradedWeight',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 340,
  },
  {
    Header: 'Minimum Guaranty Fund Requirement',
    id: 'minimumGuarantyFundRequirement',
    accessor: 'minimumGuarantyFundRequirement',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 300,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
