import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import moment from 'moment';
import {
  getSelectedAccount,
  getSelectedAccountId,
} from '../reducers/reconciliationReducer';
import ReconciliationHistory from '../components/ReconciliationHistory';
import history from '../constants/history';
import { fetchAccounts } from '../actions/accountsActions';
import {
  fetchReconciliationHistory,
  selectReconciliation,
} from '../actions/reconciliationActions';
import { getReconciliationHistory } from '../reducers/reconciliationHistoryReducer';
import { formatUTC, endingBusinessTime } from '../utils/time';
import qs from 'query-string';

const mapStateToProps = (state, props) => ({
  selectedAccount: getSelectedAccount(state, props),
  selectedAccountId: getSelectedAccountId(state, props),
  reconciliationHistory: getReconciliationHistory(state, props),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchReconciliationHistory,
      fetchAccounts,
      selectReconciliation,
    },
    dispatch,
  );

class ReconciliationHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.startNewReconciliation = this.startNewReconciliation.bind(this);
    this.viewConfirmedReconciliation =
      this.viewConfirmedReconciliation.bind(this);
  }
  state = {
    endTime: formatUTC(endingBusinessTime()),
    daysPrevious: 0,
  };

  componentDidMount() {
    // fetch the data
    const { fetchAccounts, fetchReconciliationHistory, selectedAccountId } =
      this.props;
    fetchAccounts({ accountIds: [selectedAccountId] });
    fetchReconciliationHistory(selectedAccountId);
  }

  startNewReconciliation() {
    const queryParam = {
      endTime: this.state.endTime,
      daysPrevious: this.state.daysPrevious,
    };
    this.props.selectReconciliation({ state: 0, uuid: null });
    history.push(
      `/reconciliation/${this.props.selectedAccountId}/reconcile?${qs.stringify(queryParam)}`,
    );
  }

  viewConfirmedReconciliation(uuid) {
    this.props.selectReconciliation({ state: 20, uuid });
    history.push(
      `/reconciliation/${this.props.selectedAccountId}/${uuid}/reconcile`,
    );
  }

  dateTimeChanged = (date) => {
    this.setState({
      endTime: formatUTC(date),
    });
  };

  daysPreviousChanged = (daysPrevious) => {
    let parsedInt = parseInt(daysPrevious.target.value) || 0;
    this.setState({
      daysPrevious: parsedInt,
    });
  };

  render() {
    const { selectedAccount, reconciliationHistory } = this.props;

    const props = {
      reconciliationHistory,
      startReconciliation: this.startNewReconciliation,
      selectedAccount,
      viewReconciliationHistory: this.viewConfirmedReconciliation,
      valid: this.valid,
      dateTimeChanged: this.dateTimeChanged,
      endTime: this.state.endTime,
      daysPreviousChanged: this.daysPreviousChanged,
      daysPrevious: this.state.daysPrevious,
    };
    return (
      <div>
        <ReconciliationHistory {...props} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReconciliationHistoryContainer);
