/* eslint-disable no-unused-expressions */
import { call, put } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import get from 'lodash/get';
import unset from 'lodash/unset';
import api, * as urls from '../api';
import replaceUrlParams from './replaceUrlParams';
import HTTP_METHODS from '../constants/httpMethods';
import parseUrl from './parseUrl';

const generateSaga = (
  // Routine must be a redux-saga-routine with a _PREFIX attribute
  routine,
  opts = {},
) =>
  function* generator({ payload }) {
    let closeToast;
    const {
      loading = '',
      success = '',
      loadingOptions = {},
      successOptions = {},
      method = HTTP_METHODS.POST,
      payloadOptions = {},
      prefix = '',
    } = opts;

    const type = prefix || get(routine, '_PREFIX', '');

    if (loading) {
      closeToast = cogoToast.loading(loading, {
        ...loadingOptions,
        hideAfter: 0,
      });
    }

    try {
      yield put(routine.request());
      // Populate params if necessary
      const url = parseUrl(urls[`${type}_API_ENDPOINT`], payload);
      // Remove queryParams and urlParams from payload
      unset(payload, 'urlParams');
      unset(payload, 'queryParams');
      // Make request with corresponding HTTP method - default to POST
      const result = yield call(api[method], url, payload);
      const returnPayload = payloadOptions.withPage
        ? { ...result, page: payload.page }
        : result;
      loading && closeToast();
      success &&
        cogoToast.success(success, {
          ...successOptions,
          onClick: () => successOptions.onClick(returnPayload),
        });
      yield put(routine.success(returnPayload));
    } catch (e) {
      loading && closeToast();
      yield put(routine.failure(e.response));
    }
  };

export default generateSaga;
