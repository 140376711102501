import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { Header } from 'semantic-ui-react';
import TextButton from '../../TextButton';
import {
  SubComponentField,
  getGroupedFieldName,
} from './EditableRowSubComponent';
import { StyledForm } from './styled';
import colors from '../../../constants/colors';
import usePrevious from '../../../hooks/usePrevious';

const Separator = styled.span`
  margin: 0px 5px;
  font-size: 19px;
  color: ${colors.primary3};
`;

const FormGroup = ({
  groups = [],
  values,
  disabled,
  onChange,
  getFieldHasChanged,
  onSetActiveGroup,
  revealFields,
}) => {
  const [activeGroup, setActiveGroup] = useState(get(groups, [0, 'key']));
  const prevActiveGroup = usePrevious(activeGroup);
  const isActive = useCallback((group) => group === activeGroup, [activeGroup]);

  const setNewActiveGroup = useCallback(
    (newActiveGroup) => {
      setActiveGroup(newActiveGroup);
    },
    [onSetActiveGroup, prevActiveGroup],
  );

  useEffect(() => {
    onSetActiveGroup(prevActiveGroup, activeGroup);
  }, [activeGroup, prevActiveGroup]);

  const getActiveGroupFields = useCallback(() => {
    const active = groups.find((group) => group.key === activeGroup);
    return active ? (
      <StyledForm className="subrow-form-grid">
        {active.fields.map((field) => {
          const groupFieldName = getGroupedFieldName(active.key, field.name);
          return (
            <SubComponentField
              field={field}
              key={field.name}
              name={groupFieldName}
              disabled={disabled}
              onChange={onChange}
              value={get(values, groupFieldName, '')}
              hasChanged={getFieldHasChanged(groupFieldName)}
              reveal={revealFields}
            />
          );
        })}
      </StyledForm>
    ) : (
      []
    );
  }, [groups, activeGroup, disabled, values, revealFields]);

  return (
    <>
      <Header as="h3" dividing style={{ marginTop: '10px' }}>
        {groups.map((nestedGroup, i) => [
          i > 0 && (
            <Separator key={`separator_${nestedGroup.key}`}>|</Separator>
          ),
          <TextButton
            key={nestedGroup.key}
            text={nestedGroup.title}
            onClick={() => setNewActiveGroup(nestedGroup.key)}
            color={isActive(nestedGroup.key) ? colors.accent2 : colors.primary3}
          />,
        ])}
      </Header>
      {getActiveGroupFields()}
    </>
  );
};
const GroupedFormFields = ({
  groupedFields,
  values,
  disabled,
  onChange,
  getFieldHasChanged,
  onSetActiveGroup,
  reveal,
}) =>
  groupedFields.map((fieldGroup) =>
    isArray(fieldGroup) ? (
      <FormGroup
        groups={fieldGroup}
        values={values}
        disabled={disabled}
        onChange={onChange}
        getFieldHasChanged={getFieldHasChanged}
        onSetActiveGroup={onSetActiveGroup}
        revealFields={reveal}
      />
    ) : (
      <>
        <Header as="h3" dividing style={{ marginTop: '10px' }}>
          {fieldGroup.title}
        </Header>
        <StyledForm className="subrow-form-grid">
          {fieldGroup.fields.map((field) => {
            const groupFieldName = getGroupedFieldName(
              fieldGroup.key,
              field.name,
            );
            return (
              <SubComponentField
                field={field}
                key={field.name}
                name={groupFieldName}
                disabled={disabled}
                onChange={onChange}
                value={get(values, groupFieldName, '')}
                hasChanged={getFieldHasChanged(groupFieldName)}
                reveal={reveal}
              />
            );
          })}
        </StyledForm>
      </>
    ),
  );

GroupedFormFields.defaultProps = {
  onChange: () => {},
  groupedFields: null,
  disabled: false,
  values: {},
  onSetActiveGroup: () => {},
  reveal: false,
};

GroupedFormFields.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  values: PropTypes.shape(PropTypes.any),
  getFieldHasChanged: PropTypes.func,
  onSetActiveGroup: PropTypes.func,
  reveal: PropTypes.bool,
  groupedFields: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        fields: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            initialValue: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.bool,
            ]),
            placeholder: PropTypes.string,
          }),
        ),
      }),
    ),
  ),
};

export default GroupedFormFields;
