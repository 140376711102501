export const roles = {
  SUPER_USER: 'super_user',
  CLEARING_OPS: 'clearing_ops',
  ONBOARDING: 'onboarding',
  MARKET_OPS: 'market_ops',
  CLIENT_SERVICES: 'client_services',
  TREASURY: 'treasury',
  FINANCE: 'finance',
  COMPLIANCE: 'compliance',
  EXTERNAL_TRANSFERS: 'external_transfers',
  RISK: 'risk',
  MEMBER_USER: 'member_user',
  TOKEN: 'token',
};
