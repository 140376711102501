import { TABLE_INPUT_TYPES } from '../../common/table/constants';

export default [
  {
    Header: 'WJ Firm ID',
    id: 'wjFirmId',
    accessor: 'wjFirmId',
    editInputType: TABLE_INPUT_TYPES.NUMBER,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'TCS Account ID',
    id: 'tcsAccountId',
    accessor: 'tcsAccountId',
    editInputType: TABLE_INPUT_TYPES.STRING,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Fiscal Year End',
    id: 'fiscalYearEnd',
    accessor: 'fiscalYearEnd',
    editInputType: TABLE_INPUT_TYPES.DATE,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'DSRO',
    id: 'dsro',
    accessor: 'dsro',
    editInputType: TABLE_INPUT_TYPES.STRING,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'DFRR Group',
    id: 'dfrrGroup',
    accessor: 'dfrrGroup',
    editInputType: TABLE_INPUT_TYPES.STRING,
    required: true,
    minWidth: 150,
  },
  {
    Header: 'Updated At',
    id: 'updatedAt',
    accessor: 'updatedAt',
    minWidth: 150,
    uneditable: true,
  },
  {
    Header: 'Updated By',
    id: 'lastUpdatedBy',
    accessor: 'lastUpdatedBy',
    minWidth: 150,
    uneditable: true,
  },
];
