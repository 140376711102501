import get from 'lodash/get';
/**
 * @param {string} unparsedFieldName - unparsed EditableRowSubComponent fieldname of the shape <formname>_<name>
 * @returns {string} String corresponding to the field name only (discards everything left of _)
 * @description EditableRowSubcomponent allows for multiple forms which may share fields with the same name.
 * It discriminates fields by their form name. This callback discards everything left of the _ (form name)
 * and returns field name only
 */

const getParsedFieldName = (unparsedFieldName = '') => {
  if (!unparsedFieldName || typeof unparsedFieldName !== 'string') return '';
  return get(unparsedFieldName?.split('_'), [1], '');
};

export default getParsedFieldName;
