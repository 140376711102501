import React, { Component, Fragment } from 'react';
import {
  WITHDRAWAL_CONFIRM,
  showModal,
  renderField,
  normalization,
  rules,
} from 'erisxkit/client';
import { connect } from 'react-redux';
import {
  Form,
  Container,
  Header,
  Label,
  Grid,
  Segment,
} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { getAssetTypeSelector } from '../../selectors';
import { getAccountSelector } from '../../reducers/accountsReducer';
import {
  fetchLinkedMemberAssetAccounts,
  getLinkedMemberAssetAccount,
  getLinkedAccountLabel,
} from '../../reducers/linkedMemberAssetAccountsReducer';
import { scaleBaseToMinUnit } from '../../actions/utilActions';

import { selectMember } from '../../reducers/membersReducer';

import {
  AccountSelectionContainer,
  AssetTypeSelectionContainer,
  FundsDesignationSelectionContainer,
  LinkedMemberAssetAccountSelectionContainer,
} from '../../common/containers';

const EXTERNAL_TRANSFER_FORM_NAME = 'external_transfer';

const mapStateToProps = (state) => ({
  getAssetType: getAssetTypeSelector(state),
  getAccount: getAccountSelector(state),
  getLinkedMemberAssetAccount: getLinkedMemberAssetAccount(state),
});

const mapDispatchToProps = {
  selectMember,
  showModal,
  scaleBaseToMinUnit,
  fetchLinkedMemberAssetAccounts,
};

class ExternalTransferContainer extends Component {
  state = {
    assetType: '',
    memberId: '',
    linkedMemberAssetAccount: undefined,
  };

  defaultProps = {
    valid: false,
  };

  accountChanged = (name, id) => {
    const { change, getAccount, selectMember, fetchLinkedMemberAssetAccounts } =
      this.props;

    if (id) {
      const account = getAccount(id);
      const { memberId } = account;
      this.setState({ account, memberId });
      selectMember(memberId);
      change('linkedMemberAssetAccount', '');

      fetchLinkedMemberAssetAccounts({
        filter: [{ attr: 'member_id', op: 'eq', value: account.memberId }],
      });
    } else {
      change('linkedMemberAssetAccount', '');
    }
  };

  assetTypeChanged = (assetType) => {
    const { isFiat } = this.props.getAssetType(assetType);
    this.props.change('linkedMemberAssetAccount', '');
    this.setState({ assetType, isFiat });
  };

  linkedMemberAssetAccountChanged = (id) => {
    if (id) {
      const linkedAccount = this.props.getLinkedMemberAssetAccount(id);
      this.props.change(
        'destinationLabel',
        getLinkedAccountLabel(linkedAccount),
      );
      if (this.state.isFiat) {
        // fiat needs this in the form
        this.props.change('linkedAssetAccount', linkedAccount);
      } else {
        // crypto needs these in the form
        this.props.change('destination', linkedAccount.address);
        this.props.change('destinationId', id);
      }
    } else {
      this.props.change('linkedMemberAssetAccount', '');
    }
  };

  confirmTransfer = ({ amount, assetType }) => {
    const { scaleBaseToMinUnit, showModal, getAssetType } = this.props;

    let payMethod;
    if (this.state.isFiat) {
      payMethod = 'FWT';
    } else {
      scaleBaseToMinUnit({ amount, assetType });
    }

    showModal(WITHDRAWAL_CONFIRM, {
      type: this.state.isFiat ? 'fiat' : 'crypto',
      formName: EXTERNAL_TRANSFER_FORM_NAME,
      payMethod, // Used for fiat only
      account: this.state.account,
      title: 'Please confirm your external transfer',
      fundingPassword: 'admin key',
    });
  };

  render = () => {
    const { handleSubmit } = this.props;

    return (
      <Fragment>
        <Form onSubmit={handleSubmit(this.confirmTransfer)}>
          <Container text className="tab-container">
            <Grid stackable columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Header as="h2" dividing>
                    External Transfer
                  </Header>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Field
                    component={AssetTypeSelectionContainer}
                    name="assetType"
                    label="Asset Type"
                    validate={[rules.required]}
                    onChange={this.assetTypeChanged}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    component={FundsDesignationSelectionContainer}
                    name="fundsDesignation"
                    label="Funds Designation"
                    validate={[rules.required]}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Segment>
                    <Label attached="top">SOURCE ACCOUNT</Label>
                    <Field
                      component={AccountSelectionContainer}
                      name="accountId"
                      label="Account ID"
                      placeholder="Select account..."
                      validate={[rules.required]}
                      onChange={this.accountChanged}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Segment>
                    <Label attached="top">DESTINATION SSI</Label>
                    <Field
                      component={LinkedMemberAssetAccountSelectionContainer}
                      filter={{
                        assetType: this.state.assetType,
                        memberId: this.state.memberId,
                      }}
                      memberId={this.state.memberId}
                      name="linkedMemberAssetAccount"
                      label="SSI"
                      onChange={this.linkedMemberAssetAccountChanged}
                      validate={[rules.required]}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Field
                    label="Amount"
                    placeholder="Amount"
                    type="text"
                    name="amount"
                    component={renderField}
                    labelPosition="right"
                    parse={normalization.floatsOnly}
                    validate={[rules.required]}
                  >
                    <input />
                    <Label>{this.state.assetType}</Label>
                  </Field>
                </Grid.Column>
                <Grid.Column>{/* Spacer */}</Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Form.Button disabled={!this.props.valid} primary>
                    Submit Transfer
                  </Form.Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Form>
      </Fragment>
    );
  };
}

export default reduxForm({
  form: EXTERNAL_TRANSFER_FORM_NAME,
})(connect(mapStateToProps, mapDispatchToProps)(ExternalTransferContainer));
