import React, { Fragment, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Segment, Button, Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import XTable7 from '../common/table/XTable7';
import ConfirmPositionTransferMetadata from '../metadata/ConfirmPositionTransferMetadata';

const BoldSpan = styled.span`
  font-weight: bold;
`;

const fetchData = () => {};

const ConfirmRejectPositionTransfer = ({ onCancel, payload, onConfirm }) => {
  const { fcmMemberFirm, fromAccount, cgm, tableState, positionsTransfers } =
    payload;
  const data = Object.values(tableState).map((x) => ({
    firm: get(x, 'firmSelected', ''),
    account: get(x, 'accountSelected', ''),
    cgm: get(x, 'cgm', ''),
    transferType: get(x, 'transferTypeSelected', ''),
    price: get(x, 'price', ''),
    longTransfer: get(x, 'longTransfer', ''),
    shortTransfer: get(x, 'shortTransfer', ''),
    productSymbol: get(positionsTransfers, `${x.index}.productSymbol`, ''),
    contractSymbol: get(positionsTransfers, `${x.index}.contractSymbol`, ''),
    currentLongQty: get(positionsTransfers, `${x.index}.currentLongQty`, ''),
    currentShortQty: get(positionsTransfers, `${x.index}.currentShortQty`, ''),
    pendingLongQty: get(positionsTransfers, `${x.index}.pendingLongQty`, ''),
    pendingShortQty: get(positionsTransfers, `${x.index}.pendingShortQty`, ''),
  }));
  // If any position has short and long of 0, prevent submission
  const isInvalid = useMemo(
    () =>
      data &&
      data?.some(
        (position) =>
          get(position, 'shortTransfer') === 0 &&
          get(position, 'longTransfer') === 0,
      ),
    [data],
  );

  return (
    <Fragment>
      <Modal.Header>Summary</Modal.Header>
      <Modal.Description>
        <Segment>
          <>
            <h4>You are going to Confirm the next action</h4>
            <Grid
              columns="equal"
              className="flex"
              style={{ width: '75%', marginTop: '15px' }}
            >
              <Grid.Row>
                <Grid.Column>
                  <BoldSpan>From Firm</BoldSpan>
                  <p>{fcmMemberFirm}</p>
                </Grid.Column>
                <Grid.Column>
                  <BoldSpan>From Account</BoldSpan>
                  <p>{fromAccount}</p>
                </Grid.Column>
                <Grid.Column>
                  <BoldSpan>From CGM</BoldSpan>
                  <p>{cgm}</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header as="h3" dividing />
            <h3>To</h3>
            <XTable7
              metadata={ConfirmPositionTransferMetadata()}
              data={data || []}
              fetchData={fetchData}
              showHeader
              showFilter={false}
              showPagination={false}
              height="60px"
            />
          </>
        </Segment>
      </Modal.Description>
      <Modal.Content />
      <Modal.Actions>
        <Button name="cancelAction" onClick={onCancel} content="Cancel" />
        <Button
          name="confirmAction"
          onClick={onConfirm}
          disabled={isInvalid}
          primary
          content="Confirm"
        />
      </Modal.Actions>
    </Fragment>
  );
};

ConfirmRejectPositionTransfer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  payload: PropTypes.shape({
    cgm: PropTypes.string,
    fromAccount: PropTypes.string,
    fcmMemberFirm: PropTypes.string,
    tableState: PropTypes.any,
    positionsTransfers: PropTypes.array,
  }),
  onConfirm: PropTypes.func.isRequired,
};

ConfirmRejectPositionTransfer.defaultProps = {
  payload: {
    cgm: '',
    fromAccount: '',
    fcmMemberFirm: '',
    tableState: {},
    positionsTransfers: [],
  },
};

export default ConfirmRejectPositionTransfer;
