import React from 'react';
import { get } from 'lodash';
import { Icon } from 'semantic-ui-react';
import { formatDateLocal } from '../utils/time';

export default ({ showModal, modalType, modalProps }) =>
  ({ symbol }) => [
    {
      Header: `${symbol} Contracts`,
      columns: [
        {
          id: 'symbol',
          Header: 'Contract Code',
          accessor: 'symbol',
        },
        {
          id: 'expiration-time',
          Header: 'Maturity Date',
          accessor: ({ expirationTime }) => formatDateLocal(expirationTime),
        },
        {
          id: 'cusip',
          Header: 'CUSIP',
          accessor: (subRow) => (
            <span className="mono">{get(subRow, 'cusip', '-')}</span>
          ),
        },
        {
          id: 'coupon',
          Header: 'Coupon',
          accessor: 'coupon',
          resizable: false,
        },
        {
          id: 'edit',
          Header: '',
          maxWidth: 30,
          resizable: false,
          Cell: ({ original }) => (
            <Icon
              id="editContract"
              name="edit"
              link
              onClick={() =>
                showModal(modalType, {
                  symbol,
                  type: 'update',
                  ...modalProps,
                  ...original,
                })
              }
            />
          ),
        },
      ],
    },
  ];
