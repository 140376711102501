import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { Fragment, Component } from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  createLoadingSelector,
  filteredArrayToAttrValue,
} from 'erisxkit/client';
import {
  fetchApprovalReqs,
  selectApproval,
  fetchApprovalLogs,
} from '../actions/approvalReqsActions';
import ApprovalReqs from '../components/ApprovalReqs';
import metadata from '../metadata/approvalReqsMetadata';
import {
  getApprovalReqsList,
  getApprovalReqsCount,
} from '../reducers/approvalReqsReducer';
import { APPROVE_REJECT } from '../constants/modalTypes';
import { showModal } from 'erisxkit/client';
import XTab from '../common/tabs/XTab';

const mapStateToProps = (state) => ({
  pendingApprovalReqs: getApprovalReqsList('pending')(state),
  doneApprovalReqs: getApprovalReqsList('done')(state),
  pendingApprovalReqsCount: getApprovalReqsCount('pending')(state),
  doneApprovalReqsCount: getApprovalReqsCount('done')(state),
  approvalsLoading: createLoadingSelector(['APPROVAL_REQS', 'APPROVAL_LOGS'])(
    state,
  ),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchApprovalReqs,
      fetchApprovalLogs,
      showModal,
      selectApproval,
    },
    dispatch,
  );

class ApprovalReqsContainer extends Component {
  onFetchData = (state, type) => {
    // initialize the filter to have trigger for type, this is required.
    let filter = [];
    //Promotion approvals and Margin Approval Requests are not to be shown on the Approvals screen
    filter.push({
      attr: 'sub_type',
      op: 'ne',
      value: ['promotion', 'AppliedMarginRates', 'MarginCallRequirements'],
    });

    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }
    if (type === 'pending') {
      // filter all approval reqs to state pending, don't need to see approved.
      filter.push({ attr: 'state', op: 'eq', value: 'pending' });
      filter.push({
        attr: 'member_transfer_final_approval',
        op: 'eq',
        value: 'false',
      });
      this.props.fetchApprovalReqs({
        limit: state.pageSize,
        offset: state.page * state.pageSize,
        filter,
      });
    } else {
      this.props.fetchApprovalLogs({
        limit: state.pageSize,
        offset: state.page * state.pageSize,
        filter,
      });
    }
  };

  approve = (approvalReq) => {
    this.props.selectApproval(approvalReq.uuid);
    this.props.showModal(APPROVE_REJECT, { selectedApprovalReq: approvalReq });
  };

  render = () => {
    const panes = [
      {
        url: 'pending',
        menuItem: 'Pending',
        render: ({
          pendingApprovalReqs,
          pendingApprovalReqsCount,
          approvalsLoading,
        }) => (
          <ApprovalReqs
            key="pending"
            loading={approvalsLoading}
            data={pendingApprovalReqs}
            metadata={metadata('pending')}
            approve={this.approve}
            onFetchData={(args) => this.onFetchData(args, 'pending')}
            count={pendingApprovalReqsCount}
          />
        ),
      },
      {
        url: 'done',
        menuItem: 'Done',
        render: ({
          doneApprovalReqs,
          doneApprovalReqsCount,
          approvalsLoading,
        }) => (
          <ApprovalReqs
            key="done"
            loading={approvalsLoading}
            data={doneApprovalReqs}
            metadata={metadata('done')}
            approve={this.approve}
            onFetchData={(args) => this.onFetchData(args, 'done')}
            count={doneApprovalReqsCount}
          />
        ),
      },
    ];

    return (
      <Fragment>
        <Header as="h1" content="Approval Requests" />
        <XTab
          panes={panes}
          pendingApprovalReqs={this.props.pendingApprovalReqs}
          doneApprovalReqs={this.props.doneApprovalReqs}
          pendingApprovalReqsCount={this.props.pendingApprovalReqsCount}
          doneApprovalReqsCount={this.props.doneApprovalReqsCount}
          approvalsLoading={this.props.approvalsLoading}
        />
      </Fragment>
    );
  };
}

ApprovalReqsContainer.propTypes = {
  fetchApprovalReqs: PropTypes.func.isRequired,
  fetchApprovalLogs: PropTypes.func.isRequired,
  selectApproval: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovalReqsContainer);
