import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { find, get } from 'lodash';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Divider, Form } from 'semantic-ui-react';
import {
  createLoadingSelector,
  withFilters,
  filteredArrayToAttrValue,
  datetimeOnchange,
  XTable,
  inputOnChange,
} from 'erisxkit/client';
import Table from '../../common/table/ExternalTable';
import { SURVEILLANCE_ENTRIES } from '../../constants/actionTypes';
import {
  surveillanceEntries,
  clearSurveillanceEntries,
  getSurveillanceEntries,
  getSurveillanceEntriesCount,
  getSurveillanceEntriesAggregations,
} from '../../reducers/surveillanceReducer';
import ProductContractSelection from '../../common/containers/ProductContractSelection';
import { closingPrices } from '../../actions/manualEntryActions';
import { getClosingPrices } from '../../reducers/manualEntriesReducer';
import { calculateNotional } from '../../utils/methods';
import surveillanceRules from '../../utils/surveillanceRules';

const TableWithFilter = withFilters(Table);

const AggregationTable = ({ aggregations = {} }) => (
  <Fragment>
    <XTable
      title="aggregations"
      pageSize={3}
      data={[
        {
          title: <b>Total Open Interest</b>,
          value: aggregations.totalMarketOpenInterest,
          notional: aggregations.closingPrice
            ? calculateNotional(
                aggregations.totalMarketOpenInterest,
                aggregations.closingPrice,
              )
            : '-',
        },
        {
          title: <b>Total Trade Volume</b>,
          value: aggregations.totalMarketVolume,
          notional: aggregations.closingPrice
            ? calculateNotional(
                aggregations.totalMarketVolume,
                aggregations.closingPrice,
              )
            : '-',
        },
        {
          title: <b>Settlement/Reference Price</b>,
          value: aggregations.closingPrice || '-',
        },
      ]}
      columns={[
        {
          accessor: 'title',
          width: 211,
          resizable: false,
        },
        {
          accessor: 'value',
          width: 211,
        },
        {
          Header: 'Notional',
          accessor: 'notional',
          resizable: false,
          width: 211,
        },
      ]}
      showPageSizeOptions={false}
      showPagination={false}
    />
    <Divider />
  </Fragment>
);

const filters = (subject, fetchClosingPrices, date) => [
  {
    component: ProductContractSelection,
    name: 'contract_symbol',
    contractName: 'contract_symbol',
    productName: 'product_symbol',
    id: 'contract_symbol',
    defaultValue: '',
    onChange:
      (onChange) =>
      (e, { name, value }) => {
        onChange(name, value, 'eq');
        if (name === 'contract_symbol') {
          fetchClosingPrices({
            contractSymbol: value,
            date: moment(date).format('YYYY-MM-DD'),
          });
        }
      },
    show: subject === 'large_trader' || subject === 'clearing_volume',
    required: subject === 'clearing_volume',
  },
  {
    component: Datetime,
    className: 'ui input datetime',
    label: 'Sent Date',
    name: 'sent_date',
    id: 'sent_date',
    defaultValue: '',
    dateFormat: 'YYYY-MM-DD',
    timeFormat: false,
    inputProps: { placeholder: 'Sent Date' },
    closeOnSelect: true,
    onChange: datetimeOnchange('sent_date', 'eq'),
    show: subject === 'ocr_data',
    required: true,
  },
  {
    placeholder: 'Firm',
    component: Form.Input,
    name: 'reporting_firm',
    id: 'reporting_firm',
    label: 'Firm',
    onChange: inputOnChange,
    show: subject === 'ocr_data' || subject === 'clearing_volume',
  },
];

const mapStateToProps = (state) => ({
  aggregations: getSurveillanceEntriesAggregations(state),
  entries: getSurveillanceEntries(state),
  count: getSurveillanceEntriesCount(state),
  loading: createLoadingSelector([SURVEILLANCE_ENTRIES])(state),
  contractClosingPrices: getClosingPrices(state),
});

const mapDispatchToProps = {
  surveillanceEntries,
  clearSurveillanceEntries,
  closingPrices,
};

class RegReportsContainer extends Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.state = {
      date: moment().subtract(1, 'days'),
      priorDate: moment().subtract(2, 'days'),
    };
  }

  componentWillUnmount = () => {
    // clear out the surveillance data on unmount
    this.props.clearSurveillanceEntries();
  };
  fetchData = (state = {}) => {
    const aggregate = ['total_market_open_interest', 'total_market_volume'];
    let filter = [
      {
        attr: 'report_date',
        op: 'eq',
        value: this.state.date,
      },
    ];

    if (state.filtered.length !== 0) {
      filter = filter.concat(filteredArrayToAttrValue(state.filtered));
    }

    this.props.surveillanceEntries({
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      page: state.page,
      filter,
      aggregate,
      priorDate: this.state.priorDate,
    });
  };

  showAggregations = () =>
    !!(
      this.props.subject === 'large_trader' ||
      this.props.subject === 'clearing_volume'
    );

  dateChanged = (date, name) => {
    let newDate = date;
    if (moment.isMoment(date)) {
      newDate = date.format('YYYY-MM-DD');
    }
    if (name === 'trade_date') {
      this.setState(
        {
          date: newDate,
          priorDate: moment(newDate).subtract(1, 'days'),
        },
        () => this.table.clearFilters(),
      );
    } else if (name === 'prior_date') {
      this.setState(
        {
          priorDate: newDate,
        },
        () => this.table.clearFilters(),
      );
    }
  };

  selectDate = () => {
    const props = {
      className: 'ui input datetime',
      label: 'Select the report date',
      name: 'report_date',
      id: 'report_date',
      value: this.state.date,
      dateFormat: 'YYYY-MM-DD',
      timeFormat: false,
      inputProps: { placeholder: 'Trade Date' },
      closeOnSelect: true,
      onChange: (date) => this.dateChanged(date, 'trade_date'),
      required: true,
    };

    const priorDayProps = {
      className: 'ui input datetime',
      label: 'Select prior business day',
      name: 'prior_date',
      id: 'prior_date',
      value: this.state.priorDate,
      dateFormat: 'YYYY-MM-DD',
      timeFormat: false,
      inputProps: { placeholder: 'Prior Date' },
      closeOnSelect: true,
      onChange: (date) => this.dateChanged(date, 'prior_date'),
      required: true,
    };
    return (
      <Form>
        <Form.Group>
          <Form.Field control={Datetime} {...props} />
          {this.showAggregations() && (
            <Form.Field control={Datetime} {...priorDayProps} />
          )}
        </Form.Group>
      </Form>
    );
  };
  render = () => {
    let { entries, aggregations = {}, contractClosingPrices } = this.props;

    const closingPrice =
      find(contractClosingPrices, (p) => p.date === this.state.date) || {};
    aggregations.closingPrice = closingPrice.price;

    if (this.props.subject === 'missing_ocr_data') {
      entries = this.props.entries.filter((entry) => entry.missing_102);
    }

    if (this.props.subject === 'ocr_data') {
      entries = this.props.entries.filter((entry) => !entry.missing_102);
    }
    const tableProps = {
      aggregations: this.props.aggregations,
      title: 'tasksList',
      className: '-striped',
      data: entries,
      onFetchData: this.fetchData,
      metadata: this.props.metadata,
      count: this.props.count,
      loading: this.props.loading,
      noDataText: 'No task logs found.',
      minRows: 5,
      pageSize: 100,
      filterable: false,
      filters: filters(
        this.props.subject,
        this.props.closingPrices,
        this.state.date,
      ),
      sortable: true,
      getTrProps: (state, rowInfo) => ({
        className: `${surveillanceRules(get(rowInfo, 'original', {})).length ? 'negative' : ''}`,
      }),
    };

    return (
      <Fragment>
        {this.selectDate()}
        {this.showAggregations() && (
          <AggregationTable aggregations={aggregations} />
        )}
        {
          <TableWithFilter
            wrappedComponentRef={(c) => (this.table = c)}
            {...tableProps}
          />
        }
      </Fragment>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegReportsContainer);
