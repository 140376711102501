import React, { Fragment } from 'react';
import { Header, Tab } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import XTab from '../../common/tabs/XTab';
import FeeRebateContainer from './FeeRebateContainer';
import GeneralJournalContainer from './GeneralJournalContainer';
import BalanceAdjustmentContainer from './BalanceAdjustmentContainer';
import ClosingPricesContainer from './ClosingPricesContainer';
import TradeEntryContainer from './TradeEntryContainer';
import BankCommunicationContainer from './BankCommunicationContainer';
import AddCollateralContainer from './AddCollateralContainer';
import DesignationTransferContainer from './DesignationTransferContainer';
import ExternalTransferContainer from './ExternalTransferContainer';
import { getEnv } from '../../reducers/envReducer';

const mapStateToProps = (state) => ({
  env: getEnv(state),
});

const panes = (env) => [
  {
    menuItem: 'Closing Prices',
    url: 'closing_prices',
    render: () => (
      <Tab.Pane attached={false}>
        <ClosingPricesContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'fee_rebate',
    menuItem: 'Fee Rebate',
    render: () => (
      <Tab.Pane attached={false}>
        <FeeRebateContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'trade_entry',
    menuItem: 'Trade Entry',
    render: () => (
      <Tab.Pane attached={false}>
        <TradeEntryContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'bank_communication',
    menuItem: 'Bank Communication',
    render: () => (
      <Tab.Pane attached={false}>
        <BankCommunicationContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'tme_adjustment',
    menuItem: 'TME Adjustment',
    render: () => (
      <Tab.Pane attached={false}>
        <BalanceAdjustmentContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'general_journal',
    menuItem: 'General Journal',
    render: () => (
      <Tab.Pane attached={false}>
        <GeneralJournalContainer />
      </Tab.Pane>
    ),
  },
  {
    url: 'designation_transfer',
    menuItem: 'Designated Funds Transfer',
    render: () => (
      <Tab.Pane attached={false}>
        <DesignationTransferContainer />
      </Tab.Pane>
    ),
    // temporarily hiding this tab per product team request
    show: false,
  },
  {
    url: 'add_collateral',
    menuItem: 'Collateral',
    render: () => (
      <Tab.Pane attached={false}>
        <AddCollateralContainer />
      </Tab.Pane>
    ),
    show: env.enableAddCollateralView === 'true',
  },
  {
    url: 'external_transfer',
    menuItem: 'External Transfer',
    render: () => (
      <Tab.Pane attached={false}>
        <ExternalTransferContainer />
      </Tab.Pane>
    ),
  },
];

const ManualEntryContainer = ({ env = {} }) => (
  <Fragment>
    <Header as="h1">Manual Entry</Header>
    <XTab panes={panes(env)} authorize />
  </Fragment>
);

ManualEntryContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps, null)(ManualEntryContainer);
