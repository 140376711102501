import { useState, useEffect } from 'react';
import { camelCase } from 'change-case-object';

const useEditableTableFields = (data = []) => {
  // State variables
  const [editedRowId, setEditedRowId] = useState('');
  const [editedField, setEditedField] = useState('');
  const [editableData, setEditableData] = useState(data);
  const [hasEdits, setHasEdits] = useState(false);

  // Utility functions
  const onReset = () => {
    setEditableData(data);
    setHasEdits(false);
    setEditedField('');
  };

  const onEditClicked = (row, id, field) => {
    setEditedRowId(id);
    setEditedField(field);
  };

  const isFieldBeingEdited = (id, fieldName) =>
    id === editedRowId && fieldName === editedField;

  // Resets edit state when user clicks out of input
  const onBlur = () => {
    setEditedRowId('');
    setEditedField('');
  };

  // Sets a new data list with the corresponding row modified
  const onSetValue = (id, field, value) => {
    const camelCasedField = camelCase(field);
    const newTempData = editableData.map((item, index) =>
      index === id ? { ...item, [camelCasedField]: value } : item,
    );
    setEditableData(newTempData);
    if (!hasEdits) setHasEdits(true);
  };

  // Reinitialize state if data list changes
  useEffect(() => {
    setEditableData(data);
  }, [data]);

  return {
    onReset,
    onEditClicked,
    onBlur,
    onSetValue,
    isFieldBeingEdited,
    editableData,
    hasEdits,
  };
};

export default useEditableTableFields;
