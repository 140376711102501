import _, { countBy, zip } from 'lodash';
import sign from 'eris-ecdsa';
import { fundsDesignation, format } from 'erisxkit/client';
import BigNumber from 'bignumber.js';
import { paramCase } from 'change-case';
import * as userTypes from '../constants/userTypes';
import * as memberTypes from '../constants/memberTypes';
import navItems from '../constants/navToTabs';
import taskDebugArgs from '../constants/taskDebugArgs';
import nonDefaultLedgerPages from '../constants/nonDefaultLedgerPages';
import entitlements from '../constants/entitlements';
import riskAlertStatuses from '../constants/riskAlertStatuses';
import * as statuses from '../constants/statuses';

export const INVALID_CIDR_IP = 'Invalid CIDR IP Address';

// copy an object to an array x number of times.
// used to mock out data, integration_fixtures only have one item.
export const times = (x) => (item, acc) => {
  if (x > 0) {
    acc.push(item);
    times(x - 1)(item, acc);
  }
  return acc;
};

// take account balances from the backend, format into
//  [{ assetType: "foo", value: 4.2 },...]
export const formatBalances = (balances) =>
  _.keys(balances)
    .sort()
    .map((asset) => ({ assetType: asset, value: balances[asset] }));

export const assetTypesFromBalance = (account) =>
  _.map(_.keys(_.get(account, ['balances'], [])), (assetType) =>
    assetType.toUpperCase(),
  );

// helper function for generator functions/sagas: yield delay(<timeout>); yield foo();
export const delay = async (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const categoryFromAssetOption = (asset = '', assetTypesOptions = []) =>
  _.get(
    assetTypesOptions.filter(
      (opt) => opt.key.toUpperCase() === asset.toUpperCase(),
    ),
    ['0', 'isfiat'],
  )
    ? 'fiat'
    : 'crypto';
// sign msg to send to tx auth
export const signMsg = (id, password, msg) => {
  let message = msg;
  if (typeof msg !== 'string') {
    message = JSON.stringify(msg);
  }
  return sign.signMsgBs58(message, sign.privateKeyFromPassword(id, password));
};

// Check to see that the user is both an admin and is not a clearing member
export const isAdmin = (categories) =>
  _.includes(categories, userTypes.employee) &&
  !_.includes(categories, userTypes.memberUser);

export const isMember = (categories) =>
  _.includes(categories, userTypes.memberUser) &&
  !_.includes(categories, userTypes.employee);

function snakeToCamel(s) {
  const normalized = s.replace(/\//g, '');
  return normalized.replace(/(_\w)/g, (m) => m[1].toUpperCase());
}

const hasPermission = (uiViews, view, camelRoute) => {
  if (uiViews[view].read) {
    if (navItems[camelRoute]) {
      return navItems[camelRoute].includes(view);
    }
    return camelRoute.includes(view);
  }
  return false;
};

export const isAuthorized = (uiViews, route, altRoute = '') =>
  uiViews
    ? Object.keys(uiViews).some((subject) => {
        // don't check authorization for home route
        if (
          route.includes('home') ||
          route === '/' ||
          route === '/profile' ||
          route.includes('transfer') ||
          route.includes('risk')
        ) {
          return true;
        }
        // change route to camelCase since the uiViews get automatically converted
        const camelRoute = snakeToCamel(route);
        if (hasPermission(uiViews, subject, camelRoute)) return true;
        if (altRoute)
          return hasPermission(uiViews, subject, snakeToCamel(altRoute));
        return false;
      })
    : true;

export const isUserRoleRestricted = (userRoles = [], restrictedRoles = []) =>
  userRoles.some((role) => restrictedRoles.includes(role));

export const isLedgerSensitive = (ledgerId, route) => {
  if (!ledgerId) {
    return true;
  }
  return nonDefaultLedgerPages.includes(route);
};

// Mock change events for inputs
export const eventCreator = (value) => ({ target: { value } });

export const changeInputByName = (wrapper, args) => (name) => {
  wrapper
    .find(`input[name="${name}"]`)
    .simulate('change', eventCreator(args[name]));
};

export const changeSelectByName = (wrapper, args) => (name) => {
  wrapper
    .find(`Dropdown[name="${name}"]`)
    .find(`DropdownItem[value="${args[name]}"]`)
    .simulate('click');
};

export const getAccountIdFromURL = () => {
  const parsedUrl = new URL(window.location.href);
  const match = /[a-zA-z_]+\/([-A-z0-9]*)/.exec(parsedUrl.pathname);
  if (!match) return '';
  return match[1];
};

export const iconColorForState = (state) =>
  ({
    active: 'green',
    pending: 'yellow',
  })[state];

export const getFundsDesignationTextByValue = (value) =>
  _.chain(fundsDesignation)
    .filter((fd) => fd.value === value)
    .get(['0', 'text'])
    .value();

// format negative numberic strings by checking if a hyphen exists. can also be forced
export const formatNegative = (value = '', force) =>
  value.indexOf('-') !== -1 || force ? `(${value.replace('-', '')})` : value;

// given a zero or "-0", replace with an emdash
export const formatZero = (value) => (Math.sign(value) !== 0 ? value : '—');

// If no closing price is given or useNotional is false, return given value
export const calculateNotional = (px, closingPx, useNotional = true) =>
  !_.isEmpty(closingPx) && useNotional
    ? BigNumber(closingPx).times(BigNumber(px)).toFixed()
    : px;

// Add more asset types as needed. Returns given string by default if no match is found.
export const mapAssetTypeToSymbol = (assetType) => {
  switch (assetType) {
    case 'USD':
      return '$';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'JPY':
      return '¥';
    default:
      return assetType;
  }
};

export const formatUSD = (str) =>
  format(str, { type: 'fiat', currency: 'USD' });

export const formatTask = (task) =>
  _.chain(task)
    .forEach((o, key) => {
      _.set(o, 'key', key);
      _.set(o, 'argument', paramCase(key));
    })
    .filter((o) => taskDebugArgs.indexOf(o.key) === -1)
    .value();

// return just the values that are different between obj1 and obj2.
export const shallowObjectDiff = (obj1, obj2) =>
  _.omitBy(obj1, (v, k) => v === obj2[k]);

export const accountHasAncestors = ({ ancestors = [] }) => !!ancestors.length;

export const accountHasDescendants = ({ descendants = [] }) =>
  !!descendants.length;

export const isMasterAccount = (account) =>
  !accountHasAncestors(account) && accountHasDescendants(account);

export const isParentAccount = (account) =>
  accountHasAncestors(account) && accountHasDescendants(account);

// check if a set of data has any entitlements
export const hasEntitlements = (values) =>
  _.some(entitlements, (accessor) => _.get(values, accessor));

// prettify labels, taking an optional labels object for manual override
export const formatLabel = (attr, labels = {}) => {
  if (_.get(labels, attr)) {
    return labels[attr];
  }
  return _.startCase(attr);
};

export const getRiskAlertStatusTextByStatus = (status = '') => {
  const riskAlertStatusItem = riskAlertStatuses.find(
    (x) => x.value === _.trim(status),
  );
  return riskAlertStatusItem?.text;
};

export const getRiskAlertStatusTextByStatusText = (statusText = '') => {
  const riskAlertStatusItem = riskAlertStatuses.find(
    (x) => x.text === _.trim(statusText),
  );
  return riskAlertStatusItem?.text;
};

export const getRiskAlertStatusTextByStatusValue = (statusText = '') => {
  const riskAlertStatusItem = riskAlertStatuses.find(
    (x) => x.text === _.trim(statusText),
  );
  return riskAlertStatusItem?.value;
};

export const nullToString = (value) => value || '';

export const concatStrings = (arr) => {
  let result = '';
  arr.forEach((x) => {
    const value = nullToString(x);
    result += value === '' ? value : `${value} `;
  });
  return result.trim();
};

export const sortTimeArrayNewerFirst = (array) => {
  if (array.length > 0) {
    array.sort((a, b) => {
      if (!a.time || !b.time) return -1;
      return new Date(b.time) - new Date(a.time);
    });
  }
  return array;
};
export const getNameFromMemberTypeCode = (code) => {
  if (!code) {
    return '';
  }

  const name = '';

  const result = Object.values(memberTypes).find((x) => x.code === code);
  return result ? result.name : name;
};

export const getCodeFromMemberType = (type) => {
  if (!type) {
    return undefined;
  }

  const code = '';

  const result = Object.values(memberTypes).find((x) => x.type === type);
  return result ? result.code : code;
};

export const removeAllWhiteSpaces = (str) => str.replace(/\s/g, '');

export const roundNumber = (number, decimals) =>
  new BigNumber(number).toFixed(decimals);

export const employeeToOptions = (employees) =>
  _.map(employees, (o) => ({
    key: o.userId,
    value: o.userId,
    text: o.email,
    description: o.userId,
  }));

export const cidrIP = (value) =>
  value &&
  !/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)([/][0-3][0-2]?|[/][1-2][0-9]|[/][0-9])?$/.test(
    value,
  )
    ? INVALID_CIDR_IP
    : undefined;

//Approval Requests Page - BEGIN
export const formatMoneyDescriptionRows = (description) => {
  description = description?.split('\n');
  description = description?.map((x) => x.trim());
  description = description?.join('\n');
  description = _.replace(description, /\n/g, ' ');
  const splitted = description?.split(' ');
  let index = -1;
  let newIndex = 0;
  let amount = 0;

  const y = _.countBy(splitted);
  const countUSD = y.USD;

  //If in the string description does not exists USD return the current description
  if (!countUSD) {
    return description;
  }

  for (let i = 0; i < countUSD; i++) {
    index = splitted?.indexOf('USD', index + 1);

    if (index > -1) {
      newIndex = index - 1;
      amount = splitted[newIndex];
      //Some times the currency comes with a N after the number like: "1000 N USD" in that case we get the number before the N
      if (isNaN(amount) && amount.toUpperCase() === 'N') {
        newIndex--;
        amount = splitted[newIndex];
      }
      amount = format(amount, { type: 'fiat', currency: 'USD', truncate: 2 });
      splitted[newIndex] = amount;
    }
  }

  return splitted.join(' ');
};

export const formatMoneyDescriptionFiatTransaction = (description) => {
  if (!description) return '';

  const splitted = description.split(' ');
  const index = splitted.findIndex((x) => x === 'USD');
  let amount = splitted[index - 1];
  amount = format(amount, { type: 'fiat', currency: 'USD', truncate: 2 });
  splitted[index - 1] = amount;
  return splitted.join(' ');
};

export const formatMoneyDescriptionGeneralJournal = (description) => {
  if (!description) return '';

  let descriptionToShow = description?.split('\n');
  if (descriptionToShow.length > 1) {
    descriptionToShow[1] = descriptionToShow[1]?.trim();
    descriptionToShow[2] = descriptionToShow[2]?.trim();
    const firstLine = descriptionToShow[1]?.split(' ');
    const secondLine = descriptionToShow[2]?.split(' ');
    if (firstLine && secondLine) {
      firstLine[1] = format(firstLine[1], {
        type: 'fiat',
        currency: 'USD',
        truncate: 2,
      });
      secondLine[1] = format(secondLine[1], {
        type: 'fiat',
        currency: 'USD',
        truncate: 2,
      });
      descriptionToShow[1] = firstLine.join(' ');
      descriptionToShow[2] = secondLine.join(' ');
    }
  }
  return descriptionToShow.join('\n');
};

export const formatMoneyPayloadGeneralJournal = (generalJournalItems) => {
  return generalJournalItems?.map((x) => ({
    ...x,
    amount: format(x.amount, { type: 'fiat', currency: 'USD', truncate: 2 }),
  }));
};
//Approval Requests Page - END

//PositionTransferPage - Begin
export const calculateFinalPositionTransfer = (current, pending, transfer) => {
  const res = isNaN(
    transformToTwoDecimal(current) -
      transformToTwoDecimal(pending) -
      transformToTwoDecimal(transfer),
  )
    ? '0'
    : transformToTwoDecimal(current) -
      transformToTwoDecimal(pending) -
      transformToTwoDecimal(transfer);
  return res !== '0' ? res.toFixed(2) : res;
};

export const deleteRowIfNotChecked = (tableState = {}, checked = {}) => {
  const filteredTableState = {};
  const filteredArray = Object.values(tableState).filter((x) =>
    Object.keys(checked).includes(x.index.toString()),
  );

  //Convert to Object
  filteredArray.forEach((x) => {
    filteredTableState[x.index.toString()] = x;
  });

  return filteredTableState;
};

export const transformToTwoDecimal = (number) => {
  if (!number || number === '') {
    return 0;
  }
  const bigNumber = new BigNumber(number);
  return Number(bigNumber.toFixed(2));
};

export const roundToNearestInteger = (number) => {
  if (!number || number === '') return 0;
  const rounded = Math.round(number);
  return Number.isNaN(rounded) ? 0 : rounded;
};

export const getStatusName = (code) => {
  const status = Object.values(statuses).find((status) => status.code === code);
  return status ? status.name : 'NotDefined';
};
//PositionTransferPage - END

export const capitalizeCamelCase = (input) => {
  const camelCaseString = _.camelCase(input);
  return camelCaseString.charAt(0).toUpperCase() + camelCaseString.slice(1);
};
