import _ from 'lodash';
import Big from 'bignumber.js';
import React, { Fragment } from 'react';
import {
  List,
  Icon,
  Button,
  Popup,
  Input,
  Message,
  Label,
} from 'semantic-ui-react';
import { columns, format } from 'erisxkit/client';
import get from 'lodash/get';
import { memberColumn } from '../common/table/commonMetadata';
import { isMasterAccount, isParentAccount } from '../utils/methods';
import {
  DateRangeColumnFilter,
  SelectColumnFilter,
} from '../common/table/Filters';
import balanceLockTypes from '../constants/balanceLockTypes';
import amlTypes from '../constants/amlTypes';
import booleanTypes from '../constants/booleanTypes';
import { NavLink } from 'react-router-dom';

export const getTotalUSDValues = (balances) => {
  if (!Array.isArray(balances)) {
    return '';
  }
  return balances
    .reduce((total, balance) => {
      if (balance.usdValue !== undefined)
          total = total.plus(Big(balance.usdValue));
      return total;
    }, Big(0))
    .toFixed();
};

const createBalancesMetadata = (assetTypes = [], balances) =>
  assetTypes.map((assetType) => {
    const symbol = _.get(assetType, 'symbol', '');
    return {
      Header: symbol,
      id: symbol,
      hideFilter: true,
      accessor: (d) =>
        format(
          _.get(
            _.find(d.balancesUSD, { assetType: symbol.toUpperCase() }),
            'amount',
            0,
          ),
        ), // Have to lowercase since our api converts everything to camelCase, thus lower-casing the assetTypes.
      className: 'mono',
      filterable: false,
      show: !!balances,
    };
  });

const clearingAccounts = (
  updateAccount,
  handleChange,
  comment,
  onClose,
  balances,
  assetTypesList,
  wizardView,
  remove,
  RIA = { isRIA: false, memberId: '' },
  popup,
) => {
  // On XTable7 columns that are not to be shown (show: false) could be shown with the show/hide columns selector
  // So we have to add/remove those optional columns to the standard column list
  const riaColumn = {
    Header: 'Access Type',
    id: 'authorized',
    width: 180,
    Cell: (props) => {
      const accountMember = get(props, 'original.memberId', '');
      const accessType =
        accountMember === RIA.memberId ? 'Owner' : 'Authorized';
      return <div style={{ textAlign: 'center' }}>{accessType}</div>;
    },
    show: RIA && get(RIA, 'isRIA', false),
  };

  const removeCol = {
    Header: '',
    id: 'actions',
    show: !!wizardView,
    Cell: ({ row }) => (
      <Button
        className="link"
        content="Remove"
        onClick={() => remove(row.original.accountId)}
      />
    ),
    resizable: false,
  };

  const stdColumnsPartOne = [
    {
      Header: 'Net Settled',
      width: 120,
      id: 'net_settled',
      accessor: (e) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input type="checkbox" checked={e?.netSettled} disabled />
        </div>
      ),
      filterOp: 'eq',
      Filter: SelectColumnFilter,
      options: booleanTypes,
    },
    {
      Header: 'Firm Code',
      id: 'firm_code',
      hideFilter: true,
      getProps: () => ({
        onClick: () => {},
      }),
      Cell: (d) => (
        <span className="table-filter-cell">
          <a
            className="table-filter-text"
            href="#"
            onClick={() => {
              d.setFilter('firm_code', d.row.original.firmCode);
            }}
          >
            {d.row.original.firmCode}
          </a>
        </span>
      ),
      width: 100,
    },
    {
      Header: 'Account Code',
      id: 'account_code',
      accessor: (d) => d.accountCode,
      width: columns.ACCOUNT_CODE,
      filterable: true,
      hideFilter: true,
    },
    {
      Header: 'Sub Account Code',
      id: 'sub_account_code',
      accessor: (d) => d.subAccountCode,
      width: columns.SUB_ACCOUNT_CODE,
      hideFilter: true,
    },
    {
      textAlign: 'center',
      Header: 'M/P',
      id: 'masterOrParent',
      hideFilter: true,
      Cell: (d) =>
        (isMasterAccount(d) && <Label content="M" circular color="orange" />) ||
        (isParentAccount(d) && <Label content="P" circular color="blue" />),
      width: 40,
    },
    {
      Header: 'Account Name',
      id: 'name',
      accessor: (d) => d.name,
      width: columns.ACCOUNT_NAME,
      hideFilter: true,
    },
  ];

  const balancesColumns = [
    {
      Header: 'Reg Type',
      id: 'funds_designation',
      accessor: (d) => d.fundsDesignation,
      width: 225,
    },
    {
      Header: 'Total USD Value',
      id: 'balances',
      hideFilter: true,
      width: 225,
      accessor: (d) =>
        `$${format(getTotalUSDValues(_.get(d, ['balancesUSD'], [])))}`,
      show: !!balances,
      className: 'pull-left mono',
      filterable: false,
    },
    ...createBalancesMetadata(assetTypesList, balances),
  ];

  const stdColumnsPartTwo = [
    {
      Header: 'State',
      id: 'locationState',
      width: 225,
      accessor: (d) => d.locationState,
      filterOp: 'eq',
    },
    memberColumn({
      filterable: false,
      accessor: 'row',
      hideFilter: true,
    }),
    {
      Header: 'Users',
      id: 'users',
      width: 225,
      Cell: (e) => (
        <List
          items={_.get(e.row.original, 'memberUsers', []).map((u) => (
            <NavLink
              target="_blank"
              className="flex"
              key={u.userId}
              to={`/member_users/${u.userId}`}
            >
              {u.email}
            </NavLink>
          ))}
        />
      ),
      filterable: false,
      show: !balances,
    },
    {
      Header: 'E-Market Account',
      id: 'emarket_account_number',
      width: 225,
      accessor: (d) => d.emarketAccountNumber,
      show: !balances,
      className: 'currenex',
      headerClassName: 'currenex',
    },
    {
      Header: 'AML',
      id: 'aml_state',
      filterable: false,
      Filter: SelectColumnFilter,
      options: amlTypes,
      filterOp: 'match',
      width: 45,
      accessor: (row, index) => (
        <Popup
          trigger={
            <Icon
              link
              name={row.amlState === 'flagged' ? 'flag red' : 'flag outline'}
            />
          }
          content={
            <Fragment>
              <Message info>
                <Message.Header>Comments:</Message.Header>
                <p>{row.amlComment}</p>
              </Message>
              <div className="flex-row">
                <Input
                  name="comment"
                  value={comment}
                  placeholder="Enter Comment"
                  required
                  onChange={handleChange}
                />
                <Button
                  disabled={!comment}
                  labelPosition="right"
                  color={row.amlState === 'flagged' ? 'green' : 'red'}
                  icon="flag"
                  onClick={() => updateAccount(row, 'aml')}
                  content={row.amlState === 'flagged' ? 'Unflag' : 'Flag'}
                />
              </div>
            </Fragment>
          }
          open={popup?.rowPopupState[`popup_aml_${index}`]}
          on="click"
          onClose={() => popup?.close(index, 'aml')}
          onOpen={() => popup?.open(index, 'aml')}
        />
      ),
    },
    {
      Header: 'Lock',
      id: 'locked_state',
      filterable: false,
      width: 70,
      filterOp: 'match',
      Filter: SelectColumnFilter,
      options: balanceLockTypes,
      accessor: (row, index) => (
        <Popup
          onClose={onClose}
          trigger={
            <Icon
              link
              name={
                row.lockedState === 'locked' ? 'lock red' : 'unlock alternate'
              }
            />
          }
          content={
            <Fragment>
              <Message info>
                <Message.Header>Comments:</Message.Header>
                <p>{row.lockedComment}</p>
              </Message>
              <div className="flex-row">
                <Input
                  name="comment"
                  value={comment}
                  placeholder="Enter Comment"
                  required
                  onChange={handleChange}
                />
                <Button
                  disabled={!comment}
                  labelPosition="right"
                  color={row.lockedState === 'locked' ? 'green' : 'red'}
                  icon="lock"
                  onClick={() => updateAccount(row, 'locked')}
                  content={row.lockedState === 'locked' ? 'Unlock' : 'Lock'}
                />
              </div>
            </Fragment>
          }
          on="click"
          open={popup?.rowPopupState[`popup_lock_${index}`]}
          onClose={() => popup?.close(index, 'lock')}
          onOpen={() => popup?.open(index, 'lock')}
        />
      ),
    },
  ];

  const finalColumns = balances
    ? [...stdColumnsPartOne, ...balancesColumns, ...stdColumnsPartTwo]
    : [...stdColumnsPartOne, ...stdColumnsPartTwo];

  if (RIA.isRIA) finalColumns.unshift(riaColumn);
  if (wizardView) finalColumns.push(removeCol);

  return finalColumns;
};

export default clearingAccounts;
