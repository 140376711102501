import React, { Fragment } from 'react';
import { Button, Modal, Popup, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { TaskDetails } from '../SystemLogs/TaskLog';

const DeliveryModal = ({
  confirmRunDelivery,
  downloadTaskLog,
  dryRun,
  handlePopupClose,
  handlePopupOpen,
  hideModal,
  loading,
  popupOpen,
  taskLogs,
}) => (
  <Fragment>
    <Modal.Header>
      Delivery{' '}
      <Button
        className="pull-right"
        icon="close"
        circular
        onClick={() => hideModal()}
      />
    </Modal.Header>
    <Modal.Content>
      <Modal.Description>
        {dryRun
          ? `Dry run of delivery task shown below.
            Confirm that the output is correct and click "Run Delivery".`
          : 'Delivery task output shown below.'}
      </Modal.Description>
      <Segment loading={loading} basic>
        <TaskDetails hideTimestamp showDebug taskLogs={taskLogs} />
      </Segment>
    </Modal.Content>
    <Modal.Actions>
      <Button
        content="Download Delivery Log"
        disabled={loading}
        floated="left"
        id="downloadButton"
        loading={loading}
        onClick={() => downloadTaskLog()}
        primary
      />
      <Button
        id="closeButton"
        loading={!dryRun && loading}
        content={dryRun ? 'Cancel' : 'OK 😊'}
        onClick={() => hideModal()}
      />
      {dryRun && (
        <Popup
          content={
            <Fragment>
              <h4>Are you sure?</h4>
              <Button.Group fluid>
                <Button onClick={() => handlePopupClose()}>No</Button>
                <Button
                  id="confirmRunDelivery"
                  color="green"
                  onClick={() => {
                    confirmRunDelivery();
                    handlePopupClose();
                  }}
                >
                  Yes
                </Button>
              </Button.Group>
            </Fragment>
          }
          on="click"
          onClose={() => handlePopupClose()}
          onOpen={() => handlePopupOpen()}
          open={popupOpen}
          position="bottom center"
          trigger={
            <Button
              id="runDelivery"
              color="red"
              disabled={loading}
              loading={loading}
              content="Run Delivery"
            />
          }
        />
      )}
    </Modal.Actions>
  </Fragment>
);

DeliveryModal.propTypes = {
  confirmRunDelivery: PropTypes.func.isRequired,
  downloadTaskLog: PropTypes.func.isRequired,
  dryRun: PropTypes.bool,
  handlePopupClose: PropTypes.func.isRequired,
  handlePopupOpen: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  popupOpen: PropTypes.bool,
  taskLogs: PropTypes.arrayOf(PropTypes.object),
};

DeliveryModal.defaultProps = {
  dryRun: true,
  loading: false,
  popupOpen: false,
  taskLogs: [],
};

export default DeliveryModal;
