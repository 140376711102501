import React from 'react';
import { Label } from 'semantic-ui-react';
import { getColorFromUserState } from 'erisxkit/client';
import { formatTimeLocal } from '../utils/time';
import {
  SelectColumnFilter,
  EmployeeColumnFilter,
  NamesFilter,
  DateRangeColumnFilter,
} from '../common/table/Filters';
import * as USER_STATES from '../constants/userStates';
import { Icon } from 'semantic-ui-react';
import EmployeeSelectionContainer from '../common/containers/EmployeeSelectionContainer';
import { employee } from '../constants/userTypes';
import { concatStrings } from '../utils/methods';

export default ({ onClick, handleAssignedEmployeeUserChange, options }) => {
  return [
    {
      Header: 'Email',
      id: 'email',
      accessor: 'email',
      width: 300,
      Cell: ({ row }) => (
        <span>
          <p className="text-link" onClick={() => onClick(row.original)}>
            {row.original.email}
          </p>
        </span>
      ),
    },
    {
      Header: 'Created Date',
      id: 'created_at',
      width: 200,
      accessor: (u) => formatTimeLocal(u.createdAt),
      Filter: DateRangeColumnFilter,
      hideFilter: true,
    },
    {
      Header: 'Updated At',
      id: 'updated_at',
      width: 200,
      accessor: (u) => formatTimeLocal(u.updatedAt),
      Filter: DateRangeColumnFilter,
      hideFilter: true,
    },
    {
      Header: 'State',
      id: 'state',
      accessor: (d) => (
        <Label content={d.state} color={getColorFromUserState(d.state)} />
      ),
      width: 200,
      Filter: SelectColumnFilter,
      options: Object.values(USER_STATES),
      getCellExportValue: ({ original = {} }) =>
        original.state || 'No State Provided',
    },
    {
      Header: 'Member Name',
      id: 'member_name',
      width: 200,
      Filter: NamesFilter,
      accessor: (u) => concatStrings([u.firstName, u.middleName, u.lastName]),
    },
    {
      Header: 'Assigned To',
      id: 'assigned_employee_user_id',
      width: 280,
      Filter: EmployeeColumnFilter,
      Cell: ({ row }) => (
        <span className="full-width">
          <EmployeeSelectionContainer
            additionalFilters={[
              { attr: 'type', op: 'eq', value: employee },
              { attr: 'disabled', op: 'eq', value: 'false' },
            ]}
            name="assignedEmployeeUserId"
            placeholder="Select assigned"
            className="ddlOnboardingEmployees-width"
            label=""
            options={options}
            input={{
              onChange: (e) =>
                handleAssignedEmployeeUserChange(row.original, { value: e }),
              value: row.original.assignedEmployeeUserId,
            }}
          />
        </span>
      ),
    },
    {
      Header: 'Notes',
      id: 'notes',
      width: 100,
      className: 'text-center mono',
      hideFilter: true,
      Cell: ({ row }) => (
        <span className="full-width">
          {row.original.hasNotes && (
            <Icon name="plus circle" className="blue-circle" />
          )}
        </span>
      ),
    },
  ];
};
