import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import cogoToast from 'cogo-toast';
import { isEmpty, some } from 'lodash';
import * as actionTypes from '../constants/actionTypes';
import api, * as urls from '../api';
import {
  createGeneralJournal,
  generalJournalCategories,
  createTrade,
  closingPrices,
  updateClosingPrice,
  createCryptoWithdrawalRequest,
  createFiatWithdrawalRequest,
  createDesignationTransferJournal,
} from '../actions/manualEntryActions';
import {
  republishBatch,
  fetchTradeEntryCgmsBuySide,
  fetchTradeEntryCgmsSellSide,
} from '../reducers/manualEntriesReducer';
import { fetchTaskLog, triggerTask } from '../reducers/taskLogReducer';
import { createSaga } from '../utils/createSaga';
import { delay } from '../utils/methods';
import {
  TASK_TRIGGER_POLLING_INTERVAL,
  TASK_TRIGGER_POLLING_MAX_RETRY,
} from '../constants/timeouts';
import { getActiveLedgerId } from '../reducers/ledgersReducer';

function* createFeeRebate({ args }) {
  const loadingCallback = cogoToast.loading('Creating approval request...', {
    hideAfter: 0,
  });
  try {
    const newFeeRebate = yield call(
      api.post,
      urls.CREATE_FEE_REBATE_API_ENDPOINT,
      args,
    );
    loadingCallback();
    yield put({ type: actionTypes.CREATE_FEE_REBATE_SUCCESS, newFeeRebate });
    cogoToast.info('Approval request for a new fee rebate created.');
  } catch (e) {
    loadingCallback();
    yield put({
      type: actionTypes.CREATE_FEE_REBATE_FAILED,
      payload: e.response,
    });
  }
}

function* createGeneralJournalRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    'Creating approval request for journal...',
    { hideAfter: 0 },
  );
  try {
    const ledgerId = yield select(getActiveLedgerId);
    yield call(api.post, urls.CREATE_GENERAL_JOURNAL_API_ENDPOINT, {
      ...payload,
      ledgerId,
    });
    loadingCallback();
    yield put(createGeneralJournal.success());
    cogoToast.info('Approval request for a new journal created.');
  } catch (e) {
    loadingCallback();
    yield put(createGeneralJournal.failure(e.response));
  }
}

function* generalJournalCategoriesRequest() {
  try {
    yield put(generalJournalCategories.request());
    const categories = yield call(
      api.post,
      urls.GENERAL_JOURNAL_CATEGORIES_API_ENDPOINT,
    );
    yield put(generalJournalCategories.success(categories));
  } catch (e) {
    yield put(generalJournalCategories.failure(e.response));
  }
}

function* createTradeRequest({ payload }) {
  const loadingCallback = cogoToast.loading('Submitting manual trade...', {
    hideAfter: 0,
  });
  try {
    const tradeEntryResponse = yield call(
      api.post,
      urls.CREATE_TRADE_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(createTrade.success());

    if (!tradeEntryResponse.reqApprovals) {
      cogoToast.success('Manual trade submitted!');
    } else {
      cogoToast.info('An approval request for your trade has been submitted.');
    }
  } catch (e) {
    loadingCallback();
    yield put(createTrade.failure(e.response));
  }
}

function* closingPricesRequest({ payload }) {
  try {
    const closingPricesList = yield call(
      api.post,
      urls.CLOSING_PRICES_API_ENDPOINT,
      payload,
    );
    yield put(closingPrices.success(closingPricesList));
  } catch (e) {
    yield put(closingPrices.failure(e.response));
  }
}

function* updateClosingPriceRequest({ payload }) {
  const loadingCallback = cogoToast.loading(
    `Submitting closing price of ${payload.price} for ${payload.symbol}...`,
    { hideAfter: 0 },
  );
  try {
    yield call(api.post, urls.UPDATE_CLOSING_PRICE_API_ENDPOINT, payload);
    loadingCallback();
    yield put(updateClosingPrice.success());
    cogoToast.success('Closing price updated!');
  } catch (e) {
    loadingCallback();
    yield put(updateClosingPrice.failure(e.response));
  }
}

function* republishBatchSaga({ payload }) {
  const loadingCallback = cogoToast.loading(
    `Republishing batch process with incremental id: ${payload.incrementalId}...`,
    { hideAfter: 0 },
  );
  try {
    yield call(api.post, urls.REPUBLISH_BATCH_API_ENDPOINT, payload);
    loadingCallback();
    yield put(republishBatch.success());
    cogoToast.success('Batch process successfully republished!');
  } catch (e) {
    loadingCallback();
    yield put(republishBatch.failure(e.response));
  }
}

function* triggerTaskRequest({ payload }) {
  yield put(triggerTask.request());
  const loadingCallback = cogoToast.loading(`Running task ${payload.name}...`, {
    hideAfter: 0,
  });
  try {
    const runningTask = yield call(
      api.post,
      urls.TRIGGER_TASK_API_ENDPOINT,
      payload,
    );
    loadingCallback();
    yield put(triggerTask.success({ ...runningTask, tries: 0 }));
  } catch (e) {
    loadingCallback();
    yield put(triggerTask.failure(e.response));
  }
}

function* pollTask({ payload }) {
  yield put(fetchTaskLog.request());
  const loadingCallback = cogoToast.loading(
    `Task ${payload.taskId} running...${payload.tries ? ` (Retried ${payload.tries} time(s))` : ''}`,
    { hideAfter: 0 },
  );
  try {
    const taskLog = yield call(api.post, urls.TASK_LOG_API_ENDPOINT, payload);
    // if we receive a message containing a success, fail, or result, stop polling
    if (
      some(
        taskLog,
        ({ type }) => ['success', 'fail', 'result'].indexOf(type) !== -1,
      )
    ) {
      loadingCallback();
      cogoToast.info(`Task ${payload.taskId} completed.`);
      yield put(fetchTaskLog.success({ taskLog, taskId: payload.taskId }));
    } else if (payload.tries < TASK_TRIGGER_POLLING_MAX_RETRY) {
      // we did not receive a message signaling the end of the task log output.
      // wait for a specified interval, stop the toast, and dispatch both the previous task log
      //  output and an action to poll again.
      yield delay(TASK_TRIGGER_POLLING_INTERVAL);
      loadingCallback();
      yield all([
        put(fetchTaskLog.success({ taskLog, taskId: payload.taskId })),
        put(
          triggerTask.success({
            ...payload,
            tries: isEmpty(taskLog) ? payload.tries + 1 : 0,
          }),
        ),
      ]);
    } else {
      // maximum retry met; communicate as failed.
      loadingCallback();
      yield put(
        fetchTaskLog.failure({
          data: {
            error: `Error: task did not trigger after maximum number of tries (${payload.tries} attempts)`,
          },
        }),
      );
    }
  } catch (e) {
    loadingCallback();
    yield put(fetchTaskLog.failure(e.response));
  }
}

const createCryptoWithdrawalRequestSaga = createSaga(
  createCryptoWithdrawalRequest,
  actionTypes.CREATE_CRYPTO_WITHDRAWAL_REQUEST,
  'Submitting external transfer request...',
  'Your transfer request has been submitted.',
);

const createFiatWithdrawalRequestSaga = createSaga(
  createFiatWithdrawalRequest,
  actionTypes.CREATE_FIAT_WITHDRAWAL_REQUEST,
  'Submitting external transfer request...',
  'Your transfer request has been submitted.',
);

export default function* watchManualEntries() {
  yield takeLatest(actionTypes.CREATE_FEE_REBATE_REQUEST, createFeeRebate);
  yield takeLatest(createGeneralJournal.TRIGGER, createGeneralJournalRequest);
  yield takeLatest(
    generalJournalCategories.TRIGGER,
    generalJournalCategoriesRequest,
  );
  yield takeLatest(createTrade.TRIGGER, createTradeRequest);
  yield takeLatest(closingPrices.TRIGGER, closingPricesRequest);
  yield takeLatest(updateClosingPrice.TRIGGER, updateClosingPriceRequest);
  yield takeLatest(republishBatch.TRIGGER, republishBatchSaga);
  yield takeLatest(
    createDesignationTransferJournal.TRIGGER,
    createSaga(
      createDesignationTransferJournal,
      actionTypes.CREATE_DESIGNATION_TRANSFER_JOURNAL,
      'Creating approval request for designated funds transfer...',
      'Approval Request Successfully created!',
    ),
  );
  yield takeLatest(triggerTask.TRIGGER, triggerTaskRequest);
  yield takeLatest(triggerTask.SUCCESS, pollTask);
  yield takeLatest(
    createCryptoWithdrawalRequest.TRIGGER,
    createCryptoWithdrawalRequestSaga,
  );
  yield takeLatest(
    createFiatWithdrawalRequest.TRIGGER,
    createFiatWithdrawalRequestSaga,
  );
  yield takeLatest(
    fetchTradeEntryCgmsBuySide.TRIGGER,
    createSaga(fetchTradeEntryCgmsBuySide, fetchTradeEntryCgmsBuySide._PREFIX),
  );
  yield takeLatest(
    fetchTradeEntryCgmsSellSide.TRIGGER,
    createSaga(
      fetchTradeEntryCgmsSellSide,
      fetchTradeEntryCgmsSellSide._PREFIX,
    ),
  );
}
