import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Divider, Icon } from 'semantic-ui-react';
import {
  getUserReferralCodes,
  setReferralCode,
  getPromotionReferrals,
  getReferralStats,
  getBonusBalance,
  fetchReferralCode,
  getReferralCode,
  getReferredByCode,
} from '../../reducers/referralCodeReducer';
import { createErrorMessageSelector } from 'erisxkit/client';
import ExternalTable from '../../common/table/ExternalTable';
import { formValueSelector } from 'redux-form';
import { createLoadingSelector } from 'erisxkit/client';
import referralCodesMetadata from '../../metadata/referralCodesMetadata';
import EnvPromise from '../../config/env';

const ReferralCode = ({ userId }) => {
  const dispatch = useDispatch();
  //Gets referredBy from user endpoint
  const { referredByCode } = useSelector(getUserReferralCodes);
  //User Referral code fetched from backend - necessary if user didn't previously have a referral code
  const referralCode = useSelector(getReferralCode);
  const error = useSelector((state) =>
    createErrorMessageSelector(['SET_REFERRED_BY_CODE'])(state),
  );
  const data = useSelector(getReferralStats);
  const bonus = useSelector(getBonusBalance);
  const loading = useSelector((state) =>
    createLoadingSelector(['PROMOTION_REFERRALS'])(state),
  );
  const [editOnboardingCode, setEditOnboardingCode] = useState(false);
  const [inputValue, setInputValue] = useState(referredByCode);
  const [empUrl, setEmpUrl] = useState('');
  const onboardingInputAction = editOnboardingCode ? 'Save' : 'Edit';

  const toggleEdit = () => {
    if (editOnboardingCode) {
      dispatch(
        setReferralCode({
          referredByCode: inputValue,
          userId,
        }),
      );
    }
    setEditOnboardingCode(!editOnboardingCode);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
  };

  const copyLink = () => {
    const link = `${empUrl}/signup?referral=${referralCode}`;
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    dispatch(getPromotionReferrals({ userId }));
    dispatch(fetchReferralCode({ userId }));
  }, [userId, referralCode]);

  useEffect(() => {
    EnvPromise.then((e) => setEmpUrl(e.empUrl));
  }, []);

  return (
    <div className="referral-wrapper">
      <Form>
        <Form.Field>
          <label>Referral Used During Onboarding</label>
          <div className="toggle-input">
            <Input
              name="onboardingReferral"
              style={{ width: '200px', color: '#828282', fontWeight: 'bold' }}
              disabled={!editOnboardingCode}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <p onClick={toggleEdit}>{onboardingInputAction}</p>
          </div>
        </Form.Field>
        <Divider />
        <Form.Field>
          <label>User Referral Code</label>
          <div className="user-referral-code">
            <div>
              <Input
                name="onboardingReferral"
                id="user-referral-code-input"
                style={{
                  width: '200px',
                  color: 'red !important',
                  fontWeight: 'bold',
                }}
                disabled={false}
                value={referralCode}
                onChange={() => {}}
              />
              <Icon
                name="copy"
                className="copy-referral"
                onClick={copyToClipboard}
              />
              <Icon
                name="share alternate"
                className="copy-referral"
                onClick={copyLink}
              />
            </div>
            <p className="referral-bonus-balance ">{`Earned Referrals: $${bonus}`}</p>
          </div>
        </Form.Field>
      </Form>
      <ExternalTable
        className="-striped -highlight"
        data={data}
        metadata={referralCodesMetadata}
        count={1}
        loading={loading}
        noDataText={<span className="italic">No referrals were found.</span>}
        filtered={false}
        showPagination={false}
      />
    </div>
  );
};

export default ReferralCode;
