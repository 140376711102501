import React, { Fragment } from 'react';
import _ from 'lodash';
import {
  Header,
  Form,
  Input,
  Select,
  Button,
  Message,
  TextArea,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { fundsDesignation } from 'erisxkit/client';
import { initialState } from '../../containers/ManualEntries/FeeRebateContainer';
import AssetTypeBalances from '../../common/AssetTypeBalances';
import AccountSelectionContainer from '../../common/containers/AccountSelectionContainer';
import postingTypes from '../../constants/postingTypes';
import FloatInputContainer from '../../common/containers/FloatInputContainer';
import { ProductContractSelection } from '../../common/containers';

const FeeRebate = ({
  handleChange,
  handleSubmit,
  feeAccount,
  assetTypes,
  lowFeeAccountBalance,
  feeRebateLoading,
  data,
  accountsLoading,
  disabled,
  submitDisabled,
}) => (
  <Fragment>
    <Header block>
      <h4>Fee Account Balances:</h4>
      <AssetTypeBalances
        loading={accountsLoading}
        balances={_.get(feeAccount, ['balances'])}
      />
    </Header>
    <Form onSubmit={handleSubmit}>
      <AccountSelectionContainer
        onChange={handleChange}
        name="accountId"
        label="Customer Account ID"
        required
        disabled={disabled}
      />
      <ProductContractSelection
        productName="productSymbol"
        contractName="contractSymbol"
        onChange={handleChange}
        required
        disabled={disabled}
      />
      <Form.Field
        loading={!assetTypes.length}
        required
        label="Asset Type"
        control={Select}
        search
        name="assetType"
        placeholder="Select or enter asset type..."
        options={assetTypes.map((asset) => ({
          key: asset.symbol,
          value: asset.symbol,
          text: asset.description,
          description: asset.symbol,
        }))}
        onChange={handleChange}
        value={data.assetType}
        disabled={disabled}
      />
      <Form.Field
        required
        label="Funds Designation"
        control={Select}
        search
        name="fundsDesignation"
        placeholder="Select or enter fund designation..."
        options={fundsDesignation.map((designation) => ({
          key: designation.value,
          text: designation.text,
          value: designation.value,
          description: designation.value,
        }))}
        onChange={handleChange}
        value={data.fundsDesignation}
        disabled={disabled}
      />
      <Form.Field
        label="Posting Type"
        required
        control={Select}
        search
        name="postingKey"
        placeholder="Select or enter posting type..."
        options={postingTypes.feeRebate.map((type) => ({
          ...type,
          key: type.value,
        }))}
        onChange={handleChange}
        value={data.postingType}
        disabled={disabled}
      />
      <FloatInputContainer
        required
        label="Amount"
        control={Input}
        icon="money bill alternate outline"
        name="amount"
        placeholder="Rebate amount"
        iconPosition="left"
        onChange={handleChange}
        value={data.amount}
        disabled={disabled}
      />
      <Form.Field
        label="Description"
        control={TextArea}
        name="description"
        placeholder="Enter Description"
        onChange={handleChange}
        value={data.description}
        disabled={disabled}
      />
      {lowFeeAccountBalance ? (
        <Message negative>
          <Message.Header>Fee Account Balance Low</Message.Header>
          <Message.Content>
            A rebate with this quantity would exceed the amount currently
            available in the associated fee account. You may still execute this
            rebate.
          </Message.Content>
        </Message>
      ) : null}
      <Button
        type="submit"
        primary
        className="add-button"
        disabled={submitDisabled}
        loading={feeRebateLoading}
        onSubmit={handleSubmit}
      >
        Submit
      </Button>
    </Form>
  </Fragment>
);

FeeRebate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  feeAccount: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      name: PropTypes.string,
      members: PropTypes.array,
      balances: PropTypes.object,
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  lowFeeAccountBalance: PropTypes.bool,
  data: PropTypes.shape({
    accountId: PropTypes.string,
    lowFeeAccountBalance: PropTypes.bool,
    assetType: PropTypes.string,
    amount: PropTypes.string,
  }),
  feeRebateLoading: PropTypes.bool,
  accountsLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
};

FeeRebate.defaultProps = {
  accounts: [],
  spotProductsList: [],
  feeAccount: [],
  lowFeeAccountBalance: false,
  data: initialState,
  feeRebateLoading: false,
  accountsLoading: false,
  disabled: false,
  submitDisabled: false,
};

export default FeeRebate;
