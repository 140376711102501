import React, { useState, useMemo, useEffect } from 'react';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import isUndefined from 'lodash/isUndefined';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';
import Datetime from 'react-datetime';
import colors from '../../constants/colors';
import { TABLE_INPUT_TYPES } from './constants';
import DatePicker from 'react-datepicker';

const StyledDatePicker = styled(DatePicker)`
  width: 150px;
`;

const Input = styled.input`
  color: ${({ hasChanged }) => (hasChanged ? `${colors.accent2}` : 'unset')};
  border: ${({ error }) =>
    `1px solid ${error ? colors.error : colors.primary3}`};
  width: 100%;
  border-radius: 3px;
  height: 25px;
  padding-left: 5px;
  :focus-visible {
    outline: none;
    border-color: ${colors.accent2};
  }
`;

const DisplayValue = styled.p`
  color: ${(props) => (props.hasChanged ? `${colors.accent2}` : 'unset')};
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 100% !important;
  min-height: unset !important;
  height: 25px;
  padding: 0px 15px 0px 5px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  color: ${({ hasChanged }) =>
    hasChanged ? `${colors.accent2}` : 'unset'} !important;
  border: ${({ error }) =>
    `1px solid ${error ? colors.error : colors.primary3}`};

  :focus {
    border-color: ${colors.accent2} !important;
  }
  .dropdown.icon {
    padding: 0 !important;
    position: unset !important;
  }
`;

const getCastedValue = (value, type) => {
  switch (type) {
    case TABLE_INPUT_TYPES.NUMBER:
      // If it's not a number - return as string
      // For number inputs, if the user used backspace to clear the field,
      // we must take the '' as a valid input
      if (value === '') return value;
      return isNaN(value) ? String(value) : Number(value);
    case TABLE_INPUT_TYPES.STRING:
      return value ? String(value) : '';
    case TABLE_INPUT_TYPES.BOOLEAN:
      // TODO: Change to Select component so to avoid handling booleans as strings
      return value;

    default:
      return value ? String(value) : '';
  }
};

const EditDropdown = ({ onChange, value, onBlur, hasChanged, options }) => (
  <StyledDropdown
    onChange={(e, v) => {
      onChange(get(v, 'value', ''));
    }}
    options={options}
    placeholder="Select"
    selection
    value={value}
    onBlur={onBlur}
    hasChanged={hasChanged}
  />
);

const EditBooleanDropdown = ({ onChange, value, onBlur, hasChanged }) => (
  <EditDropdown
    onChange={onChange}
    value={value}
    onBlur={onBlur}
    hasChanged={hasChanged}
    options={[
      { key: 'true', value: true, text: 'True' },
      { key: 'false', value: false, text: 'False' },
    ]}
  />
);

const EditDate = ({ onChange, value, hasChanged, onBlur }) => {
  return (
    <StyledDatePicker
      placeholderText="YYYY-MM-DD"
      className="ui input inherit"
      selected={value ? Datetime.moment(value, 'YYYY-MM-DD').toDate() : null}
      dateFormat="yyyy-MM-dd"
      closeOnSelect
      name="date"
      onChange={(currentDate) => {
        onChange(Datetime.moment(currentDate).format('yyyy-MM-DD'));
      }}
      onBlur={onBlur}
    />
  );
};

const EditInput = ({ hasChanged, value, type, onChange, onBlur, error }) => (
  <Input
    hasChanged={hasChanged}
    style={{ WebkitAppearance: 'auto' }}
    value={value}
    type={type}
    onChange={(e) => onChange(get(e, 'target.value', ''))}
    onBlur={onBlur}
    error={error}
  />
);

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index, id: rowId, state },
  column: { id, editInputType: type, uneditable, options },
  updateEditableData, // This is a custom function that we supplied to our table instance
  editedRows,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const isBeingEdited = useMemo(
    () => editedRows?.includes(rowId),
    [rowId, editedRows],
  );

  const hasChanged = useMemo(() => {
    const originalValue = get(state, ['original', `${id}`]);
    const hasOriginalValue = get(state, 'original', false);
    if (!hasOriginalValue) return false;
    return originalValue !== value;
  }, [value, state]);

  const onChange = (value) => {
    // Cast value to corresponding type to keep type consistency
    const val = getCastedValue(value, type);
    setValue(val);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateEditableData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const EditComponent = useMemo(() => {
    switch (type) {
      case TABLE_INPUT_TYPES.BOOLEAN:
        return EditBooleanDropdown;
      case TABLE_INPUT_TYPES.DATE:
        return EditDate;
      case TABLE_INPUT_TYPES.LIST:
        return EditDropdown;
      default:
        return EditInput;
    }
  }, [type]);

  const inputProps = {
    hasChanged,
    type,
    onChange,
    onBlur,
    value,
    options,
    error: get(state, 'invalidCells', []).includes(id),
  };

  const displayValue = isUndefined(value) ? '' : String(value);

  return isBeingEdited && !uneditable ? (
    <EditComponent {...inputProps} />
  ) : (
    <DisplayValue hasChanged={hasChanged}>{displayValue}</DisplayValue>
  );
};

export default EditableCell;
