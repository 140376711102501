import isEmpty from 'lodash/isEmpty';
import { snakeCase } from 'change-case';
import get from 'lodash/get';
import replaceUrlParams from './replaceUrlParams';
import populateQueryParams from './populateQueryParams';

/*
  - desc: Receives a uri and returns
            the uri with urlParams and queryParams populated (if corresopnding)
            If no params provided, returns the same uri
            and object with the parameter values and
            returns the uri with the populated values
  @ param - uri: string with optional unpopulated parameters, delimited by ':'
  @ param - payload: Object that may have optional queryParam and urlParams objects
  - returns: uri populated with paramaters and queryparams if corresponding
  e.g.:
 uri = "/api/user/:user_id/documents";
 urlParams = { userId: "1234";
 returns http://localhost:3002/api/v2/update_cgm_/1234/cgm/456
*/
const parseUrl = (uri, payload) => {
  const withUrlParams = replaceUrlParams(uri, get(payload, 'urlParams', {}));
  const withQueryParams = populateQueryParams(
    withUrlParams,
    get(payload, 'queryParams', {}),
  );
  return withQueryParams;
};

export default parseUrl;
