import React from 'react';
import { Redirect } from 'react-router-dom';

const HomeContainer = ({ auth }) => {
  !auth.isAuthenticated() ? (
    auth.login()
  ) : (
    <Redirect
      to={{
        pathname: '/home/',
        state: { from: '/' },
      }}
    />
  );
  return null;
};

export default HomeContainer;
