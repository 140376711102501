import React, { Fragment } from 'react';
import { Form, Modal, Button } from 'semantic-ui-react';
import { renderField, rules, normalization } from 'erisxkit/client';
import { Field, reduxForm } from 'redux-form';

export const CreateManualExternalTransaction = ({
  hideModal,
  handleSubmit,
  handleChange,
  valid,
}) => (
  <Fragment>
    <Modal.Header>Create Manual External Transaction</Modal.Header>
    <Modal.Content>
      <Form>
        <Field
          placeholder="Amount"
          label="Amount"
          type="text"
          name="amount"
          component={renderField}
          parse={normalization.floatsOnly}
          validate={[rules.required]}
        />
        <Field
          placeholder="Comment"
          label="Comment"
          type="text"
          name="comment"
          component={renderField}
          validate={[rules.required]}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button id="cancel" onClick={hideModal}>
        Cancel
      </Button>
      <Button id="confirm" onClick={handleSubmit} disabled={!valid}>
        Confirm
      </Button>
    </Modal.Actions>
  </Fragment>
);

export default reduxForm({
  form: 'create_manual_external_transaction',
})(CreateManualExternalTransaction);
