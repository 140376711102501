import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import FloatInputContainer from '../../common/containers/FloatInputContainer';
import ProductContractSelection from '../../common/containers/ProductContractSelection';
import SubExchangeSelectionContainer from '../../common/containers/SubExchangeSelectionContainer';

const UpdateClosingPrice = ({ handleChange }) => {
  const [subExchangeId, setSubExchangeId] = useState(null);
  return (
    <Form>
      <Form.Group style={{ flexWrap: 'wrap' }}>
        <Form.Field
          control={Datetime}
          label="Date"
          inputProps={{ placeholder: 'MM/DD/YYYY (optional)', name: 'date' }}
          timeFormat={false}
          name="date"
          closeOnSelect
          onChange={(currentDate) =>
            handleChange(null, {
              name: 'date',
              value: Datetime.moment(currentDate._d).format('YYYY-MM-DD'),
            })
          }
        />
        <Form.Field
          control={SubExchangeSelectionContainer}
          name="subExchangeId"
          id="subExchangeId"
          input={{
            onChange: (value) => setSubExchangeId(value),
          }}
        />
        <ProductContractSelection
          contractName="contractSymbol"
          onChange={handleChange}
          subExchangeId={subExchangeId}
          required
        />
        <FloatInputContainer
          required
          label="Price"
          icon="money bill alternate outline"
          name="price"
          id="price"
          placeholder="0.00"
          iconPosition="left"
          onChange={handleChange}
        />
        <Form.Field
          control={Form.Select}
          placeholder="Select a type"
          options={[
            { key: 'midDay', value: 'mid_day', text: 'Mid Day' },
            { key: 'adHoc', value: 'ad_hoc', text: 'Ad Hoc' },
            { key: 'endOfDay', value: 'end_of_day', text: 'End Of Day' },
            { key: 'final', value: 'final', text: 'Final' },
          ]}
          label="Settlement Type"
          name="settlementPriceType"
          id="settlementPriceType"
          onChange={handleChange}
        />
      </Form.Group>
    </Form>
  );
};

UpdateClosingPrice.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default UpdateClosingPrice;
